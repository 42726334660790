import { useMemo, useState } from 'react'
import type { Order } from '@sevenrooms/core/domain'
import { useOrdersManagementResourcesContext } from './useOrdersManagementResourcesContext'

export function useOrdersManagementContext(list: Order[]) {
  const { fulfillmentChoices } = useOrdersManagementResourcesContext()
  const [filterSearchValue, setFilterSearchValue] = useState<string>('')
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
  const [selectedFulfillment, setSelectedFulfillment] = useState<string[]>([])

  const filteredOrders = useMemo(() => {
    const searchValue = filterSearchValue?.toLowerCase()
    return list.filter(
      (order: Order) =>
        (`${order.firstName} ${order.lastName}`.toLowerCase().includes(searchValue) ||
          order.phoneNumber.toLowerCase().includes(searchValue) ||
          order.orderNumber.toLowerCase().includes(searchValue)) &&
        (!selectedStatuses.length || selectedStatuses.some(status => status === order.status)) &&
        (!selectedFulfillment.length || selectedFulfillment.some(fulfillment => fulfillment === order.method))
    )
  }, [filterSearchValue, list, selectedStatuses, selectedFulfillment])

  const filteredFulfillmentChoices = useMemo(() => {
    if (!list.length) {
      return fulfillmentChoices
    }
    const fulfillment: Record<string, boolean> = {}
    list.forEach(order => {
      fulfillment[order.method] = true
    })
    return fulfillmentChoices.filter(value => fulfillment[value.id])
  }, [list, fulfillmentChoices])

  return {
    filterSearchValue,
    setFilterSearchValue,
    selectedStatuses,
    setSelectedStatuses,
    selectedFulfillment,
    setSelectedFulfillment,
    filteredOrders,
    filteredFulfillmentChoices,
  }
}
