import React from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'

const PhotoWrap = styled.div`
  height: 100%;
  width: 100%;
`

const PhotoArea = styled.div`
  border: 1px solid #fff;
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
`

const PhotoLabel = styled.div`
  font-style: oblique;
`

const ImagePrepos = styled.img`
  left: -25%;
  position: absolute;
  width: 150%;
`

const Actions = styled.div`
  position: absolute;
  right: 3px;
  top: 3px;
`

const StyledDropper = styled(Dropzone)`
  background: #f5f5f5 url(${window.globalInit.mediaUrl}images/add_picture.png) 50% 50% no-repeat;
  background-size: 28px 21.5px;
  border: 1px dashed #ccc;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  height: 100%;
  position: relative;
  width: 100%;
`

const PictureButton = styled(StyledVmsIconS)`
  background: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.greySecondary};
  border-radius: 3px;
  color: ${props => props.theme.color.teal};
  cursor: pointer;
  padding: 3px;
  margin-left: 3px;
`

class PhotoUpload extends React.Component {
  constructor(props) {
    super(props)

    this.onDrop = this.onDrop.bind(this)
    this.deletePhoto = this.deletePhoto.bind(this)
    this.editPhoto = this.editPhoto.bind(this)

    this.aspect = 1.5
  }

  cropDisplay(photo) {
    if (!photo || !photo.crop_info) {
      return {}
    }
    const widthDiff = Number(photo.crop_info.width) / 100
    const heightDiff = Number(photo.crop_info.height) / 100
    return {
      width: `${100 / widthDiff}%`,
      top: `${-1 * (Number(photo.crop_info.y) / heightDiff)}%`,
      left: `${-1 * (Number(photo.crop_info.x) / widthDiff)}%`,
    }
  }

  onDrop(accepted) {
    if (this.props.disabled) {
      return
    }
    this.props.uploadPhoto(accepted, this.props.photoId, this.props.aspect)
  }

  editPhoto() {
    this.props.editPhoto(this.props.photoId, this.props.aspect)
  }

  deletePhoto() {
    this.props.deletePhoto(this.props.photoId)
  }

  render() {
    const { width, disabled, photo, campaignId, aspect } = this.props

    const cropStyle = this.cropDisplay(photo)

    return (
      <div style={{ width: `${width}px`, height: `${width / aspect}px` }}>
        {photo.raw_url_key ? (
          <PhotoWrap>
            <PhotoArea>
              <ImagePrepos src={`/.h/download/${photo.raw_url_key}`} style={cropStyle} />
              {!disabled ? (
                <Actions>
                  <PictureButton onClick={this.editPhoto}>{VmsIcons.EditpencilLine}</PictureButton>
                  <PictureButton onClick={this.deletePhoto}>{VmsIcons.TrashLine}</PictureButton>
                </Actions>
              ) : null}
            </PhotoArea>
            <PhotoLabel>{photo.label}</PhotoLabel>
          </PhotoWrap>
        ) : (
          <StyledDropper onDrop={this.onDrop} multiple={false} disableClick={disabled} disabled={disabled} />
        )}
      </div>
    )
  }
}

export default PhotoUpload
