import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'
import { Accordion } from '@sevenrooms/react-components/components/Accordion'
import { AccordionSummary } from '@sevenrooms/react-components/components/AccordionSummary'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Typography } from '@sevenrooms/react-components/components/Typography'

interface AccordianContentProps {
  children: React.ReactNode
  expanded: boolean
  defaultExpanded: boolean
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void
  headerMessage: string
  dataTestId: string
  actionButtonEnabled: boolean
  actionButtonProps?: {
    label: string
    url: string
  }
}
export function AccordionContent({
  children,
  expanded,
  defaultExpanded,
  onChange,
  headerMessage,
  dataTestId,
  actionButtonEnabled = false,
  actionButtonProps,
}: AccordianContentProps) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={onChange}
      sx={{
        backgroundColor: '#F7F7F7',
        mb: 6,
        mx: 6,
        borderRadius: '4px',
        borderColor: '#EEEEEE',
        '&.Mui-expanded': {
          mx: 6,
          '&:last-of-type': {
            mb: 6,
          },
        },
      }}
      elevation={0}
      data-test={dataTestId}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-content"
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-content': {
            ml: theme => theme.spacing(1),
            justifyContent: 'space-between',
            alignItems: 'center',
            '&.Mui-expanded': {
              ml: '4px',
            },
          },
        }}
      >
        <Typography variant="caption" fontWeight={700} sx={{ lineHeight: '34px' }}>
          {headerMessage}
        </Typography>
        {actionButtonEnabled && actionButtonProps && (
          <Button
            data-test="action-button-label-button"
            sx={{ ml: 2, my: 'auto', height: 'auto' }}
            size="small"
            variant="contained"
            onClick={() => window.open(actionButtonProps?.url, '_blank')}
          >
            {actionButtonProps.label}
          </Button>
        )}
      </AccordionSummary>
      {children}
    </Accordion>
  )
}
