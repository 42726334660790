import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { type ThemeColor, vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Text, SecondaryText, Header } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../locales'
import { formatParagraph } from '../../utils'

export interface ProgressMeterProps {
  total: number
  completed: number
  emptyColor?: ThemeColor
  completedColor?: ThemeColor
  interiorColor?: ThemeColor
}

export function ProgressMeter({ total, completed, emptyColor, completedColor, interiorColor }: ProgressMeterProps) {
  const { formatMessage } = useLocales()

  const colorProgress = Array<string | undefined>(3).fill(emptyColor ? theme.colors[emptyColor] : undefined)
  colorProgress.fill(completedColor ? theme.colors[completedColor] : undefined, 0, completed)
  const interior = interiorColor ? theme.colors[interiorColor] : undefined

  const encouragementOptions: { id: string; defaultMessage: string }[] = [
    insightsMessages.getStarted,
    insightsMessages.goodStart,
    insightsMessages.keepItUp,
    insightsMessages.niceJob,
  ]

  const position = completed ? completed + 3 - total : 0
  const encouragement = encouragementOptions[position]

  return (
    <Box borderColor="dividerLines" borderRadius="s" backgroundColor="primaryBackground" p="m">
      <Header mb="s" type="h2">
        {formatMessage(insightsMessages.priorityInsights)}
      </Header>
      <SecondaryText>{formatMessage(insightsMessages.priorityDescription)}</SecondaryText>
      <Box position="relative" mt="l" mb="l">
        <svg width="100%" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 125">
          {total === 3 && (
            <>
              <path
                d="M63.38,28.27l-5.16-8.94C23.23,41.48,0,80.53,0,125h10.32c0-40.65,21.16-76.37,53.06-96.73Z"
                style={{ fill: `${colorProgress[0]}`, strokeWidth: '0px' }}
              />
              <path
                d="M125,10.32c19.11,0,37.12,4.68,52.96,12.94l5.17-8.95C165.76,5.18,145.99,0,125,0s-40.76,5.18-58.13,14.32l5.17,8.95c15.84-8.27,33.86-12.94,52.96-12.94Z"
                style={{ fill: `${colorProgress[1]}`, strokeWidth: '0px' }}
              />
              <path
                d="M239.68,125h10.32c0-43.91-22.64-82.52-56.88-104.82l-5.17,8.95c31.16,20.5,51.73,55.78,51.73,95.87Z"
                style={{ fill: `${colorProgress[2]}`, strokeWidth: '0px' }}
              />
            </>
          )}
          {total === 2 && (
            <>
              <path
                d="M120,0C53.28,2.63,0,57.53,0,124.89h10.32C10.32,63.23,58.99,12.95,120,10.33V0Z"
                style={{ fill: `${colorProgress[0]}`, strokeWidth: '0px' }}
              />
              <path
                d="M130,0v10.33c61.01,2.62,109.68,52.9,109.68,114.56h10.32C250,57.53,196.72,2.63,130,0Z"
                style={{ fill: `${colorProgress[1]}`, strokeWidth: '0px' }}
              />
            </>
          )}
          {total === 1 && (
            <>
              <path
                d="M125,10.32c63.34,0,114.68,51.34,114.68,114.68h10.32C250,55.96,194.04,0,125,0S0,55.96,0,125h10.32C10.32,61.66,61.66,10.32,125,10.32Z"
                style={{ fill: `${colorProgress[0]}`, strokeWidth: '0px' }}
              />
            </>
          )}
          <path
            d="M125,17.72c-59.25,0-107.28,48.03-107.28,107.28h214.56c0-59.25-48.03-107.28-107.28-107.28Z"
            style={{ fill: `${interior}`, strokeWidth: '0px' }}
          />
        </svg>
        <Box position="absolute" width="100%" bottom="xl">
          <VStack mr="auto" ml="auto" spacing="xs" width="50%" textAlign="center">
            {!!completed && (
              <Text fontWeight="bold">
                {formatMessage(insightsMessages.insightsWithCounter, {
                  counter: completed,
                })}
              </Text>
            )}
            <SecondaryText>
              {encouragement &&
                formatMessage(encouragement, {
                  p: (chunks: string[]) => formatParagraph(chunks, 'div'),
                })}
            </SecondaryText>
          </VStack>
        </Box>
      </Box>
      <HStack>
        <SecondaryText>0</SecondaryText>
        <Box textAlign="center" width="100%" ml="l" mr="l" borderLeftColor="dividerLines" borderRightColor="dividerLines">
          <SecondaryText>{formatMessage(insightsMessages.topInsights)}</SecondaryText>
        </Box>
        <SecondaryText>{total}</SecondaryText>
      </HStack>
    </Box>
  )
}
