import moment from 'moment/moment'
import { connect } from 'react-redux'
import styled from 'styled-components'
// eslint-disable-next-line import/no-cycle
import { DayViewInnerContainer } from 'mgr/pages/single-venue/dayview'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import NotesHighlightBar from 'mgr/pages/single-venue/dayview/components/NotesHighlightBar'
import DayviewToolbar from 'mgr/pages/single-venue/dayview/containers/DayviewToolbar'
import Grid from 'mgr/pages/single-venue/dayview/containers/Grid'
import { selectNoteAndEvents } from 'mgr/pages/single-venue/dayview/selectors/DayViewSelectors'
import type { AccessRule } from '@sevenrooms/core/domain'
import { AccessRulesSlideout } from '@sevenrooms/mgr-access-rules-slideout/AccessRulesSlideout'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import type { Venue } from '@sevenrooms/mgr-core'
import type { Moment } from 'moment'
import type { RouteComponentProps } from 'react-router'

interface GridLayoutProps extends RouteComponentProps {
  noteAndEvents: { note: string; events: string; hasNoteOrEvent: boolean }
  date: Date
  venue: Venue
  selectedAccessRule: AccessRule | null
  loadAndRefreshData: (venueId: string, date: Moment) => void
  setSelectedAccessRule: (selectedAccessRule: AccessRule | null) => void
}

function GridLayout({
  history,
  noteAndEvents,
  date,
  venue,
  selectedAccessRule,
  loadAndRefreshData,
  setSelectedAccessRule,
}: GridLayoutProps) {
  const { data: accessRulesSlideoutData } = useAccessRulesSlideoutData()
  const { note, events, hasNoteOrEvent } = noteAndEvents

  return (
    <GridContainer>
      <DayviewToolbar history={history} key="dayViewToolBar" />
      {hasNoteOrEvent && <NotesHighlightBar note={note} events={events} />}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DayViewInnerContainer extraMargin={hasNoteOrEvent}>
        <Grid />
      </DayViewInnerContainer>
      {selectedAccessRule && accessRulesSlideoutData && (
        <AccessRulesSlideout
          accessRule={selectedAccessRule}
          onClose={() => setSelectedAccessRule(null)}
          onRuleSave={() => {
            setSelectedAccessRule(null)
            loadAndRefreshData(venue.id, moment(date))
          }}
          onRuleDelete={() => {
            setSelectedAccessRule(null)
            loadAndRefreshData(venue.id, moment(date))
          }}
          selectedDay={date.toISOString()}
          shiftCategories={[]}
          startDate={date}
          accessRulesSlideoutData={accessRulesSlideoutData}
        />
      )}
    </GridContainer>
  )
}

const GridContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
`

const mapStateToProps = (state: { dayviewState: { date: Date; selectedAccessRule: AccessRule | null }; appState: { venue: Venue } }) => ({
  noteAndEvents: selectNoteAndEvents(state),
  venue: state.appState.venue,
  date: state.dayviewState.date,
  selectedAccessRule: state.dayviewState.selectedAccessRule,
})

const mapDispatchToProps = {
  setSelectedAccessRule: Actions.setSelectedAccessRule,
  loadAndRefreshData: Actions.loadAndRefreshData,
}

export default connect(mapStateToProps, mapDispatchToProps)(GridLayout)
