export enum RevenueTypeEnum {
  EMAIL_MARKETING = 'email_marketing',
  AUTOMATED_EMAILS = 'automated_emails',
  EXPERIENCE_OFFERS = 'experience_offers',
  UPGRADE = 'upgrade',
  OTHER_PREPAYMENTS = 'other_prepayments',
  CANCELLATION_AND_NO_SHOW_FEE = 'cancellation_and_no_show_fee',
  EVENTS = 'events',
  MULTI_VENUE_WIDGETS = 'multi_venue_widgets',
}
