import * as React from 'react'
import styled from 'styled-components'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'

const EditDeleteInputBody = styled.div``
const EditDeleteInputRow = styled.div``
const EditDeleteInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`
const EditDeleteInputWrapper = styled.div`
  position: relative;
`
const EditDeleteInputField = styled.input`
  font-size: 13px;
  width: ${props => (props.disabled ? `${props.maxWidth}px` : props.isDeleted ? `${props.maxWidth - 17}px` : `${props.maxWidth - 42}px`)};
  padding: 10px;
  outline: none;
  background-color: ${props => (props.isFocused ? props.theme.color.white : '#F7F7F7')};
  border: 1px solid
    ${props => (props.disabled ? 'rgba(248, 248, 248)' : props.isFocused ? '#347baf' : props.isValid ? '#DBDCDE' : '#E95757')};
  border-radius: 2px;
  text-decoration: ${props => (props.isDeleted ? 'line-through' : 'none')};
`
const EditDeleteInputLabel = styled.label`
  position: absolute;
  right: 12px;
  margin-top: 12px;
  color: ${props => props.theme.color.grey};
  text-decoration: ${props => (props.isDeleted ? 'line-through' : 'none')};
`
const EditIcon = styled(StyledIcons.S)`
  cursor: pointer;
  padding-left: 10px;
  color: ${props => (props.isFocused ? '#347baf' : '#93979d')};
`
const DeleteIcon = styled(StyledIcons.S)`
  cursor: pointer;
  padding-left: 10px;
  color: ${props => (props.isFocused ? '#347baf' : '#93979d')};
`
const ErrorMessage = styled.div`
  font-size: 13px;
  color: #e95757;
  padding-left: 20px;
`

class EditDeleteTextInput extends React.PureComponent {
  static propTypes = {
    editDeleteInputContainerStyles: React.PropTypes.object,
    idx: React.PropTypes.number,
    uniqueId: React.PropTypes.string,
    className: React.PropTypes.string,
    value: React.PropTypes.string,
    placeHolder: React.PropTypes.string,
    floatRightLabel: React.PropTypes.string,
    isFocused: React.PropTypes.bool,
    isValid: React.PropTypes.bool,
    onDeleteMsg: React.PropTypes.string,
    errorMsg: React.PropTypes.string,
    editIconFocused: React.PropTypes.bool,
    isDeleted: React.PropTypes.bool,
    disabled: React.PropTypes.bool,
    maxWidth: React.PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.state = {
      idx: this.props.idx,
      uniqueId: this.props.uniqueId,
      className: this.props.className,
      value: this.props.value,
      placeHolder: this.props.placeHolder,
      floatRightLabel: this.props.floatRightLabel,
      isFocused: this.props.isFocused,
      isValid: this.props.isValid,
      onDeleteMsg: this.props.onDeleteMsg || '',
      errorMsg: this.props.errorMsg || '',
      editIconFocused: this.props.editIconFocused || false,
      isDeleted: false,
    }
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onDeleteHandler = this.onDeleteHandler.bind(this)
    this.onEditHandler = this.onEditHandler.bind(this)
    this.onFocusHandler = this.onFocusHandler.bind(this)
    this.onBlurHandler = this.onBlurHandler.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isValid !== this.props.isValid ||
      prevProps.isDeleted !== this.props.isDeleted ||
      prevProps.isFocused !== this.props.isFocused ||
      prevProps.idx !== this.props.idx ||
      prevProps.value !== this.props.value ||
      prevProps.errorMsg !== this.props.errorMsg ||
      prevProps.onDeleteMsg !== this.props.onDeleteMsg
    ) {
      this.setState({
        value: this.props.value,
        isValid: this.props.isValid,
        isDeleted: this.props.isDeleted,
        idx: this.props.idx,
        isFocused: this.props.isFocused,
        errorMsg: this.props.errorMsg,
        onDeleteMsg: this.props.onDeleteMsg,
      })
    }
  }

  onFocusHandler() {
    this.setState({ isFocused: true, editIconFocused: true })
    if (this.inputField) {
      this.inputField.focus()
      this.inputField.setSelectionRange(this.inputField.value.length, this.inputField.value.length)
      this.inputField.value = this.inputField.value
    }
    if (this.props.focusHandler) {
      this.props.focusHandler(this.state.idx)
    }
  }

  onBlurHandler() {
    this.setState({ isFocused: false })
    this.inputField.blur()
    if (this.props.blurHandler) {
      this.props.blurHandler(this.state.idx)
    }
  }

  onEditHandler() {
    if (!this.state.editIconFocused) {
      this.onFocusHandler()
      if (this.props.editHandler) {
        this.props.editHandler()
      }
    } else {
      this.setState({ editIconFocused: false })
    }
  }

  onChangeHandler(e) {
    if (this.state.isDeleted) {
      return
    }
    this.setState({ value: e.target.value, isValid: true })
    this.props.onChange(e.target.value, this.state.idx)
  }

  onDeleteHandler() {
    this.setState({ isDeleted: !this.state.isDeleted, isFocused: false })
    if (this.props.deleteHandler) {
      this.props.deleteHandler(this.state.idx)
    }
  }

  render() {
    return (
      <EditDeleteInputBody>
        <EditDeleteInputRow>
          <EditDeleteInputContainer style={this.props.editDeleteInputContainerStyles}>
            <EditDeleteInputWrapper>
              <EditDeleteInputField
                id={this.state.uniqueId}
                key={this.state.uniqueId}
                value={this.state.value}
                className={this.state.className}
                placeholder={this.state.placeHolder}
                isValid={this.state.isValid}
                isDeleted={this.state.isDeleted}
                autoFocus={this.state.isFocused}
                isFocused={this.state.isFocused}
                onFocus={this.onFocusHandler}
                onBlur={this.onBlurHandler}
                onChange={event => this.onChangeHandler(event)}
                ref={inputField => (this.inputField = inputField)}
                disabled={this.props.disabled}
                maxWidth={this.props.maxWidth}
              />
              <EditDeleteInputLabel
                htmlFor={this.state.uniqueId}
                className={`sr-edit-delete-label-${this.state.className}`}
                isDeleted={this.state.isDeleted}
              >
                {this.state.floatRightLabel}
              </EditDeleteInputLabel>
            </EditDeleteInputWrapper>
            <DeleteIcon onClick={this.onDeleteHandler} className={`sr-delete-icon-${this.state.className}`} aria-hidden="true">
              {this.state.isDeleted ? VmsIcons.ReplyAll : VmsIcons.Delete}
            </DeleteIcon>
            {!this.state.isDeleted && !this.props.disabled && (
              <EditIcon
                onClick={this.onEditHandler}
                aria-hidden="true"
                isFocused={this.state.isFocused}
                className={`sr-edit-icon-${this.state.className}`}
              >
                {VmsIcons.EditPencil}
              </EditIcon>
            )}
          </EditDeleteInputContainer>
        </EditDeleteInputRow>
        {!this.state.isValid && (
          <EditDeleteInputRow>
            <ErrorMessage key={`sr-edit-delete-error-msg-${this.state.uniqueId}`}>{this.state.errorMsg}</ErrorMessage>
          </EditDeleteInputRow>
        )}
        {this.state.isValid && this.state.isDeleted && (
          <EditDeleteInputRow>
            <ErrorMessage key={`sr-edit-delete-delete-msg-${this.state.uniqueId}`}>{this.state.onDeleteMsg}</ErrorMessage>
          </EditDeleteInputRow>
        )}
      </EditDeleteInputBody>
    )
  }
}
EditDeleteTextInput.defaultProps = {
  maxWidth: 404,
}
export default EditDeleteTextInput
