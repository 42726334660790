import { useEffect, useRef } from 'react'
import { useFuzzyFilter } from '@sevenrooms/core/ui-kit/hooks'
import { Box, type SideNavigationCategoryItem, type SideNavigationPageItem } from '@sevenrooms/core/ui-kit/layout'
import { NavigationDashboardSearch } from '../NavigationDashboardSearch'
import { NavigationDashboardSection } from '../NavigationDashboardSection'

export const NavigationDashboardTestIds = {
  section: 'navigation-dashboard-section',
  filteredResultsSection: 'navigation-dashboard-filtered-results',
}

export interface NavigationDashboardPageItem extends SideNavigationPageItem {
  description?: string
  badge?: string
  thumbnail: string
}

export interface NavigationDashboardCategoryItem extends SideNavigationCategoryItem {
  hideTitle?: boolean
  items: NavigationDashboardPageItem[]
}

export interface NavigationDashboardProps {
  items: NavigationDashboardCategoryItem[]
  searchPlaceholder: string
  type: string
}

export function NavigationDashboard({ items, searchPlaceholder, type }: NavigationDashboardProps) {
  const {
    result: filteredItems,
    filter,
    setFilter,
  } = useFuzzyFilter<NavigationDashboardPageItem>({
    options: items.flatMap(x => x.items),
    optionToString: itemToString,
  })

  const previousFilter = useRef('')
  const isBackspacing = useRef(false)
  useEffect(() => {
    if (!isBackspacing.current && filter.length < previousFilter.current.length) {
      sendTrackingEvent(previousFilter.current, 'beforeBackspace', type)
      isBackspacing.current = true
    } else if (filter.length > previousFilter.current.length) {
      isBackspacing.current = false
    }

    previousFilter.current = filter
  }, [filter, type])

  useEffect(() => {
    const handler = () => {
      sendTrackingEvent(previousFilter.current, 'beforeUnload', type)
    }

    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
      sendTrackingEvent(previousFilter.current, 'beforeReactNavigation', type)
    }
  }, [type])

  return (
    <Box mb="xxl" width="850px">
      <NavigationDashboardSearch placeholder={searchPlaceholder} setSearch={setFilter} />
      {filter ? (
        <NavigationDashboardSection
          header="Search Results"
          hideHeader
          items={filteredItems}
          dataTest={NavigationDashboardTestIds.filteredResultsSection}
        />
      ) : (
        items.map(categoryItem => (
          <NavigationDashboardSection
            key={categoryItem.id}
            header={categoryItem.title}
            hideHeader={categoryItem.hideTitle}
            items={categoryItem.items}
            dataTest={NavigationDashboardTestIds.section}
          />
        ))
      )}
    </Box>
  )
}

const itemToString = (item: NavigationDashboardPageItem | null) => {
  let result = item?.title ?? ''
  if (item?.keywords) {
    result = `${result} ${item.keywords.join(' ')} ${item.description}`
  }
  return result
}

const sendTrackingEvent = (query: string, state: string, type: string) => {
  if (!query) {
    return
  }

  window?.metricManager?.track('reporting_dashboard_search', {
    query,
    state,
    type,
  })
}
