import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import {
  getOrderingChargesList,
  putOrderingCharge,
  postNewOrderingCharge,
  deleteOrderingChargeById,
} from 'mgr/lib/services/OrderingChargesService'
import { getOrderingSites } from 'mgr/lib/services/SitesServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'

export const loadOrderingCheckoutFeeList =
  ({ venueId }) =>
  dispatch => {
    Promise.all([getOrderingChargesList({ venueId }), getOrderingSites(venueId)])
      .then(([chargesResponse, sitesResponse]) => {
        dispatch({
          type: ActionTypes.GET_ORDERING_CHECKOUT_FEE_LIST_SUCCESS,
          data: {
            serviceFees: chargesResponse.data.ordering_service_charges,
            orderingSites: sitesResponse.data.ordering_sites,
          },
        })
      })
      .catch(error => {
        dispatch(GlobalActions.showErrorMessage(`Unable to fetch Service Fees. ${error}`))
      })
  }

export const removeOrderingCharge =
  ({ venueId, serviceChargeId }) =>
  dispatch =>
    deleteOrderingChargeById({ venueId, serviceChargeId }).then(
      () => {
        dispatch(GlobalActions.showSuccessMessage('Successfully deleted Service Fee'))
      },
      error => {
        dispatch(GlobalActions.showErrorMessage(`Unable to delete Service Fee. ${error}`))
        return error
      }
    )

export const addNewOrderingCheckoutFee =
  ({ venueId, serviceCharge }) =>
  dispatch =>
    postNewOrderingCharge({ venueId, serviceCharge }).then(
      () => {
        dispatch(GlobalActions.showSuccessMessage('Successfully created Service Fee'))
      },
      error => {
        dispatch(GlobalActions.showErrorMessage(`Unable to create Service Fee. ${error}`))
        return error
      }
    )

export const updateOrderingCheckoutFee =
  ({ venueId, serviceCharge, serviceChargeId }) =>
  dispatch =>
    putOrderingCharge({ venueId, serviceCharge, serviceChargeId }).then(
      () => {
        dispatch(GlobalActions.showSuccessMessage('Successfully updated Service Fee'))
      },
      error => {
        dispatch(GlobalActions.showErrorMessage(`Unable to update Service Fee. ${error}`))
        return error
      }
    )
