import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import { fetchOutgoingEmails } from 'mgr/lib/services/OutgoingEmailServices'
import { convertFilters } from 'mgr/pages/single-venue/marketing/utils/data'
import * as ActionTypes from 'mgr/pages/single-venue/reporting/actions/ActionTypes'
import { INIT } from 'mgr/pages/single-venue/search/actions/ActionTypes'

export const tryLoadOutgoingEmails = venue => (dispatch, getState) => {
  const state = getState()
  const venue_timezone = state.appState.venue.timezone

  dispatch({
    type: ActionTypes.LOAD_OUTGOING_EMAILS_START,
  })

  dispatch({
    type: INIT,
    venueKey: state.appState.venue.urlKey,
  })

  const filters = convertFilters(state.outgoingEmails.filters)

  return fetchOutgoingEmails({
    venue_id: venue.id,
    venue_timezone,
    ...filters,
  })
    .then(response =>
      dispatch({
        type: ActionTypes.LOAD_OUTGOING_EMAILS_SUCCESS,
        results: response.data.results,
        total: response.data.total,
      })
    )
    .catch(e => {
      console.error('An error occurred fetching outgoing emails', e)
      dispatch({
        type: ActionTypes.LOAD_OUTGOING_EMAILS_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Fetching outgoing emails failed, please try again or contact support'))

      return e
    })
}

export const onChangeVenue = venue => dispatch => {
  dispatch({
    type: CHANGE_VENUE,
    venue,
  })
}

export const openOutgoingEmail = data => dispatch => {
  const email = data ? data.original : null
  dispatch({
    type: ActionTypes.OPEN_OUTGOING_EMAIL,
    selected: email,
  })
}

export const onRowClick = rowData => dispatch => {
  dispatch(openOutgoingEmail(rowData))
}

export const startLoading = () => dispatch => dispatch({ type: ActionTypes.LOAD_OUTGOING_EMAILS_START })
