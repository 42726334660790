/* eslint consistent-return: 0 no-param-reassign: 0 */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { VenueGroupSubNavSections } from 'mgr/pages/shared/utils/Constants'
import { SlideoutContent } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import { onColorSwatchClick } from 'mgr/pages/venue-group/actions/Pods'
import {
  openAddTagSlideout,
  changeTagCategoryName,
  toggleCheckbox,
  toggleDeleteWarning,
  setRadioButtonVal,
  updateTagCategoryFollowers,
  updateTagCategoryPods,
  appendNewTag,
  changeTagName,
  deleteTag,
  updateTagSlideoutErrors,
  tryAddTagCategory,
  tryEditTagCategory,
} from 'mgr/pages/venue-group/actions/Tags'
import TagSlideout from 'mgr/pages/venue-group/containers/tags/TagSlideout'
import { WarningMessage } from 'mgr/pages/venue-group/containers/tags/WarningMessage'
import { VmsIcons } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import EditDeleteTextInput from 'svr/component-lib/Generic/TextInputs/EditDeleteTextInput'
import NewItemTextInput from 'svr/component-lib/Generic/TextInputs/NewItemTextInput'
import DropdownMenu from 'svr/component-lib/Manager/Menus/DropdownMenu'
import TagDropdownMenu from 'svr/component-lib/Manager/Menus/TagDropdownMenu'
import Button from 'svr/lib/Buttons/Button'
import ColorSwatch from 'svr/lib/ColorPicker/ColorSwatch'
import { hasDuplicates, TagDuplicateWarningModal } from 'mgr/pages/venue-group/containers/tags/TagDuplicateWarningModal'
// eslint-disable-next-line import/no-cycle

const DisplayArea = styled.div`
  background: #ffffff;
  box-shadow: ${props => props.theme.shadow.six};
  height: 100%;
  padding-bottom: 10px;
`
const SlideoutSection = styled.div``
const SlideoutSectionWrapper = styled.div``
const SlideoutSectionHr = styled.hr`
  border-top: 1px solid #e8e9ea;
  margin: 0 20px;
`
const SlideoutBody = styled.div`
  height: calc(100% - 109px);
  overflow-y: scroll;
`
const SlideoutButtonContainer = styled.div``
const SlideoutButtonTitle = styled.div`
  font-size: 13px;
`
const SlideoutButton = styled.div`
  display: inline-block;
  text-align: center;
  background-color: ${props => (props.isDeleteBtn ? props.theme.color.errorAccent : '#347baf')};
  color: ${props => props.theme.color.white};
  padding: 12px 28px;
  border-radius: 5px;
  margin-right: 20px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => props.disabled && 0.5};
  pointer-events: ${props => props.disabled && 'none'};
`
const SlideoutFooter = styled.div`
  position: fixed;
  text-align: right;
  bottom: 0;
  width: 100%;
  max-width: 498px;
  padding: 25px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.color.white};
`

const SlideoutLabel = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  color: ${props => props.theme.color.grey};
  font-weight: bold;
  padding: 20px 0 0 20px;
`

const SlideoutSubLabel = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.color.grey};
  padding: 20px 0 0 20px;
`

const SlideoutSubSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 0 20px;
`

const VmsCheckboxWrapper = styled.div`
  margin: 19px 20px;
`

const SlideoutLabelActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`

const TagsContainer = styled.div``
const TagInputWrapper = styled.div``
const InputField = styled.div`
  border: 1px solid ${props => (props.isInvalid ? '#E95757' : '#e6e8eb')};
  border-radius: 5px;
  display: inline-block;
  width: 329px;
  height: 100%;
`

const NewItemTextInputWrapper = styled.div`
  padding: 10px 20px;
`

const ColorPickerContainer = styled.div`
  display: inline-block;
  width: 100px;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0 20px;
`

const TagDropdownMenuWrapper = styled.div`
  padding: 20px;
  display: ${props => (props.hide ? 'none' : 'block')};
`

class ShowTags extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: this.props.formErrors,
      disableBtn: false,
      showDuplicateWarning: false,
    }
    this.appendTagHandler = this.appendTagHandler.bind(this)
    this.enableActionBtn = this.enableActionBtn.bind(this)
    this.determineIfTagsValid = this.determineIfTagsValid.bind(this)
    this.tagNameChangeHandler = this.tagNameChangeHandler.bind(this)
    this.tagCategoryCanShowCustom = this.tagCategoryCanShowCustom.bind(this)
    this.deleteTagHandler = this.deleteTagHandler.bind(this)
    this.isValidTagCategorySubmission = this.isValidTagCategorySubmission.bind(this)
    this.actionButtonHandler = this.actionButtonHandler.bind(this)
    this.tagDuplicateWarningModalIsActive = this.tagDuplicateWarningModalIsActive.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    window.document.body.addEventListener('keydown', event => {
      if (event.keyCode === 27) {
        this.props.openAddTagSlideout(false, null, '', [])
      }
    })
  }

  componentDidUpdate() {
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ formErrors: this.props.formErrors })
  }

  onSubmit() {
    this.tagDuplicateWarningModalIsActive(false)

    this.setState({ disableBtn: true })
    if (this.props.isNewTagCategory) {
      this.props.tryAddTagCategory(this.enableActionBtn)
    } else {
      this.props.tryEditTagCategory(this.enableActionBtn)
    }
  }

  getDuplicateTagKeys(activeTags) {
    const tagsGroupedByNames = _.groupBy(activeTags, tag => tag.updatedName.toLowerCase().trim())
    const duplicateTags = _.flatten(_.filter(tagsGroupedByNames, n => n.length > 1))
    const duplicateTagKeys = _.map(duplicateTags, tag => {
      if (tag.updatedName !== '') {
        return tag.tagKeyOrder
      }
    })
    return _.without(duplicateTagKeys, undefined)
  }

  determineIfTagCategoryValid(formErrors) {
    if (this.props.tagCategoryName.trim() === '' && (this.props.tagCategoryNameDisplay || '').trim() === '') {
      formErrors.tagCategoryName = true
    }
  }

  determineIfTagsValid(tags, formErrors) {
    const activeTags = _.filter(tags, tag => !tag.isDeleted)
    const nonEmptyTags = _.filter(activeTags, tag => tag.updatedName.trim() !== '')
    const allTagsDeleted = activeTags.length === 0
    const allTagsNoName = nonEmptyTags.length === 0

    let displayReqTagError = true
    tags.forEach(tag => {
      // First active tag that has no name where all other active tags have no name as well
      // OR existing active tag with no name
      if (tag.updatedName.trim() === '' && !tag.isDeleted && (allTagsNoName || tag.id !== undefined)) {
        formErrors[tag.tagKeyOrder] = true
        displayReqTagError = false
      } else {
        delete formErrors[tag.tagKeyOrder]
      }
    })
    if (displayReqTagError && (allTagsDeleted || allTagsNoName)) {
      formErrors[tags[0].tagKeyOrder] = true
    } else if (!allTagsNoName && tags[0].id !== undefined && tags[0].updatedName.trim() !== '') {
      delete formErrors[tags[0].tagKeyOrder]
    }
    const duplicateTagKeys = this.getDuplicateTagKeys(activeTags)
    if (duplicateTagKeys.length) {
      duplicateTagKeys.forEach(tagKey => {
        formErrors[tagKey] = true
      })
    }
  }

  isValidTagCategorySubmission() {
    const formErrors = {}
    this.determineIfTagCategoryValid(formErrors)
    this.determineIfTagsValid(this.props.tagCategoryTags, formErrors)
    this.props.updateTagSlideoutErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  appendTagHandler(text) {
    this.props.appendNewTag(this.determineIfTagsValid, text)
  }

  tagNameChangeHandler(val, idx, isActive = true) {
    this.props.changeTagName(val, idx, isActive, this.determineIfTagsValid)
  }

  tagCategoryCanShowCustom() {
    return ['Dietary restrictions', 'Special Occasions'].includes(this.props.tagCategoryName) && this.props.tagCategoryIsDefault
  }

  deleteTagHandler(idx) {
    this.props.deleteTag(idx, this.determineIfTagsValid)
  }

  enableActionBtn() {
    this.setState({ disableBtn: false })
  }

  tagDuplicateWarningModalIsActive(status) {
    this.setState({ showDuplicateWarning: status })
  }

  actionButtonHandler() {
    const {
      tagCategoryName,
      tagCategoryNameDisplay,
      tagCategoryTags,
      tagCategoryTagNameDisplays,
      tagCategories,
      tagCategoryClassification,
      tagCategoryPods,
    } = this.props
    if (this.isValidTagCategorySubmission()) {
      if (
        hasDuplicates({
          tagCategory: {
            tags: tagCategoryTags,
            name: tagCategoryName,
            name_display: tagCategoryNameDisplay,
            tag_name_displays: tagCategoryTagNameDisplays,
            is_pod_tag: tagCategoryClassification === 'POD',
            pod_ids: tagCategoryPods?.map(tag => tag.value) ?? [],
          },
          tagCategories,
        })
      ) {
        this.tagDuplicateWarningModalIsActive(true)
      } else {
        this.onSubmit()
      }
    }
  }

  renderAddDefaultTag() {
    if (!this.props.tagCategoryDisableNewTags) {
      return
    }

    const choices = []
    const alreadyHaveTags = _.map(this.props.tagCategoryTags, 'name')
    _.each(this.props.tagCategoryDefaultOptionalTags, tag => {
      if (!_.includes(alreadyHaveTags, tag)) {
        choices.push({
          isSelected: false,
          value: tag,
          name: tag,
          hexColor: 'black',
        })
      }
    })

    return (
      <TagDropdownMenuWrapper hide>
        <DropdownMenu
          key="add-tag-from-default"
          choices={choices}
          placeholder="Select Tags"
          isLightTheme
          searchingEnabled
          style={{
            width: '100%',
          }}
          isFullSizeMenu
          onChangeHandler={choices => {
            this.appendTagHandler()
            this.tagNameChangeHandler(choices[0].value, 0, false)
          }}
        />
      </TagDropdownMenuWrapper>
    )
  }

  renderClassification() {
    const {
      isClientTagPage,
      tagCategoryIsAutotag,
      tagCategoryClassification,
      tagCategoryPods,
      podCategoryTagsByCategory,
      userCanAdministrateGlobalReservationTags,
      userCanAdministrateGlobalClientTags,
      userCanAdministratePodReservationTags,
      userCanAdministratePodClientTags,
    } = this.props

    const tagCategoryClassificationList = []
    if ((isClientTagPage && userCanAdministrateGlobalClientTags) || (!isClientTagPage && userCanAdministrateGlobalReservationTags)) {
      tagCategoryClassificationList.push({ value: 'GLOBAL', label: 'Global (used by all locations)' })
    }
    if ((isClientTagPage && userCanAdministratePodClientTags) || (!isClientTagPage && userCanAdministratePodReservationTags)) {
      tagCategoryClassificationList.push({ value: 'POD', label: 'Pod (used by select locations)' })
    }

    return (
      !tagCategoryIsAutotag && (
        <SlideoutSection>
          <SlideoutSubLabel>Classification</SlideoutSubLabel>
          <SlideoutSubSection>
            <RadioList
              field="selectedTagCategoryClassification"
              selectedValue={this.props.tagCategoryClassification}
              options={tagCategoryClassificationList}
              onClick={this.props.setRadioButtonVal}
            />
          </SlideoutSubSection>
          <TagDropdownMenuWrapper hide={tagCategoryClassification !== 'POD'}>
            <TagDropdownMenu
              id="tag-category-pods"
              selectedValues={tagCategoryPods}
              nestedChoices={podCategoryTagsByCategory}
              placeholder="Select Pods"
              selectedIcon={VmsIcons.ClearLine}
              selectedChoiceIcon={VmsIcons.Checked}
              unselectedIcon={VmsIcons.OvalLine}
              iconTagHeaderIcon={VmsIcons.SiteMap}
              onNestedSelectTagClickHandler={this.props.updateTagCategoryPods}
              onNestedChoiceTagClickHandler={this.props.updateTagCategoryPods}
            />
          </TagDropdownMenuWrapper>
        </SlideoutSection>
      )
    )
  }

  renderTagsBlock() {
    const { tagCategoryDefaultOptionalTags, tagCategoryTags } = this.props
    return (
      !this.props.tagCategoryIsAutotag && (
        <SlideoutSection>
          <SlideoutSectionHr />
          <SlideoutLabelActionContainer>
            <SlideoutLabel>Tags</SlideoutLabel>
          </SlideoutLabelActionContainer>
          <TagsContainer>
            <NewItemTextInputWrapper>
              <NewItemTextInput onConfirm={this.appendTagHandler} buttonText="Add Tag" />
            </NewItemTextInputWrapper>
            {this.renderAddDefaultTag()}
            {_.map(tagCategoryTags, (tag, idx) => (
              <TagInputWrapper key={`tag-wrapper-${tag.tagKeyOrder}`}>
                <EditDeleteTextInput
                  idx={idx}
                  key={`sr-tag-field-${tag.tagKeyOrder}`}
                  uniqueId={tag.id}
                  className="sr-tag"
                  value={tag.updatedName}
                  placeHolder="type input here"
                  isValid={!this.state.formErrors[tag.tagKeyOrder]}
                  isDeleted={tag.isDeleted}
                  onChange={this.tagNameChangeHandler}
                  deleteHandler={this.deleteTagHandler}
                  disabled={_.includes(tagCategoryDefaultOptionalTags, tag.updatedName)}
                />
              </TagInputWrapper>
            ))}
          </TagsContainer>
        </SlideoutSection>
      )
    )
  }

  render() {
    const {
      isNewTagCategory,
      tagCategoryName,
      tagCategoryNameDisplay,
      tagCategoryIsDefault,
      tagCategoryHexColor,
      tagCategoryVip,
      tagCategorySuperuser,
      tagCategoryRestricted,
      tagCategoryShowChit,
      tagCategoryResSummary,
      tagCategoryShowCustom,
      tagCategoryFollowers,
      followers,
      tagCategoryTags,
      tagCategoryTagNameDisplays,
      tagCategories,
      tagCategoryClassification,
      tagCategoryPods,
    } = this.props
    const title = `${isNewTagCategory ? 'CREATE NEW' : 'EDIT'} TAG CATEGORY`
    const submitButtonText = isNewTagCategory ? 'Create' : 'Update'
    const showDeleteWarning = false
    const tagCategory = {
      tags: tagCategoryTags,
      name: tagCategoryNameDisplay,
      tag_name_displays: tagCategoryTagNameDisplays,
      is_pod_tag: tagCategoryClassification === 'POD',
      pod_ids: tagCategoryPods?.map(tag => tag.value) ?? [],
    }

    return (
      <TagSlideout title={title}>
        <TagDuplicateWarningModal
          isActive={this.state.showDuplicateWarning}
          setIsActive={this.tagDuplicateWarningModalIsActive}
          onSubmit={this.onSubmit}
          tagCategory={tagCategory}
          tagCategories={tagCategories}
          tags={tagCategoryTags}
        />
        <SlideoutContent>
          <DisplayArea>
            <SlideoutBody id="sr-tags-slideout-main">
              <SlideoutSection>
                <SlideoutLabel>Settings</SlideoutLabel>
                <FlexRow>
                  <InputField>
                    <ActiveLabelTextInput
                      field="tag-category-name"
                      value={tagCategoryNameDisplay || tagCategoryName}
                      placeholderLabel="Category Name"
                      isValid={!this.state.formErrors.tagCategoryName}
                      style={{ width: '68%' }}
                      onChange={this.props.changeTagCategoryName}
                      disabled={tagCategoryIsDefault}
                    />
                  </InputField>
                  <ColorPickerContainer>
                    <ColorSwatch
                      id="sr-colorswatch-input"
                      name="color"
                      color={tagCategoryHexColor}
                      onColorSwatchClick={this.props.onColorSwatchClick}
                    />
                  </ColorPickerContainer>
                </FlexRow>
              </SlideoutSection>
              {!tagCategoryIsDefault && this.renderClassification()}
              <SlideoutSection>
                <SlideoutSubLabel>Do you want to treat all tags in this category as a VIP tag?</SlideoutSubLabel>
                <SlideoutSubSection>
                  <RadioList
                    field="selectedTagCategoryVip"
                    selectedValue={tagCategoryVip}
                    options={[
                      { value: 1, label: 'Yes' },
                      { value: 0, label: 'No' },
                    ]}
                    onClick={this.props.setRadioButtonVal}
                  />
                </SlideoutSubSection>
              </SlideoutSection>
              <SlideoutSection>
                <SlideoutSubLabel>Display</SlideoutSubLabel>
                <VmsCheckboxWrapper>
                  <VmsCheckbox
                    field="selectedTagCategorySuperuser"
                    onClick={this.props.toggleCheckbox}
                    isSelected={!!tagCategorySuperuser}
                    label="Visible to Superusers only (not recommended)"
                    tooltipText="If selected, tags within this category can only be viewed on profiles by users with Superuser permissions"
                    tooltipWidth="200px"
                    selectedIcon={VmsIcons.CheckBoxSelected}
                    unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                    tooltipIcon={VmsIcons.Info}
                  />
                </VmsCheckboxWrapper>
                {this.props.isClientTagPage && (
                  <VmsCheckboxWrapper>
                    <VmsCheckbox
                      field="selectedTagCategoryRestricted"
                      onClick={this.props.toggleCheckbox}
                      isSelected={!!tagCategoryRestricted}
                      label="Restricted Tag Category"
                      tooltipText="If selected, tags within this category can only be applied to profiles by users with Restricted Tag Category user permission"
                      selectedIcon={VmsIcons.CheckBoxSelected}
                      unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                      tooltipIcon={VmsIcons.Info}
                    />
                  </VmsCheckboxWrapper>
                )}
                <VmsCheckboxWrapper>
                  <VmsCheckbox
                    field="selectedTagCategoryShowChit"
                    onClick={this.props.toggleCheckbox}
                    isSelected={!!tagCategoryShowChit}
                    label="Show on chit"
                    tooltipText="Control whether or not tags within this category are printed on the reservation chit"
                    selectedIcon={VmsIcons.CheckBoxSelected}
                    unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                    tooltipIcon={VmsIcons.Info}
                  />
                </VmsCheckboxWrapper>
                <VmsCheckboxWrapper>
                  <VmsCheckbox
                    field="selectedTagCategoryResSummary"
                    onClick={this.props.toggleCheckbox}
                    isSelected={!!tagCategoryResSummary}
                    label="Show on reservation summary"
                    tooltipText="Control whether or not tags within this category are seen on emailed and printed reservation summaries"
                    selectedIcon={VmsIcons.CheckBoxSelected}
                    unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                    tooltipIcon={VmsIcons.Info}
                  />
                </VmsCheckboxWrapper>
                {this.tagCategoryCanShowCustom() && (
                  <VmsCheckboxWrapper>
                    <VmsCheckbox
                      field="selectedTagCategoryShowCustom"
                      onClick={this.props.toggleCheckbox}
                      isSelected={!!tagCategoryShowCustom}
                      label="Show custom tags to guests"
                      tooltipText="Controls whether custom tags added to this category show to guests"
                      selectedIcon={VmsIcons.CheckBoxSelected}
                      unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                      tooltipIcon={VmsIcons.Info}
                    />
                  </VmsCheckboxWrapper>
                )}
              </SlideoutSection>
              <SlideoutSectionWrapper>
                <SlideoutSectionHr />
                <SlideoutSection>
                  <SlideoutLabel>Add Followers</SlideoutLabel>
                  <TagDropdownMenuWrapper>
                    <TagDropdownMenu
                      id="tag-category-followers"
                      selectedValues={tagCategoryFollowers}
                      choices={followers}
                      placeholder="Select Followers"
                      selectedIcon={VmsIcons.ClearLine}
                      selectedChoiceIcon={VmsIcons.Checked}
                      unselectedIcon={VmsIcons.OvalLine}
                      onSelectedTagClickHandler={this.props.updateTagCategoryFollowers}
                      onChoiceTagClickHandler={this.props.updateTagCategoryFollowers}
                    />
                  </TagDropdownMenuWrapper>
                </SlideoutSection>
              </SlideoutSectionWrapper>
              {this.renderTagsBlock()}
            </SlideoutBody>
            <SlideoutFooter>
              {!tagCategoryIsDefault && !isNewTagCategory && (
                <SlideoutButton id="sr-tagcategory-delete-btn-wrapper" isDeleteBtn onClick={this.props.toggleDeleteWarning}>
                  <Button
                    buttonId="sr-tagcategory-delete-btn"
                    onClick={this.props.toggleDeleteWarning}
                    disabled={false}
                    innerElement={
                      <SlideoutButtonContainer>
                        <SlideoutButtonTitle>Delete</SlideoutButtonTitle>
                      </SlideoutButtonContainer>
                    }
                  />
                </SlideoutButton>
              )}
              <SlideoutButton
                id="sr-tagcategory-action-btn-wrapper"
                isDeleteBtn={false}
                onClick={this.actionButtonHandler}
                disabled={this.state.disableBtn}
              >
                <Button
                  buttonId="sr-tagcategory-action-btn"
                  onClick={this.actionButtonHandler}
                  disabled={this.state.disableBtn}
                  innerElement={
                    <SlideoutButtonContainer>
                      <SlideoutButtonTitle>{submitButtonText}</SlideoutButtonTitle>
                    </SlideoutButtonContainer>
                  }
                />
              </SlideoutButton>
            </SlideoutFooter>
          </DisplayArea>
        </SlideoutContent>
        {showDeleteWarning && <WarningMessage isDelete={false} proceed={() => {}} cancel={() => {}} />}
      </TagSlideout>
    )
  }
}

const mapStateToProps = state => {
  const defaultClassification =
    state.appState.userDomain.f_administrate_global_reservation_tags || state.appState.userDomain.f_administrate_global_client_tags
      ? 'GLOBAL'
      : 'POD'
  const tagCategoryClassification = state.tags.selectedTagCategoryClassification || defaultClassification

  return {
    isNewTagCategory: state.tags.isNewTagCategory,
    tagCategoryName: state.tags.selectedTagCategoryName,
    tagCategoryNameDisplay: state.tags.selectedTagCategoryNameDisplay,
    tagCategoryIsDefault: state.tags.selectedTagCategoryIsDefault,
    tagCategoryIsAutotag: state.tags.selectedTagCategoryIsAutotag,
    tagCategoryDefaultOptionalTags: state.tags.selectedTagCategoryDefaultOptionalTags,
    tagCategoryDisableNewTags: state.tags.selectedTagCategoryDisableNewTags,
    tagCategoryHexColor: state.tags.selectedTagCategoryHexColor,
    tagCategoryClassification,
    tagCategoryVip: state.tags.selectedTagCategoryVip,
    tagCategorySuperuser: state.tags.selectedTagCategorySuperuser,
    tagCategoryRestricted: state.tags.selectedTagCategoryRestricted,
    tagCategoryShowChit: state.tags.selectedTagCategoryShowChit,
    tagCategoryResSummary: state.tags.selectedTagCategoryResSummary,
    tagCategoryShowCustom: state.tags.selectedTagCategoryShowCustom,
    tagCategoryFollowers: state.tags.selectedTagCategoryFollowers,
    tagCategoryTags: state.tags.selectedTagCategoryTags,
    tagCategoryTagNameDisplays: state.tags.selectedTagCategoryTagNameDisplays,
    tagCategoryPods: state.tags.selectedTagCategoryPods,
    followers: state.tags.followers,
    formErrors: state.tags.formErrors,
    isClientTagPage: state.navState.subNavSection === VenueGroupSubNavSections.CLIENT_TAGS,
    podCategoryTagsByCategory: state.tags.podCategoryTagsByCategory,
    userCanAdministrateGlobalReservationTags: state.appState.userDomain.f_administrate_global_reservation_tags,
    userCanAdministrateGlobalClientTags: state.appState.userDomain.f_administrate_global_client_tags,
    userCanAdministratePodReservationTags: state.appState.userDomain.f_administrate_pod_reservation_tags,
    userCanAdministratePodClientTags: state.appState.userDomain.f_administrate_pod_client_tags,
    tagCategories: state.tags.tagCategories,
  }
}

const mapDispatchToProps = {
  openAddTagSlideout,
  changeTagCategoryName,
  onColorSwatchClick,
  toggleCheckbox,
  toggleDeleteWarning,
  setRadioButtonVal,
  updateTagCategoryFollowers,
  updateTagCategoryPods,
  appendNewTag,
  changeTagName,
  deleteTag,
  updateTagSlideoutErrors,
  tryAddTagCategory,
  tryEditTagCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowTags)
