import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, VStack, SectionHeader } from '@sevenrooms/core/ui-kit/layout'
import { NavigationDashboard } from '../../components/NavigationDashboard'
import { useGroupNavigationDashboardItems } from '../../hooks/useGroupNavigationItems'
import { useNavigationDashboardItems } from '../../hooks/useNavigationItems'
import { reportingMessages } from '../../locales/reportingMessages'

export function ReportingDashboard({ isGroup }: { isGroup?: boolean }) {
  const { formatMessage } = useLocales()
  const groupItems = useGroupNavigationDashboardItems()
  const venueItems = useNavigationDashboardItems()
  const navigationItems = isGroup ? groupItems : venueItems
  const type = isGroup ? 'group' : 'venue'

  return (
    <Box backgroundColor="margin" height="100%">
      <Box ml="l" mr="s">
        <VStack>
          <Box mt="l" mb="m">
            <SectionHeader
              title={formatMessage(reportingMessages.reportingTitle)}
              description={formatMessage(reportingMessages.reportingDescription)}
              type="h1"
            />
          </Box>
          <NavigationDashboard
            items={navigationItems}
            searchPlaceholder={formatMessage(reportingMessages.reportingSearchPlaceholder)}
            type={type}
          />
        </VStack>
      </Box>
    </Box>
  )
}
