import _ from 'lodash'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import RadioLine from 'svr/component-lib/Generic/RadioButtons/RadioLine'
import Caret from 'svr/component-lib/Generic/Symbols/Caret'

const OuterBody = styled.div`
  width: 100%;
  min-width: 154px;
  min-height: ${props => props.height}px;
  text-align: ${props => props.textAlign};
  ${props => props.theme.clearFix};
  ${props => props.style};
`

const BorderedArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: ${props => props.height + 2}px;
  height: ${props => props.height + 2}px;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border: ${props => (props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark' ? 2 : 1)}px solid
    ${props =>
      props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
        ? props.customStyle.colorLines
        : props.borderColor};
  border-radius: 4px;
  background-color: ${props =>
    props.disabled
      ? props.theme.color.white
      : props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
      ? props.customStyle.colorCellBackground
      : props.theme.background};
  box-shadow: ${props => (props.isOpen ? '0 2px 4px 0 rgba(0,0,0,0.5)' : 'none')};
  :hover {
    border-color: ${props =>
      props.disabled ? (props.customStyle ? props.customStyle.colorLines : props.borderColor) : props.hoverBorderColor};
    transition: border-color 0.2s ease-out;
  }
  ${props => props.theme.clearFix};
`

// Fix for #3 here: https://github.com/philipwalton/flexbugs
// Since BorderedArea has min-height: 44px
const BorderedAreaFlexIEFix = styled.div`
  order: 0;
  width: 0;
  height: ${props => props.height + 2}px;
`

const GroupContainer = styled.div`
  padding: ${props => props.theme.padding.medium};
  border-bottom: ${props => (props.isLast ? '0px' : '1px')} solid #dedede;
`
const GroupLabelWrapper = styled.div`
  padding-bottom: ${props => props.theme.padding.small};
`
const GroupLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  color: #6a6a6a;
  padding-left: ${props => props.theme.padding.small};
`
const GroupDisplayWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`
const GroupDisplayText = styled.span`
  padding-left: ${props => props.theme.padding.small};
`
const CommaText = styled.span`
  padding-right: ${props => props.theme.padding.small};
`
const CheckboxWrapper = styled.div`
  padding-top: ${props => (props.idx !== 0 ? props.theme.padding.small : '0px')};
`
const NameInside = styled.div`
  position: absolute;
  top: 3px;
  left: 10px;
  right: 3px;
  color: ${props => props.theme.darkGrey};
  font-weight: 500;
  font-size: ${props => (props.isLightTheme ? '11px' : '12px')};
`
const SelectedItemDisplay = styled.div`
  order: 1;
  flex-grow: 1;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  margin-top: ${props => (props.useOutsideLabel || props.noHeader ? '0' : '15px')};
  color: ${props =>
    props.hasSelectedValues && !props.disabled
      ? props.customStyle
        ? props.customStyle.fontsColorPrimary
        : props.theme.navigationDark
      : props.theme.darkGrey};
  ${props => props.theme.ellipsis};
`
const Icon = styled(StyledVmsIconXS)`
  pointer-events: none;
  display: inline-block;
  color: #6a6a6a;
`

const DropdownRelative = styled.div`
  position: relative;
  top: 2px;
  right: ${props => (props.textAlign === 'right' ? '0' : 'auto')};
`

const DropdownAbsolute = styled.div`
  position: absolute;
  width: 100%;
  right: ${props => (props.textAlign === 'right' ? '0' : 'auto')};
`

const DropUpAbsolute = styled(DropdownAbsolute)`
  bottom: 0;
`

const OptionsContainer = styled.div`
  position: relative;
  border: 1px solid ${props => (props.customStyle ? props.customStyle.colorLines : props.borderColor)};
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  left: 0;
  width: 100%;
  max-height: 332px;
  overflow-y: auto;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
  z-index: 501;
  border-radius: 0 0 4px 4px;
`

const DropDown = styled.div`
  width: 250px;
  top: 5px;
  left: -1px;
  position: relative;
  z-index: 501;
`

export class GroupDropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleKeyPressOption = (event, choice) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.handleItemClick(event, choice)
    }
  }

  closeDropdown = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      if (this.props.onDropdownCloseHandler) {
        this.props.onDropdownCloseHandler()
      }
    }
  }

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      if (this.props.onDropdownCloseHandler) {
        this.props.onDropdownCloseHandler()
      }
    }
  }

  handleToggleDropdownClick = () => {
    const { disabled } = this.props
    if (disabled) {
      return
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleItemClick = (e, choice) => {
    const { onChangeHandler, disabled } = this.props
    e.stopPropagation()
    if (disabled) {
      return
    }

    if (choice.hasOwnProperty('selectValue')) {
      onChangeHandler(choice.selectValue)
    } else {
      onChangeHandler(choice.value)
    }
    this.setState({ isOpen: false })
  }

  render() {
    const {
      testId,
      showCaret,
      name,
      values,
      dropDown,
      isLightTheme,
      placeholder,
      isDropUp,
      style,
      borderAreaStyle,
      disabled,
      optionsContainerStyle,
      height,
      id,
      labelStyles,
      ariaDescriptor,
      customStyle,
      useOutsideLabel,
      noHeader,
      dontShowSelectedItem,
      selectedRadioValue,
    } = this.props
    const { isOpen } = this.state
    const hoverBorderColor = isLightTheme ? Theme.darkGrey : Theme.black
    const borderColor = isOpen ? hoverBorderColor : isLightTheme ? Theme.lightGrey : '#031927'

    const selectedItemNames = []
    const selectedItemNamesByIcon = new Map()

    const renderSingleOption = (choice, idx, isSelectedChoice) => (
      <CheckboxWrapper key={`${choice.value}-group-${idx}`} idx={idx}>
        <VmsCheckbox
          key={`${choice.value}-${idx}`}
          field={choice.value}
          onClick={this.props.onChangeHandler}
          isSelected={isSelectedChoice}
          isDisabled={choice.disabled}
          label={choice.name}
          labelStyles={labelStyles}
          selectedIcon={VmsIcons.CheckBoxSelected}
          unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
        />
      </CheckboxWrapper>
    )

    const renderOptions = groups =>
      groups.map((group, idx) => {
        const isLast = idx === groups.length - 1
        const groupChoices = group.choices.map((choice, idx) => {
          const isSelectedChoice = values.includes(choice.value)
          if (isSelectedChoice) {
            selectedItemNames.push(choice.name)
            if (!selectedItemNamesByIcon.get(group.icon)) {
              selectedItemNamesByIcon.set(group.icon, [])
            }
            const selectedItems = selectedItemNamesByIcon.get(group.icon)
            selectedItems.push(choice.name)
            selectedItemNamesByIcon.set(group.icon, selectedItems)
          }
          return renderSingleOption(choice, idx, isSelectedChoice)
        })
        return (
          <GroupContainer isLast={isLast} key={`group-container-${idx}`}>
            {group.label && group.label !== '' && (
              <GroupLabelWrapper>
                <Icon>{group.icon}</Icon>
                <GroupLabel>{group.label}</GroupLabel>
              </GroupLabelWrapper>
            )}
            {groupChoices}
          </GroupContainer>
        )
      })

    const renderSingleRadioChoice = (choice, idx, isSelectedChoice) => (
      <CheckboxWrapper key={`${choice.value}-radio-group-${idx}`} idx={idx}>
        <RadioLine
          key={`${choice.value}-${idx}`}
          radioId={`sr-radio-line-${choice.value}`}
          label={choice.name}
          isSelected={isSelectedChoice}
          onClick={this.props.onRadioChangeHandler.bind(this, choice.name, choice.value)}
          customRadioIcon={this.props.customRadioIconStyles}
          customRadioLabel={this.props.customRadioLabelStyles}
        />
      </CheckboxWrapper>
    )

    const renderRadioChoices = radioChoices => {
      const singleRadioChoices = radioChoices.map((choice, idx) => {
        const isSelectedChoice = selectedRadioValue === choice.value
        return renderSingleRadioChoice(choice, idx, isSelectedChoice)
      })
      return <GroupContainer>{singleRadioChoices}</GroupContainer>
    }

    const renderedRadioChoices = this.props.radioChoices ? renderRadioChoices(this.props.radioChoices) : []
    const renderedOptions = renderOptions(this.props.groups)
    if (_.isEmpty(selectedItemNames)) {
      selectedItemNames.push('All')
    }
    const hasSelectedValues = true
    const selectedItemNamesFormatted = selectedItemNames.join(', ')
    const optionsContainer = (
      <OptionsContainer
        id="optionsContainer"
        data-test="sr-picker_options"
        key="optionsContainer"
        style={optionsContainerStyle}
        ariaExpanded={isOpen}
        role="list"
        {...{ borderColor, customStyle }}
      >
        {renderedRadioChoices}
        {renderedOptions}
      </OptionsContainer>
    )

    const DropContainer = isDropUp ? DropUpAbsolute : DropdownAbsolute
    const selectedItemsDisplay = []
    selectedItemNamesByIcon.forEach((values, groupIcon) => {
      selectedItemsDisplay.push(
        <GroupDisplayWrapper>
          <Icon>{groupIcon}</Icon>
          <GroupDisplayText>{values.join(', ')}</GroupDisplayText>
        </GroupDisplayWrapper>
      )
      selectedItemsDisplay.push(<CommaText>,</CommaText>)
    })
    selectedItemsDisplay.pop()

    return (
      <OuterBody
        data-test={testId || (name ? `sr-group-dropdown-${name.toLowerCase().replace(/ /g, '_')}` : null)}
        aria-label={ariaDescriptor}
        onKeyDown={this.handleKeyPress}
        role="button"
        tabIndex="0"
        id={id}
        {...{ style, height }}
      >
        <BorderedArea
          {...{
            height,
            borderColor,
            hoverBorderColor,
            isOpen,
            disabled,
            customStyle,
          }}
          style={borderAreaStyle}
          onClick={this.handleToggleDropdownClick}
        >
          <BorderedAreaFlexIEFix {...{ height }} />
          {!useOutsideLabel && !noHeader && <NameInside {...{ isLightTheme }}>{name}</NameInside>}
          {!dontShowSelectedItem && (
            <SelectedItemDisplay
              {...{
                useOutsideLabel,
                noHeader,
                hasSelectedValues,
                disabled,
                customStyle,
              }}
            >
              {selectedItemNamesFormatted || placeholder}
            </SelectedItemDisplay>
          )}
          {showCaret && !disabled && <Caret {...{ customStyle }} direction="down" />}
        </BorderedArea>
        <DropdownRelative>
          <DropContainer>
            {isOpen && !dropDown && optionsContainer}
            {isOpen && dropDown && <DropDown>{dropDown}</DropDown>}
          </DropContainer>
        </DropdownRelative>
      </OuterBody>
    )
  }
}

GroupDropdownMenu.defaultProps = {
  groups: [],
  name: '',
  values: [],
  customRadioIconStyles: [],
  customRadioLabelStyles: [],
  validator: null,
  useOutsideLabel: false,
  placeholder: '',
  style: {},
  height: 42,
  optionsContainerStyle: {},
  borderAreaStyle: {},
  showCaret: true,
  dropDown: null,
  isLightTheme: false,
  disabled: false,
  noHeader: false,
  wrapText: true,
  dontShowSelectedItem: false,
  onChangeHandler: () => {},
  onRadioChangeHandler: () => {},
  onDropdownCloseHandler: () => {},
  id: '',
  ariaDescriptor: '',
}

GroupDropdownMenu.propTypes = {
  testId: React.PropTypes.string,
  selectedRadioValue: React.PropTypes.string,
  useOutsideLabel: React.PropTypes.bool,
  isDropUp: React.PropTypes.bool,
  values: React.PropTypes.array,
  placeholder: React.PropTypes.string.isRequired,
  groups: React.PropTypes.array.isRequired,
  radioChoices: React.PropTypes.array,
  dropDown: React.PropTypes.object,
  isLightTheme: React.PropTypes.bool.isRequired,
  showCaret: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  noHeader: React.PropTypes.bool.isRequired,
  wrapText: React.PropTypes.bool,
  dontShowSelectedItem: React.PropTypes.bool,
  onChangeHandler: React.PropTypes.func.isRequired,
  onRadioChangeHandler: React.PropTypes.func,
  onDropdownCloseHandler: React.PropTypes.func,
  id: React.PropTypes.string,
  ariaDescriptor: React.PropTypes.string,
  customStyle: React.PropTypes.object,
  customRadioIconStyles: React.PropTypes.array,
  customRadioLabelStyles: React.PropTypes.array,
}

GroupDropdownMenu = onClickOutside(GroupDropdownMenu)
export default GroupDropdownMenu
