import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { fetchVenuePods, createSaveUser, loadUser, lockUser, deleteUser, loadUsers } from 'mgr/lib/services/UserAccountServices'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'

export const onTextFieldChange = (field, val) => ({
  type: ActionTypes.USER_ACCOUNTS_TEXT_FIELD_CHANGE,
  field,
  val,
})

export const onUserOptionChange = val => ({
  type: ActionTypes.USER_ACCOUNTS_OPTIONS_CHANGE,
  val,
})

export const onGroupPermissionChange = values => ({
  type: ActionTypes.USER_ACCOUNTS_SET_GROUP_PERMISSION,
  values,
})

export const onPrimaryAccessChange = val => ({
  type: ActionTypes.USER_ACCOUNTS_PRIMARY_ACCESS_CHANGE,
  val,
})

export const onAdtlAccessChange = (idx, val) => ({
  type: ActionTypes.USER_ACCOUNTS_ADTL_ACCESS_CHANGE,
  idx,
  val,
})

export const onAccessRoleEntitiesChange = (roleId, val) => ({
  type: ActionTypes.USER_ACCOUNTS_ROLE_ENTITIES_CHANGE,
  roleId,
  val,
})

export const onAdtlAccessRoleEntitiesChange = (idx, val) => ({
  type: ActionTypes.USER_ACCOUNTS_ADTL_ACCESS_ROLE_ENTITIES_CHANGE,
  idx,
  val,
})

export const onDeleteAccess = idx => ({
  type: ActionTypes.USER_ACCOUNTS_DELETE_ACCESS,
  idx,
})

export const onDeletePrimaryAccess = () => ({
  type: ActionTypes.USER_ACCOUNTS_DELETE_PRIMARY_ACCESS,
})

/**
 * @typedef {object} UserFields
 * @property {boolean?} firstNameValid
 * @property {boolean?} lastNameValid
 * @property {boolean?} emailValid
 */

/**
 * Marks corresponding fields as valid/invalid
 * @param {UserFields} userFields
 */
export const invalidateUserFields = userFields => ({
  type: ActionTypes.INVALIDATE_USER_FIELDS,
  userFields,
})

export const addAdditionalAccess = () => ({
  type: ActionTypes.USER_ACCOUNTS_ADD_ADDITIONAL_ACCESS,
})

export const resetUserState = () => ({
  type: ActionTypes.RESET_USER_STATE,
})

export const tryLoadVenuePods = venueGroup => async (dispatch, getState) => {
  const { users } = getState()
  if (users.venues !== null && users.pods !== null) {
    return
  }
  dispatch({
    type: ActionTypes.LOAD_VENUE_PODS_START,
  })

  try {
    const response = await fetchVenuePods({ venue_group_id: venueGroup.id })
    dispatch({
      type: ActionTypes.LOAD_VENUE_PODS_SUCCESS,
      venues: response.data.venues,
      podCategoriesMapById: response.data.pod_categories_map_by_id,
      venueGroupAccess: response.data.venue_group_access,
      venueGroup,
    })
  } catch (e) {
    dispatch({
      type: ActionTypes.LOAD_VENUE_PODS_FAIL,
    })
    dispatch(GlobalActions.showErrorMessage('Fetching venues and pods failed, please try again or contact support'))

    throw e
  }
}

const getIntersectionOfAccessIds = (selectedEntityIds, comparisonEntityIds) => {
  const intersectionIds = _.map(selectedEntityIds, entityId => {
    if (comparisonEntityIds.includes(entityId)) {
      return entityId
    }
    return null
  })
  return _.compact(intersectionIds)
}

const formatVmsAccessUsers = (venueGroupId, users) => {
  const vmsAccessUsers = []
  const venueIds = _.map(users.venues, venue => venue.id)
  const podIds = users.pods.map(pod => pod.id)
  if (users.selectedUserPrimaryAccessRoleId) {
    vmsAccessUsers.push({
      id: users.selectedUserPrimaryAccessId,
      vms_role_template_id: users.selectedUserPrimaryAccessRoleId,
      venue_ids: getIntersectionOfAccessIds(users.selectedUserPrimaryAccessEntities, venueIds),
      pod_ids: getIntersectionOfAccessIds(users.selectedUserPrimaryAccessEntities, podIds),
      is_venue_group_level_user: users.selectedUserPrimaryAccessEntities.includes(venueGroupId),
      is_primary_access_user: true,
    })
  }
  users.selectedUserAdtlAccesses.forEach(access => {
    vmsAccessUsers.push({
      id: access.id,
      vms_role_template_id: access.roleId,
      venue_ids: getIntersectionOfAccessIds(access.accessEntities, venueIds),
      pod_ids: getIntersectionOfAccessIds(access.accessEntities, podIds),
      is_venue_group_level_user: access.accessEntities.includes(venueGroupId),
    })
  })
  return JSON.stringify(vmsAccessUsers)
}

export const getUserParams = (appState, users) => ({
  venue_group_id: appState.venueGroup.id,
  user_id: users.selectedUserId,
  first_name: users.selectedUserFirstName,
  last_name: users.selectedUserLastName,
  email: users.selectedUserEmail,
  f_user_admin: users.f_user_admin,
  f_role_admin: users.f_role_admin,
  f_administrate_pods: users.f_administrate_pods,
  f_administrate_global_reservation_tags: users.f_administrate_global_reservation_tags,
  f_administrate_global_client_tags: users.f_administrate_global_client_tags,
  f_administrate_pod_reservation_tags: users.f_administrate_pod_reservation_tags,
  f_administrate_pod_client_tags: users.f_administrate_pod_client_tags,
  f_administrate_venue_group_user: users.f_administrate_venue_group_user,
  f_administrate_venue_group_user_permission: users.f_administrate_venue_group_user_permission,
  has_access_group_reporting: users.has_access_group_reporting,
  vms_access_users: formatVmsAccessUsers(appState.venueGroup.id, users),
  is_mfa_enabled: users.selectedUser.isMfaEnabled,
  is_suspended: users.selectedUser.isSuspended,
  email_alerts_enabled: users.selectedUser.emailAlertsEnabled,
})

export const tryLoadUser = userId => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.TRY_LOAD_USER_START,
  })
  const { appState } = getState()
  const fetchUserParams = {
    user_id: userId,
    venue_group_id: appState.venueGroup.id,
  }
  return loadUser(fetchUserParams)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.TRY_LOAD_USER_SUCCESS,
        user_id: data.user_id,
        is_locked: data.is_locked,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        primary_vms_access_user_id: data.primary_vms_access_user_id,
        vms_access_users: data.vms_access_users,
        venue_users: data.venue_users,
        f_user_admin: data.f_user_admin,
        f_role_admin: data.f_role_admin,
        f_administrate_pods: data.f_administrate_pods,
        f_administrate_global_reservation_tags: data.f_administrate_global_reservation_tags,
        f_administrate_global_client_tags: data.f_administrate_global_client_tags,
        f_administrate_pod_reservation_tags: data.f_administrate_pod_reservation_tags,
        f_administrate_pod_client_tags: data.f_administrate_pod_client_tags,
        f_administrate_venue_group_user: data.f_administrate_venue_group_user,
        f_administrate_venue_group_user_permission: data.f_administrate_venue_group_user_permission,
        has_access_group_reporting: data.has_access_group_reporting,
        venue_group_id: appState.venueGroup.id,
        selectedUser: {
          isMfaEnabled: data.is_mfa_enabled,
          isSuspended: data.is_suspended,
          emailAlertsEnabled: data.email_alerts_enabled,
          isSSOUser: data.is_sso_user,
          lastLogin: data.last_login ? new Date(data.last_login) : null,
          lastPwReset: data.last_pw_reset ? new Date(data.last_pw_reset) : null,
        },
      })
    })
    .catch(e => {
      dispatch({
        type: ActionTypes.TRY_LOAD_USER_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Loading user failed, please try again or contact support'))

      throw e
    })
}

const determineUserHasAccesses = params => JSON.parse(params.vms_access_users).length !== 0

export const tryCreateSaveUser = () => async (dispatch, getState) => {
  const { appState, users } = getState()
  if (users.isUserUpdating) {
    return
  }
  const params = getUserParams(appState, users)
  const userHasAccesses = determineUserHasAccesses(params)
  if (!userHasAccesses) {
    dispatch(GlobalActions.showErrorMessage('User must have at least one access assigned'))
    throw new Error('No Access')
  }
  dispatch({
    type: ActionTypes.TRY_CREATE_USER_START,
  })

  try {
    await createSaveUser(params)
    dispatch({ type: ActionTypes.TRY_CREATE_USER_SUCCESS })
  } catch (e) {
    dispatch({ type: ActionTypes.TRY_CREATE_USER_FAIL })
    dispatch(GlobalActions.showErrorMessage(`Create/edit user failed, please try again or contact support: ${e.toString()}`))
    throw e
  }
}

export const tryFetchUsers = (venueGroup, usersCursor, groupPortalFilterValue, roleFilterValues) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.TRY_FETCH_USERS_START,
  })
  const { users } = getState()
  // eslint-disable-next-line no-nested-ternary
  const hasVenueGroupPortalAccess = groupPortalFilterValue === 'Yes' ? true : groupPortalFilterValue === 'No' ? false : null
  const roleValues = roleFilterValues.includes('All') ? [] : roleFilterValues
  const accessToAllVenuesOnly = users.venuePodsFilterValues.includes(venueGroup.id)
  const finalVenuePodsFilterValues = users.venuePodsFilterValues.slice(0)
  const venuePodsFilterValues = _.remove(finalVenuePodsFilterValues, v => v !== venueGroup.id)
  const fetchUsersParams = {
    venue_group_id: venueGroup.id,
    cursor: usersCursor,
    query: users.userSearchQuery,
    includes_role_ids: roleValues,
    access_to_all_venues_only: accessToAllVenuesOnly,
    venue_pods_primary_only: users.venuePodsRadioFilterValue === 'Primary',
    venue_pods_filters: venuePodsFilterValues,
    exclude_user_ids: users.excludeUserIds,
  }
  if (hasVenueGroupPortalAccess !== null) {
    fetchUsersParams.has_venue_group_portal_access = hasVenueGroupPortalAccess
  }
  return loadUsers(fetchUsersParams)
    .then(response =>
      dispatch({
        type: ActionTypes.TRY_FETCH_USERS_SUCCESS,
        users: response.data.users,
        cursor: response.data.cursor,
        totalNumUsers: response.data.total,
      })
    )
    .catch(e => {
      dispatch({
        type: ActionTypes.TRY_FETCH_USERS_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Fetching users failed, please try again or contact support'))

      return e
    })
}

export const tryDeleteUser = userId => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.DELETE_USER_START,
  })

  const { appState } = getState()
  return deleteUser({
    user_id: userId,
    venue_group_id: appState.venueGroup.id,
  })
    .then(() => {
      dispatch({
        type: ActionTypes.DELETE_USER_SUCCESS,
        userId,
      })
    })
    .catch(e => {
      dispatch({
        type: ActionTypes.DELETE_USER_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Deleting user failed, please try again or contact support'))

      return e
    })
}

export const changeUserSearchQuery = value => ({
  type: ActionTypes.USER_SEARCH_QUERY_CHANGE,
  value,
})

export const changeRolesFilter = values => ({
  type: ActionTypes.ROLE_FILTER_CHANGE,
  values,
})

export const changeVenuePodsRadio = value => ({
  type: ActionTypes.VENUE_PODS_GROUP_RADIO_CHANGE,
  value,
})

export const changeVenuePodsFilter = (value, field) => (dispatch, getState) => {
  const { appState } = getState()
  dispatch({
    type: ActionTypes.VENUE_PODS_GROUP_FILTER_CHANGE,
    venueGroupId: appState.venueGroup.id,
    value,
    field,
  })
}

export const changeGroupPortalAccessFilter = value => ({
  type: ActionTypes.GROUP_PORTAL_ACCESS_FILTER_CHANGE,
  value,
})

export const tryLockUser = (userId, isLocked) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.LOCK_USER_START,
  })

  const { appState } = getState()
  return lockUser({
    user_id: userId,
    is_locked: isLocked,
    venue_group_id: appState.venueGroup.id,
  })
    .then(() => {
      dispatch({
        type: ActionTypes.LOCK_USER_SUCCESS,
      })
    })
    .catch(e => {
      dispatch({
        type: ActionTypes.LOCK_USER_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Locking user failed, please try again or contact support'))

      return e
    })
}

export const displayAdtlAccessModal = (userFullName, rolesToAccessList) => ({
  type: ActionTypes.DISPLAY_ADTL_ACCESS_MODAL,
  userFullName,
  rolesToAccessList,
})

export const closeAdtlAccessModal = () => ({
  type: ActionTypes.CLOSE_ADTL_ACCESS_MODAL,
})

export const toggleDeleteWarning = (showWarning, selectedUserId = null) => ({
  type: ActionTypes.TOGGLE_DELETE_WARNING,
  showWarning,
  selectedUserId,
})

export const displayAccessDefinitionModal = (roleId, roleTemplates) => ({
  type: ActionTypes.DISPLAY_ACCESS_DEFINITION_MODAL,
  roleId,
  roleTemplates,
})

export const closeAccessDefinitionModal = () => ({
  type: ActionTypes.CLOSE_ACCESS_DEFINITION_MODAL,
})
