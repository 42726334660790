import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import history from 'mgr/pages/shared/utils/History'
import {
  tryGetVenueLanguages,
  venueLanguageChanged,
  resetVenueLanguageToInitial,
} from 'mgr/pages/single-venue/settings/actions/LanguageActions'
import {
  tryGetLanguageStrings,
  trySaveLanguageStrings,
  updateLanguageString,
} from 'mgr/pages/single-venue/settings/actions/LanguageStringActions'
import { selectLanguageStringSettingsLanguages } from 'mgr/pages/single-venue/settings/selectors/language'
import LanguageCategories from 'svr/component-lib/Manager/Layout/LanguageCategories'
import LanguageContentLayout from 'svr/component-lib/Manager/Layout/LanguageContent'

// TODO add secondary buttons for cancel and send test email functionality for email interfaces
// component is EmailActions
class LanguageStringSettings extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isReload: false }
  }

  componentDidMount() {
    const { venue, languages, isLanguageListLoading, tryGetVenueLanguages, resetVenueLanguageToInitial } = this.props

    if (!isLanguageListLoading) {
      if (languages.length === 0) {
        tryGetVenueLanguages(venue.id)
      } else {
        this.setState({ isReload: true })
        resetVenueLanguageToInitial()
      }
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { venue, defaultLanguage, selectedLanguage, tryGetLanguageStrings, interfaceType, isLanguageStringsLoading } = this.props
    if (!isLanguageStringsLoading) {
      const reloadStringsForInitialLanguageReset = this.state.isReload && this.state.isReload !== prevState.isReload
      const reloadForLanguageChange = selectedLanguage && defaultLanguage && prevProps.selectedLanguage !== selectedLanguage
      if (reloadForLanguageChange || reloadStringsForInitialLanguageReset) {
        tryGetLanguageStrings(venue.id, interfaceType, defaultLanguage, selectedLanguage)
      }
    }
  }

  render() {
    const {
      venue,
      venues,
      defaultLanguage,
      selectedLanguage,
      venueLanguageChanged,
      isLanguageListLoading,
      mediaUrl,
      languageStrings,
      isLanguageStringsLoading,
      updateLanguageString,
      trySaveLanguageStrings,
      interfaceType,
      title,
      routes,
      enabledLanguages,
      onChangeVenue,
      infoText,
      showDefaultLanguageOnly,
    } = this.props
    const translatedLanguages = []
    if (!_.isEmpty(selectedLanguage) && !selectedLanguage.is_default && !showDefaultLanguageOnly) {
      translatedLanguages.push(selectedLanguage)
    }
    const isLoading = isLanguageListLoading || isLanguageStringsLoading
    const selectedLanguageValue = _.isEmpty(selectedLanguage) ? '' : selectedLanguage.value

    return (
      <LanguageContentLayout
        title={title}
        venue={venue}
        venues={venues}
        routes={routes}
        history={history}
        onChangeVenue={onChangeVenue}
        languages={enabledLanguages}
        isLoading={isLoading}
        selectedLanguage={selectedLanguageValue}
        onChangeLanguage={venueLanguageChanged}
        mediaUrl={mediaUrl}
        saveFunction={() => trySaveLanguageStrings(interfaceType)}
        infoText={infoText}
      >
        <LanguageCategories
          data={languageStrings}
          defaultLanguage={defaultLanguage}
          translatedLanguages={translatedLanguages}
          handleCellChange={updateLanguageString}
          selectedLanguage={selectedLanguageValue}
          enabledLanguages={enabledLanguages}
          onChangeLanguage={venueLanguageChanged}
        />
      </LanguageContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    venue,
    venues,
    languages: state.languages.venueLanguages,
    enabledLanguages: selectLanguageStringSettingsLanguages(state),
    defaultLanguage: state.languages.defaultLanguage,
    selectedLanguage: state.languages.selectedLanguage,
    isLanguageListLoading: state.languages.isLoading,
    mediaUrl: state.appState.mediaUrl,
    languageStrings: state.languageStrings.languageStringData,
    isLanguageStringsLoading: state.languageStrings.isLoading,
  }
}

const mapDispatchToProps = {
  tryGetVenueLanguages,
  venueLanguageChanged,
  tryGetLanguageStrings,
  trySaveLanguageStrings,
  updateLanguageString,
  resetVenueLanguageToInitial,
  onChangeVenue,
}

LanguageStringSettings.defaultProps = {
  showDefaultLanguageOnly: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LanguageStringSettings))
