// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { getContrastingColor } from 'svr/common/Color'

const StaticTagsContainer = styled.div`
  color: ${props => props.color};
`
const StaticTagWrapper = styled.div`
  display: inline-block;
  padding: 4px 2px;
`
const StaticTag = styled.div`
  background-color: ${props => props.hexColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 7px 12px;
  word-break: break-word;
  white-space: pre-wrap;
`

/* type Props = {
  className: string,
  hexColor: string,
  tagNames: Array<string | (() => mixed)>,
} */

const StaticTags = (props /* : Props */) => (
  <StaticTagsContainer className={`${props.className}-container`} color={getContrastingColor(props.hexColor)}>
    {_.map(props.tagNames, (tagName, idx) => (
      <StaticTagWrapper key={`static-tags-wrapper-${idx}`}>
        <StaticTag key={`static-tag-${idx}`} className={props.className} hexColor={props.hexColor}>
          {props.tagNameDisplays && tagName in props.tagNameDisplays ? props.tagNameDisplays[tagName] : tagName}
        </StaticTag>
      </StaticTagWrapper>
    ))}
  </StaticTagsContainer>
)

StaticTags.defaultProps = {
  className: '',
  hexColor: '#347BAF',
  tagNames: [],
}

export default StaticTags
