import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { formatBold, GOOGLE_RESERVE_ENABLED_INSTRUCTIONS_URL } from '../../../../utils'

export function GoogleReserveEnabledSuggestedActions() {
  const { formatMessage } = useLocales()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-google-reserve-enabled-suggested-actions">
      <Text data-test="insights-google-reserve-enabled-suggested-actions-review">
        1. {formatMessage(insightsMessages.googleReserveEnabledReviewStep)}
      </Text>
      <Text data-test="insights-google-reserve-enabled-suggested-actions-config">
        2.{' '}
        {formatMessage(insightsMessages.googleReserveEnabledConfigStep, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <Text data-test="insights-google-reserve-enabled-suggested-actions-help">
        3.{' '}
        {formatMessage(insightsMessages.googleReserveEnabledHelpCenterStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link
              to={GOOGLE_RESERVE_ENABLED_INSTRUCTIONS_URL}
              target="_blank"
              data-test="insights-google-reserve-enabled-suggested-actions-help-link"
            >
              {chunks}
            </Link>
          ),
        })}
      </Text>
    </VStack>
  )
}
