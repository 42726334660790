// /* @flow */
import * as React from 'react'
import styled from 'styled-components'
import { StyledIcons } from 'svr/common/VmsIcons'
import Button from 'svr/lib/Buttons/Button'

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.fontColor || props.theme.color.white};
  padding: ${props => props.verticalPadding || props.theme.gutter.medium} ${props => props.horizontalPadding || props.theme.gutter.standard};
  ${props => props.customButtonStyles}
`
const ActionButtonWrapper = styled.div``
const ActionButtonText = styled.span`
  font-size: ${props => props.fontSize || '13px'};
  padding-left: ${props => (props.hasActionIcon ? '10px' : '0px')};
`
const ActionIcon = styled(StyledIcons.XS)`
  cursor: pointer;
`

/* type Props = {
  onClickHandler: () => mixed,
  dataTest: string,
  actionText: string,
  className: string,
  fontSize: string,
  backgroundColor: string,
  verticalPadding: string,
  horizontalPadding: string,
  actionIcon: Array<string | (() => mixed)>,
  customButtonStyles: Array<string | (() => mixed)>,
} */

const ActionButton = (props /* : Props */) => (
  <ActionButtonContainer
    customButtonStyles={props.customButtonStyles}
    backgroundColor={props.backgroundColor}
    verticalPadding={props.verticalPadding}
    horizontalPadding={props.horizontalPadding}
    onClick={props.onClickHandler}
    disabled={props.disabled}
  >
    <Button
      testId={props.dataTest}
      onClick={props.onClickHandler}
      disabled={props.disabled}
      innerElement={
        <ActionButtonWrapper>
          {props.actionIcon && (
            <ActionIcon onClick={props.onClickHandler} aria-hidden="true" className={`sr-action-icon-${props.className}`}>
              {props.actionIcon}
            </ActionIcon>
          )}
          <ActionButtonText hasActionIcon={!!props.actionIcon} fontSize={props.fontSize}>
            {props.actionText}
          </ActionButtonText>
        </ActionButtonWrapper>
      }
    />
  </ActionButtonContainer>
)

ActionButton.defaultProps = {
  actionText: '',
  actionIcon: null,
  className: '',
  fontSize: '',
  disabled: false,
  backgroundColor: '#347BAF',
  onClickHandler: () => {},
}

export default ActionButton
