import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'

export function useInsightUtils() {
  const { formatMessage } = useLocales()

  const getFormattedHoursAndMinutes = (totalMinutes: number) => {
    const { hours, minutes } = getHoursAndMinutes(totalMinutes)
    const hourText = formatMessage(commonMessages.hoursAbbreviated, { count: hours })
    const minuteText = formatMessage(commonMessages.minutesAbbreviated, { count: minutes })
    const displayHours = hours > 0 ? `${hourText}` : ''
    const displayMinutes = minutes > 0 ? `${minuteText}` : ''
    const conditionalSpace = hours > 0 && minutes > 0 ? ' ' : ''
    return `${displayHours}${conditionalSpace}${displayMinutes}`
  }

  return {
    getFormattedHoursAndMinutes,
  }
}
