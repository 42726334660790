import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const SubNavStyles = {
  width: 180,
}

// TODO normalize colors for sizzle, z-index
const SubNavContainer = styled.div`
  display: flex;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: ${SubNavStyles.width}px;
  flex-direction: column;
  background-color: ${props => props.theme.color.lightGrey};
  z-index: 351;
  overflow: auto;
`

const ListContainer = styled.ul`
  padding: 0 ${props => props.theme.gutter.standard};
  padding-bottom: ${props => props.theme.gutter.triple};
  margin: 0;
  font-size: ${props => props.theme.fontSize.xsmall};
  li {
    list-style: none;
  }
  a {
    &:hover {
      background-color: ${props => props.theme.color.greyMuted};
      cursor: pointer;
    }
    &.is-active {
      background-color: ${props => props.theme.color.greyMuted};
      font-weight: bold;
    }
    border-radius: 2px;
    color: ${props => props.theme.color.black};
    display: block;
    padding: 3px 6px;
    text-decoration: none;
    margin: 1px 0;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.color.grey};
  font-family: ${props => props.theme.headerFontFamily};
  font-size: ${props => props.theme.fontSize.small};
  padding: 0 6px;
  margin: 20px 20px 5px 0;
`

class SubNav extends Component {
  render() {
    const { items } = this.props
    return (
      <SubNavContainer>
        <ListContainer>
          {items.map((item, idx) => {
            let innerItem
            if (item.isTitle) {
              innerItem = <Title>{item.name}</Title>
            } else if (item.exit) {
              innerItem = (
                <a className={item.class} href={item.link}>
                  {item.name}
                </a>
              )
            } else {
              innerItem = (
                <NavLink
                  activeClassName="is-active"
                  exact={item.exact}
                  to={item.link}
                  className={item.className || item.class}
                  id={item.id}
                >
                  {item.name}
                </NavLink>
              )
            }
            return <li key={item.link || item.name}>{innerItem}</li>
          })}
        </ListContainer>
      </SubNavContainer>
    )
  }
}

export default SubNav
