import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'
import StyleUtils from 'mgr/layout/StyleUtils'
import { getProblemDescription } from 'mgr/pages/shared/utils/Availability'
import {
  showConflictDialogAction,
  completeMoveActualAction,
  closeConflictDialogAction,
} from 'mgr/pages/single-venue/dayview/actions/Actions'
import { merge } from 'svr/common/SRUtils'

class GridMoveDropDown extends PureComponent {
  constructor(props) {
    super(props)

    this.handleClickAutoAssign = this.handleClickAutoAssign.bind(this)
    this.handleClickRecommended = this.handleClickRecommended.bind(this)
    this.handleClickNotRecommended = this.handleClickNotRecommended.bind(this)
    this.dropDownRef = null

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    this.dropDownRef = ReactDOM.findDOMNode(this.refs.dropDownRef)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleClickAutoAssign() {
    this.props.actions.unassignTables(this.props.venue.id, this.props.actual)
  }

  handleClickRecommended(avail) {
    const tableIds = this.getTableIdsFromId(avail.id)
    this.props.actions.assignTables(this.props.venue.id, this.props.actual, tableIds)
  }

  handleClickNotRecommended(avail) {
    const conflictActual = null
    const description = getProblemDescription(this.props.availability, false)
    const tableIds = this.getTableIdsFromId(avail.id)
    this.props.actions.showConflictDialogAction(
      description,
      'Assign table',
      conflictActual,
      completeMoveActualAction(this.props.venue.id, this.props.actual.id, tableIds),
      closeConflictDialogAction(this.props.actual.id)
    )
  }

  handleScroll() {
    this.handleClickOutside()
  }

  handleClickOutside() {
    this.props.onClickOutside()
  }

  getTableIdsFromId(id) {
    return id.split(',')
  }

  hasRecommended() {
    let count = 0
    const avail = this.props.availability
    if (avail.recommended) {
      count += 1
    }
    count += avail.recommendedCombos?.length ?? 0
    return count > 0
  }

  hasNotRecommended() {
    let count = 0
    const avail = this.props.availability
    if (!avail.recommended) {
      count += 1
    }
    count += avail.notRecommendedCombos?.length ?? 0
    return count > 0
  }

  renderRecommended(styles) {
    const recommended = []
    const avail = this.props.availability
    if (avail.recommended) {
      recommended.push(
        <div
          key={avail.id}
          style={styles.dropDownItem}
          onClick={() => {
            this.handleClickRecommended(avail)
          }}
        >
          <div style={styles.leftItem}>{avail.name}</div>
          <div style={styles.rightItem}>{avail.partySizeRange}</div>
          <div style={StyleUtils.ClearFix} />
        </div>
      )
    }
    if (avail.recommendedCombos) {
      for (const combo of avail.recommendedCombos) {
        recommended.push(
          <div
            key={combo.id}
            style={styles.dropDownItem}
            onClick={() => {
              this.handleClickRecommended(combo)
            }}
          >
            <div style={styles.leftItem}>{combo.name}</div>
            <div style={styles.rightItem}>{avail.partySizeRange}</div>
            <div style={StyleUtils.ClearFix} />
          </div>
        )
      }
    }
    return recommended
  }

  renderNotRecommended(styles) {
    const notRecommended = []
    const avail = this.props.availability
    if (!avail.recommended) {
      const description = getProblemDescription(avail)
      notRecommended.push(
        <div
          key={avail.id}
          style={styles.dropDownItem}
          onClick={() => {
            this.handleClickNotRecommended(avail)
          }}
        >
          <div style={styles.leftItem}>{avail.name}</div>
          <div style={styles.description}>{description}</div>
          <div style={styles.rightItem}>{avail.partySizeRange}</div>
          <div style={StyleUtils.ClearFix} />
        </div>
      )
    }
    if (avail.notRecommendedCombos) {
      for (const combo of avail.notRecommendedCombos) {
        const description = getProblemDescription(combo)
        notRecommended.push(
          <div
            key={combo.id}
            style={styles.dropDownItem}
            onClick={() => {
              this.handleClickNotRecommended(combo)
            }}
          >
            <div style={styles.leftItem}>{combo.name}</div>
            <div style={styles.description}>{description}</div>
            <div style={styles.rightItem}>{combo.partySizeRange}</div>
            <div style={StyleUtils.ClearFix} />
          </div>
        )
      }
    }
    return notRecommended
  }

  render() {
    const styles = {
      dropDownContainer: {
        position: 'relative',
      },
      dropDownMenu: merge(
        {
          backgroundColor: 'white',
          position: 'fixed',
          width: 350,
          zIndex: 53,
          top: 35,
          left: 0,
          overflow: 'hidden',
        },
        StyleUtils.rounded('5px'),
        StyleUtils.shadow('rgba(0,0,0,0.68)')
      ),
      dropDownItem: {
        width: 'auto',
        lineHeight: '33px',
        height: 33,
        textAlign: 'left',
        paddingLeft: 11,
        fontSize: '14px',
        cursor: 'pointer',
        fontWeight: 400,
        ':hover': {
          backgroundColor: '#eee',
        },
      },
      leftItem: {
        float: 'left',
        marginRight: 10,
        maxWidth: 100,
        textOverflow: 'ellipsis',
      },
      description: {
        float: 'left',
        fontStyle: 'italic',
        color: '#8B8B8B',
        fontSize: '12px',
      },
      rightItem: {
        float: 'right',
        color: '#8B8B8B',
        marginRight: 15,
      },
      header: {
        width: 'auto',
        lineHeight: '36px',
        height: 36,
        textAlign: 'left',
        paddingLeft: 11,
        fontSize: '12px',
      },
    }

    const boundingRect = this.props.parentRef.getBoundingClientRect()
    if (this.props.leftPos) {
      styles.dropDownMenu.left = this.props.leftPos + boundingRect.left
      styles.dropDownMenu.top = styles.dropDownMenu.top + boundingRect.top
    }

    /*
    // Auto assign disabled for now until more customer feedback  8/2/2017 KP
    <div key={'autoAssign'} style={styles.dropDownItem} onClick={this.handleClickAutoAssign}>
      Auto Assign
    </div>
    */

    return (
      <div style={styles.dropDownContainer}>
        <div ref="dropDownRef" style={styles.dropDownMenu}>
          {this.hasRecommended() ? (
            <div key="recommended" style={styles.header}>
              RECOMMENDED
            </div>
          ) : (
            ''
          )}
          {this.renderRecommended(styles)}
          {this.hasNotRecommended() ? (
            <div key="notRecommended" style={styles.header}>
              NOT RECOMMENDED
            </div>
          ) : (
            ''
          )}
          {this.renderNotRecommended(styles)}
        </div>
      </div>
    )
  }
}

GridMoveDropDown = onClickOutside(Radium(GridMoveDropDown))

const mapStateToProps = (state, ownProps) => ({
  venue: state.appState.venue,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    unassignTables: (venueId, actual) => {
      dispatch(completeMoveActualAction(venueId, actual.id))
    },
    assignTables: (venueId, actual, tableIds) => {
      dispatch(completeMoveActualAction(venueId, actual.id, tableIds))
    },
    showConflictDialogAction: (explanation, actionButtonText, conflictActual, nextAction, closeAction) => {
      dispatch(showConflictDialogAction(explanation, actionButtonText, conflictActual, nextAction, closeAction))
    },
  },
})

GridMoveDropDown = connect(mapStateToProps, mapDispatchToProps)(GridMoveDropDown)

export default GridMoveDropDown
