import { FlatfileProvider } from 'flatfile-react-latest'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import { Contents, SubContainer } from 'mgr/pages/single-venue/settings/components/shared'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { ENVIRONMENTS } from 'svr/manager/lib/utils/Constants'
import { useConfigProvider } from '@sevenrooms/connect-config'
import { useLocales } from '@sevenrooms/core/locales'
import { type UserDomainVenue, useVenueContext, useVenues, type RootState, useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { ImportLocales } from './Import.locales'
import {
  closeSubmissionDialog,
  getInitialData,
  initializeSubmissionDialogue,
  submitImport,
  uploadFlatFile,
} from './reservationAndClientImportSlice'
import { InitialSettings, FlatFileImport, FlatFileImportNew, TagColumnSelection, TagColumnMapping } from './steps'
import SubmitImportModal from './SubmitImportModal'
import {
  type FlatFileFieldMapping,
  type FlatFileFieldValidator,
  type FlatFileResults,
  FlatFileVersion,
  ImportType,
  type NewTagGroup,
} from './types'

interface ReservationAndClientImportProps {
  importType: ImportType
}

export default function ReservationAndClientImport(props: ReservationAndClientImportProps) {
  const { importType } = props
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()
  const { venue } = useVenueContext()
  const venues = useVenues()
  const { userDomain } = useAppContext()
  const permissions = useNavigationPermissionsContext()
  const { isEnabled, loading: configLoading } = useConfigProvider()

  const [canUseFlatFileLatest, setCanUseFlatFileLatest] = useState(false)
  const [customFields, setCustomFields] = useState<string[]>([])
  const [customTagFields, setCustomTagFields] = useState<string[]>([])
  const [dateFormat, setDateFormat] = useState('')
  const [futureTableAndSeatingAreaOverride, setFutureTableAndSeatingAreaOverride] = useState('table')
  const [flatFileResults, setFlatFileResults] = useState<FlatFileResults | null>(null)
  const [step, setStep] = useState(1)
  const [tagsToMapping, setTagsToMapping] = useState<string[]>([])

  const { isInitialDataLoaded, isPopUpOpen, blobstoreUploadKey, mappedTags, tagGroups, importId, fields, dateFields, publishableKey } =
    useSelector((state: RootState) => state.reservationAndClientImport)

  const flatFileDebugMode = userDomain?.env === ENVIRONMENTS.DEVELOPMENT

  const updatedFields = useMemo(() => {
    const updatedFields = _.cloneDeep(fields) as FlatFileFieldMapping[]
    const regexByDateFormat: { [key: string]: string } = {
      'dd.mm.yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[012])\\.\\d{4}$',
      'dd/mm/yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\/(0?[1-9]|1[012])\\/\\d{4}$',
      'dd-mm-yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\-(0?[1-9]|1[012])\\-\\d{4}$',

      'yyyy.mm.dd': '^\\d{4}\\.(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])$',
      'yyyy/mm/dd': '^\\d{4}\\/(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])$',
      'yyyy-mm-dd': '^\\d{4}\\-(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$',

      'mm.dd.yyyy': '^(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])\\.\\d{4}$',
      'mm/dd/yyyy': '^(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])\\/\\d{4}$',
      'mm-dd-yyyy': '^(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])\\-\\d{4}$',

      'dd.mm': '^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[012])$',
      'dd/mm': '^(0?[1-9]|[12][0-9]|3[01])\\/(0?[1-9]|1[012])$',
      'dd-mm': '^(0?[1-9]|[12][0-9]|3[01])\\-(0?[1-9]|1[012])$',

      'mm.dd': '^(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])$',
      'mm/dd': '^(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])$',
      'mm-dd': '^(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$',
    }
    _.each(updatedFields, field => {
      if (dateFields?.includes(field.key)) {
        const validator: FlatFileFieldValidator = {
          validate: 'regex_matches',
          regex: regexByDateFormat[dateFormat as keyof typeof regexByDateFormat] || '',
        }
        if (!field.validators) {
          // eslint-disable-next-line no-param-reassign
          field.validators = [validator]
        } else {
          const index = _.findIndex(field.validators, { validate: 'regex_matches' })
          if (index !== -1) {
            // eslint-disable-next-line no-param-reassign
            field.validators[index] = validator
          } else {
            field.validators.push(validator)
          }
        }
      }
    })
    return updatedFields
  }, [fields, dateFields, dateFormat])

  useEffect(() => {
    if (!configLoading) {
      const canUseFlatfileLatest = isEnabled('cx.flatfile_version_latest')
      setCanUseFlatFileLatest(canUseFlatfileLatest ?? false)
      const flatFileVersion = canUseFlatfileLatest ? FlatFileVersion.LATEST : FlatFileVersion.V2
      dispatch(getInitialData({ venueId: venue.id, importType, flatFileVersion }))
    }
  }, [dispatch, venue.id, importType, configLoading, isEnabled])

  if (!permissions || !isInitialDataLoaded) {
    return null
  }

  if (!permissions.canAccessFlatfileImports) {
    window.location.href = '/'
    return null
  }

  const handleClosePopUp = () => {
    dispatch(closeSubmissionDialog())
    setDateFormat('')
    setFutureTableAndSeatingAreaOverride('table')
    setStep(1)
  }

  const submit = () => {
    const newTagGroupsToSubmit = _.filter(tagGroups, tagGroup => (tagGroup as NewTagGroup).isNew)
    if (blobstoreUploadKey) {
      dispatch(
        submitImport({
          venueId: venue.id,
          importType,
          blobstoreUploadKey,
          customTagFields,
          mappedTags,
          newTagGroupsToSubmit,
          dateFormat,
          futureTableAndSeatingAreaOverride,
        })
      )
    }
  }

  const handleCopyImportId = () => {
    if (importId) {
      navigator.clipboard.writeText(importId)
    }
  }

  const handleFlatFileDone = (newFlatFileResults: FlatFileResults) => {
    const customFields = _.chain(newFlatFileResults.$data).first().get('data.$custom').keys().value().sort()
    setCustomFields(customFields)
    setFlatFileResults(newFlatFileResults)
    setStep(3)
  }

  const handleTagFieldsSelected = () => {
    const tagsToMapping = _.chain(flatFileResults?.$data)
      .flatMapDeep(elem =>
        _.chain(elem.data.$custom)
          .pick(customTagFields)
          .pickBy(_.identity)
          .values()
          .map(tags => _.split(String(tags), ','))
          .value()
      )
      .map(tag => _.trim(tag))
      .uniq()
      .value()

    setTagsToMapping(tagsToMapping)
    setStep(4)

    if (flatFileResults) {
      dispatch(uploadFlatFile(flatFileResults))
    }
  }

  const title =
    importType === ImportType.RESERVATION ? formatMessage(ImportLocales.reservationTitle) : formatMessage(ImportLocales.clientTitle)

  return (
    <ContentLayout title={title} venue={venue} venues={venues} onChangeVenue={(venue: UserDomainVenue) => dispatch(onChangeVenue(venue))}>
      {isPopUpOpen && <SubmitImportModal handleClosePopUp={handleClosePopUp} submit={submit} handleCopyImportId={handleCopyImportId} />}
      <Contents>
        <SubContainer>
          {step === 1 && (
            <InitialSettings
              importType={importType}
              dateFormat={dateFormat}
              handleChooseDateFormat={(value: string) => setDateFormat(value)}
              futureTableAndSeatingAreaOverride={futureTableAndSeatingAreaOverride}
              handleChooseTableOverride={(value: string) => setFutureTableAndSeatingAreaOverride(value)}
              handleNext={() => setStep(2)}
              disableNext={!dateFormat}
            />
          )}
          {step === 2 && !canUseFlatFileLatest && (
            <FlatFileImport
              importType={importType}
              dateFormat={dateFormat}
              title={title}
              fields={updatedFields}
              handleFlatFileDone={handleFlatFileDone}
              handleClickBack={() => setStep(1)}
            />
          )}
          {step === 2 && canUseFlatFileLatest && publishableKey && (
            <FlatfileProvider publishableKey={publishableKey} config={{ displayAsModal: true, debug: flatFileDebugMode }}>
              <FlatFileImportNew
                importType={importType}
                dateFormat={dateFormat}
                title={title}
                fields={fields}
                handleFlatFileDone={handleFlatFileDone}
                handleClickBack={() => setStep(1)}
              />
            </FlatfileProvider>
          )}
          {step === 3 && (
            <TagColumnSelection
              customFields={customFields}
              handleCustomFieldsSelected={customTagFields => setCustomTagFields(customTagFields as string[])}
              handleTagFieldsSelected={handleTagFieldsSelected}
              handleClickBack={() => setStep(2)}
            />
          )}
          {step === 4 && (
            <TagColumnMapping
              tagsToMapping={tagsToMapping}
              handleOpenPopUp={() => dispatch(initializeSubmissionDialogue())}
              handleClickBack={() => setStep(3)}
            />
          )}
        </SubContainer>
      </Contents>
    </ContentLayout>
  )
}
