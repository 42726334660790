import { defineMessages } from '@sevenrooms/core/locales'

export const reasonsModalMessages = defineMessages({
  titleDate: {
    id: 'reasonsModal.titleInfo',
    defaultMessage: `{count, plural,
      one {{covers} cover at {time} Availability}
      other {{covers} covers at {time} Availability}
    }`,
  },
} as const)

export const inactiveCategoryMessages = defineMessages({
  mainDescriptionNoShift: {
    id: 'reasonsModal.inactiveCategory.noShift.mainDescription',
    defaultMessage: 'There are no active Shifts at {time}',
  },
  noShiftButtonText: {
    id: 'reasonsModal.inactiveCategory.noShift.buttonText',
    defaultMessage: 'Manage Shifts',
  },
  mainDescriptionPastTime: {
    id: 'reasonsModal.inactiveCategory.pastTime.mainDescription',
    defaultMessage: 'Time slot is in the past',
  },
} as const)
export const venueCategoryMessages = defineMessages({
  mainDescriptionClosed: {
    id: 'reasonsModal.venueCategory.mainDescriptionClosed',
    defaultMessage: 'Venue is closed because of Blackout Date',
  },
  mainDescriptionNoAccessRules: {
    id: 'reasonsModal.venueCategory.mainDescriptionNoAccessRules',
    defaultMessage: 'No Access Rules for this Shift',
  },
  manageBlackoutDates: {
    id: 'reasonsModal.venueCategory.manageBlackoutDate',
    defaultMessage: 'Manage Blackout Dates',
  },
  manageAccessRules: {
    id: 'reasonsModal.venueCategory.manageAccessRules',
    defaultMessage: 'Manage Access Rules',
  },
} as const)

export const shiftsCategoryMessages = defineMessages({
  mainDescriptionNoShiftsFound: {
    id: 'reasonsModal.shiftsCategory.noShiftsFound.mainDescription',
    defaultMessage: 'There are no active Shifts at {time}',
  },
  manageShiftsNoShiftsFound: {
    id: 'reasonsModal.shiftsCategory.noShiftsFound.manageShifts',
    defaultMessage: 'Manage Shifts',
  },
  mainDescriptionHeaderOverMaxShift: {
    id: 'reasonsModal.shiftsCategory.overMaxShift.mainDescriptionHeader',
    defaultMessage: 'Shift: <a>{shiftName}</a>',
  },
  mainDescriptionOverMaxShift: {
    id: 'reasonsModal.shiftsCategory.overMaxShift.mainDescription',
    defaultMessage: `{count, plural,
      one {Booking {covers} cover at {time} would exceed the total max covers
      ({bookedTotalPossibleCovers} of {coversMaxPerShift} covers booked) for this Shift}
      other {Booking {covers} covers at {time} would exceed the total max covers
      ({bookedTotalPossibleCovers} of {coversMaxPerShift} covers booked) for this Shift}
    }`,
  },
} as const)

export const accessRulesMatchCategoryMessages = defineMessages({
  accordianHeader: {
    id: 'reasonsModal.accessRulesMatchCategory.accordianHeader',
    defaultMessage: `{count, plural,
      one {{numArs} Access Rule matches search criteria but has other restrictions}
      other {{numArs} Access Rules match search criteria but have other restrictions}
    }`,
  },
  mainDescriptionHeader: {
    id: 'reasonsModal.accessRulesMatchCategory.mainDescriptionHeader',
    defaultMessage: 'Access Rule: <a>{ruleName}</a>',
  },
  mainDescriptionNoInventoryReservations: {
    id: 'reasonsModal.accessRulesMatchCategory.noInventoryReservations.mainDescription',
    defaultMessage: 'Booking a reservation at {time} would exceed the total max reservations for this Access Rule',
  },
  mainDescriptionNoInventoryCovers: {
    id: 'reasonsModal.accessRulesMatchCategory.noInventoryCovers.mainDescription',
    defaultMessage: `{count, plural,
      one {Booking {count} cover at {time} would exceed the pacing covers defined on Access Rule ({inventoryRemaining} remaining)}
      other {Booking {count} covers at {time} would exceed the total max covers for this Access Rule ({inventoryRemaining} remaining)}
    }`,
  },
  mainDescriptionCutoffPassed: {
    id: 'reasonsModal.accessRulesMatchCategory.cutoffPassed.mainDescription',
    defaultMessage: `The booking cutoff setting for this Access Rule requires bookings to be made {cutoffNum} {cutoffType, select,
      MINUTES {{cutoffNum, plural,
        one {minute}
        other {minutes}
      }}
      HOURS {{cutoffNum, plural,
        one {hour}
        other {hours}
      }}
      DAYS {{cutoffNum, plural,
        one {day}
        other {days}
      }}
      WEEKS {{cutoffNum, plural,
        one {week}
        other {weeks}
      }}
      MONTHS {{cutoffNum, plural,
        one {month}
        other {months}
      }}
      other {{cutoffType}}
    } before {cutoffHour, select,
      RESERVATION_TIME {the Reservation time}
      other {{cutoffHour}}
    }`,
  },
  mainDescriptionHeldByAccessRule: {
    id: 'reasonsModal.accessRulesMatchCategory.heldByAccessRule.mainDescription',
    defaultMessage: '“{ruleName}” is currently holding table {table}',
  },
  mainDescriptionNotReachedBookingStartTime: {
    id: 'reasonsModal.accessRulesMatchCategory.notReachedBookingStartTime.mainDescription',
    defaultMessage: `There is still time to go until you reach the allowed guest booking start time ({startNum} {startType, select,
      MINUTES {{startNum, plural,
        one {minute}
        other {minutes}
      }}
      HOURS {{startNum, plural,
        one {hour}
        other {hours}
      }}
      DAYS {{startNum, plural,
        one {day}
        other {days}
      }}
      WEEKS {{startNum, plural,
        one {week}
        other {weeks}
      }}
      MONTHS {{startNum, plural,
        one {month}
        other {months}
      }}
      other {{startType}}
    } before {startHour, select,
      RESERVATION_TIME {Reservation time}
      other {{startHour}}
    }) for this Audience ({audience})`,
  },
  mainDescriptionNoPacingCoversRemaining: {
    id: 'reasonsModal.accessRulesMatchCategory.noPacingCoversRemaining.mainDescription',
    defaultMessage: `{count, plural,
      one {Booking {covers} cover at {time} would exceed the pacing covers defined on Access Rule}
      other {Booking {covers} covers at {time} would exceed the pacing covers defined on Access Rule}
    }`,
  },
  mainDescriptionTagsMismatch: {
    id: 'reasonsModal.accessRulesMatchCategory.tagsMismatch.mainDescription',
    defaultMessage: 'The Access Rule Client Tags for this Audience ({audience}) do not match',
  },
})

export const accessRulesTargetingCategoryMessages = defineMessages({
  accordianHeader: {
    id: 'reasonsModal.accessRulesTargetingCategory.accordianHeader',
    defaultMessage: `{count, plural,
      one {{numArs} Access Rule does not match search criteria}
      other {{numArs} Access Rules do not match search criteria}
    }`,
  },
  mainDescriptionHeader: {
    id: 'reasonsModal.accessTargetingCategory.mainDescriptionHeader',
    defaultMessage: 'Access Rule: <a>{ruleName}</a>',
  },
  mainDescriptionPartySizeRestrictionMismatch: {
    id: 'reasonsModal.accessRulesTargetingCategory.partySizeRestrictionMismatch.mainDescription',
    defaultMessage: 'Search party size ({partySize}) does not match Access Rule’s party size restriction ({partyMin}-{partyMax})',
  },
  mainDescriptionAudienceTierMismatch: {
    id: 'reasonsModal.accessRulesTargetingCategory.audienceTierMismatch.mainDescription',
    defaultMessage:
      "Search Audience either does not match the Access Rule's Audience list, or you have not yet reached the allowed guest booking start time for the searched Audience",
  },
  mainDescriptionDurationRestrictionMismatch: {
    id: 'reasonsModal.accessRulesTargetingCategory.durationRestrictionMismatch.mainDescription',
    defaultMessage:
      "Search duration ({durationHours} hr {durationMinutes} mins) is not within Access Rule's duration range ({durationMinHours} hr {durationMinMinutes} mins - {durationMaxHours} hr {durationMaxMinutes} mins)",
  },
  mainDescriptionNotAllowedTime: {
    id: 'reasonsModal.accessRulesTargetingCategory.notAllowedTime.mainDescription',
    defaultMessage: `{count, plural,
       one {Search time ({time}) is not within Access Rule’s time ({arTimes})}
       other {Search time ({time}) is not within Access Rule’s times ({arTimes})}
     }`,
  },
  mainDescriptionNotWithinTimeRange: {
    id: 'reasonsModal.accessRulesTargetingCategory.notWithinTimeRange.mainDescription',
    defaultMessage: "Search time ({time}) doesn't match Access Rule’s time range ({arTimeRangeStart} - {arTimeRangeEnd})",
  },
  mainDescriptionNotDurationPickerEligible: {
    id: 'reasonsModal.accessRulesTargetingCategory.notDurationPickerEligible.mainDescription',
    defaultMessage:
      "You've searched for a specific duration ({hours} hr {minutes} mins) but this Access Rule does not have duration picker enabled",
  },
  mainDescriptionOnlyDurationPickerEligible: {
    id: 'reasonsModal.accessRulesTargetingCategory.onlyDurationPickerEligible.mainDescription',
    defaultMessage: 'Your search does not specify a duration but this Access Rule has duration picker enabled',
  },
})

export const tablesMatchCategoryMessages = defineMessages({
  accordianHeader: {
    id: 'reasonsModal.tablesMatchCategory.accordianHeader',
    defaultMessage: `{count, plural,
      one {{numTables} Table matches search criteria but has other restrictions}
      other {{numTables} Tables match search criteria but have other restrictions}
    }`,
  },
  manageTablesButtonText: {
    id: 'reasonsModal.tablesMatchCategory.manageTablesButtonText',
    defaultMessage: 'Manage Tables',
  },
  mainDescriptionHeaderHardAssignedReservations: {
    id: 'reasonsModal.tablesMatchCategory.hardAssignedReservation.mainDescriptionHeader',
    defaultMessage: `{count, plural,
      one {{numTables} Table has an hard-assigned Reservation overlapping search time ({time})}
      other {{numTables} Tables have hard-assigned Reservations overlapping search time ({time})}
    }`,
  },
  mainDescriptionHardAssignedReservations: {
    id: 'reasonsModal.tablesMatchCategory.hardAssignedReservation.mainDescription',
    defaultMessage: '<a>Table {table}</a>',
  },
  mainDescriptionHeaderAutoAssignedReservations: {
    id: 'reasonsModal.tablesMatchCategory.autoAssignedReservation.mainDescriptionHeader',
    defaultMessage: `{count, plural,
      one {{numTables} Table has an auto-assigned Reservation overlapping search time ({time})}
      other {{numTables} Tables have auto-assigned Reservations overlapping search time ({time})}
    }`,
  },
  mainDescriptionAutoAssignedReservations: {
    id: 'reasonsModal.tablesMatchCategory.autoAssignedReservation.mainDescription',
    defaultMessage: '<a>Table {table}</a>',
  },
  mainDescriptionHeaderStartTimeBlock: {
    id: 'reasonsModal.tablesMatchCategory.startTimeBlock.mainDescriptionHeader',
    defaultMessage: `{count, plural,
      one {{numTables} Table has start time Block at search time ({time})}
      other {{numTables} Tables have start time Blocks at search time ({time})}
    }`,
  },
  mainDescriptionStartTimeBlock: {
    id: 'reasonsModal.tablesMatchCategory.startTimeBlock.mainDescription',
    defaultMessage: 'Table {table} is blocked (<a>{blockName}</a>)',
  },
  mainDescriptionHeaderBlocked: {
    id: 'reasonsModal.tablesMatchCategory.blocked.mainDescriptionHeader',
    defaultMessage: `{count, plural,
      one {{numTables} Table has Block at search time ({time})}
      other {{numTables} Tables have Blocks at search time ({time})}
    }`,
  },
  mainDescriptionBlocked: {
    id: 'reasonsModal.tablesMatchCategory.blocked.mainDescription',
    defaultMessage: 'Table {table} is blocked (<a>{blockName}</a>)',
  },
})

export const tablesTargetingCategoryMessages = defineMessages({
  accordianHeader: {
    id: 'reasonsModal.tablesTargetingCategory.accordianHeader',
    defaultMessage: `{count, plural,
      one {{numTables} Table does not match the criteria}
      other {{numTables} Tables do not match the criteria}
    }`,
  },
  manageTablesButtonText: {
    id: 'reasonsModal.tablesTargetingCategory.manageTablesButtonText',
    defaultMessage: 'Manage Tables',
  },
  mainDescriptionHeaderNoTables: {
    id: 'reasonsModal.tablesMatchCategory.noTables.mainDescriptionHeader',
    defaultMessage: 'No Tables',
  },
  mainDescriptionNoTables: {
    id: 'reasonsModal.tablesMatchCategory.noTables.mainDescription',
    defaultMessage: `{count, plural,
      one {There are no active Tables in the Seating Area selected on this Shift}
      other {There are no active Tables in the Seating Areas selected on this Shift}
    }`,
  },
  mainDescriptionHeaderNotFoundInShiftLayout: {
    id: 'reasonsModal.tablesMatchCategory.notFoundInShiftLayout.mainDescriptionHeader',
    defaultMessage: 'Table not found in Shift seating areas or Shift floorplan layout',
  },
  mainDescriptionNotFoundInShiftLayout: {
    id: 'reasonsModal.tablesMatchCategory.notFoundInShiftLayout.mainDescription',
    defaultMessage: 'Table {table}',
  },
  mainDescriptionHeaderNotInSeatingArea: {
    id: 'reasonsModal.tablesMatchCategory.notInSeatingArea.mainDescriptionHeader',
    defaultMessage: 'Table not included in Seating Area',
  },
  mainDescriptionNotInSeatingArea: {
    id: 'reasonsModal.tablesMatchCategory.notInSeatingArea.mainDescription',
    defaultMessage: 'Table {table}',
  },
  mainDescriptionHeaderNoAccessRules: {
    id: 'reasonsModal.tablesMatchCategory.noAccessRules.mainDescriptionHeader',
    defaultMessage: 'Table not included in the Seating Area or Tables selected on Access Rule',
  },
  mainDescriptionNoAccessRules: {
    id: 'reasonsModal.tablesMatchCategory.noAccessRules.mainDescription',
    defaultMessage: 'Table {table}',
  },
  mainDescriptionHeaderPartySizeMismatch: {
    id: 'reasonsModal.tablesMatchCategory.partySizeMismatch.mainDescriptionHeader',
    defaultMessage: 'Party size does not match search criteria',
  },
  mainDescriptionPartySizeMismatch: {
    id: 'reasonsModal.tablesMatchCategory.partySizeMismatch.mainDescription',
    defaultMessage: 'Table {table} <span>({min}-{max})</span>',
  },
})

export const combosMatchCategoryMessages = defineMessages({
  accordianHeader: {
    id: 'reasonsModal.combosMatchCategory.accordianHeader',
    defaultMessage: `{count, plural,
      one {{numCombos} Table Combination matches this criteria but has other restrictions}
      other {{numCombos} Table Combinations match this criteria but have other restrictions}
    }`,
  },
  manageCombinationsButtonText: {
    id: 'reasonsModal.combosMatchCategory.manageCombinationsButtonText',
    defaultMessage: 'Manage Combinations',
  },
  mainDescriptionHeaderStartTimeBlock: {
    id: 'reasonsModal.combosMatchCategory.startTimeBlock.mainDescriptionHeader',
    defaultMessage: `{count, plural,
      one {{numCombos} Table Combination has start time Block at search time ({time})}
      other {{numCombos} Table Combinations have start time Blocks at search time ({time})}
    }`,
  },
  mainDescriptionStartTimeBlock: {
    id: 'reasonsModal.combosMatchCategory.startTimeBlock.mainDescription',
    defaultMessage: 'Table Combination {combo} is blocked (<a>{blockName}</a>)',
  },
  mainDescriptionHeaderBlockedAtTime: {
    id: 'reasonsModal.combosMatchCategory.blockedAtTime.mainDescriptionHeader',
    defaultMessage: `{count, plural,
      one {{numCombos} Table Combination is blocked, preventing new reservations or overlapping durations at search time ({time})}
      other {{numCombos} Table Combinations ar blocked, preventing new reservations or overlapping durations at search time ({time})}
    }`,
  },
  mainDescriptionBlockedAtTime: {
    id: 'reasonsModal.combosMatchCategory.blockedAtTime.mainDescription',
    defaultMessage: 'Table Combination {combo} is blocked (<a>{blockName}</a>)',
  },
  mainDescriptionHeaderNotAllAvailable: {
    id: 'reasonsModal.combosMatchCategory.notAllAvailable.mainDescriptionHeader',
    defaultMessage: 'One or more tables in combination are unavailable (check the reasons above for details)',
  },
  mainDescriptionNotAllAvailable: {
    id: 'reasonsModal.combosMatchCategory.notAllAvailable.mainDescription',
    defaultMessage: 'Table Combination {combo}',
  },
})

export const combosTargetingCategoryMessages = defineMessages({
  accordianHeader: {
    id: 'reasonsModal.combosTargetingCategory.accordianHeader',
    defaultMessage: `{count, plural,
      one {{numCombos} Table Combination does not match this criteria}
      other {{numCombos} Table Combinations do not match this criteria}
    }`,
  },
  manageCombinationsButtonText: {
    id: 'reasonsModal.combosTargetingCategory.manageCombinationsButtonText',
    defaultMessage: 'Manage Combinations',
  },
  mainDescriptionHeaderPartySizeMismatch: {
    id: 'reasonsModal.combosTargetingCategory.partySizeMismatch.mainDescriptionHeader',
    defaultMessage: 'Party size does not match search criteria',
  },
  mainDescriptionPartySizeMismatch: {
    id: 'reasonsModal.combosTargetingCategory.partySizeMismatch.mainDescription',
    defaultMessage: 'Table Combination {combo} <span>({min}-{max})</span>',
  },
  mainDescriptionHeaderNoAccessRules: {
    id: 'reasonsModal.combosTargetingCategory.noAccessRules.mainDescriptionHeader',
    defaultMessage: 'Table combination is not included in the Seating Areas or Tables selected on Access Rule',
  },
  mainDescriptionNoAccessRules: {
    id: 'reasonsModal.combosTargetingCategory.noAccessRules.mainDescription',
    defaultMessage: 'Table Combination {combo}',
  },
})
