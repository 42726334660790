import * as React from 'react'
import styled, { css } from 'styled-components'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'

const NewItemTextInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const NewItemInputField = styled.input`
  padding: 10px;
  outline: none;
  flex-grow: 1;
  background-color: ${props => props.theme.color.white};
  border: 1px solid;
  border-color: ${props => (props.isFocused ? '#3e92d1' : '#dbdcde')};
  border-radius: 2px;
  font-size: 13px;
`

const FieldPlaceholder = styled.div`
  position: absolute;
  color: #dbdcde;
  margin-left: ${props => props.theme.gutter.standard};
  display: flex;
  align-items: center;
  pointer-events: none;
`

const FieldPlaceholderText = styled.span`
  font-size: 12px;
  margin-left: 10px;
`

const ClearIcon = styled.span`
  position: absolute;
  color: #dbdcde;
  right: 10px;
  cursor: pointer;

  &:hover {
    color: #3e92d1;
  }
`

const ActionIcon = styled(StyledIcons.XS)``

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
`

const NewItemAddButton = styled.button`
  padding: 10px 30px;
  outline: none;
  margin-left: ${props => props.theme.gutter.standard};
  font-weight: 200;
  font-size: 13px;
  font-family: Roboto;
  text-align: center;
  flex-shrink: 0;
  border-radius: 2px;
  color: ${props => (props.isFocused ? '#3e92d1' : '#dbdcde')};
  background-color: white;
  box-sizing: border-box;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border: 1px solid;
  border-color: ${props => (props.isFocused ? '#3e92d1' : '#dbdcde')};

  ${props =>
    !props.disabled &&
    css`
      :hover {
        opacity: 0.8;
        background: #e8f5ff;
      }
    `} :active {
    opacity: 1;
  }
  ${props => (props.disabled ? 'opacity: 0.8;' : '')};
`

export default class NewItemTextInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isFocused: false, value: '' }
  }

  onChangeHandler(e) {
    this.setState({ value: e.target.value })
  }

  onFocusHandler = () => {
    this.setState({ isFocused: true })
  }

  onBlurHandler = () => {
    this.setState({ isFocused: false })
  }

  onKeyPress = e => {
    if (this.state.value && e.key === 'Enter') {
      this.confirm()
    }
  }

  confirm = () => {
    if (this.state.value) {
      this.props.onConfirm(this.state.value)
      this.clear()
    }
  }

  clear = () => {
    this.setState({ value: '' })
  }

  render() {
    const shouldShowPlaceholder = !this.state.isFocused && !this.state.value
    const hasValue = Boolean(this.state.value.trim())

    return (
      <NewItemTextInputContainer>
        <InputWrapper>
          <NewItemInputField
            data-test="new-item-input-field"
            value={this.state.value}
            onChange={event => this.onChangeHandler(event)}
            onKeyPress={this.onKeyPress}
            isFocused={this.state.isFocused}
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
          />

          {shouldShowPlaceholder && (
            <FieldPlaceholder>
              <ActionIcon>{VmsIcons.Plus}</ActionIcon>
              <FieldPlaceholderText>{this.props.placeholder || 'type and press enter'}</FieldPlaceholderText>
            </FieldPlaceholder>
          )}

          {hasValue && (
            <ClearIcon onClick={this.clear}>
              <ActionIcon>{VmsIcons.Close}</ActionIcon>
            </ClearIcon>
          )}
        </InputWrapper>

        <NewItemAddButton isFocused={this.state.isFocused} onClick={this.confirm} disabled={!hasValue}>
          {this.props.buttonText || 'Add'}
        </NewItemAddButton>
      </NewItemTextInputContainer>
    )
  }
}
