import { useCallback, useMemo, useState } from 'react'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Window } from '@sevenrooms/core/ui-kit/layout'
import { ExcludedDateRangeModal } from './ExcludedDateRangeModal'
import { ExcludedDateRangesTable } from './ExcludedDateRangesTable'
import type { OrderingMenu, ExcludedDateRange } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface ExcludedDateRangesProps {
  menu: OrderingMenu
  onAddMenuExcludedDateRange: (excludedDateRange: ExcludedDateRange) => void
  onUpdateMenuExcludedDateRange: (index: number, excludedDateRange: ExcludedDateRange) => void
  onRemoveExcludedDateRange: (index: number) => void
}

export function ExcludedDateRanges({
  menu,
  onAddMenuExcludedDateRange,
  onUpdateMenuExcludedDateRange,
  onRemoveExcludedDateRange,
}: ExcludedDateRangesProps) {
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [selectedExcludedDateRangeIndex, setSelectedExcludedDateRangeIndex] = useState<number>(-1)
  const selectedExcludedDateRange = useMemo(
    () => menu.excludedDateRanges[selectedExcludedDateRangeIndex],
    [menu, selectedExcludedDateRangeIndex]
  )
  const onAdd = useCallback(() => {
    setSelectedExcludedDateRangeIndex(-1)
    setEditModalVisible(true)
  }, [setSelectedExcludedDateRangeIndex, setEditModalVisible])
  const onEdit = useCallback(
    (index: number) => {
      setSelectedExcludedDateRangeIndex(index)
      setEditModalVisible(true)
    },
    [setSelectedExcludedDateRangeIndex, setEditModalVisible]
  )
  const onDelete = useCallback(
    (index: number) => {
      setSelectedExcludedDateRangeIndex(index)
      setDeleteModalVisible(true)
    },
    [setSelectedExcludedDateRangeIndex, setDeleteModalVisible]
  )
  const onEditCancel = useCallback(() => {
    setSelectedExcludedDateRangeIndex(-1)
    setEditModalVisible(false)
  }, [setSelectedExcludedDateRangeIndex, setEditModalVisible])
  const onEditSave = useCallback(
    (excludedDateRange: ExcludedDateRange) => {
      if (selectedExcludedDateRangeIndex === -1) {
        onAddMenuExcludedDateRange(excludedDateRange)
      } else {
        onUpdateMenuExcludedDateRange(selectedExcludedDateRangeIndex, excludedDateRange)
      }
      setSelectedExcludedDateRangeIndex(-1)
      setEditModalVisible(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onAddMenuExcludedDateRange, selectedExcludedDateRangeIndex, setSelectedExcludedDateRangeIndex, setEditModalVisible]
  )
  const onEditSaveAndAddNew = useCallback(
    (excludedDateRange: ExcludedDateRange) => {
      onEditSave(excludedDateRange)
      setEditModalVisible(true)
    },
    [onEditSave]
  )
  return (
    <>
      <Box pt="m" pb="m">
        <Button data-test="menu-management-add-menu-excluded-date-range" variant="secondary" icon="VMSWeb-add" onClick={onAdd}>
          Add excluded dates
        </Button>
      </Box>
      <Box>
        <ExcludedDateRangesTable excludedDateRanges={menu.excludedDateRanges} onEdit={onEdit} onDelete={onDelete} />
      </Box>
      <Window active={isEditModalVisible}>
        <ExcludedDateRangeModal
          excludedDateRange={selectedExcludedDateRange}
          onCancel={onEditCancel}
          onSave={onEditSave}
          onSaveAndAddNew={onEditSaveAndAddNew}
        />
      </Window>
      {isDeleteModalVisible && (
        <MessageBox
          handleActionClick={() => {
            if (selectedExcludedDateRangeIndex >= 0) {
              onRemoveExcludedDateRange(selectedExcludedDateRangeIndex)
            }
            setDeleteModalVisible(false)
          }}
          handleCloseClick={() => {
            setDeleteModalVisible(false)
          }}
          dialogType={MessageBox.DialogType.WARNING}
          header="Warning"
          explain="Warning"
          details={[]}
          explanation="Are you sure that you want to delete this excluded date range?"
          actionButtonText="Delete"
        />
      )}
    </>
  )
}
