// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { getContrastingColor } from 'svr/common/Color'
import { StyledIcons } from 'svr/common/VmsIcons'

const IconTagContainerWrapper = styled.div`
  display: inline-block;
`
const IconTagsContainer = styled.div`
  color: ${props => props.color};
  display: inline-block;
`
const IconTagWrapper = styled.div`
  display: ${props => (props.isVisible ? 'inline-block' : 'none')};
  padding: 4px 2px;
  cursor: pointer;
`
const IconTag = styled.div`
  cursor: pointer;
  background-color: ${props => props.hexColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 7px 12px;
  word-break: break-word;
  white-space: pre-wrap;
`

const IconTagText = styled.div`
  display: inline-block;
  font-size: 12px;
`
const TagIcon = styled(StyledIcons.XS)`
  padding: 0 0 0 ${props => props.theme.gutter.standard};
`

/* type Props = {
  className: string,
  hexColor: string,
  filterVal: string,
  selectedIcon: mixed,
  unselectedIcon: mixed,
  tags: Array<string | (() => mixed)>,
  onClickHandler: Function,
} */

const IconTags = (props /* : Props */) => (
  <IconTagContainerWrapper>
    {_.map(props.tags, (tag, idx) => (
      <IconTagsContainer
        key={`icon-tags-container-${idx}`}
        className={`${props.className}-container`}
        color={getContrastingColor(tag.hexColor)}
      >
        <IconTagWrapper
          key={`icon-tags-wrapper-${idx}`}
          className={`${props.className}-wrapper`}
          onClick={e => props.onClickHandler(idx, tag.classifier)}
          isVisible={
            props.filterVal && props.filterVal.length !== 0 ? tag.name.toLowerCase().includes(props.filterVal.toLowerCase()) : true
          }
        >
          <IconTag key={`icon-tag-${idx}`} className={props.className} hexColor={tag.hexColor}>
            <IconTagText className={`${props.className}-name`}>{tag.name}</IconTagText>
            <TagIcon className={`${props.className}-icon`}>{tag.isSelected ? props.selectedIcon : props.unselectedIcon}</TagIcon>
          </IconTag>
        </IconTagWrapper>
      </IconTagsContainer>
    ))}
  </IconTagContainerWrapper>
)

IconTags.defaultProps = {
  className: '',
  filterVal: '',
  selectedIcon: null,
  unselectedIcon: null,
  tags: [],
  onClickHandler: null,
}

export default IconTags
