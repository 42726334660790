import { useMemo } from 'react'
import { z } from '@sevenrooms/core/form'
import { useDateRangePickerOptionalForm } from '@sevenrooms/core/ui-kit/form'

export function useVenueGroupActivityLogForm() {
  const dateRange = useDateRangePickerOptionalForm()

  return useMemo(
    () =>
      z.object({
        dateRange,
        limit: z.number().min(1).default(100),
        cursor: z.string().optional(),
        user: z.string().default('ALL'),
        category: z.string().default('ALL'),
      }),
    [dateRange]
  )
}
