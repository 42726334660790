import { TimeLocale } from '@sevenrooms/core/timepiece'
import { Text, type TextProps } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core/typings'

export const formatBold = (chunks: string[]) => (
  <Text fontWeight="bold" color="inherit">
    {chunks}
  </Text>
)

export const formatParagraph = (chunks: string[], type: TextProps['type'] = 'p') => (
  <Text type={type} color="inherit">
    {chunks}
  </Text>
)

export const formatCurrency = (venue: Venue, amount: number) =>
  Intl.NumberFormat(TimeLocale.getLocale(), { style: 'currency', currency: venue.currencyCode }).format(amount)
