import type { PRODUCT_TYPES_ALL } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

export const LIGHTSPEED = 'LIGHTSPEED'
export const LIGHTSPEED_KOUNTA = 'LIGHTSPEED_KOUNTA'

export const FEE_AMOUNT = {
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'AMOUNT',
  BOTH: 'BOTH',
  PASS_THROUGH: 'PASS_THROUGH',
}

export enum MiddlewareSource {
  NO_POS = 'SEVENROOMS_NO_POS',
  SEVENROOMS_INFOGENESIS = 'SEVENROOMS_INFOGENESIS',
  SEVENROOMS_SIMPHONY2 = 'SEVENROOMS_SIMPHONY2',
  DELIVERECT = 'DELIVERECT',
  CHECKMATE = 'CHECKMATE',
}

export enum ProductTagsTypes {
  ALLERGEN = 'allergen',
  NON_ALLERGEN = 'non_allergen',
}

export const PRODUCT_TYPES_DISPLAY_NAMES: Record<PRODUCT_TYPES_ALL, string> = {
  ALL: 'All',
  MENU_ITEM: 'Menu Item',
  MODIFIER_GROUP: 'Modifier Group',
  MODIFIER: 'Modifier',
}

export const PAY_LATER_MIDDLEWARES: Set<string> = new Set([
  MiddlewareSource.NO_POS,
  MiddlewareSource.SEVENROOMS_INFOGENESIS,
  MiddlewareSource.SEVENROOMS_SIMPHONY2,
  MiddlewareSource.DELIVERECT,
])
