// /* @flow */
/* eslint react/prop-types: 0 */
import _ from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { StyledIcons, VmsIcons } from 'svr/common/VmsIcons'
import StaticTags from 'svr/component-lib/Manager/Containers/StaticTags'

const TagCategoryContainer = styled.div`
  display: block;
  border: 1px solid #dbdcde;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${props => (props.isDisabled ? props.theme.color.lightGrey : props.theme.color.white)};
`

const TagCategoryWrapper = styled.div`
  padding: ${props => (props.isDisabled ? '24px' : '14px 24px 24px 24px')};
`
const TagLineWrapper = styled.div`
  width: 100%;
`
const TagHeadLiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TagSubLiner = styled.div`
  display: flex;
  color: #888c92;
  font-size: 12px;
  padding: 5px 0 0 0;
`
const CategoryTitleSection = styled.div`
  display: inline-block;
`
const CategoryIconSection = styled.div`
  display: inline-block;
`
const IconWrapper = styled.div`
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
  background-color: ${props => (props.isHover ? '#E8F5FF' : props.theme.color.white)};
`
const SpacedIconWrapper = styled(IconWrapper)`
  margin: 0 10px 0 0;
`
const CategoryIcon = styled(StyledIcons.XS)``
const EditIcon = styled(StyledIcons.S)`
  color: ${props => (props.isHover ? '#347baf' : '#888C92')};
  cursor: pointer;
`
const DeleteIcon = styled(StyledIcons.S)`
  color: ${props => (props.isHover ? '#347baf' : '#888C92')};
  cursor: pointer;
`
const DisableIcon = styled(StyledIcons.S)`
  color: ${props => (props.isHover ? '#347baf' : '#888C92')};
  cursor: pointer;
`
const LockIcon = styled(StyledIcons.XS)`
  padding: 0 0 0 4px;
`
const CategoryTitle = styled.div`
  display: inline-flex;
  font-size: 15px;
  font-weight: bold;
  padding: 1px 0 0 8px;
`
const EnableBtn = styled.div`
  color: ${props => (props.isHover ? props.theme.primary : '#888C92')};
  background-color: ${props => (props.isHover ? '#E8F5FF' : props.theme.color.white)};
  border: 1px solid ${props => (props.isHover ? '#347baf' : '#DBDCDE')};
  border-radius: 4px;
  font-size: 15px;
  padding: 12px;
  cursor: pointer;
`
const StaticTagsWrapper = styled.div`
  padding: ${props => props.theme.gutter.double} 0 0 0;
`

export class TagCategory extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      enableHover: false,
      deleteHover: false,
      editHover: false,
      disableHover: false,
    }
    this.offActionHover = this.offActionHover.bind(this)
    this.onEnableHover = this.onEnableHover.bind(this)
    this.onDeleteHover = this.onDeleteHover.bind(this)
    this.onEditHover = this.onEditHover.bind(this)
    this.onDisableHover = this.onDisableHover.bind(this)
    this.editClickHandler = this.editClickHandler.bind(this)
    this.enableClickHandler = this.enableClickHandler.bind(this)
    this.disableClickHandler = this.disableClickHandler.bind(this)
    this.deleteClickHandler = this.deleteClickHandler.bind(this)
  }

  offActionHover() {
    this.setState({
      enableHover: false,
      deleteHover: false,
      editHover: false,
      disableHover: false,
    })
  }

  onEnableHover() {
    this.setState({
      enableHover: true,
      deleteHover: false,
      editHover: false,
      disableHover: false,
    })
  }

  onDeleteHover() {
    this.setState({
      enableHover: false,
      deleteHover: true,
      editHover: false,
      disableHover: false,
    })
  }

  onEditHover() {
    this.setState({
      enableHover: false,
      deleteHover: false,
      editHover: true,
      disableHover: false,
    })
  }

  onDisableHover() {
    this.setState({
      enableHover: false,
      deleteHover: false,
      editHover: false,
      disableHover: true,
    })
  }

  editClickHandler() {
    this.props.onEditClick(this.props.categoryId)
  }

  enableClickHandler() {
    this.props.onEnableClick(this.props.categoryId)
  }

  deleteClickHandler() {
    this.props.onDeleteClick(this.props.categoryId)
  }

  disableClickHandler() {
    this.props.onDisableClick(this.props.categoryId)
  }

  render() {
    const categoryName = _.filter([
      this.props.categoryNameDisplay || this.props.categoryName,
      this.props.isVip && '(VIP)',
      this.props.isDefaultCategory && '- default',
      this.props.isDisabled && '(disabled)',
    ]).join(' ')
    const categoryCaption = _.filter([
      !this.props.isPodTag && 'Global',
      this.props.superuserVisibleOnly && 'Visible to Superuser only',
      this.props.isRestricted && 'Restricted tag category',
      this.props.showReservation && 'Show on reservation',
      this.props.showChit && 'Show on chit',
    ]).join(', ')
    const MAX_VISIBLE_PODS = 4
    const podNames = _.map(this.props.pods, pod => pod.name)
    const exceedsMaxPods = this.props.pods.length > MAX_VISIBLE_PODS
    let podNameLine = 'Visible to Pod(s): '
    if (exceedsMaxPods) {
      const numPodsLeft = this.props.pods.length - (MAX_VISIBLE_PODS + 1)
      podNameLine += `${podNames.slice(0, 3).join(', ')} + ${numPodsLeft} more`
    } else if (podNames.length === 0) {
      podNameLine += 'No Pods Selected'
    } else {
      podNameLine += podNames.join(', ')
    }
    return (
      <TagCategoryContainer id="tag-category-container-" isDisabled={this.props.isDisabled}>
        <TagCategoryWrapper isDisabled={this.props.isDisabled}>
          <TagHeadLiner>
            <TagLineWrapper>
              <TagHeadLiner>
                <CategoryTitleSection>
                  <CategoryIcon>{this.props.categoryIcon}</CategoryIcon>
                  <CategoryTitle data-test="tag-category-title" className={`tag-category-title-${this.props.categoryName}`}>
                    {categoryName}
                    {this.props.isRestricted && <LockIcon>{this.props.restrictedIcon}</LockIcon>}
                  </CategoryTitle>
                </CategoryTitleSection>
                {!this.props.isDisabled && (
                  <CategoryIconSection>
                    {!this.props.isDefaultCategory && this.props.userCanAdministrateTagCategory && (
                      <SpacedIconWrapper isHover={this.state.deleteHover}>
                        <DeleteIcon
                          className={`tag-category-delete-${this.props.categoryName}`}
                          isHover={this.state.deleteHover}
                          onMouseOver={this.onDeleteHover}
                          onMouseOut={this.offActionHover}
                          onClick={this.deleteClickHandler}
                        >
                          {VmsIcons.Delete}
                        </DeleteIcon>
                      </SpacedIconWrapper>
                    )}
                    {this.props.isDefaultCategory && this.props.userCanAdministrateTagCategory && (
                      <SpacedIconWrapper isHover={this.state.disableHover}>
                        <DisableIcon
                          className={`tag-category-disable-${this.props.categoryName}`}
                          isHover={this.state.disableHover}
                          onMouseOver={this.onDisableHover}
                          onMouseOut={this.offActionHover}
                          onClick={this.disableClickHandler}
                        >
                          {VmsIcons.NotInterested}
                        </DisableIcon>
                      </SpacedIconWrapper>
                    )}
                    {this.props.userCanAdministrateTagCategory && (
                      <IconWrapper isHover={this.state.editHover}>
                        <EditIcon
                          className={`tag-category-edit-${this.props.categoryName}`}
                          isHover={this.state.editHover}
                          onMouseOver={this.onEditHover}
                          onMouseOut={this.offActionHover}
                          onClick={this.editClickHandler}
                        >
                          {VmsIcons.Settings}
                        </EditIcon>
                      </IconWrapper>
                    )}
                  </CategoryIconSection>
                )}
              </TagHeadLiner>
              <TagSubLiner className={`tag-category-caption-${this.props.categoryName}`}>
                {this.props.isPodTag && `${podNameLine}. `} {categoryCaption}
              </TagSubLiner>
            </TagLineWrapper>
            {this.props.isDisabled && this.props.userCanAdministrateTagCategory && (
              <EnableBtn
                id={`tag-category-enable-${this.props.categoryName}`}
                isHover={this.state.enableHover}
                onMouseOver={this.onEnableHover}
                onMouseOut={this.offActionHover}
                onClick={this.enableClickHandler}
              >
                Enable
              </EnableBtn>
            )}
          </TagHeadLiner>
          {!this.props.isDisabled && (
            <StaticTagsWrapper>
              <StaticTags
                className={`tag-category-tags-${this.props.categoryName}`}
                hexColor={this.props.hexColor}
                tagNames={this.props.tagNames}
                tagNameDisplays={this.props.tagNameDisplays}
              />
            </StaticTagsWrapper>
          )}
        </TagCategoryWrapper>
      </TagCategoryContainer>
    )
  }
}

export default TagCategory
