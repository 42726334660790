import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { ReportingDashboard } from '@sevenrooms/mgr-reporting/views/ReportingDashboard'

class LookerReportingLayout extends PureComponent {
  render() {
    const { venueGroup, userDomain, history } = this.props

    return (
      <ContentLayout
        hideTitleBar
        venueGroup={venueGroup}
        userDomain={userDomain}
        onChangeVenueGroup={newVenueGroup => {
          history.push(`/group/${newVenueGroup.id}`)
          window.location.reload()
        }}
        overrideChangeVenue
      >
        <ReportingDashboard isGroup />
      </ContentLayout>
    )
  }
}
const mapStateToProps = state => ({
  venueGroup: state.appState.venueGroup,
  userDomain: state.appState.userDomain,
})

export default withRouter(connect(mapStateToProps, null)(LookerReportingLayout))
