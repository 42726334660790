import Radium from 'radium'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { merge } from './SRUtils'

class LoaderBar extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const styles = {
      body: {
        display: this.props.isLoading ? 'inline-block' : 'none',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        height: '2px',
        zIndex: '10000',
      },
      line: {
        position: 'absolute',
        background: this.props.color,
        width: '100%',
        height: '100%',
      },
      break: {
        position: 'absolute',
        background: '#fff',
        width: '1px',
        height: '100%',
      },
    }
    return (
      <div className="loaderBar" style={styles.body}>
        <div style={styles.line} />
        <div key="dot1" className="dot1" style={styles.break} />
        <div key="dot2" className="dot2" style={styles.break} />
      </div>
    )
  }
}

LoaderBar.defaultProps = {
  isLoading: false,
  color: '#031927',
}

export default LoaderBar
