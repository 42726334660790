import moment from 'moment-timezone'
import { processFail, showSuccessMessage } from 'mgr/lib/actions/GlobalActions'
import { getSnoozedProductListByVenueId, postProductSnooze } from 'mgr/lib/services/EightySixProductServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { SNOOZE_OPTIONS } from 'mgr/pages/single-venue/settings/reducers/ordering/EightySixProduct'
import { batchActions } from 'svr/common/ReduxUtils'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { EightySixProduct } from 'mgr/pages/single-venue/settings/types/ordering/EightySixProduct.types'
import type { Moment } from 'moment'
import type { Dispatch } from 'redux'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export type Product86Action =
  | GetOrderingProductsListStartAction
  | GetOrderingProductsListSuccessAction
  | GetOrderingProductsListFailAction
  | CloseSnoozeModalDispatchType
  | SubmitSnoozeProductStartAction
  | SubmitSnoozeProductSuccessAction
  | SubmitSnoozeProductFailAction
  | ReturnType<typeof setSpecificSnoozeDate>
  | ReturnType<typeof setProductSnoozeTypeFilter>
  | ReturnType<typeof setProductSnoozeFilterSearchValue>
  | ReturnType<typeof setProductSnoozeSortedColumns>
  | ReturnType<typeof selectProductSnoozeStateTab>

type CloseSnoozeModalDispatchType = ReturnType<typeof selectSnoozeOption> | ReturnType<typeof selectProductSnoozeItem>
export const closeSnoozeModal = () => (dispatch: Dispatch<CloseSnoozeModalDispatchType>) => {
  dispatch(batchActions([selectSnoozeOption(SNOOZE_OPTIONS[0]?.id as string), selectProductSnoozeItem(null)]))
}

export const setSpecificSnoozeDate = (date: Moment) =>
  ({
    type: ActionTypes.SET_SPECIFIC_SNOOZE_DATE,
    date,
  } as const)

export const setProductSnoozeTypeFilter = (value: string) =>
  ({
    type: ActionTypes.SET_PRODUCT_TYPE_FILTER,
    value,
  } as const)

export const setProductSnoozeFilterSearchValue = (value: string) =>
  ({
    type: ActionTypes.SET_PRODUCT_FILTER_SEARCH_VALUE,
    value,
  } as const)

export const setProductSnoozeSortedColumns = (data: SortColumn[]) =>
  ({
    type: ActionTypes.SET_ORDERING_PRODUCT_SORTED_COLUMNS,
    data,
  } as const)

export const selectProductSnoozeStateTab = (productStateId: string) =>
  ({
    type: ActionTypes.SELECT_PRODUCT_STATE_TAB,
    productStateId,
  } as const)

const getStartAndEndSnoozeTimes = (snoozeOptionId: string, startOfDayHour: number, selectedSpecificSnoozeDate: Moment) => {
  const snoozeOption = SNOOZE_OPTIONS.find(option => option.id === snoozeOptionId)

  if (!snoozeOption) {
    throw new Error('Invalid snooze option id')
  }

  const times = snoozeOption.calcSnoozeTimes(moment(), startOfDayHour, selectedSpecificSnoozeDate)
  const start = times.snoozeStartTime
  const end = times.snoozeEndTime

  return {
    snoozeStartTime: start && start.toISOString(),
    snoozeEndTime: end ? end.toISOString() : null,
  }
}

export const selectProductSnoozeItem = (product: EightySixProduct | null) =>
  ({
    type: ActionTypes.SELECT_PRODUCT_ITEM,
    product,
  } as const)

export const selectSnoozeOption = (snoozeOptionId: string) =>
  ({
    type: ActionTypes.SELECT_SNOOZE_OPTION,
    snoozeOptionId,
  } as const)

export interface SubmitSnoozeProductStartAction {
  type: typeof ActionTypes.SUBMIT_SNOOZE_PRODUCT_START
}

export interface SubmitSnoozeProductSuccessAction {
  type: typeof ActionTypes.SUBMIT_SNOOZE_PRODUCT_SUCCESS
}

export interface SubmitSnoozeProductFailAction {
  type: typeof ActionTypes.SUBMIT_SNOOZE_PRODUCT_FAIL
}

const productSnoozeSuccessMessage = 'Product successfully snoozed.'

const productUnsnoozeSuccessMessage = 'Product successfully unsnoozed.'

export const submitProductSnooze =
  (product: EightySixProduct, snoozeOptionId: string) =>
  (
    dispatch: Dispatch<
      | CloseSnoozeModalDispatchType
      | LoadSnoozedProductListByVenueIdDispatchType
      | SubmitSnoozeProductStartAction
      | SubmitSnoozeProductSuccessAction
      | ReturnType<typeof showSuccessMessage>
    >,
    getState: () => State
  ) => {
    const state = getState()
    const { venue } = state.appState
    const { selectedSpecificSnoozeDate } = state.orderingProducts
    const { snoozeStartTime, snoozeEndTime } = getStartAndEndSnoozeTimes(snoozeOptionId, venue.startOfDayHour, selectedSpecificSnoozeDate)
    const postProductSnoozeSuccessMessage = snoozeStartTime ? productSnoozeSuccessMessage : productUnsnoozeSuccessMessage

    dispatch(
      batchActions([
        {
          type: ActionTypes.SUBMIT_SNOOZE_PRODUCT_START,
        },
        closeSnoozeModal(),
      ])
    )

    return postProductSnooze(venue.id, [
      {
        ...product,
        snoozeStartTime,
        snoozeEndTime,
      },
    ] as EightySixProduct[]).then(
      () => {
        dispatch(
          batchActions([
            {
              type: ActionTypes.SUBMIT_SNOOZE_PRODUCT_SUCCESS,
            },
            showSuccessMessage(postProductSnoozeSuccessMessage),
            loadSnoozedProductListByVenueId(venue.id),
          ])
        )
      },
      e => {
        // eslint-disable-next-line no-console
        console.error('An error occurred snoozing the product', e)

        dispatch(
          processFail(
            'Unable to snooze product, please try again later or contact customer support.',
            ActionTypes.SUBMIT_SNOOZE_PRODUCT_FAIL
          )
        )

        return e
      }
    )
  }

export interface GetOrderingProductsListStartAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCTS_LIST_START
}

export interface GetOrderingProductsListSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCTS_LIST_SUCCESS
  data: EightySixProduct[]
}

export interface GetOrderingProductsListFailAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCTS_LIST_FAIL
}

type LoadSnoozedProductListByVenueIdDispatchType =
  | GetOrderingProductsListStartAction
  | GetOrderingProductsListSuccessAction
  | GetOrderingProductsListFailAction
  | ReturnType<typeof showSuccessMessage>
export const loadSnoozedProductListByVenueId = (venueId: string) => (dispatch: Dispatch<LoadSnoozedProductListByVenueIdDispatchType>) => {
  dispatch({
    type: ActionTypes.GET_ORDERING_PRODUCTS_LIST_START,
  })

  return getSnoozedProductListByVenueId(venueId).then(
    response =>
      dispatch({
        type: ActionTypes.GET_ORDERING_PRODUCTS_LIST_SUCCESS,
        data: response.data.products,
      }),
    e => {
      // eslint-disable-next-line no-console
      console.error('An error occurred fetching product list', e)

      dispatch(
        processFail(
          'Unable to fetch products, please try again later or contact customer support.',
          ActionTypes.GET_ORDERING_PRODUCTS_LIST_FAIL
        )
      )

      return e
    }
  )
}
