import React from 'react'
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, DividerLine, HStack } from '@sevenrooms/core/ui-kit/layout'

export interface NavigationDashboardSearchProps {
  placeholder: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  dataTest?: string
}

export function NavigationDashboardSearch({ placeholder, setSearch, dataTest }: NavigationDashboardSearchProps) {
  return (
    <Box>
      <HStack alignItems="center">
        <Icon name="VMSWeb-search" size="lg" />
        <Box ml="s" width="100%">
          <NavigationDashboardSearchInput
            placeholder={placeholder}
            onChange={event => setSearch(event.target.value)}
            data-test={dataTest}
          />
        </Box>
      </HStack>
      <DividerLine color="borders" ml="none" mt="s" />
    </Box>
  )
}

const NavigationDashboardSearchInput = styled.input`
  ${props => css`
    background-color: ${props.theme.colors.margin};
    font: ${props.theme.textStyles.h2};
  `}
  border: none;
  outline: none;
  width: 100%;
`
