import { connect } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import type { Venue, VenueSettings, UserDomain, UserDomainVenue } from '@sevenrooms/mgr-core'
import { EmailLanguageSettings } from '@sevenrooms/mgr-settings/Settings/LanguageSettings'
import type { match } from 'react-router'

interface EmailSettingsProps {
  readonly match: match
  readonly venue: Venue
  readonly venues: UserDomainVenue[]
  readonly onChangeVenue: (venue: UserDomainVenue) => void
}

function EmailSettingsComponent({ venue, venues, onChangeVenue, match }: EmailSettingsProps) {
  const routes = {
    RESERVATION_CONFIRMATION: `${match.path}/reservation_confirmation`,
    RESERVATION_UPDATE: `${match.path}/reservation_update`,
    RESERVATION_CANCELLATION: `${match.path}/reservation_cancellation`,
    FEEDBACK_REQUEST_RES_EMAIL: `${match.path}/feedback_request_language`,
    FEEDBACK_REQUEST_ORDER_EMAIL: `${match.path}/order_feedback_request_language`,
    PAYLINK_REQUEST_EMAIL: `${match.path}/paylink_request_language`,
    COMPLETED_TRANSACTION_EMAIL: `${match.path}/completed_transaction_language`,
    RESERVATION_REMINDER_EMAIL: `${match.path}/reservation_reminder`,
    GUESTLIST_CONFIRMATION: `${match.path}/guestlist_confirmation`,
    REQUEST_EMAIL: `${match.path}/request_email`,
    EVENT_CONFIRMATION: `${match.path}/event_confirmation`,
    EMAILS_PRIORITY_ALERTS: `${match.path}/emails_priority_alerts`,
    MARKETING_SUBSCRIPTION_CONFIRMATION: `${match.path}/marketing_subscription_confirmation`,
    CONTINUED_MARKETING_SUBSCRIPTION_CONFIRMATION_EMAIL: `${match.path}/continued_marketing_subscription_confirmation_email`,
    SUBSCRIPTION_OPT_IN: `${match.path}/subscription_opt_in`,
  }

  return <EmailLanguageSettings routes={routes} venue={venue} venues={venues} onChangeVenue={onChangeVenue} />
}

const mapStateToProps = (state: { appState: { venue: Venue; userDomain: UserDomain; venueSettings: VenueSettings } }) => {
  const { venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
}

export const EmailSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(EmailSettingsComponent)
