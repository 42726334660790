import { useSelector } from 'react-redux'
import { Desc, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import TagMapping from 'mgr/pages/single-venue/settings/containers/TagMapping'
import { useLocales } from '@sevenrooms/core/locales'
import type { RootState } from '@sevenrooms/mgr-core'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { ImportLocales } from '../Import.locales'

interface TagColumnMappingProps {
  tagsToMapping: string[]
  handleOpenPopUp: () => void
  handleClickBack: () => void
}

export default function TagColumnMapping(props: TagColumnMappingProps) {
  const { tagsToMapping, handleOpenPopUp, handleClickBack } = props
  const { formatMessage } = useLocales()
  const tagGroups = useSelector((state: RootState) => state.reservationAndClientImport.tagGroups)
  const blobstoreUploadKey = useSelector((state: RootState) => state.reservationAndClientImport.blobstoreUploadKey)

  return (
    <>
      <Desc>{formatMessage(ImportLocales.step4)}</Desc>
      <SubDesc>{formatMessage(ImportLocales.mapTagsFromFile)}</SubDesc>
      <TagMapping tagsToMapping={tagsToMapping} tagGroups={tagGroups} />
      <Desc>{formatMessage(ImportLocales.step4)}</Desc>
      {!blobstoreUploadKey ? (
        <SubDesc>{formatMessage(ImportLocales.fileUploading)}</SubDesc>
      ) : (
        <SubDesc>{formatMessage(ImportLocales.submitWhenMapped)}</SubDesc>
      )}
      <Box marginTop="20px">
        <Button variant="contained" onClick={handleClickBack} style={{ marginRight: '20px' }} data-test="tag-column-mapping-back">
          {formatMessage(ImportLocales.backStep)}
        </Button>
        <Button
          variant="contained"
          onClick={handleOpenPopUp}
          className="sr-start-import-flatfile"
          disabled={!blobstoreUploadKey}
          data-test="tag-column-mapping-submit"
        >
          {formatMessage(ImportLocales.submit)}
        </Button>
      </Box>
    </>
  )
}
