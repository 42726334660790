import { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as OrderPacingActions from 'mgr/pages/single-venue/settings/actions/ordering/OrderPacingActions'
import { PacingRuleForm } from 'mgr/pages/single-venue/settings/components/ordering/OrderPacing/PacingRuleForm'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { Route } from 'mgr/pages/single-venue/settings/settings.types'
import type { TimeRange, OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { ORDER_METHOD_TYPE } from 'mgr/pages/single-venue/settings/types/ordering/Order.types'
import type {
  PacingRuleRouteParams,
  PacingRule,
  OrderingSiteOption,
  DayTimeLimitMatrix,
} from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'
import type { RouteComponentProps } from 'react-router'

export interface PacingRuleProps extends RouteComponentProps<PacingRuleRouteParams> {
  routes: Route[]
  isSaving: boolean
  venue: Venue
  venues: UserDomainVenue[]
  onChangeVenue: (venue: UserDomainVenue) => void
  pacingRule: PacingRule
  orderingSites: OrderingSiteOption[]
  menus: OrderingMenu[]
  defaultMenuHours: TimeRange
  clearPacingRuleData: () => void
  updateName: (value: string) => void
  updateDateRangeFrom: (value: Date) => void
  updateDateRangeTo: (value: Date | null) => void
  updateOrderMethods: (value: ORDER_METHOD_TYPE[]) => void
  updateOrderingSiteIds: (value: string[]) => void
  updateTimeRangeFrom: (value: moment.Moment) => void
  updateTimeRangeTo: (value: moment.Moment) => void
  updateTimeIntervalMins: (value: number) => void
  updateDayTimeLimitMatrix: (value: DayTimeLimitMatrix) => void
  loadPacingRuleById: (venueId: string, pacingRuleId: string) => Promise<void>
  loadPacingRuleResources: (venueId: string) => Promise<void>
  savePacingRuleById: (venueId: string, pacingRuleId: string, pacingRuleData: PacingRule) => Promise<void>
  createNewPacingRule: (venueId: string, pacingRuleData: Omit<PacingRule, 'id'>) => Promise<void>
}

function PacingRuleEdit({
  routes,
  match,
  history,
  venue,
  venues,
  onChangeVenue,
  pacingRule,
  orderingSites,
  menus,
  defaultMenuHours,
  clearPacingRuleData,
  updateName,
  updateDateRangeFrom,
  updateDateRangeTo,
  updateOrderMethods,
  updateOrderingSiteIds,
  updateTimeRangeFrom,
  updateTimeRangeTo,
  updateTimeIntervalMins,
  updateDayTimeLimitMatrix,
  loadPacingRuleById,
  loadPacingRuleResources,
  savePacingRuleById,
  createNewPacingRule,
}: PacingRuleProps) {
  const venueId = venue.id
  const { id: pacingRuleId } = match.params
  const isCreate = pacingRuleId === 'new'

  const [isLoading, updateLoading] = useState(false)
  const [isSaving, updateSaving] = useState(false)

  const openPacingRulesListPage = useCallback(() => {
    const pacingRuleListRoute = routes[0]
    if (pacingRuleListRoute) {
      const url = pacingRuleListRoute.route
      history.push(url)
    }
  }, [history, routes])

  const loadData = useCallback(async () => {
    if (isLoading) {
      return
    }
    try {
      updateLoading(true)
      await loadPacingRuleById(venue.id, pacingRuleId)
      await loadPacingRuleResources(venue.id)
    } catch (e) {
      clearPacingRuleData()
      openPacingRulesListPage()
    } finally {
      updateLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPacingRuleResources, venue.id, pacingRuleId, loadPacingRuleById, openPacingRulesListPage, updateLoading])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onSavePacingRule = useCallback(
    async (changedPacingRule: PacingRule | Omit<PacingRule, 'id'>) => {
      if (isSaving) {
        return
      }
      try {
        updateSaving(true)
        if ('id' in changedPacingRule && changedPacingRule.id) {
          await savePacingRuleById(venueId, pacingRuleId, changedPacingRule)
        } else {
          await createNewPacingRule(venueId, changedPacingRule)
        }
        clearPacingRuleData()
        openPacingRulesListPage()
      } catch (e) {
        updateSaving(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSaving, openPacingRulesListPage, pacingRuleId, savePacingRuleById, venueId]
  )

  const editTitle = isCreate ? `Create New Rule` : `Edit ${pacingRule.name}`

  const contentTitle = isLoading ? 'Loading...' : editTitle

  return (
    <ContentLayout routes={routes} title={contentTitle} venue={venue} venues={venues} showSpinner={isLoading} onChangeVenue={onChangeVenue}>
      <PacingRuleForm
        {...{
          isSaving,
          venue,
          pacingRule,
          orderingSites,
          menus,
          defaultMenuHours,
          updateName,
          updateDateRangeFrom,
          updateDateRangeTo,
          updateOrderMethods,
          updateOrderingSiteIds,
          updateTimeRangeFrom,
          updateTimeRangeTo,
          updateTimeIntervalMins,
          updateDayTimeLimitMatrix,
        }}
        onSave={onSavePacingRule}
        onCancel={openPacingRulesListPage}
      />
    </ContentLayout>
  )
}

const mapStateToProps = (state: State) => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  const { pacingRule, orderingSites, menus, defaultMenuHours } = state.orderingOrderPacing

  return {
    venue,
    venues,
    pacingRule,
    orderingSites,
    menus,
    defaultMenuHours,
  }
}

const mapDispatchToProps = {
  onChangeVenue: GlobalActions.onChangeVenue,
  loadPacingRuleById: OrderPacingActions.loadPacingRuleById,
  loadPacingRuleResources: OrderPacingActions.loadPacingRuleResources,
  savePacingRuleById: OrderPacingActions.savePacingRuleById,
  createNewPacingRule: OrderPacingActions.createNewPacingRule,
  clearPacingRuleData: OrderPacingActions.clearPacingRuleData,
  updateName: OrderPacingActions.updateName,
  updateDateRangeFrom: OrderPacingActions.updateDateRangeFrom,
  updateDateRangeTo: OrderPacingActions.updateDateRangeTo,
  updateOrderMethods: OrderPacingActions.updateOrderMethods,
  updateOrderingSiteIds: OrderPacingActions.updateOrderingSiteIds,
  updateTimeRangeFrom: OrderPacingActions.updateTimeRangeFrom,
  updateTimeRangeTo: OrderPacingActions.updateTimeRangeTo,
  updateTimeIntervalMins: OrderPacingActions.updateTimeIntervalMins,
  updateDayTimeLimitMatrix: OrderPacingActions.updateDayTimeLimitMatrix,
}

export default connect(mapStateToProps, mapDispatchToProps)(PacingRuleEdit)
