import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in ORDER-819
import type { OrderMethod, OrderStatus, NotificationSettingsMap, NotificationType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, Loader, NotificationContainer, notify, RelatedSettings, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, useVenueContext } from '@sevenrooms/mgr-core'
import { useGetOrderingNotificationSettingsQuery, useSaveOrderingNotificationSettingsMutation } from '../hooks'
import { orderingNotificationSettingsMessages } from '../locales'
import {
  DeliveryNotificationToggleTable,
  OnPremNotificationToggleTable,
  PickupNotificationToggleTable,
  RoomServiceNotificationToggleTable,
} from './components'
import type { RouteComponentProps } from 'react-router'

const Main = styled.main`
  height: 100%;
  background: ${props => props.theme.colors.secondaryBackground};
  flex-grow: 1;
  overflow: auto;
  position: relative;
`

interface OrderingNotificationSettingsProps extends RouteComponentProps {}

export function OrderingNotificationSettings({ history }: OrderingNotificationSettingsProps) {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()

  const { data, isFetching, error } = useGetOrderingNotificationSettingsQuery({
    venueId,
  })

  const [savedNotificationSettings, setSavedNotificationSettings] = useState<NotificationSettingsMap>()
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettingsMap>()

  const [saveSettings, saveStatus] = useSaveOrderingNotificationSettingsMutation()
  const onSave = useCallback(
    (settings: NotificationSettingsMap) => {
      saveSettings({ venueId, orderingNotificationSettings: settings })
        .unwrap()
        .then(() => {
          setSavedNotificationSettings(settings)
          notify({ content: formatMessage(orderingNotificationSettingsMessages.saveSuccessMessage), type: 'success' })
        })
        .catch(() => {
          notify({ content: formatMessage(orderingNotificationSettingsMessages.saveErrorMessage), type: 'error' })
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveSettings, venueId]
  )

  useEffect(() => {
    if (!isFetching && data?.orderingNotificationSettings) {
      setSavedNotificationSettings(_.cloneDeep(data.orderingNotificationSettings))
      setNotificationSettings(_.cloneDeep(data.orderingNotificationSettings))
    }
  }, [data, isFetching])

  const isDirty = useMemo(
    () => !_.isEqual(notificationSettings, savedNotificationSettings),
    [notificationSettings, savedNotificationSettings]
  )

  const getNotificationSettingCallback = (method: OrderMethod, status: OrderStatus, notificationType: NotificationType): boolean | null =>
    notificationSettings ? _.get(notificationSettings, [method, status, notificationType], null) : null

  const toggleNotificationSettingCallback = (method: OrderMethod, status: OrderStatus, notificationType: NotificationType): void => {
    if (notificationSettings) {
      const currentValue = _.get(notificationSettings, [method, status, notificationType])
      if (currentValue !== undefined) {
        const updatedSettings = _.cloneDeep(notificationSettings)
        updatedSettings[method][status][notificationType] = !currentValue
        setNotificationSettings(updatedSettings)
      }
    }
  }

  const renderContent = (content: JSX.Element) => (
    <Main>
      {content}
      <NotificationContainer />
    </Main>
  )

  if (isFetching) {
    return renderContent(<Loader />)
  }

  if (error || !data?.orderingNotificationSettings) {
    return renderContent(<span>{formatMessage(orderingNotificationSettingsMessages.fetchErrorMessage)}</span>)
  }

  return renderContent(
    <SettingsPageContent
      title={formatMessage(orderingNotificationSettingsMessages.pageTitle)}
      description={formatMessage(orderingNotificationSettingsMessages.pageDescription)}
      headerWidth="calc(100% - 274px)"
      actions={
        <HStack spacing="s">
          <Button
            variant="primary"
            onClick={() => {
              if (notificationSettings) {
                onSave(notificationSettings)
              }
            }}
            id="ordering-notification-settings-save-button"
            data-test="ordering-notification-settings-save-button"
            disabled={!isDirty || saveStatus.isLoading}
          >
            Save and Publish
          </Button>
        </HStack>
      }
    >
      <VStack spacing="lm" mt="l" ml="lm" pb="xl">
        <RelatedSettings
          primary={formatMessage(orderingNotificationSettingsMessages.relatedSettingsTitle)}
          secondary={formatMessage(orderingNotificationSettingsMessages.relatedSettingsDescription)}
          label={formatMessage(orderingNotificationSettingsMessages.relatedSettingsEditButtonLabel)}
          onClick={() => {
            history.push(`/manager2/${venue.urlKey}/settings/sms_settings/sms_ordering`)
          }}
          size="xl"
          data-test="ordering-notification-settings-related-settings-section"
        />
        <BaseSection title={formatMessage(orderingNotificationSettingsMessages.pickupSectionTitle)}>
          <PickupNotificationToggleTable
            getNotificationSetting={getNotificationSettingCallback}
            toggleNotificationSetting={toggleNotificationSettingCallback}
            data-test="ordering-notification-settings-pickup-section"
          />
        </BaseSection>
        <BaseSection title={formatMessage(orderingNotificationSettingsMessages.deliverySectionTitle)}>
          <DeliveryNotificationToggleTable
            getNotificationSetting={getNotificationSettingCallback}
            toggleNotificationSetting={toggleNotificationSettingCallback}
            data-test="ordering-notification-settings-delivery-section"
          />
        </BaseSection>
        <BaseSection title={formatMessage(orderingNotificationSettingsMessages.onPremSectionTitle)}>
          <OnPremNotificationToggleTable
            getNotificationSetting={getNotificationSettingCallback}
            toggleNotificationSetting={toggleNotificationSettingCallback}
            data-test="ordering-notification-settings-onprem-section"
          />
        </BaseSection>
        <BaseSection title={formatMessage(orderingNotificationSettingsMessages.roomServiceSectionTitle)}>
          <RoomServiceNotificationToggleTable
            getNotificationSetting={getNotificationSettingCallback}
            toggleNotificationSetting={toggleNotificationSettingCallback}
            data-test="ordering-notification-settings-room-service-section"
          />
        </BaseSection>
      </VStack>
    </SettingsPageContent>
  )
}
