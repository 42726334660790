import { adaptProduct86FromApi, adaptProduct86ToApi } from 'mgr/lib/services/EightySixProductServices/adapters/adaptEightySixProduct'
import { srGet, srPostJson, throwFetchError } from '@sevenrooms/core/api'
import type { EightySixProduct, EightySixProductApi } from 'mgr/pages/single-venue/settings/types/ordering/EightySixProduct.types'

export const getSnoozedProductListByVenueId = (venueId: string) =>
  srGet<{ products: EightySixProductApi[] }>(`/api-yoa/ordering/snooze/${venueId}`, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(throwFetchError)
    .then(response => ({
      ...response,
      data: {
        ...response.data,
        products: response.data.products.map(product => adaptProduct86FromApi(product)),
      },
    }))

export const postProductSnooze = (venueId: string, products: EightySixProduct[]) =>
  srPostJson(
    `/api-yoa/ordering/snooze/${venueId}`,
    products.map(product => adaptProduct86ToApi(product))
  ).then(throwFetchError)
