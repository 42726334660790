import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { SlideoutContent } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import {
  openAddPodSlideout,
  changePodCategoryName,
  onColorSwatchClick,
  appendNewPod,
  changePodName,
  deletePod,
  addPodCategory,
  editPodCategory,
  deletePodCategory,
  toggleDeleteWarning,
  toggleReplaceSearchGroupWarning,
  focusPodInput,
  blurPodInput,
  updatePodSlideoutErrors,
  toggleIsSearchGroup,
} from 'mgr/pages/venue-group/actions/Pods'
import PodSlideout from 'mgr/pages/venue-group/containers/pods/PodSlideout'
import { VmsIcons } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import EditDeleteTextInput from 'svr/component-lib/Generic/TextInputs/EditDeleteTextInput'
import NewItemTextInput from 'svr/component-lib/Generic/TextInputs/NewItemTextInput'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import Button from 'svr/lib/Buttons/Button'
import ColorSwatch from 'svr/lib/ColorPicker/ColorSwatch'

export const DisplayArea = styled.div`
  background: #ffffff;
  box-shadow: ${props => props.theme.shadow.six};
  height: 100%;
  padding-bottom: 10px;
`
const SlideoutSection = styled.div``
const SlideoutBody = styled.div`
  height: calc(100% - 109px);
  overflow-y: scroll;
`
const SlideoutButtonContainer = styled.div``
const SlideoutButtonTitle = styled.div`
  font-size: 13px;
`
const SlideoutButton = styled.div`
  display: inline-block;
  text-align: center;
  background-color: ${props => (props.isDeleteBtn ? props.theme.color.errorAccent : '#347baf')};
  color: ${props => props.theme.color.white};
  padding: 12px 28px;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
`
const SlideoutFooter = styled.div`
  position: fixed;
  text-align: right;
  bottom: 0;
  width: 100%;
  max-width: 498px;
  padding: 25px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.color.white};
`

const SlideoutLabel = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  color: ${props => props.theme.color.grey};
  font-weight: bold;
  padding: 20px 0 0 20px;
`

const SlideoutSublabel = styled.div`
  text-align: left;
  font-size: 12px;
  color: ${props => props.theme.color.grey};
  font-weight: bold;
  padding: 20px 0 0 20px;
`

const CheckboxWrapper = styled.div`
  padding: 20px 0 0 20px;
`

const InputField = styled.div`
  border: 1px solid ${props => (props.isInvalid ? '#E95757' : '#e6e8eb')};
  border-radius: 5px;
  display: inline-block;
  width: 329px;
  height: 100%;
`

const ColorPickerContainer = styled.div`
  display: inline-block;
  width: 100px;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0 20px;
`

const SlideoutLabelActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`

const PodsContainer = styled.div``
const VenuePodInputWrapper = styled.div`
  position: relative;
`

const NewItemTextInputWrapper = styled.div`
  padding: 10px 20px;
`

export const DeleteWarning = ({ proceed, cancel }) => (
  <MessageBox
    handleActionClick={proceed}
    handleCloseClick={cancel}
    dialogType={MessageBox.DialogType.WARNING}
    header="ARE YOU SURE"
    actionButtonText="Confirm"
    explanation="Are you sure you want to remove this pod category? This will remove all existing pods and un-assign them from all venues. This cannot be undone."
  />
)

export const ReplaceSearchGroupWarning = ({ proceed, cancel }) => (
  <MessageBox
    handleActionClick={proceed}
    handleCloseClick={cancel}
    dialogType={MessageBox.DialogType.WARNING}
    header="ARE YOU SURE"
    actionButtonText="Continue"
    explanation="You are about to clear any existing search groups and replace them with the pods from this category"
  />
)

class ShowPod extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: this.props.formErrors,
      disableBtn: false,
    }
  }

  componentDidMount = () => {
    window.document.body.addEventListener('keydown', event => {
      if (event.keyCode === 27) {
        this.props.openAddPodSlideout(false, null, '', [])
      }
    })
  }

  componentDidUpdate = () => {
    this.setState({ formErrors: this.props.formErrors })
  }

  changePodCategoryNameHandler = val => {
    if (this.state.formErrors.podCategoryName) {
      this.state.formErrors.podCategoryName = false
      this.setState({ formErrors: this.state.formErrors })
    }
    this.props.changePodCategoryName(val)
  }

  getDuplicatePodKeys = activePods => {
    const podsGroupedByNames = _.groupBy(activePods, pod => pod.name.toLowerCase().trim())
    const duplicatePods = _.flatten(_.filter(podsGroupedByNames, n => n.length > 1))
    const duplicatePodKeys = _.map(duplicatePods, pod => {
      if (pod.name !== '') {
        return pod.pod_key_order
      }
    })
    return _.without(duplicatePodKeys, undefined)
  }

  determineIfPodCategoryValid = formErrors => {
    if (this.props.podCategoryName.trim() === '') {
      formErrors.podCategoryName = true
    }
  }

  determineIfPodsValid = (pods, formErrors) => {
    const activePods = _.filter(pods, p => !p.is_deleted)
    const nonEmptyPods = _.filter(activePods, p => p.name.trim() !== '')
    const allPodsDeleted = activePods.length === 0
    const allPodsNoName = nonEmptyPods.length === 0

    let displayReqPodError = true
    pods.forEach(pod => {
      // First active pod that has no name where all other active pods have no name as well
      // OR existing active pod with no name
      if (pod.name.trim() === '' && !pod.is_deleted && (allPodsNoName || pod.id !== undefined)) {
        formErrors[pod.pod_key_order] = true
        displayReqPodError = false
      } else {
        delete formErrors[pod.pod_key_order]
      }
    })
    if (displayReqPodError && (allPodsDeleted || allPodsNoName)) {
      formErrors[pods[0].pod_key_order] = true
    } else if (!allPodsNoName && pods[0].id !== undefined && pods[0].name.trim() !== '') {
      delete formErrors[pods[0].pod_key_order]
    }
    const duplicatePodKeys = this.getDuplicatePodKeys(activePods)
    if (duplicatePodKeys.length) {
      duplicatePodKeys.forEach(podKey => {
        formErrors[podKey] = true
      })
    }
  }

  isValidPodCategorySubmission = () => {
    const formErrors = {}
    this.determineIfPodCategoryValid(formErrors)
    this.determineIfPodsValid(this.props.pods, formErrors)
    this.props.updatePodSlideoutErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  enableBtn = () => {
    this.setState({ disableBtn: false })
  }

  deletePodCategoryHandler = () => {
    this.setState({ disableBtn: true })
    this.props.deletePodCategory().then(this.enableBtn)
  }

  actionButtonHandler = () => {
    if (!this.isValidPodCategorySubmission()) {
      return
    }
    if (
      this.props.podCategoryIsSearchGroup &&
      this.props.podCategorySearchGroupId !== null &&
      this.props.podCategorySearchGroupId !== this.props.podCategoryId
    ) {
      this.props.toggleReplaceSearchGroupWarning()
      return
    }
    this.setState({ disableBtn: true })
    if (this.props.isNewPodCategory) {
      this.props.addPodCategory().then(this.enableBtn)
    } else {
      this.props.editPodCategory().then(this.enableBtn)
    }
  }

  getPodErrorMsg = (firstIdxReqOnePod, allPodsAreEmpty, idx, isExistingPod, podName) => {
    if (idx === firstIdxReqOnePod) {
      return 'Please add at least one Pod'
    }
    if ((allPodsAreEmpty || isExistingPod) && podName.trim() === '') {
      return 'Pod name cannot be blank'
    }
    return 'Pod name must be unique'
  }

  podNameChangeHandler = (val, idx) => {
    this.props.changePodName(val, idx, this.determineIfPodsValid)
  }

  appendPodHandler = text => {
    this.props.appendNewPod(this.determineIfPodsValid, text)
  }

  deletePodHandler = idx => {
    this.props.deletePod(idx, this.determineIfPodsValid)
  }

  toggleIsSearchGroup = () => {
    this.props.toggleIsSearchGroup(this.props.podCategoryId)
  }

  render = () => {
    const { isNewPodCategory, podCategoryName, podCategoryHexColor, pods, showWarning, showReplaceSearchGroupWarning } = this.props

    const title = `${isNewPodCategory ? 'ADD NEW' : 'EDIT'} POD CATEGORY`
    const activePods = _.filter(this.props.pods, p => !p.is_deleted)
    const nonEmptyPods = _.filter(activePods, p => p.name.trim() !== '')
    const allPodsAreEmpty = nonEmptyPods.length === 0
    let firstIdxReqOnePod = -1
    for (let i = 0; i < this.props.pods.length; i++) {
      if (allPodsAreEmpty && !this.props.pods[i].is_deleted) {
        firstIdxReqOnePod = i
        break
      }
    }
    if (firstIdxReqOnePod === -1 && allPodsAreEmpty) {
      firstIdxReqOnePod = 0
    }
    const submitButtonText = isNewPodCategory ? 'Create' : 'Update'

    return (
      <PodSlideout title={title}>
        <SlideoutContent>
          <DisplayArea>
            <SlideoutBody id="sr-pods-slideout-main">
              <SlideoutSection>
                <SlideoutLabel>Pod Category</SlideoutLabel>
                <FlexRow>
                  <InputField>
                    <ActiveLabelTextInput
                      field="pod-category-name"
                      value={podCategoryName || ''}
                      onChange={val => this.changePodCategoryNameHandler(val)}
                      placeholderLabel="Name"
                      isValid={!this.state.formErrors.podCategoryName}
                      style={{ width: '68%' }}
                    />
                  </InputField>
                  <ColorPickerContainer>
                    <ColorSwatch
                      id="sr-colorswatch-input"
                      name="color"
                      onColorSwatchClick={this.props.onColorSwatchClick}
                      color={podCategoryHexColor}
                    />
                  </ColorPickerContainer>
                </FlexRow>
              </SlideoutSection>
              <SlideoutSection>
                <SlideoutSublabel>Options</SlideoutSublabel>
                <CheckboxWrapper>
                  <VmsCheckbox
                    dataTest="pod-category-is-search-group"
                    field="podCategoryIsSearchGroup"
                    label="Show Pods from this Pod Category in Availability Search"
                    onClick={this.toggleIsSearchGroup}
                    isSelected={this.props.podCategoryIsSearchGroup}
                    tooltipWidth="235px"
                    tooltipText="If selected, any existing Search Groups will be replaced with the below Pods. Only one Pod Category per group can have this setting enabled."
                    selectedIcon={VmsIcons.CheckBoxSelected}
                    unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                    tooltipIcon={VmsIcons.Info}
                  />
                </CheckboxWrapper>
              </SlideoutSection>
              <SlideoutSection>
                <SlideoutLabelActionContainer>
                  <SlideoutLabel>Pods</SlideoutLabel>
                </SlideoutLabelActionContainer>
                <PodsContainer>
                  <NewItemTextInputWrapper>
                    <NewItemTextInput onConfirm={this.appendPodHandler} buttonText="Add Pod" />
                  </NewItemTextInputWrapper>
                  {_.map(pods, (pod, idx) => (
                    <VenuePodInputWrapper key={`pod-wrapper${pod.pod_key_order}`}>
                      <EditDeleteTextInput
                        idx={idx}
                        key={`sr-pod-field-${pod.id}`}
                        uniqueId={pod.id}
                        className="sr-pod"
                        value={pod.name}
                        placeHolder="type input here"
                        floatRightLabel={[pod.num_venues, pod.num_venues === 1 ? 'venue' : 'venues'].join(' ')}
                        isValid={!this.state.formErrors[pod.pod_key_order]}
                        isDeleted={pod.is_deleted}
                        onDeleteMsg={pod.num_venues > 0 ? 'This pod will be removed and unassigned from all venues' : ''}
                        errorMsg={this.getPodErrorMsg(firstIdxReqOnePod, allPodsAreEmpty, idx, pod.id !== undefined, pod.name)}
                        onChange={this.podNameChangeHandler}
                        deleteHandler={this.deletePodHandler}
                      />
                    </VenuePodInputWrapper>
                  ))}
                </PodsContainer>
              </SlideoutSection>
            </SlideoutBody>
            <SlideoutFooter>
              {!isNewPodCategory && (
                <SlideoutButton id="sr-podcategory-delete-btn-wrapper" isDeleteBtn onClick={this.props.toggleDeleteWarning}>
                  <Button
                    buttonId="sr-podcategory-delete-btn"
                    onClick={this.props.toggleDeleteWarning}
                    disabled={this.state.disableBtn}
                    innerElement={
                      <SlideoutButtonContainer>
                        <SlideoutButtonTitle>Remove this Pod Category</SlideoutButtonTitle>
                      </SlideoutButtonContainer>
                    }
                  />
                </SlideoutButton>
              )}
              <SlideoutButton id="sr-podcategory-action-btn-wrapper" isDeleteBtn={false} onClick={this.actionButtonHandler}>
                <Button
                  buttonId="sr-podcategory-action-btn"
                  onClick={this.actionButtonHandler}
                  disabled={this.state.disableBtn}
                  innerElement={
                    <SlideoutButtonContainer>
                      <SlideoutButtonTitle>{submitButtonText}</SlideoutButtonTitle>
                    </SlideoutButtonContainer>
                  }
                />
              </SlideoutButton>
            </SlideoutFooter>
          </DisplayArea>
        </SlideoutContent>
        {showWarning && <DeleteWarning proceed={this.deletePodCategoryHandler} cancel={this.props.toggleDeleteWarning} />}
        {showReplaceSearchGroupWarning && (
          <ReplaceSearchGroupWarning
            proceed={this.props.isNewPodCategory ? this.props.addPodCategory : this.props.editPodCategory}
            cancel={this.props.toggleReplaceSearchGroupWarning}
          />
        )}
      </PodSlideout>
    )
  }
}

const mapStateToProps = state => ({
  isNewPodCategory: state.pods.isNewPodCategory,
  podCategoryId: state.pods.selectedPodCategoryId,
  podCategoryName: state.pods.selectedPodCategoryName,
  podCategoryHexColor: state.pods.selectedPodCategoryHexColor,
  podCategoryIsSearchGroup: state.pods.selectedPodCategoryIsSearchGroup,
  podCategorySearchGroupId: state.pods.podCategorySearchGroupId,
  pods: state.pods.selectedPods,
  showWarning: state.pods.showWarning,
  showReplaceSearchGroupWarning: state.pods.showReplaceSearchGroupWarning,
  formErrors: state.pods.formErrors,
})

const mapDispatchToProps = {
  openAddPodSlideout,
  changePodCategoryName,
  onColorSwatchClick,
  appendNewPod,
  changePodName,
  addPodCategory,
  editPodCategory,
  deletePodCategory,
  deletePod,
  toggleDeleteWarning,
  toggleReplaceSearchGroupWarning,
  focusPodInput,
  blurPodInput,
  updatePodSlideoutErrors,
  toggleIsSearchGroup,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowPod)
