import React from 'react'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'

export const ReportingWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const ReportingContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: ${ManagerStyles.DayviewToolbarHeight - 2}px;
`

export const DisplayArea = styled.div`
  background: #ffffff;
  box-shadow: ${props => props.theme.shadow.six};
  height: 100%;
  overflow: scroll;
  padding-bottom: 10px;
`

export const ActionArea = styled.div`
  height: 80px;
`

export const LinkButton = styled.div`
  background: ${props => props.theme.color.teal};
  border-radius: 5px;
  color: ${props => props.theme.color.white};
  cursor: pointer;
  display: block;
  font-size: ${props => props.theme.fontSize.button};
  font-weight: bold;
  height: 64px;
  line-height: 64px;
  margin: 8px;
  text-align: center;
  text-trasform: uppercase;
`
