import { useState } from 'react'
import { PRODUCT_TYPES_DISPLAY_NAMES } from 'mgr/pages/single-venue/settings/constants'
import { Button, type RadioChoice, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import type { PRODUCT_TYPES } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

const choices: RadioChoice<PRODUCT_TYPES>[] = (['MENU_ITEM', 'MODIFIER_GROUP', 'MODIFIER'] as const).map(type => ({
  value: type,
  label: PRODUCT_TYPES_DISPLAY_NAMES[type],
}))

export interface CreateProductModalProps {
  onSave: (type: PRODUCT_TYPES) => void
  onDiscard: () => void
}

export function CreateProductModal({ onSave, onDiscard }: CreateProductModalProps) {
  const [selectedProductType, setSelectedProductType] = useState<PRODUCT_TYPES>('MENU_ITEM')

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onDiscard()}>
        <ModalTitle title="What type of new product do you want to create?" />
      </ModalHeader>
      <ModalBody>
        <RadioGroup
          name=""
          choices={choices}
          selected={selectedProductType}
          onChange={(option: RadioChoice<PRODUCT_TYPES>) => {
            setSelectedProductType(option.value)
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={() => onDiscard()} data-test="sr-create-product-modal-discard">
            Cancel
          </Button>
          <Button variant="primary" onClick={() => onSave(selectedProductType)} data-test="sr-create-product-modal-continue">
            Continue
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
