/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-deprecated */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import {
  Desc,
  SubDesc,
  Contents,
  SubContainer,
  ProviderLogo,
  Provider,
  ProviderSection,
  SizedImg,
} from 'mgr/pages/single-venue/settings/components/shared'
import { checkKountaConnection, testKountaConnection } from '../actions/KountaActions'
import { checkLightspeedConnection, testLightspeedConnection } from '../actions/LightspeedActions'
import { KountaSection } from '../components/KountaSection'
import { LIGHTSPEED, LIGHTSPEED_KOUNTA } from '../constants'
import { LightspeedSection } from '../components/LightspeedSection'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'

class PointOfSalesProviders extends PureComponent {
  componentDidMount() {
    if (!this.shouldWeShowPOSPage()) {
      window.location = '/'
    }
    if (this.props.venue.posIntegrationType === LIGHTSPEED) {
      this.props.checkLightspeedConnection(this.props.venue.id)
    } else if (this.props.venue.posIntegrationType === LIGHTSPEED_KOUNTA) {
      this.props.checkKountaConnection(this.props.venue.id)
    }
  }

  handleLightspeedTestSubmit = () => {
    this.props.testLightspeedConnection(this.props.venue.id)
  }

  handleKountaTestSubmit = () => {
    this.props.testKountaConnection(this.props.venue.id)
  }

  shouldWeShowPOSPage() {
    return this.props.venue.posActivated && [LIGHTSPEED, LIGHTSPEED_KOUNTA].includes(this.props.venue.posIntegrationType)
  }

  render() {
    const { venue, venues } = this.props
    if (!this.shouldWeShowPOSPage()) {
      return null
    }

    return (
      <ContentLayout title="Point of Sale Integrations" venue={venue} venues={venues} onChangeVenue={this.props.onChangeVenue}>
        <Contents>
          <SubContainer>
            <Desc>Sync your credentials directly with a Point of Sale integration</Desc>
            <SubDesc>Use this page to authenticate your Point of Sale integration with SevenRooms</SubDesc>
            {venue.posIntegrationType === LIGHTSPEED && (
              <Provider>
                <ProviderLogo>
                  <SizedImg width="250" height="73" path="integrations/lightspeed.png" />
                </ProviderLogo>
                <ProviderSection>
                  <LightspeedSection
                    venue={venue}
                    isLightspeedConnected={this.props.isLightspeedConnected}
                    handleLightspeedTestSubmit={this.handleLightspeedTestSubmit}
                  />
                </ProviderSection>
              </Provider>
            )}
            {venue.posIntegrationType === LIGHTSPEED_KOUNTA && (
              <Provider>
                <ProviderLogo>
                  <SizedImg width="250" height="73" path="integrations/kounta.png" />
                </ProviderLogo>
                <ProviderSection>
                  <KountaSection
                    venue={venue}
                    isKountaConnected={this.props.isKountaConnected}
                    handleKountaTestSubmit={this.handleKountaTestSubmit}
                  />
                </ProviderSection>
              </Provider>
            )}
          </SubContainer>
        </Contents>
      </ContentLayout>
    )
  }
}

PointOfSalesProviders.propTypes = {
  venue: React.PropTypes.object.isRequired,
  venues: React.PropTypes.array.isRequired,
  checkKountaConnection: React.PropTypes.func,
  checkLightspeedConnection: React.PropTypes.func,
  isKountaConnected: React.PropTypes.bool,
  isLightspeedConnected: React.PropTypes.bool,
  testKountaConnection: React.PropTypes.func,
  testLightspeedConnection: React.PropTypes.func,
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  const isLightspeedConnected = state.lightspeed.isConnected
  const { lightspeedStatus } = state.lightspeed
  const isKountaConnected = state.kounta.isConnected
  const { kountaStatus } = state.kounta

  return {
    venue,
    venues,
    isKountaConnected,
    isLightspeedConnected,
    kountaStatus,
    lightspeedStatus,
  }
}

const mapDispatchToProps = {
  checkKountaConnection,
  checkLightspeedConnection,
  onChangeVenue,
  testKountaConnection,
  testLightspeedConnection,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointOfSalesProviders)
