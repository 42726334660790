import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { FULFILLMENT_METHOD, FULFILLMENT_METHOD_ORDERED_FOR_SITE_FORM } from 'svr/constants'

const computeOrderingSiteParams = state => ({
  menuIds: state.orderingSite.editState.menu_ids,
  selectedFulfillmentMethod: state.orderingSite.editState.fulfillment_method,
  availableMenus: state.orderingSite.availableMenus,
  fulfillmentMethods: state.orderingSite.fulfillmentMethods,
  orderAheadTimeDisplayUnits: state.orderingSite.orderAheadTimeDisplayUnits,
  posTables: state.orderingSite.posTables,
  selectedPosTables: state.orderingSite.editState.pos_tables,
})

/* eslint-disable no-param-reassign */
const computeAvailableMenuOptions = ({ availableMenus = [], menuIds = [] }) => {
  const selectedMenuIds = menuIds.reduce((menuIdsObj, menuId) => {
    menuIdsObj[menuId] = menuId
    return menuIdsObj
  }, {})
  return availableMenus.reduce((result, menu) => {
    result[menu.id] = {
      label: menu.name,
      isSelected: !_.isNil(selectedMenuIds[menu.id]),
    }
    return result
  }, {})
}
/* eslint-enable no-param-reassign */

const computeFulfillmentMethodOptions = ({ fulfillmentMethods = {} }) =>
  FULFILLMENT_METHOD_ORDERED_FOR_SITE_FORM.map(key => ({
    value: key,
    label: fulfillmentMethods[key],
  })).filter(obj => obj.label !== undefined)

const computeOrderAheadTimeDisplayUnitOptions = ({ orderAheadTimeDisplayUnits = [] }) =>
  orderAheadTimeDisplayUnits.map(option => ({
    value: option,
    name: option.toLowerCase(),
  }))

const computeSelectedFulfillmentMethodData = ({ fulfillmentMethods = {}, selectedFulfillmentMethod = '' }) => {
  let onSiteInstructionTooltip = ''
  let onSiteLabel = ''
  if (
    selectedFulfillmentMethod === FULFILLMENT_METHOD.PICKUP ||
    selectedFulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_DELIVERY ||
    selectedFulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE
  ) {
    onSiteLabel = 'Pickup'
    onSiteInstructionTooltip = `Instruct the Guest on what to do when they arrive to pick up their order. For example:
                  "Come directly to the host stand on the second floor" or "Wait in your car and we will bring the order
                  outside when it is ready". This will be displayed on the order success page and the email confirmation.`
  } else if (selectedFulfillmentMethod === FULFILLMENT_METHOD.ON_PREMISE) {
    onSiteLabel = fulfillmentMethods[FULFILLMENT_METHOD.ON_PREMISE]
    onSiteInstructionTooltip = `Instruct the Guest on what to do after they place an order. For example:
    “A server will bring the food to your table.” This will be displayed on the order success page and the email
    confirmation.`
  }

  return {
    onSiteLabel,
    onSiteInstructionTooltip,
  }
}
const computePosTableOptions = ({ posTables = [] }) => [
  ...posTables.map(posTable => ({
    value: posTable.id,
    name: posTable.name,
  })),
]

const computeSelectedPosTables = ({ selectedPosTables = [] }) => selectedPosTables.map(table => table.id)

export const selectAvailableMenuOptions = createShallowEqualSelector(computeOrderingSiteParams, computeAvailableMenuOptions)

export const selectFulfillmentMethodOptions = createShallowEqualSelector(computeOrderingSiteParams, computeFulfillmentMethodOptions)

export const selectPosTableOptions = createShallowEqualSelector(computeOrderingSiteParams, computePosTableOptions)
export const selectSelectedPosTables = createShallowEqualSelector(computeOrderingSiteParams, computeSelectedPosTables)

export const selectOrderAheadTimeDisplayUnitOptions = createShallowEqualSelector(
  computeOrderingSiteParams,
  computeOrderAheadTimeDisplayUnitOptions
)

export const selectSelectedFulfillmentMethodData = createShallowEqualSelector(
  computeOrderingSiteParams,
  computeSelectedFulfillmentMethodData
)

const computeCustomOrderFieldOptionsParams = state => ({
  venue: _.find(state.appState.userDomain.venues, { id: state.appState.venue.id }),
})

const computeCustomOrderFieldOptionsData = ({ venue }) =>
  _.get(venue, 'customFieldsConfig.order', [])
    .filter(config => config.enabled)
    .map(config => ({
      value: config.db_name || config.system_name,
      name: config.name,
    }))

export const selectCustomOrderFieldOptions = createShallowEqualSelector(
  computeCustomOrderFieldOptionsParams,
  computeCustomOrderFieldOptionsData
)
