import { useNavigation } from '@sevenrooms/core/navigation'
import type { SideNavigationCategoryItem, SideNavigationPageItem } from '@sevenrooms/core/ui-kit/layout'
import { routes } from '@sevenrooms/routes'
import { useNavigationPermissionsContext } from './useNavigationPermissionsContext'
import { useVenueContext } from './useVenueContext'

export interface LookerSideNavigationPageItem extends SideNavigationPageItem {
  description?: string
  popularSortOrder?: number
  thumbnail: string
}

export interface LookerSideNavigationCategoryItem extends SideNavigationCategoryItem {
  items: LookerSideNavigationPageItem[]
}

type LookerEmbedPath = typeof routes.manager2.insights.embed | typeof routes.manager2.reporting.embed

export const BASE_THUMBNAIL_URL = '/api-yoa/report/looker/thumbnail/'

function useLookerNavigationItems(embedPath: LookerEmbedPath): LookerSideNavigationCategoryItem[] {
  const nav = useNavigation()
  const { venue } = useVenueContext()

  if (Object.keys(venue).length === 0) {
    return []
  }

  return Object.entries(venue.lookerFolders).map(([key, reports]) => ({
    id: key,
    title: key,
    items:
      reports?.map(report => ({
        id: `looker-report-${report.type}-${report.id}`,
        title: report.title,
        to: nav.href(embedPath, { params: { venueKey: venue.urlKey, type: report.type, id: report.id } }),
        reload: true,
        description: report.description,
        keywords: report.tags ?? [],
        popularSortOrder: report.popularSortOrder,
        items: [],
        thumbnail: report.thumbnailSrc ?? `${BASE_THUMBNAIL_URL}${report.type}/${report.id}`,
      })) ?? [],
  }))
}

const INSIGHTS_LOOKER_FOLDERS = ['Revenue Optimization Dashboards', 'Revenue Management Extended Dashboards']

export function useInsightsLookerNavigationItems(): LookerSideNavigationCategoryItem[] {
  const lookerItems = useLookerNavigationItems(routes.manager2.insights.embed)
  return lookerItems.filter(x => INSIGHTS_LOOKER_FOLDERS.includes(x.title))
}

export function useReportingLookerNavigationItems(): LookerSideNavigationCategoryItem[] {
  const permissions = useNavigationPermissionsContext()
  const lookerItems = useLookerNavigationItems(routes.manager2.reporting.embed)
  return permissions?.canViewReportingFull ? lookerItems.filter(x => !INSIGHTS_LOOKER_FOLDERS.includes(x.title)) : []
}
