import React from 'react'
import styled from 'styled-components'
import CheckoutFeeItem from './CheckoutFeeItem'

const Wrapper = styled.div`
  padding-bottom: 16px;
`

const CheckoutFeeList = ({ checkoutFees, onToggleItem, onEditItem, currencySymbol }) => (
  <>
    {checkoutFees.map(feeItem => (
      <Wrapper key={feeItem.id}>
        <CheckoutFeeItem feeItem={feeItem} onEditItem={onEditItem} onToggleItem={onToggleItem} currencySymbol={currencySymbol} />
      </Wrapper>
    ))}
  </>
)

export default CheckoutFeeList
