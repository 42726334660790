import React, { Component } from 'react'
import { connect } from 'react-redux'
import PhotoCropper from 'mgr/lib/forms/PhotoCropper'
import * as BookingChannelActions from 'mgr/pages/single-venue/settings/actions/BookingChannelActions'

export { CropperScreen } from 'mgr/lib/forms/PhotoCropper'

const mapStateToProps = state => {
  const { photoIdx, cropAspect, channelSettings } = state.bookingChannels
  const resyChannelSettings = channelSettings[BookingChannelActions.CHANNEL_KEY_RESY]
  return {
    photoId: photoIdx,
    aspect: cropAspect,
    allPhotos: resyChannelSettings.images.map((img, idx) => ({
      id: idx,
      ...img,
    })),
  }
}

const mapDispatchToProps = {
  cropImage: BookingChannelActions.cropImage,
  closeCrop: BookingChannelActions.closeCrop,
  saveCrop: BookingChannelActions.saveCrop,
}

const ResyImageCropper = connect(mapStateToProps, mapDispatchToProps)(PhotoCropper)

export default ResyImageCropper
