import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import {
  EventsPopover,
  PopoverPositioning,
  popoverWidth,
  triangleSize,
} from 'mgr/actualslideout/components/availability/AvailabilityDayEvents'
import { buildCustomBadgedVMSIcon } from 'svr/common/BadgedIcon'
import VmsIcons, { StyledVmsIconS, BorderedVmsIcon, customIcon } from 'svr/common/VmsIcons'

const iconSize = 22
const badgeSize = iconSize / 4

const NoteControlContainer = styled.div`
  position: relative;
  margin-right: 9px;
`

// TODO replace all the badge stuff with svgs
const NoteControlIcon = styled(BorderedVmsIcon)`
  background: ${props => (props.active ? props.theme.color.black : props.theme.color.white)};
  &:hover {
    background: ${props => (props.active ? props.theme.color.black : null)};
  }
`

const AddNote = styled(customIcon(`${iconSize}px`))`
  color: ${props => (props.active ? props.theme.color.white : props.theme.color.black)};
`

const CheckMark = styled(customIcon(`${badgeSize}px`))`
  color: ${props => props.theme.color.white};
  font-weight: 800;
  transform: translate(0, 1px);
`

const NoteBadge = buildCustomBadgedVMSIcon(
  null,
  css`
    background: ${props => props.theme.color.successAccent};
    top: -3px;
    padding: 2px;
    font-size: 8px;
    display: flex;
    transform: scale(0.99);
  `
)

const CustomPopoverPositioned = styled(PopoverPositioning)`
  top: 40px;
  left: ${-popoverWidth / 2 + triangleSize / 2 - 2}px;
`

const clickOutsideConfig = {
  handleClickOutside(instance) {
    return () => {
      if (!instance.props.active) {
        return
      }
      instance.props.onDailyNoteChange(_.get(instance.props.dailyEvents, 'note'), '')
      instance.props.onClickOutside()
    }
  },
}

const NoteControl = ({
  active,
  onClick,
  date,
  dailyEvents,
  venue,
  onSaveNoteClick,
  updatedDailyNote,
  onDailyNoteChange,
  isSaveLoading,
}) => {
  const note = _.get(dailyEvents, 'note', false)
  const addNote = <AddNote active={active}>{note ? VmsIcons.Note : VmsIcons.NoteAddLine}</AddNote>
  const checkMark = <CheckMark>{VmsIcons.Check}</CheckMark>

  return (
    <NoteControlContainer data-test="sr-button-note_control">
      <NoteControlIcon onClick={onClick} active={active} data-test="sr-button-note-button">
        {note ? (
          <NoteBadge active={active} badgeIcon={checkMark} displayIcon={addNote} />
        ) : (
          <AddNote active={active}>{note ? VmsIcons.Note : VmsIcons.NoteAddLine}</AddNote>
        )}
      </NoteControlIcon>

      {active && (
        <EventsPopover
          customPositionElement={CustomPopoverPositioned}
          dailyEvents={dailyEvents}
          isEditable
          venue={venue}
          isSaveLoading={isSaveLoading}
          onSaveClick={onSaveNoteClick}
          updatedDailyNote={updatedDailyNote}
          onDailyNoteChange={onDailyNoteChange}
        />
      )}
    </NoteControlContainer>
  )
}

NoteControl.propTypes = {
  active: React.PropTypes.bool.isRequired,
  onClick: React.PropTypes.func.isRequired,
  onClickOutside: React.PropTypes.func.isRequired,
  date: React.PropTypes.object.isRequired,
  venue: React.PropTypes.object.isRequired,
  onSaveNoteClick: React.PropTypes.func.isRequired,
  onDailyNoteChange: React.PropTypes.func.isRequired,
  isSaveLoading: React.PropTypes.bool.isRequired,
  dailyEvents: React.PropTypes.object,
  updatedDailyNote: React.PropTypes.string,
}

export default onClickOutside(NoteControl, clickOutsideConfig)
