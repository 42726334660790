/* eslint-disable camelcase */
import type { ProductTagsTypes } from 'mgr/pages/single-venue/settings/constants'
import type { MenuItem, ModifierItem, ModifiersGroupItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

interface ProductImagePhotoDict {
  x: number
  y: number
  width: number
  height: number
  aspect: number
}

export interface ImageCropData {
  x: number
  y: number
  width: number
  aspect: number
}

export interface ProductImageData {
  productImgBlobKey: string
  productImgCropData: ImageCropData
  productImgPhotoDict: ProductImagePhotoDict
  productImgUrl: string
}

export interface ProductImageDataApi {
  product_img_blob_key: string
  product_img_crop_data: ImageCropData | string
  product_img_photo_dict: ProductImagePhotoDict
  product_img_url: string
}

export interface ProductImageUrls {
  small: string
  medium: string
  large: string
  mega: string
  externalUrls: string[]
}

export interface ProductImageUrlsApi {
  small: string
  medium: string
  large: string
  mega: string
  external_urls: string[]
}

interface Modifier {
  name: string
  price: number
  priceFormatted?: string
}
export type ProductTags = Record<ProductTagsTypes, string[]>

export type Modifiers = Record<string, Modifier>

export interface ModifierGroupApi {
  name: string
  modifier_ids: string[]
}

export type ModifierGroupsApi = Record<string, ModifierGroupApi>

export interface ModifierGroup {
  name: string
  modifierIds: string[]
}

export type ModifierGroups = Record<string, ModifierGroup>

export interface ProductApi {
  id: string
  name?: string
  description?: string
  display_id?: string
  type: PRODUCT_TYPES
  type_display_name?: string
  menu_names?: string[]
}

export interface Product {
  id: string
  name?: string
  description?: string
  displayId?: string
  type: PRODUCT_TYPES
  typeDisplayName?: string
  menuNames?: string[]
}
export type PRODUCT_STATES = 'snoozed' | 'available'
export type PRODUCT_TYPES = 'MENU_ITEM' | 'MODIFIER_GROUP' | 'MODIFIER'
export type PRODUCT_TYPES_ALL = PRODUCT_TYPES | 'ALL'
export type EIGHTY_SIX_PRODUCT_TYPES = 'MENU_ITEM' | 'MODIFIER' | 'ALL'

export const isMenuItem = (product: Product): product is MenuItem => product.type === 'MENU_ITEM'
export const isModifier = (product: Product): product is ModifierItem => product.type === 'MODIFIER'
export const isModifierGroup = (product: Product): product is ModifiersGroupItem => product.type === 'MODIFIER_GROUP'
