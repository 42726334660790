import styled from 'styled-components'

export const TagsContent = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.color.greyMuted};
`

export const TagCategoryContainer = styled.div`
  padding: 25px 16px;
`

export const TagCategoryWrapper = styled.div`
  padding-bottom: 16px;
`
