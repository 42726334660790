import { useLocales } from '@sevenrooms/core/locales'
import { insightsMessages } from '../../../../locales'
import { formatParagraph, type DetailProps } from '../../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { POSEnabledSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'
import { FeatureEnablementProofSection } from '../../../Proofs'

export function POSEnabledDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { insight } = data

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={formatMessage(insightsMessages.posEnabledSlideoutDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
      })}
      proofLabel={formatMessage(insightsMessages.posEnabledProofLabel)}
      suggestedActionsComponent={<POSEnabledSuggestedActions />}
      proofComponent={
        <FeatureEnablementProofSection
          title={formatMessage(insightsMessages.posEnabledProofTitle)}
          lastCheckedDate={insight.createdDatetime}
        />
      }
    />
  )
}
