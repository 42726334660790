import PropTypes from 'prop-types'
import * as React from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'
import Icon from 'svr/component-lib/Generic/Icon'

const Button = styled.button`
  border: none;
  background: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? '#ccc' : '#93979d')};
  padding: 8px;
  border-radius: 50%;
  transition: background 0.3s ease;
  outline: 0;

  &:hover {
    background-color: ${props => (props.disabled ? '' : '#e8f5ff')};
  }
`

const ButtonIcon = ({ onClick, icon, size, disabled, ...props }) => {
  const dataProps = passDataAttrs(props)
  return (
    <Button onClick={onClick} disabled={disabled} {...dataProps} type="button">
      <Icon icon={icon} size={size} />
    </Button>
  )
}

ButtonIcon.SIZES = Icon.SIZES
ButtonIcon.ICONS = Icon.ICONS

ButtonIcon.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(Object.values(ButtonIcon.ICONS)).isRequired,
  size: PropTypes.number,
  onClick: PropTypes.func.isRequired,
}

ButtonIcon.defaultProps = {
  disabled: false,
  size: Icon.SIZES.SMALL,
}

export default ButtonIcon
