import { AUTOMATED_CHANGE_REQUEST_ELIGIBLE_TYPES, CHANGE_REQUEST_ELIGIBLE_TYPES, InsightStatus } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Section, SectionController, Box, HStack, VStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useInsightSuggestedActionsContext } from '../../../hooks'
import { insightsMessages } from '../../../locales'
import { StatusIcon } from '../../Status'
import { InsightChangeRequestInstructionsSection } from '../InsightChangeRequestInstructionsSection'
import type { PropsWithChildren } from 'react'

export function InsightNewSection({ children }: PropsWithChildren<{}>) {
  const { formatMessage } = useLocales()
  const { setDisplayChangeRequestSection, displayChangeRequestSection, setDisplayDeclineReasonSection, onSave, insight } =
    useInsightSuggestedActionsContext()
  const titleContent = (
    <HStack spacing="s" data-test="insight-new-section-title">
      <StatusIcon variant="new" />
      <Text textStyle="h2">{formatMessage(insightsMessages.insightSuggestedActionTitle)}</Text>
    </HStack>
  )

  const shouldDisplayChangeRequestButton = CHANGE_REQUEST_ELIGIBLE_TYPES.includes(insight.insightTypeKey)
  const automatedChangeSupported = AUTOMATED_CHANGE_REQUEST_ELIGIBLE_TYPES.includes(insight.insightTypeKey)

  const buttonPanel = (
    <VStack p="sm l l l" spacing="sm" data-test="insight-new-button-panel">
      <Text fontWeight="bold" data-test="insight-new-suggested-action-taken">
        {formatMessage(
          automatedChangeSupported
            ? insightsMessages.insightSuggestedActionAutomatedQuestion
            : insightsMessages.insightSuggestedActionTakenQuestion
        )}
      </Text>
      <HStack spacing="sm">
        <Button
          variant="secondary"
          size="s"
          data-test="insight-new-suggested-actions-yes-button"
          onClick={() => onSave({ updatedStatus: InsightStatus.TRACKING, automatedChangeRequested: automatedChangeSupported })}
        >
          {formatMessage(
            automatedChangeSupported ? insightsMessages.makeChangeAutomatedButtonLabel : insightsMessages.makeChangeYesButtonLabel,
            {
              icon: <Icon name="VMSWeb-insight-optimized" size="2x" />,
            }
          )}
        </Button>
        {shouldDisplayChangeRequestButton && (
          <Button
            variant="secondary"
            size="s"
            data-test="insight-new-suggested-actions-make-this-change-button"
            onClick={() => setDisplayChangeRequestSection(true)}
          >
            {formatMessage(insightsMessages.makeChangeAssignButtonLabel, {
              icon: <Icon name="VMSWeb-7r-logo" size="sm" />,
            })}
          </Button>
        )}
        <Button
          variant="secondary-warning"
          size="s"
          data-test="insight-new-suggested-actions-no-button"
          onClick={() => setDisplayDeclineReasonSection(true)}
        >
          {formatMessage(insightsMessages.makeChangeNoButtonLabel, {
            icon: <Icon name="VMSWeb-insight-declined" size="2x" />,
          })}
        </Button>
      </HStack>
    </VStack>
  )

  return (
    <SectionController behavior="one-section-at-one-time" localStoragePrefix="insightsSuggestedAction">
      <Section title={titleContent} name="suggested-action-collapsible" data-test="insight-new-section">
        <VStack pt="m">
          <DividerLine />
          <VStack pt="sm" pl="l">
            <SecondaryText data-test="insight-new-suggested-actions-description">
              {formatMessage(insightsMessages.insightSuggestedActionDescription)}
            </SecondaryText>
            <Box data-test="insight-new-content">{children}</Box>
          </VStack>
          <DividerLine />
          {displayChangeRequestSection ? <InsightChangeRequestInstructionsSection /> : buttonPanel}
        </VStack>
      </Section>
    </SectionController>
  )
}
