import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useStoreSelector } from '../store'

export function useNavigationCalendarContext() {
  const dispatch = useDispatch()
  const date: Date | null = useStoreSelector(state => state?.appState?.selectedNavDate)
  const highlightedDates: string[] = useStoreSelector(state => state?.appState?.highlightedDates)
  const setDate = useCallback((date: Date | null) => dispatch({ type: 'CHANGE_NAVIGATION_DATE', date }), [dispatch])
  const setHighlightedDates = useCallback((dates: string[]) => dispatch({ type: 'CHANGE_HIGHLIGHTED_DATES', dates }), [dispatch])

  return { date, setDate, highlightedDates, setHighlightedDates }
}
