export const SettingRow = {
  clear: 'both',
}

export const SettingCol = {
  width: '190px',
  float: 'left',
}

export const SettingColTitle = {
  ...SettingCol,
  lineHeight: '26px',
  color: '#999999',
}

export const emptyPhoto = {
  background: `#f5f5f5 url(${window.globalInit.mediaUrl}images/add_picture.png) 50% 50% no-repeat`,
  border: '1px dashed #ccc',
  cursor: 'pointer',
  // float: 'left',
  position: 'relative',
}

export const hasPhoto = {
  border: '1px solid #fff',
  // float: 'left',
  position: 'relative',
  overflow: 'hidden',
}

export const bigPhoto = {
  backgroundSize: '42px 32px',
  height: '200px',
  marginRight: '20px',
  marginTop: '10px',
  width: '400px',
}

export const actionAdjust = {
  height: '25px',
  width: '21px',
}

export const photoActions = {
  right: '4px',
  position: 'absolute',
  top: '4px',
}

export const photoStyle = {
  left: '-25%',
  position: 'absolute',
  width: '100%',
}

export const cropWrap = {
  background: '#fff',
  left: '50%',
  marginLeft: '-200px',
  position: 'fixed',
  top: '25%',
  width: '400px',
  zIndex: '1001',
}

export const action = {
  backgroundColor: '#f1f1f1',
  backgroundSize: '15px 15px',
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ccc',
  borderRadius: '3px',
  cursor: 'pointer',
  display: 'inline-block',
  height: '35px',
  marginLeft: '5px',
  marginTop: '2px',
  width: '31px',
}

export const headerStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}

export const headerTextStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '80%',
}

export const closer = {
  cursor: 'pointer',
  fontSize: '41px',
  fontWeight: '100',
  position: 'absolute',
  right: '10px',
  top: '0px',
}

export const eg = {
  clear: 'both',
  color: '#aaa',
  fontSize: '10px',
  fontStyle: 'italic',
}

export const shadow = {
  background: '#000',
  height: '100%',
  left: '0',
  opacity: '0.8',
  position: 'fixed',
  top: '0',
  width: '100%',
  zIndex: '1000',
}
