import { useMemo, useState } from 'react'
import type { GenericTagGroup } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { generateTagHash } from '@sevenrooms/mgr-access-rules-slideout/components/BookingChannels/BookingChannels.zod'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { Autocomplete } from '@sevenrooms/react-components/components/Autocomplete'
import { Checkbox } from '@sevenrooms/react-components/components/Checkbox'
import { Chip } from '@sevenrooms/react-components/components/Chip'
import { FormControlLabel } from '@sevenrooms/react-components/components/FormControlLabel'
import { List } from '@sevenrooms/react-components/components/List'
import { ListSubheader } from '@sevenrooms/react-components/components/ListSubheader'
import { TextField } from '@sevenrooms/react-components/components/TextField'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { useAvailabilityUrlParams } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'
import { StandardInput } from './StandardInput'
import type { AutocompleteGroupedOption } from './options'

export interface ClientTagsInputProps {
  clientTags: GenericTagGroup[]
}

export function ClientTagsInput({ clientTags }: Partial<ClientTagsInputProps>) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useVenueSettingsContext()

  return (
    <StandardInput
      htmlFor="aqv-client-tags"
      label={formatMessage(availabilityDebuggerMessages.clientTagsLabel)}
      tooltip={formatMessage(availabilityDebuggerMessages.clientTagsTooltip)}
    >
      {clientTags != null && venueSettings?.is_consumer_tags_enabled && venueSettings?.is_consumer_feature_enabled ? (
        <ClientTagsInputEnabled clientTags={clientTags} />
      ) : (
        <ClientTagsInputDisabled />
      )}
    </StandardInput>
  )
}

function ClientTagsInputEnabled({ clientTags: data }: ClientTagsInputProps) {
  const theme = useTheme()
  const { formatMessage } = useLocales()
  const [{ clientTagIds }, setParams] = useAvailabilityUrlParams()

  const [initiallySelected] = useState(clientTagIds)
  const options = useMemo(() => transformData(data), [data])
  const defaultValue = useMemo(() => getSelected(options, initiallySelected), [initiallySelected, options])

  return (
    <Autocomplete
      fullWidth
      disableClearable
      id="aqv-client-tags"
      renderInput={params => (
        <TextField
          {...params}
          placeholder={clientTagIds.length === 0 ? formatMessage(availabilityDebuggerMessages.multiSelectPlaceholderNone) : undefined}
        />
      )}
      renderGroup={({ children, group, key }) => (
        <div key={key}>
          <ListSubheader>{group}</ListSubheader>
          <List>{children}</List>
        </div>
      )}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props
        return (
          <li key={key} {...optionProps}>
            <FormControlLabel control={<Checkbox checked={selected} />} label={option.label} />
          </li>
        )
      }}
      renderTags={(value: AutocompleteGroupedOption[], getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })
          let sx: Record<string, string | number> = {}
          if (option.color) {
            sx = {
              ...sx,
              backgroundColor: option.color,
              color: theme.palette.getContrastText(option.color),
              fontWeight: 'bold',
            }
          }
          return <Chip label={option.label} key={key} sx={sx} {...tagProps} />
        })
      }
      ListboxProps={{ sx: { p: 0 } }}
      getOptionLabel={option => option.label}
      multiple
      limitTags={1}
      disableCloseOnSelect
      options={options}
      groupBy={option => option.group}
      defaultValue={defaultValue}
      onChange={(_event, selected, _reason) => setParams({ clientTagIds: selected?.map(t => t.value) })}
    />
  )
}

function ClientTagsInputDisabled() {
  return <Autocomplete id="aqv-client-tags" fullWidth disabled options={[]} renderInput={params => <TextField {...params} />} />
}

function getSelected(options: AutocompleteGroupedOption[], selected?: string[]) {
  return options.filter(o => selected?.includes(o.value))
}

function transformData(data: GenericTagGroup[], consumerOnly = true): AutocompleteGroupedOption[] {
  // This is a flag to limit the tags to only the consumer tags. This can be removed when we open this up to all client tags
  // This is always set to true currently
  if (consumerOnly) {
    return data
      .filter(tg => tg.nameDisplay === 'SevenRooms App')
      .flatMap(t =>
        t.tags
          .filter(tagKey => ['Tier1', 'Tier2'].includes(tagKey))
          .map(tag => ({
            label: t.tagNameDisplays[tag] || tag,
            value: generateTagHash(t.privacy, t.id, t.name, tag),
            group: t.nameDisplay,
            color: t.colorHex,
          }))
      )
  }

  return data
    .filter(tg => tg.tags.length !== 0)
    .flatMap(t =>
      t.tags.map(tag => ({
        label: t.tagNameDisplays[tag] || tag,
        value: generateTagHash(t.privacy, t.id, t.name, tag),
        group: t.nameDisplay,
        color: t.colorHex,
      }))
    )
}
