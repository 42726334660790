import React from 'react'
import styled, { css } from 'styled-components'

const DefaultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease-in;

  ${props =>
    props.selected &&
    css`
      opacity: 1;
    `};
`

const highlighted = css`
  background: #eaf5fe;
  border: 1px solid ${props => props.theme.color.blue};
  color: ${props => props.theme.color.blue};
`

const Button = styled.button`
  border: 1px solid ${props => props.theme.color.grey};
  color: ${props => props.theme.color.grey};
  background: transparent;
  border-radius: 4px;
  padding: ${props => props.theme.gutter.medium};
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSize.caption};
  cursor: pointer;
  transition: all 0.3s ease-in;
  outline: none;

  &:hover {
    opacity: 0.7;
    ${highlighted};
  }

  ${props =>
    props.selected &&
    css`
      ${highlighted};
    `};
`

const defaultButtonCell = (onDefaultButtonClick, props) => {
  const { value, row } = props

  return (
    <DefaultContainer
      id={`row-${row._index}-name-${row.name}-default-button`}
      onClick={() => onDefaultButtonClick(props.original)}
      selected={value}
      className="default-button-container"
    >
      <Button selected={value}>{value ? 'Default' : 'Set as Default'}</Button>
    </DefaultContainer>
  )
}

export default defaultButtonCell
