import _ from 'lodash'
import { FEE_AMOUNT } from 'mgr/pages/single-venue/settings/constants'

const validateFeeAmount = fields => fields.feeAmount in FEE_AMOUNT

const validateAmountFixed = fields => {
  const amountFixedApplies = fields.feeAmount === FEE_AMOUNT.AMOUNT || fields.feeAmount === FEE_AMOUNT.BOTH
  return !amountFixedApplies || fields.amountFixed > 0 || fields.distanceTiers.length > 0
}
const validateDistanceTierAmounts = fields => {
  const distanceTiersApplies = fields.feeAmount === FEE_AMOUNT.AMOUNT && fields.distanceTiers.length > 0
  return !distanceTiersApplies || _.every(fields.distanceTiers.map(dt => dt.amount > 0))
}
const validateDistanceTierDistances = fields => {
  const distanceTiersApplies = fields.feeAmount === FEE_AMOUNT.AMOUNT && fields.distanceTiers.length > 0
  if (!distanceTiersApplies) {
    return true
  }
  let lastDistance = -1
  for (const distanceTier of fields.distanceTiers) {
    if (distanceTier.distance <= lastDistance) {
      return false
    }
    lastDistance = distanceTier.distance
  }
  return true
}
const validateAmountFraction = fields => {
  const amountFixedApplies = fields.feeAmount === FEE_AMOUNT.PERCENTAGE || fields.feeAmount === FEE_AMOUNT.BOTH
  return !amountFixedApplies || fields.amountFraction > 0
}
const validateFulfillmentMethods = fields => fields.fulfillmentMethods.length > 0
const validateOrderingSiteIds = fields => fields.orderingSiteIds.length > 0

const validateFee = fields => {
  let fieldsValid = Object.keys(fields).reduce((acc, fieldKey) => {
    // eslint-disable-next-line no-param-reassign
    acc[fieldKey] = true
    return acc
  }, {})

  if (!fields.name) {
    fieldsValid.name = false
  }

  fieldsValid = {
    ...fieldsValid,
    feeAmount: validateFeeAmount(fields),
    amountFixed: validateAmountFixed(fields),
    distanceTierAmounts: validateDistanceTierAmounts(fields),
    distanceTierDistances: validateDistanceTierDistances(fields),
    amountFraction: validateAmountFraction(fields),
    fulfillmentMethods: validateFulfillmentMethods(fields),
    orderingSiteIds: validateOrderingSiteIds(fields),
  }

  return {
    isValid: !Object.values(fieldsValid).includes(false),
    fieldsValid,
  }
}

export default validateFee
