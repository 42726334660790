import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import Icon from 'svr/component-lib/Generic/Icon'
import Input from 'svr/component-lib/Generic/TextInputs/Input'
import CurrencyInput from './CurrencyInput'
import MultiInputRow from './MultiInputRow'

const LeftIconWrapper = styled.div`
  margin-right: ${props => props.theme.gutter.standard};
`

const AdditionalDistanceButton = styled.button`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  background: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  padding: ${props => props.theme.gutter.standard};
  padding-left: 0;
`

const TrashButton = styled.button`
  background: none;
  color: ${props => props.theme.black};
  cursor: pointer;
  border: none;
  padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.standard};
  padding-left: 0;
`

const TextBetweenInputs = styled.div`
  margin: 0 ${props => props.theme.gutter.medium};
`

const DistanceUnitInputWrapper = styled.div`
  margin-left: ${props => props.theme.gutter.standard};
`

const DistanceUnitDisplayWrapper = styled.span`
  margin: 0 40px 0 ${props => props.theme.gutter.medium};
`

const AmountFixed = ({
  isValidFixed,
  isValidDistanceTierAmounts,
  isValidDistanceTierDistances,
  amountFixed,
  onChangeAmountFixed,
  distanceTiers,
  onChangeDistanceTiers,
  distanceUnit,
  onChangeDistanceUnit,
  costPerExtraDistanceUnit,
  onChangeCostPerExtraDistanceUnit,
  isDeliveryOnlyOptionsVisible,
  currencySymbol,
}) => {
  const onClickAdditionalTier = () => onChangeDistanceTiers([...distanceTiers, { amount: amountFixed, distance: 0 }])
  const updateDistanceTierIndex = (distanceTier, index) =>
    onChangeDistanceTiers(distanceTiers.map((t, i) => (i === index ? distanceTier : t)))
  const removeDistanceTierIndex = index => onChangeDistanceTiers(distanceTiers.filter((t, i) => i !== index))
  const showAdditionalDistanceTiers = !_.isEmpty(distanceTiers)
  return (
    <>
      {isDeliveryOnlyOptionsVisible && showAdditionalDistanceTiers ? (
        <>
          {distanceTiers.map((distanceTier, distanceTierIdx) => (
            <MultiInputRow key={distanceTierIdx}>
              <CurrencyInput
                testId={`service-fee-amount-fixed-tier-amount-${distanceTierIdx}`}
                isValid={isValidDistanceTierAmounts}
                value={distanceTier.amount}
                onChange={v => updateDistanceTierIndex({ ...distanceTier, amount: v }, distanceTierIdx)}
                currencySymbol={currencySymbol}
              />
              <TextBetweenInputs>up until</TextBetweenInputs>
              <Input
                data-test={`service-fee-amount-fixed-tier-distance-${distanceTierIdx}`}
                width={90}
                isValid={isValidDistanceTierDistances}
                value={distanceTier.distance}
                onChange={v => updateDistanceTierIndex({ ...distanceTier, distance: v }, distanceTierIdx)}
                type="number"
                min={0}
                step={0.1}
              />
              {distanceTierIdx === 0 ? (
                <DistanceUnitInputWrapper>
                  <DropdownArrowsPicker
                    name=""
                    testId="service-fee-amount-fixed-distance-unit"
                    choices={[
                      { name: 'mi', value: 'mi' },
                      { name: 'km', value: 'km' },
                    ]}
                    style={{ minWidth: 60, width: 60 }}
                    isLightTheme
                    useOutsideLabel
                    value={distanceUnit || 'mi'}
                    onChangeHandler={onChangeDistanceUnit}
                  />
                </DistanceUnitInputWrapper>
              ) : (
                <DistanceUnitDisplayWrapper>{distanceUnit}</DistanceUnitDisplayWrapper>
              )}
              {distanceTierIdx === distanceTiers.length - 1 && (
                <TrashButton
                  onClick={() => removeDistanceTierIndex(distanceTierIdx)}
                  data-test={`service-fee-amount-fixed-tier-delete-${distanceTierIdx}`}
                >
                  <Icon icon={Icon.ICONS.Trash} size={15} />
                </TrashButton>
              )}
            </MultiInputRow>
          ))}
          <MultiInputRow>
            <AdditionalDistanceButton onClick={onClickAdditionalTier}>
              <LeftIconWrapper>
                <Icon icon={Icon.ICONS.Plus} size={15} />
              </LeftIconWrapper>
              Additional distance tier
            </AdditionalDistanceButton>
          </MultiInputRow>
          <MultiInputRow>
            <CurrencyInput
              testId="service-fee-amount-fixed-additional-unit-cost"
              isValid={isValidFixed}
              value={costPerExtraDistanceUnit}
              onChange={onChangeCostPerExtraDistanceUnit}
              currencySymbol={currencySymbol}
            />
            <TextBetweenInputs>for each additional {distanceUnit}</TextBetweenInputs>
          </MultiInputRow>
        </>
      ) : (
        <>
          <MultiInputRow>
            <CurrencyInput
              testId="service-fee-amount-currency-input"
              isValid={isValidFixed}
              value={amountFixed}
              onChange={onChangeAmountFixed}
              currencySymbol={currencySymbol}
            />
            {isDeliveryOnlyOptionsVisible && <TextBetweenInputs>on all driving distances</TextBetweenInputs>}
          </MultiInputRow>
          {isDeliveryOnlyOptionsVisible && (
            <MultiInputRow>
              <AdditionalDistanceButton onClick={onClickAdditionalTier}>
                <LeftIconWrapper>
                  <Icon icon={Icon.ICONS.Plus} size={15} />
                </LeftIconWrapper>
                Distance Tiers
              </AdditionalDistanceButton>
            </MultiInputRow>
          )}
        </>
      )}
    </>
  )
}

export default AmountFixed
