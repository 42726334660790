import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { updateNavState } from 'mgr/lib/actions/NavActions'
import { VenueGroupNavSections, VenueGroupSubNavSections } from 'mgr/pages/shared/utils/Constants'
import {
  tryLoadVmsRoleTemplates,
  tryDeleteVmsRoleTemplate,
  tryDuplicateVmsRoleTemplate,
  toggleDeleteWarning,
} from 'mgr/pages/venue-group/actions/UserRoles'
import { VmsIcons } from 'svr/common/VmsIcons'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import { textCell, customStyleTextCell } from 'svr/component-lib/Generic/Tables/Cells'
import ActionButton from 'svr/component-lib/Manager/Buttons/ActionButton'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import IconDropdownMenu from 'svr/component-lib/Manager/Menus/IconDropdownMenu'
import MessageBox from 'svr/component-lib/Manager/MessageBox'

const textAlignCss = css`
  width: 100%;
  text-align: right;
`
const UsersFilterBar = styled.div`
  display: flex;
`

// Hide for V1
// const RolesDefinitionHeader = styled.div`
//   text-align: right;
//   padding: 16px;
//   font-size: 15px;
// `
//
// const RolesDefinitionHref = styled.a`
//   color: #6a6a6a;
//   cursor: pointer;
// `
//
// const RolesDefinitionLabel = styled.div`
//   display: inline-block;
// `
//
// const Icon = styled(StyledVmsIconS)`
//   display: inline-block;
//   padding: 0 8px;
// `

export const customTableContainerStyles = css`
  height: 100%;
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} 0;
  .ReactTable {
    border-style: none;
    border-width: 2px;
    overflow: hidden;
    padding: 0 ${props => props.theme.gutter.double};

    .rt-tr-group {
      overflow: hidden;
      flex: 0 0 auto !important;
    }

    .rt-tr {
      font-size: ${props => props.theme.fontSize.body};
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      &.-even {
        background-color: ${props => props.theme.color.white};
      }
    }

    .rt-thead {
      .rt-th {
        padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.double};
        font-size: ${props => props.theme.fontSize.body};
        font-weight: 500;
        line-height: 17px;
        text-transform: none;
        :focus {
          outline: none;
        }
      }

      &.-header {
        background-color: ${props => props.theme.color.white};
        box-shadow: None;
        .rt-th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
    }

    .rt-tbody {
      overflow: auto;
      .rt-tr:not(.-padRow) {
        :hover {
          cursor: default;
          background: ${props => props.theme.color.white};
          .highlighted {
            background: ${props => props.theme.color.white};
          }
        }
      }
    }

    .rt-td {
      padding: ${props => props.theme.gutter.half} ${props => props.theme.gutter.double} !important;
    }

    .align-right {
      text-align: right;
    }

    .-sort-desc,
    .-sort-asc {
      box-shadow: none !important;
    }
  }
`

const DeleteWarning = ({ proceed, cancel }) => (
  <MessageBox
    handleActionClick={proceed}
    handleCloseClick={cancel}
    dialogType={MessageBox.DialogType.WARNING}
    header="ARE YOU SURE"
    actionButtonText="Confirm"
    explanation="Are you sure you want to remove this role? This will remove access from all users with this role. This cannot be undone."
  />
)

class UserRolesLayout extends React.PureComponent {
  componentDidMount = () => {
    this.props.updateNavState(VenueGroupNavSections.USERS, VenueGroupSubNavSections.ROLES)
    this.props.tryLoadVmsRoleTemplates(this.props.venueGroup)
  }

  handleCreateRole = () => {
    const createRoleUrl = `${this.props.history.location.pathname}/create`
    this.props.history.push(createRoleUrl)
  }

  handleEditRole = roleId => {
    const editRoleUrl = `${this.props.history.location.pathname}/edit/${roleId}`
    this.props.history.push(editRoleUrl)
  }

  reloadRoles = () => {
    this.props.tryLoadVmsRoleTemplates(this.props.venueGroup)
  }

  handleDeleteRole = roleId => {
    this.props.toggleDeleteWarning(false)
    this.props.tryDeleteVmsRoleTemplate(roleId).then(this.reloadRoles)
  }

  handleDuplicateRole = roleId => {
    this.props.tryDuplicateVmsRoleTemplate(roleId).then(this.reloadRoles)
  }

  addRoleButton = () => (
    <ActionButton
      fontSize="15px"
      horizontalPadding="20px"
      onClickHandler={this.handleCreateRole}
      dataTest="create-role-button"
      className="create-role-button"
      actionText="Create Role"
      backgroundColor="#347baf"
    />
  )

  roleActionIcon = roleId => {
    const ROLE_ICON_DROPDOWN_VALUES = [
      {
        name: 'Edit',
        icon: VmsIcons.EditPencil,
        onClickHandler: () => {
          this.handleEditRole(roleId)
        },
      },
      {
        name: 'Delete',
        icon: VmsIcons.Delete,
        onClickHandler: () => {
          this.props.toggleDeleteWarning(true, roleId)
        },
      },
      {
        name: 'Duplicate',
        icon: VmsIcons.Copy,
        onClickHandler: () => {
          this.handleDuplicateRole(roleId)
        },
      },
    ]
    return (
      <IconDropdownMenu
        choices={ROLE_ICON_DROPDOWN_VALUES}
        isLightTheme
        name={`vmsrole-dropdown-${roleId}`}
        id="vmsrole-dropdown"
        icon={VmsIcons.More}
        textAlign="right"
        rightAlignPx="32px"
        iconColor="#888C92"
      />
    )
  }

  formatRolesDataForTable = roleTemplates =>
    _.map(roleTemplates, role => ({
      dataTestId: role.id,
      name: role.name,
      numVaus: role.num_vaus.toString(),
      action: this.roleActionIcon(role.id),
    }))

  render() {
    const { venueGroup, userDomain, vmsRoleTemplates, rolesListIsLoading, showWarning, toggleDeleteWarning, selectedRoleId } = this.props
    const formattedRoleTemplates = this.formatRolesDataForTable(vmsRoleTemplates)

    return (
      <ContentLayout title="Roles" venueGroup={venueGroup} userDomain={userDomain} Actions={this.addRoleButton()}>
        <UsersFilterBar />
        {/* <RolesDefinitionHeader> */}
        {/*  <RolesDefinitionHref> */}
        {/*    <RolesDefinitionLabel>Roles Definition</RolesDefinitionLabel> */}
        {/*    <Icon>{VmsIcons.Logout}</Icon> */}
        {/*  </RolesDefinitionHref> */}
        {/* </RolesDefinitionHeader> */}
        <Table
          manual
          loading={rolesListIsLoading}
          showPagination={false}
          sortable={false}
          data={formattedRoleTemplates}
          pageSize={formattedRoleTemplates.length}
          customTableContainerStyles={customTableContainerStyles}
          columns={[
            {
              Header: 'Role Name',
              accessor: 'name',
              Cell: textCell,
            },
            {
              Header: '# of Users',
              accessor: 'numVaus',
              width: 200,
              headerClassName: 'align-right',
              Cell: customStyleTextCell.bind(null, textAlignCss),
            },
            {
              Header: '',
              accessor: 'action',
              headerClassName: 'align-right',
              Cell: customStyleTextCell.bind(null, textAlignCss),
            },
          ]}
        />
        {showWarning && selectedRoleId && (
          <DeleteWarning proceed={() => this.handleDeleteRole(selectedRoleId)} cancel={() => toggleDeleteWarning(false)} />
        )}
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => ({
  venueGroup: state.appState.venueGroup,
  userDomain: state.appState.userDomain,
  vmsRoleTemplates: state.roles.vmsRoleTemplates,
  rolesListIsLoading: state.roles.rolesListIsLoading,
  showWarning: state.roles.showWarning,
  selectedRoleId: state.roles.selectedRoleId,
})

const mapDispatchToProps = {
  updateNavState,
  tryLoadVmsRoleTemplates,
  tryDeleteVmsRoleTemplate,
  tryDuplicateVmsRoleTemplate,
  toggleDeleteWarning,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRolesLayout)
