import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { updateNavState } from 'mgr/lib/actions/NavActions'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { VenueGroupNavSections, VenueGroupSubNavSections } from 'mgr/pages/shared/utils/Constants'
import {
  tryLoadVenuePods,
  tryFetchUsers,
  tryDeleteUser,
  changeUserSearchQuery,
  changeRolesFilter,
  changeVenuePodsRadio,
  changeVenuePodsFilter,
  changeGroupPortalAccessFilter,
  displayAdtlAccessModal,
  closeAdtlAccessModal,
  toggleDeleteWarning,
} from 'mgr/pages/venue-group/actions/UserAccounts'
import { tryLoadVmsRoleTemplates } from 'mgr/pages/venue-group/actions/UserRoles'
import { VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'
import ModalCover from 'svr/component-lib/Generic/Modals/ModalCover'
import Caret from 'svr/component-lib/Generic/Symbols/Caret'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import { textCell, verticalSplitTextCell, customStyleTextCell } from 'svr/component-lib/Generic/Tables/Cells'
import IconTextInput from 'svr/component-lib/Generic/TextInputs/IconTextInput'
import ActionButton from 'svr/component-lib/Manager/Buttons/ActionButton'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import GroupDropdownMenu from 'svr/component-lib/Manager/Menus/GroupDropdownMenu'
import IconDropdownMenu from 'svr/component-lib/Manager/Menus/IconDropdownMenu'
import MessageBox from 'svr/component-lib/Manager/MessageBox'

const zIndexCss = css`
  z-index: 999;
  border-radius: 8px;
`

const paddingCss = css`
  padding: 10px 0;
`

const textAlignCenterCss = css`
  width: 100%;
  text-align: center;
`

const textAlignRightCss = css`
  width: 100%;
  text-align: right;
`
const searchContainerStyles = css`
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 16px;
  min-width: 260px;
`
const searchIconStyles = css`
  font-size: 15px;
`
const searchInputStyles = css`
  font-size: 15px;
  padding-left: 12px;
`
const customRadioIconStyles = css`
  background-color: #fff;
  width: 15px;
  height: 15px;
`
const inputStyleCss = {
  width: '200px',
}
const ModalBody = styled.div`
  width: 564px;
  height: 374px;
  border-radius: 5px;
  background-color: white;
  padding: 16px;
`
const ModalClose = styled.div`
  text-align: right;
`
const ModalContents = styled.div`
  padding: 0 2px 0 20px;
`
const ModalHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 13px;
`
const AdtlAccessTableWrapper = styled.div`
  max-height: 305px;
  overflow-y: scroll;
`
const AdtlAccessTable = styled.table`
  width: 100%;
  border: 1px solid #dedede;
`
const AdtlAccessTbody = styled.tbody``
const AdtlAccessRow = styled.tr``
const AdtlAccessHeaderCell = styled.td`
  font-size: 12px;
  font-weight: bold;
  color: #6a6a6a;
  padding: 13px 0 13px 23px;
  border: 1px solid #dedede;
`
const AdtlAccessTextCell = styled.td`
  font-size: 13px;
  color: #303030;
  padding: 13px 0 13px 23px;
  border: 1px solid #dedede;
`
const FiltersBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0 24px;
`
const DropdownFilters = styled.div`
  display: flex;
`
const GroupFilterWrapper = styled.div`
  padding-right: 13px;
`
const FilterWrapper = styled.div`
  padding-right: 4px;
`
const SearchFilter = styled.div``
const UsersDefinitionHeader = styled.div`
  text-align: right;
  padding: 16px;
  font-size: 15px;
`

const UsersDefinitionGroup = styled.div`
  display: inline-block;
  padding-left: ${props => props.theme.padding.medium};
`

const UsersDefinitionLabel = styled.div`
  display: inline-block;
  color: #1e252f;
  padding: 0 ${props => props.theme.padding.small};
`

const Icon = styled(StyledVmsIconXS)`
  display: inline-block;
  color: #6a6a6a;
  ${props => props.customIconStyles};
`

const closeIconCss = css`
  font-size: 18px;
  color: #092238;
  cursor: pointer;
  padding-right: 2px;
`

const customButtonStyles = css`
  border: 1px solid #347baf;
  color: #347baf;
`

const VmsAccessUserDisplayLine = styled.div`
  display: flex;
  align-items: center;
  color: #6a6a6a;
  cursor: ${props => (props.isAdtlAccessCell ? 'pointer' : 'auto')};
  :hover {
    color: ${props => (props.isAdtlAccessCell ? '#347baf' : '#6a6a6a')};
    text-decoration: ${props => (props.isAdtlAccessCell ? 'underline' : 'none')};
  }
`
const AccessDisplayPortion = styled.span``
const AccessDisplayLineText = styled.span`
  padding: 0 10px 0 5px;
`
const VmsAccessUserCell = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
`
const RoleTitleDisplay = styled.div`
  padding-right: 10px;
`
const TablePaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 19px 0;
`
const RowsInfo = styled.div``
const PaginationInfo = styled.div`
  padding: 0 48px;
`
const CaretContainer = styled.div`
  padding-right: 20px;
  cursor: pointer;
`

const customTableContainerStyles = css`
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} 0;
  .ReactTable {
    border-style: none;
    border-width: 2px;
    padding: 0 ${props => props.theme.gutter.double};

    .rt-tr-group {
      overflow: hidden;
      flex: 0 0 auto !important;
    }

    .rt-tr {
      font-size: ${props => props.theme.fontSize.body};
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      &.-even {
        background-color: ${props => props.theme.color.white};
      }
    }

    .rt-thead {
      .rt-th {
        padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.double};
        font-size: ${props => props.theme.fontSize.body};
        font-weight: 500;
        line-height: 17px;
        text-transform: none;
        :focus {
          outline: none;
        }
      }

      &.-header {
        background-color: ${props => props.theme.color.white};
        box-shadow: None;
        .rt-th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
    }

    .rt-tbody {
      overflow: auto;
      .rt-tr:not(.-padRow) {
        :hover {
          cursor: default;
          background: ${props => props.theme.color.white};
          .highlighted {
            background: ${props => props.theme.color.white};
          }
        }
      }
    }

    .rt-td {
      padding: ${props => props.theme.gutter.half} ${props => props.theme.gutter.double} !important;
    }

    .align-right {
      text-align: right;
    }

    .-sort-desc,
    .-sort-asc {
      box-shadow: none !important;
    }
  }
`

const DeleteWarning = ({ proceed, cancel }) => (
  <MessageBox
    handleActionClick={proceed}
    handleCloseClick={cancel}
    dialogType={MessageBox.DialogType.WARNING}
    header="ARE YOU SURE"
    actionButtonText="Confirm"
    explanation="Are you sure you want to remove this user? This will remove access from all venues. This cannot be undone."
  />
)

class UserAccountsLayout extends React.PureComponent {
  componentDidMount = () => {
    const {
      updateNavState,
      venueGroup,
      tryFetchUsers,
      tryLoadVmsRoleTemplates,
      tryLoadVenuePods,
      usersCursor,
      groupPortalFilterValue,
      roleFilterValues,
    } = this.props
    updateNavState(VenueGroupNavSections.USERS, VenueGroupSubNavSections.USER_ACCOUNTS)
    tryFetchUsers(venueGroup, usersCursor, groupPortalFilterValue, roleFilterValues)
    tryLoadVmsRoleTemplates(venueGroup)
    tryLoadVenuePods(venueGroup)
  }

  reloadUsers = () => {
    this.props.tryFetchUsers(this.props.venueGroup, this.props.usersCursor, this.props.groupPortalFilterValue, this.props.roleFilterValues)
  }

  handleCreateUser = () => {
    const createUserUrl = `${this.props.history.location.pathname}/create`
    this.props.history.push(createUserUrl)
  }

  handleExportUsers = () => {}

  handleEditUser = userId => {
    const editUserUrl = `${this.props.history.location.pathname}/edit/${userId}`
    this.props.history.push(editUserUrl)
  }

  handleDeleteUser = userId => {
    this.props.toggleDeleteWarning(false)
    this.props.tryDeleteUser(userId).then(this.reloadUsers)
  }

  addUserButton = () =>
    this.props.canAdminUsers && (
      <ActionButton
        fontSize="15px"
        horizontalPadding="32px"
        onClickHandler={this.handleCreateUser}
        dataTest="create-user-button"
        className="create-user-button"
        actionText="Create User"
        backgroundColor="#347baf"
      />
    )

  exportUsersButton = () => (
    <ActionButton
      fontSize="15px"
      horizontalPadding="32px"
      onClickHandler={this.handleExportUsers}
      className="export-users-button"
      actionText="Export"
      backgroundColor="#FFF"
      customButtonStyles={customButtonStyles}
    />
  )

  vmsAccessDisplayLine = (isGroupLevelUser, venueGroupName, podNames, internalVenueNames, isAdtlAccessCell, onClickHandler) => (
    <VmsAccessUserDisplayLine isAdtlAccessCell={isAdtlAccessCell} onClick={onClickHandler}>
      {isGroupLevelUser && (
        <AccessDisplayPortion>
          <Icon>{VmsIcons.Company}</Icon>
          <AccessDisplayLineText>{venueGroupName}</AccessDisplayLineText>
        </AccessDisplayPortion>
      )}
      {podNames !== '' && (
        <AccessDisplayPortion>
          <Icon>{VmsIcons.SiteMap}</Icon>
          <AccessDisplayLineText>{podNames}</AccessDisplayLineText>
        </AccessDisplayPortion>
      )}
      {internalVenueNames !== '' && (
        <AccessDisplayPortion>
          <Icon>{VmsIcons.StoreFront}</Icon>
          <AccessDisplayLineText>{internalVenueNames}</AccessDisplayLineText>
        </AccessDisplayPortion>
      )}
    </VmsAccessUserDisplayLine>
  )

  displayAdtlAccessPopup = (userFullName, rolesToAccessList) => {
    this.props.displayAdtlAccessModal(userFullName, rolesToAccessList)
  }

  vmsAccessCellDisplay = (venueGroupName, roleName, vmsAccessUser, rolesToAccessList, userFullName, isAdtlAccessCell) => {
    const internalVenueNames = vmsAccessUser.internal_venue_names.join(', ')
    const podNamesPre = vmsAccessUser.pod_names.join(', ')
    const podNamesSuffix = podNamesPre !== '' && internalVenueNames !== '' ? ', ' : ''
    const podNames = podNamesPre + podNamesSuffix
    const vgName = vmsAccessUser.is_group_level_user && podNames !== '' ? `${venueGroupName}, ` : venueGroupName
    const vmsAccessDisplayLineText = this.vmsAccessDisplayLine(
      vmsAccessUser.is_group_level_user,
      vgName,
      podNames,
      internalVenueNames,
      isAdtlAccessCell,
      isAdtlAccessCell
        ? () => {
            this.displayAdtlAccessPopup(userFullName, rolesToAccessList)
          }
        : null
    )
    return (
      <VmsAccessUserCell>
        {roleName && <RoleTitleDisplay>{roleName}</RoleTitleDisplay>}
        {vmsAccessDisplayLineText}
      </VmsAccessUserCell>
    )
  }

  groupPortalAccessIcon = hasGroupPortalAccess => <Icon>{hasGroupPortalAccess ? VmsIcons.Check : VmsIcons.Close}</Icon>

  userActionIcon = userId => {
    if (!this.props.canAdminUsers) {
      return null
    }
    const USER_ICON_DROPDOWN_VALUES = [
      {
        name: 'Edit',
        icon: VmsIcons.EditPencil,
        onClickHandler: () => {
          this.handleEditUser(userId)
        },
      },
      {
        name: 'Delete',
        icon: VmsIcons.Delete,
        onClickHandler: () => {
          this.props.toggleDeleteWarning(true, userId)
        },
      },
    ]
    return (
      <IconDropdownMenu
        choices={USER_ICON_DROPDOWN_VALUES}
        isLightTheme
        name={`users-dropdown-${userId}`}
        id="users-dropdown"
        icon={VmsIcons.More}
        textAlign="right"
        rightAlignPx="32px"
        iconColor="#888C92"
      />
    )
  }

  formatUsersDataForTable = (venueGroupName, users) =>
    _.map(users, user => {
      const userFullName = [user.first_name, user.last_name].join(' ')
      return {
        name: {
          topValue: userFullName,
          bottomValue: user.email,
        },
        primary_access: this.vmsAccessCellDisplay(
          venueGroupName,
          user.primary_access_user.role_name,
          user.primary_access_user,
          null,
          userFullName,
          false
        ),
        additional_access: this.vmsAccessCellDisplay(
          venueGroupName,
          null,
          user.adtl_access_users,
          user.roles_to_access_list,
          userFullName,
          true
        ),
        group_portal_access: this.groupPortalAccessIcon(user.has_venue_group_portal_access),
        action: this.userActionIcon(user.id),
      }
    })

  paginateUsersLeft = () => {
    if (this.props.usersCursor === 0) {
      return
    }
    this.props.tryFetchUsers(
      this.props.venueGroup,
      this.props.usersCursor - this.props.maxUsersPerPage,
      this.props.groupPortalFilterValue,
      this.props.roleFilterValues
    )
  }

  paginateUsersRight = () => {
    if (this.props.usersCursor + this.props.maxUsersPerPage >= this.props.totalNumUsers) {
      return
    }
    this.props.tryFetchUsers(
      this.props.venueGroup,
      this.props.usersCursor + this.props.maxUsersPerPage,
      this.props.groupPortalFilterValue,
      this.props.roleFilterValues
    )
  }

  handleCloseAdtlAccessModal = e => {
    if (e.target !== e.currentTarget) {
      return
    }
    this.props.closeAdtlAccessModal()
  }

  handleOnUserSearchQueryChange = (value, field) => {
    this.props.changeUserSearchQuery(value)
  }

  handleSubmission = () => {
    this.props.tryFetchUsers(this.props.venueGroup, 0, this.props.groupPortalFilterValue, this.props.roleFilterValues)
  }

  changeRolesFilter = (selectedValues, actionType, value) => {
    let newSelectedValues = selectedValues.slice(0)
    if (value === 'All' || selectedValues.length === 0) {
      newSelectedValues = ['All']
    }
    if (newSelectedValues.length > 1 && newSelectedValues.includes('All')) {
      newSelectedValues = _.difference(newSelectedValues, ['All'])
    }
    this.props.changeRolesFilter(newSelectedValues)
    this.props.tryFetchUsers(this.props.venueGroup, 0, this.props.groupPortalFilterValue, newSelectedValues)
  }

  changeGroupPortalAccessFilter = value => {
    this.props.changeGroupPortalAccessFilter(value)
    this.props.tryFetchUsers(this.props.venueGroup, 0, value, this.props.roleFilterValues)
  }

  render = () => {
    const {
      venueGroup,
      userDomain,
      users,
      usersListIsLoading,
      usersCursor,
      totalNumUsers,
      maxUsersPerPage,
      showAdtlAccessModal,
      selectedUserFullName,
      rolesToAccessList,
      userSearchQuery,
      roleFilterValues,
      venuePodsFilterValues,
      groupPortalFilterValue,
      vmsRoleTemplateChoices,
      groupPortalChoices,
      formattedFilterVenuePodsChoices,
      venueGroupPodsRadioChoices,
      venuePodsRadioFilterValue,
      showWarning,
      toggleDeleteWarning,
      selectedUserId,
    } = this.props
    const formattedUserAccounts = this.formatUsersDataForTable(venueGroup.name, users)
    const startPaginationNum = usersCursor + 1
    const endPaginationNum = usersCursor + formattedUserAccounts.length
    return (
      <ContentLayout
        title="User Accounts"
        venueGroup={venueGroup}
        userDomain={userDomain}
        Actions={this.addUserButton()}
        // AdditionalActions={this.exportUsersButton()} Hiding for v1
      >
        {showAdtlAccessModal && (
          <ModalCover customModalScreen={zIndexCss} onClickHandler={this.handleCloseAdtlAccessModal}>
            <ModalBody>
              <ModalClose>
                <Icon customIconStyles={closeIconCss} onClick={this.handleCloseAdtlAccessModal}>
                  {VmsIcons.Close}
                </Icon>
              </ModalClose>
              <ModalContents>
                <ModalHeader>{selectedUserFullName}'s Permissions</ModalHeader>
                <AdtlAccessTableWrapper>
                  <AdtlAccessTable>
                    <AdtlAccessTbody>
                      <AdtlAccessRow key="adtl-access-header">
                        <AdtlAccessHeaderCell>Role</AdtlAccessHeaderCell>
                        <AdtlAccessHeaderCell>Access</AdtlAccessHeaderCell>
                      </AdtlAccessRow>
                      {_.map(rolesToAccessList, (roleToAccess, idx) => (
                        <AdtlAccessRow key={`role-access-${idx}`}>
                          <AdtlAccessTextCell>{roleToAccess.role_name}</AdtlAccessTextCell>
                          <AdtlAccessTextCell>{roleToAccess.access_name}</AdtlAccessTextCell>
                        </AdtlAccessRow>
                      ))}
                    </AdtlAccessTbody>
                  </AdtlAccessTable>
                </AdtlAccessTableWrapper>
              </ModalContents>
            </ModalBody>
          </ModalCover>
        )}
        <FiltersBar>
          <DropdownFilters>
            <FilterWrapper>
              <DropdownArrowsPicker
                key="ROLES"
                name="ROLES"
                choices={vmsRoleTemplateChoices}
                values={roleFilterValues}
                isMultiSelect
                isLightTheme
                style={inputStyleCss}
                onChangeHandler={this.changeRolesFilter}
              />
            </FilterWrapper>
            <GroupFilterWrapper>
              <GroupDropdownMenu
                key="VENUE_PODS_GROUP"
                name="VENUE/PODS/GROUP"
                groups={formattedFilterVenuePodsChoices}
                values={venuePodsFilterValues}
                radioChoices={venueGroupPodsRadioChoices}
                selectedRadioValue={venuePodsRadioFilterValue}
                customRadioIconStyles={customRadioIconStyles}
                onChangeHandler={this.props.changeVenuePodsFilter}
                onRadioChangeHandler={this.props.changeVenuePodsRadio}
                onDropdownCloseHandler={this.handleSubmission}
                isMultiSelect
                isLightTheme
                style={inputStyleCss}
              />
            </GroupFilterWrapper>
            <FilterWrapper>
              <DropdownArrowsPicker
                key="GROUP_PORTAL_ACCESS"
                name="GROUP PORTAL ACCESS"
                choices={groupPortalChoices}
                value={groupPortalFilterValue}
                isMultiSelect={false}
                isLightTheme
                style={inputStyleCss}
                onChangeHandler={this.changeGroupPortalAccessFilter}
              />
            </FilterWrapper>
          </DropdownFilters>
          <SearchFilter>
            <IconTextInput
              name="users-search-filter"
              value={userSearchQuery}
              onChange={this.handleOnUserSearchQueryChange}
              handleSubmission={this.handleSubmission}
              icon={VmsIcons.Search}
              iconColor="#6a6a6a"
              containerStyles={searchContainerStyles}
              iconStyles={searchIconStyles}
              inputStyles={searchInputStyles}
              placeholderLabel="Search name, email"
            />
          </SearchFilter>
        </FiltersBar>
        <UsersDefinitionHeader>
          <UsersDefinitionGroup>
            <Icon>{VmsIcons.StoreFront}</Icon>
            <UsersDefinitionLabel>Venue</UsersDefinitionLabel>
          </UsersDefinitionGroup>
          <UsersDefinitionGroup>
            <Icon>{VmsIcons.SiteMap}</Icon>
            <UsersDefinitionLabel>Pods</UsersDefinitionLabel>
          </UsersDefinitionGroup>
          <UsersDefinitionGroup>
            <Icon>{VmsIcons.Company}</Icon>
            <UsersDefinitionLabel>Group</UsersDefinitionLabel>
          </UsersDefinitionGroup>
        </UsersDefinitionHeader>
        <Table
          manual
          loading={usersListIsLoading}
          showPagination={false}
          sortable={false}
          data={formattedUserAccounts}
          pageSize={formattedUserAccounts.length}
          customTableContainerStyles={customTableContainerStyles}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              Cell: verticalSplitTextCell.bind(null, paddingCss),
            },
            {
              Header: 'Primary Access',
              accessor: 'primary_access',
              Cell: textCell,
            },
            {
              Header: 'Additional Access',
              accessor: 'additional_access',
              Cell: textCell,
            },
            {
              Header: 'Group Portal Access',
              accessor: 'group_portal_access',
              Cell: customStyleTextCell.bind(null, textAlignCenterCss),
              width: 200,
            },
            {
              Header: '',
              accessor: 'action',
              headerClassName: 'align-right',
              Cell: customStyleTextCell.bind(null, textAlignRightCss),
              width: 200,
            },
          ]}
        />
        <TablePaginationContainer>
          <RowsInfo>Rows per page: {maxUsersPerPage}</RowsInfo>
          <PaginationInfo>
            {startPaginationNum} - {endPaginationNum} of {totalNumUsers}
          </PaginationInfo>
          <CaretContainer onClick={this.paginateUsersLeft}>
            <Caret direction="left" fontSize="20px" />
          </CaretContainer>
          <CaretContainer onClick={this.paginateUsersRight}>
            <Caret direction="right" fontSize="20px" />
          </CaretContainer>
        </TablePaginationContainer>
        {showWarning && selectedUserId && (
          <DeleteWarning proceed={() => this.handleDeleteUser(selectedUserId)} cancel={() => toggleDeleteWarning(false)} />
        )}
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => ({
  venueGroup: state.appState.venueGroup,
  userDomain: state.appState.userDomain,
  users: state.users.users,
  usersListIsLoading: state.users.usersListIsLoading,
  usersCursor: state.users.cursor,
  totalNumUsers: state.users.totalNumUsers,
  maxUsersPerPage: state.users.maxUsersPerPage,
  showAdtlAccessModal: state.users.showAdtlAccessModal,
  selectedUserFullName: state.users.selectedUserFullName,
  rolesToAccessList: state.users.rolesToAccessList,
  userSearchQuery: state.users.userSearchQuery,
  roleFilterValues: state.users.roleFilterValues,
  venuePodsFilterValues: state.users.venuePodsFilterValues,
  groupPortalFilterValue: state.users.groupPortalFilterValue,
  vmsRoleTemplateChoices: state.roles.vmsRoleTemplateChoices,
  groupPortalChoices: state.users.groupPortalChoices,
  formattedFilterVenuePodsChoices: state.users.formattedFilterVenuePodsChoices,
  venueGroupPodsRadioChoices: state.users.venueGroupPodsRadioChoices,
  venuePodsRadioFilterValue: state.users.venuePodsRadioFilterValue,
  showWarning: state.users.showWarning,
  selectedUserId: state.users.selectedUserId,
  canAdminUsers: state.appState.userDomain.f_user_admin,
})

const mapDispatchToProps = {
  updateNavState,
  tryLoadVmsRoleTemplates,
  tryLoadVenuePods,
  tryFetchUsers,
  tryDeleteUser,
  changeUserSearchQuery,
  changeRolesFilter,
  changeVenuePodsRadio,
  changeVenuePodsFilter,
  changeGroupPortalAccessFilter,
  displayAdtlAccessModal,
  closeAdtlAccessModal,
  toggleDeleteWarning,
}
export default connect(mapStateToProps, mapDispatchToProps)(UserAccountsLayout)
