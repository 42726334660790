import { useState, useCallback } from 'react'

export const useBoolToggle = (defaultValue = false, deps = []) => {
  const [isToggle, updateToggle] = useState(defaultValue)

  const handleToggle = useCallback(value => {
    updateToggle(prevIsToggle => (typeof value === 'boolean' ? value : !prevIsToggle))
  }, deps)

  return [isToggle, handleToggle]
}
