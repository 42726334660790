import type { Product } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

export const computeFilteredBySearchValueProductList = <T extends Product>({
  orderingProductsList,
  productFilterSearchValue,
}: {
  orderingProductsList: T[]
  productFilterSearchValue?: string
}) =>
  orderingProductsList.filter(product => {
    if (!productFilterSearchValue) {
      return true
    }
    const searchValue = productFilterSearchValue.toLowerCase()
    return product?.name?.toLowerCase().includes(searchValue) || product?.displayId?.toLowerCase().includes(searchValue)
  })
