import _ from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import DirtyStateUnloader from 'mgr/lib/components/DirtyStateUnloader'
import getAvailableSites from 'mgr/pages/single-venue/settings/components/ordering/CheckoutFee/CheckoutFeeItemForm/getAvailableSites'
import { FEE_AMOUNT } from 'mgr/pages/single-venue/settings/constants'
import useForm from 'svr/common/hooks/useForm'
import Form from 'svr/component-lib/Generic/Form'
import Select from 'svr/component-lib/Generic/Select'
import Slideout from 'svr/component-lib/Generic/Slideout'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import { FULFILLMENT_METHOD } from 'svr/constants'
import ButtonControls from './ButtonControls'
import FeeAmount from './FeeAmount'
import FeeMethod from './FeeMethod'
import Sites from './Sites'
import validateFee from './validateFee'

const Container = styled.div`
  padding: 24px;
  font-size: 15px;
`

const FieldItem = styled.div`
  padding-top: 36px;
`

const Header = styled.div`
  font-weight: bold;
  padding-bottom: 8px;
`

const CheckoutFeeItemForm = ({
  onSubmit,
  onDelete,
  feeItem,
  orderingSites,
  isOpen,
  isNew,
  taxGroups,
  onClose,
  currencySymbol,
  deliveryPartnerKey,
}) => {
  const taxGroupsOptions = [
    ...taxGroups.map(tax => ({
      value: tax.id,
      label: tax.name,
    })),
  ]

  const initialFields = {
    name: '',
    feeAmount: '',
    amountFixed: 0,
    amountFraction: 0,
    distanceTiers: [],
    distanceUnit: 'mi',
    costPerExtraDistanceUnit: 0,
    isEnabled: true,
    taxGroupId: _.get(taxGroups, '[0].id', ''),
    fulfillmentMethods: [],
    orderingSiteIds: [],
    isPassThroughThirdParty: false,
  }
  const { handleChangeFields, handleChange, fields, checkFieldsChanged, resetFields } = useForm(initialFields)

  const [isModalVisible, toggleModalVisible] = useState(false)
  const [isValidateFee, setValidateFee] = useState(false)
  const [slideoutOpenCounter, setSlideoutOpenCounter] = useState(0) // Use to remount fields on new slideout opening
  const [isSitesDefaultCollapsed, setIsSitesDefaultCollapsed] = useState(true)

  const feeItemFields = useMemo(() => {
    if (feeItem) {
      const availableOrderingSiteIds = _.map(getAvailableSites(orderingSites, feeItem.fulfillmentMethods), 'id').sort()
      const isAllOrderingSitesSelected =
        _.isEmpty(feeItem.orderingSiteIds) || _.isEqual(availableOrderingSiteIds, feeItem.orderingSiteIds.sort())
      const feeItemFields = { ...feeItem }
      if (isAllOrderingSitesSelected) {
        feeItemFields.orderingSiteIds = availableOrderingSiteIds
      }
      setIsSitesDefaultCollapsed(isAllOrderingSitesSelected)
      return feeItemFields
    }
    setIsSitesDefaultCollapsed(true)
    return initialFields
  }, [feeItem, orderingSites])

  useEffect(() => {
    resetFields(feeItemFields)
    setValidateFee(false)
    setSlideoutOpenCounter(slideoutOpenCounter + 1)
  }, [feeItemFields])

  const isFieldsChanged = checkFieldsChanged(feeItemFields)

  const { isValid, fieldsValid } = validateFee(fields)
  const fieldsValidUsed = isValidateFee ? fieldsValid : _.mapValues({ ...fieldsValid }, () => true)

  const availableOrderingSites = getAvailableSites(orderingSites, fields.fulfillmentMethods)

  const onFormSubmit = () => {
    if (!isValid) {
      setValidateFee(true)
      return
    }
    const isAllOrderingSitesSelected = _.isEqual(_.map(availableOrderingSites, 'id').sort(), fields.orderingSiteIds.sort())
    if (isAllOrderingSitesSelected) {
      fields.orderingSiteIds = []
    }
    onSubmit(fields)
    resetFields()
  }

  const onChangeTax = taxId => {
    handleChange(taxId, 'taxGroupId')
  }

  const onChangeMethod = newValues => {
    const changedFields = {}
    if (
      fields.feeAmount === FEE_AMOUNT.PASS_THROUGH &&
      fields.fulfillmentMethods.length === 1 &&
      fields.fulfillmentMethods[0] === FULFILLMENT_METHOD.DELIVERY
    ) {
      changedFields.feeAmount = ''
    }

    handleChangeFields({
      fulfillmentMethods: newValues,
      orderingSiteIds: _.map(getAvailableSites(orderingSites, newValues), 'id'),
      ...changedFields,
    })
    setIsSitesDefaultCollapsed(true)
  }

  const handleClose = () => {
    if (!isFieldsChanged) {
      onClose()
      return
    }

    toggleModalVisible(true)
  }

  const slideoutTitle = isNew ? 'Create new service fee' : 'Edit service fee'
  const closeModalWarningTitle = isNew
    ? 'Are you sure that you want to close without saving this Service Fee?'
    : 'Are you sure that you want to close without updating this Service Fee?'

  return (
    <DirtyStateUnloader isDirty={isFieldsChanged} modalQuestionContext="this service fee">
      <Form onSubmit={() => {}}>
        <Slideout onClose={handleClose} isOpen={isOpen} maxWidth="500px" title={slideoutTitle}>
          <Slideout.Content>
            <Container>
              <ActiveLabelTextInput
                dataTest="service-fee-name-input"
                field="name"
                placeholderLabel="Service Fee Name"
                onChange={handleChange}
                isValid={fieldsValidUsed.name}
                value={fields.name}
              />
              <FieldItem>
                <Header>What fulfillment method(s) should this fee apply to?</Header>
                <FeeMethod methodsList={fields.fulfillmentMethods} onChange={onChangeMethod} isValid={fieldsValidUsed.fulfillmentMethods} />
              </FieldItem>
              <FieldItem>
                <Header>Fee amount</Header>
                <FeeAmount
                  fulfillmentMethods={fields.fulfillmentMethods}
                  deliveryPartnerKey={deliveryPartnerKey}
                  feeAmount={fields.feeAmount}
                  amountFixed={fields.amountFixed}
                  amountFraction={fields.amountFraction}
                  isValidFixed={fieldsValidUsed.amountFixed}
                  isValidDistanceTierAmounts={fieldsValidUsed.distanceTierAmounts}
                  isValidDistanceTierDistances={fieldsValidUsed.distanceTierDistances}
                  isValidFraction={fieldsValidUsed.amountFraction}
                  distanceTiers={fields.distanceTiers}
                  distanceUnit={fields.distanceUnit}
                  costPerExtraDistanceUnit={fields.costPerExtraDistanceUnit}
                  currencySymbol={currencySymbol}
                  onChangeFee={value => {
                    handleChange(value, 'feeAmount')
                  }}
                  onChangeAmountFixed={value => {
                    handleChange(value, 'amountFixed')
                    handleChange([], 'distanceTiers')
                    handleChange(0, 'costPerExtraDistanceUnit')
                  }}
                  onChangeAmountFraction={value => {
                    handleChange(value, 'amountFraction')
                  }}
                  onChangeDistanceTiers={value => {
                    handleChange(value, 'distanceTiers')
                    handleChange(0, 'amountFixed')
                  }}
                  onChangeDistanceUnit={value => {
                    handleChange(value, 'distanceUnit')
                  }}
                  onChangeCostPerExtraDistanceUnit={value => {
                    handleChange(value, 'costPerExtraDistanceUnit')
                  }}
                />
              </FieldItem>
              <FieldItem>
                <Header>How much tax should be applied on this service fee?</Header>
                <Select
                  data-test="service-fee-tax-group-select"
                  options={taxGroupsOptions}
                  value={fields.taxGroupId}
                  onChange={onChangeTax}
                />
              </FieldItem>
              <FieldItem>
                <Header>What ordering sites do you want this service fee to apply to?</Header>
                <Sites
                  key={slideoutOpenCounter}
                  isDefaultCollapsed={isSitesDefaultCollapsed}
                  fulfillmentMethods={fields.fulfillmentMethods}
                  isValid={fieldsValidUsed.orderingSiteIds}
                  value={fields.orderingSiteIds}
                  orderingSites={availableOrderingSites}
                  onChangeSites={sitesIds => handleChange(sitesIds, 'orderingSiteIds')}
                />
              </FieldItem>
            </Container>
          </Slideout.Content>
          <Slideout.Controls>
            <ButtonControls isNew={isNew} onSubmit={onFormSubmit} onDelete={onDelete} isAbleToSubmit={isFieldsChanged} />
          </Slideout.Controls>
        </Slideout>
      </Form>
      {isModalVisible && (
        <MessageBox
          handleActionClick={() => {
            toggleModalVisible(false)
            onClose()
          }}
          handleCloseClick={() => {
            toggleModalVisible(false)
          }}
          dialogType={MessageBox.DialogType.WARNING}
          header="Warning"
          explain="Warning"
          details={[]}
          explanation={closeModalWarningTitle}
          actionButtonText="Close"
        />
      )}
    </DirtyStateUnloader>
  )
}

export default CheckoutFeeItemForm
