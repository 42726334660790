import moment from 'moment-timezone'
import styled from 'styled-components'
import { MenusColumn } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenusColumn'
import SettingsTable from 'mgr/pages/single-venue/settings/components/shared/SettingsTable'
import { MiddlewareSource } from 'mgr/pages/single-venue/settings/constants'
import ActionButton from 'svr/component-lib/Manager/Buttons/ActionButton'
import type { Venue } from '@sevenrooms/mgr-core'
import type { EightySixProduct } from 'mgr/pages/single-venue/settings/types/ordering/EightySixProduct.types'
import type { Duration } from 'moment'
import type { Column, SortColumn, TableCellProps } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

const formatDiffStr = (duration: Duration) => {
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  const parts = []
  if (days) {
    parts.push(`${days}d`)
  }
  if (hours) {
    parts.push(`${hours}h`)
  }
  if (minutes) {
    parts.push(`${minutes}m`)
  }
  return parts.join(' ')
}

const Highlighted = styled.div`
  background: #fdf4ed;
  border: 1px solid #f7cba4;
  color: #f19850;
  font-size: 12px;
  padding: ${props => props.theme.gutter.half};
  border-radius: 4px;
`

interface TimeUntilAvailableProps {
  snoozeEndTime: string | null
  rowIndex: number
}

function TimeUntilAvailable({ snoozeEndTime, rowIndex }: TimeUntilAvailableProps) {
  let timeDiffText = !snoozeEndTime && 'Indefinite'

  if (snoozeEndTime) {
    const start = moment()
    const end = moment(`${snoozeEndTime}Z`)
    const diff = end.diff(start)

    timeDiffText = `Available in: ${formatDiffStr(moment.duration(diff))}`
  }

  return <Highlighted data-test={`86-products-list-entry-${rowIndex}-countdown`}>{timeDiffText}</Highlighted>
}

interface ProductListTableProps {
  productList: EightySixProduct[]
  venue: Venue
  sorted?: SortColumn[]
  onSortedChange?: (data: SortColumn[]) => void
  isLoading?: boolean
  onEightSixItemClick: (product: EightySixProduct) => void
  productStateId: string
}

function EightySixSettingsTable({
  productList = [],
  venue,
  sorted,
  onSortedChange,
  isLoading = false,
  onEightSixItemClick,
  productStateId,
}: ProductListTableProps) {
  const isNoPosMiddlewareSource = venue.orderingMiddlewareSource === MiddlewareSource.NO_POS
  const columns = [
    {
      Header: 'Item Name',
      accessor: 'name',
      Cell: ({ value, index }: { value: string; index: number }) => <div data-test={`86-products-list-entry-${index}-name`}>{value}</div>,
    },
    {
      Header: 'Product Type',
      accessor: 'typeDisplayName',
      Cell: ({ value, index }: { value: string; index: number }) => (
        <div data-test={`86-products-list-entry-${index}-product-type`}>{value}</div>
      ),
    },
    ...(isNoPosMiddlewareSource
      ? []
      : [
          {
            Header: 'Identifier/PLU',
            accessor: 'displayId',
            Cell: ({ value, index }: { value: string; index: number }) => (
              <div data-test={`86-products-list-entry-${index}-id/plu`}>{value}</div>
            ),
          },
        ]),
    {
      Header: 'Menu(s)',
      accessor: 'menuNames',
      Cell: ({ value, index }: { value: string[]; index: number }) => (
        <MenusColumn data-test={`86-products-list-entry-${index}-menus`} menuNames={value} />
      ),
    },
    ...(productStateId === 'snoozed'
      ? [
          {
            Header: '86 Countdown',
            sortable: false,
            accessor: '',
            Cell: (props: TableCellProps) => {
              const { original, index: rowIndex } = props
              const { snoozeEndTime } = original as EightySixProduct

              return <TimeUntilAvailable {...{ snoozeEndTime, rowIndex }} />
            },
          },
        ]
      : []),
    {
      Header: '',
      accessor: '',
      Cell: (props: TableCellProps) => (
        <ActionButton
          onClickHandler={() => onEightSixItemClick(props.original as EightySixProduct)}
          actionText={productStateId === 'available' ? '86 Product' : 'Edit Availability'}
          backgroundColor="#347baf"
          dataTest={`86-products-list-entry-${props.index}-action-btn`}
        />
      ),
    },
  ] as Column[]

  return (
    <div data-test="sr-ordering-product-list-table">
      <SettingsTable data={productList} columns={columns} isLoading={isLoading} onSortedChange={onSortedChange} sorted={sorted} />
    </div>
  )
}

export default EightySixSettingsTable
