import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import VmsIcons, { StyledVmsIconS, BorderedVmsIcon, customIcon } from 'svr/common/VmsIcons'

class CoverflowModal extends React.PureComponent {
  componentDidMount() {
    const { venue, date, shift, actuals, onClick } = this.props
    window.modules.coverflow.helpers.displayCoverFlow(
      `/manager/${venue.urlKey}`,
      [shift],
      date.format('MM-DD-YYYY'),
      window.globalInit.mediaUrl,
      'Manager2',
      shift.persistent_id,
      null,
      { is_headless_mode: true },
      actuals,
      onClick
    )
  }

  render() {
    return <div id="coverflow-content-wrapper" />
  }
}

CoverflowModal.propTypes = {
  venue: React.PropTypes.object.isRequired,
  date: React.PropTypes.object.isRequired,
  shift: React.PropTypes.object.isRequired,
  actuals: React.PropTypes.array,
  onClick: React.PropTypes.func.isRequired,
}

const iconSize = 22

const CoverflowControlContainer = styled.div`
  position: relative;
  margin-right: 9px;
`

const CoverflowControlIcon = styled(BorderedVmsIcon)`
  background: ${props => (props.active ? props.theme.color.black : props.theme.color.white)};
  &:hover {
    background: ${props => (props.active ? props.theme.color.black : null)};
  }
`

const CoverflowIcon = styled(customIcon(`${iconSize}px`))`
  color: ${props => (props.active ? props.theme.color.white : props.theme.color.black)};
`

const CoverflowText = styled.div`
  color: ${props => (props.active ? props.theme.color.white : props.theme.color.black)};
  width: 72px;
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: -0.24px;
  padding-left: 7px;
`

const clickOutsideConfig = {
  handleClickOutside(instance) {
    return () => {
      if (!instance.props.active) {
        return
      }
      instance.props.onClickOutside()
    }
  },
}

const CoverflowControl = ({ active, onClick, venue, date, shift, actuals }) => (
  <CoverflowControlContainer data-test="sr-button-cover_flow">
    <CoverflowControlIcon {...{ onClick, active }}>
      <CoverflowIcon {...{ active }}>{VmsIcons.FlowLine} </CoverflowIcon>
      <CoverflowText {...{ active }}>Cover Flow</CoverflowText>
    </CoverflowControlIcon>
    {active && <CoverflowModal {...{ venue, date, shift, actuals, onClick }} />}
  </CoverflowControlContainer>
)

CoverflowControl.propTypes = {
  active: React.PropTypes.bool.isRequired,
  onClick: React.PropTypes.func.isRequired,
  onClickOutside: React.PropTypes.func.isRequired,
  venue: React.PropTypes.object.isRequired,
  date: React.PropTypes.object.isRequired,
  shift: React.PropTypes.object.isRequired,
  actuals: React.PropTypes.array,
}

export default onClickOutside(CoverflowControl, clickOutsideConfig)
