import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { fetchOrderingCheckoutSettings, saveOrderingCheckoutSettings } from 'mgr/lib/services/OrderingCheckoutSettingsServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { batchActions } from 'svr/common/ReduxUtils'
import type { showErrorMessage } from 'mgr/lib/actions/GlobalActions'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { CheckoutSettings, PosFields } from 'mgr/pages/single-venue/settings/types/ordering/Checkout.types'
import type moment from 'moment-timezone'
import type { Dispatch } from 'redux'

export interface LoadCheckoutSettingsStartAction {
  type: typeof ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_START
}
export interface LoadCheckoutSettingsSuccessAction {
  type: typeof ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_SUCCESS
  checkoutSettings: CheckoutSettings
  enforceDoordashRules: boolean
  posFields: PosFields
}
export interface LoadCheckoutSettingsFailAction {
  type: typeof ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_FAIL
}

export const loadCheckoutSettings =
  () =>
  (
    dispatch: Dispatch<
      | LoadCheckoutSettingsStartAction
      | LoadCheckoutSettingsSuccessAction
      | LoadCheckoutSettingsFailAction
      | ReturnType<typeof showErrorMessage>
    >,
    getState: () => State
  ) => {
    const state = getState()
    const { venue } = state.appState
    dispatch({
      type: ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_START,
    })

    return fetchOrderingCheckoutSettings(venue.id).then(
      response =>
        dispatch({
          type: ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_SUCCESS,
          checkoutSettings: response.data.checkout_settings,
          enforceDoordashRules: response.data.enforce_doordash_rules,
          posFields: response.data.pos_fields,
        }),
      e => {
        // eslint-disable-next-line no-console
        console.error('An error occurred fetching ordering checkout settings', e)
        dispatch({
          type: ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_FAIL,
        })
        dispatch(GlobalActions.showErrorMessage('Fetching ordering checkout settings failed, please try again or contact support'))
        return e
      }
    )
  }

export interface ChangeCheckoutFieldAction {
  type: typeof ActionTypes.CHANGE_ORDERING_CHECKOUT_SETTINGS_FIELD
  field: string
  value: string | number | boolean
}
export const changeCheckoutField = (field: string, value: string | number | boolean) => ({
  type: ActionTypes.CHANGE_ORDERING_CHECKOUT_SETTINGS_FIELD,
  field,
  value,
})

export interface ChangePosFieldAction {
  type: typeof ActionTypes.CHANGE_ORDERING_CHECKOUT_POS_FIELD
  id: string
  value: string
}
export const changePosField = (id: string, value: string) => ({
  type: ActionTypes.CHANGE_ORDERING_CHECKOUT_POS_FIELD,
  id,
  value,
})

export interface SaveCheckoutSettingsStartAction {
  type: typeof ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_START
}
export interface SaveCheckoutSettingsSuccessAction {
  type: typeof ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_SUCCESS
  updated: moment.Moment
}
export interface SaveCheckoutSettingsFailAction {
  type: typeof ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_FAIL
}
export const saveCheckoutSettings =
  (checkoutSettings: CheckoutSettings, posFields: PosFields) =>
  (
    dispatch: Dispatch<
      | SaveCheckoutSettingsStartAction
      | SaveCheckoutSettingsSuccessAction
      | SaveCheckoutSettingsFailAction
      | ReturnType<typeof showErrorMessage>
    >,
    getState: () => State
  ) => {
    dispatch({
      type: ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_START,
    })

    const state = getState()
    const venueId = state.appState.venue.id
    const posFieldsDict = posFields.reduce<Record<string, string>>((accumulator, posField) => {
      accumulator[posField.id] = posField.value
      return accumulator
    }, {})

    return saveOrderingCheckoutSettings({
      venueId,
      checkoutSettings,
      posFieldsDict,
    }).then(
      response => {
        const showMsgLevel = response.data.result === 'success' ? GlobalActions.showSuccessMessage : GlobalActions.showWarnMessage
        dispatch(
          batchActions([
            {
              type: ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_SUCCESS,
              updated: response.data.updated,
            },
            showMsgLevel(response.data.message),
          ])
        )
      },
      e => {
        dispatch(GlobalActions.showErrorMessage(`Unable to save checkout settings. ${e}`))
        dispatch({
          type: ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_FAIL,
        })
        return e
      }
    )
  }

export type CheckoutSettingsAction =
  | LoadCheckoutSettingsStartAction
  | LoadCheckoutSettingsSuccessAction
  | LoadCheckoutSettingsFailAction
  | ChangeCheckoutFieldAction
  | ChangePosFieldAction
  | SaveCheckoutSettingsStartAction
  | SaveCheckoutSettingsSuccessAction
  | SaveCheckoutSettingsFailAction
