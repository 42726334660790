import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { DragDropPanel } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/Section/DragDropPanel'
import SearchGroupDropdownMenu from 'svr/component-lib/Manager/Menus/SearchGroupDropdownMenu'
import type { PosTable, PosTableOptions } from '@sevenrooms/core/domain'
import { Box } from '@sevenrooms/core/ui-kit/layout'

export interface PosTablesFieldProps {
  posTables: PosTable[]
  posTableOptions: PosTableOptions[]
  selectedPosTables: string[]
  updateSelectedPosTables: (value: string[]) => void
  removeSelectedPosTable: (value: string) => void
}

export function PosTablesField({
  posTables,
  selectedPosTables,
  posTableOptions,
  updateSelectedPosTables,
  removeSelectedPosTable,
}: PosTablesFieldProps) {
  const panelItems = useMemo(
    () =>
      posTables
        .filter(({ id }) => _.includes(selectedPosTables, id))
        .map(posTable => ({
          id: posTable.id,
          title: posTable.name,
        })),
    [selectedPosTables, posTables]
  )

  const groups = useMemo(
    () => [
      {
        choices: posTableOptions.map(item => ({ ...item, disabled: false })),
      },
    ],
    [posTableOptions]
  )
  const onChangeHandler = useCallback(
    (value: string) => {
      const isSelected = _.includes(selectedPosTables, value)
      if (isSelected) {
        updateSelectedPosTables(selectedPosTables.filter(posTableId => posTableId !== value))
      } else {
        updateSelectedPosTables([...selectedPosTables, value])
      }
    },
    [selectedPosTables, updateSelectedPosTables]
  )

  return (
    <div>
      <SearchGroupDropdownMenu
        name="pos-tables-field"
        isLightTheme
        placeholder="Search for tables"
        groups={groups}
        values={selectedPosTables}
        onChangeHandler={onChangeHandler}
        height={40}
        style={{
          width: '250px',
          marginRight: 16,
        }}
        optionsContainerStyle={{
          top: '-66px',
        }}
        isValid={!posTableOptions?.length || !!selectedPosTables.length}
      />
      {!!panelItems.length && (
        <Box mr="s" mt="m">
          <DragDropPanel
            items={panelItems}
            onItemsUpdate={items => {
              updateSelectedPosTables(items.map(item => item.id))
            }}
            onRemove={removeSelectedPosTable}
            panelId="Pos Tables"
          />
        </Box>
      )}
    </div>
  )
}
