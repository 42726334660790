import { useMemo } from 'react'
import type { OverallocatedDurationsTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Spreadsheet, Box, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useInsightUtils } from '../../../hooks'
import { insightsMessages } from '../../../locales'

export function OverallocatedDurationsProofTable({ tableRows }: { tableRows: OverallocatedDurationsTableRow[] }) {
  const { formatMessage } = useLocales()
  const { getFormattedHoursAndMinutes } = useInsightUtils()
  const columnWidth = 75
  const columns = useMemo<DataTableColumn<OverallocatedDurationsTableRow>[]>(
    () => [
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.overallocatedDurationsTableHeaderPartySize)}
          </Text>
        ),
        render: 'partySize',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.overallocatedDurationsTableHeaderResCompleted)}
          </Text>
        ),
        render: 'numResCompleted',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.overallocatedDurationsTableHeaderCurrentDuration)}
          </Text>
        ),
        key: 'configuredDuration',
        render: tableRow => getFormattedHoursAndMinutes(tableRow.configuredDuration),
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.overallocatedDurationsTableHeaderAvgDuration)}
          </Text>
        ),
        render: tableRow => getFormattedHoursAndMinutes(tableRow.averageDuration),
        key: 'averageDuration',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.overallocatedDurationsTableHeaderSuggestedDuration)}
          </Text>
        ),
        render: tableRow => getFormattedHoursAndMinutes(tableRow.suggestedDurationMins),
        key: 'suggestedDurationMins',
        width: columnWidth,
        maxWidth: columnWidth,
      },
    ],
    [formatMessage, getFormattedHoursAndMinutes]
  )
  return (
    <Box data-test="overallocated-durations-proof-grid">
      <Spreadsheet data={tableRows} columns={columns} noPagination />
    </Box>
  )
}
