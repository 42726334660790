import Radium from 'radium'
import React, { PureComponent } from 'react'
import ManagerStyles from 'mgr/layout/Styles'
import StyleUtils from 'mgr/layout/StyleUtils'
import { dropDownTypes } from 'mgr/pages/shared/utils/Constants'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'

const GridBlockCellStyles = {
  body: {
    display: 'inline-block',
    position: 'absolute',
    zIndex: 29,
    height: GridStyles.ReservationRowHeight,
    lineHeight: `${GridStyles.ReservationRowHeight}px`,
    top: 0,
    left: 0,
    color: ManagerStyles.AxesFontColor,
    backgroundColor: 'white',
    fontSize: '9px',
    overflow: 'hidden',
    borderLeft: '1px solid white',
  },
  title: {
    backgroundColor: '#f3356b',
    color: 'white',
    height: 14,
    lineHeight: '14px',
    paddingLeft: 2,
    fontWeight: '600',
  },
  shaded: {
    background: 'repeating-linear-gradient(-45deg, #f3356b 1px, white 1px, #f3356b 5px)',
    height: '100%',
    opacity: '0.25',
  },
  titleContainer: {
    zIndex: 29,
    height: 14,
    lineHeight: '14px',
    ':hover': merge(
      {
        zIndex: 31,
      },
      StyleUtils.shadow()
    ),
  },
  clickable: {
    cursor: 'pointer',
  },
  shadedContainer: {
    zIndex: 28,
    pointerEvents: 'none',
    opacity: '0.8',
  },
}

class GridBlockCell extends PureComponent {
  constructor(props) {
    super(props)
    this.handleDropDownClick = this.handleDropDownClick.bind(this)
    this.addDropDownContainerRef = null
  }

  getDurationWidth(durationOrders) {
    return durationOrders * GridStyles.TimeHeaderWidth + durationOrders + Math.floor(durationOrders / 6)
  }

  handleDropDownClick() {
    const elRect = this.addDropDownContainerRef.getBoundingClientRect()
    this.props.onAddDropDownOpened(
      this.props.isBookable,
      this.props.table,
      this.props.order,
      this.props.isUnassigned,
      elRect,
      dropDownTypes.EDIT_BLOCK,
      this.props.block
    )
  }

  render() {
    GridBlockCellStyles.body.width = this.getDurationWidth(this.props.durationOrders || 0)
    return (
      <span
        style={GridBlockCellStyles.clickable}
        onClick={this.handleDropDownClick}
        ref={addDropDownContainer => {
          this.addDropDownContainerRef = addDropDownContainer
        }}
      >
        <div style={[GridBlockCellStyles.body, GridBlockCellStyles.titleContainer]}>
          <div style={GridBlockCellStyles.title}>{this.props.block.name}</div>
        </div>
        <div style={[GridBlockCellStyles.body, GridBlockCellStyles.shadedContainer]}>
          <div style={GridBlockCellStyles.shaded} />
        </div>
      </span>
    )
  }
}

export default GridBlockCell = Radium(GridBlockCell)
