import React from 'react'
import styled, { css } from 'styled-components'
import { validateCurrency } from 'svr/common/Validators'
import { VmsIcons } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import CurrencyInput from 'svr/component-lib/Generic/TextInputs/CurrencyInput'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 42px;
  padding-bottom: ${props => props.theme.gutter.standard};
  padding-right: 14px;
`

const InputWrapper = css`
  width: 80px;
  margin-right: 2px;
`

const InputText = css`
  height: 42px;
  width: 96px;
  border-radius: 4px;
  border-width: 1px;
  font-size: ${props => props.theme.fontSize.body};
`
export const EditSiteToggleableAmount = ({
  orderAmount,
  showAmount,
  label,
  onChangeAmount,
  onClickToggle,
  currencySymbol,
  dataTest = 'toggleable-amount',
}) => (
  <Container>
    <VmsCheckbox
      onClick={onClickToggle}
      isSelected={showAmount}
      label={label}
      selectedIcon={VmsIcons.CheckBoxSelected}
      unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
      tooltipIcon={VmsIcons.Info}
      dataTest={`${dataTest}-toggle-display-amount-checkbox`}
    />
    {showAmount && (
      <CurrencyInput
        value={orderAmount}
        placeholder="0.00"
        currencySymbol={currencySymbol}
        onChange={onChangeAmount}
        validator={validateCurrency}
        customInputWrapper={InputWrapper}
        customInput={InputText}
        dataTest={`${dataTest}-amount-input`}
      />
    )}
  </Container>
)

export default EditSiteToggleableAmount
