import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Button, NegativeButton } from 'mgr/pages/single-venue/settings/components/shared'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'

export const ReadmeFields = styled.div`
  padding-bottom: 20px;
`

export const KountaButton = styled(Button)`
  float: left;
  margin-right: 10px;
`
export const NegativeKountaButton = styled(NegativeButton)`
  float: left;
  margin-right: 10px;
`

export class KountaSection extends PureComponent {
  static propTypes = {
    venue: React.PropTypes.object.isRequired,
    isKountaConnected: React.PropTypes.bool,
    handleKountaTestSubmit: React.PropTypes.func,
  }

  renderButton() {
    const integrated = this.props.isKountaConnected
    const startAuthenticationLabel = 'Start Authentication'
    const againAuthenticateLabel = 'Authenticate Again'
    if (this.props.venue.posKey) {
      return (
        <KountaButton
          onClick={() => {
            window.location.href = `/kounta-oauth2-start/${this.props.venue.id}`
          }}
        >
          {integrated ? againAuthenticateLabel : startAuthenticationLabel}
        </KountaButton>
      )
    }
    return <NegativeKountaButton>{startAuthenticationLabel}</NegativeKountaButton>
  }

  renderTestConnectionButton() {
    const testConnection = 'Test Connection'
    if (this.props.venue.posKey) {
      return <KountaButton onClick={this.props.handleKountaTestSubmit}>{testConnection}</KountaButton>
    }
    return <NegativeKountaButton>{testConnection}</NegativeKountaButton>
  }

  render() {
    const integrated = this.props.isKountaConnected
    return (
      <div>
        <ReadmeFields>
          <TextInput label="Lightspeed K-Series Site ID:" value={this.props.venue.posKey} disabled />
        </ReadmeFields>
        {integrated && (
          <div>
            Status: <strong>Authenticated</strong>
          </div>
        )}
        {this.renderButton()}
        {integrated && this.renderTestConnectionButton()}
      </div>
    )
  }
}
