import { useState } from 'react'
import { Redirect } from 'react-router'
import { MiddlewareSource } from 'mgr/pages/single-venue/settings/constants'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import type { ProductInventoryItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

interface EditCellProps {
  product: ProductInventoryItem
  venue: Venue
  onDelete: (product: ProductInventoryItem) => void
}

export function EditCell({ product, venue, onDelete }: EditCellProps) {
  const { id } = product
  const type = product.type.toLowerCase()
  const isNoPosMiddlewareSource = venue.orderingMiddlewareSource === MiddlewareSource.NO_POS
  const [isEdit, setIsEdit] = useState(false)
  return isEdit ? (
    <Redirect push to={`/manager2/${venue.urlKey}/settings/ordering/inventory/product/${type}/${id}`} />
  ) : (
    <Menu data-test={`product-inventory-${type}-menu-${id}`}>
      <MenuItem data-test={`product-inventory-edit-${type}-link-${id}`} onClick={() => setIsEdit(true)}>
        <Icon name="VMSWeb-edit" />
        <Text>Edit</Text>
      </MenuItem>
      {isNoPosMiddlewareSource && (
        <MenuItem
          data-test={`product-inventory-delete-${type}-link-${id}`}
          onClick={() => {
            onDelete(product)
          }}
        >
          <Icon name="VMSWeb-trash" />
          Delete
        </MenuItem>
      )}
    </Menu>
  )
}
