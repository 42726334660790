import React from 'react'
import CheckboxButton from 'svr/component-lib/Generic/Checkbox/CheckboxButton'
import { FULFILLMENT_METHOD, ORDER_METHOD_TITLE } from 'svr/constants'

const getNextValue = (isChecked, methodsList, method) =>
  isChecked ? [...methodsList, method] : methodsList.filter(checkingMethod => checkingMethod !== method)

const FeeMethod = ({ methodsList, onChange, isValid }) => (
  <>
    <CheckboxButton
      data-test="service-fee-method-pickup-checkbox"
      value={methodsList.includes(FULFILLMENT_METHOD.PICKUP)}
      isValid={isValid}
      onChange={isChecked => {
        const nextValue = getNextValue(isChecked, methodsList, FULFILLMENT_METHOD.PICKUP)
        onChange(nextValue)
      }}
    >
      {ORDER_METHOD_TITLE[FULFILLMENT_METHOD.PICKUP]}
    </CheckboxButton>
    <CheckboxButton
      data-test="service-fee-method-delivery-checkbox"
      value={methodsList.includes(FULFILLMENT_METHOD.DELIVERY)}
      isValid={isValid}
      onChange={isChecked => {
        const nextValue = getNextValue(isChecked, methodsList, FULFILLMENT_METHOD.DELIVERY)
        onChange(nextValue)
      }}
    >
      {ORDER_METHOD_TITLE[FULFILLMENT_METHOD.DELIVERY]}
    </CheckboxButton>
    <CheckboxButton
      data-test="service-fee-method-delivery-checkbox"
      value={methodsList.includes(FULFILLMENT_METHOD.ON_PREMISE)}
      isValid={isValid}
      onChange={isChecked => {
        const nextValue = getNextValue(isChecked, methodsList, FULFILLMENT_METHOD.ON_PREMISE)
        onChange(nextValue)
      }}
    >
      {ORDER_METHOD_TITLE[FULFILLMENT_METHOD.ON_PREMISE]}
    </CheckboxButton>
    <CheckboxButton
      data-test="service-fee-method-delivery-checkbox"
      value={methodsList.includes(FULFILLMENT_METHOD.ROOM_SERVICE)}
      isValid={isValid}
      onChange={isChecked => {
        const nextValue = getNextValue(isChecked, methodsList, FULFILLMENT_METHOD.ROOM_SERVICE)
        onChange(nextValue)
      }}
    >
      {ORDER_METHOD_TITLE[FULFILLMENT_METHOD.ROOM_SERVICE]}
    </CheckboxButton>
  </>
)

export default FeeMethod
