import { useMemo, useState, useRef, useCallback } from 'react'
import { useAddCommentMutation } from '@sevenrooms/core/api'
import type { ActivityLogItem } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { Box, DividerLine, HStack, Avatar, notify } from '@sevenrooms/core/ui-kit/layout'
import { Editor, defaultConfig } from '@sevenrooms/core/ui-kit/optional'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useUserContext, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { insightsMessages } from '../../../locales'
import { ActivityItem } from './ActivityItem'
import type ReactFroalaEditor from 'react-froala-wysiwyg'

export function ActivityLog({ insightId, activityLog }: { insightId: string; activityLog: ActivityLogItem[] }) {
  const { formatMessage } = useLocales()
  const user = useUserContext()
  const { venueId } = useVenueContext()
  const { venueSettings } = useAppContext()
  const scrollRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<ReactFroalaEditor>(null)
  const [inputActive, setInputActive] = useState<boolean>(false)
  const [logs, setLogs] = useState<ActivityLogItem[]>([])
  const [addComment, { isLoading }] = useAddCommentMutation()

  useMemo(() => {
    setLogs(activityLog)
  }, [setLogs, activityLog])

  const logDisplay = logs.map(log => <ActivityItem key={`al_${log.id}`} log={log} />)

  const openEditor = () => {
    setInputActive(true)
  }

  const editorConfig = {
    ...defaultConfig,
    toolbarButtons: ['bold', 'underline', 'italic'],
    height: 120,
    events: {
      initialized: () => {
        scrollRef.current?.scrollIntoView()
        editorRef.current?.getEditor().events.focus()
      },
    },
  }

  const [comment, setComment] = useState<string | null | undefined>(null)
  const saveComment = useCallback(() => {
    if (!comment) {
      return
    }
    addComment({ venueId, insightId, comment })
      .then(result => {
        if ('data' in result) {
          const { data } = result
          setLogs([...logs, data])
          setInputActive(false)
        }
      })
      .catch(() => {
        notify({ content: formatMessage(insightsMessages.insightCommentErrorMessage), type: 'error' })
      })
  }, [addComment, insightId, venueId, comment, formatMessage, logs])

  return (
    <div data-test="insight-activity-log-section">
      <DividerLine mt="lm" mb="lm" />
      <Text type="p" textStyle="h2">
        {formatMessage(insightsMessages.activityLabel)}
      </Text>
      <Box>{logDisplay}</Box>
      <Box>
        <HStack>
          <Box mr="sm">
            <Text fontSize="s">
              <Avatar firstName={user?.first_name || ''} lastName={user?.last_name || ''} />
            </Text>
          </Box>
          <Box width="100%">
            {inputActive ? (
              <>
                <Editor
                  ref={editorRef}
                  onChange={value => setComment(value)}
                  config={editorConfig}
                  data-test="insight-activity-input"
                  isLoyaltyAndPerksEnabled={venueSettings?.isLoyaltyAndPerksEnabled}
                  referralProgramEnabled={venueSettings?.referralProgramEnabled}
                />
                <HStack ref={scrollRef} mt="m">
                  <Box mr="s" ml="auto">
                    <Button onClick={() => setInputActive(false)} size="s" variant="secondary" data-test="cancel-button">
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <LoaderButton size="s" loading={isLoading} onClick={() => saveComment()} data-test="save-button">
                      Save
                    </LoaderButton>
                  </Box>
                </HStack>
              </>
            ) : (
              <Input fullWidth onFocus={() => openEditor()} placeholder={formatMessage(insightsMessages.addComment)} />
            )}
          </Box>
        </HStack>
      </Box>
    </div>
  )
}
