import { srGet } from '@sevenrooms/core/api'

export const fetchOutgoingEmails = (params, errHandler) =>
  srGet(`/api-yoa/outgoingemails`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(response => {
      if (response.error && typeof errHandler === 'function') {
        errHandler(response)
      } else if (response.status !== 200) {
        throw new Error(response)
      }

      return response
    })
    .catch(e => {
      console.error('An unexpected error occurred fetching outgoing emails', e)
      return e
    })

export default {
  fetchOutgoingEmails,
}
