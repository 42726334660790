import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as DeliveryIntegrationActions from 'mgr/pages/single-venue/settings/actions/ordering/DeliveryIntegrationActions'
import { Desc, SubDesc, Contents, SubContainer } from 'mgr/pages/single-venue/settings/components/shared'
import DisableScreen from 'svr/component-lib/Manager/DisableScreen'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import DoorDashDeliveryIntegrationSettings from './DoorDashDeliveryIntegrationSettings'
import OrkestroDeliveryIntegrationSettings from './OrkestroDeliveryIntegrationSettings'
import YelloDeliveryIntegrationSettings from './YelloDeliveryIntegrationSettings'

const DELIVERY_INTEGRATION_SETTINGS = {
  DOORDASH: DoorDashDeliveryIntegrationSettings,
  ORKESTRO: OrkestroDeliveryIntegrationSettings,
  YELLO: YelloDeliveryIntegrationSettings,
}

const DeliveryIntegrations = ({ venue, venues, isLoading, actions, supportedIntegrations }) => {
  useEffect(() => {
    actions.tryLoadDeliveryIntegrationSettings()
  }, [])

  return (
    <ContentLayout title="Delivery Integrations" venue={venue} venues={venues} onChangeVenue={actions.onChangeVenue}>
      <Contents>
        <SubContainer>
          <Desc>Have your delivery orders handled by a Last Mile Delivery Provider</Desc>
          <SubDesc>Use this page to connect your Last Mile Delivery Provider with SevenRooms</SubDesc>
          {supportedIntegrations
            .map(integrationKey => [DELIVERY_INTEGRATION_SETTINGS[integrationKey], integrationKey])
            .map(([IntegrationSettings, integrationKey]) => (
              <IntegrationSettings key={integrationKey} />
            ))}
        </SubContainer>
        {isLoading && <DisableScreen />}
      </Contents>
    </ContentLayout>
  )
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    venue,
    venues,
    isLoading: state.deliveryIntegrations.isLoading,
    supportedIntegrations: state.deliveryIntegrations.supportedIntegrations,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      tryLoadDeliveryIntegrationSettings: DeliveryIntegrationActions.tryLoadDeliveryIntegrationSettings,
      onChangeVenue: GlobalActions.onChangeVenue,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryIntegrations)
