import { connect } from 'react-redux'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import { openOutgoingEmail } from 'mgr/pages/single-venue/reporting/actions/OutgoingEmails'

const mapStateToProps = state => ({
  isSlideoutOpen: !!state.outgoingEmails.data.selected,
  maxWidth: '1050px',
})

const mapDispatchToProps = dispatch => ({
  onCloseHandler: () => dispatch(openOutgoingEmail(null)),
})

const OutgoingEmailSlideout = connect(mapStateToProps, mapDispatchToProps)(SlideoutComponent)

export default OutgoingEmailSlideout
