import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { css } from 'styled-components'
import GenericTagsDropDown, { getTagHash } from 'mgr/lib/components/GenericTagsDropDown'
import type { ProductTags } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

const PRODUCT_TAGS_GROUPS_NAMES = {
  allergen: 'Allergen Tags',
  non_allergen: 'Non-Allergen Tags',
}

export interface ProductTaxFieldProps {
  venueId: string
  selectedTags?: string[]
  productTags: ProductTags
  onUpdateSelected: (value: string[]) => void
}

interface Tag {
  tagName: string
  tagGroup: string
  tagHash: string
}

const customStyles = css`
  margin-bottom: 36px;
`

export function ProductTagsField({ venueId, selectedTags, productTags, onUpdateSelected }: ProductTaxFieldProps) {
  const tagsGroups = useMemo(
    () =>
      Object.keys(productTags).map((productTagCategoryName: string) => ({
        id: productTagCategoryName,
        name: productTagCategoryName,
        name_display: PRODUCT_TAGS_GROUPS_NAMES[productTagCategoryName as keyof typeof PRODUCT_TAGS_GROUPS_NAMES],
        tags: productTags[productTagCategoryName as keyof ProductTags],
        color_hex: '#7FB7FF',
        tag_name_displays: [],
      })),
    [productTags]
  )

  const selected = useMemo(
    () =>
      selectedTags &&
      selectedTags.map(selectedTag => {
        const tagGroup = _.find(tagsGroups, tagGroup => _.find(tagGroup.tags, tag => tag.toUpperCase() === selectedTag.toUpperCase()))
        return { tagName: selectedTag, tagGroup, tagHash: getTagHash(tagGroup, selectedTag) }
      }),
    [selectedTags, tagsGroups]
  )

  const updateSelectedTags = useCallback(
    (selectedTags: Tag[]) => {
      onUpdateSelected(selectedTags.map((selected: { tagName: string }) => selected.tagName))
    },
    [onUpdateSelected]
  )

  return (
    <GenericTagsDropDown
      useOutsideLabel={false}
      tagGroups={tagsGroups}
      venueId={venueId}
      selectedTags={selected}
      onChangeHandler={updateSelectedTags}
      width="100%"
      customStyles={customStyles}
    />
  )
}
