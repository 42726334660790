import Radium from 'radium'
import React, { PureComponent } from 'react'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'
import StyleUtils from 'mgr/layout/StyleUtils'
import { ReservationStatuses, getIconForStatus, getIconColor } from 'mgr/lib/utils/Statuses'
import { tryPostSaveResStatusAction } from 'mgr/pages/single-venue/dayview/actions/Actions'
import { merge } from 'svr/common/SRUtils'
import { VmsIconSizes } from 'svr/common/VmsIcons'

class GridStatusDropDown extends PureComponent {
  constructor(props) {
    super(props)

    this.bookedClick = this.bookedClick.bind(this)
    this.leftMessageClick = this.leftMessageClick.bind(this)
    this.noAnswerClick = this.noAnswerClick.bind(this)
    this.wrongNumberClick = this.wrongNumberClick.bind(this)
    this.lateClick = this.lateClick.bind(this)
    this.holdClick = this.holdClick.bind(this)
    this.confirmedClick = this.confirmedClick.bind(this)
    this.cancelClick = this.cancelClick.bind(this)

    this.handleClickStatus = this.handleClickStatus.bind(this)
    this.getPreServiceStatusItems = this.getPreServiceStatusItems.bind(this)

    this.state = { isFlipped: false }
  }

  handleClickOutside(ev) {
    this.props.onClickOutside()
  }

  bookedClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.BOOKED)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  leftMessageClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.LEFT_MESSAGE)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  noAnswerClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.NO_ANSWER)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  wrongNumberClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.WRONG_NUMBER)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  lateClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.LATE)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  holdClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.HOLD)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  confirmedClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.CONFIRMED)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  cancelClick(e) {
    this.props.actions.changeStatus(this.props.actualId, ReservationStatuses.CANCELED)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  handleClickStatus(e, status) {
    this.props.actions.changeStatus(this.props.actualId, status)
    e.stopPropagation()
    this.props.onClickOutside()
  }

  getIconColor(status) {
    return getIconColor(this.props.statusesByDb, status)
  }

  shouldEnableStatus(status) {
    if (!(status in this.props.statusesByDb)) {
      return false
    }
    const statusDict = this.props.statusesByDb[status]
    return statusDict.enabled && !statusDict.deleted && (!statusDict.dead || status === ReservationStatuses.CANCELED)
  }

  getIconForStatus(status) {
    return getIconForStatus(status, false)
  }

  getPreServiceStatusItems(styles) {
    const statusItems = []
    for (const status of this.props.statusesPreservice) {
      if (this.shouldEnableStatus(status.db_name)) {
        statusItems.push(
          <div key={status.db_name} style={styles.dropDownItem} onClick={e => this.handleClickStatus(e, status.db_name)}>
            <span style={merge(VmsIconSizes.small, styles.icon, this.getIconColor(status.db_name))}>
              {this.getIconForStatus(status.db_name)}
            </span>
            {status.display}
          </div>
        )
      }
    }
    return statusItems
  }

  componentDidMount() {
    const bounding = this.menuContainer.getBoundingClientRect()
    this.setState({ isFlipped: bounding.bottom > (window.innerHeight || document.documentElement.clientHeight) })
  }

  render() {
    const styles = {
      dropDownContainer: {
        position: 'relative',
      },
      dropDownMenu: merge(
        {
          backgroundColor: 'white',
          position: 'absolute',
          width: 150,
          zIndex: '35',
          left: 1,
        },
        this.state.isFlipped ? { bottom: 2 } : { top: 2 },
        StyleUtils.shadow('rgba(0,0,0,0.68)')
      ),
      dropDownItem: {
        width: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '33px',
        height: 33,
        textAlign: 'left',
        paddingLeft: 8,
        fontSize: '14px',
        cursor: 'pointer',
        textTransform: 'capitalize',
        ':hover': {
          backgroundColor: '#eee',
        },
      },
      cancelItem: {
        width: 'auto',
        lineHeight: '33px',
        height: 33,
        textAlign: 'center',
        fontSize: '12px',
        cursor: 'pointer',
        color: '#D4194D',
        ':hover': {
          backgroundColor: '#eee',
        },
      },
      icon: merge(
        {
          fontSize: '16px',
          marginRight: 8,
        },
        StyleUtils.rounded('9px')
      ),
    }

    return (
      <div style={styles.dropDownContainer}>
        <div
          style={styles.dropDownMenu}
          ref={el => {
            this.menuContainer = el
          }}
        >
          {this.getPreServiceStatusItems(styles)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  statusesPreservice: state.appState.venueSettings.service_statuses['pre-service'],
  statusesByDb: state.appState.venueSettings.statusesByDb,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    changeStatus: (actualId, status) => dispatch(tryPostSaveResStatusAction(ownProps.venueId, actualId, status)),
  },
})

GridStatusDropDown = onClickOutside(Radium(GridStatusDropDown))
GridStatusDropDown = connect(mapStateToProps, mapDispatchToProps)(GridStatusDropDown)

export default GridStatusDropDown
