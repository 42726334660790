import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { timeHHmmssToFormat } from 'svr/common/DateTime'
import { Select, type SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

const transformOption = (time: string): SelectOption => ({ id: time, label: timeHHmmssToFormat(time) })

export interface TimeRangeProps {
  start?: string
  end?: string
  timeSlots: string[]
  isCopied: boolean
  onRemoveMenuAvailability: () => void
  onCopyMenuAvailabilityToAll: () => void
  onMenuAvailabilityUpdate: (start?: string, end?: string) => void
}

export function TimeRange({
  start: initialStart,
  end: initialEnd,
  timeSlots,
  isCopied,
  onRemoveMenuAvailability,
  onCopyMenuAvailabilityToAll,
  onMenuAvailabilityUpdate,
}: TimeRangeProps) {
  const availableOptions = useMemo(() => timeSlots.map(transformOption), [timeSlots])
  const [start, setStart] = useState(initialStart ?? null)
  const [end, setEnd] = useState(initialEnd ?? null)

  const availableEndOptions = useMemo(
    () => (start ? availableOptions.slice(availableOptions.findIndex(option => option.id === start)) : availableOptions),
    [availableOptions, start]
  )

  const onStartChange = useCallback(
    (value: string | null) => {
      if (value !== start) {
        const endTime = end && (!value || value < end) ? end : undefined
        onMenuAvailabilityUpdate(value || undefined, endTime)
      }
      setStart(value)
    },
    [onMenuAvailabilityUpdate, start, end]
  )

  const onEndChange = useCallback(
    (value: string | null) => {
      if (value !== end) {
        onMenuAvailabilityUpdate(start || undefined, value || undefined)
      }
      setEnd(value)
    },
    [onMenuAvailabilityUpdate, start, end]
  )

  return (
    <HStack alignItems="center">
      <Select
        placeholder="Choose time"
        data-test="menu-management-time-range-start-date"
        onChange={onStartChange}
        options={availableOptions}
        value={start ?? null}
        withEmpty
        hideEmptyOption
        searchable={false}
      />
      <Box pl="xs" pr="xs">
        <Text>To</Text>
      </Box>
      <Select
        placeholder="Choose time"
        data-test="menu-management-time-range-end-date"
        onChange={onEndChange}
        options={availableEndOptions}
        value={end ?? null}
        withEmpty
        hideEmptyOption
        searchable={false}
      />
      <Box pl="s" pr="s">
        <IconButton
          borderType="none-round"
          data-test="menu-management-time-range-remove"
          icon="VMSWeb-trash"
          onClick={onRemoveMenuAvailability}
        />
      </Box>
      <CopyAllButton
        isVisible={!isCopied}
        variant="tertiary"
        data-test="menu-management-time-range-copy-to-all"
        icon="VMSWeb-copy"
        disabled={!start || !end}
        noPadding
        onClick={onCopyMenuAvailabilityToAll}
      >
        Copy to all
      </CopyAllButton>
    </HStack>
  )
}

const CopyAllButton = styled(Button)<{ isVisible: boolean }>`
  visibility: ${props => (props.isVisible ? 'initial' : 'hidden')};
`
