import { useEffect } from 'react'
import { connect } from 'react-redux'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as OrderPacingActions from 'mgr/pages/single-venue/settings/actions/ordering/OrderPacingActions'
import { PacingRuleListTable } from 'mgr/pages/single-venue/settings/components/ordering/OrderPacing/PacingRuleListTable'
import { selectOrderingPacingRuleList } from 'mgr/pages/single-venue/settings/selectors/orderPacing'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box } from '@sevenrooms/core/ui-kit/layout'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { PacingRule, OrderingSiteOption } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'
import type { RouteComponentProps } from 'react-router'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export interface PacingRuleListProps extends RouteComponentProps {
  isLoading: boolean
  mediaUrl: string
  loadPacingRuleListByVenueId: (venueId: string) => void
  loadPacingRuleResources: (venueId: string) => Promise<void>
  onChangeVenue: (venue: UserDomainVenue) => void
  orderingSitesById: Record<string, OrderingSiteOption>
  pacingRuleList: PacingRule[]
  setPacingRuleListSortedColumns: (data: SortColumn[]) => void
  removePacingRule: (venueId: string, pacingRuleId: string) => Promise<void>
  sorted: SortColumn[]
  venue: Venue
  venues: UserDomainVenue[]
}

function PacingRuleList({
  history,
  isLoading,
  mediaUrl,
  loadPacingRuleListByVenueId,
  loadPacingRuleResources,
  onChangeVenue,
  orderingSitesById,
  pacingRuleList,
  setPacingRuleListSortedColumns,
  removePacingRule,
  sorted,
  venue,
  venues,
}: PacingRuleListProps) {
  useEffect(() => {
    if (!isLoading) {
      loadPacingRuleListByVenueId(venue.id)
      loadPacingRuleResources(venue.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPacingRuleListByVenueId, loadPacingRuleResources, venue.id])

  return (
    <ContentLayout
      title="Order Pacing"
      venue={venue}
      venues={venues}
      onChangeVenue={onChangeVenue}
      showSpinner={isLoading}
      spinnerImgUrl={mediaUrl}
      Actions={
        <Button
          variant="primary"
          icon="VMSWeb-add"
          onClick={() => {
            history.push(`/manager2/${venue.urlKey}/settings/ordering/pacing-rules/new`)
          }}
          id="create-new-pacing-rule"
          data-test="create-new-pacing-rule"
        >
          New Rule
        </Button>
      }
    >
      <div data-test="pacing-rules-container">
        <BaseSection
          title="Rules"
          description={`If there are overlapping rules, SevenRooms will look at the strictest limit for a time range to
            determine what the max number of orders is.`}
          maxWidth="100%"
        >
          <Box pl="lm" pr="lm">
            <PacingRuleListTable
              venue={venue}
              pacingRuleList={pacingRuleList}
              sorted={sorted}
              onSortedChange={setPacingRuleListSortedColumns}
              venueUrlKey={venue.urlKey}
              orderingSitesById={orderingSitesById}
              removePacingRule={removePacingRule}
            />
          </Box>
        </BaseSection>
      </div>
    </ContentLayout>
  )
}

const mapStateToProps = (state: State) => {
  const { mediaUrl, venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    venue,
    venues,
    mediaUrl,
    isLoading: state.orderingOrderPacing.isLoading,
    pacingRuleList: selectOrderingPacingRuleList(state),
    sorted: state.orderingOrderPacing.sorted,
    orderingSitesById: state.orderingOrderPacing.orderingSitesById,
  }
}

const mapDispatchToProps = {
  onChangeVenue: GlobalActions.onChangeVenue,
  loadPacingRuleListByVenueId: OrderPacingActions.loadPacingRuleListByVenueId,
  removePacingRule: OrderPacingActions.removePacingRule,
  loadPacingRuleResources: OrderPacingActions.loadPacingRuleResources,
  setPacingRuleListSortedColumns: OrderPacingActions.setPacingRuleListSortedColumns,
  setPacingRuleListFilterSearchValue: OrderPacingActions.setPacingRuleListFilterSearchValue,
}

export default connect(mapStateToProps, mapDispatchToProps)(PacingRuleList)
