import { Draggable, type DraggableProvided, type DraggableStateSnapshot } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface Item {
  id: string
  title: string
}

export interface SectionProps {
  index: number
  item: Item
  onRemove: (id: string) => void
  isDragDisabled?: boolean
}

export function Section({ item, index, onRemove, isDragDisabled }: SectionProps) {
  return (
    <Draggable draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <SectionWrapper isDragging={snapshot.isDragging} pl="sm" ref={provided.innerRef} {...provided.draggableProps}>
          <HStack alignItems="center">
            {!isDragDisabled && (
              <IconWrapper {...provided.dragHandleProps}>
                <Icon name={Icons['VMSWeb-drag']} />
              </IconWrapper>
            )}
            <Box ml="sm">
              <Text fontWeight="bold" fontSize="m">
                {item.title}
              </Text>
            </Box>
          </HStack>
          <IconButton
            borderType="none-round"
            iconSize="sm"
            data-test="menu-management-time-range-remove"
            icon="VMSWeb-trash"
            onClick={() => onRemove(item.id)}
          />
        </SectionWrapper>
      )}
    </Draggable>
  )
}

const SectionWrapper = styled(Box)<{ isDragging: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.mediumGrey};
  background-color: ${props => props.theme.color.lightGrey};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
`

const IconWrapper = styled(Box)`
  font-size: 14px;
`
