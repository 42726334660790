import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'
import type { MenuCategory } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface CategoryPositionDropdownProps {
  category: MenuCategory
  position: number
  categoriesCount: number
  onCategoryOrderChanged: (categoryName: string, position: number) => void
}

export function CategoryPositionDropdown({ category, position, categoriesCount, onCategoryOrderChanged }: CategoryPositionDropdownProps) {
  const options = useMemo(() => _.range(1, categoriesCount + 1), [categoriesCount])
  return (
    <SelectWrapper>
      <CategoryPositionSelect onChange={e => onCategoryOrderChanged(category.name, Number(e.target.value))} value={position + 1}>
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </CategoryPositionSelect>
    </SelectWrapper>
  )
}

const CategoryPositionSelect = styled.select`
  width: 60px;
  height: 32px;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px 5px 17px;
  appearance: none;
  border: 1px solid #cccccc;
  border-radius: 2px;
`

const SelectWrapper = styled.div`
  &:after {
    content: '\\2304';
    top: -3px;
    position: relative;
    left: -20px;
    font-size: ${props => props.theme.fontSize.medium};
    pointer-events: none;
  }
`
