import type { TablesWithoutSeatingAreaTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { insightsMessages } from '../../../locales'
import { formatParagraph, formatBold, type DetailProps } from '../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { TablesWithoutSeatingAreaSuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { TablesWithoutSeatingAreaProofTable } from '../../Proofs'

export function TablesWithoutSeatingAreaDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { insightProofData } = data
  const tableRows = (insightProofData?.[0]?.tableRows as TablesWithoutSeatingAreaTableRow[]) ?? []

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.floorplanOptimizationTitle)}
      description={formatMessage(insightsMessages.suggestedActionsNoSeatingAreaSlideoutDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
        strong: (chunks: string[]) => formatBold(chunks),
      })}
      proofLabel={formatMessage(insightsMessages.suggestedActionsNoSeatingAreaProofLabel)}
      suggestedActionsComponent={<TablesWithoutSeatingAreaSuggestedActions />}
      proofComponent={<TablesWithoutSeatingAreaProofTable tableRows={tableRows} />}
    />
  )
}
