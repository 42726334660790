import type { AvailabilityDebuggerReason } from '@sevenrooms/core/domain'

export interface AvailabilityDebuggerAccessRuleGrouping {
  accessRuleId: string
  reasonCode: string
  reasons: AvailabilityDebuggerReason[]
}

// The same reason may be returned multiple times per reason per access rule (usually because it is checked by table)
// For reasons with any of the access rule categories we want to group by access rule
export function groupReasonsByAccessRule(reasons: AvailabilityDebuggerReason[]): AvailabilityDebuggerAccessRuleGrouping[] {
  const groupedReasonsByAr: { [key: string]: { [key: string]: AvailabilityDebuggerReason[] } } = {}

  for (const reason of reasons) {
    const ar = reason.data?.accessRuleId
    if (!ar) {
      continue
    }

    const arReasons = groupedReasonsByAr[ar]
    if (arReasons) {
      arReasons[reason.reason] = [reason, ...(arReasons[reason.reason] ?? [])]
    } else {
      groupedReasonsByAr[ar] = { [reason.reason]: [reason] }
    }
  }

  return Object.entries(groupedReasonsByAr)
    .flatMap(([accessRuleId, reasonsGroup]) =>
      Object.entries(reasonsGroup)
        .filter(([, reasons]) => reasons.length > 0)
        .map(([reasonCode, reasons]) => ({ accessRuleId, reasonCode, reasons }))
    )
    .sort(sortFunction)
}

function sortFunction(a: AvailabilityDebuggerAccessRuleGrouping, b: AvailabilityDebuggerAccessRuleGrouping): number {
  const arNameA = a.reasons[0]?.data.accessRuleName
  const arNameB = b.reasons[0]?.data.accessRuleName

  if (arNameA && arNameB) {
    return arNameA.localeCompare(arNameB)
  }

  return 0
}
