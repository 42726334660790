import { FULFILLMENT_METHOD } from 'svr/constants'

const isSiteAvailable = (fulfillmentMethod, fulfillmentMethods) => {
  if (fulfillmentMethods.includes(fulfillmentMethod)) {
    return true
  }
  const isPickupIncluded = fulfillmentMethods.includes(FULFILLMENT_METHOD.PICKUP)
  const isDeliveryIncluded = fulfillmentMethods.includes(FULFILLMENT_METHOD.DELIVERY)
  const isRoomServiceIncluded = fulfillmentMethods.includes(FULFILLMENT_METHOD.ROOM_SERVICE)

  const isPickupOrDelivery = fulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_DELIVERY && (isPickupIncluded || isDeliveryIncluded)
  const isPickupOrRoomService =
    fulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE && (isPickupIncluded || isRoomServiceIncluded)

  return isPickupOrDelivery || isPickupOrRoomService
}

const getAvailableSites = (orderingSites, fulfillmentMethods) =>
  orderingSites.filter(site => isSiteAvailable(site.fulfillment_method, fulfillmentMethods))

export default getAvailableSites
