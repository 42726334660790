import React from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly, DateOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/react-components/components/Button'
import { DialogActions } from '@sevenrooms/react-components/components/DialogActions'
import { DialogContent } from '@sevenrooms/react-components/components/DialogContent'
import { DialogContentText } from '@sevenrooms/react-components/components/DialogContentText'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { shiftsCategoryMessages } from '../../../locales'

interface ShiftsContentCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  venue: Venue
  time: TimeOnly
  date: DateOnly
  covers: number
}

export function ShiftsCategory({ reasons, venue, time, date, covers }: ShiftsContentCategoryProps) {
  const { formatMessage } = useLocales()

  // This category is a full stop category, so we are guaranteed to only have one element in the array
  const shiftReason = reasons[0]

  let dialogContentText
  switch (shiftReason?.reason) {
    case 'NO_MATCHING_SHIFTS':
    case 'NO_SHIFTS_FOUND':
      dialogContentText = (
        <>
          <DialogContentText id="shifts-category-modal-description">
            {formatMessage(shiftsCategoryMessages.mainDescriptionNoShiftsFound, { time: time.formatSTime() })}
          </DialogContentText>
          <DialogActions sx={{ p: 0 }}>
            <Button
              data-test="no-shifts-found-button"
              size="small"
              variant="contained"
              onClick={() => window.open(`/manager/${venue.urlKey}/manage/capacity/schedule`, '_blank')}
            >
              {formatMessage(shiftsCategoryMessages.manageShiftsNoShiftsFound)}
            </Button>
          </DialogActions>
        </>
      )
      break
    case 'OVER_MAX_SHIFT_COVERS':
      dialogContentText = (
        <>
          <DialogContentText component="div" id="shifts-category-modal-description">
            <Typography variant="h4" sx={{ pb: 1 }}>
              {formatMessage(shiftsCategoryMessages.mainDescriptionHeaderOverMaxShift, {
                shiftName: shiftReason.data?.shiftName as string,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                a: (chunks: string[]) => (
                  <Link
                    data-test="over-max-shift-link"
                    href={`/manager/${venue.urlKey}/manage/capacity/schedule?selected_shift_persistent_id=${
                      shiftReason.data?.shiftPersistentId
                    }&date=${date.toJsDate().toLocaleString()}`}
                    target="_blank"
                  >
                    {chunks}
                  </Link>
                ),
              })}
            </Typography>
            <Typography>
              {formatMessage(shiftsCategoryMessages.mainDescriptionOverMaxShift, {
                count: covers,
                covers,
                time: time.formatSTime(),
                bookedTotalPossibleCovers: shiftReason.data?.bookedTotalPossibleCovers,
                coversMaxPerShift: shiftReason.data?.coversMaxPerShift,
              })}
            </Typography>
          </DialogContentText>
        </>
      )
      break
    default:
      dialogContentText = <></>
      break
  }
  return (
    dialogContentText && (
      <DialogContent
        sx={{
          backgroundColor: '#F7F7F7',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 3,
          mb: 3,
          mx: 3,
          borderRadius: '4px',
          '&.MuiDialogContent-root': {
            p: 2,
          },
        }}
        data-test="shift-category-content"
      >
        {dialogContentText}
      </DialogContent>
    )
  )
}
