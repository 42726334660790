import _ from 'lodash'

export function isPartOfTableCombo(tableCombos, tableId) {
  if (!tableId) {
    return false
  }
  for (let i = 0; i < tableCombos.length; i++) {
    const idx = _.findIndex(tableCombos[i].table_ids, comboId => comboId === tableId)
    if (idx !== -1) {
      return true
    }
  }
  return false
}
