import styled from 'styled-components'
import type { PropsWithChildren, HTMLAttributes } from 'react'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.gutter.double};
`
const Label = styled.span`
  font-size: ${props => props.theme.fontSize.body};
  font-weight: bold;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px ${props => props.theme.gutter.triple} ${props => props.theme.gutter.standard} ${props => props.theme.gutter.triple};
`

const SubCaption = styled.div`
  color: #6a6a6a;
  font-size: 12px;
  margin-top: 4px;
`

interface RowBlockContainerProps extends HTMLAttributes<HTMLDivElement> {
  label?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions?: any
  subCaption?: string
}

function RowBlockContainer({ label, actions, subCaption, children, ...rest }: PropsWithChildren<RowBlockContainerProps>) {
  return (
    <Container {...rest}>
      {!!label && (
        <Header>
          <div>
            <Label>{label}</Label>
            {subCaption && <SubCaption>{subCaption}</SubCaption>}
          </div>
          {actions}
        </Header>
      )}
      {children}
    </Container>
  )
}

export default RowBlockContainer
