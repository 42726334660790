import { type HTMLAttributes, type PropsWithChildren, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { onChangeVenue } from 'mgr/lib/actions/DirtyStateUnloader'
import DirtyStateUnloader from 'mgr/lib/components/DirtyStateUnloader'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import * as CheckoutActions from 'mgr/pages/single-venue/settings/actions/ordering/CheckoutActions'
import DeliveryRadius from 'mgr/pages/single-venue/settings/components/ordering/Checkout/DeliveryRadius'
import DeliveryRadiusUnit from 'mgr/pages/single-venue/settings/components/ordering/Checkout/DeliveryRadiusUnit'
import LastUpdated from 'mgr/pages/single-venue/settings/components/ordering/Checkout/LastUpdated'
import { PAY_LATER_MIDDLEWARES } from 'mgr/pages/single-venue/settings/constants'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import Input from 'svr/component-lib/Generic/TextInputs/Input'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { SaveButton } from 'svr/component-lib/Manager/Layout/LanguageContent'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import Section from 'svr/component-lib/Manager/Section'
import { Toggle } from '@sevenrooms/core/ui-kit/core'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import { useErrorFields, type ErrorFields } from './useErrorFields'
import { validateFields } from './validateFields'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { CheckoutSettings, PosFields } from 'mgr/pages/single-venue/settings/types/ordering/Checkout.types'

const TIPS_FIELDS_NUMBERS = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
}

const TIP_FIELD_NAMES = {
  TIP0: 'tip0',
  TIP1: 'tip1',
  TIP2: 'tip2',
  TIP_DEFAULT: 'default_tip',
}

const TipRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

interface LinkWrapperProps extends HTMLAttributes<HTMLElement> {
  isDefault: boolean
}

const LinkWrapper = styled.div<LinkWrapperProps>`
  font-size: 13px;
  color: ${props => (props.isDefault ? '#9A9B9C' : '#347baf')};
  cursor: ${props => (props.isDefault ? 'default' : 'pointer')};
  margin-top: 16px;
  margin-left: 16px;
`

interface TipButtonLinkProps {
  defaultTip: number
  currentTip: number
  onClick?: () => void
}
function TipButtonLink({ defaultTip, currentTip, onClick }: TipButtonLinkProps) {
  const isDefault = defaultTip === currentTip
  const title = isDefault ? 'Default' : 'Make Default'
  return (
    <LinkWrapper
      isDefault={isDefault}
      onClick={isDefault ? undefined : onClick}
      data-test="checkout-button-link-tip"
      data-test-id={currentTip}
    >
      {title}
    </LinkWrapper>
  )
}

function StyledDropdownArrowsPicker({ ...args }) {
  return (
    <DropdownArrowsPicker
      style={{
        width: '350px',
        minWidth: '350px',
        color: '#666666',
      }}
      borderAreaStyle={{
        border: '1px solid #ECECEC',
        height: '40px',
        minHeight: '40px',
      }}
      height={40}
      isLightTheme
      {...args}
    />
  )
}

function InputWrapper({ ...props }) {
  return <Input {...props} width={260} type="number" min={0} max={100} suffix="%" />
}
function AgeToConsentInputWrapper({ ...props }) {
  return <Input {...props} width={60} height={30} type="number" min={0} max={100} />
}

const LineContainer = styled.div`
  display: flex;
`

const DropdownField = styled.div`
  padding-bottom: 12px;
`

const Container = styled.div`
  padding: 24px;
  max-width: 715px;
  position: relative;
  height: 100%;
`

const Description = styled.div`
  color: #1e252f;
  margin-bottom: 4px;
  line-height: 17.58px;
`

const SubDescription = styled.div`
  color: #9a9b9c;
  line-height: 17.58px;
`

const DescriptionContainer = styled.div`
  padding-bottom: 24px;
`

const ToggleLabel = styled.div`
  color: #999999;
  font-weight: bold;
  float: left;
  line-height: 29px;
  margin-right: ${props => props.theme.gutter.double};
`

const ToggleLabelIndented = styled(ToggleLabel)`
  margin-left: ${props => props.theme.gutter.quadruple};
`

interface ErrorDialogProps {
  errorFields: ErrorFields
  onClose: () => void
}

function ErrorDialog({ errorFields, onClose }: ErrorDialogProps) {
  const details = (
    <>
      {Object.values(errorFields).map(explanation => (
        <div key={explanation}>{explanation}</div>
      ))}
    </>
  )
  return (
    <MessageBox
      isShowCloseButton={false}
      handleCloseClick={() => {}}
      handleActionClick={onClose}
      dialogType={MessageBox.DialogType.WARNING}
      header="Error"
      explain="Error"
      details={[]}
      explanation={details}
      actionButtonText="Cancel"
    />
  )
}

export interface OrderingCheckoutProps {
  venue: Venue
  venues: UserDomainVenue[]
  isLoading: boolean
  isDirty: boolean
  checkoutSettings: CheckoutSettings
  posFields: PosFields
  enforceDoordashRules: boolean
  onChangeVenue: (venue: UserDomainVenue) => void
  loadCheckoutSettings: () => Promise<void>
  changeCheckoutField: (field: string, value: string | number | boolean) => void
  changePosField: (id: string, value: string) => void
  saveOrderingCheckoutSettings: (checkoutSettings: CheckoutSettings, posFields: PosFields) => Promise<void>
  isTaxInclusiveVenue: boolean
  isSevenRoomsOrderingEnabled: boolean
  isSevenRoomsOrderingMiddleware: boolean
  orderingMiddlewareSource: string
}

function OrderingCheckout({
  venue,
  venues,
  isLoading,
  checkoutSettings,
  enforceDoordashRules,
  posFields,
  onChangeVenue,
  loadCheckoutSettings,
  changeCheckoutField,
  changePosField,
  saveOrderingCheckoutSettings,
  isTaxInclusiveVenue,
  isSevenRoomsOrderingEnabled,
  orderingMiddlewareSource,
  isDirty,
}: OrderingCheckoutProps) {
  const [isSaving, updateSaving] = useState(false)
  const { isHasErrors, errorFields, updateErrorFields } = useErrorFields()

  const loadData = async () => {
    await loadCheckoutSettings()
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSaveButtonClick = async () => {
    if (isSaving) {
      return
    }
    const checkedFields = validateFields(checkoutSettings, enforceDoordashRules)
    updateErrorFields(checkedFields)
    if (isHasErrors(checkedFields)) {
      return
    }
    try {
      updateSaving(true)
      await saveOrderingCheckoutSettings(checkoutSettings, posFields)
    } finally {
      updateSaving(false)
    }
  }

  const isLoadingData = isLoading || isSaving
  const isPayLaterSupportedMiddleware = PAY_LATER_MIDDLEWARES.has(orderingMiddlewareSource)

  interface PosFieldOption {
    id: string
    name: string
  }

  interface PosField {
    name: string
    id: string
    type: string
    value: string
    options?: PosFieldOption[]
  }

  const buildPosField = (posField: PosField) => {
    if (posField.type === 'SELECT') {
      return (
        <DropdownField key={posField.id}>
          <StyledDropdownArrowsPicker
            choices={posField.options?.map(option => ({
              value: option.id,
              name: option.name,
            }))}
            value={posField.value}
            onChangeHandler={(value: string) => changePosField(posField.id, value)}
            name={posField.name}
            isLightTheme
            testId={`pos-select-${posField.name}`}
          />
        </DropdownField>
      )
    } else if (posField.type === 'INPUT') {
      return (
        <ActiveLabelTextInput
          placeholderLabel={posField.name}
          value={posField.value}
          isValid
          onChange={(value: string) => changePosField(posField.id, value)}
          dataTest={`pos-input-${posField.name}`}
          style={{ width: '68%' }}
        />
      )
    }
    return null
  }

  return (
    <DirtyStateUnloader isDirty={isDirty} modalQuestionContext="this checkout settings">
      <ContentLayout
        title="Checkout Settings"
        venue={venue}
        venues={venues}
        onChangeVenue={onChangeVenue}
        overrideChangeVenue
        Actions={
          <SaveButton disabled={isLoadingData} onClick={onSaveButtonClick} id="save-checkout-button" data-test="sr-save-button">
            Save and Publish
          </SaveButton>
        }
      >
        <Container>
          {isHasErrors(errorFields) && <ErrorDialog errorFields={errorFields} onClose={() => updateErrorFields({})} />}
          <Section headerText="Delivery radius">
            <DescriptionContainer>
              <Description>
                Delivery orders will be restricted to addresses inside this radius. If using a third party delivery service, a separate
                delivery radius might already be enforced by that service, but this setting can still be used to further limit the delivery
                zone.
              </Description>
            </DescriptionContainer>
            <DropdownField>
              <LineContainer>
                <DeliveryRadius
                  value={checkoutSettings.delivery_radius_value}
                  onChange={value => changeCheckoutField('delivery_radius_value', value)}
                />
                <DeliveryRadiusUnit
                  value={checkoutSettings.delivery_radius_unit || 'mi'}
                  onChange={value => changeCheckoutField('delivery_radius_unit', value)}
                />
              </LineContainer>
            </DropdownField>
          </Section>
          <Section headerText="Tip">
            <DescriptionContainer>
              <Description>
                We suggest that you list your tip percentages in ascending order. If you do not want a Tip section on your Checkout page,
                set all three percentages to 0%.
              </Description>
              {enforceDoordashRules && (
                <SubDescription>For the DoorDash Drive integration, your default tip percentage has to be at least 15%</SubDescription>
              )}
            </DescriptionContainer>
            <TipRow>
              <InputWrapper
                label="1ST TIP PERCENTAGE*"
                value={checkoutSettings.tip0}
                onChange={(value: number) => changeCheckoutField(TIP_FIELD_NAMES.TIP0, value)}
                data-test="sr-input-checkout-1st-tip"
              />
              <TipButtonLink
                currentTip={TIPS_FIELDS_NUMBERS.FIRST}
                defaultTip={checkoutSettings.default_tip}
                onClick={() => changeCheckoutField(TIP_FIELD_NAMES.TIP_DEFAULT, TIPS_FIELDS_NUMBERS.FIRST)}
              />
            </TipRow>
            <TipRow>
              <InputWrapper
                label="2ND TIP PERCENTAGE*"
                value={checkoutSettings.tip1}
                onChange={(value: number) => changeCheckoutField(TIP_FIELD_NAMES.TIP1, value)}
                data-test="sr-input-checkout-2nd-tip"
              />
              <TipButtonLink
                currentTip={TIPS_FIELDS_NUMBERS.SECOND}
                defaultTip={checkoutSettings.default_tip}
                onClick={() => changeCheckoutField(TIP_FIELD_NAMES.TIP_DEFAULT, TIPS_FIELDS_NUMBERS.SECOND)}
              />
            </TipRow>
            <TipRow>
              <InputWrapper
                label="3RD TIP PERCENTAGE*"
                value={checkoutSettings.tip2}
                onChange={(value: number) => changeCheckoutField(TIP_FIELD_NAMES.TIP2, value)}
                data-test="sr-input-checkout-3rd-tip"
              />
              <TipButtonLink
                currentTip={TIPS_FIELDS_NUMBERS.THIRD}
                defaultTip={checkoutSettings.default_tip}
                onClick={() => changeCheckoutField(TIP_FIELD_NAMES.TIP_DEFAULT, TIPS_FIELDS_NUMBERS.THIRD)}
              />
            </TipRow>
          </Section>
          <Section headerText="Other Configurations">
            <VStack spacing="m">
              <ToggleRow>
                <ToggleLabel>Allow Guests to Provide Special Instructions on Each Menu Item Ordered</ToggleLabel>
                <Toggle
                  data-test="checkout-special-instruction-on-menu-item-toggle"
                  checked={checkoutSettings.allow_special_instructions}
                  onChange={(value: boolean) => changeCheckoutField('allow_special_instructions', value)}
                />
              </ToggleRow>
              <ToggleRow>
                <ToggleLabel>Allow Guests to Provide Note to Restaurant during Checkout</ToggleLabel>
                <Toggle
                  data-test="checkout-special-instruction-to-restaurant-toggle"
                  checked={checkoutSettings.show_note_to_restaurant}
                  onChange={(value: boolean) => changeCheckoutField('show_note_to_restaurant', value)}
                />
              </ToggleRow>
              <ToggleRow>
                <ToggleLabel>Allow Guests to Enter Promo Code During Checkout</ToggleLabel>
                <Toggle
                  data-test="checkout-promo-code-toggle"
                  checked={checkoutSettings.show_promo_code}
                  onChange={(value: boolean) => changeCheckoutField('show_promo_code', value)}
                />
              </ToggleRow>
              <ToggleRow>
                <ToggleLabel>Hide 86’d Menu Items on Ordering Sites (Instead of Showing Them as “Sold Out”)</ToggleLabel>
                <Toggle
                  data-test="checkout-hide-eighty-six-menu-items-toggle"
                  checked={checkoutSettings.hide_eighty_six_menu_items}
                  onChange={(value: boolean) => changeCheckoutField('hide_eighty_six_menu_items', value)}
                />
              </ToggleRow>
              <ToggleRow>
                <ToggleLabel>Age shown on checkout for menu items that require age verification</ToggleLabel>
                <AgeToConsentInputWrapper
                  value={checkoutSettings.age_to_consent}
                  onChange={(value: boolean) => changeCheckoutField('age_to_consent', value)}
                  data-test="sr-input-age-to-consent"
                />
              </ToggleRow>
              {isTaxInclusiveVenue && isSevenRoomsOrderingEnabled && (
                <ToggleRow>
                  <ToggleLabel>Show Amount of Included Taxes Paid on Guest Receipt</ToggleLabel>
                  <Toggle
                    data-test="checkout-display-amount-of-included-taxes-paid-toggle"
                    checked={checkoutSettings.display_amount_of_included_taxes_paid}
                    onChange={(value: boolean) => changeCheckoutField('display_amount_of_included_taxes_paid', value)}
                  />
                </ToggleRow>
              )}
              {isSevenRoomsOrderingEnabled && isPayLaterSupportedMiddleware && (
                <>
                  <ToggleRow>
                    <ToggleLabel>Allow Guest the option to Pay Later during Checkout</ToggleLabel>
                    <Toggle
                      data-test="checkout-allow-pay-later-toggle"
                      checked={checkoutSettings.allow_pay_later}
                      onChange={(value: boolean) => {
                        changeCheckoutField('allow_pay_later', value)
                        if (!value && checkoutSettings.allow_pay_later_only) {
                          changeCheckoutField('allow_pay_later_only', value)
                        }
                      }}
                    />
                  </ToggleRow>
                  {checkoutSettings.allow_pay_later && (
                    <ToggleRow>
                      <ToggleLabelIndented>Only allow Pay Later on checkout (not recommended)</ToggleLabelIndented>
                      <Toggle
                        data-test="checkout-only-allow-pay-later-toggle"
                        checked={checkoutSettings.allow_pay_later_only}
                        disabled={!checkoutSettings.allow_pay_later}
                        onChange={(value: boolean) => changeCheckoutField('allow_pay_later_only', value)}
                      />
                    </ToggleRow>
                  )}
                </>
              )}
            </VStack>
          </Section>
          {posFields.length > 0 && <Section headerText="POS Integration">{posFields.map(posField => buildPosField(posField))}</Section>}

          <LastUpdated created={checkoutSettings.created} updated={checkoutSettings.updated} />
        </Container>
      </ContentLayout>
    </DirtyStateUnloader>
  )
}

function ToggleRow({ children }: PropsWithChildren<unknown>) {
  return (
    <HStack justifyContent="space-between" width="550px">
      {children}
    </HStack>
  )
}

const mapStateToProps = (state: State) => {
  const { venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  const { isLoading, checkoutSettings, enforceDoordashRules, posFields, isDirty } = state.orderingCheckout
  const { is_tax_inclusive: isTaxInclusiveVenue } = state.appState.venueSettings
  const { isSevenRoomsOrderingEnabled, orderingMiddlewareSource } = state.appState.venue

  return {
    venue,
    venues,
    isLoading,
    checkoutSettings,
    enforceDoordashRules,
    posFields,
    isTaxInclusiveVenue,
    isSevenRoomsOrderingEnabled,
    orderingMiddlewareSource,
    isDirty,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
  loadCheckoutSettings: CheckoutActions.loadCheckoutSettings,
  changeCheckoutField: CheckoutActions.changeCheckoutField,
  changePosField: CheckoutActions.changePosField,
  saveOrderingCheckoutSettings: CheckoutActions.saveCheckoutSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderingCheckout)
