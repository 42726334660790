import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { selectNavigationPermissions } from 'mgr/lib/utils/PermissionsUtils'
import { ErrorNotFound } from 'mgr/pages/default'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import { DayViewStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
// eslint-disable-next-line import/no-cycle
import FloorplanLayout from 'mgr/pages/single-venue/dayview/layouts/FloorplanLayout'
// eslint-disable-next-line import/no-cycle
import GridLayout from 'mgr/pages/single-venue/dayview/layouts/GridLayout'

const DayViewOuterContainer = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  padding-top: ${ManagerStyles.DayviewToolbarHeight - 2}px;
  flex: 1;
`

export const DayViewInnerContainer = styled.div`
  background-color: ${props => props.theme.color.greyMuted};
  width: 100%;
  ${props => props.extraMargin && `margin-top: ${DayViewStyles.NotesHighlightBarHeight}px;`};
`

class DayView extends PureComponent {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.actions.tryLoadDay(this.props.venue.urlKey, this.props.date)
    this.props.actions.getTodaysShifts(this.props.venue)
  }

  componentWillMount() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    const callbackHandlers = {
      onBook(actual, response, assignments_and_actual_problems_promise) {
        if (response.data.needs_approval) {
          that.props.actions.showSuccessMessage('Reservation request created. Awaiting approval.')
          ReservationSlideOut.close()
          return
        }
        if (response.data.request_added) {
          that.props.actions.showSuccessMessage('Reservation request added.')
          ReservationSlideOut.close()
          return
        }
        if (moment(actual.date, 'MM/DD/YY').isSame(that.props.date)) {
          that.props.actions.refreshActuals(that.props.venue.id, that.props.date, actual.shift_persistent_id, [actual.id])
        }
        if (!window.globalInit.venueSettings.use_supafly) {
          if (response.data.charge_status === 'FAILED') {
            that.props.actions.showWarnMessage('Reservation booked, but charge failed!')
          }
          that.props.actions.showSuccessMessage(`Booked a reservation at ${actual.arrival_time_display}`)
        }
      },
      onEditSave(original_actual_id, actual, response, assignments_and_actual_problems_promise) {
        if (moment(actual.date, 'MM/DD/YY').isSame(that.props.date)) {
          that.props.actions.refreshActuals(that.props.venue.id, that.props.date, actual.shift_persistent_id, [actual.id])
        }
        if (!window.globalInit.venueSettings.use_supafly) {
          that.props.actions.showSuccessMessage('Edited a reservation')
        }
      },
      onCancel(id, actual) {
        if (moment(actual.date, 'MM/DD/YY').isSame(that.props.date)) {
          that.props.actions.refreshActuals(that.props.venue.id, that.props.date, actual.shift_persistent_id, [actual.id])
        }
        if (!window.globalInit.venueSettings.use_supafly) {
          that.props.actions.showSuccessMessage('Canceled a reservation')
        }
      },
    }
    if (window.globalInit.venueSettings.use_supafly) {
      SvrManager.ActualSlideout.registerCallbackHandlers(callbackHandlers)
      ReservationSlideOut.onBookNew(callbackHandlers.onBook) // For requests
    } else {
      ReservationSlideOut.onBookNew(callbackHandlers.onBook)
      ReservationSlideOut.onEdit(callbackHandlers.onEditSave)
      ReservationSlideOut.onCancel(callbackHandlers.onCancel)
      // uncancel needs subscription but is a no-op on the dayView
      ReservationSlideOut.onUncancel()
    }
  }

  render() {
    const { match, permissions } = this.props

    return (
      <DayViewOuterContainer>
        <Switch>
          {permissions.canViewGrid && <Route path={`${match.path}/grid/:shift?`} component={GridLayout} />}
          {permissions.canAccessOperationsTab && <Route path={`${match.path}/floorplan/:shift?`} component={FloorplanLayout} />}
          <Route path="*" component={ErrorNotFound} />
        </Switch>
      </DayViewOuterContainer>
    )
  }
}

const mapStateToProps = state => ({
  venue: state.appState.venue,
  date: state.dayviewState.date,
  permissions: selectNavigationPermissions(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      refreshActuals: Actions.refreshActualsAction,
      showInfoMessage: GlobalActions.showInfoMessage,
      showSuccessMessage: GlobalActions.showSuccessMessage,
      showWarnMessage: GlobalActions.showWarnMessage,
      showErrorMessage: GlobalActions.showErrorMessage,
      tryLoadDay: (venueId, date) => Actions.tryLoadDayAction(venueId, date),
      getTodaysShifts: venue => Actions.getTodaysShifts(venue),
    },
    dispatch
  ),
})

DayView = connect(mapStateToProps, mapDispatchToProps)(DayView)

export default DayView
