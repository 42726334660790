import { srGet, srPost, srPut } from '@sevenrooms/core/api'

export const fetchClientTagCategories = (params, errHandler) =>
  srGet(`/api-yoa/tags/client`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(response => {
      if (response.error && typeof errHandler === 'function') {
        errHandler(response)
      } else if (response.status !== 200) {
        throw new Error(response.errorMsg)
      }
      return response
    })
    .catch(e => {
      console.error(`An unexpected error occurred fetching client tag categories: ${e.toString()}`)
      throw new Error(e.toString())
    })

export const fetchReservationTagCategories = (params, errHandler) =>
  srGet(`/api-yoa/tags/reservation`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(response => {
      if (response.error && typeof errHandler === 'function') {
        errHandler(response)
      } else if (response.status !== 200) {
        throw new Error(response.errorMsg)
      }
      return response
    })
    .catch(e => {
      console.error(`An unexpected error occurred fetching reservation tag categories: ${e.toString()}`)
      throw new Error(e.toString())
    })

export const addTagCategory = (params, errHandler) =>
  srPost(
    `/api-yoa/tagcategory?venue_group_id=${params.venue_group_id}`,
    undefined,
    {
      json: true,
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    },
    {},
    params
  )
    .then(response => {
      if (response.error && typeof errHandler === 'function') {
        errHandler(response)
      } else if (response.status !== 200) {
        throw new Error(response.errorMsg)
      } else if (response.data.error_message) {
        throw new Error(response.data.error_message)
      }
      return response
    })
    .catch(e => {
      console.error(`An unexpected error occurred adding tag category: ${e.toString()}`)
      throw new Error(e.toString())
    })

export const editTagCategory = (params, errHandler) =>
  srPut(`/api-yoa/tagcategory?venue_group_id=${params.venue_group_id}`, params, {
    json: true,
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(response => {
      if (response.error && typeof errHandler === 'function') {
        errHandler(response)
      } else if (response.status !== 200) {
        throw new Error(response.errorMsg)
      } else if (response.data && response.data.error_message) {
        throw new Error(response.data.error_message)
      }
      return response
    })
    .catch(e => {
      console.error(`An unexpected error occurred editing tag category: ${e.toString()}`)
      throw new Error(e.toString())
    })
