import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import {
  addNewOrderingCheckoutFee,
  loadOrderingCheckoutFeeList,
  removeOrderingCharge,
  updateOrderingCheckoutFee,
} from 'mgr/pages/single-venue/settings/actions/ordering/CheckoutFeeActions'
import { tryLoadDeliveryIntegrationSettings } from 'mgr/pages/single-venue/settings/actions/ordering/DeliveryIntegrationActions'
import { useLoading } from 'svr/common/hooks/useLoading'
import { useTaxGroups } from 'svr/common/hooks/useTaxGroups'
import { useVenue } from 'svr/common/hooks/useVenue'
import { useVenues } from '@sevenrooms/mgr-core/hooks/useVenues'

export const useCheckoutFee = () => {
  const dispatch = useDispatch()
  const checkoutFees = useSelector(state => state.orderingCheckoutFee.checkoutFees)
  const orderingSites = useSelector(state => state.orderingCheckoutFee.orderingSites)
  const deliveryPartnerKey = useSelector(state => {
    const enabledIntegrationSetting = Object.entries(state.deliveryIntegrations.integrationSettings).find(([_key, { enabled }]) => enabled)
    return enabledIntegrationSetting && enabledIntegrationSetting[0]
  })
  const mediaUrl = useSelector(state => state.appState.mediaUrl)

  const { venueId, venue } = useVenue()
  const venues = useVenues()
  const { taxGroups } = useTaxGroups()

  const [isLoadingData, loadData] = useLoading(async () => {
    await dispatch(loadOrderingCheckoutFeeList({ venueId }))
    await dispatch(tryLoadDeliveryIntegrationSettings())
  }, [venueId])

  const [isCreatingFee, addNewFee] = useLoading(
    async serviceCharge => {
      if (isCreatingFee) {
        return
      }
      await dispatch(addNewOrderingCheckoutFee({ venueId, serviceCharge }))
      await dispatch(loadOrderingCheckoutFeeList({ venueId }))
    },
    [venueId]
  )

  const [isUpdatingFee, updateFeeById] = useLoading(
    async (serviceChargeId, serviceCharge) => {
      if (isUpdatingFee) {
        return
      }
      await dispatch(updateOrderingCheckoutFee({ venueId, serviceCharge, serviceChargeId }))
      await dispatch(loadOrderingCheckoutFeeList({ venueId }))
    },
    [venueId]
  )

  const [isRemovingFee, removeFeeById] = useLoading(
    async serviceChargeId => {
      if (isRemovingFee) {
        return
      }
      await dispatch(removeOrderingCharge({ venueId, serviceChargeId }))
      await dispatch(loadOrderingCheckoutFeeList({ venueId }))
    },
    [venueId]
  )

  const [isUpdateEnable, updateEnableById] = useLoading(
    async (serviceChargeId, checkoutFees) => {
      if (isUpdateEnable) {
        return
      }
      const serviceCharge = checkoutFees.find(fee => fee.id === serviceChargeId)
      if (!serviceCharge) {
        return
      }
      const changedEnabled = {
        ...serviceCharge,
        isEnabled: !serviceCharge.isEnabled,
      }
      await dispatch(
        updateOrderingCheckoutFee({
          venueId,
          serviceChargeId,
          serviceCharge: changedEnabled,
        })
      )
      await dispatch(loadOrderingCheckoutFeeList({ venueId }))
    },
    [venueId]
  )

  const isLoading = isRemovingFee || isUpdatingFee || isCreatingFee || isLoadingData || isUpdateEnable

  useEffect(() => {
    loadData()
  }, [venueId, loadData])

  return {
    mediaUrl,
    onChangeVenue: useCallback(() => dispatch(onChangeVenue), [venueId, dispatch]),
    addNewFee,
    updateFeeById,
    updateEnableById,
    removeFeeById,
    taxGroups,
    venue,
    deliveryPartnerKey,
    venues,
    checkoutFees,
    orderingSites,
    isLoading,
  }
}
