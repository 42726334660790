import MessageBox from 'svr/component-lib/Manager/MessageBox'

interface ChangePageModalProps {
  settingsTitle: string
  onDiscard?: () => void
  onCancel?: () => void
}

function ChangePageModal({ settingsTitle, onCancel, onDiscard }: ChangePageModalProps) {
  return (
    <MessageBox
      handleCloseClick={onCancel}
      handleActionClick={onDiscard}
      dialogType={MessageBox.DialogType.WARNING}
      header="Warning"
      explain="Warning"
      details={[]}
      explanation={`Are you sure you want to leave without saving ${settingsTitle}?`}
      actionButtonText="Yes"
      customModalScreen="position: relative"
    />
  )
}

export default ChangePageModal
