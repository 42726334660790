import { connect } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import type { Venue, UserDomain, UserDomainVenue } from '@sevenrooms/mgr-core'
import { PagesLanguageSettings } from '@sevenrooms/mgr-settings/Settings/LanguageSettings'
import type { match } from 'react-router'

interface PagesSettingsProps {
  readonly match: match
  readonly venue: Venue
  readonly venues: UserDomainVenue[]
  readonly onChangeVenue: (venue: UserDomainVenue) => void
}

function PagesSettingsComponent(props: PagesSettingsProps) {
  const { venue, venues, onChangeVenue, match } = props

  const routes = {
    FEEDBACK_PAGE_LANGUAGE: `${match.path}/feedback_page_language`,
    RECEIPT_PAGE_LANGUAGE: `${match.path}/receipt_page_language`,
    PAYLINK_PAGE_LANGUAGE: `${match.path}/paylink_page_language`,
    RES_WIDGET_CANCEL_PAGE_LANGUAGE: `${match.path}/cancellation_page_language`,
    RES_WIDGET_CONFIRM_PAGE_LANGUAGE: `${match.path}/confirmation_page_language`,
    EMAIL_PREFERENCE_CENTER_LANGUAGE: `${match.path}/email_preference_center_language`,
    MARKETING_SUBSCRIPTION: `${match.path}/marketing_subscription_language`,
  }

  return <PagesLanguageSettings routes={routes} venue={venue} venues={venues} onChangeVenue={onChangeVenue} />
}

const mapStateToProps = (state: { appState: { venue: Venue; userDomain: UserDomain } }) => {
  const { venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
}

export const PagesSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(PagesSettingsComponent)
