import { timeHHmmssToFormat } from 'svr/common/DateTime'
import type { TimeRange } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MomentTimeRange } from 'svr/lib/Ordering/mergeMenuAvailableHours'

export interface HoursItem {
  dayOfWeekIndex: number
  hoursListTitle: string
}

export interface HoursGroupedItem {
  startDayOfWeekIndex: number
  endDayOfWeekIndex: number | null
  hoursListTitle: string
}

const createNewHoursGroupedItem = (hoursItem: HoursItem): HoursGroupedItem => ({
  startDayOfWeekIndex: hoursItem.dayOfWeekIndex,
  endDayOfWeekIndex: null,
  hoursListTitle: hoursItem.hoursListTitle,
})

const redefineEndOfHoursInGroupItem = (
  groupedList: HoursGroupedItem[],
  redefineIndex: number,
  endDayOfWeekIndex: number
): HoursGroupedItem[] =>
  groupedList.map((groupItem, groupItemIndex) => {
    if (groupItemIndex !== redefineIndex) {
      return groupItem
    }

    return {
      ...groupedList[redefineIndex],
      endDayOfWeekIndex,
    } as HoursGroupedItem
  })

const getHoursListTitle = (hoursList: (TimeRange | MomentTimeRange)[]) =>
  hoursList.map(({ start, end }) => (start && end ? `${timeHHmmssToFormat(start)} - ${timeHHmmssToFormat(end)}` : '')).join(', ')

export const getGroupedHoursByWeekDay = (availableHours: Record<number, (TimeRange | MomentTimeRange)[]>) =>
  Object.keys(availableHours)
    .map(Number)
    .map(
      weekIndex =>
        ({
          dayOfWeekIndex: weekIndex,
          hoursListTitle: getHoursListTitle(availableHours[weekIndex] || []),
        } as HoursItem)
    )
    .reduce((groupedList: HoursGroupedItem[], hoursItem: HoursItem) => {
      if (groupedList.length === 0) {
        return [createNewHoursGroupedItem(hoursItem)]
      }

      const prevIndex = groupedList.length - 1
      const { hoursListTitle: prevHoursListTitle } = groupedList[prevIndex] || {}

      if (prevHoursListTitle === hoursItem.hoursListTitle) {
        return redefineEndOfHoursInGroupItem(groupedList, prevIndex, hoursItem.dayOfWeekIndex)
      }

      return [...groupedList, createNewHoursGroupedItem(hoursItem)]
    }, [])

export default getGroupedHoursByWeekDay
