import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ManagerStyles from 'mgr/layout/Styles'
import DataHeavyLayout from 'mgr/pages/shared/layouts/DataHeavy'
import { SubNavStyles } from 'mgr/pages/shared/SubNav'
import Toolbar from 'mgr/pages/single-venue/dayview/containers/DayviewToolbar'
import {
  onDateFilterChange,
  onSearchFilterChange,
  onOutgoingEmailsPageChange,
  onCustomDateRangeChange,
  onOutgoingEmailsFilterChange,
} from 'mgr/pages/single-venue/reporting/actions/EmailFilters'
import {
  startLoading,
  tryLoadOutgoingEmails,
  onChangeVenue,
  onRowClick,
  openOutgoingEmail,
} from 'mgr/pages/single-venue/reporting/actions/OutgoingEmails'
import OutgoingEmailsFilter from 'mgr/pages/single-venue/reporting/components/OutgoingEmailsFilter'
import OutgoingEmailsList from 'mgr/pages/single-venue/reporting/components/OutgoingEmailsList'
import { ReportingWrapper, ReportingContainer } from 'mgr/pages/single-venue/reporting/components/ReportingWrap'
import ShowOutgoingEmail from 'mgr/pages/single-venue/reporting/containers/ShowOutgoingEmail'
import { selectOutgoingEmailsFiltersValues } from 'mgr/pages/single-venue/reporting/selectors/EmailFilters'

class OutgoingEmails extends React.PureComponent {
  constructor(props) {
    super(props)

    this.onChangeVenue = this.onChangeVenue.bind(this)
    this.onOutgoingEmailsPageChange = this.onOutgoingEmailsPageChange.bind(this)
  }

  onChangeVenue(venue) {
    this.props.onChangeVenue(venue)
  }

  onOutgoingEmailsPageChange(page) {
    this.props.onOutgoingEmailsPageChange(page, this.props.filters.pageSize)
  }

  componentDidMount() {
    const { tryLoadOutgoingEmails, venue } = this.props

    tryLoadOutgoingEmails(venue)
  }

  render() {
    const {
      results,
      total,
      isLoading,
      startLoading,
      history,
      filters,
      venue,
      page,
      pages,
      pageSize,
      onRowClick,
      onDateFilterChange,
      onSearchFilterChange,
      onOutgoingEmailsFilterChange,
      dateValue,
      searchValue,
      emailTypeValue,
      onCustomDateRangeChange,
      tryLoadOutgoingEmails,
      locale,
    } = this.props
    return (
      <ReportingWrapper>
        <ReportingContainer>
          <Toolbar
            showShiftDropdown={false}
            showDateDropdown={false}
            showControls={false}
            overrideChangeVenue={this.onChangeVenue}
            key="dayViewToolBar"
            history={history}
            showActionButton={false}
            width={`calc(100% - ${SubNavStyles.width}px - ${ManagerStyles.NavigationWidth}px)`}
          />
          <DataHeavyLayout
            DataFilter={
              <OutgoingEmailsFilter
                startLoading={startLoading}
                dateValue={dateValue}
                searchValue={searchValue}
                emailTypeValue={emailTypeValue}
                onDateFilterChange={onDateFilterChange}
                onSearchFilterChange={onSearchFilterChange}
                onOutgoingEmailsFilterChange={onOutgoingEmailsFilterChange}
                startDate={filters.startDate}
                endDate={filters.endDate}
                onCustomDateRangeChange={(type, val) => {
                  onCustomDateRangeChange(type, val)
                  tryLoadOutgoingEmails(venue)
                }}
              />
            }
            DataSummary={false}
            DataList={
              <OutgoingEmailsList
                isLoading={isLoading}
                emails={results}
                total={total}
                rowClick={onRowClick}
                page={page}
                pageSize={pageSize}
                pages={pages}
                locale={locale}
                onOutgoingEmailsPageChange={this.onOutgoingEmailsPageChange}
              />
            }
          />
        </ReportingContainer>
        <ShowOutgoingEmail />
      </ReportingWrapper>
    )
  }
}

const mapStateToProps = state => {
  const filtersValues = selectOutgoingEmailsFiltersValues(state)

  return {
    venue: state.appState.venue,
    results: state.outgoingEmails.data.results,
    total: state.outgoingEmails.data.total,
    lastUpdated: state.outgoingEmails.data.lastUpdated,
    selected: state.outgoingEmails.data.selected,
    isLoading: state.outgoingEmails.data.isLoading,
    filters: state.outgoingEmails.filters,
    dateValue: filtersValues.values.date,
    searchValue: filtersValues.values.search,
    emailTypeValue: filtersValues.values.emailType,
    page: filtersValues.values.page,
    pages: filtersValues.values.pages,
    pageSize: filtersValues.values.pageSize,
    locale: state.appState.venue.locale,
  }
}

const mapDispatchToProps = {
  tryLoadOutgoingEmails,
  onChangeVenue,
  openOutgoingEmail,
  onRowClick,
  onDateFilterChange,
  onSearchFilterChange,
  onOutgoingEmailsFilterChange,
  onOutgoingEmailsPageChange,
  startLoading,
  onCustomDateRangeChange,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OutgoingEmails))
