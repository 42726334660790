import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Button, TextArea } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useInsightSuggestedActionsContext } from '../../../hooks'
import { insightsMessages } from '../../../locales'
import type { FormEvent } from 'react'

export function InsightChangeRequestInstructionsSection() {
  const { setChangeRequestInstructions, changeRequestInstructions, setDisplayChangeRequestSection, onChangeRequest } =
    useInsightSuggestedActionsContext()

  const { formatMessage } = useLocales()

  return (
    <VStack p="sm sm sm l" spacing="s" data-test="insight-change-request-section">
      <Text fontWeight="bold">{formatMessage(insightsMessages.changeRequestMessageText)}</Text>
      <SecondaryText>{formatMessage(insightsMessages.changeRequestMessageSecondaryText)}</SecondaryText>
      <TextArea
        data-test="insight-change-request-edit-input"
        aria-label={formatMessage(insightsMessages.changeRequestMessageText)}
        fullWidth
        value={changeRequestInstructions}
        onChange={(e: FormEvent<HTMLTextAreaElement>) => {
          setChangeRequestInstructions(e.currentTarget.value)
        }}
        placeholder={formatMessage(insightsMessages.changeRequestMessagePlaceholderText)}
      />
      <HStack justifyContent="right" spacing="sm">
        <Button
          variant="secondary"
          size="s"
          data-test="insight-change-request-back-button"
          onClick={() => setDisplayChangeRequestSection(false)}
        >
          {formatMessage(commonMessages.back)}
        </Button>
        <Button variant="primary" size="s" data-test="insight-change-request-submit-button" onClick={onChangeRequest}>
          {formatMessage(commonMessages.submit)}
        </Button>
      </HStack>
    </VStack>
  )
}
