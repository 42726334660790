/* eslint-disable camelcase */
import { processFail, showErrorMessage, showSuccessMessage } from 'mgr/lib/actions/GlobalActions'
import {
  getPacingRuleById,
  savePacingRule,
  getPacingRuleListByVenueId,
  getPacingRuleResources,
  createPacingRule,
  deletePacingRule,
} from 'mgr/lib/services/OrderPacingServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { TimeRange, OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { ORDER_METHOD_TYPE } from 'mgr/pages/single-venue/settings/types/ordering/Order.types'
import type { PacingRule, OrderingSiteOption, DayTimeLimitMatrix } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'
import type { Dispatch } from 'redux'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export interface GetOrderingPacingRuleListStartAction {
  type: typeof ActionTypes.GET_ORDERING_PACING_RULE_LIST_START
}
export interface GetOrderingPacingRuleListFailAction {
  type: typeof ActionTypes.GET_ORDERING_PACING_RULE_LIST_FAIL
}
export interface GetOrderingPacingRuleListSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_PACING_RULE_LIST_SUCCESS
  pacingRules: PacingRule[]
}
export interface GetOrderingPacingRuleSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_PACING_RULE_SUCCESS
  pacingRule: PacingRule
}
export interface GetOrderingPacingRuleResourcesSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_PACING_RULE_RESOURCES_SUCCESS
  menus: OrderingMenu[]
  defaultMenuHours: TimeRange
  fulfillmentMethods: ORDER_METHOD_TYPE[]
  orderingSites: OrderingSiteOption[]
  venueId: string
}
export interface RemoveOrderingPacingRuleStartAction {
  type: typeof ActionTypes.REMOVE_PACING_RULE_START
}
export interface RemoveOrderingPacingRuleFailAction {
  type: typeof ActionTypes.REMOVE_PACING_RULE_FAIL
}
export interface RemoveOrderingPacingRuleSuccessAction {
  type: typeof ActionTypes.REMOVE_PACING_RULE_SUCCESS
}

export const setPacingRuleListFilterSearchValue = (value: string) =>
  ({ type: ActionTypes.SET_PACING_RULE_LIST_FILTER_SEARCH_VALUE, value } as const)

export const setPacingRuleListSortedColumns = (data: SortColumn[]) =>
  ({ type: ActionTypes.SET_PACING_RULE_LIST_SORTED_COLUMNS, data } as const)

export const clearPacingRuleData = () => ({ type: ActionTypes.CLEAR_PACING_RULE_DATA } as const)

export const updateName = (value: string) => ({ type: ActionTypes.UPDATE_PACING_RULE_NAME, value } as const)
export const updateDateRangeFrom = (value: Date) => ({ type: ActionTypes.UPDATE_PACING_RULE_DATE_RANGE_FROM, value } as const)
export const updateDateRangeTo = (value: Date | null) => ({ type: ActionTypes.UPDATE_PACING_RULE_DATE_RANGE_TO, value } as const)
export const updateOrderMethods = (value: ORDER_METHOD_TYPE[]) => ({ type: ActionTypes.UPDATE_PACING_ORDER_METHODS, value } as const)
export const updateOrderingSiteIds = (value: string[]) => ({ type: ActionTypes.UPDATE_PACING_RULE_ORDERING_SITE_IDS, value } as const)
export const updateTimeRangeFrom = (value: moment.Moment) => ({ type: ActionTypes.UPDATE_PACING_RULE_TIME_RANGE_FROM, value } as const)
export const updateTimeRangeTo = (value: moment.Moment) => ({ type: ActionTypes.UPDATE_PACING_RULE_TIME_RANGE_TO, value } as const)
export const updateTimeIntervalMins = (value: number) => ({ type: ActionTypes.UPDATE_PACING_RULE_TIME_INTERVAL_MINS, value } as const)
export const updateDayTimeLimitMatrix = (value: DayTimeLimitMatrix) =>
  ({ type: ActionTypes.UPDATE_PACING_RULE_DAY_TIME_LIMIT_MATRIX, value } as const)

type loadPacingRuleListByVenueIdType = GetOrderingPacingRuleListStartAction | GetOrderingPacingRuleListSuccessAction
export const loadPacingRuleListByVenueId = (venueId: string) => async (dispatch: Dispatch<loadPacingRuleListByVenueIdType>) => {
  dispatch({ type: ActionTypes.GET_ORDERING_PACING_RULE_LIST_START })
  try {
    const response = await getPacingRuleListByVenueId(venueId)
    dispatch({
      type: ActionTypes.GET_ORDERING_PACING_RULE_LIST_SUCCESS,
      pacingRules: response.pacingRules,
    })
  } catch (e) {
    dispatch(processFail(SOMETHING_WENT_WRONG_LOADING, ActionTypes.GET_ORDERING_PACING_RULE_LIST_FAIL))
  }
}

export const loadPacingRuleById =
  (venueId: string, pacingRuleId: string) =>
  async (dispatch: Dispatch<GetOrderingPacingRuleSuccessAction | ReturnType<typeof showErrorMessage>>) => {
    let response

    try {
      response = await getPacingRuleById(venueId, pacingRuleId)
    } catch (e) {
      dispatch(showErrorMessage(SOMETHING_WENT_WRONG_LOADING))
      throw e
    }

    dispatch({
      type: ActionTypes.GET_ORDERING_PACING_RULE_SUCCESS,
      pacingRule: response.pacingRule,
    })
  }

export const loadPacingRuleResources =
  (venueId: string) =>
  async (dispatch: Dispatch<GetOrderingPacingRuleResourcesSuccessAction | ReturnType<typeof showErrorMessage>>, getState: () => State) => {
    let response
    const state = getState()
    if (state.orderingOrderPacing.resourcesLoadedForVenueId === venueId) {
      return
    }

    try {
      response = await getPacingRuleResources(venueId)
    } catch (e) {
      dispatch(showErrorMessage(SOMETHING_WENT_WRONG_LOADING))
      throw e
    }

    dispatch({
      type: ActionTypes.GET_ORDERING_PACING_RULE_RESOURCES_SUCCESS,
      menus: response.menus,
      defaultMenuHours: response.defaultMenuHours,
      fulfillmentMethods: response.fulfillmentMethods,
      orderingSites: response.orderingSites,
      venueId,
    })
  }

export const savePacingRuleById =
  (venueId: string, pacingRuleId: string, pacingRuleData: PacingRule) =>
  async (dispatch: Dispatch<ReturnType<typeof showSuccessMessage> | ReturnType<typeof showErrorMessage>>) => {
    try {
      await savePacingRule(venueId, pacingRuleId, pacingRuleData)
      dispatch(showSuccessMessage(SUCCESSFUL_SAVE))
    } catch (e) {
      dispatch(showErrorMessage(SOMETHING_WENT_WRONG_SAVING))
      throw e
    }
  }

export const createNewPacingRule =
  (venueId: string, pacingRuleData: Omit<PacingRule, 'id'>) =>
  async (dispatch: Dispatch<ReturnType<typeof showSuccessMessage> | ReturnType<typeof showErrorMessage>>) => {
    try {
      await createPacingRule(venueId, pacingRuleData)
      dispatch(showSuccessMessage(SUCCESSFUL_SAVE))
    } catch (e) {
      dispatch(showErrorMessage(SOMETHING_WENT_WRONG_SAVING))
      throw e
    }
  }

export const removePacingRule =
  (venueId: string, pacingRuleId: string) =>
  async (
    dispatch: Dispatch<
      | RemoveOrderingPacingRuleStartAction
      | RemoveOrderingPacingRuleFailAction
      | RemoveOrderingPacingRuleSuccessAction
      | ReturnType<typeof showErrorMessage>
      | ReturnType<typeof showSuccessMessage>
      | loadPacingRuleListByVenueIdType
    >
  ) => {
    dispatch({
      type: ActionTypes.REMOVE_PACING_RULE_START,
    })
    try {
      await deletePacingRule(venueId, pacingRuleId)
    } catch (e) {
      dispatch(processFail(SOMETHING_WENT_WRONG_REMOVE, ActionTypes.REMOVE_PACING_RULE_FAIL))
      throw e
    }
    dispatch({
      type: ActionTypes.REMOVE_PACING_RULE_SUCCESS,
    })
    dispatch(showSuccessMessage(RULE_DELETED_SUCCESS_MESSAGE))
    await loadPacingRuleListByVenueId(venueId)(dispatch)
  }

const SUCCESSFUL_SAVE = 'Saved successfully. Allow 30 seconds for ordering sites to reflect updated order pacing rules.'
const RULE_DELETED_SUCCESS_MESSAGE = 'Pacing rule deleted'
const SOMETHING_WENT_WRONG_LOADING = 'Something went wrong. Please try loading again.'
const SOMETHING_WENT_WRONG_SAVING = 'Something went wrong. Please try saving again.'
const SOMETHING_WENT_WRONG_REMOVE = 'Unable to delete pacing rule, please try again later or contact customer support.'

export type OrderPacingAction =
  | GetOrderingPacingRuleListStartAction
  | GetOrderingPacingRuleListFailAction
  | GetOrderingPacingRuleListSuccessAction
  | GetOrderingPacingRuleSuccessAction
  | GetOrderingPacingRuleResourcesSuccessAction
  | RemoveOrderingPacingRuleStartAction
  | RemoveOrderingPacingRuleSuccessAction
  | RemoveOrderingPacingRuleFailAction
  | ReturnType<typeof clearPacingRuleData>
  | ReturnType<typeof setPacingRuleListFilterSearchValue>
  | ReturnType<typeof setPacingRuleListSortedColumns>
  | ReturnType<typeof updateName>
  | ReturnType<typeof updateDateRangeFrom>
  | ReturnType<typeof updateDateRangeTo>
  | ReturnType<typeof updateOrderMethods>
  | ReturnType<typeof updateOrderingSiteIds>
  | ReturnType<typeof updateTimeRangeFrom>
  | ReturnType<typeof updateTimeRangeTo>
  | ReturnType<typeof updateTimeIntervalMins>
  | ReturnType<typeof updateDayTimeLimitMatrix>
