import { useState } from 'react'
import type { AvailabilityCalendarTimeslotResponse } from '@sevenrooms/core/domain'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Box } from '@sevenrooms/react-components/components/Box'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { ReasonsModal, type TimeslotPartialExceptTime } from '../ReasonsModal/ReasonsModal'

const BASE_BLOCK_WIDTH = 20

export interface AvailabilityCalendarBlockProps {
  timeslot: Partial<AvailabilityCalendarTimeslotResponse>
  partySize?: number
  date?: DateOnly
  isLegend?: boolean
}

export function AvailabilityCalendarBlock({ timeslot, partySize, date, isLegend = false }: AvailabilityCalendarBlockProps) {
  const { venue } = useVenueContext()
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  function handleOnClose() {
    setOpen(false)
  }

  const getBlockWidth = ({ intervalMinutes }: Partial<AvailabilityCalendarTimeslotResponse>): number => {
    switch (intervalMinutes) {
      case 15:
        return BASE_BLOCK_WIDTH
      case 30:
        return BASE_BLOCK_WIDTH * 2 + 2
      case 60:
        return BASE_BLOCK_WIDTH * 4 + 4
      default:
        return BASE_BLOCK_WIDTH
    }
  }

  const getColor = ({ isOpen, isPast, hasAvailability }: Partial<AvailabilityCalendarTimeslotResponse>): string => {
    if (isPast || !isOpen) {
      return theme.palette.grey[300]
    } else if (hasAvailability) {
      return theme.palette.success.main
    }
    return '#C01313' // Hardcode color until added to new theme
  }

  const isClickable = !isLegend && !timeslot.hasAvailability

  return (
    <>
      <Box
        onClick={() => {
          if (isClickable) {
            setOpen(true)
          }
        }}
        sx={{
          height: 20,
          width: getBlockWidth(timeslot),
          borderRadius: 1,
          m: '1px',
          bgcolor: getColor(timeslot),
          ...(isClickable && {
            cursor: 'pointer',
            '&:hover': {
              outlineWidth: 1,
              outlineStyle: 'solid',
              outlineColor: theme.palette.info.dark,
            },
          }),
        }}
        data-test="availability-calendar-block"
      />
      {open && date && timeslot.time && (
        <ReasonsModal
          open={open}
          onClose={handleOnClose}
          venue={venue}
          date={date}
          timeslot={timeslot as TimeslotPartialExceptTime<AvailabilityCalendarTimeslotResponse, 'time'>}
          covers={partySize as number}
        />
      )}
    </>
  )
}
