import _ from 'lodash'
import { useCallback } from 'react'
import { DragDropContext, Droppable, type DroppableProvided, type DropResult } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { type Item, Section } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/Section/Section'
import { VStack } from '@sevenrooms/core/ui-kit/layout'

export interface DragDropPanelProps {
  items: Item[]
  panelId: string
  onItemsUpdate: (items: Item[]) => void
  onRemove: (value: string) => void
  isDragDisabled?: boolean
}

export function DragDropPanel({ items, panelId, onItemsUpdate, onRemove, isDragDisabled }: DragDropPanelProps) {
  const onItemsMove = useCallback(
    (dropResult: DropResult) => {
      const { source, destination } = dropResult
      if (!destination) {
        return
      }
      const updatedModifiers = [...items]
      updatedModifiers.splice(source.index, 1)
      updatedModifiers.splice(destination.index, 0, _.find(items, { id: dropResult.draggableId }) as Item)
      onItemsUpdate(updatedModifiers)
    },
    [items, onItemsUpdate]
  )
  return (
    <DragDropContext onDragEnd={onItemsMove}>
      <Droppable droppableId={panelId}>
        {(provided: DroppableProvided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <PanelWrapper spacing="m" justifyContent="space-between" alignItems="center">
              {items.map((item, index) => (
                <Section key={item.id} item={item} index={index} onRemove={onRemove} isDragDisabled={isDragDisabled} />
              ))}
            </PanelWrapper>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
const PanelWrapper = styled(VStack)``
