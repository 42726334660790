/* eslint-disable camelcase */
import {
  adaptModifierGroupsFromApi,
  adaptProductFromApi,
  adaptProductToApi,
} from 'mgr/lib/services/ProductInventoryServices/adapters/adaptProductInventory'
import { srDelete, srGet, srPostJson, srPut, throwFetchError } from '@sevenrooms/core/api'
import type { ModifierGroupsApi, Modifiers, PRODUCT_TYPES, ProductTags } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { ProductInventoryItem, ProductInventoryItemApi } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export const getProductInventoryByVenueId = (venueId: string, productType?: PRODUCT_TYPES) =>
  srGet<{ products: ProductInventoryItemApi[] }>(`/api-yoa/ordering/inventory/${venueId}`, productType ? { productType } : {}, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      products: response.data.products.map(product => adaptProductFromApi(product)),
    }))

export const getProductInventoryItemById = async (venueId: string, productId: string, productType: PRODUCT_TYPES) =>
  srGet<{ product: ProductInventoryItemApi }>(
    `/api-yoa/ordering/inventory/${venueId}/${productId}`,
    { productType },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  )
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      product: adaptProductFromApi(response.data.product),
    }))

export const saveProductInventoryItem = async (venueId: string, productId: string, productData: ProductInventoryItem) =>
  srPut(`/api-yoa/ordering/inventory/${venueId}/${productId}`, adaptProductToApi(productData), {
    json: true,
  }).then(throwFetchError)

export const createProductInventoryItem = async (venueId: string, productData: ProductInventoryItem) =>
  srPostJson(`/api-yoa/ordering/inventory/${venueId}`, adaptProductToApi(productData)).then(throwFetchError)

export const deleteProductInventoryItem = async (venueId: string, productId: string, productType: PRODUCT_TYPES) =>
  srDelete(
    `/api-yoa/ordering/inventory/${venueId}/${productId}`,
    { productType },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(throwFetchError)

export const getProductInventoryResources = async (venueId: string, productType: PRODUCT_TYPES) =>
  srGet<{ modifiers: Modifiers; modifier_groups: ModifierGroupsApi; product_tags: ProductTags }>(
    `/api-yoa/ordering/inventory_resources/${venueId}`,
    { productType },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  )
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      modifiers: response.data.modifiers,
      modifierGroups: adaptModifierGroupsFromApi(response.data.modifier_groups),
      productTags: response.data.product_tags,
    }))
