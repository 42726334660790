import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import TagMappingSlideout from 'mgr/pages/single-venue/settings/containers/TagMappingSlideout'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'

class TagMapping extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tagForSlideout: null,
    }
  }

  rowClick = row => {
    this.setState({ tagForSlideout: row.original })
  }

  onCloseHandler = () => {
    this.setState({ tagForSlideout: null })
  }

  render() {
    return (
      <>
        <Table
          showPagination={false}
          sortable={false}
          data={this.props.tagsToMapping}
          rowClick={this.rowClick}
          pageSize={this.props.tagsToMapping.length}
          showPageSizeOptions={false}
          columns={[
            {
              Header: 'Tag from file',
              accessor: name => name,
              id: 'name',
            },
            {
              Header: 'Map to tag',
              accessor: name =>
                this.props.mappedTags[name] && `${this.props.mappedTags[name].tagName} (${this.props.mappedTags[name].tagGroup.name})`,
              id: 'mappedTo',
            },
          ]}
        />
        <TagMappingSlideout
          tagGroups={this.props.tagGroups}
          tagToMapping={this.state.tagForSlideout}
          isSlideoutOpen={!!this.state.tagForSlideout}
          onCloseHandler={this.onCloseHandler}
        />
      </>
    )
  }
}

TagMapping.propTypes = {
  tagsToMapping: React.PropTypes.array.isRequired,
  tagGroups: React.PropTypes.arrayOf(React.PropTypes.object),
}

const mapStateToProps = state => ({
  mappedTags: state.reservationAndClientImport.mappedTags,
})

export default connect(mapStateToProps)(TagMapping)
