import React from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/react-components/components/Button'
import { DialogActions } from '@sevenrooms/react-components/components/DialogActions'
import { DialogContent } from '@sevenrooms/react-components/components/DialogContent'
import { DialogContentText } from '@sevenrooms/react-components/components/DialogContentText'
import { venueCategoryMessages } from '../../../locales'

interface VenueContentCategoryProps {
  venue: Venue
  reasons: AvailabilityDebuggerReason[]
}

export function VenueCategory({ venue, reasons }: VenueContentCategoryProps) {
  const { formatMessage } = useLocales()

  const reason = reasons[0]
  let contentText = ''
  let actionText = ''
  let actionUrl = ''
  let dataTest = ''
  switch (reason?.reason) {
    case 'VENUE_CLOSED': {
      contentText = formatMessage(venueCategoryMessages.mainDescriptionClosed)
      actionText = formatMessage(venueCategoryMessages.manageBlackoutDates)
      actionUrl = `/manager/${venue.urlKey}/manage/blackoutdates`
      dataTest = 'venue-category-blackout-dates-action-button'
      break
    }
    case 'NO_ACCESS_RULES_FOR_SHIFT': {
      contentText = formatMessage(venueCategoryMessages.mainDescriptionNoAccessRules)
      actionText = formatMessage(venueCategoryMessages.manageAccessRules)
      actionUrl = `/manager/${venue.urlKey}/manage/capacity/accessrules`
      dataTest = 'venue-category-access-rules-action-button'
      break
    }
    default:
      break
  }
  return (
    <DialogContent
      sx={{
        backgroundColor: '#F7F7F7',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 3,
        mb: 3,
        mx: 3,
        borderRadius: '4px',
        '&.MuiDialogContent-root': {
          p: 2,
        },
      }}
      data-test="venue-category-content"
    >
      <DialogContentText id="venue-category-modal-description">{contentText}</DialogContentText>
      <DialogActions sx={{ p: 0 }} id="venue-category-modal-actions">
        <Button data-test={dataTest} size="small" variant="contained" onClick={() => window.open(actionUrl, '_blank')}>
          {actionText}
        </Button>
      </DialogActions>
    </DialogContent>
  )
}
