import React from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { VisuallyHiddenStyle } from '@sevenrooms/core/ui-kit/core'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { NavigationDashboardCard } from '../NavigationDashboardCard'
import type { NavigationDashboardPageItem } from '../NavigationDashboard'

export const NavigationDashboardSectionTestIds = {
  categoryHeader: 'navigation-dashboard-section-header',
  hiddenHeader: 'navigation-dashboard-section-header-hidden',
  card: 'navigation-dashboard-card',
}

export interface NavigationDashboardSectionProps {
  header: string
  hideHeader?: boolean
  items: NavigationDashboardPageItem[]
  dataTest?: string
}

const VisuallyHiddenDiv = styled.div`
  ${VisuallyHiddenStyle}
`

function ConditionallyVisuallyHiddenDiv({ children, hidden }: React.PropsWithChildren<{ hidden?: boolean }>) {
  return hidden ? (
    <VisuallyHiddenDiv data-test={NavigationDashboardSectionTestIds.hiddenHeader}>{children}</VisuallyHiddenDiv>
  ) : (
    <>{children}</>
  )
}

export function NavigationDashboardSection({ header, hideHeader, items, dataTest }: NavigationDashboardSectionProps) {
  return (
    <Box data-test={dataTest}>
      <ConditionallyVisuallyHiddenDiv hidden={hideHeader}>
        <Text
          type="h2"
          color="secondaryFont"
          fontSize="s"
          fontWeight="bold"
          letterSpacing="m"
          textTransform="uppercase"
          data-test={NavigationDashboardSectionTestIds.categoryHeader}
        >
          {header}
        </Text>
      </ConditionallyVisuallyHiddenDiv>
      {items.map(item => (
        <Box key={item.id} mt="s" mb="s" data-test={NavigationDashboardSectionTestIds.card}>
          <NavigationDashboardCard
            title={item.title}
            to={item.to}
            reload={item.reload}
            thumbnail={item.thumbnail}
            description={item.description}
            badge={item.badge}
          />
        </Box>
      ))}
    </Box>
  )
}
