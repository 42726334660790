import _ from 'lodash'
import { useState } from 'react'

export type ErrorFields = Record<string, string>

export const useErrorFields = () => {
  const [errorFields, updateErrorFields] = useState({})

  const isHasErrors = (errorFields: ErrorFields): boolean => !_.isEmpty(errorFields)

  return {
    isHasErrors,
    errorFields,
    updateErrorFields,
  }
}
