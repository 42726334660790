import { WEEK_INDEX_TO_TITLE_SHORT } from 'svr/constants'
import { HStack, Box } from '@sevenrooms/core/ui-kit/layout'
import getGroupedHoursByWeekDay from './getGroupedHoursByWeekDay'
import type { TimeRange } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MomentTimeRange } from 'svr/lib/Ordering/mergeMenuAvailableHours'

interface AvailableHoursProps {
  value: Record<number, (TimeRange | MomentTimeRange)[]>
  index: number
}

function AvailableHours({ value, index }: AvailableHoursProps) {
  const groupedHoursByWeekDay = getGroupedHoursByWeekDay(value)
  let dataTestCounter = 0
  return (
    <div>
      {groupedHoursByWeekDay.map(({ endDayOfWeekIndex, startDayOfWeekIndex, hoursListTitle }) => {
        const daysOfWeek =
          endDayOfWeekIndex !== null
            ? `${WEEK_INDEX_TO_TITLE_SHORT[startDayOfWeekIndex]} - ${WEEK_INDEX_TO_TITLE_SHORT[endDayOfWeekIndex]}`
            : WEEK_INDEX_TO_TITLE_SHORT[startDayOfWeekIndex]

        if (!hoursListTitle) {
          return null
        }
        dataTestCounter += 1
        return (
          <HStack key={daysOfWeek}>
            <Box minWidth="100px" data-test={`menu-mgt-entry-${index}-menu-availability-${dataTestCounter}-days`}>
              {daysOfWeek}
            </Box>
            <Box minWidth="72px" data-test={`menu-mgt-entry-${index}-menu-availability-${dataTestCounter}-hours`}>
              {hoursListTitle}
            </Box>
          </HStack>
        )
      })}
    </div>
  )
}

export default AvailableHours
