import { AddMenuItemModal } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/AddMenuItem/AddMenuItemModal'
import { Category } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/Category'
import { CategoryModal } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/CategoryModal'
import { ConfirmMenuCategoryDeleteModal } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/ConfirmMenuCategoryDeleteModal'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Window } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import type { MenuCategory, MenuCategoryItem, OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface MenuBuilderProps {
  menu: OrderingMenu
  menuItems: MenuItem[]
  venue: Venue
  selectedMenuCategory?: MenuCategory
  isCategoryModalOpen: boolean
  onCategoryModalClose: () => void
  isConfirmDeleteModalOpen: boolean
  onCategoryModalOpen: (category?: MenuCategory) => void
  onConfirmDeleteModalClose: () => void
  onCategoryOrderChanged: (categoryName: string, position: number) => void
  onCategoryCreate: (category: MenuCategory) => void
  onCategoryUpdate: (categoryName: string, category: MenuCategory) => void
  onCategoryDelete: (categoryName: string) => void
  onConfirmDeleteModalOpen: (categoryName: string) => void
  onCategoryMenuItemDelete: (category: MenuCategory, menuItem: MenuItem) => void
  onCategoryMenuItemsUpdate: (category: MenuCategory, menuItems: MenuCategoryItem[]) => void
  isAddMenuItemModalOpen: boolean
  onAddMenuItemModalOpen: (category: MenuCategory) => void
  onAddMenuItemModalClose: () => void
  isVisible: boolean
}

export function MenuBuilder({
  menu,
  menuItems,
  venue,
  selectedMenuCategory,
  isCategoryModalOpen,
  isConfirmDeleteModalOpen,
  onConfirmDeleteModalClose,
  onCategoryOrderChanged,
  onCategoryCreate,
  onConfirmDeleteModalOpen,
  onCategoryDelete,
  onCategoryMenuItemDelete,
  onCategoryMenuItemsUpdate,
  onCategoryModalOpen,
  onCategoryModalClose,
  onCategoryUpdate,
  isAddMenuItemModalOpen,
  onAddMenuItemModalOpen,
  onAddMenuItemModalClose,
  isVisible,
}: MenuBuilderProps) {
  return (
    <>
      {menu.hierarchy.categories.map((category, index) => (
        <Box mb="lm" key={category.name}>
          <Category
            venue={venue}
            position={index}
            categoriesCount={menu.hierarchy.categories.length}
            category={category}
            menuItems={menuItems}
            onCategoryOrderChanged={onCategoryOrderChanged}
            onCategoryEdit={onCategoryModalOpen}
            onCategoryDelete={onConfirmDeleteModalOpen}
            onCategoryMenuItemDelete={onCategoryMenuItemDelete}
            onCategoryMenuItemsUpdate={onCategoryMenuItemsUpdate}
            onAddMenuItemModalOpen={onAddMenuItemModalOpen}
            isVisible={isVisible}
          />
        </Box>
      ))}
      <Button data-test="add-new-category-button" variant="secondary" icon="VMSWeb-add" onClick={() => onCategoryModalOpen()}>
        Add New Category
      </Button>
      <Window active={isCategoryModalOpen}>
        <CategoryModal
          menu={menu}
          category={selectedMenuCategory}
          onCategorySave={(category: MenuCategory) =>
            selectedMenuCategory ? onCategoryUpdate(selectedMenuCategory.name, category) : onCategoryCreate(category)
          }
          onCategoryModalClose={onCategoryModalClose}
        />
      </Window>
      <Window active={isConfirmDeleteModalOpen}>
        <ConfirmMenuCategoryDeleteModal
          category={selectedMenuCategory as MenuCategory}
          onConfirm={onCategoryDelete}
          onDiscard={onConfirmDeleteModalClose}
        />
      </Window>
      <Window active={isAddMenuItemModalOpen}>
        <AddMenuItemModal
          venue={venue}
          category={selectedMenuCategory as MenuCategory}
          menuItems={menuItems}
          tooltipId="addMenuItemModalTooltip"
          onAddMenuItemModalSave={onCategoryMenuItemsUpdate}
          onAddMenuItemModalClose={onAddMenuItemModalClose}
        />
      </Window>
    </>
  )
}
