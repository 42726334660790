import { useLocales } from '@sevenrooms/core/locales'
import { Toggle, type ToggleProps } from '@sevenrooms/core/ui-kit/core'
import { HStack, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { orderingNotificationSettingsMessages } from '../../locales/orderingNotificationSettingsMessages'

export interface NotificationToggleRowProps {
  notificationType: string
  notificationTypeDescription: string
  emailEnabled: boolean | null
  smsEnabled: boolean | null
  emailToggleReadOnly?: boolean
  smsToggleReadOnly?: boolean
  emailOnChange: () => void
  smsOnChange: () => void
}

export const NotificationTableWidth = '515px'

export function NotificationToggleRow({
  notificationType,
  notificationTypeDescription,
  emailEnabled,
  smsEnabled,
  emailToggleReadOnly = true,
  smsToggleReadOnly = false,
  emailOnChange,
  smsOnChange,
}: NotificationToggleRowProps) {
  return (
    <HStack justifyContent="space-between" width={NotificationTableWidth} height="54px" m="lm">
      <VStack width="376px">
        <Text>{notificationType}</Text>
        <SecondaryText>{notificationTypeDescription}</SecondaryText>
      </VStack>
      {emailEnabled !== null && <EmailToggle checked={emailEnabled} disabled={emailToggleReadOnly} onChange={emailOnChange} />}
      {smsEnabled !== null && (
        <Toggle
          checked={smsEnabled}
          onChange={smsOnChange}
          disabled={smsToggleReadOnly}
          name="ordering-notification-settings-sms-toggle"
          data-test="ordering-notification-settings-sms-toggle"
        />
      )}
    </HStack>
  )
}

function EmailToggle(props: ToggleProps) {
  const { formatMessage } = useLocales()

  const content = <Toggle {...props} name="ordering-notif-settings-email-toggle" data-test="ordering-notification-settings-email-toggle" />

  if (props.disabled) {
    return (
      <Tooltip
        alignment="bottom"
        displayAction="hover"
        content={<span>{formatMessage(orderingNotificationSettingsMessages.disabledToggleTooltipMessage)}</span>}
        data-test="ordering-notification-settings-email-tooltip"
      >
        {content}
      </Tooltip>
    )
  }
  return content
}
