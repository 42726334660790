import React, { PureComponent } from 'react'
import { CallToAction, Check, NegativeButton, Button, HigherModal, Error, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import { convert, checkConnection, disconnect, transition, ProviderState } from '../actions/EmailServiceProviderActions'

export class MailchimpSection extends PureComponent {
  constructor(props) {
    super(props)
    this.url_base = 'mailchimp'
    this.connected_var = 'mailchimp_connected'
    this.state_var = 'mailchimp_connection'
    this.service_type = 'emailserviceproviders'
    this.state = { [this.state_var]: ProviderState.WORKING, error: undefined }
  }

  componentDidMount() {
    checkConnection(this)
  }

  render() {
    switch (this.state.mailchimp_connection) {
      case ProviderState.DISCONNECTED:
        return (
          <span>
            <CallToAction>Link an existing Mailchimp account</CallToAction>
            <Button className="mailchimp-auth-button" onClick={() => transition(this, ProviderState.CONNECTING)}>
              Authenticate
            </Button>
          </span>
        )

      case ProviderState.POST_CONNECTING:
        return (
          <span>
            <CallToAction>Link an existing Mailchimp account</CallToAction>
            <NegativeButton className="mailchimp-auth-button">Authenticate</NegativeButton>
            <HigherModal
              title="Success"
              content="You have successfully authenticated your Mailchimp account with SevenRooms. Next, head to the clients tab to send a list to Mailchimp."
              visibility
              action={() => transition(this, ProviderState.CONNECTED)}
              actionText="Close"
            />
          </span>
        )

      case ProviderState.CONNECTING:
        const form = (
          <span>
            <span>
              <CallToAction>Authenticate Mailchimp and SevenRooms integration</CallToAction>
              <Error>{this.state.error}</Error>
              <TextInput value={this.state.api_key} name="api_key" placeholder="Api Key" onChange={api_key => this.setState({ api_key })} />
            </span>
            <SubDesc>
              <a href="https://sevenrooms.zendesk.com/hc/en-us/articles/360031171611" target="_blank" rel="noreferrer">
                What is this field?
              </a>
            </SubDesc>
          </span>
        )

        return (
          <span>
            <CallToAction>Link an existing Mailchimp account</CallToAction>
            <NegativeButton className="mailchimp-auth-button">Authenticate</NegativeButton>
            <HigherModal
              toggler={() => transition(this, ProviderState.DISCONNECTED)}
              action={() => this.sendCredentials()}
              actionText="Confirm"
              title="MAILCHIMP INTEGRATION"
              content={form}
              visibility
            />
          </span>
        )

      case ProviderState.DISCONNECTING:
        return (
          <span>
            <CallToAction>
              <Check src={`${this.props.media_url}images/check2x.png`} />
              Mailchimp account successfully linked
            </CallToAction>
            <NegativeButton className="mailchimp-disconnect-button">Disconnect Mailchimp</NegativeButton>
            <HigherModal
              toggler={() => transition(this, ProviderState.CONNECTED)}
              action={() => disconnect(this)}
              actionText="Confirm"
              title="Are you sure?"
              content="Disconnecting Mailchimp from SevenRooms will prevent users from sending clients to Mailchimp."
              visibility
            />
          </span>
        )

      case ProviderState.CANT_CONNECT:
        return (
          <span>
            <Button
              onClick={() => {
                window.location.href =
                  'https://sevenrooms.com/en/7x-marketing-automation-guest-engagement/?&channel=EspProductPageMailchimpButton&utm_source=espProductPage&utm_medium=display&utm_campaign=MailchimpbtnNon7X+july2019'
              }}
              className="mailchimp-sizzle-only-button"
            >
              Book a 7X demo
            </Button>
            <SubDesc>
              <a href="https://mailchimp.com/marketing-platform/" target="_blank" rel="noreferrer">
                Learn more about Mailchimp
              </a>
            </SubDesc>
          </span>
        )
      case ProviderState.CONNECTED:
        return (
          <span>
            <CallToAction>
              <Check src={`${this.props.media_url}images/check2x.png`} />
              Mailchimp account successfully linked
            </CallToAction>
            <NegativeButton className="mailchimp-disconnect-button" onClick={() => transition(this, ProviderState.DISCONNECTING)}>
              Disconnect Mailchimp
            </NegativeButton>
          </span>
        )
      case ProviderState.WORKING:
        return <CallToAction className="mailchimp-working-button">Working....</CallToAction>
      case ProviderState.ERROR:
        return <CallToAction>Error communicating with 7r</CallToAction>
      default:
        return null
    }
  }

  sendCredentials() {
    const data = new FormData()
    data.append('api_key', this.state.api_key)

    fetch(`/manager/${this.props.venue_id}/emailserviceproviders/${this.url_base}/connect`, {
      method: 'POST',
      body: data,
    })
      .then(convert)
      .then(r => {
        if (r.success) {
          transition(this, ProviderState.POST_CONNECTING)
        } else {
          this.setState({ error: 'Invalid credentials' })
        }
      })
      .catch(r => this.setState({ error: 'Error communicating with 7r' }))
  }
}
