import { useLocales } from '@sevenrooms/core/locales'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { formatParagraph, type DetailProps, SCHEDULING_FEEDBACK_SUMMARY_EMAIL_INSTRUCTIONS_URL } from '../../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { FeedbackSummaryEmailEnabledSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'
import { FeatureEnablementProofSection } from '../../../Proofs'

export function FeedbackSummaryEmailEnabledDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { insight } = data
  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={formatMessage(insightsMessages.feedbackSummaryEmailEnabledSlideoutDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
        link: (chunks: string[]) => (
          <Link to={SCHEDULING_FEEDBACK_SUMMARY_EMAIL_INSTRUCTIONS_URL} target="_blank">
            {chunks}
          </Link>
        ),
      })}
      proofLabel={formatMessage(insightsMessages.feedbackSummaryEmailEnabledProofLabel)}
      suggestedActionsComponent={<FeedbackSummaryEmailEnabledSuggestedActions />}
      proofComponent={
        <FeatureEnablementProofSection
          title={formatMessage(insightsMessages.feedbackSummaryEmailEnabledProofTitle)}
          lastCheckedDate={insight.createdDatetime}
        />
      }
    />
  )
}
