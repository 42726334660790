import { srDelete, srGet, srPost, srPut, throwFetchError } from '@sevenrooms/core/api'

export const fetchPodConfigurations = params =>
  srGet(`/api-yoa/pod_configs`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const tryAddPodCategory = params =>
  srPost(`/api-yoa/pod_category`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const tryEditPodCategory = params =>
  srPut(`/api-yoa/pod_category`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const tryDeletePodCategory = params =>
  srDelete(`/api-yoa/pod_category`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const tryAddPodToVenue = params =>
  srPost(`/api-yoa/pod`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const tryRemovePodFromVenue = params =>
  srDelete(`/api-yoa/pod`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)
