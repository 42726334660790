import styled from 'styled-components'

const FormField = styled.div`
  padding-bottom: 12px;
  &:last-child {
    padding-bottom: 0;
  }
`

export default FormField
