/* eslint-disable camelcase */
import { adaptOrderingMenuFromApi } from 'mgr/lib/services/OrderingMenusServices/adapters/adaptOrderingMenu'
import { srDelete, srGet, srPostJson, srPut, throwFetchError } from '@sevenrooms/core/api'
import {
  adaptPacingRuleFromApi,
  adaptOrderingSiteOptionFromApi,
  adaptPacingRuleToApiOnCreate,
  adaptPacingRuleToApiOnEdit,
} from './adapters/adaptPacingRule'
import type { TimeRange, OrderingMenuApi } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { ORDER_METHOD_TYPE } from 'mgr/pages/single-venue/settings/types/ordering/Order.types'
import type { PacingRule, PacingRuleApi, OrderingSiteOptionApi } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'

export const getPacingRuleListByVenueId = (venueId: string) =>
  srGet<{ ordering_pacing_rules: PacingRuleApi[] }>(
    `/api-yoa/ordering/pacing_rules/${venueId}`,
    {},
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  )
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      pacingRules: response.data.ordering_pacing_rules.map(pacingRule => adaptPacingRuleFromApi(pacingRule)),
    }))

export const getPacingRuleById = async (venueId: string, pacingRuleId: string) =>
  srGet<{ ordering_pacing_rule: PacingRuleApi }>(
    `/api-yoa/ordering/pacing_rules/${venueId}/${pacingRuleId}`,
    {},
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  )
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      pacingRule: adaptPacingRuleFromApi(response.data.ordering_pacing_rule),
    }))

export const savePacingRule = async (venueId: string, pacingRuleId: string, pacingRuleData: PacingRule) =>
  srPut(`/api-yoa/ordering/pacing_rules/${venueId}/${pacingRuleId}`, adaptPacingRuleToApiOnEdit(pacingRuleData), {
    json: true,
  }).then(throwFetchError)

export const createPacingRule = async (venueId: string, pacingRuleData: Omit<PacingRule, 'id'>) =>
  srPostJson(`/api-yoa/ordering/pacing_rules/${venueId}`, adaptPacingRuleToApiOnCreate(pacingRuleData)).then(throwFetchError)

export const deletePacingRule = async (venueId: string, pacingRuleId: string) =>
  srDelete(`/api-yoa/ordering/pacing_rules/${venueId}/${pacingRuleId}`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true }).then(
    throwFetchError
  )

export const getPacingRuleResources = async (venueId: string) =>
  srGet<{
    fulfillment_methods: ORDER_METHOD_TYPE[]
    default_menu_hours: TimeRange
    ordering_sites: OrderingSiteOptionApi[]
    menus: OrderingMenuApi[]
  }>(
    `/api-yoa/ordering/pacing_rule_resources/${venueId}`,
    {},
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  )
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      fulfillmentMethods: response.data.fulfillment_methods,
      defaultMenuHours: response.data.default_menu_hours,
      orderingSites: response.data.ordering_sites.map(orderingSite => adaptOrderingSiteOptionFromApi(orderingSite)),
      menus: response.data.menus.map(menu => adaptOrderingMenuFromApi(menu)),
    }))
