import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'

export interface DeliveryRadiusUnitProps {
  value: string
  onChange: (value: string) => void
}

function DeliveryRadiusUnit({ value, onChange }: DeliveryRadiusUnitProps) {
  return (
    <DropdownArrowsPicker
      testId="sr-picker-delivery-radius-unit"
      choices={[
        { name: 'miles', value: 'mi' },
        { name: 'kilometers', value: 'km' },
      ]}
      isLightTheme
      useOutsideLabel
      value={value}
      onChangeHandler={onChange}
    />
  )
}

export default DeliveryRadiusUnit
