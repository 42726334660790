import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import GridResRow from './GridResRow'
import GridTableCell from './GridTableCell'
import GridUnassignedCell from './GridUnassignedCell'

const scrollBarHeightMask = 15
const GridSectionStyles = {
  body: {
    marginTop: 5,
    marginBottom: 5,
  },
  tableItems: {
    position: 'absolute',
    zIndex: 45,
  },
  gridBlocksContainer: {
    overflow: 'hidden',
    position: 'relative',
    top: -scrollBarHeightMask,
  },
  gridBlocks: {
    display: 'block',
    marginLeft: GridStyles.TableCellWidth,
    overflowX: 'scroll',
    overflowY: 'hidden',
    position: 'relative',
    top: scrollBarHeightMask,
    paddingBottom: scrollBarHeightMask,
  },
}

export class GridSection extends PureComponent {
  constructor(props) {
    super(props)
    this.gridPanelRef = React.createRef()
  }

  componentDidMount() {
    const gridPanel = ReactDOM.findDOMNode(this.gridPanelRef.current)
    gridPanel.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    const gridPanel = ReactDOM.findDOMNode(this.gridPanelRef.current)
    gridPanel.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = ev => {
    if (typeof this.props.onScrollHandler !== 'function') {
      return
    }
    this.props.onScrollHandler(ev.target.scrollLeft, this.props.sectionId)
  }

  sortTables(tables, isAscending) {
    if (isAscending) {
      return tables
    }
    return [...tables].reverse()
  }

  render() {
    const sortedTables = this.sortTables(this.props.tables, this.props.tableSortIsAscending)
    const moveModeAdjust = this.props.isActualMoveMode ? { overflowX: 'hidden' } : {}

    return (
      <div style={GridSectionStyles.body}>
        <div data-test="sr-section-tables" style={GridSectionStyles.tableItems}>
          {sortedTables.map((table, idx) => (
            <GridTableCell key={table.item_code} table={table} />
          ))}
        </div>
        <div style={GridSectionStyles.gridBlocksContainer}>
          <div
            ref={this.gridPanelRef}
            className="scrollSync"
            data-section-id={this.props.sectionId}
            style={[GridSectionStyles.gridBlocks, moveModeAdjust]}
          >
            {sortedTables.map((table, idx) => (
              <GridResRow
                key={`${table.item_code}_row`}
                rowEntity={table}
                sortOrders={this.props.sortOrders}
                gridActions={this.props.gridActions}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

GridSection.defaultProps = {
  sectionId: '',
  tables: [],
  sortOrders: [],
  intervalOrder: 1,
  onScrollHandler: null,
}

GridSection = Radium(GridSection)

const mapStateToProps = state => ({
  tableSortIsAscending: state.gridState.tableSortIsAscending,
  isActualMoveMode: state.gridState.isActualMoveMode,
})

GridSection = connect(mapStateToProps)(GridSection)

export class GridUnassignedSection extends PureComponent {
  constructor(props) {
    super(props)
    this.gridUnassignedPanelRef = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const gridPanel = ReactDOM.findDOMNode(this.gridUnassignedPanelRef.current)
    gridPanel.addEventListener('scroll', this.handleScroll)
    gridPanel.scrollLeft = this.props.initialScroll
  }

  componentWillUnmount() {
    const gridPanel = ReactDOM.findDOMNode(this.gridUnassignedPanelRef.current)
    gridPanel.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(ev) {
    if (typeof this.props.onScrollHandler !== 'function') {
      return
    }
    this.props.onScrollHandler(ev.target.scrollLeft, this.props.sectionId)
  }

  render() {
    const moveModeAdjust = this.props.isActualMoveMode ? { overflowX: 'hidden' } : {}
    return (
      <div style={GridSectionStyles.body}>
        <div style={GridSectionStyles.tableItems}>
          {this.props.guestSizes.map((numGuests, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <GridUnassignedCell key={idx} numGuests={numGuests} />
          ))}
        </div>
        <div style={GridSectionStyles.gridBlocksContainer}>
          <div
            ref={this.gridUnassignedPanelRef}
            className="scrollSync"
            data-section-id={this.props.sectionId}
            style={[GridSectionStyles.gridBlocks, moveModeAdjust]}
          >
            {this.props.guestSizes.map((numGuests, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <GridResRow key={idx} rowEntity={idx} sortOrders={this.props.sortOrders} gridActions={this.props.gridActions} isUnassigned />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

GridUnassignedSection.defaultProps = {
  guestSizes: [],
  sortOrders: [],
  intervalOrder: 1,
  onScrollHandler: null,
  initialScroll: 0,
}

GridUnassignedSection = Radium(GridUnassignedSection)
GridUnassignedSection = connect(mapStateToProps)(GridUnassignedSection)
