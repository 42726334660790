import styled, { css } from 'styled-components'
import { CommonModal } from 'mgr/pages/single-venue/marketing/components/shared/InnerModal'
import { StyledVmsIconS } from 'svr/common/VmsIcons'
import { Col, ContainerFluid, Row } from 'svr/lib/styled-bootstrap-grid'

export const HigherModal = styled(CommonModal)`
  z-index: 20;
  width: 50%;
  left: 25%;
`

export const SettingsModal = styled(CommonModal)`
  z-index: 1001;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;

  .modal-top-bar {
    background: transparent;
  }

  .modal-cancel {
    color: ${props => props.theme.primary};
  }

  .modal-confirm {
    background: ${props => props.theme.primary};
  }
`

export const Screen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.greySecondary};
  opacity: 0.7;
  z-index: 1000;
`

export const AccessDenied = styled.div`
  color: white;
  background: ${props => props.theme.color.errorAccent};
`
export const Error = styled.div`
  color: ${props => props.theme.color.errorAccent};
`

export const SettingsContainer = styled.div`
  display: flex;
  background: white;
  flex: 1;
  flex-direction: row;
`

export const Contents = styled.div`
  flex: 1;
  overflow-y: auto;
  border-top: 1px solid #e1e1e1;
`

export const Check = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

export const Title = styled.h2`
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  font: 400 20px Oswald, sans-serif;
  height: 50px;
  line-height: 50px;
  margin: 0px;
  text-transform: uppercase;
  text-indent: 10px;
`

export const SubContainer = styled.div`
  margin-left: ${props => props.theme.gutter.triple};
`

export const Desc = styled.div`
  margin-top: 30px;
  font-size: 17px;
  font-weight: 400;
`

export const SubDesc = styled.div`
  font-size: 14px;
  clear: both;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const Provider = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 1px solid #dadada;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 950px;
  margin: 20px;
`

export const ProviderLogo = styled.div`
  border-right: 1px solid #dadada;
  width: 284px;
  height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const ProviderSection = styled.div`
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const Channel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 1px solid #dadada;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 950px;
  margin: 20px;
`

export const ChannelLogo = styled.div`
  border-right: 1px solid #dadada;
  width: 284px;
  height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const ChannelSection = styled.div`
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const ProviderCredentialsSection = styled.div`
  padding: 20px;
  align-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  display: flex;
`

export const CallToAction = styled.div`
  color: #999;
  padding-bottom: 20px;
`

export const Button = styled.div`
  width: 200px;
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 30px 10px 30px;
  background: #347baf;
  color: #fff;
  display: block;
  font-size: 14px;
  min-width: 70px;
  text-align: center;
`

export const NegativeButton = styled(Button)`
  background: #e1e1e1;
`

export const SizedImg = styled.img.attrs(props => ({
  src: `${window.globalInit.mediaUrl}images/manager/settings/${props.path}`,
  width: props.width,
  height: props.height,
}))`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`

export const OtherChannelBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 80px;
`

export const OtherChannelItem = styled.div`
  color: ${props => props.theme.color.neutral};
  margin-right: ${props => props.theme.gutter.triple};
  min-width: 38px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const OtherChannelImg = styled(SizedImg)``
OtherChannelImg.defaultProps = {
  width: 36,
  height: 36,
}

export const LargeChannelImg = styled(SizedImg)``
LargeChannelImg.defaultProps = {
  width: 60,
  height: 60,
}

export const BookingChannelContainer = styled.div`
  border: 1px solid #dadada;
  padding: 20px;
  width: 950px;
  margin: 20px;
`

export const FormContainer = styled(ContainerFluid).attrs({
  sidePadding: '20px',
})``

export const FieldCol = styled(Col).attrs({ sidePadding: '5px' })`
  padding-top: 5px;
  padding-bottom: 5px;
`

export const FieldRow = styled(Row)``

export const HorizontalLine = styled.div`
  border-bottom: 1px dashed ${props => props.theme.margin};
  margin: 10px 0;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => props.theme.gutter.standard};
`

export const HeaderTitle = styled.div`
  font-size: 22px;
  line-height: 60px;
  font-weight: 500;
  margin-left: ${props => props.theme.gutter.double};
  color: ${props => props.theme.navigationDark};
  flex: 1;
`

export const HeaderTimestamp = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color.neutral};
`

export const HeaderButtons = styled.div``

export const DeactivateButton = styled.button`
  ${props => props.theme.deleteButton};
  height: 40px;
  line-height: 40px;
  width: 86px;
  float: right;
`

export const EditButton = styled.button`
  ${props => props.theme.secondaryTealButton};
  height: 40px;
  line-height: 40px;
  width: 86px;
  float: right;
  margin-left: ${props => props.theme.gutter.standard};
`

export const SaveDraftButton = styled.button`
  ${props => props.theme.secondaryTealButton};
  font-size: ${props => props.theme.fontSize.caption};
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  padding: 0 ${props => props.theme.gutter.double};
  margin-left: ${props => props.theme.gutter.double};
  float: right;
`

export const SaveButton = styled.button`
  ${props => props.theme.primaryTealButton};
  font-size: ${props => props.theme.fontSize.caption};
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  padding: 0 ${props => props.theme.gutter.double};
  margin-left: ${props => props.theme.gutter.double};
  float: right;
`

export const CancelButton = styled.button`
  ${props => props.theme.secondaryTealButton};
  font-size: ${props => props.theme.fontSize.caption};
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  color: ${props => props.theme.color.neutral};
  border: none;
  padding: 0 ${props => props.theme.gutter.double};
  float: right;
`

export const CustomSaveButton = styled.div`
  ${props => props.theme.primaryButton};
  background: ${props => props.backgroundColor || props.theme.color.search};
  color: ${props => props.color || props.theme.color.white};
  height: ${props => props.height || '46px'};
  line-height: ${props => props.height || '46px'};
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
  width: ${props => props.width || 'auto'};
  border-radius: ${props => props.borderRadius || '4px'};
  text-align: ${props => props.textAlign || 'center'};
  font-size: ${props => props.fontSize || '15px'};
  transition: all 0.3s;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`

export const CustomCancelButton = styled.div`
  ${props => props.theme.primaryButton};
  background: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.color.greySecondary)};
  color: ${props => (props.color ? props.color : props.theme.color.black)};
  height: ${props => props.height || '46px'};
  line-height: ${props => props.height || '46px'};
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
  width: ${props => props.width || 'auto'};
  border-radius: ${props => props.borderRadius || '4px'};
  text-align: ${props => props.textAlign || 'center'};
  font-size: ${props => props.fontSize || '15px'};
  transition: all 0.3s;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`

export const ErrorDialogWrapper = styled.div`
  margin: ${props => props.theme.gutter.standard} 0;
  background-color: ${props => props.theme.error};
  box-shadow: 0 2px 4px 0 rgba(72, 72, 76, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  transition: all 100ms ease-in-out;
`

export const ErrorIcon = styled(StyledVmsIconS)`
  width: 38px;
  text-align: right;
  color: ${props => props.theme.white};
`

export const ErrorBody = styled.ul`
  flex-grow: 5;
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.fontSize.caption};
  font-weight: 500;
  line-height: 19px;
  margin: 0 15px !important;
  padding: 15px 0 !important;
  > li {
    list-style-position: inside;
  }
`

export const ErrorDismiss = styled(StyledVmsIconS)`
  width: 38px;
  padding: 15px 0;
  text-align: left;
  color: ${props => props.theme.white};
  cursor: pointer;
`
