export const RESERVATION_WIDGET = 'res_widget'
export const RESERVATION_CONFIRMATION_EMAIL = 'res_conf_email'
export const RESERVATION_CANCELLATION = 'res_cancel_email'
export const RESERVATION_UPDATE = 'res_update_email'
export const FEEDBACK_REQUEST_RESERVATION_EMAIL = 'feedback_req_res_email'
export const RESERVATION_REMINDER_EMAIL = 'res_reminder_email'
export const FEEDBACK_REQUEST_ORDER_EMAIL = 'feedback_req_order_email'
export const SUBSCRIPTION_CONFIRMATION_EMAIL = 'subscription_confirmation_email'
export const CONTINUED_MARKETING_SUBSCRIPTION_CONFIRMATION_EMAIL = 'continued_marketing_subscription_confirmation_email'
export const SUBSCRIPTION_OPT_IN = 'subscription_opt_in'
export const FEEDBACK_PAGE = 'feedback_page'
export const SUBSCRIPTION_SUCCESS_PAGE = 'subscription_success_page'
export const RECEIPT_PAGE = 'receipt_page'
export const PAYLINK_PAGE = 'paylink_page'
export const PAYLINK_REQUEST_EMAIL = 'paylink_req_email'
export const COMPLETED_TRANSACTION_EMAIL = 'paylink_complete_email'
export const SMS_RES = 'sms_res'
export const SMS_WAITLIST = 'sms_waitlist'
export const SMS_ORDER_MENU = 'sms_cop_menu'
export const RES_WIDGET_CANCEL_PAGE = 'res_cancel_page'
export const RES_WIDGET_CONFIRM_PAGE = 'res_confirm_page'
export const EMAIL_PREFERENCE_CENTER = 'email_preference_center'
export const ORDERING_SITE = 'ordering_site'
export const POLICY_TYPE_PAYMENT = 'PAYMENT'
export const POLICY_TYPE_BOOKING = 'BOOKING'
export const OTHER_POLICIES = 'other_policies'
export const WAITLIST_WIDGET = 'waitlist_widget'
export const EVENT_WIDGET = 'event_widget'
export const SUBSCRIPTION_WIDGET = 'subscription_widget'
export const SMS_ORDERING = 'sms_ordering'
export const GUESTLIST_EMAIL = 'guestlist_email'
export const REQUEST_EMAIL = 'request_email'
export const EVENT_CONFIRMATION_EMAIL = 'event_conf_email'
export const SMS_PRIORITY_ALERTS = 'sms_priority_alerts'
export const SMS_PRIVATE_LINE = 'sms_private_line'
export const EMAILS_PRIORITY_ALERTS = 'priority_alerts_email'
