import _ from 'lodash'
import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'
import StyleUtils from 'mgr/layout/StyleUtils'
import { dropDownTypes } from 'mgr/pages/shared/utils/Constants'
import { merge } from 'svr/common/SRUtils'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

class GridDropDown extends PureComponent {
  handleClickOutside() {
    this.props.onClickOutside()
  }

  render() {
    const styles = {
      dropDownContainer: {
        position: 'relative',
      },
      dropDownMenu: merge(
        {
          backgroundColor: 'white',
          position: 'absolute',
          width: 150,
          zIndex: '47',
          top: 33,
          left: -3,
        },
        StyleUtils.shadow('rgba(0,0,0,0.68)')
      ),
      dropDownItem: {
        width: 'auto',
        lineHeight: '40px',
        height: 40,
        textAlign: 'left',
        paddingLeft: 11,
        fontSize: '14px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#eee',
        },
      },
      icon: {
        fontSize: '16px',
        marginRight: 8,
      },
    }
    const dropdownOptions = []
    if (this.props.isBookable) {
      dropdownOptions.push(
        <div data-test="sr-button-add_reservation" key="addRes" style={styles.dropDownItem} onClick={this.props.onAddReservationClick}>
          <span style={merge(VmsIconSizes.small, styles.icon)}>{VmsIcons.BookAddLine}</span>
          Add reservation
        </div>
      )
    }
    if (this.props.isBookable && this.props.permissions.canAddBlocks && this.props.dropDownType === dropDownTypes.ADD_BLOCK) {
      dropdownOptions.push(
        <div data-test="sr-button-add_block" key="addBlock" style={styles.dropDownItem} onClick={this.props.onAddBlockClick}>
          <span style={merge(VmsIconSizes.small, styles.icon)}>{VmsIcons.BlockLine}</span>
          Add block
        </div>
      )
    }
    if (this.props.dropDownType === dropDownTypes.EDIT_BLOCK) {
      dropdownOptions.push(
        <div data-test="sr-button-edit_block" key="editBlock" style={styles.dropDownItem} onClick={this.props.onEditBlockClick}>
          <span style={merge(VmsIconSizes.small, styles.icon)}>{VmsIcons.BlockLine}</span>
          Edit block
        </div>
      )
    }
    if (this.props.dropDownType === dropDownTypes.EDIT_ACCESS_RULE && this.props.permissions.canAdministrateShiftsAccessRules) {
      dropdownOptions.push(
        <div
          data-test="sr-button-edit_access_rule"
          key="editAccessRule"
          style={styles.dropDownItem}
          onClick={this.props.onEditAccessRuleClick}
        >
          <span style={merge(VmsIconSizes.small, styles.icon)}>{VmsIcons.BlockLine}</span>
          Edit access rule
        </div>
      )
    }
    if (_.isEmpty(dropdownOptions)) {
      return null
    }

    return (
      <div data-test="sr-dropdown-grid_cell" style={styles.dropDownContainer}>
        <div style={styles.dropDownMenu}>{dropdownOptions}</div>
      </div>
    )
  }
}

GridDropDown = onClickOutside(Radium(GridDropDown))

export default GridDropDown
