import type { GoogleResAvailabilitySuggestedActions, GoogleReserveAvailabilityTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link, List } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../locales'
import { formatBold } from '../../../utils'

export interface GoogleReserveAvailabilitySuggestedActionsProps {
  insightSuggestedActionData: GoogleResAvailabilitySuggestedActions
  insightProofDataRecord: GoogleReserveAvailabilityTableRow
}

export function GoogleReserveAvailabilitySuggestedActions({
  insightSuggestedActionData,
  insightProofDataRecord,
}: GoogleReserveAvailabilitySuggestedActionsProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-google-reserve-availability-suggested-actions">
      <List variant="ordered" spacing="s">
        <Text type="li" data-test="insights-google-reserve-availability-suggested-actions-navigate-access-rules">
          <Link
            to={nav.href(routes.manager.manage.accessRules, {
              params: { venueKey: venue.urlKey },
            })}
            target="_blank"
            data-test="insights-google-reserve-availability-suggested-actions-access-rules-link"
          >
            {formatMessage(insightsMessages.suggestedActionNavigatetoAccessRulesText, {
              icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            })}
          </Link>
        </Text>
        <Text type="li" data-test="insights-google-reserve-availability-click-rule">
          {formatMessage(insightsMessages.suggestedActionGoogleReserveAvailabilityClickRuleStep, {
            accessRuleName: insightSuggestedActionData.accessRuleName,
          })}
        </Text>
        <Text type="li" data-test="insights-google-reserve-availability-edit-rule">
          {formatMessage(insightsMessages.suggestedActionGoogleReserveAvailabilityEditDaysStep, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        {insightProofDataRecord.audienceSuggestedStep && (
          <Text type="li" data-test="insights-google-reserve-add-audience">
            <Box mb="s">{formatMessage(insightsMessages.suggestedActionGoogleReserveAvailabilityAddAudienceStep)}</Box>
            <List variant="ordered" spacing="s" type="a">
              <Text type="li" data-test="insights-google-reserve-nav-to-audience">
                {formatMessage(insightsMessages.suggestedActionNavigateToAudienceStep, {
                  strong: (chunks: string[]) => formatBold(chunks),
                })}
              </Text>
              <Text type="li" data-test="insights-google-reserve-update-audience">
                {formatMessage(insightsMessages.suggestedActionGoogleReserveAvailabilityUpdateAudienceStep, {
                  strong: (chunks: string[]) => formatBold(chunks),
                })}
              </Text>
            </List>
          </Text>
        )}
        {insightProofDataRecord.publicDescriptionSuggestedStep && (
          <Text type="li" data-test="insights-google-reserve-update-guest-facing-display">
            <Box mb="s">{formatMessage(insightsMessages.suggestedActionUpdateGuestFacingDisplayStep)}</Box>
            <List variant="ordered" type="a">
              <Text type="li" data-test="insights-google-reserve-nav-to-guest-facing-display">
                {formatMessage(insightsMessages.suggestedActionNavigateToGuestFacingDisplayStep, {
                  strong: (chunks: string[]) => formatBold(chunks),
                })}
              </Text>
              <Text type="li" data-test="insights-google-reserve-allow-guest-facing-display">
                {formatMessage(insightsMessages.suggestedActionAllowGuestFacingDisplayStep, {
                  strong: (chunks: string[]) => formatBold(chunks),
                })}
              </Text>
              <Text type="li" data-test="insights-google-reserve-nav-to-seating-areas">
                <Box mb="s">
                  {formatMessage(insightsMessages.suggestedActionNavigateToSeatingAreasStep, {
                    strong: (chunks: string[]) => formatBold(chunks),
                  })}
                </Box>
                <List variant="ordered" type="i">
                  <Text type="li" data-test="insights-google-select-seating-area-option">
                    {formatMessage(insightsMessages.suggestedActionGoogleReserveAvailabilitySelectSeatingAreOptionStep, {
                      strong: (chunks: string[]) => formatBold(chunks),
                    })}
                  </Text>
                </List>
              </Text>
            </List>
          </Text>
        )}
        <Text type="li" data-test="insights-google-reserve-save">
          {formatMessage(insightsMessages.suggestedActionsSaveChangesStep, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
      </List>
    </VStack>
  )
}
