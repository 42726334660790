import { useMemo } from 'react'
import { TagDuplicateWarningModalLocales } from 'mgr/pages/venue-group/containers/tags/TagDuplicateWarningModalLocales'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack, Window, Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'

interface Tag {
  name: string
  isDeleted?: boolean
  isNew?: string
}

interface TagCategory {
  name: string
  // eslint-disable-next-line camelcase
  name_display: string
  tags: Tag[]
  // eslint-disable-next-line camelcase
  tag_name_displays: { [key: string]: string }
  // eslint-disable-next-line camelcase
  is_pod_tag: boolean
  // eslint-disable-next-line camelcase
  pod_ids: string[]
}

interface ExistingTagCategory {
  name: string
  // eslint-disable-next-line camelcase
  name_display: string
  tags: string[]
  // eslint-disable-next-line camelcase
  tag_name_displays: { [key: string]: string }
  // eslint-disable-next-line camelcase
  is_pod_tag: boolean
  // eslint-disable-next-line camelcase
  pod_ids: string[]
}

interface TagDuplicateWarningModalProps {
  isActive: boolean
  setIsActive(status: boolean): {}
  onSubmit(): {}
  tagCategory: TagCategory
  tagCategories: ExistingTagCategory[]
}

interface HasDuplicatesProps {
  tagCategory: TagCategory
  tagCategories: ExistingTagCategory[]
}

interface GetActualTagsProps {
  tagCategory: TagCategory
}

interface GetActualCategoriesProps {
  tagCategory: TagCategory
  tagCategories: ExistingTagCategory[]
}

function getActualTags({ tagCategory }: GetActualTagsProps) {
  return tagCategory.tags
    .filter(tag => !tag.isDeleted) // Exclude deleted tags
    .filter(tag => tag.isNew) // Exclude existing tags for existing categories
}

function getActualCategories({ tagCategory, tagCategories }: GetActualCategoriesProps) {
  const filteredTagCategories = tagCategories.filter(category => category.name !== tagCategory.name)

  if (tagCategory.is_pod_tag) {
    // Exclude categories with different pods
    return filteredTagCategories.filter(
      category => (category.is_pod_tag && category.pod_ids.some(podId => tagCategory.pod_ids.includes(podId))) || !category.is_pod_tag
    )
  }

  return filteredTagCategories
}

export function hasDuplicates({ tagCategory, tagCategories }: HasDuplicatesProps) {
  return getActualTags({ tagCategory }).some(tag =>
    getActualCategories({ tagCategory, tagCategories })
      .flatMap(tc => tc.tags.map(t => tc.tag_name_displays[t] || t))
      .includes(tagCategory.tag_name_displays[tag.name.trim()] || tag.name.trim())
  )
}

export function TagDuplicateWarningModal({ isActive, setIsActive, onSubmit, tagCategory, tagCategories }: TagDuplicateWarningModalProps) {
  const { formatMessage } = useLocales()
  const items = useMemo(
    () =>
      getActualTags({ tagCategory })
        ?.map(tag => ({
          tag: tag.name,
          categories: getActualCategories({ tagCategory, tagCategories })
            .filter(category => {
              const tagCategoryTags = category.tags.map(t => category.tag_name_displays[t] || t)
              return tagCategoryTags.includes(tag.name.trim())
            })
            .map(category => category.name_display || category.name),
        }))
        .filter(tag => tag.categories.length),
    [tagCategories, tagCategory]
  )

  return (
    <Window active={isActive}>
      <Modal ariaLabel="TagDuplicateWarningModal">
        <ModalHeader onClose={() => setIsActive(false)}>
          <VStack spacing="s">
            <Status kind="warning">{formatMessage(commonMessages.warning)}</Status>
            <ModalTitle title={formatMessage(TagDuplicateWarningModalLocales.title)} />
          </VStack>
        </ModalHeader>
        <ModalBody>
          {items?.map(({ tag, categories }) => (
            <>
              <Text key={tag}>
                {formatMessage(TagDuplicateWarningModalLocales.item, {
                  tag: tag.trim(),
                  categories: categories.map(i => `"${i}"`).join(', '),
                  count: categories.length,
                })}
              </Text>
              <br key={`tag-${tag}`} />
            </>
          ))}
          <br />
          <Text>{formatMessage(TagDuplicateWarningModalLocales.description, { count: items.length })}</Text>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button data-test="duplicate-warning-cancel-button" variant="tertiary" onClick={() => setIsActive(false)}>
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button data-test="duplicate-warning-create-anyway-button" variant="primary" onClick={onSubmit}>
              {formatMessage(commonMessages.createAnyway)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
