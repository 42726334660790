import { useCallback, useState, useEffect } from 'react'
import { useSaveInsightMutation } from '@sevenrooms/core/api'
import type { InsightSaveParameters } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { MultiSelect } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../locales'

export interface FollowersProps {
  insightId: string
  insightFollowerUserIds: string[]
  userOptions: { id: string; label: string }[]
}

export function InsightFollowers({ insightId, insightFollowerUserIds, userOptions }: FollowersProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [saveInsight] = useSaveInsightMutation()
  const [followerUserIds, setFollowerUserIds] = useState<string[] | undefined>()

  useEffect(() => {
    if (insightFollowerUserIds !== followerUserIds) {
      setFollowerUserIds(insightFollowerUserIds ?? undefined)
    }
  }, [followerUserIds, insightFollowerUserIds])

  const updateUser = useCallback(
    (value: string[]) => {
      const insightParameters: InsightSaveParameters = {
        followerUserIds: value,
      }
      saveInsight({ venueId, insightId, insightParameters })
        .unwrap()
        .then(() => {
          setFollowerUserIds(value ?? undefined)
          notify({ content: formatMessage(insightsMessages.insightSaveSuccessMessage), type: 'success', autoClose: 1000 })
        })
        .catch(() => {
          notify({ content: formatMessage(insightsMessages.insightSaveErrorMessage), type: 'error' })
        })
    },
    [setFollowerUserIds, formatMessage, saveInsight, venueId, insightId]
  )

  return insightFollowerUserIds === followerUserIds ? (
    <VStack spacing="s" data-test="insight-followers-section" width="60%">
      <Text fontWeight="bold">{formatMessage(insightsMessages.followersLabel)}</Text>
      <SecondaryText>{formatMessage(insightsMessages.followersDescription)}</SecondaryText>
      <Box>
        <MultiSelect
          searchable
          data-test="insight-followers-field"
          options={userOptions}
          initialSelectedIds={followerUserIds}
          value={followerUserIds}
          onChange={updateUser}
        />
      </Box>
    </VStack>
  ) : null
}
