import { useMemo } from 'react'
import type { CancellationFeeTableRow } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Spreadsheet, Box, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../locales'

export function AddCancellationFeeProofTable({ tableRows }: { tableRows: CancellationFeeTableRow[] }) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const columnWidth = 75
  const columns = useMemo<DataTableColumn<CancellationFeeTableRow>[]>(
    () => [
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.addCancellationFeeTableHeaderBookedCovers)}
          </Text>
        ),
        render: 'bookedCovers',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.addCancellationFeeTableHeaderCoversCancelled)}
          </Text>
        ),
        render: 'totalCoversCanceled',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.addCancellationFeeTableHeaderCancelNoShowAmount)}
          </Text>
        ),
        key: 'totalCancellationNoShowAmount',
        render: tableRow => FormatService.formatCurrency(tableRow.totalCancellationNoShowAmount, venue.currencyCode),
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.addCancellationFeeTableHeaderPotentialFee)}
          </Text>
        ),
        key: 'potentialCancellationFee',
        render: tableRow => FormatService.formatCurrency(tableRow.potentialCancellationFee, venue.currencyCode),
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.addCancellationFeeTableHeaderIncrementalFee)}
          </Text>
        ),
        key: 'potentialIncrementalCancellationFee',
        render: tableRow => FormatService.formatCurrency(tableRow.potentialIncrementalCancellationFee, venue.currencyCode),
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.addCancellationFeeTableHeaderSearchesWithoutSlot)}
          </Text>
        ),
        render: 'numberOfSearchesWithoutAnySlotSum',
        width: columnWidth,
        maxWidth: columnWidth,
      },
    ],
    [formatMessage, venue]
  )
  return (
    <Box overflowX="scroll" data-test="add-cancellation-fee-proof-grid">
      <Spreadsheet width="1040px" data={tableRows} columns={columns} noPagination />
    </Box>
  )
}
