import React, { createRef } from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import App from 'mgr/pages/App'
import { mountWarningModal } from 'mgr/pages/components/WarningModal'
import * as ReviewActions from 'mgr/pages/single-venue/marketing/actions/Reviews'
import * as OutgoingEmailActions from 'mgr/pages/single-venue/reporting/actions/OutgoingEmails'
import ShowOutgoingEmail from 'mgr/pages/single-venue/reporting/containers/ShowOutgoingEmail'
import { Header } from './components/Header'
import { store } from './reduxStore'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'

// Subscribe before initialize gets called, so that we're guaranteed to process user domain venues
Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(() => store.dispatch(GlobalActions.userDomainVenuesLoaded()))
Pmp.Manager.Global.subscribeOnUserDomainVenueGroupsLoad(() => store.dispatch(GlobalActions.userDomainVenueGroupsLoaded()))

// Global init specified in reactloader.soy.
// contains user, access, and initial venue info
// TODO temporary workaround
if (document.getElementById('sevenrooms-main')) {
  const render = (AppComponent, _globalInit) => {
    ReactDOM.render(
      <Provider store={store}>
        <AppContainer>
          <AppComponent globalInit={_globalInit} />
        </AppContainer>
      </Provider>,
      document.getElementById('sevenrooms-main')
    )
  }

  render(App, globalInit)
}

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(App, globalInit)
  })
}

if (document.getElementById('sevenrooms-email-slideout')) {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <ShowOutgoingEmail />
      </ThemeProvider>
    </Provider>,
    document.getElementById('sevenrooms-email-slideout')
  )
}

export const initHeader = (domNodeId, activeVenueId, entityUrlSuffix, onVenuesMenuToggle) => {
  const headerRef = createRef()

  ReactDOM.render(
    <Header
      headerRef={headerRef}
      activeVenueId={activeVenueId}
      entityUrlSuffix={entityUrlSuffix}
      onVenuesMenuToggle={onVenuesMenuToggle}
    />,
    document.getElementById(domNodeId)
  )
  return headerRef
}

/* Export some functions that can be used by the ES5 world */
export const postSuccessMessage = message => {
  store.dispatch(GlobalActions.showSuccessMessage(message))
}
export const postErrorMessage = message => {
  store.dispatch(GlobalActions.showErrorMessage(message))
}
export const linkReviewDispatch = review => {
  store.dispatch(ReviewActions.linkReview(review))
}
export const unlinkReviewDispatch = review => {
  store.dispatch(ReviewActions.unlinkReview(review))
}
export const showEmailSlideout = email => {
  store.dispatch(OutgoingEmailActions.openOutgoingEmail(email))
}

svrExport('UserNotifications', 'postSuccessMessage', postSuccessMessage)
svrExport('UserNotifications', 'postErrorMessage', postErrorMessage)
svrExport('Components', 'mountWarningModal', mountWarningModal)
svrExport('OutgoingEmails', 'showEmailSlideout', showEmailSlideout)
svrExport('Reviews', 'linkReviewDispatch', linkReviewDispatch)
svrExport('Reviews', 'unlinkReviewDispatch', unlinkReviewDispatch)
svrExport('Header', 'container', initHeader)
