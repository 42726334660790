import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { type HTMLAttributes, useCallback } from 'react'
import styled from 'styled-components'
import type { TablePaginationProps } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gutter.double};
  padding-right: ${props => props.theme.gutter.quadruple};
  font-size: 14px;
  line-height: 18px;
`

const PerPageCountContainer = styled.span`
  margin-right: 48px;
`

const ItemsCountContainer = styled.span`
  margin-right: ${props => props.theme.gutter.double};
`
interface IconWrapperProps extends HTMLAttributes<HTMLElement> {
  isDisabled: boolean
}

const IconWrapper = styled.span<IconWrapperProps>`
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  color: ${props => (props.isDisabled ? props.theme.color.grey : props.theme.color.black)};
`

const PreviousIconWrapper = styled(IconWrapper)`
  margin-right: 25px;
`

const SizeOptionsSelect = styled.select`
  border: 0;
`

export function SettingsTablePagination({
  page,
  pages,
  data,
  pageSize,
  showPageSizeOptions,
  pageSizeOptions,
  onPageSizeChange,
  canPrevious,
  canNext,
  canNextFromData,
  onPageChange,
}: TablePaginationProps) {
  const getSafePage = useCallback(
    (sourcePage: number) => {
      let safePage = sourcePage
      if (_.isNaN(safePage)) {
        safePage = page
      }
      if (canNextFromData) {
        return safePage
      }
      return Math.min(Math.max(safePage, 0), pages - 1)
    },
    [canNextFromData, page, pages]
  )

  const changePage = useCallback(
    (newPage: number) => {
      const safePage = getSafePage(newPage)
      if (safePage !== page) {
        onPageChange(safePage)
      }
    },
    [getSafePage, onPageChange, page]
  )

  const onPreviousClick = useCallback(() => {
    if (canPrevious) {
      changePage(page - 1)
    }
  }, [canPrevious, changePage, page])

  const onNextClick = useCallback(() => {
    if (canNext) {
      changePage(page + 1)
    }
  }, [canNext, changePage, page])

  const pageNumberTitle = data.length
    ? `${page * pageSize + 1} - ${Math.min((page + 1) * pageSize, data.length)} of ${data.length} items`
    : '0 items'
  return (
    <PaginationContainer>
      <div>
        <PerPageCountContainer>Rows per page:</PerPageCountContainer>
        {showPageSizeOptions && (
          <SizeOptionsSelect onChange={e => onPageSizeChange(Number(e.target.value))} value={pageSize}>
            {pageSizeOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </SizeOptionsSelect>
        )}
      </div>
      <div>
        <ItemsCountContainer>
          <span>{pageNumberTitle}</span>
        </ItemsCountContainer>
        <PreviousIconWrapper onClick={onPreviousClick} isDisabled={!canPrevious}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </PreviousIconWrapper>
        <IconWrapper onClick={onNextClick} isDisabled={!canNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </IconWrapper>
      </div>
    </PaginationContainer>
  )
}
