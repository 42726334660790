import React from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/react-components/components/Button'
import { DialogActions } from '@sevenrooms/react-components/components/DialogActions'
import { DialogContent } from '@sevenrooms/react-components/components/DialogContent'
import { DialogContentText } from '@sevenrooms/react-components/components/DialogContentText'
import { inactiveCategoryMessages } from '../../../locales'

interface InactiveCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  venue: Venue
  time: TimeOnly
}

export function InactiveCategory({ reasons, venue, time }: InactiveCategoryProps) {
  const { formatMessage } = useLocales()

  // This category is a full stop category, so we are guaranteed to only have one element in the array
  const shiftReason = reasons[0]

  let mainDescriptionText = ''
  let buttonText = ''
  let buttonLink = ''
  switch (shiftReason?.reason) {
    case 'PAST_TIME':
      mainDescriptionText = formatMessage(inactiveCategoryMessages.mainDescriptionPastTime)
      break
    case 'NO_SHIFT':
      mainDescriptionText = formatMessage(inactiveCategoryMessages.mainDescriptionNoShift, { time: time.formatSTime() })
      buttonText = formatMessage(inactiveCategoryMessages.noShiftButtonText)
      buttonLink = `/manager/${venue.urlKey}/manage/capacity/schedule`
      break
    default:
      break
  }
  return (
    <DialogContent
      sx={{
        backgroundColor: '#F7F7F7',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 3,
        mb: 3,
        mx: 3,
        borderRadius: '4px',
        '&.MuiDialogContent-root': {
          p: 2,
        },
      }}
      data-test="inactive-category-content"
    >
      <DialogContentText id="inactive-category-modal-description">{mainDescriptionText}</DialogContentText>
      {buttonText && (
        <DialogActions sx={{ p: 0 }}>
          <Button
            data-test="inactive-category-button"
            size="small"
            variant="contained"
            onClick={() => window.open(`${buttonLink}`, '_blank')}
          >
            {buttonText}
          </Button>
        </DialogActions>
      )}
    </DialogContent>
  )
}
