import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Button, NegativeButton } from 'mgr/pages/single-venue/settings/components/shared'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'

export const ReadmeFields = styled.div`
  padding-bottom: 20px;
`

export const LightSpeedButton = styled(Button)`
  float: left;
  margin-right: 10px;
`
export const NegativeLightSpeedButton = styled(NegativeButton)`
  float: left;
  margin-right: 10px;
`

export class LightspeedSection extends PureComponent {
  static propTypes = {
    venue: React.PropTypes.object.isRequired,
    isLightspeedConnected: React.PropTypes.bool,
    handleLightspeedTestSubmit: React.PropTypes.func,
  }

  renderButton() {
    const integrated = this.props.isLightspeedConnected
    const startAuthenticationLabel = 'Start Authentication'
    const againAuthenticateLabel = 'Authenticate Again'
    return (
      <LightSpeedButton
        onClick={() => {
          window.location.href = `/lightspeed-oauth2-start/${this.props.venue.id}`
        }}
      >
        {integrated ? againAuthenticateLabel : startAuthenticationLabel}
      </LightSpeedButton>
    )
  }

  renderTestConnectionButton() {
    const testConnection = 'Test Connection'
    return <LightSpeedButton onClick={this.props.handleLightspeedTestSubmit}>{testConnection}</LightSpeedButton>
  }

  render() {
    const integrated = this.props.isLightspeedConnected
    return (
      <div>
        {integrated && (
          <div>
            Status: <strong>Authenticated</strong>
          </div>
        )}
        {this.renderButton()}
        {integrated && this.renderTestConnectionButton()}
      </div>
    )
  }
}
