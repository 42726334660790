import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'
import ManagerStyles from 'mgr/layout/Styles'
import StyleUtils from 'mgr/layout/StyleUtils'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

class GridCollapsibleSection extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isCollapsed: props.isCollapsed }
  }

  handleToggleCollapseClick() {
    this.setState({ isCollapsed: !this.state.isCollapsed })
  }

  render() {
    const styles = {
      body: {
        width: 'auto',
        marginTop: GridStyles.SectionsTopMargin,
        marginLeft: GridStyles.SectionsLeftMargin,
        marginBottom: -30,
      },
      titleBar: merge(
        {
          height: 38,
          lineHeight: '38px',
          width: 'auto',
          backgroundColor: ManagerStyles.PrimaryHeaderColor,
          paddingLeft: 17,
          cursor: 'pointer',
          color: 'white',
        },
        StyleUtils.rounded('6px 0 0 0')
      ),
      icon: {
        display: 'inline-block',
        fontSize: 23,
        fontWeight: 100,
        verticalAlign: 'sub',
      },
      chevron: {
        transform: 'rotate(90deg)',
      },
      title: {
        display: 'inline-block',
        fontSize: 13,
        fontWeight: 600,
      },
      stats: {
        display: 'inline-block',
        fontSize: 13,
        marginLeft: 10,
      },
      collapsed: {
        transform: 'rotate(-90deg)',
      },
    }
    const iconStyle = merge(VmsIconSizes.small, styles.icon)
    const chevronStyle = merge(iconStyle, styles.chevron, this.state.isCollapsed ? styles.collapsed : {})
    const bodyAdjust = this.state.isCollapsed ? merge(styles.body, { marginBottom: 0 }) : styles.body
    const toolTipId = `id-${this.props.title}`
    const titleBarStyles = this.props.backgroundColor
      ? merge(styles.titleBar, { backgroundColor: this.props.backgroundColor })
      : styles.titleBar

    return (
      <div style={bodyAdjust}>
        <div data-test="sr-section-seating_area" style={titleBarStyles} onClick={e => this.handleToggleCollapseClick()}>
          <div style={chevronStyle}>{VmsIcons.Chevron}</div>
          <div data-test="sr-label-seating_area" style={styles.title}>
            {this.props.title}
          </div>
          {this.props.popoverText && (
            <div style={{ display: 'inline-block' }}>
              <ReactTooltip html id={toolTipId} effect="solid" />
              <div data-for={toolTipId} data-tip={this.props.popoverText} style={merge(iconStyle, { transform: 'scale(0.75)' })}>
                {VmsIcons.Info}
              </div>
            </div>
          )}
          <div data-test="sr-label-covers_and_reservations" style={styles.stats}>
            {this.props.numCovers} ({this.props.numReservations})
          </div>
        </div>
        <div className={this.state.isCollapsed ? 'noDisplay' : ''}>{this.props.gridSection}</div>
      </div>
    )
  }
}

GridCollapsibleSection.defaultProps = {
  title: 'UNASSIGNED',
  numCovers: 138,
  numReservations: 32,
  isCollapsed: false,
  gridSection: [],
  backgroundColor: null,
}

GridCollapsibleSection = Radium(GridCollapsibleSection)
export default GridCollapsibleSection
