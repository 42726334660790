import { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import type { Insight } from '@sevenrooms/core/domain'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Grid, Box, DividerLine, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useInsightsDashboardContext } from '../../hooks/useInsightsDashboardContext'
import { formatTitleCase } from '../../utils'
import { StatusIcon } from '../Status'

export interface InsightRowProps {
  insight: Insight
  insightAssignedUserName?: string
  isAssignedToCurrentUser: boolean
  insights: Insight[]
}

export function InsightRow({ insight, insightAssignedUserName, isAssignedToCurrentUser, insights }: InsightRowProps) {
  const { setSlideoutOpen, setBrowseStatus, setInsightId, setInsightList, insightId, queryId, setQueryId } = useInsightsDashboardContext()
  const insightList = useMemo(() => insights.filter(ist => ist.status === insight.status), [insight.status, insights])
  const [hoverId, setHoverId] = useState<string | null>(null)
  const rowRef = useRef<HTMLDivElement>(null)

  const openInsightDetail = useCallback(() => {
    setSlideoutOpen(true)
    setBrowseStatus(insight.status)
    setInsightId(insight.id)
    setInsightList(insightList)
  }, [setSlideoutOpen, setBrowseStatus, setInsightId, setInsightList, insight, insightList])

  useEffect(() => {
    if (queryId === insight.id) {
      setQueryId(null)
      openInsightDetail()
      if (rowRef.current) {
        rowRef.current.scrollIntoView({ block: 'center' })
      }
    }
  }, [queryId, insight, openInsightDetail, setQueryId])

  const highlightColor = insightId === insight.id || hoverId === insight.id ? 'secondaryBackground' : 'primaryBackground'

  return (
    <Box
      ref={rowRef}
      cursor="pointer"
      onClick={openInsightDetail}
      onMouseEnter={() => setHoverId(insight.id)}
      onMouseLeave={() => setHoverId(null)}
    >
      <DividerLine margin="none" />
      <Grid backgroundColor={highlightColor} gridTemplateColumns="auto 25% 30px" pt="sm" pb="sm" width="100%" alignItems="center">
        <HStack pl="m" alignItems="center" justifyContent="space-between" gridColumn="1">
          <StatusIcon variant={insight.status} size="lg" />
          <Box mr="auto" ml="sm">
            <Text color="darkFont" fontSize="m">
              {insight.insightText}
            </Text>
          </Box>
        </HStack>
        {insight.status !== 'declined' && (
          <Box ml="sm" mr="sm" gridColumn="2">
            {insightAssignedUserName ? (
              <HStack alignItems="center">
                <Icon name="VMSWeb-account-circle" size="lg" color={isAssignedToCurrentUser ? 'primaryButton' : 'secondaryFont'} />
                <Box ml="sm">
                  <Text color="darkFont" fontSize="m">
                    {isAssignedToCurrentUser ? 'Me' : formatTitleCase(insightAssignedUserName)}
                  </Text>
                </Box>
              </HStack>
            ) : (
              <Text color="mutedFont">Unassigned</Text>
            )}
          </Box>
        )}
        <Box gridColumn="3">
          <Icon name="VMSWeb-chevron-right" size="lg" color="darkFont" />
        </Box>
      </Grid>
    </Box>
  )
}
