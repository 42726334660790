import Radium from 'radium'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import ManagerStyles from 'mgr/layout/Styles'
import { closeConflictDialogAction } from 'mgr/pages/single-venue/dayview/actions/Actions'
import Modal from 'svr/lib/Modal/Modal'

class GridConflictDialog extends PureComponent {
  handleActionClick = () => {
    if (this.props.nextAction) {
      this.props.actions.performNextAction(this.props.nextAction)
      this.props.actions.closeConflictDialogSimple()
    }
  }

  handleCancelClick = () => {
    this.props.actions.closeConflictDialog(this.props.closeAction)
  }

  renderDialog() {
    const styles = {
      body: {
        backgroundColor: 'white',
        width: 350,
        textAlign: 'center',
      },
      iconContainer: {
        display: 'inline-block',
        border: '3px solid white',
        borderRadius: '33px',
        width: 60,
        height: 60,
      },
      icon: {
        paddingTop: 20,
        paddingBottom: 20,
        lineHeight: '63px',
        backgroundColor: ManagerStyles.WarningColor,
        color: 'white',
        fontSize: '50px',
      },
      contentContainer: {
        marginBottom: 20,
      },
      header: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: '16px',
        fontWeight: 600,
        color: ManagerStyles.WarningColor,
      },
      explanation: {
        color: '#676869',
        fontSize: '14px',
        lineHeight: '18px',
        paddingLeft: 10,
        paddingRight: 10,
      },
      detailContainer: {
        color: '#9A9B9C',
        marginTop: 15,
      },
      detailLine: {
        marginBottom: 5,
      },
      buttonContainer: {
        padding: 20,
      },
      actionButton: {
        margin: 'auto',
        backgroundColor: ManagerStyles.WarningColor,
        color: 'white',
        borderRadius: '4px',
        paddingTop: 10,
        paddingBottom: 10,
        width: '90%',
        fontSize: '14px',
        fontWeight: '600',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: ManagerStyles.ActiveWarningColor,
        },
      },
      cancelLink: {
        marginTop: 20,
        fontSize: '14px',
        fontWeight: '600',
        cursor: 'pointer',
        color: ManagerStyles.WarningColor,
        ':hover': {
          color: ManagerStyles.ActiveWarningColor,
        },
      },
    }
    return (
      <div style={styles.body}>
        <div style={styles.icon}>
          <span style={styles.iconContainer}>!</span>
        </div>
        <div style={styles.contentContainer}>
          <div style={styles.header}>ARE YOU SURE</div>
          <div style={styles.explanation}>{this.props.explanation}</div>
          {this.props.conflictActual ? (
            <div style={styles.detailContainer}>
              <div style={styles.detailLine}>{this.props.conflictActual.client_display_name}</div>
              <div style={styles.detailLine}>
                {this.props.conflictActual.max_guests} {this.props.conflictActual.max_guests == 1 ? 'guest' : 'guests'}
              </div>
              <div style={styles.detailLine}>{this.props.conflictActual.arrival_time_display}</div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div style={styles.buttonContainer}>
          <div key="_actionButton" style={styles.actionButton} onClick={this.handleActionClick}>
            {this.props.actionButtonText}
          </div>
          <div key="_cancelLink" style={styles.cancelLink} onClick={this.handleCancelClick}>
            Cancel
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Modal zIndex="500" body={this.renderDialog()} backgroundColor="#ccc" />
      </div>
    )
  }
}

GridConflictDialog = Radium(GridConflictDialog)

const mapDispatchToProps = dispatch => ({
  actions: {
    performNextAction: nextAction => {
      dispatch(nextAction)
    },
    closeConflictDialog: closeAction => {
      dispatch(closeAction)
    },
    closeConflictDialogSimple: () => {
      dispatch(closeConflictDialogAction())
    },
  },
})

GridConflictDialog = connect(() => ({}), mapDispatchToProps)(GridConflictDialog)

export default GridConflictDialog
