import React from 'react'
import TextInput, { ValidatorTypes } from 'mgr/lib/forms/TextInput'

export const businessNameField = ({ doordashIntegrationSettings, changeDoorDashField, formErrors, validateFieldMap }) => (
  <TextInput
    label="RESTAURANT NAME IN DOORDASH*"
    charLimit={300}
    validator={ValidatorTypes.notEmpty}
    ref={e => (validateFieldMap.business_name = e)}
    isValid={!formErrors.business_name}
    value={doordashIntegrationSettings.settings.business_name}
    onChange={v => changeDoorDashField('business_name', v)}
  />
)

export const pickupInstructionsField = ({ doordashIntegrationSettings, changeDoorDashField }) => (
  <TextInput
    label="PICKUP INSTRUCTIONS"
    charLimit={300}
    value={doordashIntegrationSettings.settings.pickup_instructions}
    onChange={v => changeDoorDashField('pickup_instructions', v)}
  />
)
