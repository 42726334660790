import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const computeOutgoingEmailsFiltersParams = state => ({
  filters: state.outgoingEmails.filters,
  total: state.outgoingEmails.data.total,
})

const computeOutgoingEmailsFiltersValues = ({ filters = {}, total = 0 }) => ({
  values: {
    date: filters.date,
    search: filters.search,
    page: filters.page,
    pageSize: filters.pageSize,
    pages: Math.ceil(total / filters.pageSize),
    sortKey: filters.sortKey,
    emailType: filters.emailType,
  },
})

export const selectOutgoingEmailsFiltersValues = createShallowEqualSelector(
  computeOutgoingEmailsFiltersParams,
  computeOutgoingEmailsFiltersValues
)
