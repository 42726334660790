import { type ReactNode, type PropsWithChildren, Children, type HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

const TitleContainer = styled.div`
  display: flex;
`

const SubCaption = styled.div`
  color: #6a6a6a;
  font-size: 12px;
  margin-top: 4px;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${props => props.theme.darkGrey};
  border-radius: 40px;
  text-align: center;
  background-color: transparent;
  transition: all 0.3s ease;
`

const IconContainer = styled.div<{ disableIconHover: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: ${props => props.theme.darkGrey};
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s ease;
    padding: ${props => props.theme.gutter.medium};
  }

  > ${Icon} {
    ${props =>
      !props.disableIconHover &&
      css`
        :hover {
          cursor: pointer;
          background-color: #e8f5ff;
      `}

      a {
        color: #347baf;
      }
    }
  }
`

const BlockContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple};
  border-radius: 6px;
  border: 1px solid ${props => props.theme.mediumGrey};
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.08px;
  line-height: 18px;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.black};
  box-sizing: border-box;
  margin-bottom: ${props => props.theme.gutter.medium};
`

const TextOverride = styled.div`
  color: ${props => props.theme.darkGrey};
  padding: ${props => props.theme.gutter.double} 0;
`

interface RowBlockProps extends HTMLAttributes<HTMLDivElement> {
  label?: string
  subCaption?: string
  upfront?: ReactNode
  id?: string
  overrideIcons?: boolean
  textOverride?: string
  disableIconHover?: boolean
}

function RowBlock({
  label,
  subCaption,
  upfront,
  id,
  overrideIcons = false,
  disableIconHover = false,
  textOverride = '',
  children,
  ...rest
}: PropsWithChildren<RowBlockProps>) {
  return (
    <BlockContainer id={id} {...rest}>
      <TitleContainer>
        {upfront}
        <div>
          <div>{label}</div>
          {subCaption && <SubCaption>{subCaption}</SubCaption>}
        </div>
      </TitleContainer>

      {!overrideIcons && (
        <IconContainer disableIconHover={disableIconHover}>
          {Children.map(children, child => (
            <Icon>{child}</Icon>
          ))}
        </IconContainer>
      )}

      {overrideIcons && <TextOverride>{textOverride}</TextOverride>}
    </BlockContainer>
  )
}

export default RowBlock
