import { InsightStatus } from '@sevenrooms/core/domain'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useInsightSuggestedActionsContext } from '../../hooks'
import {
  InsightNewSection,
  InsightDeclineReasonSection,
  InsightDeclinedSection,
  InsightTrackingSection,
  InsightChangeRequestedSection,
} from '../InsightSuggestedActionStatusSections'
import type { PropsWithChildren } from 'react'

export function InsightSuggestedActionsSection({ children }: PropsWithChildren<{}>) {
  const { displayDeclineReasonSection, isSaving, insight } = useInsightSuggestedActionsContext()

  if (isSaving) {
    return <Loader />
  }

  if (displayDeclineReasonSection) {
    return <InsightDeclineReasonSection />
  }

  switch (insight.status) {
    case InsightStatus.TRACKING:
      return insight.changeRequestedDatetime && !insight.resolvedDatetime ? (
        <InsightChangeRequestedSection>{children}</InsightChangeRequestedSection>
      ) : (
        <InsightTrackingSection />
      )
    case InsightStatus.DECLINED:
      return (
        <InsightDeclinedSection
          declinedReason={insight.declinedReason}
          declinedBy={insight.declinedBy}
          declinedDatetime={insight.declinedDatetime}
        />
      )
    case InsightStatus.NEW:
      return <InsightNewSection>{children}</InsightNewSection>
    default:
      return null
  }
}
