import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import DateRangePicker from 'mgr/pages/single-venue/marketing/components/shared/DateRangePicker'
import { dateFilters, emailTypeFilters, outgoingEmailsReleaseDate } from 'mgr/pages/single-venue/reporting/reducers/EmailFilters'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'

const FiltersContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const FiltersOnlyContainer = styled.div`
  display: flex;
`

export const DateFilter = ({
  choices,
  value = null,
  values = null,
  onChangeHandler,
  isMultiSelect,
  style,
  name = '',
  noLabel = false,
  showNavigation,
  displayOverride = null,
}) => (
  <DropdownArrowsPicker
    choices={choices}
    value={value}
    values={values}
    isLightTheme
    style={style}
    name={name}
    onChangeHandler={onChangeHandler}
    isMultiSelect={isMultiSelect}
    noLabel={noLabel}
    overrideUseOutsideLabel={false}
    showNavigation={showNavigation}
    displayOverride={displayOverride}
  />
)

export const EmailTypeFilter = ({
  choices,
  value = null,
  values = null,
  onChangeHandler,
  isMultiSelect,
  style,
  name = '',
  noLabel = false,
  showNavigation,
  displayOverride = null,
}) => (
  <DropdownArrowsPicker
    choices={choices}
    value={value}
    values={values}
    isLightTheme
    style={style}
    name={name}
    onChangeHandler={onChangeHandler}
    isMultiSelect={isMultiSelect}
    noLabel={noLabel}
    overrideUseOutsideLabel={false}
    showNavigation={showNavigation}
    displayOverride={displayOverride}
  />
)

class OutgoingEmailsFilter extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
    }

    this.onSearchChange = this.onSearchChange.bind(this)
    this.onDateFilterChange = this.onDateFilterChange.bind(this)
    this.onOutgoingEmailsFilterChange = this.onOutgoingEmailsFilterChange.bind(this)

    this.handleSearchDebounced = _.debounce(function () {
      this.props.onSearchFilterChange.apply(this, [this.state.search])
    }, 1200)
  }

  onDateFilterChange(value) {
    this.props.onDateFilterChange(value)
  }

  onOutgoingEmailsFilterChange(value) {
    this.props.onOutgoingEmailsFilterChange(value)
  }

  componentDidMount() {
    this.setState({ search: this.props.searchValue })
  }

  onSearchChange(value) {
    this.setState({ search: value })
    this.props.startLoading()
    this.handleSearchDebounced()
  }

  render() {
    const { dateValue, startDate, endDate, emailTypeValue, onCustomDateRangeChange } = this.props

    return (
      <FiltersContainer>
        <FiltersOnlyContainer>
          <DateFilter
            choices={dateFilters}
            value={dateValue}
            style={{ width: 'auto' }}
            onChangeHandler={this.onDateFilterChange}
            name="Date"
          />
          {dateValue !== 'all' && (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDateChange={onCustomDateRangeChange}
              hasMinDate
              customMinDate={outgoingEmailsReleaseDate}
            />
          )}
          <EmailTypeFilter
            choices={emailTypeFilters}
            value={emailTypeValue}
            style={{ width: '250px' }}
            onChangeHandler={this.onOutgoingEmailsFilterChange}
            name="Email Type"
          />
        </FiltersOnlyContainer>
        <TextInput
          placeholder="Search"
          value={this.state.search}
          onChange={this.onSearchChange}
          customInputWrapper={css`
            width: auto;
          `}
        />
      </FiltersContainer>
    )
  }
}

export default OutgoingEmailsFilter
