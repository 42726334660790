import _ from 'lodash'
import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isMajorOrder } from 'mgr/lib/utils/ShiftUtils'
import { getAccessByOrderFromGridMap } from 'mgr/pages/shared/utils/Access'
import {
  getMaxResOrder,
  getActualsByOrderFromGridMap,
  getUnassignedActuals,
  getUsedOrdersByTableFromGridMap,
} from 'mgr/pages/shared/utils/Actuals'
import { getBlocksByOrderFromGridMap } from 'mgr/pages/shared/utils/Blocks'
import { EMPTY_ARRAY } from 'mgr/pages/shared/utils/Constants'
import { addDropDownOpenedAction } from 'mgr/pages/single-venue/dayview/actions/Actions'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { isPastTime } from 'svr/common/TimeUtil'
import GridAccessCell from './GridAccessCell'
import GridBlockCell from './GridBlockCell'
import { GridCell } from './GridCell'
import GridMoveCell from './GridMoveCell'
import GridResCell from './GridResCell'

const RENDER_OFFSCREEN_NUM_ROWS = 5
const RENDER_OFFSCREEN_PX = RENDER_OFFSCREEN_NUM_ROWS * GridStyles.ReservationRowHeight

const GridResRowStyles = {
  row: {
    whiteSpace: 'nowrap',
    marginBottom: 4,
    height: GridStyles.ReservationRowHeight,
  },
  resBox: {
    display: 'inline-block',
    position: 'relative',
    backgroundColor: 'white',
    minWidth: GridStyles.TimeHeaderWidth,
    width: GridStyles.TimeHeaderWidth,
    height: GridStyles.ReservationRowHeight,
    marginLeft: GridStyles.MinorAxesBorderWidth,
  },
  majorOrder: {
    marginLeft: GridStyles.MajorAxesBorderWidth,
  },
  noClick: {
    backgroundColor: '#f7f7f7',
  },
}

class GridResRow extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { doRender: false }
    this.isScrollListenersBound = false
    this.handleWindowScroll = _.debounce(this.handleWindowScroll.bind(this), 25, { leading: false, trailing: true })
  }

  componentDidMount() {
    this.addScrollListeners()
    this.handleWindowScroll()
  }

  componentWillUnmount() {
    this.removeScrollListeners()
  }

  addScrollListeners() {
    if (this.isScrollListenersBound) {
      return
    }
    window.addEventListener('scroll', this.handleWindowScroll)
    window.addEventListener('resize', this.handleWindowScroll)
    this.isScrollListenersBound = true
  }

  removeScrollListeners() {
    if (!this.isScrollListenersBound) {
      return
    }
    window.removeEventListener('scroll', this.handleWindowScroll)
    window.removeEventListener('resize', this.handleWindowScroll)
    this.isScrollListenersBound = false
  }

  componentWillReceiveProps(nextProps) {
    const doRender = this.isRowVisible()
    this.setState({ doRender })
    if (doRender) {
      this.removeScrollListeners()
    } else {
      this.addScrollListeners()
    }
  }

  componentDidUpdate() {
    this.handleWindowScroll()
  }

  handleWindowScroll() {
    if (this.state.doRender) {
      return
    }
    if (this.isRowVisible()) {
      this.setState({ doRender: true })
      this.removeScrollListeners()
    }
  }

  isRowVisible() {
    if (!this.resRowElement) {
      return false
    }
    const rect = this.resRowElement.getBoundingClientRect()
    const elemTop = rect.top
    const elemBottom = rect.bottom
    return elemTop < window.innerHeight + RENDER_OFFSCREEN_PX && elemBottom >= -RENDER_OFFSCREEN_PX
  }

  wrappedIsPastTime(order) {
    return this.isPastTime(order)
  }

  isPastTime(order) {
    return isPastTime(order, this.props.venue.timezone, this.props.venue.startOfDayHour, this.props.date)
  }

  isBookable(order) {
    const usedInActual =
      this.props.actuals._usedOrders &&
      Object.keys(this.props.actuals._usedOrders).filter(usedOrder => parseInt(usedOrder) === order).length > 0
    return !(this.isPastTime(order) || order > _.last(this.props.sortOrders) || usedInActual)
  }

  getMovingActual(order) {
    if (!this.props.movingActual) {
      return null
    }
    const startOrder = parseInt(this.props.movingActual.arrival_time_sort_order)
    if (this.props.isActualMoveMode && order === startOrder) {
      return this.props.movingActual
    }
    return null
  }

  render() {
    const { minResOrder, maxResOrder } = this.props

    if (!this.state.doRender) {
      const width = (GridStyles.TimeHeaderWidth + GridStyles.MajorAxesBorderWidth) * (maxResOrder - minResOrder + 1)
      return <div style={[GridResRowStyles.row, { width }]} ref={e => (this.resRowElement = e)} />
    }
    const currentTime = moment().tz(this.props.venue.timezone)
    const timeRange = _.range(minResOrder, maxResOrder + 1)
    let overlappingBlockTuples = []
    let overlappingAccessTuples = []
    return (
      <div
        style={GridResRowStyles.row}
        ref={e => {
          this.resRowElement = e
          this.handleWindowScroll()
        }}
      >
        {timeRange.map((order, idx) => {
          const isBookable = this.isBookable(order)
          const appliedStyles = [GridResRowStyles.resBox]
          if (isMajorOrder(order, this.props.intervalOrder)) {
            appliedStyles.push(GridResRowStyles.majorOrder)
          }
          if (!isBookable) {
            appliedStyles.push(GridResRowStyles.noClick)
          }
          // Overlapping block tuples (decrement by one numOrder each iteration)
          overlappingBlockTuples = overlappingBlockTuples
            .map(({ blockTuple, remainingOrders }) => ({
              blockTuple,
              remainingOrders: remainingOrders - 1,
            }))
            .filter(({ remainingOrders }) => remainingOrders > 0)
          const orderBlockTuples = this.props.blockTuples[order] || EMPTY_ARRAY
          for (const blockTuple of orderBlockTuples) {
            overlappingBlockTuples.push({
              blockTuple,
              remainingOrders: blockTuple.durationOrders,
            })
          }
          // Overlapping access tuples (decrement by one numOrder each iteration)
          overlappingAccessTuples = overlappingAccessTuples
            .map(({ accessTuple, remainingOrders }) => ({
              accessTuple,
              remainingOrders: remainingOrders - 1,
            }))
            .filter(({ remainingOrders }) => remainingOrders > 0)
          const orderAccessTuples = this.props.accessTuples[order] || EMPTY_ARRAY
          for (const accessTuple of orderAccessTuples) {
            overlappingAccessTuples.push({
              accessTuple,
              remainingOrders: accessTuple.durationOrders,
            })
          }
          return (
            <div data-test="sr-grid_cell" key={order} style={appliedStyles}>
              <GridCellContents
                table={this.props.rowEntity}
                order={order}
                isUnassigned={this.props.isUnassigned}
                isBookable={isBookable}
                unassignedActuals={this.props.unassignedActuals}
                actuals={this.props.actuals[order] || EMPTY_ARRAY}
                usedOrders={this.props.usedOrders}
                blockTuples={orderBlockTuples}
                accessTuples={orderAccessTuples}
                gridActions={this.props.gridActions}
                venue={this.props.venue}
                movingActual={this.getMovingActual(order)}
                onAddDropDownOpened={this.props.actions.addDropDownOpened}
              />
              <GridCell
                table={this.props.rowEntity}
                order={order}
                isUnassigned={this.props.isUnassigned}
                blockTuples={overlappingBlockTuples.map(({ blockTuple }) => blockTuple)}
                accessTuples={overlappingAccessTuples.map(({ accessTuple }) => accessTuple)}
                onAddDropDownOpened={this.props.actions.addDropDownOpened}
                isBookable={isBookable}
                pastDate={moment(this.props.date).isBefore(moment(currentTime), 'day')}
                currentDate={moment(this.props.date).isSame(moment(currentTime), 'day')}
                isPastTime={order > 75 ? this.wrappedIsPastTime(order) : null}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

GridResRow = Radium(GridResRow)

const mapStateToProps = (state, ownProps) => ({
  venue: state.appState.venue,
  date: state.dayviewState.date,
  minResOrder: _.min(ownProps.sortOrders),
  maxResOrder: _.max(
    ownProps.sortOrders.concat([getMaxResOrder(state.gridState.gridActualsMapByShift, state.dayviewState.shiftPersistentId)])
  ),
  actuals: getActualsByOrderFromGridMap(state.gridState.gridActualsMapByShift, state.dayviewState.shiftPersistentId, ownProps.rowEntity.id),
  usedOrders: getUsedOrdersByTableFromGridMap(
    state.gridState.gridActualsMapByShift,
    state.dayviewState.shiftPersistentId,
    ownProps.rowEntity.id
  ),
  blockTuples: getBlocksByOrderFromGridMap(
    state.gridState.gridBlocksMapByShift,
    state.dayviewState.shiftPersistentId,
    ownProps.rowEntity.id
  ),
  accessTuples: getAccessByOrderFromGridMap(
    state.gridState.gridAccessMapByShift,
    state.dayviewState.shiftPersistentId,
    ownProps.rowEntity.id
  ),
  unassignedActuals: getUnassignedActuals(state.gridState.gridActualsMapByShift, state.dayviewState.shiftPersistentId),
  isActualMoveMode: state.gridState.isActualMoveMode,
  movingActual: state.gridState.movingActual,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addDropDownOpened: addDropDownOpenedAction,
    },
    dispatch
  ),
})

GridResRow = connect(mapStateToProps, mapDispatchToProps)(GridResRow)

export default GridResRow

class GridCellContents extends PureComponent {
  renderCell() {
    const { table, order, isUnassigned, isBookable } = this.props

    const { actuals } = this.props
    const { blockTuples } = this.props
    const { accessTuples } = this.props

    const cellChildren = []
    for (const actual of actuals) {
      // skip canceled actuals
      if (actual.is_canceled) {
        continue
      }

      cellChildren.push(
        <GridResCell
          key={actual.id}
          venueId={this.props.venue.id}
          actual={actuals[0]}
          rowEntity={table}
          order={order}
          usedOrders={this.props.usedOrders}
          gridActions={this.props.gridActions}
          isUnassigned={isUnassigned}
        />
      )
    }
    let idx = 0
    for (const blockTuple of blockTuples) {
      cellChildren.push(
        <GridBlockCell
          key={blockTuple.block.id + idx}
          block={blockTuple.block}
          durationOrders={blockTuple.durationOrders}
          actions={this.props.gridActions}
          order={order}
          table={table}
          isUnassigned={isUnassigned}
          isBookable={isBookable}
          onAddDropDownOpened={this.props.onAddDropDownOpened}
        />
      )
      idx += 1
    }
    idx = 0
    for (const accessTuple of accessTuples) {
      cellChildren.push(
        <GridAccessCell
          key={accessTuple.rule.id + idx}
          rule={accessTuple.rule}
          durationWidthInIntervals={accessTuple.durationIntervals}
          actions={this.props.gridActions}
          order={order}
          table={table}
          isUnassigned={isUnassigned}
          isBookable={isBookable}
          onAddDropDownOpened={this.props.onAddDropDownOpened}
        />
      )
      idx += 1
    }
    return cellChildren
  }

  renderUnassignedCell() {
    const idx = this.props.table
    const { order } = this.props
    // find actual by unassigned index and order
    const actuals = this.props.unassignedActuals
    if (idx < actuals.length && parseInt(actuals[idx].arrival_time_sort_order) === order) {
      return (
        <GridResCell
          venueId={this.props.venue.id}
          actual={actuals[idx]}
          rowEntity={idx}
          order={order}
          gridActions={this.props.gridActions}
          isUnassigned={this.props.isUnassigned}
        />
      )
    }
    return <></>
  }

  renderActualMoveMode() {
    return (
      <GridMoveCell
        key={`${this.props.movingActual.id}_move`}
        actual={this.props.movingActual}
        table={this.props.table}
        isUnassigned={this.props.isUnassigned}
      />
    )
  }

  render() {
    return (
      <div data-test="sr-grid_cell_contents" data-test-bookable={this.props.isBookable}>
        {this.props.isUnassigned ? this.renderUnassignedCell() : this.renderCell()}
        {this.props.movingActual ? this.renderActualMoveMode() : ''}
      </div>
    )
  }
}
