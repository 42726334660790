import { useLocales } from '@sevenrooms/core/locales'
import { DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../locales'
import type { PropsWithChildren } from 'react'

export function DetailSupportingData({ children, proofLabel }: PropsWithChildren<{ proofLabel?: string }>) {
  const { formatMessage } = useLocales()

  return (
    <>
      <DividerLine mt="lm" mb="lm" />
      <Text type="p" textStyle="h2">
        {formatMessage(insightsMessages.supportingDataLabel)}
      </Text>
      {proofLabel && <Text type="p">{proofLabel}</Text>}
      {children}
    </>
  )
}
