import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import history from 'mgr/pages/shared/utils/History'

export const onChangeVenueExitEditFlow = (venueNew, routes) => dispatch => {
  const urlSplit = routes[0].route.split('/')
  dispatch(tryChangeVenue(urlSplit, venueNew))
}

export const onChangeVenue = venueNew => dispatch => {
  const urlSplit = history.location.pathname.split('/')
  dispatch(tryChangeVenue(urlSplit, venueNew))
}

const tryChangeVenue = (urlSplit, venueNew) => dispatch => {
  // eslint-disable-next-line no-param-reassign
  urlSplit[2] = venueNew.urlKey
  history.push(urlSplit.join('/'))
  dispatch(GlobalActions.onChangeVenueIntent(venueNew))
}
