import { useLocales } from '@sevenrooms/core/locales'
import { Box, Grid, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../locales'
import { variantTitle, variantColor } from '../../utils/insightStatusUtils'

export interface StatusHeaderProps {
  variant?: string
  count: number
}

export function StatusHeader({ variant = 'new', count }: StatusHeaderProps) {
  const { formatMessage } = useLocales()
  const newHeader = count > 1 ? insightsMessages.newInsightsHeaderText : insightsMessages.newInsightHeaderText
  const message = variant === 'new' ? newHeader : variantTitle[variant]
  const text = message ? formatMessage(message) : ''
  const color = variantColor[variant]

  return (
    <>
      <Grid gridTemplateColumns="auto 25% 30px" pl="xxs" mt="lm" mb="sm" ml="none">
        <HStack>
          <Box p="none" display="inline-block" borderRadius="s" mr="sm" minWidth="17px" textAlign="center" backgroundColor={color}>
            <Text fontSize="xs" fontWeight="bold" color="primaryBackground">
              {count}
            </Text>
          </Box>
          <Text fontSize="m" fontWeight={500}>
            {text}
          </Text>
        </HStack>
        {variant === 'new' && (
          <Box ml="sm" mr="sm">
            <Text fontSize="m" fontWeight={500}>
              Assigned To
            </Text>
          </Box>
        )}
      </Grid>
    </>
  )
}
