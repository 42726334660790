import { useCallback, useState } from 'react'

export const useToggle: (defaultChecked: boolean, callback?: (checked: boolean) => void) => [checked: boolean, onToggle: () => void] = (
  defaultChecked: boolean,
  callback?: (checked: boolean) => void
): [checked: boolean, onToggle: () => void] => {
  const [checked, updateChecked] = useState(defaultChecked)
  const onToggle = useCallback(() => {
    updateChecked(!checked)
    callback?.(!checked)
  }, [callback, checked])
  return [checked, onToggle]
}
