import type { AvailabilityDebuggerReason } from '@sevenrooms/core/domain'

// logic duplicated and brought into the world of js from sr/model/tableitem.py
// not as simple as it is in python, but it works the same
export function compareTableItems(a: AvailabilityDebuggerReason, b: AvailabilityDebuggerReason): number {
  function keyTableItemCode(itemCode: string): (string | [number, string])[] {
    return itemCode.split(/(\d+)/).map(s => (/\d/.test(s) ? [parseInt(s, 10), s] : s.toLowerCase()))
  }

  const tableItemCodeA = a.data?.tableItemCode || ''
  const tableItemCodeB = b.data?.tableItemCode || ''

  const keyA = keyTableItemCode(tableItemCodeA)
  const keyB = keyTableItemCode(tableItemCodeB)

  for (let i = 0; i < Math.max(keyA.length, keyB.length); i += 1) {
    const partA = keyA[i]
    const partB = keyB[i]

    if (partA === undefined) {
      return -1
    }
    if (partB === undefined) {
      return 1
    }

    if (Array.isArray(partA) && Array.isArray(partB)) {
      const numCompare = partA[0] - partB[0]
      if (numCompare !== 0) {
        return numCompare > 0 ? 1 : -1
      }
    } else if (Array.isArray(partA)) {
      return 1
    } else if (Array.isArray(partB)) {
      return -1
    } else {
      const strCompare = partA.localeCompare(partB)
      if (strCompare !== 0) {
        return strCompare
      }
    }
  }

  return 0
}
