import type { CancellationFeeTableRow, ShiftSuggestedActions } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../locales'
import { formatParagraph, type DetailProps } from '../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { AddCancellationFeeSuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { AddCancellationFeeProofTable } from '../../Proofs'

export function AddCancellationFeeDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { suggestedActionsData, insightProofData } = data
  const tableRows = (insightProofData?.[0]?.tableRows as CancellationFeeTableRow[]) ?? []

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.reduceCancellationsTitle)}
      description={formatMessage(insightsMessages.addCancellationFeeText, {
        p: (chunks: string[]) => formatParagraph(chunks),
      })}
      proofLabel={formatMessage(insightsMessages.addCancellationFeeProofLabel)}
      suggestedActionsComponent={
        <AddCancellationFeeSuggestedActions
          venueUrlKey={venue.urlKey}
          insightSuggestedActionData={suggestedActionsData as ShiftSuggestedActions}
        />
      }
      proofComponent={<AddCancellationFeeProofTable tableRows={tableRows} data-test="add-cancellation-fee-proof-table" />}
    />
  )
}
