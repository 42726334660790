import React, { useMemo, useState } from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly, DateOnly } from '@sevenrooms/core/timepiece'
import { AccordionDetails } from '@sevenrooms/react-components/components/AccordionDetails'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { combosMatchCategoryMessages } from '../../../locales'
import { AccordionContent } from './AccordionContent'
import { compareTableItems } from './tablesCategoryUtil'
import { getBlocksUrl } from './urlRedirectUtil'

interface CombosMatchCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  defaultExpanded?: boolean
  time: TimeOnly
  date: DateOnly
  venue: Venue
}

interface CombosMatchDetailsProps {
  reason: string
  reasons: AvailabilityDebuggerReason[]
  time: TimeOnly
  date: DateOnly
  venue: Venue
}

interface CombosRuleContentProps {
  formattedHeader: string
  formattedTableDetails: JSX.Element[]
}

interface CombosMatchGroupedReasons {
  START_TIME_BLOCK: AvailabilityDebuggerReason[]
  BLOCKED_AT_TIME: AvailabilityDebuggerReason[]
  NOT_ALL_AVAILABLE: AvailabilityDebuggerReason[]
}

function CombosRuleContent({ formattedHeader, formattedTableDetails }: CombosRuleContentProps) {
  return (
    <Box sx={{ '&:not(:last-child)': { pb: 2 } }}>
      <Typography fontWeight="medium">{formattedHeader}</Typography>
      {formattedTableDetails}
    </Box>
  )
}

function CombosMatchDetails({ reason, reasons, time, venue, date }: CombosMatchDetailsProps) {
  const { formatMessage } = useLocales()

  let formattedHeader: string
  let formattedTableDetails: JSX.Element[]

  switch (reason) {
    case 'START_TIME_BLOCK':
      formattedHeader = formatMessage(combosMatchCategoryMessages.mainDescriptionHeaderStartTimeBlock, {
        count: reasons.length,
        numCombos: reasons.length,
        time: time.formatSTime(),
      })
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(combosMatchCategoryMessages.mainDescriptionStartTimeBlock, {
            combo: r.data?.tableItemCodes,
            blockName: r.data?.blockNames?.join(', '),
            a: (chunks: string[]) => (
              <Link
                data-test="table-combination-start-time-block"
                href={getBlocksUrl(venue, date, r.data?.shiftCategory as string)}
                target="_blank"
              >
                {chunks}
              </Link>
            ),
          })}
        </Typography>
      ))
      return <CombosRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'BLOCKED_AT_TIME':
      formattedHeader = formatMessage(combosMatchCategoryMessages.mainDescriptionHeaderBlockedAtTime, {
        count: reasons.length,
        numCombos: reasons.length,
        time: time.formatSTime(),
      })
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(combosMatchCategoryMessages.mainDescriptionBlockedAtTime, {
            combo: r.data?.tableItemCodes,
            blockName: r.data?.blockNames?.join(', '),
            a: (chunks: string[]) => (
              <Link
                data-test="table-combination-blocked-at-time"
                href={getBlocksUrl(venue, date, r.data?.shiftCategory as string)}
                target="_blank"
              >
                {chunks}
              </Link>
            ),
          })}
        </Typography>
      ))
      return <CombosRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'NOT_ALL_AVAILABLE':
      formattedHeader = formatMessage(combosMatchCategoryMessages.mainDescriptionHeaderNotAllAvailable)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(combosMatchCategoryMessages.mainDescriptionNotAllAvailable, {
            combo: r.data?.tableItemCodes,
          })}
        </Typography>
      ))
      return <CombosRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    default:
      return <></>
  }
}

function groupCombosByReason(reasons: AvailabilityDebuggerReason[]) {
  const groupedReasons: CombosMatchGroupedReasons = {
    START_TIME_BLOCK: [],
    BLOCKED_AT_TIME: [],
    NOT_ALL_AVAILABLE: [],
  }

  let numCombosAffected = 0

  // group table reasons together and de-dupe and table item code reasons
  reasons.sort(compareTableItems).forEach(r => {
    const reason = r.reason as keyof CombosMatchGroupedReasons
    if (reason in groupedReasons) {
      if (groupedReasons[reason].length > 0) {
        const tc = r.data?.tableItemCodes
        if (!groupedReasons[reason].some(r => r.data?.tableItemCodes === tc)) {
          groupedReasons[reason].push(r)
          numCombosAffected += 1
        }
      } else {
        groupedReasons[reason].push(r)
        numCombosAffected += 1
      }
    }
  })

  return { groupedReasons, numCombosAffected }
}

export function CombosMatchCategory({ reasons, defaultExpanded = true, time, date, venue }: CombosMatchCategoryProps) {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  const { formatMessage } = useLocales()
  const { groupedReasons, numCombosAffected } = useMemo(() => groupCombosByReason(reasons), [reasons])
  const headerMessage = formatMessage(combosMatchCategoryMessages.accordianHeader, {
    count: numCombosAffected,
    numCombos: numCombosAffected,
  })

  if (numCombosAffected === 0) {
    return <></>
  }

  return (
    <AccordionContent
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      headerMessage={headerMessage}
      dataTestId="combos-match-category-content"
      actionButtonEnabled
      actionButtonProps={{
        label: formatMessage(combosMatchCategoryMessages.manageCombinationsButtonText),
        url: `/manager/${venue.urlKey}/manage/table_combos`,
      }}
    >
      <AccordionDetails id="combos-match-panel-content">
        {Object.entries(groupedReasons)
          .filter(([, reasons]) => reasons.length > 0)
          .map(([reason, reasons]) => (
            <CombosMatchDetails key={`${reason}`} reason={reason} reasons={reasons} time={time} date={date} venue={venue} />
          ))}
      </AccordionDetails>
    </AccordionContent>
  )
}
