// Consider using TypeScript
/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import ButtonIcon from 'svr/component-lib/Generic/Buttons/ButtonIcon'
import { ORDER_METHOD_TITLE } from 'svr/constants'
import { Toggle } from '@sevenrooms/core/ui-kit/core'

const RootContainer = styled.div`
  min-height: 60px;
  border-radius: 6px;
  border: 1px solid #dbdcde;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  > * {
    margin-right: 8px;
  }
`

const Amount = styled.div`
  color: #9a9b9c;
  font-size: 12px;
  margin-top: 4px;
`

const Title = styled.div`
  font-size: 15px;
  font-weight: 400;
`

const getAmountTitle = ({ fulfillmentMethods }) => {
  const titles = fulfillmentMethods
    .map(method => ORDER_METHOD_TITLE[method].toLowerCase())
    .sort()
    .reverse()
    .join(' and ')
  return `For ${titles}`
}

const displayAmount = (amount, currencySymbol) => `${currencySymbol}${Math.floor(amount * 10) / 1000}`

const getAmount = (feeItem, currencySymbol) => {
  if (feeItem.isPassThroughThirdParty) {
    return 'Pass through from delivery integration'
  }
  const fees = []
  if (feeItem.amountFraction) {
    fees.push(`${feeItem.amountFraction}%`)
  }
  if (feeItem.amountFixed) {
    fees.push(displayAmount(feeItem.amountFixed, currencySymbol))
  }
  if (feeItem.distanceTiers.length) {
    fees.push(feeItem.distanceTiers.map(dt => displayAmount(dt.amount, currencySymbol)).join(', '))
    if (feeItem.costPerExtraDistanceUnit) {
      fees.push(`${displayAmount(feeItem.costPerExtraDistanceUnit, currencySymbol)} per ${feeItem.distanceUnit}`)
    }
  }
  return fees.join(' + ')
}

const CheckoutFeeItem = ({ feeItem, onEditItem, onToggleItem, currencySymbol }) => {
  const { id, name } = feeItem

  return (
    <RootContainer>
      <div data-test="service-fee-details">
        <Title>{name}</Title>
        <Amount>
          {getAmountTitle(feeItem)} | {getAmount(feeItem, currencySymbol)}
        </Amount>
      </div>
      <Controls>
        <ButtonIcon
          data-test="service-fee-edit-button"
          onClick={() => {
            onEditItem(id)
          }}
          icon={ButtonIcon.ICONS.EditPencil}
        />
        <Toggle data-test="service-fee-active-toggle" checked={feeItem.isEnabled} onChange={() => onToggleItem(id)} />
      </Controls>
    </RootContainer>
  )
}

export default CheckoutFeeItem
