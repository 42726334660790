import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import * as BookingChannelActions from 'mgr/pages/single-venue/settings/actions/BookingChannelActions'
import {
  Desc,
  SubDesc,
  Contents,
  SubContainer,
  OtherChannelBar,
  OtherChannelItem,
  OtherChannelImg,
} from 'mgr/pages/single-venue/settings/components/shared'
import DisableScreen from 'svr/component-lib/Manager/DisableScreen'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import ResyBookingChannelSettings from './bookingchannelsettings/ResyBookingChannelSettings'

const BOOKING_CHANNEL_SETTINGS = [ResyBookingChannelSettings]

const OTHER_BOOKING_CHANNELS = [
  ['Google', 'Google_Logo.png'],
  ['OpenTable', 'OT_Logo.png'],
  ['Facebook', 'FB_Logo.png'],
  ['Instagram', 'IG_Logo.png'],
  ['Bookatable', 'Bookatable_Logo.png'],
  ['Reserveout', 'Reserveout_Logo.png'],
  ['Zomato', 'Zomato_Logo.png'],
  ['TripAdvisor', 'TripAdvisor_Logo.png'],
  ['eat', 'eat_Logo.png'],
]

class BookingChannels extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    const { tryLoadBookingChannelSettings } = this.props.actions
    tryLoadBookingChannelSettings()
  }

  render() {
    const { venue, venues, onChangeVenue, isLoading } = this.props
    return (
      <ContentLayout title="Booking Channels" venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
        <Contents>
          <SubContainer>
            <Desc>Booking Channels</Desc>
            <SubDesc>Use this page to configure and learn more about SevenRooms Booking Channels.</SubDesc>
            {BOOKING_CHANNEL_SETTINGS.map((ChannelSettings, index) => (
              <ChannelSettings key={index} />
            ))}
          </SubContainer>
          <SubContainer>
            <Desc>Other Booking Channels</Desc>
            <SubDesc>Reach out to your Customer Success Manager to learn more about adding additional channels.</SubDesc>
            <OtherChannelBar>
              {OTHER_BOOKING_CHANNELS.map(([channelName, channelLogo]) => (
                <OtherChannelItem key={channelName}>
                  <OtherChannelImg path={`integrations/${channelLogo}`} />
                  <div>{channelName}</div>
                </OtherChannelItem>
              ))}
            </OtherChannelBar>
          </SubContainer>
          {isLoading && <DisableScreen />}
        </Contents>
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    venue,
    venues,
    isLoading: state.bookingChannels.isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      tryLoadBookingChannelSettings: BookingChannelActions.tryLoadBookingChannelSettings,
      onChangeVenue,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingChannels)
