import styled from 'styled-components'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import type { MenuCategory } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface ConfirmMenuCategoryDeleteModalProps {
  category: MenuCategory
  onConfirm: (categoryName: string) => void
  onDiscard: () => void
}

export function ConfirmMenuCategoryDeleteModal({ category, onConfirm, onDiscard }: ConfirmMenuCategoryDeleteModalProps) {
  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onDiscard()}>
        <VStack spacing="s">
          <Status kind="error">Warning</Status>
          <ModalTitle title={`Are you sure you want to delete the category: ${category.name}?`} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <TextContent>Deleting this category will also remove all {category.items.length} menu items from this menu.</TextContent>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={() => onDiscard()} data-test="sr-menu-builder-discard-delete">
            Cancel
          </Button>
          <Button variant="primary-warning" onClick={() => onConfirm(category.name)} data-test="sr-menu-builder-confirm-delete">
            Delete
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}

const TextContent = styled(Text)`
  white-space: nowrap;
`
