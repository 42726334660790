import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import CheckoutFeeItemForm from 'mgr/pages/single-venue/settings/components/ordering/CheckoutFee/CheckoutFeeItemForm/CheckoutFeeItemForm'
import CheckoutFeeList from 'mgr/pages/single-venue/settings/components/ordering/CheckoutFee/CheckoutFeeList'
import { useBoolToggle } from 'svr/common/hooks/useBoolToggle'
import StandardButton from 'svr/component-lib/Generic/Buttons/StandardButton'
import { Blocker } from 'svr/component-lib/Generic/Loading'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import { useCheckoutFee } from './useCheckoutFee'

const Header = styled.div`
  display: flex;
  margin-bottom: 16px;

  > *:last-child {
    margin-left: auto;
  }
`

const MainContent = styled(Blocker)`
  background: white;
`

const SubTitle = styled.div`
  color: #6a6a6a;
  font-size: 12px;
`

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
`
const Texts = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`

const Layout = styled.div`
  padding: 24px;
`

const CheckoutFee = () => {
  const {
    isLoading,
    venue,
    venues,
    checkoutFees,
    orderingSites,
    onChangeVenue,
    addNewFee,
    removeFeeById,
    updateFeeById,
    updateEnableById,
    taxGroups,
    deliveryPartnerKey,
  } = useCheckoutFee()

  const [selectedFeeId, updateSelectedFeeId] = useState('')
  const [isModalVisible, toggleModalVisible] = useState(false)
  const { currencySymbol } = venue

  const selectedFeeItem = selectedFeeId ? checkoutFees.find(item => item.id === selectedFeeId) : null

  const isNewFee = !selectedFeeItem

  const [isFormVisible, switchFormVisible] = useBoolToggle()

  const onSubmit = useCallback(
    fields => {
      switchFormVisible(false)
      if (isNewFee) {
        addNewFee(fields)
      } else {
        updateFeeById(selectedFeeId, fields)
      }
    },
    [selectedFeeId]
  )

  const onEditItem = id => {
    updateSelectedFeeId(id)
    switchFormVisible(true)
  }

  const onToggleItem = id => {
    updateEnableById(id, checkoutFees)
  }

  const onDeleteConfirm = () => {
    toggleModalVisible(true)
  }

  const onDelete = () => {
    toggleModalVisible(false)
    switchFormVisible(false)
    removeFeeById(selectedFeeId)
  }

  return (
    <ContentLayout title="Service Fees" venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
      <MainContent isActive={isLoading}>
        <Layout>
          <Header>
            <Texts>
              <Title>Service Fees</Title>
              <SubTitle>The service fee name will be the name displayed in the checkout</SubTitle>
            </Texts>
            <StandardButton
              skin={StandardButton.SKINS.VMS_PRIMARY}
              onClick={() => {
                updateSelectedFeeId('')
                switchFormVisible(true)
              }}
              data-test="sr-save-button"
            >
              Create New Service Fee
            </StandardButton>
          </Header>
          <CheckoutFeeList
            checkoutFees={checkoutFees}
            onEditItem={onEditItem}
            onToggleItem={onToggleItem}
            currencySymbol={currencySymbol}
          />
        </Layout>
      </MainContent>
      {isFormVisible && (
        <CheckoutFeeItemForm
          isNew={isNewFee}
          isOpen={isFormVisible}
          onClose={switchFormVisible}
          orderingSites={orderingSites}
          onSubmit={onSubmit}
          onDelete={onDeleteConfirm}
          taxGroups={taxGroups}
          feeItem={selectedFeeItem}
          currencySymbol={currencySymbol}
          deliveryPartnerKey={deliveryPartnerKey}
        />
      )}
      {isModalVisible && (
        <MessageBox
          handleActionClick={onDelete}
          handleCloseClick={() => {
            toggleModalVisible(false)
          }}
          dialogType={MessageBox.DialogType.WARNING}
          header="Warning"
          explain="Warning"
          details={[]}
          explanation="Are you sure that you want to delete this Service Fee?"
          actionButtonText="Delete"
        />
      )}
    </ContentLayout>
  )
}

export default CheckoutFee
