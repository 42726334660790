import React, { useMemo, useState } from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { AccordionDetails } from '@sevenrooms/react-components/components/AccordionDetails'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { tablesTargetingCategoryMessages } from '../../../locales'
import { AccordionContent } from './AccordionContent'
import { compareTableItems } from './tablesCategoryUtil'

interface TablesTargetingCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  defaultExpanded?: boolean
  venue: Venue
}

interface TablesTargetingDetailsProps {
  reason: string
  reasons: AvailabilityDebuggerReason[]
}

interface TablesRuleContentProps {
  formattedHeader: string
  formattedTableDetails: JSX.Element[]
}

interface TablesTargetingGroupedReasons {
  NO_TABLES: AvailabilityDebuggerReason[]
  NOT_FOUND_IN_SHIFT_LAYOUT: AvailabilityDebuggerReason[]
  NOT_IN_SEATING_AREA: AvailabilityDebuggerReason[]
  NO_ACCESS_RULES: AvailabilityDebuggerReason[]
  NO_PACING_COVERS_REMAINING: AvailabilityDebuggerReason[]
  PARTY_SIZE_MISMATCH: AvailabilityDebuggerReason[]
}

function TablesRuleContent({ formattedHeader, formattedTableDetails }: TablesRuleContentProps) {
  return (
    <Box sx={{ '&:not(:last-child)': { pb: 2 } }}>
      <Typography fontWeight="medium">{formattedHeader}</Typography>
      {formattedTableDetails}
    </Box>
  )
}

function TablesTargetingDetails({ reason, reasons }: TablesTargetingDetailsProps) {
  const { formatMessage } = useLocales()

  let formattedHeader: string
  let formattedTableDetails: JSX.Element[]

  switch (reason) {
    case 'NO_TABLES':
      formattedHeader = formatMessage(tablesTargetingCategoryMessages.mainDescriptionHeaderNoTables)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesTargetingCategoryMessages.mainDescriptionNoTables, {
            count: r.data?.seatingAreaIds?.length,
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'NOT_FOUND_IN_SHIFT_LAYOUT':
      formattedHeader = formatMessage(tablesTargetingCategoryMessages.mainDescriptionHeaderNotFoundInShiftLayout)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesTargetingCategoryMessages.mainDescriptionNotFoundInShiftLayout, {
            table: r.data?.tableItemCode,
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'NOT_IN_SEATING_AREA':
      formattedHeader = formatMessage(tablesTargetingCategoryMessages.mainDescriptionHeaderNotInSeatingArea)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesTargetingCategoryMessages.mainDescriptionNotInSeatingArea, {
            table: r.data?.tableItemCode,
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'NO_ACCESS_RULES':
      formattedHeader = formatMessage(tablesTargetingCategoryMessages.mainDescriptionHeaderNoAccessRules)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesTargetingCategoryMessages.mainDescriptionNoAccessRules, {
            table: r.data?.tableItemCode,
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'PARTY_SIZE_MISMATCH':
      formattedHeader = formatMessage(tablesTargetingCategoryMessages.mainDescriptionHeaderPartySizeMismatch)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesTargetingCategoryMessages.mainDescriptionPartySizeMismatch, {
            table: r.data?.tableItemCode,
            min: r.data?.tableMin,
            max: r.data?.tableMax,
            span: (chunks: string[]) => (
              <Typography component="span" whiteSpace="nowrap">
                {chunks}
              </Typography>
            ),
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    default:
      return <></>
  }
}

function groupTablesByReason(reasons: AvailabilityDebuggerReason[]) {
  const groupedReasons: TablesTargetingGroupedReasons = {
    NO_TABLES: [],
    NOT_FOUND_IN_SHIFT_LAYOUT: [],
    NOT_IN_SEATING_AREA: [],
    NO_ACCESS_RULES: [],
    NO_PACING_COVERS_REMAINING: [],
    PARTY_SIZE_MISMATCH: [],
  }

  let numTablesAffected = 0

  // group table reasons together and de-dupe and table item code reasons
  reasons.sort(compareTableItems).forEach(r => {
    const reason = r.reason as keyof TablesTargetingGroupedReasons
    if (reason in groupedReasons) {
      if (groupedReasons[reason].length > 0) {
        const tc = r.data?.tableItemCode
        if (!groupedReasons[reason].some(r => r.data?.tableItemCode === tc)) {
          groupedReasons[reason].push(r)
          numTablesAffected += 1
        }
      } else {
        groupedReasons[reason].push(r)
        numTablesAffected += 1
      }
    }
  })

  return { groupedReasons, numTablesAffected }
}

export function TablesTargetingCategory({ reasons, defaultExpanded = true, venue }: TablesTargetingCategoryProps) {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  const { formatMessage } = useLocales()
  const { groupedReasons, numTablesAffected } = useMemo(() => groupTablesByReason(reasons), [reasons])
  const headerMessage = formatMessage(tablesTargetingCategoryMessages.accordianHeader, {
    count: numTablesAffected,
    numTables: numTablesAffected,
  })

  if (numTablesAffected === 0) {
    return <></>
  }

  return (
    <AccordionContent
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      headerMessage={headerMessage}
      dataTestId="tables-targeting-category-content"
      actionButtonEnabled
      actionButtonProps={{
        label: formatMessage(tablesTargetingCategoryMessages.manageTablesButtonText),
        url: `/manager/${venue.urlKey}/manage/capacity/table/edit`,
      }}
    >
      <AccordionDetails id="tables-match-targeting-content">
        {Object.entries(groupedReasons)
          .filter(([, reasons]) => reasons.length > 0)
          .map(([reason, reasons]) => (
            <TablesTargetingDetails key={`${reason}`} reason={reason} reasons={reasons} />
          ))}
      </AccordionDetails>
    </AccordionContent>
  )
}
