import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import CheckboxToggle from 'mgr/lib/forms/CheckboxToggle'
import {
  toggleLendingPageSlideout,
  toggleSetting,
  tryLoadLandingPageSettingsData,
  removeButton,
  toggleButtonEditor,
  reorderButtons,
  toggleLendingPageHeaderTextSlideout,
  onChangeVenue,
} from 'mgr/pages/single-venue/marketing/actions/LandingPageSettings'
import { ActionButton } from 'mgr/pages/single-venue/marketing/components/shared/ActionButton'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'
import { Blocker } from 'svr/component-lib/Generic/Loading'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import RowBlock from 'svr/component-lib/Manager/NavigationBlocks/RowBlock'
import RowBlockContainer from 'svr/component-lib/Manager/NavigationBlocks/RowBlockContainer'
import LandingPageButtonSlideout, { contactUsActionsNames, widgetActionsNames, buttonTypesNames } from './LandingPageButtonSlideout'
import LandingPageHeaderEdit from './LandingPageHeaderEdit'

class LandingPageSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = { removeButtonId: null }
  }

  componentDidMount() {
    this.props.tryLoadLandingPageSettingsData(this.props.venue)
  }

  render() {
    return (
      <ContentLayout
        title="Landing Page Settings"
        venue={this.props.venue}
        venues={this.props.venues}
        onChangeVenue={this.props.onChangeVenue}
      >
        <MainContent isActive={this.props.isLoading}>
          <RowBlockContainer label="Page Settings">
            <RowBlock label="Show Header Text" subCaption={this.props.data.header_text}>
              <ActionIcon data-test-id="sr-lp-edit-header" onClick={this.props.toggleLendingPageHeaderTextSlideout}>
                {VmsIcons.EditPencil}
              </ActionIcon>
              <CheckboxToggle
                name="show_header_text"
                data-test-id="sr-lp-show-header"
                testId="sr-lp-show-header-label"
                on={this.props.data.show_header_text}
                onChange={() => this.props.toggleSetting('show_header_text')}
              />
            </RowBlock>

            <RowBlock label="Show Venue Banner">
              <CheckboxToggle
                name="show_venue_banner"
                data-test-id="sr-lp-show-venue-banner"
                testId="sr-lp-show-venue-banner"
                on={this.props.data.show_venue_banner}
                onChange={() => this.props.toggleSetting('show_venue_banner')}
              />
            </RowBlock>

            <RowBlock label="Show Special Attention Message">
              <CheckboxToggle
                data-test-id="sr-lp-special-attention"
                testId="sr-lp-special-attention"
                name="show_special_attention_message"
                on={this.props.data.show_special_attention_message}
                onChange={() => this.props.toggleSetting('show_special_attention_message')}
              />
            </RowBlock>
          </RowBlockContainer>

          <RowBlockContainer
            label="Buttons"
            subCaption="Drag and drop the buttons to reorder them on the page."
            actions={
              <AddNewButton Reservation widget="sr-new-button" onClick={!this.props.isSlideoutOpen && this.props.toggleLendingPageSlideout}>
                Add New Button
              </AddNewButton>
            }
          >
            <DragDropContext onDragEnd={this.props.reorderButtons}>
              <div>
                <Droppable droppableId="primary">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <ButtonSectionTitle>Primary Buttons</ButtonSectionTitle>
                      {this.props.data.buttons.primary.length === 0 && (
                        <ButtonSectionEmpty>Click ‘Add New Button’ above to create a Primary button.</ButtonSectionEmpty>
                      )}

                      {this.props.data.buttons.primary.map((b, i) => (
                        <ButtonRow
                          type="primary"
                          isFirst
                          button={b}
                          index={i}
                          key={b.id}
                          totalRowsInSection={this.props.data.buttons.primary.length}
                          draggableId={b.id}
                          onRemove={() => this.setState({ removeButtonId: b.id })}
                          onEditClick={() => this.props.toggleButtonEditor(b.id)}
                        />
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <Droppable droppableId="secondary">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <ButtonSectionTitle addTopSpace>Secondary Buttons</ButtonSectionTitle>
                      {this.props.data.buttons.secondary.length === 0 && (
                        <ButtonSectionEmpty>Click ‘Add New Button’ above to create a Secondary button.</ButtonSectionEmpty>
                      )}

                      {this.props.data.buttons.secondary.map((b, i) => (
                        <ButtonRow
                          isLast
                          type="secondary"
                          button={b}
                          index={i}
                          key={b.id}
                          totalRowsInSection={this.props.data.buttons.secondary.length}
                          draggableId={b.id}
                          onRemove={() => this.setState({ removeButtonId: b.id })}
                          onEditClick={() => this.props.toggleButtonEditor(b.id)}
                        />
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </RowBlockContainer>

          <RowBlockContainer label="Links">
            <LinksSectionInfo>
              All the styles on the Landing Page will be inherited from the styles in the{' '}
              <a href={`/manager/${this.props.venue.urlKey}/settings/widgets/dining`}>Reservation widget settings</a> page.
            </LinksSectionInfo>

            <div>
              <PreformatCaption>Direct link to Landing Page</PreformatCaption>
              <Preformat>
                {location.origin}/landing/{this.props.venue.urlKey}
              </Preformat>
            </div>
          </RowBlockContainer>
        </MainContent>

        <LandingPageButtonSlideout />
        <LandingPageHeaderEdit />

        {this.state.removeButtonId !== null && (
          <MessageBox
            handleActionClick={() => {
              this.props.removeButton(this.state.removeButtonId)
              this.setState({ removeButtonId: null })
            }}
            handleCloseClick={() => this.setState({ removeButtonId: null })}
            dialogType={MessageBox.DialogType.WARNING}
            header="ARE YOU SURE"
            explanation="Are you sure you want to remove this button?  This will be immediately removed from your landing page. This cannot be undone."
            details={[]}
            actionButtonText="Confirm"
          />
        )}
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  let venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  venues = venues.filter(v => v.landingPageEnabled)

  return {
    venue,
    venues,
    isLoading: state.landingPageSettings.isLoading,
    isSlideoutOpen: state.landingPageSettings.isSlideoutOpen,
    data: state.landingPageSettings.data,
  }
}

const mapDispatchToProps = {
  tryLoadLandingPageSettingsData,
  toggleLendingPageSlideout,
  toggleSetting,
  removeButton,
  toggleButtonEditor,
  reorderButtons,
  toggleLendingPageHeaderTextSlideout,
  onChangeVenue,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPageSettings))

function ButtonRow(props) {
  const allNames = {
    ...contactUsActionsNames,
    ...widgetActionsNames,
    ...buttonTypesNames,
  }

  const subCaption = props.button.action ? allNames[props.button.action] : allNames[props.button.type]

  return (
    <Draggable draggableId={props.draggableId} index={props.index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <RowWrapper>
            <RowBlockWrapper
              label={props.button.button_text}
              subCaption={subCaption}
              data-test-id={`sr-lp-btn-row-${props.type}-${props.index}`}
              upfront={
                <MoveButtonsWrapper>
                  <MoveButtons>
                    <div {...provided.dragHandleProps}>
                      <DragIcon>{VmsIcons.DragIndicator}</DragIcon>
                    </div>
                  </MoveButtons>
                </MoveButtonsWrapper>
              }
            >
              <ActionIcon onClick={props.onEditClick} data-test-id="sr-lp-row-edit">
                {VmsIcons.EditPencil}
              </ActionIcon>
              <ActionIcon onClick={props.onRemove} data-test-id="sr-lp-row-delete">
                {VmsIcons.Trash}
              </ActionIcon>
            </RowBlockWrapper>
          </RowWrapper>
        </div>
      )}
    </Draggable>
  )
}

const MainContent = styled(Blocker)`
  background: white;
`

const LinksSectionInfo = styled('div')`
  color: #888c92;
`

const PreformatCaption = styled('div')`
  margin-top: 16px;
`

const Preformat = styled('pre')`
  display: inline-block;
  color: #565c63;
  background: #f7f7f7;
  border-radius: 6px;
  border: 1px solid #dbdcde;
  font-size: 12px;
  padding: 12px;
  margin-top: 12px;
`

const RowWrapper = styled('div')`
  display: flex;
`

const RowBlockWrapper = styled(RowBlock)`
  flex-grow: 1;
`

const MoveButtonsWrapper = styled('div')`
  width: 50px;
`

const MoveButtons = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
`

const DragIcon = styled(StyledIcons.S)`
  cursor: move;
  color: #93979d;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`

const ActionIcon = styled(StyledIcons.S)`
  cursor: pointer;
  color: #93979d;
  padding: 8px;

  &:hover {
    color: #347baf;
  }
`

const AddNewButton = styled(ActionButton)`
  background: ${props => props.theme.primary};
`

const ButtonSectionTitle = styled('div')`
  color: #565c63;
  font-size: 15px;
  margin-top: ${props => (props.addTopSpace ? '20px' : 0)};
  margin-bottom: 12px;
`

const ButtonSectionEmpty = styled('div')`
  color: #565c63;
  font-size: 12px;
  font-style: oblique;
  margin-top: 20px;
`
