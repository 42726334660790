import React, { PureComponent } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router'
import styled from 'styled-components'
import LookerReportingEmbed from 'mgr/pages/shared/components/reporting/LookerReportingEmbed'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'

class LookerReportingLayout extends PureComponent {
  render() {
    const { match, location, venue, venues, onChangeVenue, history } = this.props

    const reportingUrl = location && _.drop(location.pathname.split('/'), 4).join('/')

    return (
      <ContentLayout
        hideTitleBar
        venue={venue}
        venues={venues}
        onChangeVenue={venueNew => {
          const lookerUrlB64 = btoa(reportingUrl)
          history.push(`/manager/${venueNew.urlKey}/reports/view_reports_redirection?looker_url_b64=${lookerUrlB64}`)
          window.location.reload()
        }}
        overrideChangeVenue
      >
        <LookerReportingEmbed isGroup={false} url={reportingUrl} lookerFilters={location.search} />
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
  }
}

export default withRouter(connect(mapStateToProps, null)(LookerReportingLayout))
