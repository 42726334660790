import PropTypes from 'prop-types'
import React from 'react'

const Form = ({ onSubmit, children, ...props }) => (
  <form
    onSubmit={e => {
      e.preventDefault()
      onSubmit(e)
    }}
    {...props}
  >
    {children}
  </form>
)

Form.defaultProps = {
  onSubmit: PropTypes.func.isRequired,
}

export default Form
