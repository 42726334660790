import { InsightStatus } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, VStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useInsightSuggestedActionsContext } from '../../../hooks'
import { insightsMessages } from '../../../locales'
import { StatusIcon } from '../../Status'

export function InsightTrackingSection() {
  const { formatMessage } = useLocales()
  const { onSave } = useInsightSuggestedActionsContext()
  const titleContent = (
    <HStack spacing="s" data-test="insight-tracking-section-title">
      <StatusIcon variant="tracking" />
      <Text textStyle="h2">{formatMessage(insightsMessages.statusTrackingLabel)}</Text>
    </HStack>
  )

  return (
    <BaseSection title={titleContent} data-test="insight-tracking-section">
      <VStack pt="m">
        <DividerLine />
        <Box p="m">
          <SecondaryText data-test="insight-tracking-description">
            {formatMessage(insightsMessages.trackingText, {
              link: (
                <Button
                  variant="tertiary"
                  onClick={() => onSave({ updatedStatus: InsightStatus.NEW })}
                  noPadding
                  data-test="insight-tracking-revert-link"
                >
                  {formatMessage(insightsMessages.revertStatusLinkText)}
                </Button>
              ),
            })}
          </SecondaryText>
        </Box>
      </VStack>
    </BaseSection>
  )
}
