/* eslint-disable camelcase */
import { adaptProductItemFromApi, adaptProductItemToApi } from 'mgr/lib/services/ProductServices/adapters/adaptProduct'
import type {
  ImageCropData,
  ModifierGroups,
  ModifierGroupsApi,
  ProductImageData,
  ProductImageDataApi,
  ProductImageUrls,
  ProductImageUrlsApi,
} from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type {
  MenuItemApi,
  MenuItem,
  ProductInventoryItemApi,
  ModifiersGroupToModifiersApi,
  ModifiersGroupToModifiers,
  ModifierItem,
  ModifierItemApi,
  ModifiersGroupItemApi,
  ModifiersGroupItem,
  ProductInventoryItem,
} from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export const adaptModifierGroupsFromApi = (groups: ModifierGroupsApi) =>
  Object.entries(groups).reduce<ModifierGroups>((accumulator, [key, item]) => {
    accumulator[key] = {
      name: item.name,
      modifierIds: item.modifier_ids,
    }
    return accumulator
  }, {})

const adaptPriceFromApi = (price: number): number => (price ? price / 100 : price)
const adaptPriceToApi = (price: number): number => Math.round(price * 100)

export const adaptProductFromApi = (product: ProductInventoryItemApi): ProductInventoryItem => {
  switch (product.type) {
    case 'MENU_ITEM':
      return adaptMenuItemFromApi(product)
    case 'MODIFIER':
      return adaptModifierItemFromApi(product)
    case 'MODIFIER_GROUP':
      return adaptModifierGroupFromApi(product)
    default:
      throw new Error('Unknown product type')
  }
}

export const adaptMenuItemFromApi = (menuItem: MenuItemApi): MenuItem => ({
  ...adaptProductItemFromApi(menuItem),
  type: 'MENU_ITEM',
  price: adaptPriceFromApi(menuItem.price),
  verifyAge: menuItem.verify_age,
  taxRate: menuItem.tax_rate,
  taxGroupId: menuItem.tax_group_id,
  productTags: menuItem.product_tags,
  menuNames: menuItem.menu_names,
  modifierGroupsToModifiers: menuItem.modifier_groups_to_modifiers
    ? adaptModifierGroupsToModifiersFromApi(menuItem.modifier_groups_to_modifiers)
    : [],
  imageUrls: menuItem.image_urls && adaptImageUrlsFromApi(menuItem.image_urls),
  uploadedImageData: menuItem.uploaded_image_data && adaptImageDataFromApi(menuItem.uploaded_image_data),
})

export const adaptModifierItemFromApi = (modifierItem: ModifierItemApi): ModifierItem => ({
  ...adaptProductItemFromApi(modifierItem),
  type: 'MODIFIER',
  price: adaptPriceFromApi(modifierItem.price),
  verifyAge: modifierItem.verify_age,
  taxRate: modifierItem.tax_rate,
  taxGroupId: modifierItem.tax_group_id,
  modifierGroupsToModifiers: modifierItem.modifier_groups_to_modifiers
    ? adaptModifierGroupsToModifiersFromApi(modifierItem.modifier_groups_to_modifiers)
    : [],
})

export const adaptModifierGroupFromApi = (modifierGroupItem: ModifiersGroupItemApi): ModifiersGroupItem => ({
  ...adaptProductItemFromApi(modifierGroupItem),
  type: 'MODIFIER_GROUP',
  modifierItemIds: modifierGroupItem.modifier_item_ids,
  minAmount: modifierGroupItem.min_amount,
  maxAmount: modifierGroupItem.max_amount,
  allowOrderSameModifiers: modifierGroupItem.allow_order_same_modifiers,
  maxSameModifiersAmount: modifierGroupItem.max_same_modifiers_amount,
})

export const adaptModifierGroupsToModifiersFromApi = (modifiers: ModifiersGroupToModifiersApi[]): ModifiersGroupToModifiers[] =>
  modifiers.map((modifier: ModifiersGroupToModifiersApi) => ({
    modifierGroupId: modifier.modifier_group_id,
    modifierIds: modifier.modifier_ids,
  }))

export const adaptModifierGroupsToModifiersToApi = (modifiers: ModifiersGroupToModifiers[]): ModifiersGroupToModifiersApi[] =>
  modifiers.map((modifier: ModifiersGroupToModifiers) => ({
    modifier_group_id: modifier.modifierGroupId,
    modifier_ids: modifier.modifierIds,
  }))

export const adaptImageDataFromApi = (imageData: ProductImageDataApi): ProductImageData => ({
  productImgBlobKey: imageData.product_img_blob_key,
  productImgCropData: imageData.product_img_crop_data as ImageCropData,
  productImgPhotoDict: imageData.product_img_photo_dict,
  productImgUrl: imageData.product_img_url,
})

export const adaptImageDataToApi = (imageData: ProductImageData): ProductImageDataApi => ({
  product_img_blob_key: imageData.productImgBlobKey,
  product_img_crop_data: imageData.productImgCropData ? JSON.stringify(imageData.productImgCropData) : imageData.productImgCropData,
  product_img_photo_dict: imageData.productImgPhotoDict,
  product_img_url: imageData.productImgUrl,
})

export const adaptImageUrlsFromApi = (imgUrls: ProductImageUrlsApi): ProductImageUrls => ({
  small: imgUrls.small,
  medium: imgUrls.medium,
  large: imgUrls.large,
  mega: imgUrls.mega,
  externalUrls: imgUrls.external_urls,
})

export const adaptImageUrlsToApi = (img_urls: ProductImageUrls): ProductImageUrlsApi => ({
  small: img_urls.small,
  medium: img_urls.medium,
  large: img_urls.large,
  mega: img_urls.mega,
  external_urls: img_urls.externalUrls,
})

export const adaptMenuItemToApi = (menuItem: MenuItem): MenuItemApi => ({
  ...adaptProductItemToApi(menuItem),
  type: 'MENU_ITEM',
  price: adaptPriceToApi(menuItem.price),
  verify_age: menuItem.verifyAge,
  tax_rate: menuItem.taxRate,
  tax_group_id: menuItem.taxGroupId,
  product_tags: menuItem.productTags,
  menu_names: menuItem.menuNames,
  modifier_groups_to_modifiers: menuItem.modifierGroupsToModifiers
    ? adaptModifierGroupsToModifiersToApi(menuItem.modifierGroupsToModifiers)
    : [],
  image_urls: menuItem.imageUrls && adaptImageUrlsToApi(menuItem.imageUrls),
  uploaded_image_data: menuItem.uploadedImageData && adaptImageDataToApi(menuItem.uploadedImageData),
})

export const adaptModifierItemToApi = (modifierItem: ModifierItem): ModifierItemApi => ({
  ...adaptProductItemToApi(modifierItem),
  type: 'MODIFIER',
  price: adaptPriceToApi(modifierItem.price),
  verify_age: modifierItem.verifyAge,
  tax_rate: modifierItem.taxRate,
  tax_group_id: modifierItem.taxGroupId,
  modifier_groups_to_modifiers: modifierItem.modifierGroupsToModifiers
    ? adaptModifierGroupsToModifiersToApi(modifierItem.modifierGroupsToModifiers)
    : [],
})

export const adaptModifierGroupToApi = (modifierGroupItem: ModifiersGroupItem): ModifiersGroupItemApi => ({
  ...adaptProductItemToApi(modifierGroupItem),
  type: 'MODIFIER_GROUP',
  modifier_item_ids: modifierGroupItem.modifierItemIds,
  min_amount: modifierGroupItem.minAmount,
  max_amount: modifierGroupItem.maxAmount === 0 ? null : modifierGroupItem.maxAmount,
  allow_order_same_modifiers: modifierGroupItem.allowOrderSameModifiers,
  max_same_modifiers_amount: modifierGroupItem.maxSameModifiersAmount === 0 ? null : modifierGroupItem.maxSameModifiersAmount,
})

export const adaptProductToApi = (product: ProductInventoryItem): ProductInventoryItemApi => {
  switch (product.type) {
    case 'MENU_ITEM':
      return adaptMenuItemToApi(product)
    case 'MODIFIER':
      return adaptModifierItemToApi(product)
    case 'MODIFIER_GROUP':
      return adaptModifierGroupToApi(product)
    default:
      throw new Error('Unknown product type')
  }
}
