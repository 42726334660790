import { srDelete, srGet, srPost, srPut, throwFetchError } from '@sevenrooms/core/api'

export const fetchUserRoles = params =>
  srGet(`/api-yoa/users/vmsroletemplates`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const fetchUserRole = params =>
  srGet(`/api-yoa/users/vmsroletemplate`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const createUserRole = params =>
  srPost(`/api-yoa/users/vmsroletemplate`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const saveUserRole = params =>
  srPut(`/api-yoa/users/vmsroletemplate`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const deleteVmsRole = params =>
  srDelete(`/api-yoa/users/vmsroletemplate`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const duplicateVmsRole = params =>
  srPost(`/api-yoa/users/vmsroletemplate/duplicate`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)
