import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'

export function LinkChecksSuggestedActions() {
  const { formatMessage } = useLocales()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-link-checks-suggested-actions">
      <Text data-test="insights-link-checks-suggested-actions-host-training">
        1. {formatMessage(insightsMessages.suggestedActionsLinkChecksHostTrainingStep)}
      </Text>
      <VStack spacing="s" pl="m">
        <Text data-test="insights-link-checks-suggested-actions-icon-display-tip">
          a.{' '}
          {formatMessage(insightsMessages.suggestedActionsLinkChecksIconDisplayTip, {
            icon: <Icon name="VMSWeb-link-variant" size="lg" />,
          })}
        </Text>
        <Text data-test="insights-link-checks-suggested-actions-missed-link-tip">
          b. {formatMessage(insightsMessages.suggestedActionsLinkChecksMissedLinkTip)}
        </Text>
      </VStack>
    </VStack>
  )
}
