import React from 'react'
import MessageBox from 'svr/component-lib/Manager/MessageBox'

export interface WarningMessageProps {
  isDelete: boolean
  proceed: () => void
  cancel: () => void
  extraExplanation?: string
}

export function WarningMessage({ isDelete, proceed, cancel, extraExplanation }: WarningMessageProps) {
  return (
    <MessageBox
      handleActionClick={proceed}
      handleCloseClick={cancel}
      dialogType={MessageBox.DialogType.WARNING}
      header="ARE YOU SURE"
      actionButtonText="Confirm"
      explanation={`${isDelete ? 'Deleting' : 'Disabling'} this tag category will impact all associated venues. ${extraExplanation}`}
    />
  )
}
