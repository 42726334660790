import { srDelete, srGet, srPostJson, srPut, throwFetchError } from '@sevenrooms/core/api'

export const getOrderingSites = venueId =>
  srGet(`/api-yoa/ordering/sites/${venueId}`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true }).catch(e => {
    console.error('An unexpected error occurred fetching the sites list', e)

    return e
  })

export const deleteOrderingSite = (venueId, orderingSiteId) =>
  srDelete(`/api-yoa/ordering/sites/${venueId}/${orderingSiteId}`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true }).then(
    response => {
      if (response.status !== 200 && response.error) {
        throw new Error(response.errorMsg)
      }
      return response
    }
  )

export const getOrderingSite = (venueId, orderingSiteId) =>
  srGet(`/api-yoa/ordering/sites/${venueId}/${orderingSiteId}`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true }).catch(e => {
    console.error('An unexpected error occurred fetching the site data', e)

    return e
  })

export const getOrderingSiteSyncedPosTables = venueId =>
  srGet(`/api-yoa/ordering/sites/pos_tables_sync/${venueId}`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true }).catch(e => {
    console.error('An unexpected error occurred fetching synced pos table data', e)

    return e
  })

export const saveOrderingSite = (venueId, orderingSiteId, siteData) =>
  srPut(`/api-yoa/ordering/sites/${venueId}/${orderingSiteId}`, siteData, {
    json: true,
  }).then(response => {
    if (response.error) {
      throw new Error(response.errorMsg)
    }
    return response.data || {}
  })

export const createOrderingSite = (venueId, orderingSiteId, siteData) =>
  srPostJson(`/api-yoa/ordering/sites/${venueId}`, siteData).then(throwFetchError)
