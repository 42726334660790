import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { InputLabel, type InputLabelProps } from '@sevenrooms/react-components/components/InputLabel'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import type { PropsWithChildren } from 'react'

export interface StandardInputProps extends InputLabelProps {
  label: string
  width?: string | number
  minWidth?: string | number
  tooltip?: string | null
}

export function StandardInput({
  children,
  htmlFor,
  label,
  width = '100%',
  minWidth = '150px',
  tooltip = null,
}: PropsWithChildren<StandardInputProps>) {
  if (tooltip && children != null) {
    return (
      <Stack direction="column" spacing={0} sx={{ width, minWidth }}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <InputLabel htmlFor={htmlFor}>{label}</InputLabel>
          <Tooltip disableFocusListener title={<Typography>{tooltip}</Typography>}>
            <InfoOutlinedIcon fontSize="small" color="action" />
          </Tooltip>
        </Stack>
        {children}
      </Stack>
    )
  }
  return children != null ? (
    <Stack direction="column" alignItems="start" spacing={0} sx={{ width, minWidth }}>
      <InputLabel htmlFor={htmlFor}>{label}</InputLabel>
      {children}
    </Stack>
  ) : null
}
