import type { AccessRule } from '@sevenrooms/core/domain'
import { DateOnly } from '@sevenrooms/core/timepiece'
import type { AutocompleteOption } from './options'

export interface AccessRuleInputProps {
  accessRules: AccessRule[]
}

export function hasDurationOverride(accessRules: AccessRule[]) {
  return accessRules.some(ar => ar.durationMin != null || ar.durationMax != null)
}

export function getSelectedAccessRuleOptions(options: AutocompleteOption[], selected?: string[]) {
  return options.filter(o => selected?.includes(o.value))
}

export function filterAccessRuleDataByDate(data: AccessRule[], iso8601: string) {
  const date = DateOnly.from(iso8601)
  return data.filter(
    ar =>
      ar.date?.isEqualTo(date) ||
      (ar.startDate?.isLessThanOrEqualTo(date) &&
        (ar.endDate == null || ar.endDate.isGreaterThanOrEqualTo(date)) &&
        // 0 is Monday, 0 - 1 mod 7 != 6
        (ar.dayOfWeek ?? [])[(date.toJsDate().getDay() + 6) % 7] &&
        (ar.excludedDates ?? []).every(d => !DateOnly.from(d as unknown as string).isEqualTo(date)))
  )
}

export function transformAccessRuleDataToOptions(data: AccessRule[]): AutocompleteOption[] {
  return data.map(ar => ({
    label: ar.name,
    value: ar.persistentId,
  }))
}
