import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { DragDropPanel } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/Section/DragDropPanel'
import CheckboxButton from 'svr/component-lib/Generic/Checkbox/CheckboxButton'
import VmsCheckboxGroup from 'svr/component-lib/Generic/VmsCheckboxGroup'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Item } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/Section/Section'
import type { ModifierGroups, Modifiers } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { ModifiersGroupToModifiers } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface ModifierGroupsFieldProps {
  modifierGroups: ModifierGroups
  modifiers: Modifiers
  selectedModifierGroups?: ModifiersGroupToModifiers[]
  title: string
  updateSelectedGroupModifiers: (groupId: string, modifiers?: string[]) => void
  updateSelectedGroups: (groups: ModifiersGroupToModifiers[]) => void
}

export function ModifierGroupsField({
  modifierGroups,
  modifiers,
  selectedModifierGroups = [],
  title,
  updateSelectedGroupModifiers,
  updateSelectedGroups,
}: ModifierGroupsFieldProps) {
  const selectedModifierGroupsMap = useMemo(
    () =>
      selectedModifierGroups.reduce<Record<string, ModifiersGroupToModifiers>>((accumulator, group) => {
        accumulator[group.modifierGroupId] = group
        return accumulator
      }, {}),
    [selectedModifierGroups]
  )
  const panelItems = useMemo(
    () =>
      selectedModifierGroups
        .map(group => ({ id: group.modifierGroupId, title: modifierGroups[group.modifierGroupId]?.name }))
        .filter((grp): grp is Item => grp.title !== undefined),
    [modifierGroups, selectedModifierGroups]
  )

  const updateSelectedGroup = useCallback(
    (groupId: string, isSelected: boolean) => {
      updateSelectedGroupModifiers(groupId, isSelected ? [] : undefined)
    },
    [updateSelectedGroupModifiers]
  )
  const updateSelectedModifier = useCallback(
    (groupId: string, modifierId: string, isSelected: boolean) => {
      const groupModifiers = modifierGroups[groupId]?.modifierIds || []
      const selectedGroup = _.find(selectedModifierGroups, { modifierGroupId: groupId })
      let modifiers = _.get(selectedGroup, 'modifierIds')
      if (modifiers && modifiers.length === 0) {
        modifiers = groupModifiers
      } else if (!modifiers) {
        modifiers = []
      }
      let updatedModifiers
      if (isSelected) {
        updatedModifiers = [...modifiers, modifierId]
        if (_.difference(groupModifiers, updatedModifiers).length === 0) {
          updatedModifiers = []
        }
      } else {
        updatedModifiers = modifiers.filter(modifier => modifier !== modifierId)
        if (!updatedModifiers.length) {
          updatedModifiers = undefined
        }
      }
      updateSelectedGroupModifiers(groupId, updatedModifiers)
    },
    [modifierGroups, selectedModifierGroups, updateSelectedGroupModifiers]
  )
  return (
    <>
      <Box mb="sm">
        <Text>{title}</Text>
      </Box>
      {Object.entries(modifierGroups)
        .filter(([, groupData]) => !_.isEmpty(groupData.modifierIds))
        .sort(([, groupData1], [, groupData2]) => groupData1.name.localeCompare(groupData2.name))
        .map(([groupId, groupData]) => {
          const groupKey = `modifier-group-${groupId}`
          const groupModifiers = groupData.modifierIds
          const selectedGroup = _.find(selectedModifierGroups, { modifierGroupId: groupId })
          const selectedGroupModifiers = selectedGroup && selectedGroup.modifierIds
          const isGroupSelected = selectedGroupModifiers && selectedGroupModifiers.length === 0
          const isDefaultCollapsed = !isGroupSelected && _.intersection(selectedGroupModifiers, groupModifiers).length === 0
          return (
            <VmsCheckboxGroup
              key={groupKey}
              data-test={groupKey}
              title={`${groupData.name}${groupModifiers.length ? ` (${groupModifiers.length})` : ''}`}
              checkedFields={
                isGroupSelected ? [true] : groupModifiers.map(groupModifier => _.includes(selectedGroupModifiers, groupModifier))
              }
              isValid
              disabled={false}
              disabledFields={[]}
              onChange={() => updateSelectedGroup(groupId, !isGroupSelected)}
              isDefaultCollapsed={isDefaultCollapsed}
            >
              {groupModifiers.map(modifierId => {
                const modifierKey = `modifier-${groupId}-${modifierId}`
                const isModifierSelected = isGroupSelected || _.includes(selectedGroupModifiers, modifierId)
                return (
                  <CheckboxButton
                    data-test={modifierKey}
                    key={modifierKey}
                    value={isModifierSelected}
                    isValid
                    onChange={(value: boolean) => updateSelectedModifier(groupId, modifierId, value)}
                  >
                    {`${_.get(modifiers[modifierId], 'name')} ${_.get(modifiers[modifierId], 'priceFormatted', '')}`}
                  </CheckboxButton>
                )
              })}
            </VmsCheckboxGroup>
          )
        })}
      {!!panelItems.length && (
        <>
          <Delimiter mt="lm" mb="lm" />
          <Text>Sort the modifiers groups to be the way how you want them to be shown on the menu.</Text>
          <Box mt="m">
            <DragDropPanel
              items={panelItems}
              onItemsUpdate={items => {
                const modifierGroups: ModifiersGroupToModifiers[] = items.map(
                  item => selectedModifierGroupsMap[item.id] as ModifiersGroupToModifiers
                )
                updateSelectedGroups(modifierGroups)
              }}
              onRemove={value => updateSelectedGroupModifiers(value, undefined)}
              panelId="Modifiers"
            />
          </Box>
        </>
      )}
    </>
  )
}

const Delimiter = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.mediumGrey};
`
