import { useMemo } from 'react'
import { timeHHmmssToFormat } from 'svr/common/DateTime'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { TimeRange as TimeRangeType } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

const noFulfillmentHoursText = 'No fulfillment hours'

export function TimeRangeReadOnly({ hoursList }: { hoursList: TimeRangeType[] }) {
  const timeRangesString = useMemo(
    () =>
      hoursList
        .filter(({ start, end }) => start && end)
        .map(({ start, end }) => `${timeHHmmssToFormat(start as string)} - ${timeHHmmssToFormat(end as string)}`)
        .join(', '),
    [hoursList]
  )
  return hoursList.length !== 0 ? (
    <Text>{timeRangesString}</Text>
  ) : (
    <Text color="secondaryFont" fontStyle="italic">
      {noFulfillmentHoursText}
    </Text>
  )
}
