import { connect } from 'react-redux'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import { openAddPodSlideout } from 'mgr/pages/venue-group/actions/Pods'

const mapStateToProps = state => ({
  isSlideoutOpen: state.pods.selectedPodCategoryName !== null,
  maxWidth: '498px',
})

const mapDispatchToProps = dispatch => ({
  onCloseHandler: () => dispatch(openAddPodSlideout(false, null, '#e21d3e', [])),
})

const PodSlideout = connect(mapStateToProps, mapDispatchToProps)(SlideoutComponent)

export default PodSlideout
