import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { CONTACT_SUPPORT_URL, SUPPORTED_POS_LIST_URL } from '../../../../utils'

export function POSEnabledSuggestedActions() {
  const { formatMessage } = useLocales()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-pos-enabled-suggested-actions">
      <Text data-test="insights-pos-enabled-suggested-actions-supported-pos-review">
        1.{' '}
        {formatMessage(insightsMessages.posEnabledReviewPOSSystemsStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link to={SUPPORTED_POS_LIST_URL} target="_blank">
              {chunks}
            </Link>
          ),
        })}
      </Text>
      <Text data-test="insights-pos-enabled-suggested-actions-supported-pos-config">
        2.{' '}
        {formatMessage(insightsMessages.posEnabledScheduleConfigStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link to={CONTACT_SUPPORT_URL} target="_blank" data-test="insights-pos-enabled-suggested-actions-contact-support-link">
              {chunks}
            </Link>
          ),
        })}
      </Text>
      <Text data-test="insights-pos-enabled-suggested-actions-supported-pos-decline">
        3. {formatMessage(insightsMessages.posEnabledDeclineStep)}
      </Text>
    </VStack>
  )
}
