import _ from 'lodash'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const BarWrapper = styled.div`
  height: 32px;
  max-width: 100%;
  margin: 5px 0;
  border: 1px solid ${props => props.theme.lightGrey};
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const ButtonChoice = styled.div`
  width: 100px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
  ${props => (props.isFirst ? 'border-radius: 4px 0 0 4px;' : '')}
  border-right: 1px solid ${props => props.theme.lightGrey};
  color: ${props => (props.selected ? props.theme.white : props.theme.navigationDark)};
  background-color: ${props => (props.selected ? props.theme.primarySelected : props.theme.white)};
  :hover {
    background-color: ${props => (props.selected ? props.theme.primarySelected : props.theme.lightGrey)};
  }
`

class FloorplanRoomSelectBar extends PureComponent {
  render() {
    const { floorplanRooms, selectedFloorplanRoom, onChangeHandler } = this.props
    return (
      !_.isEmpty(floorplanRooms) && (
        <BarWrapper>
          {floorplanRooms.map((floorplanRoom, i) => (
            <ButtonChoice
              key={`ButtonChoice_${floorplanRoom.id}`}
              selected={selectedFloorplanRoom === floorplanRoom}
              isFirst={i === 0}
              onClick={() => onChangeHandler(floorplanRoom)}
            >
              {floorplanRoom.code}
            </ButtonChoice>
          ))}
        </BarWrapper>
      )
    )
  }
}

FloorplanRoomSelectBar.defaultProps = {
  onChangeHandler: () => {},
}

FloorplanRoomSelectBar.propTypes = {
  floorplanRooms: React.PropTypes.array,
  selectedFloorplanRoom: React.PropTypes.object,
  onChangeHandler: React.PropTypes.func,
}

export default FloorplanRoomSelectBar
