import type { PacingCoverageSuggestedActionsType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link, List } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../locales'
import { formatBold } from '../../../utils'

export interface PacingCoverageSuggestedActionsProps {
  insightSuggestedActionData: PacingCoverageSuggestedActionsType
}

export function PacingCoverageSuggestedActions({ insightSuggestedActionData }: PacingCoverageSuggestedActionsProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-pacing-coverage-suggested-actions">
      <List variant="ordered" spacing="s">
        <Text type="li" data-test="insights-pacing-coverage-navigate-shifts-actions">
          <Link
            to={nav.href(routes.manager.manage.shifts, {
              params: { venueKey: venue.urlKey },
            })}
            target="_blank"
            data-test="insights-pacing-coverage-navigate-shifts-link"
          >
            {formatMessage(insightsMessages.suggestedActionNavigatetoShiftLinkText, {
              icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            })}
          </Link>
        </Text>
        <Text type="li" data-test="insights-pacing-coverage-click-shifts-actions">
          {formatMessage(insightsMessages.suggestedActionsClickOnShiftStep, {
            shiftName: insightSuggestedActionData.shiftName,
          })}
        </Text>
        <Text type="li" data-test="insights-pacing-coverage-edit-all-actions">
          {formatMessage(insightsMessages.suggestedActionsEditAllDaysStep, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        <Text type="li" data-test="insights-pacing-coverage-capacity-settings-actions">
          <Box mb="s">
            {formatMessage(insightsMessages.suggestedActionsNavigateCapacitySettingsStep, {
              strong: (chunks: string[]) => formatBold(chunks),
            })}
          </Box>
          <List variant="ordered" spacing="s" type="a">
            <Text type="li" data-test="insights-pacing-coverage-consider-default-actions">
              {formatMessage(insightsMessages.suggestedActionsIncreaseDefaultStep, {
                strong: (chunks: string[]) => formatBold(chunks),
              })}
            </Text>
            <Text type="li" data-test="insights-pacing-coverage-consider-custom-actions">
              {formatMessage(insightsMessages.suggestedActionsIncreaseCustomStep, {
                strong: (chunks: string[]) => formatBold(chunks),
              })}
            </Text>
          </List>
        </Text>
        <Text type="li" data-test="insights-pacing-coverage-save-actions">
          {formatMessage(insightsMessages.suggestedActionsSaveChangesStep, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
      </List>
    </VStack>
  )
}
