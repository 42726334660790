import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, List } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'
import { formatBold } from '../../../utils'

export function ReducePaidChannelSuggestedActions({ shiftDetail }: { shiftDetail: string }) {
  const { formatMessage } = useLocales()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-reduce-paid-channel-suggested-actions">
      <List variant="ordered" spacing="s">
        <Text type="li" data-test="insights-reduce-paid-channel-suggested-actions-review">
          {formatMessage(insightsMessages.suggestedActionReducePaidReview)}
        </Text>
        <Text type="li" data-test="insights-reduce-paid-channel-suggested-actions-elimiate">
          {formatMessage(insightsMessages.suggestedActionReducePaidEliminate, {
            shiftDetail,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        <Text type="li" data-test="insights-reduce-paid-channel-suggested-actions-adjust-access">
          {formatMessage(insightsMessages.suggestedActionReducePaidAdjustAccess)}
        </Text>
      </List>
    </VStack>
  )
}
