import React from 'react'
import styled from 'styled-components'
import EditSiteExampleContainer, { EditSiteExampleWrapper } from './EditSiteExampleContainer'

const InputContainer = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  height: 42px;
  background: #f7f7f7;
  border: 1px solid #dbdcde;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 26px;
  margin-bottom: 32px;
`
const HintContainer = styled.div`
  display: inline-block;
  position: relative;
`

const HintTop = styled.div`
  font-size: 12px;
  color: #565c63;
  position: absolute;
  white-space: nowrap;
  top: -48px;
  transform: translate(-50%, 0);
`

const HintBottom = styled.div`
  font-size: 12px;
  color: #565c63;
  position: absolute;
  white-space: nowrap;
  top: 38px;
  left: 13px;
  transform: translate(-50%, 0);
`

const StickSingleTop = styled.div`
  border-left: 2px solid #347baf;
  position: absolute;
  height: 19px;
  top: -32px;
  left: -2px;
`

const SticksBottom = styled.div`
  border: 2px solid #347baf;
  border-top: none;
  position: absolute;
  height: 12px;
  top: 12px;
  left: -2px;
  width: 28px;
`

const StickSingleBottom = styled.div`
  border-left: 2px solid #347baf;
  position: absolute;
  height: 10px;
  top: 24px;
  left: 13px;
`

const EditSiteNowOrdersExample = ({ timeSlotBuffer = 0 }) => (
  <EditSiteExampleContainer>
    <EditSiteExampleWrapper>
      <InputContainer>
        Pickup Now (2
        <HintContainer>
          <StickSingleTop />
          <HintTop>Order Prep Time</HintTop>
          <SticksBottom />
          <StickSingleBottom />
          <HintBottom>Time slot buffer</HintBottom>
        </HintContainer>
        5 - {25 + timeSlotBuffer} min)
      </InputContainer>
    </EditSiteExampleWrapper>
    <EditSiteExampleWrapper>
      <InputContainer>
        Delivery Now (3
        <HintContainer>
          <StickSingleTop />
          <HintTop>Order Prep Time + Delivery Time</HintTop>
          <SticksBottom />
          <StickSingleBottom />
          <HintBottom>Time slot buffer</HintBottom>
        </HintContainer>
        5 - {35 + timeSlotBuffer} min)
      </InputContainer>
    </EditSiteExampleWrapper>
  </EditSiteExampleContainer>
)

export default EditSiteNowOrdersExample
