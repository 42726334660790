import _ from 'lodash'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { scroller } from 'react-scroll'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { validatorFuncs, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { StyledVmsIconS } from 'svr/common/VmsIcons'
import Caret from 'svr/component-lib/Generic/Symbols/Caret'

const OuterBody = styled.div`
  min-width: 154px;
  min-height: ${props => props.height}px;
  ${props => props.theme.clearFix};
`

const BorderedArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: ${props => props.height + 2}px;
  height: ${props => props.height + 2}px;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border: ${props => (props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark' ? 2 : 1)}px solid
    ${props =>
      props.isValid
        ? props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
          ? props.customStyle.colorLines
          : props.borderColor
        : props.theme.error};
  border-radius: 4px;
  background-color: ${props =>
    props.disabled
      ? props.theme.color.white
      : props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
      ? props.customStyle.colorCellBackground
      : props.theme.background};
  box-shadow: ${props => (props.isOpen ? '0 2px 4px 0 rgba(0,0,0,0.5)' : 'none')};
  :hover {
    border-color: ${props =>
      props.disabled ? (props.customStyle ? props.customStyle.colorLines : props.borderColor) : props.hoverBorderColor};
    transition: border-color 0.2s ease-out;
  }
  ${props => props.theme.clearFix};
`

// Fix for #3 here: https://github.com/philipwalton/flexbugs
// Since BorderedArea has min-height: 44px
const BorderedAreaFlexIEFix = styled.div`
  order: 0;
  width: 0;
  height: ${props => props.height + 2}px;
`

const Icon = styled(StyledVmsIconS)`
  order: 2;
  font-size: 17px;
  width: 17px;
  height: 17px;
  pointer-events: none;
  margin: 0 10px 0 5px;
`

const DropdownRelative = styled.div`
  position: relative;
  width: 100%;
`

const DropdownAbsolute = styled.div`
  position: absolute;
  width: 100%;
`

const DropUpAbsolute = styled(DropdownAbsolute)`
  bottom: 0;
`

const OptionBase = styled.div`
  overflow: hidden;
  font-size: 14px;
  align-items: baseline;
  padding: 10px;
  box-sizing: border-box !important;
  display: flex;
`

const OptionLightTheme = styled(OptionBase)`
  color: ${props => props.theme.navigationDark};
  background-color: ${props => (props.isSelectedChoice ? props.theme.primaryRgba20 : props.theme.background)};
  :hover,
  :focus {
    background-color: ${props => props.theme.primaryRgba10};
  }
`

const OptionDarkTheme = styled(OptionBase)`
  color: ${props =>
    props.isSelectedChoice ? props.theme.white : props.customStyle ? props.customStyle.fontsColorPrimary : props.theme.navigationDark};
  background-color: ${props =>
    props.isSelectedChoice
      ? props.customStyle
        ? props.customStyle.colorPrimary
        : '#031927'
      : props.customStyle
      ? props.customStyle.isResWidgetDark
        ? '#8a8b8b'
        : props.customStyle.colorCellBackground
      : props.theme.background};
  border-bottom: ${props => (props.isLast ? '0px' : '1px')} solid
    ${props =>
      props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
        ? props.customStyle.colorLines
        : props.theme.navigationDark};
  :hover,
  :focus {
    color: #031927;
    background-color: ${props => props.theme.lightGrey};
  }
`

const OptionDetailsContainer = styled.div`
  width: 100%;
`

const OptionTextContainer = styled.div`
  display: flex;
  align-items: center;
`

const OptionText = styled.span`
  flex-shrink: 50;
`

const OptionCaption = styled.span`
  color: ${props => props.theme.darkGrey};
  padding-left: ${props => props.theme.gutter.half};
  font-size: ${props => props.theme.fontSize.small};
`

const OptionSubtext = styled.div`
  color: #565c63;
  padding-top: ${props => props.theme.gutter.half};
  font-size: ${props => props.theme.fontSize.small};
`

const SelectedItemDisplay = styled.div`
  order: 1;
  flex-grow: 1;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  color: ${props =>
    props.hasSelectedValue && !props.disabled
      ? props.customStyle
        ? props.customStyle.fontsColorPrimary
        : props.theme.navigationDark
      : props.theme.darkGrey};
  ${props => props.theme.ellipsis};
`

const OptionsContainer = styled.div`
  position: relative;
  border: 1px solid ${props => (props.customStyle ? props.customStyle.colorLines : props.borderColor)};
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  left: 0;
  width: 100%;
  max-height: 332px;
  overflow-y: auto;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
  z-index: 501;
  border-radius: 0 0 4px 4px;
`

const DropDown = styled.div`
  width: 250px;
  top: 5px;
  left: -1px;
  position: relative;
  z-index: 501;
`

const requiredValidators = Object.freeze([ValidatorTypes.notEmpty])

const callValidator = (value, vtype) => validatorFuncs[vtype](value)

export class DetailsDropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      firstSelectedElementId: null,
    }
    this.didScrollOnOpen = false
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleToggleDropdownClick = this.handleToggleDropdownClick.bind(this)
    this.isValid = this.isValid.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleKeyPressOption = this.handleKeyPressOption.bind(this)
  }

  componentWillMount() {
    const firstSelectedElementId = _.uniqueId('firstSelectedElement-')
    this.setState({ firstSelectedElementId })
  }

  componentDidUpdate() {
    const { isOpen, firstSelectedElementId } = this.state
    if (isOpen && !this.didScrollOnOpen) {
      scroller.scrollTo(firstSelectedElementId, {
        duration: 200,
        offset: 0,
        smooth: 'easeOutCubic',
        containerId: 'optionsContainer',
      })
    }
    this.didScrollOnOpen = isOpen
  }

  /**
   * Returns true if valid, otherwise invalid text
   */
  isValid() {
    const { validator, customValidator, value } = this.props
    const customIsValid = customValidator(value)

    if (customIsValid !== true) {
      return customIsValid
    }
    if (_.isEmpty(validator)) {
      return true
    }

    return callValidator(value || '', validator) || this.buildInvalidDisplayText()
  }

  handleKeyPress(event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleKeyPressOption(event, choice) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.handleItemClick(event, choice)
    }
  }

  buildInvalidDisplayText() {
    const { name, placeholder, invalidDisplayText, validator, value } = this.props
    if (!_.isEmpty(invalidDisplayText)) {
      return invalidDisplayText
    }
    const isRequiredValidator = _.includes(requiredValidators, validator)
    const showRequiredMsg = (isRequiredValidator && !value) || _.isEmpty(value)
    return _.startCase(_.toLower(name || placeholder)) + (showRequiredMsg ? ' is required' : '  is not valid')
  }

  closeDropdown() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleClickOutside(e) {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleToggleDropdownClick() {
    const { disabled } = this.props
    if (disabled) {
      return
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleItemClick(e, choice) {
    const { onChangeHandler, disabled } = this.props
    e.stopPropagation()
    if (disabled) {
      return
    }

    if (choice.hasOwnProperty('selectValue')) {
      onChangeHandler(choice.selectValue)
    } else {
      onChangeHandler(choice.value)
    }
    this.setState({ isOpen: false })
  }

  render() {
    const {
      testId,
      showCaret,
      dontShowSelectedItem,
      name,
      value,
      displayOverride,
      adtlDisplayText,
      dropDown,
      isLightTheme,
      isValid,
      placeholder,
      leftSideIcon,
      isDropUp,
      style,
      borderAreaStyle,
      disabled,
      optionsContainerStyle,
      height,
      wrapText,
      id,
      ariaDescriptor,
      customStyle,
    } = this.props
    const { isOpen, firstSelectedElementId } = this.state
    const hoverBorderColor = isLightTheme ? Theme.darkGrey : Theme.black
    const borderColor = isOpen ? hoverBorderColor : isLightTheme ? Theme.lightGrey : '#031927'

    const OptionTheme = isLightTheme ? OptionLightTheme : OptionDarkTheme
    const selectedItemNames = []

    const renderSingleOption = (choice, isLast, isSelectedChoice, optionId) => {
      let optionStyle = null
      if (!wrapText) {
        choice.style = _.extend(choice.style, { height: 'auto' })
        optionStyle = { 'white-space': 'normal' }
      }

      return (
        <OptionTheme
          onKeyDown={e => this.handleKeyPressOption(e, choice)}
          tabIndex="0"
          aria-label={choice.name}
          style={choice.style}
          key={choice.value}
          id={optionId}
          {...{ isLast, isSelectedChoice, customStyle }}
          onClick={e => this.handleItemClick(e, choice)}
        >
          <OptionDetailsContainer>
            <OptionTextContainer>
              <OptionText style={optionStyle}>{choice.name}</OptionText>
              <OptionCaption>{choice.caption}</OptionCaption>
            </OptionTextContainer>
            <OptionSubtext>{choice.subtext}</OptionSubtext>
          </OptionDetailsContainer>
        </OptionTheme>
      )
    }

    let leftSideIconElement = null
    const renderOptions = choices =>
      choices.map((choice, idx) => {
        const isLast = idx === choices.length - 1
        const isSelectedChoice = choice.value === value
        let optionId = null
        if (isSelectedChoice) {
          if (_.isEmpty(selectedItemNames)) {
            optionId = firstSelectedElementId
          }
          selectedItemNames.push(choice.name)

          if (choice.hasOwnProperty('icon')) {
            leftSideIconElement = <StyledVmsIconS style={choice.iconStyle}>{choice.icon}</StyledVmsIconS>
          }
        }
        return renderSingleOption(choice, isLast, isSelectedChoice, optionId)
      })

    if (!leftSideIconElement && leftSideIcon) {
      leftSideIconElement = <Icon>{leftSideIcon}</Icon>
    }

    const renderedOptions = renderOptions(this.props.choices)
    const hasSelectedValue = !_.isEmpty(selectedItemNames)
    let selectedItemName = _.isNil(displayOverride) ? selectedItemNames.join(', ') : displayOverride
    selectedItemName += !_.isNil(adtlDisplayText) && value !== 0 ? adtlDisplayText : ''
    const optionsContainer = (
      <OptionsContainer
        id="optionsContainer"
        data-test="sr-picker_options"
        key="optionsContainer"
        style={optionsContainerStyle}
        ariaExpanded={isOpen}
        role="list"
        {...{ borderColor, customStyle }}
      >
        {renderedOptions}
      </OptionsContainer>
    )

    const DropContainer = isDropUp ? DropUpAbsolute : DropdownAbsolute

    return (
      <OuterBody
        data-test={testId || (name ? `sr-picker-${name.toLowerCase().replace(/ /g, '_')}` : null)}
        aria-label={ariaDescriptor}
        onKeyDown={this.handleKeyPress}
        role="button"
        tabIndex="0"
        id={id}
        {...{ style, height }}
      >
        <BorderedArea
          {...{
            height,
            borderColor,
            hoverBorderColor,
            isOpen,
            isValid,
            disabled,
            customStyle,
          }}
          style={borderAreaStyle}
          onClick={this.handleToggleDropdownClick}
        >
          <BorderedAreaFlexIEFix {...{ height }} />
          {!dontShowSelectedItem && (
            <SelectedItemDisplay
              {...{
                hasSelectedValue,
                disabled,
                customStyle,
              }}
            >
              {leftSideIconElement} {selectedItemName || placeholder}
            </SelectedItemDisplay>
          )}
          {showCaret && !disabled && <Caret {...{ customStyle }} direction={this.state.isOpen ? 'up' : 'down'} />}
        </BorderedArea>
        <DropdownRelative>
          <DropContainer>
            {isOpen && !dropDown && optionsContainer}
            {isOpen && dropDown && <DropDown>{dropDown}</DropDown>}
          </DropContainer>
        </DropdownRelative>
      </OuterBody>
    )
  }
}

DetailsDropdownMenu.defaultProps = {
  choices: [],
  name: '',
  value: null,
  isValid: true,
  validator: null,
  placeholder: '',
  displayOverride: null,
  adtlDisplayText: null,
  style: {},
  height: 42,
  optionsContainerStyle: {},
  borderAreaStyle: {},
  showCaret: true,
  dropDown: null,
  isLightTheme: false,
  dontShowSelectedItem: false,
  disabled: false,
  wrapText: true,
  onChangeHandler: () => {},
  id: '',
  ariaDescriptor: '',
}

DetailsDropdownMenu.propTypes = {
  testId: React.PropTypes.string,
  isDropUp: React.PropTypes.bool,
  value: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]), // For single-select
  placeholder: React.PropTypes.string.isRequired,
  displayOverride: React.PropTypes.string,
  adtlDisplayText: React.PropTypes.string,
  choices: React.PropTypes.array.isRequired,
  dropDown: React.PropTypes.object,
  dontShowSelectedItem: React.PropTypes.bool,
  isLightTheme: React.PropTypes.bool.isRequired,
  showCaret: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  validator: React.PropTypes.oneOf(_.values(ValidatorTypes)),
  isValid: React.PropTypes.bool,
  wrapText: React.PropTypes.bool,

  onChangeHandler: React.PropTypes.func.isRequired,
  id: React.PropTypes.string,
  ariaDescriptor: React.PropTypes.string,
  customStyle: React.PropTypes.object,
}

DetailsDropdownMenu = onClickOutside(DetailsDropdownMenu)
export default DetailsDropdownMenu
