export const ProviderState = Object.freeze({
  WORKING: Symbol('working'),
  AUTHENTICATED: Symbol('authenticated'),
  POST_AUTHENTICATED: Symbol('post_authenticated'),
  DISCONNECTED: Symbol('disconnected'),
  DISCONNECTING: Symbol('disconnecting'),
  CONNECTED: Symbol('connected'),
  CONNECTING: Symbol('connecting'),
  POST_CONNECTING: Symbol('post_connecting'),
  CANT_CONNECT: Symbol('cant_connect'),
  ERROR: Symbol('error'),
})

export const transition = (container, providerState, error) => {
  container.setState({ [container.state_var]: providerState, error })
}

export const convert = r => {
  if (!r.ok) {
    throw new Error('Connection Error')
  }
  return r.json()
}

export const checkConnection = container => {
  if (!container.props.is_sizzle_enabled) {
    transition(container, ProviderState.CANT_CONNECT)
  } else if (container.state[container.state_var] !== ProviderState.ERROR) {
    fetch(`/manager/${container.props.venue_id}/emailserviceproviders/${container.url_base}/is_connected`)
      .then(convert)
      .then(r => transition(container, r[container.connected_var] ? ProviderState.CONNECTED : ProviderState.DISCONNECTED))
      .catch(err => {
        transition(container, ProviderState.ERROR)
      })
  }
}

export const disconnect = container => {
  if (container.state[container.state_var] === ProviderState.DISCONNECTING) {
    transition(container, ProviderState.WORKING)
    fetch(`/manager/${container.props.venue_id}/emailserviceproviders/${container.url_base}/disconnect`, {
      method: 'POST',
    })
      .then(convert)
      .then(r => transition(container, ProviderState.DISCONNECTED))
      .catch(r => transition(container, ProviderState.ERROR))
  }
}
