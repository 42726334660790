import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { onChangeVenueExitEditFlow } from 'mgr/lib/actions/DirtyStateUnloader'
import * as OrderingMenuManagement from 'mgr/pages/single-venue/settings/actions/ordering/OrderingMenuManagement'
import { enabledWeekdays, OrderingMenuForm } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/OrderingMenuForm'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { Route } from 'mgr/pages/single-venue/settings/settings.types'
import type {
  ExcludedDateRange,
  MenuCategory,
  MenuCategoryItem,
  MenuRouteParams,
  OrderingMenu,
} from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'
import type { RouteComponentProps } from 'react-router'

export interface MenuProps extends RouteComponentProps<MenuRouteParams> {
  routes: Route[]
  mediaUrl: string
  venue: Venue
  venues: UserDomainVenue[]
  onChangeVenue: (venue: UserDomainVenue) => void
  orderingMenu: OrderingMenu
  menuItems: MenuItem[]
  selectedMenuCategory?: MenuCategory
  createMenu: (venueId: string, menuData: OrderingMenu) => void
  updateMenuById: (venueId: string, menuId: string, menuData: OrderingMenu) => void
  loadMenuById: (venueId: string, menuId: string) => Promise<unknown>
  loadMenuItems: (venueId: string) => Promise<unknown>
  clearMenu: () => void
  onNameChange: (value: string) => void
  onPrepTimeChange: (value: number) => void
  isDirty: boolean
  isCategoryModalOpen: boolean
  onCategoryModalOpen: (category?: MenuCategory) => void
  onCategoryModalClose: () => void
  isConfirmDeleteModalOpen: boolean
  onConfirmDeleteModalClose: () => void
  onCategoryOrderChanged: (categoryName: string, position: number) => void
  onCategoryCreate: (category: MenuCategory) => void
  onConfirmDeleteModalOpen: (categoryName: string) => void
  onCategoryDelete: (categoryName: string) => void
  onCategoryMenuItemDelete: (category: MenuCategory, menuItem: MenuItem) => void
  onCategoryMenuItemsUpdate: (category: MenuCategory, menuItems: MenuCategoryItem[]) => void
  onCategoryUpdate: (categoryName: string, category: MenuCategory) => void
  isAddMenuItemModalOpen: boolean
  onAddMenuItemModalOpen: (category: MenuCategory) => void
  onAddMenuItemModalClose: () => void
  onUpdateDateRangeFrom: (value: Date) => void
  onUpdateDateRangeTo: (value: Date | null) => void
  onAddMenuAvailability: (dayOfWeekIndex: number) => void
  onRemoveMenuAvailability: (dayOfWeekIndex: number, dateRangeIndex: number) => void
  onCopyMenuAvailabilityToAll: (dayOfWeekIndex: number, dateRangeIndex: number) => void
  onMenuAvailabilityUpdate: (dayOfWeekIndex: number, dateRangeIndex: number, start?: string, end?: string) => void
  onAddMenuExcludedDateRange: (excludedDateRange: ExcludedDateRange) => void
  onUpdateMenuExcludedDateRange: (index: number, excludedDateRange: ExcludedDateRange) => void
  onRemoveExcludedDateRange: (index: number) => void
}

function Menu({
  match,
  history,
  routes,
  mediaUrl,
  venue,
  venues,
  orderingMenu,
  menuItems,
  onChangeVenue,
  selectedMenuCategory,
  updateMenuById,
  createMenu,
  loadMenuById,
  loadMenuItems,
  clearMenu,
  onNameChange,
  onPrepTimeChange,
  isDirty,
  isCategoryModalOpen,
  isConfirmDeleteModalOpen,
  onCategoryModalOpen,
  onCategoryModalClose,
  onConfirmDeleteModalClose,
  onCategoryOrderChanged,
  onCategoryCreate,
  onConfirmDeleteModalOpen,
  onCategoryDelete,
  onCategoryMenuItemDelete,
  onCategoryMenuItemsUpdate,
  onCategoryUpdate,
  isAddMenuItemModalOpen,
  onAddMenuItemModalOpen,
  onAddMenuItemModalClose,
  onUpdateDateRangeFrom,
  onUpdateDateRangeTo,
  onAddMenuAvailability,
  onRemoveMenuAvailability,
  onCopyMenuAvailabilityToAll,
  onMenuAvailabilityUpdate,
  onAddMenuExcludedDateRange,
  onUpdateMenuExcludedDateRange,
  onRemoveExcludedDateRange,
}: MenuProps) {
  const venueId = venue.id
  const { id: menuId } = match.params

  const [isLoading, updateLoading] = useState(false)
  const [isSaving, updateSaving] = useState(false)

  const openMenuListPage = useCallback(() => {
    const menuListUrl = routes[0]?.route
    if (menuListUrl) {
      history.push(menuListUrl)
    }
  }, [routes, history])

  const loadData = useCallback(
    async (menuId?: string) => {
      if (isLoading) {
        return
      }
      try {
        updateLoading(true)
        if (menuId) {
          await loadMenuById(venue.id, menuId)
        } else {
          clearMenu()
        }
        if (venue.isSevenRoomsOrderingMiddleware) {
          await loadMenuItems(venue.id)
        }
      } catch (e) {
        openMenuListPage()
      } finally {
        updateLoading(false)
      }
    },
    [isLoading, venue, loadMenuById, clearMenu, loadMenuItems, openMenuListPage]
  )

  useEffect(() => {
    loadData(menuId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId])

  const onSaveMenu = useCallback(
    async (changedMenu: OrderingMenu) => {
      if (isSaving) {
        return
      }
      try {
        updateSaving(true)
        const cleansedMenu = cleanseMenuForSave(changedMenu)
        if (menuId) {
          await updateMenuById(venueId, menuId, cleansedMenu)
        } else {
          await createMenu(venueId, cleansedMenu)
        }
        openMenuListPage()
      } finally {
        updateSaving(false)
      }
    },
    [createMenu, isSaving, menuId, openMenuListPage, updateMenuById, venueId]
  )

  const menuTitle = menuId ? `Edit Menu: ${orderingMenu?.name}` : 'Create Menu:'

  const contentTitle = isLoading ? 'Loading...' : menuTitle

  return (
    <ContentLayout
      routes={routes}
      title={contentTitle}
      venue={venue}
      venues={venues}
      showSpinner={isLoading || isSaving}
      spinnerImgUrl={mediaUrl}
      overrideChangeVenue
      onChangeVenue={onChangeVenue}
    >
      <OrderingMenuForm
        menu={orderingMenu}
        onSave={onSaveMenu}
        onCancel={openMenuListPage}
        {...{
          venue,
          menuItems,
          selectedMenuCategory,
          isSaving,
          isDirty,
          isCategoryModalOpen,
          isConfirmDeleteModalOpen,
          onNameChange,
          onPrepTimeChange,
          onConfirmDeleteModalClose,
          onCategoryModalOpen,
          onCategoryModalClose,
          onCategoryOrderChanged,
          onCategoryCreate,
          onCategoryUpdate,
          onCategoryDelete,
          onConfirmDeleteModalOpen,
          onCategoryMenuItemDelete,
          onCategoryMenuItemsUpdate,
          isAddMenuItemModalOpen,
          onAddMenuItemModalOpen,
          onAddMenuItemModalClose,
          onUpdateDateRangeFrom,
          onUpdateDateRangeTo,
          onAddMenuAvailability,
          onRemoveMenuAvailability,
          onCopyMenuAvailabilityToAll,
          onMenuAvailabilityUpdate,
          onAddMenuExcludedDateRange,
          onUpdateMenuExcludedDateRange,
          onRemoveExcludedDateRange,
        }}
      />
    </ContentLayout>
  )
}

const cleanseMenuForSave = (menu: OrderingMenu): OrderingMenu => {
  const weekdays = enabledWeekdays(menu.dateRangeFrom, menu.dateRangeTo)
  const enabledWeekdayIndices = weekdays.map(([dayOfWeekIndex]) => dayOfWeekIndex)
  const availableHours = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    ..._.pick(menu.availableHours, enabledWeekdayIndices),
  }
  return {
    ...menu,
    availableHours,
  }
}

const mapStateToProps = (state: State) => {
  const { mediaUrl, venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    mediaUrl,
    venues,
    orderingMenu: state.orderingMenu.orderingMenu,
    menuItems: state.orderingMenu.menuItems,
    selectedMenuCategory: state.orderingMenu.selectedMenuCategory,
    isCategoryModalOpen: state.orderingMenu.isCategoryModalOpen,
    isConfirmDeleteModalOpen: state.orderingMenu.isConfirmDeleteModalOpen,
    isAddMenuItemModalOpen: state.orderingMenu.isAddMenuItemModalOpen,
    isDirty: state.orderingMenu.isDirty,
  }
}

const mapDispatchToProps = {
  onChangeVenue: onChangeVenueExitEditFlow,
  loadMenuById: OrderingMenuManagement.loadMenuById,
  loadMenuItems: OrderingMenuManagement.loadMenuItems,
  updateMenuById: OrderingMenuManagement.updateMenuById,
  createMenu: OrderingMenuManagement.saveMenu,
  clearMenu: OrderingMenuManagement.clearMenu,
  onNameChange: OrderingMenuManagement.updateMenuName,
  onPrepTimeChange: OrderingMenuManagement.updateMenuPrepTime,
  onCategoryModalClose: OrderingMenuManagement.closeCategoryModal,
  onCategoryModalOpen: OrderingMenuManagement.openCategoryModal,
  onConfirmDeleteModalClose: OrderingMenuManagement.closeConfirmDeleteModal,
  onCategoryOrderChanged: OrderingMenuManagement.updateMenuCategoryOrder,
  onCategoryCreate: OrderingMenuManagement.createMenuCategory,
  onCategoryUpdate: OrderingMenuManagement.updateMenuCategory,
  onCategoryDelete: OrderingMenuManagement.deleteMenuCategory,
  onConfirmDeleteModalOpen: OrderingMenuManagement.openConfirmDeleteModal,
  onCategoryMenuItemDelete: OrderingMenuManagement.deleteCategoryMenuItem,
  onCategoryMenuItemsUpdate: OrderingMenuManagement.updateCategoryMenuItems,
  onAddMenuItemModalOpen: OrderingMenuManagement.openAddMenuItemModal,
  onAddMenuItemModalClose: OrderingMenuManagement.closeAddMenuItemModal,
  onAddMenuAvailability: OrderingMenuManagement.addMenuAvailability,
  onUpdateDateRangeFrom: OrderingMenuManagement.updateDateRangeFrom,
  onUpdateDateRangeTo: OrderingMenuManagement.updateDateRangeTo,
  onRemoveMenuAvailability: OrderingMenuManagement.removeMenuAvailability,
  onCopyMenuAvailabilityToAll: OrderingMenuManagement.copyMenuAvailabilityToAll,
  onMenuAvailabilityUpdate: OrderingMenuManagement.updateMenuAvailability,
  onAddMenuExcludedDateRange: OrderingMenuManagement.addMenuExcludedDateRange,
  onUpdateMenuExcludedDateRange: OrderingMenuManagement.updateMenuExcludedDateRange,
  onRemoveExcludedDateRange: OrderingMenuManagement.removeExcludedDateRange,
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
