import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'
import ButtonIcon from 'svr/component-lib/Generic/Buttons/ButtonIcon'
import CheckboxButton from 'svr/component-lib/Generic/Checkbox/CheckboxButton'
import Icon from 'svr/component-lib/Generic/Icon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 62px;
`

const Title = styled.div`
  font-weight: bold;
`

const Header = styled.div`
  display: flex;
  align-content: center;
`

const getNextValue = values => {
  if (values.every(value => value === true)) {
    return false
  }

  if (values.every(value => value === false)) {
    return true
  }

  if (values.some(value => value === false)) {
    return false
  }

  if (values.some(value => value === true)) {
    return true
  }

  return false
}

const getUsedIcon = values => {
  if (values.length === 0) {
    return ''
  }

  if (values.every(value => value === true)) {
    return Icon.ICONS.Check
  }

  if (values.every(value => value === false)) {
    return ''
  }

  return Icon.ICONS.Minus
}

/**
 * @typedef {{
 *   checkedFields: boolean[];
 *   disabledFields: boolean[];
 *   onChange: function;
 *   disabled: boolean;
 *   isValid: boolean;
 *   title: string;
 *   isDefaultCollapsed: boolean;
 * }} VmsCheckboxGroupProps
 *
 * @type {React.FC<VmsCheckboxGroupProps>}
 */
const VmsCheckboxGroup = ({
  disabled,
  isValid,
  checkedFields,
  disabledFields,
  title,
  onChange,
  children,
  isDefaultCollapsed,
  ...props
}) => {
  const checkingValues = checkedFields.filter((field, index) => !disabledFields[index])

  const passAttrs = passDataAttrs(props)
  const [isCollapsed, updateCollapse] = useState(isDefaultCollapsed)
  const handlerChange = useCallback(() => {
    const nextValue = getNextValue(checkingValues)
    onChange(nextValue)
  }, [onChange, checkingValues])

  const buttonIcon = isCollapsed ? ButtonIcon.ICONS.ChevronDown : ButtonIcon.ICONS.ChevronUp

  const checkboxIcon = getUsedIcon(checkedFields)
  const isChecked = !!checkboxIcon

  return (
    <div>
      <Header>
        <ButtonIcon
          disabled={disabled}
          icon={buttonIcon}
          onClick={() => {
            updateCollapse(!isCollapsed)
          }}
          {...passAttrs}
        />
        <CheckboxButton disabled={disabled} icon={checkboxIcon} value={isChecked} isValid={isValid} onChange={handlerChange} {...passAttrs}>
          <Title>{title}</Title>
        </CheckboxButton>
      </Header>
      {!isCollapsed && <Container>{children}</Container>}
    </div>
  )
}

VmsCheckboxGroup.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isDefaultCollapsed: PropTypes.bool.isRequired,
  checkedFields: PropTypes.arrayOf(PropTypes.bool).isRequired,
  disabledFields: PropTypes.arrayOf(PropTypes.bool).isRequired,
  onChange: PropTypes.func.isRequired,
}

VmsCheckboxGroup.defaultProps = {
  disabled: false,
  isValid: true,
  isDefaultCollapsed: false,
  title: '',
}

export default VmsCheckboxGroup
