import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'
import IconTags from 'svr/component-lib/Manager/Containers/IconTags'

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
`

const TagDropdownWrapper = styled.div`
  border: 1px solid ${props => (props.isActive ? '#347baf' : '#DBDCDE')};};
  border-radius: 4px;
  padding: 16px 12px;
  font-size: 15px;
`

const SelectedTagsWrapper = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
`

const TagsLeftSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const SelectedTagsDisplay = styled.div`
  margin: ${props => (props.addPadding ? '12px 0 0 26px' : '0')};
`

const IconTagsWrapper = styled.div`
  padding: 16px;
  margin: 2px 0 0 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: ${props => (props.isVisible ? 'block' : 'none')};
  overflow-y: ${props => (props.maxHeight ? 'scroll' : 'visible')};
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : 'none')};
`

const IconTagsSection = styled.div`
  padding: 0 0 ${props => (props.isLast ? '0' : props.theme.gutter.double)} 0;
`
const IconTagsHeader = styled.div`
  color: #161718;
  display: flex;
  align-items: center;
  padding: 0 0 ${props => props.theme.gutter.standard} 0;
`
const IconTagsTitle = styled.div`
  font-size: 12px;
  padding: 0 0 0 4px;
`

const SearchIcon = styled(StyledIcons.S)`
  color: #888c92;
  padding: 0 ${props => props.theme.gutter.standard} 0 0;
`

const IconTagHeaderIcon = styled(StyledIcons.XS)``

const CaretIcon = styled(StyledIcons.S)`
  color: #888c92;
`

const UpCaretIcon = styled(CaretIcon)`
  transform: rotate(-90deg);
`

const DownCaretIcon = styled(CaretIcon)`
  transform: rotate(90deg);
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  color: ${props => props.theme.color.secondary};
  font-size: 15px;
  padding: ${props => props.theme.gutter.standard} 0;
  display: ${props => (props.isVisible ? 'block' : 'none')};
`

export class TagDropdownMenu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      isDropdownFocused: false,
      searchValue: '',
      selectedValues: this.props.selectedValues,
      choices: this.props.choices,
      nestedChoices: this.props.nestedChoices,
    }
    this.onDropdownFocus = this.onDropdownFocus.bind(this)
    this.dropDownClickHandler = this.dropDownClickHandler.bind(this)
    this.setIsActiveTrue = this.setIsActiveTrue.bind(this)
    this.focusSearchInput = this.focusSearchInput.bind(this)
    this.getChoiceIdxByNestedClassifier = this.getChoiceIdxByNestedClassifier.bind(this)
    this.onSelectedTagClickHandler = this.onSelectedTagClickHandler.bind(this)
    this.onChoiceTagClickHandler = this.onChoiceTagClickHandler.bind(this)
    this.onNestedSelectedTagClickHandler = this.onNestedSelectedTagClickHandler.bind(this)
    this.onNestedChoiceTagClickHandler = this.onNestedChoiceTagClickHandler.bind(this)
  }

  componentWillMount() {
    document.addEventListener('click', this.onDropdownFocus, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDropdownFocus, false)
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.selectedValues) !== JSON.stringify(this.props.selectedValues) ||
      JSON.stringify(prevProps.choices) !== JSON.stringify(this.props.choices) ||
      JSON.stringify(prevProps.nestedChoices) !== JSON.stringify(this.props.nestedChoices)
    ) {
      this.setState({
        selectedValues: this.props.selectedValues,
        choices: this.props.choices,
        nestedChoices: this.props.nestedChoices,
      })
    }
  }

  focusSearchInput() {
    this.searchField.focus()
    this.searchField.setSelectionRange(this.searchField.value.length, this.searchField.value.length)
  }

  onDropdownFocus(e) {
    if (this.node && this.node.contains(e.target)) {
      this.setState({
        isDropdownFocused: true,
      })
    } else {
      this.setState({
        isDropdownFocused: false,
        isActive: false,
        searchValue: '',
      })
    }
  }

  dropDownClickHandler(e) {
    if (e.target.className.indexOf('selected-icon-tags') === -1) {
      this.focusSearchInput()
      this.setState({
        isActive: !this.state.isActive,
      })
    }
  }

  setIsActiveTrue() {
    this.focusSearchInput()
    this.setState({ isActive: true })
  }

  changeSearchInputHandler(e) {
    this.setState({ searchValue: e.target.value })
  }

  removeTagFromTags(tags, val) {
    return _.filter(tags, tag => tag.value !== val)
  }

  onSelectedTagClickHandler(idx, classifier) {
    const selectedTag = this.state.selectedValues[idx]
    const newSelectedTags = this.removeTagFromTags(this.state.selectedValues, selectedTag.value)
    const selectChoiceTags = this.state.choices.splice(0)
    const choiceTagIdx = _.findIndex(selectChoiceTags, {
      value: selectedTag.value,
    })
    selectChoiceTags[choiceTagIdx].isSelected = !selectChoiceTags[choiceTagIdx].isSelected
    this.setState({
      selectedValues: newSelectedTags,
      choices: selectChoiceTags,
      isDropdownFocused: true,
    })
    this.props.onSelectedTagClickHandler(newSelectedTags, selectChoiceTags)
  }

  onChoiceTagClickHandler(idx, classifier) {
    const choiceTags = this.state.choices.splice(0)
    choiceTags[idx].isSelected = !choiceTags[idx].isSelected
    let selectedTags = []
    if (choiceTags[idx].isSelected) {
      selectedTags = [...this.state.selectedValues, choiceTags[idx]]
    } else {
      selectedTags = this.removeTagFromTags(this.state.selectedValues, choiceTags[idx].value)
    }
    this.setState({
      selectedValues: selectedTags,
      choices: choiceTags,
      isDropdownFocused: true,
    })
    this.props.onChoiceTagClickHandler(selectedTags, choiceTags)
  }

  getChoiceIdxByNestedClassifier(classifier) {
    return _.findIndex(this.state.nestedChoices, { id: classifier })
  }

  onNestedSelectedTagClickHandler(idx, classifier) {
    const selectedTag = this.state.selectedValues[idx]
    const newSelectedTags = this.removeTagFromTags(this.state.selectedValues, selectedTag.value)
    const choiceIdx = this.getChoiceIdxByNestedClassifier(classifier)
    const selectChoiceTags = this.state.nestedChoices[choiceIdx].choices
    const choiceTagIdx = _.findIndex(selectChoiceTags, {
      value: selectedTag.value,
    })
    selectChoiceTags[choiceTagIdx].isSelected = !selectChoiceTags[choiceTagIdx].isSelected
    const nestedChoices = this.state.nestedChoices.splice(0)
    nestedChoices[choiceIdx].choices = selectChoiceTags
    this.setState({
      selectedValues: newSelectedTags,
      nestedChoices,
      isDropdownFocused: true,
    })
    this.props.onNestedSelectTagClickHandler(newSelectedTags, nestedChoices)
  }

  onNestedChoiceTagClickHandler(idx, classifier) {
    const nestedChoiceIdx = this.getChoiceIdxByNestedClassifier(classifier)
    const choiceTags = this.state.nestedChoices[nestedChoiceIdx].choices
    choiceTags[idx].isSelected = !choiceTags[idx].isSelected
    let selectedTags = []
    if (choiceTags[idx].isSelected) {
      selectedTags = [...this.state.selectedValues, choiceTags[idx]]
    } else {
      selectedTags = this.removeTagFromTags(this.state.selectedValues, choiceTags[idx].value)
    }
    const newNestedChoices = this.state.nestedChoices.splice(0)
    newNestedChoices[nestedChoiceIdx].choices = choiceTags
    this.setState({
      selectedValues: selectedTags,
      nestedChoices: newNestedChoices,
      isDropdownFocused: true,
    })
    this.props.onNestedChoiceTagClickHandler(selectedTags, newNestedChoices)
  }

  render() {
    const displayDropDown = this.state.isActive && this.state.isDropdownFocused
    const hasSelectedValues = this.state.selectedValues && this.state.selectedValues.length !== 0
    return (
      <InputWrapper id={`tag-dropdown-${this.props.id}`} ref={node => (this.node = node)}>
        <TagDropdownWrapper isActive={displayDropDown} onClick={e => this.dropDownClickHandler(e)}>
          <SelectedTagsWrapper isVisible={displayDropDown || !hasSelectedValues} className="selected-icon-tags-wrapper">
            <TagsLeftSection>
              {displayDropDown && <SearchIcon>{VmsIcons.Search}</SearchIcon>}
              <SearchInput
                id={`search-icon-tags-filters-${this.props.id}`}
                className="selected-icon-tags-search-filter"
                value={this.state.searchValue}
                placeholder={this.props.placeholder}
                onClick={this.setIsActiveTrue}
                onChange={e => this.changeSearchInputHandler(e)}
                ref={searchField => (this.searchField = searchField)}
                isVisible={this.state.isActive || !hasSelectedValues}
              />
            </TagsLeftSection>
            {displayDropDown ? <UpCaretIcon>{VmsIcons.Chevron}</UpCaretIcon> : <DownCaretIcon>{VmsIcons.Chevron}</DownCaretIcon>}
          </SelectedTagsWrapper>
          <SelectedTagsWrapper isVisible={hasSelectedValues} className="selected-icon-tags-display-wrapper" onClick={this.setIsActiveTrue}>
            <SelectedTagsDisplay className="selected-icon-tags-display" addPadding={displayDropDown}>
              <IconTags
                className={`${this.props.id}-selected-icon-tags`}
                onClickHandler={this.state.nestedChoices ? this.onNestedSelectedTagClickHandler : this.onSelectedTagClickHandler}
                tags={this.state.selectedValues}
                selectedIcon={this.props.selectedIcon}
                unselectedIcon={this.props.unselectedIcon}
              />
            </SelectedTagsDisplay>
            {!displayDropDown && <DownCaretIcon>{VmsIcons.Chevron}</DownCaretIcon>}
          </SelectedTagsWrapper>
        </TagDropdownWrapper>
        <IconTagsWrapper isVisible={displayDropDown} maxHeight={this.props.maxHeight}>
          {this.state.choices && (
            <IconTags
              className={`${this.props.id}-choice-icon-tags`}
              onClickHandler={this.onChoiceTagClickHandler}
              tags={this.state.choices}
              filterVal={this.state.searchValue}
              selectedIcon={this.props.selectedChoiceIcon}
              unselectedIcon={this.props.unselectedIcon}
            />
          )}
          {this.state.nestedChoices &&
            _.map(this.state.nestedChoices, (nestedChoice, idx) => (
              <IconTagsSection isLast={this.state.nestedChoices.length - 1 === idx} key={`icon-tags-sections-${nestedChoice.header}`}>
                <IconTagsHeader key={`icon-tags-header-${nestedChoice.header}`}>
                  <IconTagHeaderIcon key={`icon-tags-icon-${nestedChoice.header}`}>{this.props.iconTagHeaderIcon}</IconTagHeaderIcon>
                  <IconTagsTitle key={`icon-tags-title-${nestedChoice.header}`}>{nestedChoice.header}</IconTagsTitle>
                </IconTagsHeader>
                <IconTags
                  className={`${this.props.id}-choice-icon-tags`}
                  onClickHandler={this.onNestedChoiceTagClickHandler}
                  tags={nestedChoice.choices}
                  filterVal={this.state.searchValue}
                  selectedIcon={this.props.selectedChoiceIcon}
                  unselectedIcon={this.props.unselectedIcon}
                />
              </IconTagsSection>
            ))}
        </IconTagsWrapper>
      </InputWrapper>
    )
  }
}

export default TagDropdownMenu
