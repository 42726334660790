import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { PacingRule } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'

const computePacingRuleListParams = (state: State) => ({
  orderingPacingRulesList: state.orderingOrderPacing.list,
  pacingRuleFilterSearchValue: state.orderingOrderPacing.pacingRuleFilterSearchValue,
})

const computeFilteredBySearchValuePacingRuleList = ({
  orderingPacingRulesList,
  pacingRuleFilterSearchValue,
}: {
  orderingPacingRulesList: PacingRule[]
  pacingRuleFilterSearchValue: string
}) =>
  pacingRuleFilterSearchValue
    ? orderingPacingRulesList.filter(pacingRule => pacingRule?.name?.toLowerCase().includes(pacingRuleFilterSearchValue.toLowerCase()))
    : orderingPacingRulesList

export const selectOrderingPacingRuleList = createShallowEqualSelector(
  computePacingRuleListParams,
  computeFilteredBySearchValuePacingRuleList
)
