import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import {
  tryGetVenueLanguages,
  onDefaultCellClick,
  onIsEnabledCellClick,
  trySaveVenueLanguageSettings,
  resetLanguageSettings,
} from 'mgr/pages/single-venue/settings/actions/LanguageActions'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import defaultButtonCell from 'svr/component-lib/Generic/Tables/Cells/defaultButton'
import switchCell from 'svr/component-lib/Generic/Tables/Cells/switch'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { SaveButton } from 'svr/component-lib/Manager/Layout/LanguageContent'

const customTableContainerStyles = css`
  .ReactTable {
    border-radius: 4px;

    .rt-tbody {
      .rt-tr:not(.-padRow) {
        background: transparent;

        :hover {
          cursor: default;
          background: transparent;

          .default-button-container {
            opacity: 1;
          }
        }
    }
  }
`

const Subtitle = styled.h3`
  margin-top: 0;
  margin-bottom: ${props => props.theme.gutter.double};
  font-size: ${props => props.theme.fontSize.body};
  font-weight: bold;
`

const LanguageSettingsContainer = styled.div`
  padding: ${props => props.theme.gutter.double};
`

class LanguageSettings extends PureComponent {
  componentDidMount() {
    if (this.props.languageTableRows.length === 0) {
      this.props.tryGetVenueLanguages(this.props.venue.id)
    }
  }

  componentWillUnmount() {
    this.props.resetLanguageSettings()
  }

  render() {
    const {
      venue,
      venues,
      languageTableRows,
      isLoading,
      onDefaultCellClick,
      onIsEnabledCellClick,
      trySaveVenueLanguageSettings,
      onChangeVenue,
    } = this.props

    return (
      <ContentLayout
        title="Language Settings"
        venue={venue}
        venues={venues}
        onChangeVenue={onChangeVenue}
        Actions={
          <SaveButton disabled={isLoading} onClick={trySaveVenueLanguageSettings} id="language-settings-save-publish-button">
            Save and Publish
          </SaveButton>
        }
      >
        <LanguageSettingsContainer id="language-settings">
          <Subtitle>Language Selector</Subtitle>

          <Table
            data={languageTableRows}
            loading={isLoading}
            customTableContainerStyles={customTableContainerStyles}
            pageSize={languageTableRows.length}
            pages={languageTableRows.length}
            showPagination={false}
            resizable={false}
            columns={[
              {
                Header: 'Language',
                accessor: 'name',
                sortable: false,
              },
              {
                Header: 'Translation Availability',
                accessor: 'is_enabled',
                sortable: false,
                Cell: switchCell.bind(null, onIsEnabledCellClick),
              },
              {
                Header: 'Set as Default',
                accessor: 'is_default',
                sortable: false,
                Cell: defaultButtonCell.bind(null, onDefaultCellClick),
              },
            ]}
          />
        </LanguageSettingsContainer>
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    languageTableRows: state.languages.editLanguageSettingsObj.venueLanguages,
    isLoading: state.languages.isLoading,
  }
}

const mapDispatchToProps = {
  tryGetVenueLanguages,
  onDefaultCellClick,
  onIsEnabledCellClick,
  trySaveVenueLanguageSettings,
  resetLanguageSettings,
  onChangeVenue,
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettings)
