import { useLocales } from '@sevenrooms/core/locales'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../../locales'
import { type DetailProps, formatParagraph } from '../../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { MarketingAutomationsSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'
import { FeatureEnablementProofSection } from '../../../Proofs'

export function MarketingAutomationsDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { insight } = data

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={formatMessage(insightsMessages.featureUsageMarketingAutomationsText, {
        p: (chunks: string[]) => formatParagraph(chunks),
      })}
      proofLabel={formatMessage(insightsMessages.featureUsageMarketingAutomationsProofLabel)}
      suggestedActionsComponent={<MarketingAutomationsSuggestedActions venueUrlKey={venue.urlKey} />}
      proofComponent={
        <FeatureEnablementProofSection
          title={formatMessage(insightsMessages.featureUsageMarketingAutomationsProofTitle)}
          lastCheckedDate={insight.createdDatetime}
        />
      }
    />
  )
}
