import React from 'react'
import { Tooltip } from 'react-lightweight-tooltip'
import styled from 'styled-components'
import {
  INTEGRATION_KEY_DOORDASH,
  INTEGRATION_KEY_YELLO,
} from 'mgr/pages/single-venue/settings/actions/ordering/DeliveryIntegrationActions'
import { FEE_AMOUNT } from 'mgr/pages/single-venue/settings/constants'
import Icon from 'svr/component-lib/Generic/Icon'
import RadioButton from 'svr/component-lib/Generic/RadioButtons/RadioButton'
import Input from 'svr/component-lib/Generic/TextInputs/Input'
import { toolTipStyle } from 'svr/component-lib/Generic/Tooltip/Tooltip'
import { FULFILLMENT_METHOD } from 'svr/constants'
import AmountFixed from './AmountFixed'
import CurrencyInput from './CurrencyInput'
import MultiInputRow from './MultiInputRow'

const Title = styled.div`
  display: flex;
`

const IconWrapper = styled.div`
  color: #93979d;
  margin-left: 8px;
`

const checkDeliveryOnlyOptionsVisible = fulfillmentMethods =>
  fulfillmentMethods.length === 1 && fulfillmentMethods[0] === FULFILLMENT_METHOD.DELIVERY

const PASS_THROUGH_FEE_SUPPORTED_PARTNERS = [INTEGRATION_KEY_DOORDASH, INTEGRATION_KEY_YELLO]
const checkPassThroughSupported = activeDeliveryPartnerKey => PASS_THROUGH_FEE_SUPPORTED_PARTNERS.includes(activeDeliveryPartnerKey)

const TextBetweenInputs = styled.div`
  margin: 0 14px;
`

const InputWrapper = styled.div`
  margin-left: 24px;
  margin-bottom: 6px;
`

const FractionInput = ({ value, onChange, isValid }) => (
  <Input
    data-test="service-fee-amount-percentage-input"
    width={150}
    isValid={isValid}
    value={value}
    onChange={onChange}
    type="number"
    min={0}
    max={100}
    suffix="%"
  />
)

const FeeAmount = ({
  fulfillmentMethods,
  amountFixed,
  amountFraction,
  feeAmount,
  distanceTiers,
  distanceUnit,
  costPerExtraDistanceUnit,
  isValidFixed,
  isValidDistanceTierAmounts,
  isValidDistanceTierDistances,
  isValidFraction,
  onChangeFee,
  onChangeAmountFixed,
  onChangeAmountFraction,
  onChangeDistanceTiers,
  onChangeDistanceUnit,
  onChangeCostPerExtraDistanceUnit,
  currencySymbol,
  deliveryPartnerKey,
}) => {
  const isDeliveryOnlyOptionsVisible = checkDeliveryOnlyOptionsVisible(fulfillmentMethods)
  const isDeliveryPartnerSupportsPassThrough = checkPassThroughSupported(deliveryPartnerKey)
  const isPercentageSelected = feeAmount === FEE_AMOUNT.PERCENTAGE
  const isAmountSelected = feeAmount === FEE_AMOUNT.AMOUNT
  const isBothSelected = feeAmount === FEE_AMOUNT.BOTH

  return (
    <>
      <RadioButton
        data-test="service-fee-percentage-radio"
        name="feeAmount"
        checked={isPercentageSelected}
        value={FEE_AMOUNT.PERCENTAGE}
        onChange={onChangeFee}
      >
        Percentage of item subtotal
      </RadioButton>

      {isPercentageSelected && (
        <InputWrapper>
          <FractionInput isValid={isValidFraction} value={amountFraction} onChange={onChangeAmountFraction} />
        </InputWrapper>
      )}

      <RadioButton
        data-test="service-fee-amount-radio"
        name="feeAmount"
        checked={isAmountSelected}
        value={FEE_AMOUNT.AMOUNT}
        onChange={onChangeFee}
      >
        Fixed Amount
      </RadioButton>

      {isAmountSelected && (
        <AmountFixed
          {...{
            isValidFixed,
            isValidDistanceTierAmounts,
            isValidDistanceTierDistances,
            amountFixed,
            onChangeAmountFixed,
            distanceTiers,
            onChangeDistanceTiers,
            distanceUnit,
            onChangeDistanceUnit,
            costPerExtraDistanceUnit,
            onChangeCostPerExtraDistanceUnit,
            isDeliveryOnlyOptionsVisible,
            currencySymbol,
          }}
        />
      )}

      <RadioButton
        data-test="service-fee-both-radio"
        name="feeAmount"
        checked={isBothSelected}
        value={FEE_AMOUNT.BOTH}
        onChange={onChangeFee}
      >
        Percentage of item subtotal + fixed amount
      </RadioButton>
      {isBothSelected && (
        <MultiInputRow>
          <FractionInput isValid={isValidFraction} value={amountFraction} onChange={onChangeAmountFraction} />
          <TextBetweenInputs>
            <Icon icon={Icon.ICONS.Plus} size={12} />
          </TextBetweenInputs>
          <CurrencyInput isValid={isValidFixed} value={amountFixed} onChange={onChangeAmountFixed} currencySymbol={currencySymbol} />
        </MultiInputRow>
      )}

      {isDeliveryOnlyOptionsVisible && isDeliveryPartnerSupportsPassThrough && (
        <RadioButton
          data-test="service-fee-pass-through-radio"
          name="feeAmount"
          checked={feeAmount === FEE_AMOUNT.PASS_THROUGH}
          value={FEE_AMOUNT.PASS_THROUGH}
          onChange={onChangeFee}
        >
          <Title>
            Pass through from delivery integration
            <IconWrapper>
              <Tooltip
                styles={{
                  ...toolTipStyle,
                  tooltip: { ...toolTipStyle.tooltip },
                }}
                content="This will be calculated based on the distance the customer has input"
              >
                <Icon icon={Icon.ICONS.Info} size={15} />
              </Tooltip>
            </IconWrapper>
          </Title>
        </RadioButton>
      )}
    </>
  )
}

export default FeeAmount
