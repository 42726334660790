import _ from 'lodash'
import { type FormEvent, useMemo, useState } from 'react'
import styled from 'styled-components'
import { MenuItemRow } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/AddMenuItem/MenuItemRow'
import { SelectFilter, SelectTarget } from '@sevenrooms/core/ui-kit/core'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import type { MenuCategory, MenuCategoryItem } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface MenuItemSearchFieldProps {
  venue: Venue
  category: MenuCategory
  menuItems: MenuItem[]
  selectedMenuItems: MenuCategoryItem[]
  setSelectedMenuItems: (menuItems: MenuCategoryItem[]) => void
}

export function MenuItemSearchField({ category, venue, menuItems, selectedMenuItems, setSelectedMenuItems }: MenuItemSearchFieldProps) {
  const [searchString, setSearchString] = useState('')
  const filteredMenuItemsMap = useMemo(() => {
    const value = searchString.trim().toLowerCase()
    return _.keyBy(value ? menuItems.filter(menuItem => menuItem?.name?.toLowerCase().includes(value)) : menuItems, 'id')
  }, [menuItems, searchString])
  const savedItemsMap = useMemo(() => _.keyBy(category.items, 'id'), [category.items])
  const selectedItemsMap = useMemo(() => _.keyBy(selectedMenuItems, 'id'), [selectedMenuItems])

  return (
    <>
      <SelectTarget>
        <SelectFilter
          placeholder="Search by product name or identifier/PLU"
          selectedValue={searchString || null}
          searchable
          onChange={(event: FormEvent<HTMLInputElement>) => {
            setSearchString(event.currentTarget.value)
          }}
        />
      </SelectTarget>
      <SelectContent pl="sm" pr="sm" pt="s" pb="s">
        {menuItems.map(menuItem => (
          <MenuItemRow
            key={menuItem.id}
            venue={venue}
            menuItem={menuItem}
            isVisible={!!filteredMenuItemsMap[menuItem.id]}
            isSaved={!!savedItemsMap[menuItem.id]}
            isSelected={!!selectedItemsMap[menuItem.id]}
            onAdd={(menuItem: MenuItem) => {
              setSelectedMenuItems([...selectedMenuItems, { id: menuItem.id }])
            }}
            onRemove={(menuItem: MenuItem) => {
              setSelectedMenuItems(selectedMenuItems.filter((selectedMenuItem: MenuCategoryItem) => selectedMenuItem.id !== menuItem.id))
            }}
          />
        ))}
      </SelectContent>
    </>
  )
}

const SelectContent = styled(Box)`
  height: 416px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.mediumGrey};
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
`
