import React from 'react'
import styled from 'styled-components'
import { Input, TextArea } from 'svr/common/FormElement'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'

const CellWrapper = styled.div`
  width: 100%;
  padding: 0;
  height: 100%;
  border: 1px solid transparent;
  border-color: ${props => (props.isSelected ? '#347baf' : 'transparent')};
  outline: none;
  input:focus,
  textarea:focus {
    outline: none !important;
  }
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  input,
  textarea {
    cursor: pointer;
    background: transparent;
    font-size: 15px;
    font-family: Roboto;
    border: 1px solid transparent;
    width: 100%;
    color: black;
    padding: 0px;
    outline: none;
    height: 100%;
    max-width: 80%;
    margin: 0;
    padding-left: ${props => props.theme.gutter.triple};
    padding-top: ${props => props.theme.gutter.half};
    padding-bottom: ${props => props.theme.gutter.half};
    &::placeholder {
      color: #888c92;
    }
  }
  transition: 0.3s opacity ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  textarea {
    resize: none;
    line-height: 17.58px;
    max-height: 300px;
  }
  p.input {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
  }
`
const Edit = styled(StyledVmsIconS)`
  font-size: 14px;
  z-index: 1;
  color: ${props => props.theme.color.grey};
  display: none;
  ${CellWrapper}:hover & {
    display: ${props => (props.isEditMode ? 'none' : 'block')};
  }
`

const IconWrapper = styled.div`
  display: flex;
  position: relative;
  top: 34%;
`

const InputWrapper = styled.div`
  width: 90%;
`

const editableCell = (
  data,
  handleInputChange,
  tableId,
  columnIndex,
  valueFieldName = '',
  cellInfo,
  placeholder = '',
  useInputTextArea = false,
  innerComponent = null
) => (
  <EditableCell
    data={data}
    handleInputChange={handleInputChange}
    tableId={tableId}
    columnIndex={columnIndex}
    valueFieldName={valueFieldName}
    cellInfo={cellInfo}
    placeholder={placeholder}
    useInputTextArea={useInputTextArea}
    innerComponent={innerComponent}
  />
)
class EditableCell extends React.PureComponent {
  constructor(props) {
    super(props)
    const initialCellValue = props.cellInfo.value
      ? props.valueFieldName
        ? props.cellInfo.value[props.valueFieldName]
        : props.cellInfo.value
      : ''
    this.state = {
      isEditMode: false,
      cellId: `edit-cell-table${props.tableId}-column${props.columnIndex}-row${props.cellInfo.index}`,
      fallbackPlaceholderValue: initialCellValue,
      activePlaceholderValue: initialCellValue,
      isSelected: false,
    }
    this.input = null
  }

  componentDidMount() {
    this.resizeTextArea()
  }

  toggleEditMode = mode => {
    this.setState({ isEditMode: mode })
    if (!this.input.value) {
      this.setState({
        activePlaceholderValue: this.state.fallbackPlaceholderValue,
      })
    }
  }

  handleInputWrapperClick = mode => {
    this.toggleEditMode(mode)
    this.input.focus()
    this.setState({
      isSelected: this.input === document.activeElement,
    })
  }

  handleBlur = () => {
    this.toggleEditMode(false)
    this.setState({
      isSelected: false,
    })
  }

  resizeTextArea = () => {
    this.input.style.height = ''
    this.input.style.height = `${this.input.scrollHeight + 8}px`
  }

  render() {
    const { handleInputChange, valueFieldName = '', cellInfo, showMoreIcon, placeholder, useInputTextArea, innerComponent } = this.props
    const value = cellInfo.value ? (valueFieldName ? cellInfo.value[valueFieldName] : cellInfo.value) : ''
    return (
      <CellWrapper
        onClick={() => this.handleInputWrapperClick(true)}
        isSelected={this.state.isSelected}
        onBlur={this.handleBlur}
        tabIndex="0"
      >
        <InputWrapper>
          {useInputTextArea ? (
            <TextArea
              id={this.state.cellId}
              value={value}
              onChangeHandler={handleInputChange.bind(null, cellInfo)}
              onBlurHandler={this.handleBlur}
              onInputHandler={this.resizeTextArea}
              placeholder={this.state.activePlaceholderValue || placeholder}
              inputRef={e => (this.input = e)}
              inputCss={{ maxWidth: '90%' }}
            />
          ) : (
            <Input
              id={this.state.cellId}
              value={value}
              size="300"
              onChangeHandler={handleInputChange.bind(null, cellInfo)}
              onBlurHandler={this.handleBlur}
              placeholder={this.state.activePlaceholderValue || placeholder}
              inputRef={e => (this.input = e)}
              inputCss={{ maxWidth: '90%' }}
            />
          )}
        </InputWrapper>
        <IconWrapper>
          <Edit isEditMode={this.state.isEditMode} onClick={() => this.handleInputWrapperClick(true)}>
            {VmsIcons.Editpencil}
          </Edit>
          {innerComponent}
        </IconWrapper>
      </CellWrapper>
    )
  }
}
export default editableCell
