import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { SUPPORTED_PAYMENT_SYSTEMS_LIST_URL } from '../../../../utils'

export function PaymentEnabledSuggestedActions() {
  const { formatMessage } = useLocales()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-payment-enabled-suggested-actions">
      <Text data-test="insights-payment-enabled-suggested-actions-review">
        1.{' '}
        {formatMessage(insightsMessages.paymentEnabledReviewSystemsStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link
              to={SUPPORTED_PAYMENT_SYSTEMS_LIST_URL}
              target="_blank"
              data-test="insights-payment-enabled-suggested-actions-supported-pos-link"
            >
              {chunks}
            </Link>
          ),
        })}
      </Text>
      <Text data-test="insights-payment-enabled-suggested-actions-config">
        2. {formatMessage(insightsMessages.paymentEnabledConfigStep)}
      </Text>
      <Text data-test="insights-payment-enabled-suggested-actions-signup">
        3. {formatMessage(insightsMessages.paymentEnabledSignupStep)}
      </Text>
      <Text data-test="insights-payment-enabled-suggested-actions-decline">
        4. {formatMessage(insightsMessages.paymentEnabledDeclineStep)}
      </Text>
    </VStack>
  )
}
