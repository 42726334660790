import { srDelete, srGet, srPost, throwFetchError } from '@sevenrooms/core/api'

export const fetchVenuePods = params =>
  srGet(`/api-yoa/venuegroup/venue_pods`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const createSaveUser = params =>
  srPost(`/api-yoa/users/user`, params, {
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const loadUser = params =>
  srGet(`/api-yoa/users/user`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const deleteUser = params =>
  srDelete(`/api-yoa/users/user`, params, {
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const lockUser = params =>
  srPost(`/api-yoa/users/user/lock`, params, {
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

export const loadUsers = params =>
  srGet(`/api-yoa/users/users`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)
