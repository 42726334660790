import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { saveOrderingCheckoutSettings } from 'mgr/lib/services/OrderingCheckoutSettingsServices'
import {
  createOrderingSite,
  deleteOrderingSite,
  getOrderingSite,
  getOrderingSites,
  getOrderingSiteSyncedPosTables,
  saveOrderingSite,
} from 'mgr/lib/services/SitesServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { batchActions } from 'svr/common/ReduxUtils'

export const tryGetOrderingSites = venueId => dispatch => {
  dispatch({
    type: ActionTypes.GET_ORDERING_SITES_START,
  })

  return getOrderingSites(venueId)
    .then(response => {
      dispatch({
        type: ActionTypes.GET_ORDERING_SITES_SUCCESS,
        data: response.data,
      })

      return response
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage('Unable to fetch sites list, please try again later or contact customer support.'))

      dispatch({
        type: ActionTypes.GET_ORDERING_SITES_FAIL,
      })

      return e
    })
}

export const tryGetOrderingSite = (venueId, orderingSiteId) => dispatch => {
  dispatch({
    type: ActionTypes.GET_ORDERING_SITE_START,
  })

  return getOrderingSite(venueId, orderingSiteId)
    .then(response => {
      dispatch({
        type: ActionTypes.GET_ORDERING_SITE_SUCCESS,
        data: response.data,
      })

      return response
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage(`Unable to fetch site data: ${e}`))

      dispatch({
        type: ActionTypes.GET_ORDERING_SITE_FAIL,
      })

      return e
    })
}

export const tryGetOrderingSiteSyncedPosTables = venueId => dispatch => {
  dispatch({
    type: ActionTypes.GET_ORDERING_SITE_SYNCED_POS_TABLES_START,
  })

  return getOrderingSiteSyncedPosTables(venueId)
    .then(response => {
      dispatch({
        type: ActionTypes.GET_ORDERING_SITE_SYNCED_POS_TABLES_SUCCESS,
        data: response.data,
      })

      return response
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage(`Unable to fetch site synced pos tables data: ${e}`))

      dispatch({
        type: ActionTypes.GET_ORDERING_SITE_SYNCED_POS_TABLES_FAIL,
      })

      return e
    })
}

export const tryDeleteOrderingSite = (venueId, orderingSiteId) => dispatch => {
  dispatch({
    type: ActionTypes.DELETE_ORDERING_SITE_START,
  })

  return deleteOrderingSite(venueId, orderingSiteId)
    .then(response => {
      dispatch({
        type: ActionTypes.DELETE_ORDERING_SITE_SUCCESS,
      })
      dispatch(GlobalActions.showSuccessMessage('Site Deleted'))
      dispatch(tryGetOrderingSites(venueId))
      return response
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage(`Unable to delete site: ${e}`))

      dispatch({
        type: ActionTypes.DELETE_ORDERING_SITE_FAIL,
      })

      return e
    })
}

const _getNullableFloatField = value => (!_.isEmpty(value) || !_.isNil(value) ? parseFloat(value.toString()) : null)

const _buildPayloadFromEditState = (editState, orderingSiteId) => ({
  fulfillment_method: editState.fulfillment_method,
  id: orderingSiteId,
  time_slot_spacing_mins: editState.allow_order_ahead ? parseInt(editState.time_slot_spacing_mins, 10) : 0,
  max_order_ahead_mins: editState.allow_order_ahead
    ? _calculateMaxOrderAheadMins(editState.max_order_ahead_time, editState.max_order_ahead_time_display_unit)
    : 0,
  max_order_ahead_time_display_unit: editState.allow_order_ahead ? editState.max_order_ahead_time_display_unit : 'DAYS',
  room_service_subtotal_min:
    editState.displaySettings.show_room_service_min && editState.displaySettings.show_room_service_section
      ? _getNullableFloatField(editState.room_service_subtotal_min)
      : null,
  room_service_subtotal_max:
    editState.displaySettings.show_room_service_max && editState.displaySettings.show_room_service_section
      ? _getNullableFloatField(editState.room_service_subtotal_max)
      : null,
  delivery_subtotal_min:
    editState.displaySettings.show_delivery_min && editState.displaySettings.show_delivery_section
      ? _getNullableFloatField(editState.delivery_subtotal_min)
      : null,
  delivery_subtotal_max:
    editState.displaySettings.show_delivery_max && editState.displaySettings.show_delivery_section
      ? _getNullableFloatField(editState.delivery_subtotal_max)
      : null,
  subtotal_min:
    editState.displaySettings.show_pickup_min && editState.displaySettings.show_pickup_section
      ? _getNullableFloatField(editState.subtotal_min)
      : null,
  subtotal_max:
    editState.displaySettings.show_pickup_max && editState.displaySettings.show_pickup_section
      ? _getNullableFloatField(editState.subtotal_max)
      : null,
  time_slot_width_mins: parseInt(editState.time_slot_width_mins, 10),
  custom_fields_enabled: editState.custom_fields_enabled,
  custom_fields_required: editState.custom_fields_required,
  delivery_custom_fields_enabled: editState.delivery_custom_fields_enabled,
  delivery_custom_fields_required: editState.delivery_custom_fields_required,
  room_service_custom_fields_enabled: editState.room_service_custom_fields_enabled,
  room_service_custom_fields_required: editState.room_service_custom_fields_required,
  checkout_message: editState.displaySettings.show_pickup_section && editState.checkout_message ? editState.checkout_message : '',
  banner_message: editState.banner_message || '',
  delivery_checkout_message: editState.delivery_checkout_message || '',
  room_service_checkout_message: editState.room_service_checkout_message || '',
  pos_tables: editState.is_pos_table_multiselect ? editState.pos_tables : editState.pos_tables.slice(0, 1),
  is_enabled: editState.is_enabled,
  is_pos_table_multiselect: editState.is_pos_table_multiselect,
  name: editState.name,
  url_key: editState.url_key,
  menu_ids: editState.menu_ids || [],
  is_guest_will_pick_up_room_number_from_list: editState.is_guest_will_pick_up_room_number_from_list,
})

const _calculateMaxOrderAheadMins = (time, displayUnit) => {
  if (displayUnit === 'HOURS') {
    return time * 60
  }
  if (displayUnit === 'DAYS') {
    return time * 24 * 60
  }
  return 0
}

export const trySaveOrderingSite = (orderingSiteId, history, successfulSavePath) => (dispatch, getState) => {
  const state = getState()
  const payload = _buildPayloadFromEditState(state.orderingSite.editState, orderingSiteId)
  const successfulSaveMessage = 'Successfully saved changes'
  const { venue } = state.appState
  const venueId = venue.id
  const isNewSite = orderingSiteId === 'new'
  const saveFunction = isNewSite ? createOrderingSite : saveOrderingSite
  dispatch({
    type: ActionTypes.SAVE_ORDERING_SITE_START,
  })

  return saveFunction(venueId, orderingSiteId, payload)
    .then(response => {
      dispatch(GlobalActions.showSuccessMessage(successfulSaveMessage))
      dispatch({
        type: ActionTypes.SAVE_ORDERING_SITE_SUCCESS,
        data: response.data,
      })
      history.push(successfulSavePath)
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage(`Unable to save changes: ${e}`))

      dispatch({
        type: ActionTypes.SAVE_ORDERING_SITE_FAIL,
      })

      return e
    })
}

export const trySetThirdPartyDefault = (venueId, newOrderingSiteId) => (dispatch, getState) => {
  const state = getState()
  const checkoutSettingsId = state.orderingCheckout.checkoutSettings.id

  const checkoutSettings = {
    id: checkoutSettingsId,
    third_party_default_ordering_site_id: newOrderingSiteId,
  }
  dispatch({
    type: ActionTypes.SET_ORDERING_SITE_DEFAULT_3RD_PARTY_START,
  })

  const successMessage = newOrderingSiteId
    ? 'Successfully set this site as third party default'
    : 'Successfully unset this site as third party default'
  const errorMessage = newOrderingSiteId
    ? 'Unable to set this site as third party default'
    : 'Unable to unset this site as third party default'
  return saveOrderingCheckoutSettings({
    venueId,
    checkoutSettings,
  }).then(
    response => {
      dispatch(
        batchActions([
          {
            type: ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_SUCCESS,
            updated: response.data.updated,
          },
          {
            type: ActionTypes.CHANGE_ORDERING_CHECKOUT_SETTINGS_FIELD,
            field: 'third_party_default_ordering_site_id',
            value: newOrderingSiteId,
          },
          {
            type: ActionTypes.SET_ORDERING_SITE_DEFAULT_3RD_PARTY_SUCCESS,
            data: response.data,
          },
          GlobalActions.showSuccessMessage(successMessage),
        ])
      )
      window.metric.track(`Settings.OrderingCheckout.Save.${response.data.result}`)
    },
    e => {
      dispatch(GlobalActions.showErrorMessage(`${errorMessage}: ${e}`))
      dispatch({
        type: ActionTypes.SET_ORDERING_SITE_DEFAULT_3RD_PARTY_FAIL,
      })
      return e
    }
  )
}

export const updateField = (fieldName, newValue) => ({
  type: ActionTypes.UPDATE_ORDERING_FIELD,
  fieldName,
  newValue,
})

export const toggleDisplayField = (fieldName, newValue) => ({
  type: ActionTypes.TOGGLE_DISPLAY_FIELD,
  fieldName,
  newValue,
})

export const toggleField = fieldName => ({
  type: ActionTypes.TOGGLE_ORDERING_FIELD,
  fieldName,
})

export const updateFields = updatedFields => ({
  type: ActionTypes.UPDATE_ORDERING_FIELDS,
  updatedFields,
})

export const updateMenus = menus => ({
  type: ActionTypes.UPDATE_ORDERING_SITE_MENUS,
  menus,
})

export const updateFulfillmentOption = option => ({
  type: ActionTypes.UPDATE_FULFILLMENT_OPTION,
  option,
})

export const updateSelectedPosTables = value => ({
  type: ActionTypes.UPDATE_ORDERING_SITE_POS_TABLES,
  value,
})

export const removeSelectedPosTable = value => ({
  type: ActionTypes.REMOVE_ORDERING_SITE_POS_TABLE,
  value,
})
