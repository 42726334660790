import React from 'react'
import styled from 'styled-components'

const SKIN = {
  DEFAULT: 'DEFAULT',
  GRAY: 'GRAY',
}

const getBgColorBySkin = props => {
  switch (props.skin) {
    case SKIN.GRAY:
      return props.theme.color.lightGrey
    default:
      return ''
  }
}

const GroupPanel = styled.div`
  border: 1px solid ${props => props.theme.lightGrey};
  border-radius: 4px;
  padding: ${props => props.theme.padding.large};
  margin-bottom: ${props => props.theme.padding.large};
  background-color: ${getBgColorBySkin};
  &:last-child {
    margin-bottom: 0;
  }
`

GroupPanel.SKIN = SKIN

const Title = styled.div`
  font-size: ${props => props.theme.fontSize.body};
  font-weight: bold;
`

GroupPanel.Header = styled.div`
  border-bottom: 1px solid ${props => props.theme.lightGrey};
  padding-bottom: ${props => props.theme.padding.medium};
  margin-bottom: ${props => props.theme.padding.large};
`

GroupPanel.Title = ({ children }) => (
  <GroupPanel.Header>
    <Title>{children}</Title>
  </GroupPanel.Header>
)

export default GroupPanel
