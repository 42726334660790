import { showErrorMessage, showSuccessMessage } from 'mgr/lib/actions/GlobalActions'
import { createMenu, deleteMenu, getMenuById, getMenusListByVenueId, putMenuById } from 'mgr/lib/services/OrderingMenusServices'
import { getProductInventoryByVenueId } from 'mgr/lib/services/ProductInventoryServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { batchActions } from 'svr/common/ReduxUtils'
import type {
  MenuCategory,
  MenuCategoryItem,
  OrderingMenu,
  ExcludedDateRange,
} from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'
import type { Dispatch } from 'redux'

export interface LoadMenusListStartAction {
  type: typeof ActionTypes.GET_ORDERING_MENUS_LIST_START
}
export interface LoadMenusListSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_MENUS_LIST_SUCCESS
  data: OrderingMenu[]
}
export interface LoadMenusListFailAction {
  type: typeof ActionTypes.GET_ORDERING_MENUS_LIST_FAIL
}
export interface RemoveMenuStartAction {
  type: typeof ActionTypes.REMOVE_MENU_START
}
export interface RemoveMenuSuccessAction {
  type: typeof ActionTypes.REMOVE_MENU_SUCCESS
}
export interface RemoveMenuFailAction {
  type: typeof ActionTypes.REMOVE_MENU_FAIL
}
export type OrderingMenusLisAction =
  | LoadMenusListStartAction
  | LoadMenusListSuccessAction
  | LoadMenusListFailAction
  | RemoveMenuStartAction
  | RemoveMenuSuccessAction
  | RemoveMenuFailAction

export interface LoadMenuSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_MENU_SUCCESS
  menu: OrderingMenu
}

export interface LoadMenuItemsSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_MENU_ITEMS_SUCCESS
  menuItems: MenuItem[]
}
export interface CreateMenuSuccessAction {
  type: typeof ActionTypes.CREATE_ORDERING_MENU_SUCCESS
}
export interface UpdateMenuSuccessAction {
  type: typeof ActionTypes.UPDATE_ORDERING_MENU_SUCCESS
}

export type MenuAction =
  | LoadMenuSuccessAction
  | LoadMenuItemsSuccessAction
  | CreateMenuSuccessAction
  | UpdateMenuSuccessAction
  | ReturnType<typeof clearMenu>
  | ReturnType<typeof updateMenuName>
  | ReturnType<typeof updateMenuPrepTime>
  | ReturnType<typeof updateMenuCategoryOrder>
  | ReturnType<typeof createMenuCategory>
  | ReturnType<typeof updateMenuCategory>
  | ReturnType<typeof openConfirmDeleteModal>
  | ReturnType<typeof closeConfirmDeleteModal>
  | ReturnType<typeof deleteMenuCategory>
  | ReturnType<typeof deleteCategoryMenuItem>
  | ReturnType<typeof updateCategoryMenuItems>
  | ReturnType<typeof openCategoryModal>
  | ReturnType<typeof closeCategoryModal>
  | ReturnType<typeof openAddMenuItemModal>
  | ReturnType<typeof closeAddMenuItemModal>
  | ReturnType<typeof updateDateRangeFrom>
  | ReturnType<typeof updateDateRangeTo>
  | ReturnType<typeof addMenuAvailability>
  | ReturnType<typeof removeMenuAvailability>
  | ReturnType<typeof copyMenuAvailabilityToAll>
  | ReturnType<typeof updateMenuAvailability>
  | ReturnType<typeof addMenuExcludedDateRange>
  | ReturnType<typeof updateMenuExcludedDateRange>
  | ReturnType<typeof removeExcludedDateRange>

const processFail = (message: string, type: string) =>
  batchActions([
    showErrorMessage(message),
    {
      type,
    },
  ])
export const updateMenuName = (value: string) => ({ type: ActionTypes.UPDATE_ORDERING_MENU_NAME, value } as const)
export const updateMenuPrepTime = (value: number) => ({ type: ActionTypes.UPDATE_ORDERING_MENU_PREP_TIME_MINS, value } as const)
export const updateMenuCategoryOrder = (categoryName: string, position: number) =>
  ({ type: ActionTypes.UPDATE_ORDERING_MENU_CATEGORY_ORDER, categoryName, position } as const)
export const createMenuCategory = (category: MenuCategory) => ({ type: ActionTypes.CREATE_ORDERING_MENU_CATEGORY, category } as const)
export const updateMenuCategory = (categoryName: string, category: MenuCategory) =>
  ({ type: ActionTypes.UPDATE_ORDERING_MENU_CATEGORY, categoryName, category } as const)
export const deleteMenuCategory = (categoryName: string) => ({ type: ActionTypes.DELETE_ORDERING_MENU_CATEGORY, categoryName } as const)
export const deleteCategoryMenuItem = (category: MenuCategory, menuItem: MenuItem) =>
  ({ type: ActionTypes.DELETE_ORDERING_MENU_CATEGORY_ITEM, category, menuItem } as const)
export const updateCategoryMenuItems = (category: MenuCategory, menuItems: MenuCategoryItem[]) =>
  ({ type: ActionTypes.UPDATE_ORDERING_MENU_CATEGORY_ITEMS, category, menuItems } as const)
export const clearMenu = () => ({ type: ActionTypes.CLEAR_ORDERING_MENU } as const)
export const openCategoryModal = (category?: MenuCategory) => ({ type: ActionTypes.OPEN_CATEGORY_MODAL, category } as const)
export const closeCategoryModal = () => ({ type: ActionTypes.CLOSE_CATEGORY_MODAL } as const)
export const closeConfirmDeleteModal = () => ({ type: ActionTypes.CLOSE_CONFIRM_DELETE_MODAL } as const)
export const openConfirmDeleteModal = (categoryName: string) => ({ type: ActionTypes.OPEN_CONFIRM_DELETE_MODAL, categoryName } as const)
export const openAddMenuItemModal = (category: MenuCategory) => ({ type: ActionTypes.OPEN_ADD_MENU_ITEM_MODAL, category } as const)
export const closeAddMenuItemModal = () => ({ type: ActionTypes.CLOSE_ADD_MENU_ITEM_MODAL } as const)
export const updateDateRangeFrom = (value: Date) => ({ type: ActionTypes.UPDATE_ORDERING_MENU_DATE_RANGE_FROM, value } as const)
export const updateDateRangeTo = (value: Date | null) => ({ type: ActionTypes.UPDATE_ORDERING_MENU_DATE_RANGE_TO, value } as const)
export const addMenuAvailability = (dayOfWeekIndex: number) => ({ type: ActionTypes.ADD_MENU_AVAILABILITY, dayOfWeekIndex } as const)
export const removeMenuAvailability = (dayOfWeekIndex: number, dateRangeIndex: number) =>
  ({ type: ActionTypes.REMOVE_MENU_AVAILABILITY, dayOfWeekIndex, dateRangeIndex } as const)
export const copyMenuAvailabilityToAll = (dayOfWeekIndex: number, dateRangeIndex: number) =>
  ({ type: ActionTypes.COPY_MENU_AVAILABILITY_TO_ALL, dayOfWeekIndex, dateRangeIndex } as const)
export const updateMenuAvailability = (dayOfWeekIndex: number, dateRangeIndex: number, start?: string, end?: string) =>
  ({ type: ActionTypes.UPDATE_MENU_AVAILABILITY, dayOfWeekIndex, dateRangeIndex, start, end } as const)
export const addMenuExcludedDateRange = (excludedDateRange: ExcludedDateRange) =>
  ({ type: ActionTypes.ADD_MENU_EXCLUDED_DATE_RANGE, excludedDateRange } as const)
export const updateMenuExcludedDateRange = (index: number, excludedDateRange: ExcludedDateRange) =>
  ({ type: ActionTypes.UPDATE_MENU_EXCLUDED_DATE_RANGE, index, excludedDateRange } as const)
export const removeExcludedDateRange = (index: number) => ({ type: ActionTypes.REMOVE_MENU_EXCLUDED_DATE_RANGE, index } as const)

export const removeMenu =
  (venueId: string, menuId: string) =>
  async (
    dispatch: Dispatch<
      | RemoveMenuStartAction
      | RemoveMenuSuccessAction
      | ReturnType<typeof showErrorMessage>
      | ReturnType<typeof showSuccessMessage>
      | RemoveMenuFailAction
      | loadMenusListByVenueIdType
    >
  ) => {
    dispatch({
      type: ActionTypes.REMOVE_MENU_START,
    })

    try {
      await deleteMenu(venueId, menuId)
    } catch (e) {
      dispatch(processFail('Unable to delete menu, please try again later or contact customer support.', ActionTypes.REMOVE_MENU_FAIL))

      throw e
    }

    dispatch({
      type: ActionTypes.REMOVE_MENU_SUCCESS,
    })
    dispatch(showSuccessMessage('Menu Deleted'))
    await loadMenusListByVenueId(venueId)(dispatch)
  }

type loadMenusListByVenueIdType = LoadMenusListStartAction | LoadMenusListSuccessAction | LoadMenusListFailAction
export const loadMenusListByVenueId = (venueId: string) => async (dispatch: Dispatch<loadMenusListByVenueIdType>) => {
  dispatch({
    type: ActionTypes.GET_ORDERING_MENUS_LIST_START,
  })
  let response

  try {
    response = await getMenusListByVenueId(venueId)
  } catch (e) {
    dispatch(
      processFail('Unable to fetch menus, please try again later or contact customer support.', ActionTypes.GET_ORDERING_MENUS_LIST_FAIL)
    )
    return
  }

  dispatch({
    type: ActionTypes.GET_ORDERING_MENUS_LIST_SUCCESS,
    data: response.menus,
  })
}

export const loadMenuById =
  (venueId: string, menuId: string) => async (dispatch: Dispatch<LoadMenuSuccessAction | ReturnType<typeof showErrorMessage>>) => {
    let menuResponse

    try {
      menuResponse = await getMenuById(venueId, menuId)
    } catch (e) {
      dispatch(showErrorMessage('Unable to fetch menu, please try again later or contact customer support.'))

      throw e
    }

    dispatch({
      type: ActionTypes.GET_ORDERING_MENU_SUCCESS,
      menu: menuResponse.menu,
    })
  }

export const loadMenuItems =
  (venueId: string) => async (dispatch: Dispatch<LoadMenuItemsSuccessAction | ReturnType<typeof showErrorMessage>>) => {
    let productsResponse

    try {
      productsResponse = await getProductInventoryByVenueId(venueId, 'MENU_ITEM')
    } catch (e) {
      dispatch(showErrorMessage('Unable to fetch menu items, please try again later or contact customer support.'))

      throw e
    }

    dispatch({
      type: ActionTypes.GET_ORDERING_MENU_ITEMS_SUCCESS,
      menuItems: productsResponse.products as MenuItem[],
    })
  }

export const updateMenuById =
  (venueId: string, menuId: string, menuData: OrderingMenu) =>
  async (dispatch: Dispatch<ReturnType<typeof showSuccessMessage> | ReturnType<typeof showErrorMessage> | UpdateMenuSuccessAction>) => {
    try {
      await putMenuById(venueId, menuId, menuData)
      dispatch({ type: ActionTypes.UPDATE_ORDERING_MENU_SUCCESS })
      dispatch(showSuccessMessage(`${menuData.name} has been successfully saved.`))
    } catch (e) {
      dispatch(showErrorMessage('Unable to save menu, please try again later or contact customer support.'))
      throw e
    }
  }

export const saveMenu =
  (venueId: string, menuData: OrderingMenu) =>
  async (dispatch: Dispatch<ReturnType<typeof showSuccessMessage> | ReturnType<typeof showErrorMessage> | CreateMenuSuccessAction>) => {
    try {
      await createMenu(venueId, menuData)
      dispatch({ type: ActionTypes.CREATE_ORDERING_MENU_SUCCESS })
      dispatch(showSuccessMessage(`${menuData.name} has been successfully saved.`))
    } catch (e) {
      dispatch(showErrorMessage('Unable to save menu, please try again later or contact customer support.'))
      throw e
    }
  }
