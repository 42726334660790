import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import { ORDER_METHOD_TITLE } from 'svr/constants'
import { Box, Spreadsheet, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import { PacingRuleEditCell } from './PacingRuleEditCell'
import type { PacingRule, OrderingSiteOption } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

interface PacingRuleListTableProps {
  venue: Venue
  pacingRuleList: PacingRule[]
  venueUrlKey: string
  sorted?: SortColumn[]
  onSortedChange?: (data: SortColumn[]) => void
  orderingSitesById: Record<string, OrderingSiteOption>
  removePacingRule: (venueId: string, pacingRuleId: string) => Promise<void>
}

export function PacingRuleListTable({
  venue,
  pacingRuleList,
  sorted,
  venueUrlKey,
  onSortedChange,
  orderingSitesById,
  removePacingRule,
}: PacingRuleListTableProps) {
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedRule, setSelectedRule] = useState<PacingRule>()
  const onDelete = useCallback(
    (pacingRule: PacingRule) => {
      setSelectedRule(pacingRule)
      setModalVisible(true)
    },
    [setModalVisible]
  )
  const columns = useMemo<DataTableColumn<PacingRule>[]>(
    () => [
      {
        header: 'Name',
        key: 'name',
        render: (value: PacingRule, index: number) => <BasicCell value={value.name} data-test={`pacing-rule-${index}-name`} />,
        textDisplay: 'flex',
        sortable: true,
        maxWidth: 300,
      },
      {
        header: 'Date Range',
        key: 'dateRange',
        render: (value: PacingRule, index: number) => (
          <BasicCell value={formatDateRange(value)} data-test={`pacing-rule-${index}-date-range`} />
        ),
        textDisplay: 'flex',
        sortable: true,
      },
      {
        header: 'Times',
        key: 'times',
        render: (value: PacingRule, index: number) => <TimeRangeCell pacingRule={value} index={index} />,
        textDisplay: 'flex',
        sortable: true,
      },
      {
        header: 'Fulfillment Method(s)',
        key: 'fulfillmentMethod',
        render: (value: PacingRule, index: number) => (
          <BasicCell value={formatFulfillmentMethod(value)} data-test={`pacing-rule-${index}-fulfillment-methods`} />
        ),
        textDisplay: 'flex',
        sortable: true,
      },
      {
        header: 'Ordering Sites',
        key: 'orderingSites',
        render: (value: PacingRule, index: number) => (
          <BasicCell value={formatOrderingSites(value, orderingSitesById)} data-test={`pacing-rule-${index}-ordering-sites`} />
        ),
        textDisplay: 'flex',
        sortable: true,
      },
      {
        header: '',
        key: 'action',
        attach: 'right',
        render: value => <PacingRuleEditCell pacingRule={value} venueUrlKey={venueUrlKey} onDelete={onDelete} />,
      },
    ],
    [onDelete, orderingSitesById, venueUrlKey]
  )

  return (
    <Box mt="lm" data-test="sr-ordering-pacing-rule-inventory-table">
      <Spreadsheet data={pacingRuleList} columns={columns} sorted={sorted} onSortedChange={onSortedChange} />
      {isModalVisible && (
        <MessageBox
          handleActionClick={() => {
            if (selectedRule) {
              removePacingRule(venue.id, selectedRule.id)
            }
            setModalVisible(false)
          }}
          handleCloseClick={() => {
            setModalVisible(false)
          }}
          dialogType={MessageBox.DialogType.WARNING}
          header="Warning"
          explain="Warning"
          details={[]}
          explanation="Are you sure that you want to delete this rule?"
          actionButtonText="Delete"
        />
      )}
    </Box>
  )
}

export interface BasicCellProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
}

function BasicCell({ value, ...props }: BasicCellProps) {
  return (
    <Cell {...props}>
      <Text>{value}</Text>
    </Cell>
  )
}

function TimeRangeCell({ pacingRule, index }: { pacingRule: PacingRule; index: number }) {
  return (
    <Cell>
      <div data-test={`pacing-rule-${index}-time-range`}>{formatTimeRange(pacingRule)}</div>
      <Text color="secondaryFont" data-test={`pacing-rule-${index}-time-interval`}>
        {`${formatTimeInterval(pacingRule.timeIntervalMins)} intervals`}
      </Text>
    </Cell>
  )
}

const Cell = styled.div`
  box-sizing: border-box;
  height: 36px;
  ${props => props.theme.ellipsis};
`

const formatDateRange = (pacingRule: PacingRule) => {
  const startDateFormatted = pacingRule.dateRangeFrom?.format('L') || 'Unknown'
  const endDateFormatted = pacingRule.dateRangeTo?.format('L') || 'Infinite'
  return `${startDateFormatted} - ${endDateFormatted}`
}

const formatTimeRange = (pacingRule: PacingRule) => {
  const startTimeFormatted = pacingRule.timeRangeFrom?.format('LT') || 'Unknown'
  const endTimeFormatted = pacingRule.timeRangeTo?.format('LT') || 'Infinite'
  return `${startTimeFormatted} - ${endTimeFormatted}`
}

const formatTimeInterval = (timeInterval: number) => (timeInterval / 60 >= 1 ? `${timeInterval / 60} hr` : `${timeInterval} min`)

const formatFulfillmentMethod = (pacingRule: PacingRule, concat = ', ') =>
  pacingRule.orderMethods.map(fm => ORDER_METHOD_TITLE[fm] || 'Unknown').join(concat)

const formatOrderingSites = (pacingRule: PacingRule, orderingSitesById: Record<string, OrderingSiteOption>) =>
  pacingRule.orderingSiteIds.length
    ? pacingRule.orderingSiteIds.map(siteId => orderingSitesById[siteId]?.name || 'Loading').join(', ')
    : `All ordering sites for ${formatFulfillmentMethod(pacingRule, ' or ')}`
