import React from 'react'
import styled from 'styled-components'
import CheckboxToggle from 'mgr/lib/forms/CheckboxToggle'

const switchCell = (onClick, props) => {
  const { value, row } = props

  return <CheckboxToggle id={`row-${row._index}-name-${row.name}-switch`} label="" on={value} onChange={() => onClick(props.original)} />
}

export default switchCell
