import { connect } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import type { RootState, UserDomainVenue, Venue, VenueSettings } from '@sevenrooms/mgr-core'
import { WidgetLanguageSettings } from '@sevenrooms/mgr-settings/Settings/LanguageSettings'
import type { RouteComponentProps } from 'react-router'

interface WidgetSettingsContainerProps extends RouteComponentProps {
  readonly venue: Venue
  readonly venues: UserDomainVenue[]
  readonly onChangeVenue: (venue: UserDomainVenue) => void
  readonly venueSettings: VenueSettings
}

function WidgetSettingsContainerComponent(props: WidgetSettingsContainerProps) {
  const { match, venue, venues, onChangeVenue, venueSettings } = props
  const routes = {
    RESERVATION_WIDGET_LANGUAGE: `${match.path}/reservation_widget_language`,
    ORDERING_SITE_LANGUAGE: `${match.path}/ordering_sites_language`,
    WAITLIST_WIDGET_LANGUAGE: `${match.path}/waitlist_widget_language`,
    EVENT_WIDGET_LANGUAGE: `${match.path}/event_widget_language`,
    SUBSCRIPTION_WIDGET_LANGUAGE: `${match.path}/subscription_widget_language`,
  }

  return (
    <WidgetLanguageSettings venue={venue} venues={venues} routes={routes} venueSettings={venueSettings} onChangeVenue={onChangeVenue} />
  )
}

const mapStateToProps = (state: RootState) => {
  const { venue, userDomain, venueSettings } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    venue,
    venues,
    venueSettings,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
}

export const WidgetSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(WidgetSettingsContainerComponent)
