import Radium from 'radium'
import _ from 'lodash'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'
import StyleUtils from 'mgr/layout/StyleUtils'
import { isMajorOrder } from 'mgr/lib/utils/ShiftUtils'
import { getStatsForOrder, getMaxResOrder } from 'mgr/pages/shared/utils/Actuals'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'
import GridTableSortButton from './GridTableSortButton'
import GridTimeCell from './GridTimeCell'

const scrollBarHeightMask = 15

const GridTimeBarContainer = styled.div`
  height: ${GridStyles.TimeHeaderHeight}px;
  background-color: ${props => props.theme.color.white};
  position: fixed;
  width: 100%;
  z-index: 54;
  ${props => props.showTopBorder && `border-top: 2px solid ${ManagerStyles.LightBorderColor};`};
`

const GridTimeBarStyles = {
  gridTimeBarMask: {
    position: 'fixed',
    height: GridStyles.TimeHeaderHeight,
    width: '100%',
    background: 'white',
    zIndex: 49,
    boxShadow: '#ddd 0px 4px 5px 0px',
  },
  timeHeaderCell: {
    minWidth: GridStyles.TimeHeaderWidth,
    width: GridStyles.TimeHeaderWidth,
  },
  sortContainer: {
    height: GridStyles.TimeHeaderHeight,
    width: GridStyles.TableSortWidth + 10,
    position: 'absolute',
    backgroundColor: 'white',
    borderRightWidth: GridStyles.MajorAxesBorderWidth,
    borderRightStyle: 'solid',
    borderRightColor: ManagerStyles.LightBorderColor,
    zIndex: '2',
  },
  timeCells: {
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: 135, // TODO: calculate
    paddingBottom: 15,
    top: scrollBarHeightMask,
    position: 'relative',
  },
  timeCellsRightPadding: {
    // needed for firefox
    display: 'inline-block',
    width: 120,
  },
  timeCellsContainer: {
    overflow: 'hidden',
    position: 'relative',
    top: -scrollBarHeightMask,
    left: 0,
  },
}

class GridTimeBar extends PureComponent {
  constructor(props) {
    super(props)

    this.handleScroll = this.handleScroll.bind(this)
    this.scrollPanel = null
    this.scrollContainer = null

    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseMoveDebounced = _.debounce(this.onMouseMove, 1)
    this.state = { hover: false }
  }

  componentDidMount() {
    this.scrollPanel = ReactDOM.findDOMNode(this.refs.scrollPanel)
    this.scrollContainer = ReactDOM.findDOMNode(this.refs.scrollContainer)
    this.scrollPanel.addEventListener('scroll', this.handleScroll)
    window.addEventListener('mousemove', this.onMouseMoveDebounced, false)
  }

  componentWillUnmount() {
    this.scrollPanel.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('mousemove', this.onMouseMoveDebounced, false)
  }

  onMouseOver() {
    this.setState({ hover: true })
  }

  onMouseOut() {
    this.setState({ hover: false })
  }

  onMouseMove(ev) {
    if (this.state.hover && !this.props.isActualMoveMode) {
      const domNode = this.scrollPanel
      const boundingRect = domNode.getBoundingClientRect()
      const hoverAreaPercentage = 0.15
      const scrollRegionWidth = Math.floor(hoverAreaPercentage * boundingRect.width)
      const scrollRegionCutoffLeft = boundingRect.left + scrollRegionWidth
      const scrollRegionCutoffRight = boundingRect.left + boundingRect.width - scrollRegionWidth
      const existing = $(domNode).scrollLeft()
      if (ev.pageX > scrollRegionCutoffRight) {
        $(domNode).scrollLeft(existing + 10)
      } else if (ev.pageX < scrollRegionCutoffLeft && existing > 0) {
        $(domNode).scrollLeft(existing - 10)
      }
    }
  }

  handleScroll(ev) {
    if (typeof this.props.onScrollHandler !== 'function') {
      return
    }
    this.props.onScrollHandler(ev.target.scrollLeft, '_timeBar')
  }

  renderTimeCells(sortOrders) {
    return sortOrders.map((order, idx) => {
      const sortOrderStats = getStatsForOrder(order, this.props.sortOrderStats)
      /* TODO: find a way to make this responsive
      const widthAdjust = {
          width: (100 / sortOrders.length).toFixed(2) + '%'
      }
      */
      const widthAdjust = GridStyles.TimeHeaderWidth
      return (
        <GridTimeCell
          testId={`sr-column-${order}`}
          key={order}
          order={order}
          coverCount={sortOrderStats.covers}
          coverMax={sortOrderStats.maxCovers}
          numReservations={sortOrderStats.count}
          isMajor={isMajorOrder(order, this.props.intervalOrder)}
          startOfDayHour={this.props.venue.startOfDayHour}
          style={merge(GridTimeBarStyles.timeHeaderCell, widthAdjust)}
          showNumGuests={this.props.venue.isNightlifeClass}
        />
      )
    })
  }

  getOrderOverageWidths() {
    const { sortOrders } = this.props
    const maxSortOrder = _.max(sortOrders)
    const orderOffset = _.max([maxSortOrder, this.props.maxResOrder]) - maxSortOrder
    return GridStyles.TimeHeaderWidth * orderOffset
  }

  render() {
    const widthAdjust = {
      paddingRight: this.getOrderOverageWidths(),
    }

    return (
      <div>
        <div style={GridTimeBarStyles.gridTimeBarMask} />
        <GridTimeBarContainer showTopBorder={!this.props.highlightBarShowing}>
          <div style={GridTimeBarStyles.sortContainer}>
            <GridTableSortButton actions={this.props.actions} />
          </div>
          <div ref="scrollContainer" style={GridTimeBarStyles.timeCellsContainer}>
            <div
              data-test="sr-header-grid"
              ref="scrollPanel"
              className="scrollSync"
              data-section-id="_timeBar"
              style={[GridTimeBarStyles.timeCells, widthAdjust, StyleUtils.ClearFix]}
              onMouseOver={this.onMouseOver}
              onMouseOut={this.onMouseOut}
            >
              {this.renderTimeCells(this.props.sortOrders)}
              <div style={GridTimeBarStyles.timeCellsRightPadding} />
            </div>
          </div>
        </GridTimeBarContainer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  venue: state.appState.venue,
  date: state.dayviewState.date,
  maxResOrder: getMaxResOrder(state.gridState.gridActualsMapByShift, state.dayviewState.shiftPersistentId),
  isActualMoveMode: state.gridState.isActualMoveMode,
})

GridTimeBar = Radium(GridTimeBar)
GridTimeBar = connect(mapStateToProps)(GridTimeBar)

export default GridTimeBar
