import type { InsightDetail } from '@sevenrooms/core/domain'
import { ActivityLog } from './ActivityLog/ActivityLog'
import { DetailActions } from './DetailActions'
import { DetailHeader } from './DetailHeader'
import { DetailSupportingData } from './DetailSupportingData'
import { InsightUsers } from './InsightUsers'
import type { ReactNode } from 'react'

export interface InsightDetailSlideoutSectionsContainerProps {
  data: InsightDetail
  headerText?: string
  title: string
  description: string
  proofLabel?: string
  suggestedActionsComponent: ReactNode
  proofComponent: ReactNode
}

export function InsightDetailSlideoutSectionsContainer({
  data,
  headerText,
  title,
  description,
  proofLabel,
  suggestedActionsComponent,
  proofComponent,
}: InsightDetailSlideoutSectionsContainerProps) {
  const { insight, activityLog } = data
  return (
    <>
      <DetailHeader insight={insight} title={title} description={description} headerText={headerText} />
      <DetailActions insight={insight}>{suggestedActionsComponent}</DetailActions>
      <DetailSupportingData proofLabel={proofLabel}>{proofComponent}</DetailSupportingData>
      <InsightUsers insight={insight} />
      <ActivityLog insightId={insight.id} activityLog={activityLog} />
    </>
  )
}
