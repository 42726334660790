import type { Insight } from '@sevenrooms/core/domain'
import { InsightSuggestedActionsContext } from '../../hooks'
import { InsightSuggestedActionsSection } from '../InsightSuggestedActionsSectionContent'
import type { PropsWithChildren } from 'react'

export function DetailActions({ children, insight }: PropsWithChildren<{ insight: Insight }>) {
  return (
    <InsightSuggestedActionsContext insight={insight}>
      <InsightSuggestedActionsSection data-test="insight-suggested-actions-section">{children}</InsightSuggestedActionsSection>
    </InsightSuggestedActionsContext>
  )
}
