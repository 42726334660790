import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Desc,
  SubDesc,
  AccessDenied,
  Contents,
  SubContainer,
  ChannelLogo,
  Channel,
  ChannelSection,
} from 'mgr/pages/single-venue/settings/components/shared'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { GoogleSection } from '../components/GoogleSection'

class ReviewChannels extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { venue, venues } = this.props

    return (
      <ContentLayout title="Review Channels" venue={venue} venues={venues} onChangeVenue={() => {}}>
        <Contents>
          {!this.props.is_sizzle_enabled ? (
            <AccessDenied>
              Review Channels are only available with 7X, our marketing automation and guest engagement package, or our guest satisfaction
              package.
            </AccessDenied>
          ) : null}

          <SubContainer>
            <Desc>Sync reviews directly with Review Channels</Desc>
            <SubDesc className="emailserviceproviders-subdesc">Use this page to authenticate your Review Channels with SevenRooms</SubDesc>
            <Channel>
              <ChannelLogo>
                <img width="230" height="77" src={`${this.props.media_url}images/google-logo.png`} />
              </ChannelLogo>
              <ChannelSection>
                <GoogleSection
                  venue_id={this.props.venue_id}
                  media_url={this.props.media_url}
                  is_sizzle_enabled={this.props.is_sizzle_enabled}
                />
              </ChannelSection>
            </Channel>
          </SubContainer>
        </Contents>
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    venue_id: state.appState.venue.id,
    is_sizzle_enabled: state.appState.venueSettings.is_sizzle_enabled,
    media_url: state.appState.mediaUrl,
  }
}

export default connect(mapStateToProps)(ReviewChannels)
