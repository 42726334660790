import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import {
  toggleLendingPageHeaderTextSlideout,
  updateHeaderText,
  updateHeaderTextAndToggle,
} from 'mgr/pages/single-venue/marketing/actions/LandingPageSettings'
import { DisplayArea } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import { ActionButton } from 'mgr/pages/single-venue/marketing/components/shared/ActionButton'

class LandingPageButtonSlideout extends React.Component {
  state = { text: '' }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentHeaderText !== this.state.text) {
      this.setState({ text: nextProps.currentHeaderText })
    }
  }

  submit() {
    if (this.props.isHeaderTextSlideoutOpenFromRadioButton) {
      this.props.updateHeaderTextAndToggle(this.state.text)
    } else {
      this.props.updateHeaderText(this.state.text)
    }

    this.setState({ text: '' })
  }

  render() {
    return (
      <SlideoutComponent
        bodyCSS={css`
          display: flex;
          flex-flow: column;
        `}
        isSlideoutOpen={this.props.isHeaderTextSlideoutOpen}
        onCloseHandler={this.props.toggleLendingPageHeaderTextSlideout}
        title={<SlideoutTitle>Header text</SlideoutTitle>}
        maxWidth="500px"
      >
        <DisplayArea>
          <TextAreaWrapper>
            <TextArea
              data-test-id="sr-landing-page-textarea"
              placeholder="Input header text here"
              value={this.state.text}
              onChange={e => this.setState({ text: e.target.value })}
            />
          </TextAreaWrapper>
        </DisplayArea>

        <ActionArea>
          <CreateButton
            id="sr-create-button"
            onClick={() => this.submit()}
            disabled={this.props.isHeaderTextSlideoutOpenFromRadioButton && !this.state.text}
          >
            {this.props.isHeaderTextSlideoutOpenFromRadioButton ? 'Save & Enable' : 'Save'}
          </CreateButton>
        </ActionArea>
      </SlideoutComponent>
    )
  }
}

const mapStateToProps = state => ({
  isHeaderTextSlideoutOpen: state.landingPageSettings.isHeaderTextSlideoutOpen,
  isHeaderTextSlideoutOpenFromRadioButton: state.landingPageSettings.isHeaderTextSlideoutOpenFromRadioButton,
  currentHeaderText: state.landingPageSettings.data.header_text,
})

const mapDispatchToProps = {
  toggleLendingPageHeaderTextSlideout,
  updateHeaderText,
  updateHeaderTextAndToggle,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageButtonSlideout)

const SlideoutTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  height: 42px;
  line-height: 42px;
  margin-left: 20px;
`

const ActionArea = styled.div`
  min-height: 90px;
  display: flex;
  background-color: white;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
`

const CreateButton = styled(ActionButton)`
  background: ${props => (props.disabled ? '#cccccc' : props.theme.primary)};
`

const TextAreaWrapper = styled('div')`
  padding: 24px;
`

const TextArea = styled('textarea')`
  width: 100%;
  min-height: 300px;
  border-radius: 5px;
  border-color: #dbdcde;
  padding: 10px;
  font-size: 15px;
  color: #161718;
  box-sizing: border-box;
  resize: vertical;

  &:focus {
    border-color: #347baf;
  }
`
