import { useMemo, useState } from 'react'
import type { AudienceHierarchy } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Autocomplete } from '@sevenrooms/react-components/components/Autocomplete'
import { List } from '@sevenrooms/react-components/components/List'
import { ListSubheader } from '@sevenrooms/react-components/components/ListSubheader'
import { TextField } from '@sevenrooms/react-components/components/TextField'
import { useAvailabilityUrlParams } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'
import { StandardInput } from './StandardInput'
import type { AutocompleteGroupedOption } from './options'

export interface AudienceInputProps {
  audiences: AudienceHierarchy[]
}

export function AudienceInput({ audiences }: Partial<AudienceInputProps>) {
  const { formatMessage } = useLocales()

  return (
    <StandardInput htmlFor="aqv-audience" label={formatMessage(availabilityDebuggerMessages.audienceLabel)}>
      {audiences != null ? <AudienceInputEnabled audiences={audiences} /> : <AudienceInputDisabled />}
    </StandardInput>
  )
}

function AudienceInputEnabled({ audiences: data }: AudienceInputProps) {
  const [{ audience }, setParams] = useAvailabilityUrlParams()

  const [initiallySelected] = useState(audience)
  const options = useMemo(() => transformData(data), [data])
  const defaultValue = useMemo(() => getSelected(options, initiallySelected), [initiallySelected, options])

  return (
    <Autocomplete
      fullWidth
      disableClearable
      id="aqv-audience"
      renderInput={params => <TextField {...params} />}
      renderGroup={({ children, group, key }) => (
        <li key={key}>
          <ListSubheader>{group}</ListSubheader>
          <List>{children}</List>
        </li>
      )}
      options={options}
      groupBy={option => option.group}
      defaultValue={defaultValue}
      onChange={(_event, selected, _reason) => setParams({ audience: selected?.value })}
    />
  )
}

function AudienceInputDisabled() {
  return <Autocomplete id="aqv-audience" fullWidth disabled options={[]} renderInput={params => <TextField {...params} />} />
}

function getSelected(options: AutocompleteGroupedOption[], selected?: string) {
  return options.filter(o => o.value === selected)[0]
}

function transformData(data: AudienceHierarchy[], group?: string): AutocompleteGroupedOption[] {
  const options = data
    .filter(a => a.children == null || a.children.length === 0)
    .map(a => ({
      label: a.name,
      value: a.value,
      group: group ?? '',
    }))

  const groups = data.filter(a => a.children != null && a.children.length > 0).flatMap(a => transformData(a.children, a.name))

  return [...options, ...groups]
}
