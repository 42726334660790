import React from 'react'
import CheckboxButton from 'svr/component-lib/Generic/Checkbox/CheckboxButton'
import VmsCheckboxGroup from 'svr/component-lib/Generic/VmsCheckboxGroup'
import { FULFILLMENT_METHOD } from 'svr/constants'

const getTitle = (fulfillmentMethods, len) => {
  if (fulfillmentMethods[0] === FULFILLMENT_METHOD.DELIVERY && fulfillmentMethods.length === 1) {
    return `All ordering sites with delivery (${len})`
  }

  if (fulfillmentMethods[0] === FULFILLMENT_METHOD.PICKUP && fulfillmentMethods.length === 1) {
    return `All ordering sites with pickup (${len})`
  }
  if (fulfillmentMethods.includes(FULFILLMENT_METHOD.PICKUP) && fulfillmentMethods.includes(FULFILLMENT_METHOD.DELIVERY)) {
    return `All ordering sites with pickup and delivery (${len})`
  }

  return `All ordering sites (${len})`
}

const Sites = ({ value, orderingSites, onChangeSites, isDefaultCollapsed, fulfillmentMethods, isValid }) => {
  const fields = orderingSites.map(({ id, name }) => ({
    checked: value.includes(id),
    label: name,
    id,
  }))

  const onChangeFields = isChecked => {
    if (!isChecked) {
      onChangeSites([])
      return
    }

    const sites = fields.map(field => field.id)
    onChangeSites(sites)
  }

  const onSingeFieldChanged = (index, isChecked) => {
    const siteId = fields[index].id

    if (isChecked) {
      onChangeSites([...value, siteId])
      return
    }

    onChangeSites(value.filter(storedId => storedId !== siteId))
  }

  return (
    <VmsCheckboxGroup
      title={getTitle(fulfillmentMethods, fields.length)}
      checkedFields={fields.map(field => field.checked)}
      disabledFields={fields.map(field => field.disabled)}
      onChange={onChangeFields}
      isDefaultCollapsed={isDefaultCollapsed}
      isValid={isValid}
    >
      {fields.map((field, index) => (
        <CheckboxButton
          data-test="service-fee-site-checkbox"
          data-test-id={field.id}
          key={field.id}
          value={field.checked}
          isValid={isValid}
          onChange={value => onSingeFieldChanged(index, value)}
        >
          {field.label}
        </CheckboxButton>
      ))}
    </VmsCheckboxGroup>
  )
}

export default Sites
