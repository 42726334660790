import { createSelector } from 'reselect'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { computeFilteredBySearchValueProductList } from './products'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { EightySixProduct } from 'mgr/pages/single-venue/settings/types/ordering/EightySixProduct.types'
import type { EIGHTY_SIX_PRODUCT_TYPES, PRODUCT_STATES } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

const computeProductListParams = (state: State) => ({
  orderingProductsList: state.orderingProducts.list,
  productStateId: state.orderingProducts.productStateId,
  productTypeFilter: state.orderingProducts.productTypeFilter,
})

const computeProductList = ({
  orderingProductsList = [],
  productStateId,
  productTypeFilter,
}: {
  orderingProductsList: EightySixProduct[]
  productStateId: PRODUCT_STATES
  productTypeFilter: EIGHTY_SIX_PRODUCT_TYPES
}) =>
  orderingProductsList
    .filter(product => (productStateId === 'snoozed' ? product.isSnoozed : !product.isSnoozed))
    .filter(product => productTypeFilter === 'ALL' || product.type === productTypeFilter)

const selectFilteredOrderingProductsList = createSelector(computeProductListParams, computeProductList)

const computeFilteredProductListParams = (state: State) => ({
  orderingProductsList: selectFilteredOrderingProductsList(state),
  productFilterSearchValue: state.orderingProducts.productFilterSearchValue,
})

export const selectOrderingProductsList = createShallowEqualSelector(
  computeFilteredProductListParams,
  computeFilteredBySearchValueProductList
)
