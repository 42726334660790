import _ from 'lodash'
import { useSelector } from 'react-redux'

const taxAdapter = tax => ({
  id: tax.id,
  name: tax.tax_name,
  rate: tax.tax_rate,
})

export const useTaxGroups = () => {
  const venue = useSelector(state => state.appState.venue)
  const selectedVenue = useSelector(state => {
    const { userDomain } = state.appState
    if (!userDomain || !userDomain.venues) {
      return venue
    }

    return userDomain.venues.filter(venueItem => venueItem.id === venue.id)[0]
  })

  const taxGroups = _.get(selectedVenue, 'bookSettings.taxGroups', []).map(taxAdapter)

  return {
    taxGroups,
  }
}
