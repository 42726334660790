import Radium from 'radium'
import React, { PureComponent } from 'react'
import ManagerStyles from 'mgr/layout/Styles'
import StyleUtils from 'mgr/layout/StyleUtils'
import { merge } from 'svr/common/SRUtils'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

const GridTableSortButtonStyles = {
  body: merge(
    {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#ccc',
      height: '30px',
      width: 100,
      position: 'relative',
      left: 'calc(50% - 50px)',
      top: 'calc(50% - 15px)',
      cursor: 'pointer',
      ':hover': {
        borderColor: ManagerStyles.PrimaryStrokeColor,
      },
    },
    StyleUtils.rounded('20px'),
    StyleUtils.Unselectable
  ),
  label: {
    float: 'left',
    lineHeight: '30px',
    paddingLeft: 12,
  },
  chevron: {
    float: 'right',
    lineHeight: '30px',
    paddingRight: 10,
    transform: 'rotate(90deg)',
    marginTop: 5,
  },
  desc: {
    transform: 'rotate(-90deg)',
    marginTop: -5,
  },
  num: {
    marginLeft: 12,
    marginTop: 7,
    fontSize: '15px',
  },
}

class GridTableSortButton extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isAscending: this.props.isAscending }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(ev) {
    this.props.actions.changeTableSort(!this.state.isAscending)
    this.setState({ isAscending: !this.state.isAscending })
  }

  render() {
    const isDescendingStyles = !this.state.isAscending ? GridTableSortButtonStyles.desc : {}
    return (
      <div data-test="sr-button-table_sort" style={GridTableSortButtonStyles.body} onClick={this.handleClick}>
        <div style={GridTableSortButtonStyles.label}>Table</div>
        <span style={[VmsIconSizes.small, GridTableSortButtonStyles.num]}>
          {!this.state.isAscending ? VmsIcons.Sort21 : VmsIcons.Sort12}
        </span>
        <div style={[VmsIconSizes.small, GridTableSortButtonStyles.chevron, isDescendingStyles]}>{VmsIcons.Chevron}</div>
      </div>
    )
  }
}

GridTableSortButton.defaultProps = {
  isAscending: true,
  actions: {},
}

export default Radium(GridTableSortButton)
