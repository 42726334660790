import _ from 'lodash'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import PhotoUpload from 'mgr/lib/forms/PhotoUpload'
import TextInput, { InputRestrictions, ValidatorTypes } from 'mgr/lib/forms/TextInput'

const Title = styled.h1`
  font-size: ${props => props.theme.fontSize.body};
  text-align: left;
`

const Note = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSize.small};
  font-style: oblique;
  margin: 20px 0 10px;
`

const ImageSet = styled.div`
  display: flex;
  justify-content: space-between;
`

const MAX_IMAGES = 6
const RESY_IMAGE_ASPECT = 1.5

const ResyImages = ({ images, editPhoto, deletePhoto, uploadPhoto }) => {
  const imagesWithPlaceholders = [
    ...images.map((img, idx) => ({ id: idx, ...img })),
    ..._.range(MAX_IMAGES - images.length).map(idx => ({
      id: images.length + idx,
      raw_url_key: null,
    })),
  ]

  const photoUploads = imagesWithPlaceholders.map(image => (
    <PhotoUpload
      key={image.id}
      photoId={image.id}
      photo={image.raw_url_key ? image : {}}
      width={140}
      aspect={RESY_IMAGE_ASPECT}
      editPhoto={editPhoto}
      deletePhoto={deletePhoto}
      uploadPhoto={uploadPhoto}
    />
  ))

  const tipText = `At least one must be provided<br/>
  Ideal resolution is 2400 x 1602 but cannot be less than 1280 x 854.<br/>
  Note your image must be below 8MB.`

  return (
    <div>
      <ReactTooltip multiline id="image-sizes" effect="solid" />
      <Title>Upload Images</Title>
      <Note>
        Drag your images here or click to upload files. For best results,&nbsp;
        <span data-for="image-sizes" data-tip={tipText} style={{ textDecoration: 'underline', cursor: 'default' }}>
          view our image size recommendations
        </span>
        .
      </Note>
      <ImageSet>{photoUploads}</ImageSet>
    </div>
  )
}

export default ResyImages
