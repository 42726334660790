import Radium from 'radium'
import React from 'react'
import ManagerStyles from 'mgr/layout/Styles'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

const GridUnassignedCell = Radium(({ numGuests, style }) => {
  const styles = {
    body: {
      display: 'block',
      width: GridStyles.TableCellWidth,
      height: GridStyles.ReservationRowHeight,
      marginBottom: GridStyles.ReservationRowVerticalMargin,
      borderRightWidth: GridStyles.MajorAxesBorderWidth,
      borderRightStyle: 'solid',
      borderRightColor: ManagerStyles.LightBorderColor,
      color: ManagerStyles.AxesFontColor,
      lineHeight: `${GridStyles.ReservationRowHeight}px`,
      background: 'white',
    },
    icon: {
      display: 'inline-block',
      fontSize: 14,
      marginLeft: 10,
      marginRight: 10,
    },
    name: {
      display: 'inline-block',
      width: 55,
    },
    guests: {
      display: 'inline-block',
    },
  }
  return (
    <div style={merge(styles.body, style)}>
      <div style={merge(VmsIconSizes.small, styles.icon)}>{VmsIcons.FloorplanLine}</div>
      <div style={styles.name} />
      <div style={styles.guests}>{numGuests}</div>
    </div>
  )
})

GridUnassignedCell.defaultProps = {
  numGuests: 1,
}

export default GridUnassignedCell
