import React, { useState, useMemo } from 'react'
import { useGetRevenueQuery } from '@sevenrooms/core/api'
import type { RevenueDateItem } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Box, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { RevenueHeader } from '../../components/RevenueHeader'
import { RevenueOpportunities } from '../../components/RevenueOpportunities'
import { RevenuePerformance } from '../../components/RevenuePerformance'
import { DateFilterEnum } from '../../constants'
import { PerformanceTableContext } from '../../hooks'
import { reportingMessages } from '../../locales/reportingMessages'

export function RevenueReport() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { matchQuery } = useNavigation()
  const { data, isLoading, isError } = useGetRevenueQuery({ venueId: venue.id })
  const [filter, setFilter] = useState<DateFilterEnum | undefined>(DateFilterEnum.YEAR_TO_DATE)

  useMemo(() => {
    if (new Date().getMonth() === 0) {
      setFilter(DateFilterEnum.LAST_YEAR)
    }
    const query = matchQuery(routes.manager2.reporting.revenue)
    if (query?.filter) {
      const value = query?.filter as DateFilterEnum
      setFilter(value)
    }
    if (isError) {
      notify({ content: formatMessage(reportingMessages.revenueLoadError), type: 'error' })
    }
  }, [matchQuery, setFilter, isError, formatMessage])

  const dashboards = data ? data.reportingRevenueDashboards : null
  const dashboard = dashboards?.filter((d: RevenueDateItem) => d.dateFilter === filter)[0]
  const dashboardData = dashboard?.data[0]

  return (
    <PerformanceTableContext>
      {isLoading ? <Loader /> : null}
      {!isError && !isLoading ? (
        <Box>
          <RevenueHeader filter={filter} setFilter={setFilter} summary={dashboardData?.summary} />
          <RevenueOpportunities data={dashboardData} />
          <RevenuePerformance data={dashboardData} filter={filter} />
        </Box>
      ) : null}
    </PerformanceTableContext>
  )
}
