import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'
import DateDropDown from 'mgr/lib/components/DateDropDown'
import ShiftDropDown from 'mgr/lib/components/ShiftDropDown'
import VenueDropDown from 'mgr/lib/components/VenueDropDown'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import AddReservationButton from 'mgr/pages/single-venue/dayview/components/AddResButton'
import CoverflowControl from 'mgr/pages/single-venue/dayview/components/CoverflowControl'
import GoToNowButton, { findCurrentShift } from 'mgr/pages/single-venue/dayview/components/GoToNowButton'
import NoteControl from 'mgr/pages/single-venue/dayview/components/NoteControl'

// TODO refactor this into just Toolbar not tied to dayview
const DayViewToolbarContainer = styled.div`
  display: flex;
  position: fixed;
  box-sizing: border-box;
  width: ${props => props.width};
  top: 0;
  z-index: 350;
  background-color: ${props => props.theme.color.white};
  padding: ${props => props.theme.gutter.standard};
`

const GridControlsContainer = styled.div`
  display: flex;
  flex: 1;
`

const DayviewToolbar = ({
  venue,
  user,
  userDomain,
  shifts,
  bookedDates,
  date,
  shiftPersistentId,
  actuals,
  addNewReservation,
  addNewRequest,
  addNewBlock,
  changeShift,
  changeDate,
  goToNow,
  setDailyNoteActive,
  isDailyNoteActive,
  setCoverflowActive,
  isCoverflowActive,
  dailyEventsByVenueDate,
  saveDailyNote,
  updateDailyNote,
  updatedDailyNote,
  isSaveLoading,
  todaysShifts,
  history,
  showVenueDropdown = true,
  showDateDropdown = true,
  showShiftDropdown = true,
  showControls = true,
  showActionButton = true,
  overrideActionButton = null,
  width = `calc(100% - ${ManagerStyles.NavigationWidth}px)`,
  secondaryButton = null,
}) => {
  const dailyEvents = _.get(dailyEventsByVenueDate, `[${venue.urlKey}][${date.format()}]`)
  const shift = _.isEmpty(shifts) || _.isEmpty(shiftPersistentId) ? null : _.find(shifts, { persistent_id: shiftPersistentId })

  const currentShift = findCurrentShift(todaysShifts, venue)
  const onTodaysDate = date.format('MM-DD-YYYY') === moment().tz(venue.timezone).format('MM-DD-YYYY')
  const onTodaysShift = shift && currentShift ? shift.category === currentShift.category : false
  const onTodaysDateAndShift = onTodaysDate && onTodaysShift
  const hasShifts = !_.isEmpty(shifts)
  const shiftPlaceholder = !hasShifts ? 'No shifts' : null

  return (
    <DayViewToolbarContainer data-test="sr-toolbar-day_view" width={width}>
      <GridControlsContainer>
        {showVenueDropdown && (
          <VenueDropDown
            venueId={venue.id}
            venues={userDomain && userDomain.venues ? userDomain.venues : [venue]}
            onChange={venueNew => {
              const urlSplit = history.location.pathname.split('/')
              urlSplit[2] = venueNew.urlKey

              // TODO clean up venue settings, app state initialization so that `changeVenue` can be called and loaded dynamically with a history.push
              window.location = `${urlSplit.join('/')}${history.location.search}`
            }}
          />
        )}

        {showDateDropdown && (
          <DateDropDown
            date={date}
            bookedDates={bookedDates}
            locale={venue.locale}
            hasMinDate={false}
            onChangeDate={dateNew => {
              const urlSplit = history.location.pathname.split('/')
              urlSplit[3] = dateNew.format('MM-DD-YYYY')

              changeDate(dateNew)
              history.push(`${urlSplit.join('/')}${history.location.search}`)
            }}
          />
        )}

        {venue.isDiningClass && showShiftDropdown && (
          <ShiftDropDown
            shifts={shifts}
            shiftPersistentId={shiftPersistentId}
            onChangeShift={shift => changeShift(shift)}
            placeholder={shiftPlaceholder}
            showNavigation={hasShifts}
            isDisabled={!hasShifts}
          />
        )}

        <GoToNowButton
          onClickHandler={() => {
            if (!onTodaysDateAndShift) {
              goToNow(history, currentShift)
            }
          }}
          isDisabled={onTodaysDateAndShift}
        />

        {showControls && (
          <NoteControl
            venue={venue}
            onClick={() => !_.isNil(dailyEvents) && setDailyNoteActive(!isDailyNoteActive)}
            onClickOutside={() => setDailyNoteActive(false)}
            onSaveNoteClick={note => saveDailyNote(venue.urlKey, updatedDailyNote, date)}
            isSaveLoading={isSaveLoading}
            active={isDailyNoteActive}
            date={date}
            dailyEvents={dailyEvents}
            updatedDailyNote={updatedDailyNote}
            onDailyNoteChange={note => updateDailyNote(note)}
          />
        )}

        {showControls && venue.isDiningClass && shift && (
          <CoverflowControl
            venue={venue}
            date={date}
            shift={shift}
            actuals={actuals}
            active={isCoverflowActive}
            onClick={() => setCoverflowActive(!isCoverflowActive)}
            onClickOutside={() => setCoverflowActive(false)}
          />
        )}
      </GridControlsContainer>
      {secondaryButton}
      <div>
        {showActionButton &&
          (overrideActionButton ? (
            <div>{overrideActionButton}</div>
          ) : (
            <AddReservationButton
              onClick={() => addNewReservation(date)}
              onClickAddRequest={() => addNewRequest(date)}
              onClickAddBlock={() => addNewBlock(date)}
              permissions={venue.permissions}
            />
          ))}
      </div>
    </DayViewToolbarContainer>
  )
}

const mapStateToProps = state => {
  const { dayviewState } = state
  const { shiftPersistentId } = dayviewState
  const { actualsByShift } = state.gridState

  return {
    venue: state.appState.venue,
    user: state.appState.user,
    userDomain: state.appState.userDomain,
    date: dayviewState.date,
    bookedDates: dayviewState.bookedDates,
    shifts: dayviewState.shifts,
    actuals: (shiftPersistentId && actualsByShift && actualsByShift[shiftPersistentId]) || null,
    shiftPersistentId,
    isDailyNoteActive: dayviewState.dailyNoteActive,
    isCoverflowActive: dayviewState.coverflowActive,
    dailyEventsByVenueDate: dayviewState.dailyEventsByVenueDate,
    updatedDailyNote: dayviewState.updatedDailyNote,
    isSaveLoading: dayviewState.isSaveLoading,
    todaysShifts: dayviewState.todaysShifts,
  }
}

const mapDispatchToProps = {
  addNewReservation: Actions.addNewReservationAction,
  addNewRequest: Actions.addNewRequestAction,
  addNewBlock: Actions.addNewBlockAction,
  changeShift: Actions.changeShiftAction,
  changeDate: Actions.changeDateAction,
  goToNow: Actions.goToNow,
  setDailyNoteActive: Actions.setDailyNoteActive,
  setCoverflowActive: Actions.setCoverflowActive,
  saveDailyNote: Actions.saveDailyNote,
  updateDailyNote: Actions.updateDailyNote,
}

export default connect(mapStateToProps, mapDispatchToProps)(DayviewToolbar)
