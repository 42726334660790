import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const OUTER_PADDING = 50

const computeFloorplanRoomPositionsParams = state => {
  const { dayFloorPlan } = state.dayviewState
  const { tablePositionsByRoomId, shapePositionsByRoomId } = dayFloorPlan
  const { selectedFloorplanRoom } = state.floorplanState
  const tablePositions = tablePositionsByRoomId && selectedFloorplanRoom ? tablePositionsByRoomId[selectedFloorplanRoom.id] || [] : []
  const shapePositions = shapePositionsByRoomId && selectedFloorplanRoom ? shapePositionsByRoomId[selectedFloorplanRoom.id] || [] : []
  return {
    tablePositions,
    shapePositions,
  }
}

const computeFloorplanRoomPositions = ({ tablePositions, shapePositions }) => {
  const floorplanMaxXY = { x: 0, y: 0 }
  for (const tablePosition of tablePositions) {
    floorplanMaxXY.x = Math.max(floorplanMaxXY.x, tablePosition.floorplan_x_coord + 2 * tablePosition.floorplan_size)
    floorplanMaxXY.y = Math.max(floorplanMaxXY.y, tablePosition.floorplan_y_coord + 2 * tablePosition.floorplan_size)
  }
  for (const shapePosition of shapePositions) {
    floorplanMaxXY.x = Math.max(floorplanMaxXY.x, shapePosition.x_coord + shapePosition.sizeWidth)
    floorplanMaxXY.y = Math.max(floorplanMaxXY.y, shapePosition.y_coord + shapePosition.sizeHeight)
  }
  floorplanMaxXY.x += OUTER_PADDING
  floorplanMaxXY.y += OUTER_PADDING
  return { tablePositions, shapePositions, floorplanMaxXY }
}

export const selectFloorplanRoomPositions = createShallowEqualSelector(computeFloorplanRoomPositionsParams, computeFloorplanRoomPositions)

const sortFieldsDining = ['arrival_time_sort_order', 'max_guests', 'client_display_name']
const sortOrderDining = ['asc', 'asc', 'asc']

const sortFieldsNightlife = ['arrival_time_sort_order', 'client_display_name']
const sortOrderNightlife = ['asc', 'asc']

export const selectSortedActuals = createShallowEqualSelector(
  state => state.appState.venue,
  state => state.gridState.actualsByShift[state.dayviewState.shiftPersistentId] || [],
  (venue, actuals) =>
    _.orderBy(
      _.filter(actuals, actual => !actual.is_dead),
      venue.isDiningClass ? sortFieldsDining : sortFieldsNightlife,
      venue.isDiningClass ? sortOrderDining : sortOrderNightlife
    )
)
