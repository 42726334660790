import { useCallback } from 'react'
import type { Insight } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { useInsightsDashboardContext } from '../../hooks/useInsightsDashboardContext'
import { insightsMessages } from '../../locales'

export function InsightDetailSlideoutFooter() {
  const { formatMessage } = useLocales()
  const { setInsightId, insightId, insightList } = useInsightsDashboardContext()

  const changeInsight = useCallback(
    (dir: number) => {
      if (!insightId) {
        return
      }
      const targetIndex = (insightList.map((ist: Insight) => ist.id).indexOf(insightId) + dir) % insightList.length
      const targetInsight = insightList[targetIndex > -1 ? targetIndex : insightList.length - 1]
      setInsightId(targetInsight?.id as string)
    },
    [insightList, insightId, setInsightId]
  )

  const single = insightList.length === 1
  const forward = single ? undefined : () => changeInsight(1)
  const back = single ? undefined : () => changeInsight(-1)

  return (
    <>
      {!!insightList.length && (
        <HStack spacing="s" ml="auto">
          <Button data-test="ui-kit-slideout-secondary-button" onClick={back} variant="secondary">
            &lsaquo;&nbsp; {formatMessage(insightsMessages.previousInsightButtonText)}
          </Button>
          <Button data-test="ui-kit-slideout-primary-button" onClick={forward} variant="primary">
            {formatMessage(insightsMessages.nextInsightButtonText)} &nbsp;&rsaquo;
          </Button>
        </HStack>
      )}
    </>
  )
}
