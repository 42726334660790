import styled from 'styled-components'

const MultiInputRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 0 ${props => props.theme.gutter.standard} ${props => props.theme.gutter.triple};
`

export default MultiInputRow
