import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { FormatService } from '@sevenrooms/core/locales'
import { computeFilteredBySearchValueProductList } from './products'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { Modifiers, ModifierGroup } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { ProductInventoryItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

const computeProductInventoryListParams = (state: State) => ({
  orderingProductsList: state.orderingProductInventory.list,
  productFilterSearchValue: state.orderingProductInventory.productFilterSearchValue,
  productStateId: state.orderingProductInventory.productStateId,
})

export const selectOrderingProductInventoryList = createShallowEqualSelector(
  computeProductInventoryListParams,
  computeFilteredBySearchValueProductList
)

const computeProductInventoryModifierGroups = (state: State) => ({
  modifierGroups: state.orderingProductInventory.modifierGroups,
  product: state.orderingProductInventory.product,
})

const computeFilteredProductInventoryModifierGroups = ({
  modifierGroups,
  product,
}: {
  modifierGroups: Record<string, ModifierGroup>
  product: ProductInventoryItem
}) => {
  if (product.type === 'MODIFIER') {
    Object.values(modifierGroups).forEach(modifierGroup => {
      if (_.includes(modifierGroup.modifierIds, product.id)) {
        // eslint-disable-next-line no-param-reassign
        modifierGroup.modifierIds = _.filter(modifierGroup.modifierIds, modId => modId !== product.id)
      }
    })
  }
  return modifierGroups
}

export const selectOrderingProductInventoryModifierGroups = createShallowEqualSelector(
  computeProductInventoryModifierGroups,
  computeFilteredProductInventoryModifierGroups
)

const computeProductInventoryModifiers = (state: State) => ({
  modifiers: state.orderingProductInventory.modifiers,
  product: state.orderingProductInventory.product,
  currencyCode: state.appState.venue.currencyCode,
})

const computeFilteredProductInventoryModifiers = ({
  modifiers,
  product,
  currencyCode,
}: {
  modifiers: Modifiers
  product: ProductInventoryItem
  currencyCode: string
}) =>
  Object.entries(modifiers).reduce((accumulator, [modifierId, { name, price }]) => {
    if (modifierId === product.id && product.type === 'MODIFIER') {
      return accumulator
    }
    accumulator[modifierId] = {
      name,
      price,
      priceFormatted: price ? `(+ ${FormatService.formatCurrencyFromCents(price, currencyCode)})` : '',
    }
    return accumulator
  }, {} as Modifiers)

export const selectOrderingProductInventoryModifiers = createShallowEqualSelector(
  computeProductInventoryModifiers,
  computeFilteredProductInventoryModifiers
)
