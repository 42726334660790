import Radium from 'radium'
import React from 'react'
import ManagerStyles from 'mgr/layout/Styles'
import StyleUtils from 'mgr/layout/StyleUtils'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'
import { orderToTime } from 'svr/common/TimeUtil'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

const GridTimeCell = Radium(({ order, coverCount, coverMax, numReservations, isMajor, startOfDayHour, style, showNumGuests, testId }) => {
  const indent = 15

  const styles = {
    body: {
      display: 'inline-block',
      width: GridStyles.TimeHeaderWidth,
      height: GridStyles.TimeHeaderHeight - indent,
      marginTop: isMajor ? 0 : indent,
      paddingTop: isMajor ? indent : 0,
      borderColor: ManagerStyles.LightBorderColor,
      borderLeftWidth: isMajor ? GridStyles.MajorAxesBorderWidth : GridStyles.MinorAxesBorderWidth,
      borderLeftStyle: 'solid',
      borderLeftColor: ManagerStyles.LightBorderColor,
      backgroundColor: 'white',
      color: '#ACB5BD',
      fontFamily: ManagerStyles.PrimaryFont,
      fontWeight: 400,
    },
    content: {
      paddingLeft: 5,
    },
    timeDisplay: {
      fontWeight: 600,
      marginBottom: 4,
    },
    icon: {
      fontSize: 10,
      marginRight: 3,
      marginLeft: 2,
    },
    numReservations: {
      display: 'inline-block',
    },
    overBooking: merge(
      {
        background: '#f3356b',
        color: 'white',
        paddingLeft: 4,
        paddingRight: 4,
        display: 'inline-block',
      },
      StyleUtils.rounded('8px')
    ),
  }

  if (isMajor) {
    styles.timeDisplay.color = ManagerStyles.AxesFontColor
  }

  const time = orderToTime(startOfDayHour, order)
  const timeDisplay = isMajor ? time.format('LT') : time.format('LT').replace('AM', '').replace('PM', '')
  return (
    <div data-test={testId} style={merge(styles.body, style)}>
      <div style={styles.content}>
        <div data-test="sr-label-time" style={styles.timeDisplay}>
          {timeDisplay}
        </div>
        <div>
          <div style={merge(VmsIconSizes.small, styles.icon)}>{VmsIcons.Book}</div>
          <div data-test="sr-label-reservation_count" style={styles.numReservations}>
            {numReservations}
          </div>
        </div>

        {showNumGuests ? (
          <div>
            <div style={merge(VmsIconSizes.small, styles.icon)}>{VmsIcons.Client}</div>
            <div data-test="sr-label-cover_count" style={styles.numReservations}>
              {coverCount}
            </div>
          </div>
        ) : (
          <div data-test="sr-label-cover_count" style={coverCount > coverMax ? styles.overBooking : {}}>
            {coverCount}/{coverMax}
          </div>
        )}
      </div>
    </div>
  )
})

export default GridTimeCell
