import Radium from 'radium'
import React, { PureComponent } from 'react'
import ManagerStyles from 'mgr/layout/Styles'
import StyleUtils from 'mgr/layout/StyleUtils'
import { dropDownTypes } from 'mgr/pages/shared/utils/Constants'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

const GridAccessCellStyles = {
  body: {
    display: 'inline-block',
    position: 'absolute',
    zIndex: 29,
    height: GridStyles.ReservationRowHeight,
    lineHeight: `${GridStyles.ReservationRowHeight}px`,
    top: 0,
    left: 0,
    color: ManagerStyles.AxesFontColor,
    backgroundColor: 'white',
    fontSize: '9px',
    overflow: 'hidden',
    borderLeft: '1px solid white',
  },
  title: {
    backgroundColor: '#ff943d',
    color: 'white',
    height: 14,
    lineHeight: '14px',
    paddingLeft: 2,
    fontWeight: '600',
  },
  shaded: {
    background: 'repeating-linear-gradient(-45deg, #ff943d 1px, white 1px, #ff943d 5px)',
    height: '100%',
    opacity: '0.25',
  },
  titleContainer: {
    zIndex: 29,
    height: 14,
    lineHeight: '14px',
    ':hover': merge(
      {
        zIndex: 31,
      },
      StyleUtils.shadow()
    ),
  },
  clickable: {
    cursor: 'pointer',
  },
  shadedContainer: {
    zIndex: 28,
    pointerEvents: 'none',
    opacity: '0.8',
  },
}

class GridAccessCell extends PureComponent {
  constructor(props) {
    super(props)
    this.handleDropDownClick = this.handleDropDownClick.bind(this)
    this.addDropDownContainerRef = null
  }

  getDurationWidth(durationWidthInIntervals) {
    const numMajorAxesBorders = Math.floor(durationWidthInIntervals / 5)
    const numMinorAxesBorders = durationWidthInIntervals - 1 - numMajorAxesBorders
    return (
      durationWidthInIntervals * GridStyles.TimeHeaderWidth +
      numMinorAxesBorders * GridStyles.MinorAxesBorderWidth +
      numMajorAxesBorders * GridStyles.MajorAxesBorderWidth -
      2
    )
  }

  handleDropDownClick() {
    const elRect = this.addDropDownContainerRef.getBoundingClientRect()
    this.props.onAddDropDownOpened(
      this.props.isBookable,
      this.props.table,
      this.props.order,
      this.props.isUnassigned,
      elRect,
      dropDownTypes.EDIT_ACCESS_RULE,
      this.props.rule
    )
  }

  render() {
    GridAccessCellStyles.body.width = this.getDurationWidth(this.props.durationWidthInIntervals)
    const { name, party_size_min, party_size_max } = this.props.rule
    // when rule has specific party size, title will be like "Access Rule ABC (1 - 2)"
    const title =
      party_size_min && party_size_max ? (
        <>
          {`${name} (${party_size_min}-${party_size_max} `}
          <Icon name="VMSWeb-account-multiple" />)
        </>
      ) : (
        name
      )
    return (
      <span
        style={GridAccessCellStyles.clickable}
        onClick={this.handleDropDownClick}
        ref={addDropDownContainer => {
          this.addDropDownContainerRef = addDropDownContainer
        }}
      >
        <div style={[GridAccessCellStyles.body, GridAccessCellStyles.titleContainer]}>
          <div style={GridAccessCellStyles.title}>{title}</div>
        </div>
        <div style={[GridAccessCellStyles.body, GridAccessCellStyles.shadedContainer]}>
          <div style={GridAccessCellStyles.shaded} />
        </div>
      </span>
    )
  }
}

export default GridAccessCell = Radium(GridAccessCell)
