import type { OverallocatedDurationsTableRow, ShiftSuggestedActions } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../locales'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { OverallocatedDurationsSuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { OverallocatedDurationsProofTable } from '../../Proofs'
import type { DetailProps } from '../../../utils'

export function OverallocatedDurationsDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { suggestedActionsData, insightProofData } = data
  const tableRows = (insightProofData?.[0]?.tableRows as OverallocatedDurationsTableRow[]) ?? []

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.misallocationTitle)}
      description={formatMessage(insightsMessages.weekdayAllocationOptimizationText)}
      proofLabel={formatMessage(insightsMessages.overallocatedDurationsProofLabel)}
      suggestedActionsComponent={
        <OverallocatedDurationsSuggestedActions
          venueUrlKey={venue.urlKey}
          insightSuggestedActionData={suggestedActionsData as ShiftSuggestedActions}
        />
      }
      proofComponent={<OverallocatedDurationsProofTable tableRows={tableRows} data-test="overallocated-durations-proof-table" />}
    />
  )
}
