import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { variantTitle, variantColor } from '../../utils/insightStatusUtils'

export interface StatusCountProps {
  variant: string
  count: number
}

export function StatusCount({ variant, count }: StatusCountProps) {
  const { formatMessage } = useLocales()
  const message = variantTitle[variant]
  const text = message ? formatMessage(message) : ''
  const color = variantColor[variant]

  return (
    <Box p="s" display="inline" borderRadius="s" backgroundColor={color}>
      <Text fontWeight="bold" color="primaryBackground">
        {count}&nbsp;
      </Text>
      <Text color="primaryBackground">{text}</Text>
    </Box>
  )
}
