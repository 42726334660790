import React, { useMemo, useState } from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { AccordionDetails } from '@sevenrooms/react-components/components/AccordionDetails'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { combosTargetingCategoryMessages } from '../../../locales'
import { AccordionContent } from './AccordionContent'
import { compareTableItems } from './tablesCategoryUtil'

interface CombosTargetingCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  defaultExpanded?: boolean
  venue: Venue
}

interface CombosTargetingDetailsProps {
  reason: string
  reasons: AvailabilityDebuggerReason[]
}

interface CombosRuleContentProps {
  formattedHeader: string
  formattedTableDetails: JSX.Element[]
}

interface CombosTargetingGroupedReasons {
  PARTY_SIZE_MISMATCH: AvailabilityDebuggerReason[]
  NO_COVERS_REMAINING: AvailabilityDebuggerReason[]
  NO_ACCESS_RULES: AvailabilityDebuggerReason[]
}

function CombosRuleContent({ formattedHeader, formattedTableDetails }: CombosRuleContentProps) {
  return (
    <Box sx={{ '&:not(:last-child)': { pb: 2 } }}>
      <Typography fontWeight="medium">{formattedHeader}</Typography>
      {formattedTableDetails}
    </Box>
  )
}

function CombosTargetingDetails({ reason, reasons }: CombosTargetingDetailsProps) {
  const { formatMessage } = useLocales()

  let formattedHeader: string
  let formattedTableDetails: JSX.Element[]

  switch (reason) {
    case 'PARTY_SIZE_MISMATCH':
      formattedHeader = formatMessage(combosTargetingCategoryMessages.mainDescriptionHeaderPartySizeMismatch)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(combosTargetingCategoryMessages.mainDescriptionPartySizeMismatch, {
            combo: r.data?.tableItemCodes,
            min: r.data?.tableMin,
            max: r.data?.tableMax,
            span: (chunks: string[]) => (
              <Typography component="span" whiteSpace="nowrap">
                {chunks}
              </Typography>
            ),
          })}
        </Typography>
      ))
      return <CombosRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'NO_ACCESS_RULES':
      formattedHeader = formatMessage(combosTargetingCategoryMessages.mainDescriptionHeaderNoAccessRules)
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(combosTargetingCategoryMessages.mainDescriptionNoAccessRules, {
            combo: r.data?.tableItemCodes,
          })}
        </Typography>
      ))
      return <CombosRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    default:
      return <></>
  }
}

function groupCombosByReason(reasons: AvailabilityDebuggerReason[]) {
  const groupedReasons: CombosTargetingGroupedReasons = {
    PARTY_SIZE_MISMATCH: [],
    NO_COVERS_REMAINING: [],
    NO_ACCESS_RULES: [],
  }

  let numCombosAffected = 0

  // group table reasons together and de-dupe and table item code reasons
  reasons.sort(compareTableItems).forEach(r => {
    const reason = r.reason as keyof CombosTargetingGroupedReasons
    if (reason in groupedReasons) {
      if (groupedReasons[reason].length > 0) {
        const tc = r.data?.tableItemCodes
        if (!groupedReasons[reason].some(r => r.data?.tableItemCodes === tc)) {
          groupedReasons[reason].push(r)
          numCombosAffected += 1
        }
      } else {
        groupedReasons[reason].push(r)
        numCombosAffected += 1
      }
    }
  })

  return { groupedReasons, numCombosAffected }
}

export function CombosTargetingCategory({ reasons, defaultExpanded = true, venue }: CombosTargetingCategoryProps) {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  const { formatMessage } = useLocales()
  const { groupedReasons, numCombosAffected } = useMemo(() => groupCombosByReason(reasons), [reasons])
  const headerMessage = formatMessage(combosTargetingCategoryMessages.accordianHeader, {
    count: numCombosAffected,
    numCombos: numCombosAffected,
  })

  if (numCombosAffected === 0) {
    return <></>
  }

  return (
    <AccordionContent
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      headerMessage={headerMessage}
      dataTestId="combos-targeting-category-content"
      actionButtonEnabled
      actionButtonProps={{
        label: formatMessage(combosTargetingCategoryMessages.manageCombinationsButtonText),
        url: `/manager/${venue.urlKey}/manage/table_combos`,
      }}
    >
      <AccordionDetails id="combo-targeting-panel-content">
        {Object.entries(groupedReasons)
          .filter(([, reasons]) => reasons.length > 0)
          .map(([reason, reasons]) => (
            <CombosTargetingDetails key={`${reason}`} reason={reason} reasons={reasons} />
          ))}
      </AccordionDetails>
    </AccordionContent>
  )
}
