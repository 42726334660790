import { useMemo } from 'react'
import type { Order } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Box, Footer, TotalItem } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { ordersMessages } from '../locales/ordersMessages'

const calculateTotals = (orders: Order[]) => {
  let activeCount = 0
  let voidCount = 0
  let totalAmount = 0
  for (const order of orders) {
    if (order.status === 'VOID') {
      voidCount += 1
    } else {
      activeCount += 1
      totalAmount += order.total
    }
  }
  return {
    activeCount,
    voidCount,
    totalAmount,
  }
}

interface OrdersFooterProps {
  orders: Order[]
  filteredOrders: Order[]
  isFiltering: boolean
}

export function OrdersFooter({ orders, filteredOrders, isFiltering }: OrdersFooterProps) {
  const { venueCurrencyCode } = useAppContext()
  const { formatMessage } = useLocales()
  const totals = useMemo(() => calculateTotals(orders), [orders])
  const filteredTotals = useMemo(() => calculateTotals(filteredOrders), [filteredOrders])

  return (
    <>
      <Box height="31px" />
      <Footer includeNavWidth>
        <TotalItem
          primary={totals.activeCount}
          secondary={isFiltering ? filteredTotals.activeCount : undefined}
          label={formatMessage(ordersMessages.ordersFooterTotalAll)}
          info={
            <Box minWidth="max-content">
              <Text color="primaryBackground">
                {formatMessage(ordersMessages.ordersFooterTotalInfo, {
                  em: (chunks: string[]) => (
                    <Text color="primaryBackground" fontStyle="italic">
                      {chunks}
                    </Text>
                  ),
                })}
              </Text>
            </Box>
          }
        />
        <TotalItem
          primary={totals.voidCount}
          secondary={isFiltering && totals.voidCount ? filteredTotals.voidCount : undefined}
          label={formatMessage(ordersMessages.ordersFooterTotalVoided)}
        />
        <TotalItem
          primary={FormatService.formatCurrency(totals.totalAmount, venueCurrencyCode)}
          secondary={
            isFiltering && totals.totalAmount ? FormatService.formatCurrency(filteredTotals.totalAmount, venueCurrencyCode) : undefined
          }
          label={formatMessage(ordersMessages.ordersFooterTotalRevenue)}
        />
      </Footer>
    </>
  )
}
