import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'
import { limitValue as limitNumberValue, processDisplayValue } from './numberInputUtils'

const RootContainer = styled.div`
  display: flex;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  flex-direction: column;
`

const Label = styled.div`
  font-size: 11px;
  color: #9a9b9c;
  margin-bottom: 2px;
`

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background: #ffffff;
  border: 1px solid transparent;
  border-color: ${props => (props.isValid ? (props.isFocused ? '#3e92d1' : '#dbdcde') : '#ff0000')};
  box-sizing: border-box;
  border-radius: 4px;
  min-height: ${props => (props.height ? `${props.height}px` : '44px')};
  width: 100%;
  padding: 0 10px;
`

const InputWrapper = styled.input`
  width: 100%;
  appearance: none;
  border: none;
  outline: none;
`

const Prefix = styled.div`
  font-size: 14px;
  padding-right: 3px;
`

const Suffix = styled.div`
  font-size: 14px;
`

/**
 * @typedef {{
 *   value?: string | number;
 *   disabled?: boolean;
 *   label?: string;
 *   onChange: function;
 *   isValid?: boolean;
 *   type?: string;
 *   min?: number;
 *   max?: number;
 *   step?: number;
 *   prefix?: string;
 *   suffix?: string;
 *   width?: number;
 *   height?: number;
 *   [‘data-test’]?: string
 * }} InputProps
 *
 * @type {React.FC<InputProps>}
 */
const Input = ({ disabled, label, type, value, isValid, onChange, min, max, step, prefix, suffix, width, height, ...props }) => {
  const dataProps = passDataAttrs(props)
  const [isFocused, setFocused] = useState(false)
  const onInputBlur = () => {
    setFocused(false)
  }
  const onInputFocus = e => {
    setFocused(true)
    if (value === 0) {
      e.target.select()
    }
  }
  const limitValue = (value, step, type, min, max) => {
    if (_.isNil(value)) {
      return value
    }

    if (type === 'text') {
      return value
    }

    if (type === 'number') {
      return limitNumberValue(value, step, min, max)
    }

    return value
  }
  const displayValue = processDisplayValue(limitValue(value, step, type, min, max))

  return (
    <RootContainer width={width}>
      <Label>{label}</Label>
      <Container isValid={isValid} isFocused={isFocused} height={height}>
        <Prefix>{prefix}</Prefix>
        <InputWrapper
          {...dataProps}
          min={min}
          max={max}
          step={step}
          type={type}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
          value={displayValue}
          onChange={e => {
            const value = limitValue(e.target.value, step, type, min, max)
            onChange(value)
          }}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        <Suffix>{suffix}</Suffix>
      </Container>
    </RootContainer>
  )
}

Input.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  isValid: PropTypes.bool,
  width: PropTypes.number,
  value: PropTypes.oneOf(PropTypes.number, PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

Input.defaultProps = {
  label: '',
  prefix: '',
  suffix: '',
  isValid: true,
  disabled: false,
  step: 1,
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  type: 'text',
  value: '',
}

export default Input
