import { srGet, srPut, throwFetchError } from '@sevenrooms/core/api'

export const getOrderingBrandSettings = ({ venueId }) =>
  srGet(
    `/api-yoa/ordering/brand_settings`,
    { venue_id: venueId },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).then(throwFetchError)

export const saveOrderingBrandSettings = ({ venueId, brandSettings }) =>
  srPut(`/api-yoa/ordering/brand_settings`, {
    venue_id: venueId,
    ordering_brand_settings: JSON.stringify(brandSettings),
  }).then(throwFetchError)
