import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { ordersMessages } from '../locales/ordersMessages'

export function useOrdersManagementResourcesContext() {
  const { formatMessage } = useLocales()

  const statusDisplayNames: Record<string, string> = useMemo(
    () => ({
      QUEUED: formatMessage(ordersMessages.ordersTableFilterStatusQueued),
      OPEN: formatMessage(ordersMessages.ordersTableFilterStatusOpen),
      FAILED_INJECTION: formatMessage(ordersMessages.ordersTableFilterStatusFailedInjection),
      WORKING_ON_ORDER: formatMessage(ordersMessages.ordersTableFilterStatusWorking),
      READY_PICKUP: formatMessage(ordersMessages.ordersTableFilterStatusReadyForPickup),
      READY_DRIVER: formatMessage(ordersMessages.ordersTableFilterStatusReadyForDriver),
      WITH_DRIVER: formatMessage(ordersMessages.ordersTableFilterStatusWithDriver),
      CLOSED: formatMessage(ordersMessages.ordersTableFilterStatusClosed),
      VOID: formatMessage(ordersMessages.ordersTableFilterStatusVoid),
    }),
    [formatMessage]
  )

  const fulfillmentDisplayNames: Record<string, string> = useMemo(
    () => ({
      PICKUP: formatMessage(ordersMessages.ordersTableFilterFulfillmentPickup),
      DELIVERY: formatMessage(ordersMessages.ordersTableFilterFulfillmentDelivery),
      ON_PREMISE: formatMessage(ordersMessages.ordersTableFilterFulfillmentOnPremise),
      ROOM_SERVICE: formatMessage(ordersMessages.ordersTableFilterFulfillmentRoomService),
    }),
    [formatMessage]
  )

  const statusChoices = useMemo<SelectOption[]>(
    () =>
      Object.entries(statusDisplayNames).map(([value, label]) => ({
        label,
        id: value,
      })),
    [statusDisplayNames]
  )

  const fulfillmentChoices = useMemo<SelectOption[]>(
    () =>
      Object.entries(fulfillmentDisplayNames).map(([value, label]) => ({
        id: value,
        label,
      })),
    [fulfillmentDisplayNames]
  )

  return {
    statusChoices,
    statusDisplayNames,
    fulfillmentChoices,
  }
}
