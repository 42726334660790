import type { LinkChecksTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { formatParagraph, type DetailProps, CONTACT_SUPPORT_URL } from '../../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { LinkChecksSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'

export function LinkChecksDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { insightProofData } = data

  const linkedCheckPercentage = (insightProofData?.[0]?.tableRows as LinkChecksTableRow[])[0]?.linkedCheckPercentage
  const description = formatMessage(insightsMessages.linkChecksSlideoutDescription, {
    p: (chunks: string[]) => formatParagraph(chunks),
    link: (chunks: string[]) => (
      <Link to={CONTACT_SUPPORT_URL} target="_blank">
        {chunks}
      </Link>
    ),
  })

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={description}
      suggestedActionsComponent={<LinkChecksSuggestedActions />}
      proofComponent={
        <Text>
          {linkedCheckPercentage != null
            ? `${formatMessage(insightsMessages.lowLinkedCheckPercentageLabel)}: ${linkedCheckPercentage}%`
            : formatMessage(insightsMessages.lowLinkedCheckPercentageNotFound)}
        </Text>
      }
    />
  )
}
