import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../../locales'
import { formatBold } from '../../../../utils'

export function FeedbackSurveysSuggestedActions() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-feedback-surveys-suggested-actions">
      <Text data-test="insights-feedback-surveys-suggested-actions-navigate">
        1.{' '}
        <Link
          to={nav.href(routes.manager2.settings.venue, {
            params: { venueKey: venue.urlKey },
            query: { activeTab: 'guestEmailSms' },
          })}
          target="_blank"
          data-test="insights-feedback-surveys-email-sms-link"
        >
          {formatMessage(insightsMessages.suggestedActionsOpenGuestEmailSmsStep, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          })}
        </Link>
      </Text>
      <Text data-test="insights-feedback-surveys-suggested-actions-toggle">
        2.{' '}
        {formatMessage(insightsMessages.suggestedActionsFeedbackSurveysToggleStep, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <Text data-test="insights-feedback-surveys-suggested-actions-select-time">
        3. {formatMessage(insightsMessages.suggestedActionsFeedbackSurveysSelectTimeStep)}
      </Text>
      <Text data-test="insights-feedback-surveys-suggested-actions-enable-summary-email">
        4.{' '}
        {formatMessage(insightsMessages.suggestedActionsFeedbackSurveysEnableSummaryEmailStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link
              to={nav.href(routes.manager2.settings.venue, {
                params: { venueKey: venue.urlKey },
                query: { activeTab: 'internalTeamEmails' },
              })}
              target="_blank"
              data-test="insights-feedback-surveys-suggested-actions-internal-team-emails-link"
            >
              {chunks}
            </Link>
          ),
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <Text data-test="insights-feedback-surveys-suggested-actions-enable-user-summary-email">
        5.{' '}
        {formatMessage(insightsMessages.suggestedActionsFeedbackSurveysEnableUserSummaryEmailStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link
              to={nav.href(routes.manager.userAccounts, { params: { venueKey: venue.urlKey } })}
              target="_blank"
              data-test="insights-feedback-surveys-suggested-actions-user-accounts-link"
            >
              {chunks}
            </Link>
          ),
        })}
      </Text>
    </VStack>
  )
}
