import type { Order } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { ordersMessages } from '../locales/ordersMessages'

export interface RefundOrderModalProps {
  order?: Order
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onConfirm: () => void
}

export function RefundOrderModal({ order, onConfirm, closeHref }: RefundOrderModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()

  if (!venue) {
    return null
  }

  return (
    <Modal ariaLabel="Modal" minWidth={528}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">{formatMessage(commonMessages.warning)}</Status>
          <ModalTitle
            title={formatMessage(ordersMessages.ordersRefundOrderModalTitle, {
              name: `${order?.firstName} ${order?.lastName}`,
            })}
          />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text whiteSpace="nowrap">{formatMessage(ordersMessages.ordersRefundOrderModalBody)}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            variant="secondary"
            href={nav.closeSurfaceHref(routes.manager2.orders.refundModal, { params: { venueKey: venue.urlKey } })}
            data-test="sr-orders-discard-refund"
          >
            {formatMessage(commonMessages.no)}
          </Button>
          <Button
            variant="primary"
            href={nav.closeSurfaceHref(routes.manager2.orders.refundModal, { params: { venueKey: venue.urlKey } })}
            onClick={() => onConfirm()}
            data-test="sr-orders-confirm-refund"
          >
            {formatMessage(commonMessages.yes)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
