import _ from 'lodash'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'

const prependEmptyOption = (
  choices: {
    name: number
    value: number
  }[],
  name = ''
) => _.sortBy([{ name, value: '' }, ...choices], ['name', 'value'])

const TOTAL_OPTIONS = 100
const OPTIONS = Array.from(Array(TOTAL_OPTIONS).keys()).map(i => ({
  // 0.5 to 50 by 0.5 increments
  name: (i + 1) / 2,
  value: (i + 1) / 2,
}))

export interface DeliveryRadiusProps {
  value: number
  onChange: (value: number) => void
}

function DeliveryRadius({ value, onChange }: DeliveryRadiusProps) {
  return (
    <DropdownArrowsPicker
      testId="sr-picker-delivery-radius"
      choices={prependEmptyOption(OPTIONS, 'disabled')}
      isLightTheme
      useOutsideLabel
      value={value || ''}
      onChangeHandler={onChange}
    />
  )
}

export default DeliveryRadius
