import { type ActivityLogItem, InsightActivityTypes } from '@sevenrooms/core/domain'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { DateTime, DateTimeInterval } from '@sevenrooms/core/timepiece'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Box, VStack, HStack, Avatar } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'

export function ActivityItem({ log }: { log: ActivityLogItem }) {
  const { formatMessage } = useLocales()

  const name = log.userName
  const photoUrl = log.photoKey ? `/.h/download/${log.photoKey}` : null
  const [firstName, lastName] = name ? name.split(' ', 2) : ['7', 'R']
  const date = DateTime.from(log.createdDatetime)

  const interval = new DateTimeInterval(date)
  const ageDisplay = interval.formatDateTimeDistance(true)
  const dateDisplay = date.formatNYearSMonthNDaySTime()

  const textMap: { [key: string]: string } = {
    new: formatMessage(insightsMessages.statusNewText),
    tracking: formatMessage(insightsMessages.statusTrackingLabel),
    optimized: formatMessage(insightsMessages.statusOptimizedLabel),
    declined: formatMessage(insightsMessages.statusDeclinedLabel),
    do_it_for_me: formatMessage(insightsMessages.statusDoItForMeText),
    do_it_automatically: formatMessage(insightsMessages.statusDoItAutomatically),
  }

  let message
  let details
  switch (log.activityType) {
    case InsightActivityTypes.CREATED:
      message = formatMessage(insightsMessages.activityCreated)
      break
    case InsightActivityTypes.CHANGED_STATUS:
      details = log.typeDetails
      message = formatMessage(insightsMessages.activityChangedStatus, {
        oldStatus: textMap[details.oldStatus] || '',
        newStatus: textMap[details.newStatus] || '',
        note: details.note || '',
        hasNote: !!details.note,
      })
      break
    case InsightActivityTypes.ASSIGNED:
      details = log.typeDetails
      message = formatMessage(insightsMessages.activityAssigned, details)
      break
    case InsightActivityTypes.ARCHIVED:
      message = formatMessage(insightsMessages.activityArchived)
      break
    default:
      details = log.typeDetails
      message = details.comment
  }

  return (
    <HStack mb={log.activityType === InsightActivityTypes.COMMENT ? 'xxs' : 'm'}>
      <Box mr="sm">
        <Text fontSize="s">
          <Avatar firstName={firstName || ''} lastName={lastName || ''} image={photoUrl || ''} />
        </Text>
      </Box>
      <VStack>
        <HStack>
          <Box mr="m" mb="xs">
            <Text fontSize="s" fontWeight="bold">
              {log.userName || formatMessage(commonMessages.sevenRooms)}
            </Text>
          </Box>
          <Box>
            <SecondaryText fontSize="s">
              {ageDisplay} on {dateDisplay}
            </SecondaryText>
          </Box>
        </HStack>
        <Box>
          {log.activityType === InsightActivityTypes.COMMENT ? (
            <SecondaryText>
              <HTMLContent content={message} allowedTags={['p', 'div', 'br', 'u', 'b', 'strong', 'i', 'em']} data-test="activity-comment" />
            </SecondaryText>
          ) : (
            <SecondaryText fontStyle="italic">{message}</SecondaryText>
          )}
        </Box>
      </VStack>
    </HStack>
  )
}
