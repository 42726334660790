import { useLocales } from '@sevenrooms/core/locales'
import { Button, TextArea } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, VStack, DividerLine, Box } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText, Link } from '@sevenrooms/core/ui-kit/typography'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { useInsightSuggestedActionsContext } from '../../../hooks'
import { insightsMessages } from '../../../locales'
import { CONTACT_SUPPORT_URL } from '../../../utils'
import { StatusIcon } from '../../Status'
import type { PropsWithChildren } from 'react'

export function InsightChangeRequestedSection({ children }: PropsWithChildren<{}>) {
  const { formatMessage } = useLocales()
  const isSuperhero = useNavigationPermissionsContext()?.isSuperhero
  const { insight, onResolved } = useInsightSuggestedActionsContext()
  const titleContent = (
    <HStack justifyContent="space-between" data-test="insight-change-requested-title">
      <HStack spacing="s">
        <StatusIcon variant="tracking" />
        <Text textStyle="h2">{formatMessage(insightsMessages.statusChangeRequestedLabel)}</Text>
      </HStack>
    </HStack>
  )

  return (
    <BaseSection
      title={titleContent}
      data-test="insight-change-requested-section"
      actions={
        isSuperhero && (
          <Button variant="primary" size="s" data-test="insight-change-request-complete-button" onClick={onResolved}>
            {formatMessage(insightsMessages.changeRequestedMarkCompleteButtonLabel)}
          </Button>
        )
      }
    >
      <VStack pt="m">
        <DividerLine />
        <VStack p="m" spacing="s">
          <SecondaryText data-test="insight-change-requested-suggested-actions-description">
            {formatMessage(insightsMessages.changeRequestedSuggestedActionDescription)}
          </SecondaryText>
          <Box data-test="insight-change-requested-content">{children}</Box>
          <DividerLine />
          <SecondaryText data-test="insight-change-requested-description">
            {formatMessage(insightsMessages.changeRequestedText, {
              link: (
                <Link to={CONTACT_SUPPORT_URL} target="_blank" data-test="insight-change-requested-help-link">
                  {formatMessage(insightsMessages.changeRequestedLinkText)}
                </Link>
              ),
            })}
          </SecondaryText>
          {insight.changeRequestInstructions && (
            <Box data-test="change-request-instructions">
              <TextArea
                data-test="insights-change-request-display-input"
                aria-label={formatMessage(insightsMessages.changeRequestedInstructionsAriaLabel)}
                value={insight.changeRequestInstructions}
                disabled
              />
            </Box>
          )}
        </VStack>
      </VStack>
    </BaseSection>
  )
}
