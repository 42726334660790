import type { NotificationType, OrderMethod, OrderStatus } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { orderingNotificationSettingsMessages } from '../../locales/orderingNotificationSettingsMessages'
import { NotificationToggleRow, NotificationTableWidth } from './NotificationToggleRow'

export interface NotificationToggleTableProps {
  getNotificationSetting: (method: OrderMethod, status: OrderStatus, notificationType: NotificationType) => boolean | null
  toggleNotificationSetting: (method: OrderMethod, status: OrderStatus, notificationType: NotificationType) => void
}

export interface BaseNotificationToggleTableProps extends NotificationToggleTableProps {
  method: OrderMethod
}

export function BaseNotificationToggleTable({
  children,
  getNotificationSetting,
  toggleNotificationSetting,
  method,
}: React.PropsWithChildren<BaseNotificationToggleTableProps>) {
  const { formatMessage } = useLocales()
  return (
    <VStack>
      <HStack justifyContent="flex-end" width={NotificationTableWidth} ml="lm">
        <HStack justifyContent="space-between" width="110px">
          <Box width="40px">
            <Text>{formatMessage(orderingNotificationSettingsMessages.emailColumnLabel)}</Text>
          </Box>
          <Box width="40px">
            <Text>{formatMessage(orderingNotificationSettingsMessages.smsColumnLabel)}</Text>
          </Box>
        </HStack>
      </HStack>
      <NotificationToggleRow
        notificationType={formatMessage(orderingNotificationSettingsMessages.orderConfirmationTitle)}
        notificationTypeDescription={formatMessage(orderingNotificationSettingsMessages.orderConfirmationDescription)}
        emailEnabled={getNotificationSetting(method, 'OPEN', 'EMAIL')}
        smsEnabled={getNotificationSetting(method, 'OPEN', 'SMS')}
        emailOnChange={() => toggleNotificationSetting(method, 'OPEN', 'EMAIL')}
        smsOnChange={() => toggleNotificationSetting(method, 'OPEN', 'SMS')}
        data-test="ordering-notification-settings-confirmation-row"
      />
      <NotificationToggleRow
        notificationType={formatMessage(orderingNotificationSettingsMessages.workingOnOrderTitle)}
        notificationTypeDescription={formatMessage(orderingNotificationSettingsMessages.workingOnOrderDescription)}
        emailEnabled={getNotificationSetting(method, 'WORKING_ON_ORDER', 'EMAIL')}
        smsEnabled={getNotificationSetting(method, 'WORKING_ON_ORDER', 'SMS')}
        emailOnChange={() => toggleNotificationSetting(method, 'WORKING_ON_ORDER', 'EMAIL')}
        smsOnChange={() => toggleNotificationSetting(method, 'WORKING_ON_ORDER', 'SMS')}
        data-test="ordering-notification-settings-working-on-order-row"
      />
      {children}
      <NotificationToggleRow
        notificationType={formatMessage(orderingNotificationSettingsMessages.refundTitle)}
        notificationTypeDescription={formatMessage(orderingNotificationSettingsMessages.refundDescription)}
        emailEnabled={getNotificationSetting(method, 'VOID', 'EMAIL')}
        smsEnabled={getNotificationSetting(method, 'VOID', 'SMS')}
        emailOnChange={() => toggleNotificationSetting(method, 'VOID', 'EMAIL')}
        smsOnChange={() => toggleNotificationSetting(method, 'VOID', 'SMS')}
        data-test="ordering-notification-settings-refund-row"
      />
    </VStack>
  )
}
