import { defineMessages } from '@sevenrooms/core/locales'

export const orderingNotificationSettingsMessages = defineMessages({
  pageTitle: {
    id: 'orderingNotificationSettings.pageTitle',
    defaultMessage: 'Guest Notifications',
  },
  pageDescription: {
    id: 'orderingNotificationSettings.pageDescription',
    defaultMessage: 'Customize which emails and SMS your guests will receive for their online order',
  },
  saveButtonLabel: {
    id: 'orderingNotificationSettings.saveButtonLabel',
    defaultMessage: 'Save and Publish',
  },
  relatedSettingsTitle: {
    id: 'orderingNotificationSettings.relatedSettingsTitle',
    defaultMessage: 'Related Settings - Language Configurations',
  },
  relatedSettingsDescription: {
    id: 'orderingNotificationSettings.relatedSettingsDescription',
    defaultMessage: 'All the language configurations for each outbound SMS can be configured in Guest Facing Settings.',
  },
  relatedSettingsEditButtonLabel: {
    id: 'orderingNotificationSettings.relatedSettingsEditButtonLabel',
    defaultMessage: 'Edit Settings',
  },
  emailColumnLabel: {
    id: 'orderingNotificationSettings.emailColumnLabel',
    defaultMessage: 'Email',
  },
  smsColumnLabel: {
    id: 'orderingNotificationSettings.emailColumnLabel',
    defaultMessage: 'SMS',
  },
  pickupSectionTitle: {
    id: 'orderingNotificationSettings.pickupSectionTitle',
    defaultMessage: 'Pickup Notifications',
  },
  deliverySectionTitle: {
    id: 'orderingNotificationSettings.deliverySectionTitle',
    defaultMessage: 'Delivery Notifications',
  },
  onPremSectionTitle: {
    id: 'orderingNotificationSettings.onPremSectionTitle',
    defaultMessage: 'On Premise Notifications',
  },
  roomServiceSectionTitle: {
    id: 'orderingNotificationSettings.roomServiceSectionTitle',
    defaultMessage: 'Room Service Notifications',
  },
  orderConfirmationTitle: {
    id: 'orderingNotificationSettings.orderConfirmationTitle',
    defaultMessage: 'Order Confirmation',
  },
  orderConfirmationDescription: {
    id: 'orderingNotificationSettings.orderConfirmationDescription',
    defaultMessage: 'Guests will receive a SMS when they place an online order.',
  },
  workingOnOrderTitle: {
    id: 'orderingNotificationSettings.workingOnOrderTitle',
    defaultMessage: 'Working on Order',
  },
  workingOnOrderDescription: {
    id: 'orderingNotificationSettings.workingOnOrderDescription',
    defaultMessage: 'Guests will receive a SMS when the status on their order is changed to “Working on Order”.',
  },
  readyForPickupTitle: {
    id: 'orderingNotificationSettings.readyForPickupTitle',
    defaultMessage: 'Ready for Pickup',
  },
  readyForPickupDescription: {
    id: 'orderingNotificationSettings.readyForPickupDescription',
    defaultMessage: 'Guests will receive a SMS when the status on their order is changed to “Ready for Pickup”.',
  },
  refundTitle: {
    id: 'orderingNotificationSettings.refundTitle',
    defaultMessage: 'Refund',
  },
  refundDescription: {
    id: 'orderingNotificationSettings.refundDescription',
    defaultMessage: 'Guests will receive a SMS when a user refunds part or all of their order.',
  },
  readyForDriverTitle: {
    id: 'orderingNotificationSettings.readyForDriverTitle',
    defaultMessage: 'Ready for Driver',
  },
  readyForDriverDescription: {
    id: 'orderingNotificationSettings.readyForDriverDescription',
    defaultMessage: 'Guests will receive a SMS when the status on their order is changed to “Ready for Driver”.',
  },
  withDriverTitle: {
    id: 'orderingNotificationSettings.withDriverTitle',
    defaultMessage: 'With Driver',
  },
  withDriverDescription: {
    id: 'orderingNotificationSettings.withDriverDescription',
    defaultMessage: 'Guests will receive a SMS when the status on their order is changed to “With Driver”.',
  },
  saveSuccessMessage: {
    id: 'orderingNotificationSettings.saveSuccessMessage',
    defaultMessage: 'Successfully saved changes.',
  },
  saveErrorMessage: {
    id: 'orderingNotificationSettings.saveErrorMessage',
    defaultMessage: 'Unable to save changes.',
  },
  fetchErrorMessage: {
    id: 'orderingNotificationSettings.fetchErrorMessage',
    defaultMessage: 'An error occurred or settings not found for the venue.',
  },
  disabledToggleTooltipMessage: {
    id: 'orderingNotificationSettings.disabledToggleTooltipMessage',
    defaultMessage:
      'This is not editable. SevenRooms sends out emails to diners. If you have any questions, please reach out to our support.',
  },
} as const)
