import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as ActionTypes from 'mgr/pages/single-venue/reporting/actions/ActionTypes'

import { srPost, srUpload } from '@sevenrooms/core/api'

const SR_API_VERSION = 'api/1_12'
const SR_RESY_RESERVATIONS_UPLOAD_URL = `/${SR_API_VERSION}/imports/resy_reservations_upload`

const isValidResyCsv = file =>
  !_.isNil(file) &&
  !_.isNil(file.name) &&
  (file.name.indexOf('reservation_report') !== -1 || file.name.indexOf('monthly_reservations') !== -1) &&
  _.endsWith(file.name.toLowerCase(), '.csv')

export const tryUploadResyReservations = file => dispatch => {
  if (!isValidResyCsv(file)) {
    dispatch(GlobalActions.showErrorMessage(`File does not match expected format`))
    return
  }

  const failureHandler = (error, errorMsg) => {
    dispatch(
      GlobalActions.showErrorMessage(
        `Failed to process upload. ${errorMsg || 'Please make sure your reservation export file matches this restaurant.'}`
      )
    )
    return { error, errorMsg }
  }
  dispatch({ type: ActionTypes.RESY_IMPORT_UPLOAD_RESERVATIONS_START })
  uploadFileRequest(file)
    .then(uploadData => {
      if (uploadData.error) {
        throw uploadData.error
      }
      dispatch({ type: ActionTypes.RESY_IMPORT_UPLOAD_RESERVATIONS_SUCCESS })
      dispatch(
        GlobalActions.showSuccessMessage(
          'Successfully uploaded Resy Reservations. Depending on file size, please allow several minutes for processing.'
        )
      )
    })
    .catch(error => {
      failureHandler()
      dispatch({ type: ActionTypes.RESY_IMPORT_UPLOAD_RESERVATIONS_FAIL })
    })
}

export const uploadFileRequest = file =>
  srPost(`/upload-url`, { rurl: SR_RESY_RESERVATIONS_UPLOAD_URL }, { convertToGetParams: true }).then(response =>
    srUpload(response.upload_url, file, {
      file_param: 'reservations',
      'follow-redirect': true,
    })
  )
