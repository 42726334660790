import { type FormEvent, useState, useCallback } from 'react'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Button, TextArea } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, VStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useInsightSuggestedActionsContext } from '../../../hooks'
import { insightsMessages } from '../../../locales'
import { StatusIcon } from '../../Status'

export function InsightDeclineReasonSection() {
  const { formatMessage } = useLocales()
  const [submitDeclineInvalid, setSubmitDeclineInvalid] = useState<boolean>(false)
  const { declinedReason, onSubmitDecline, setDeclinedReason, setDisplayDeclineReasonSection } = useInsightSuggestedActionsContext()
  const titleContent = (
    <HStack spacing="s" data-test="insight-declined-reason-title">
      <StatusIcon variant="new" />
      <Text textStyle="h2">{formatMessage(insightsMessages.insightSuggestedActionTitle)}</Text>
    </HStack>
  )

  const onDecline = useCallback(() => {
    if (!declinedReason?.trim()) {
      setSubmitDeclineInvalid(true)
      return
    }
    setSubmitDeclineInvalid(false)
    onSubmitDecline()
  }, [declinedReason, onSubmitDecline, setSubmitDeclineInvalid])

  return (
    <BaseSection title={titleContent} data-test="insight-declined-reason-section">
      <VStack pt="m">
        <DividerLine />
        <VStack p="sm sm sm l" spacing="s">
          <Text fontWeight="bold">{formatMessage(insightsMessages.declineReasonExplainText)}</Text>
          <SecondaryText>{formatMessage(insightsMessages.declineReasonFeedbackText)}</SecondaryText>
          <TextArea
            data-test="insight-decline-reason-edit-input"
            aria-label={formatMessage(insightsMessages.declineReasonExplainText)}
            fullWidth
            value={declinedReason}
            onChange={(e: FormEvent<HTMLTextAreaElement>) => {
              setSubmitDeclineInvalid(false)
              setDeclinedReason(e.currentTarget.value)
            }}
            invalid={submitDeclineInvalid}
            errorMessage={submitDeclineInvalid ? formatMessage(insightsMessages.emptyDeclinedReasonErrorMessage) : undefined}
          />
          <HStack justifyContent="right" spacing="sm">
            <Button
              variant="secondary-warning"
              size="s"
              data-test="insight-decline-cancel-button"
              onClick={() => setDisplayDeclineReasonSection(false)}
            >
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button variant="primary" size="s" data-test="insight-decline-submit-button" onClick={onDecline}>
              {formatMessage(commonMessages.submit)}
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </BaseSection>
  )
}
