import styled from 'styled-components'

const EditingLayout = styled.div`
  max-width: 715px;
  padding: ${props => props.theme.gutter.triple};
  margin-bottom: 80px;
  font-size: ${props => props.theme.fontSize.body};
`

export default EditingLayout
