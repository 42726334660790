import _ from 'lodash'
import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import { StyledIcons, VmsIcons } from 'svr/common/VmsIcons'
import { Option, type OptionItem } from 'svr/component-lib/Manager/Menus/SimpleDropdownMenu/Option'

const OUTSIDE_CURSOR_POSITION = -1
const SEARCH_INPUT_CURSOR_POSITION = 0
const FIRST_OPTION_CURSOR_POSITION = 1

export interface SimpleDropdownMenuProps<T extends string | number> {
  items: OptionItem<T>[]
  activeItemId: T
  isSearchable: boolean
  onSelect: (item: OptionItem<T>) => void
  onToggle?: (isOpen: boolean) => void
}

export function SimpleDropdownMenu<T extends string | number>({
  items,
  activeItemId,
  isSearchable = false,
  onToggle,
  onSelect,
}: SimpleDropdownMenuProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [cursor, setCursor] = useState(OUTSIDE_CURSOR_POSITION)
  const searchInputRef = useRef<HTMLInputElement>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeItem = useMemo(() => _.find(items, { id: activeItemId }) as OptionItem<T>, [activeItemId])
  const firstFocusableElPosition = useMemo(
    () => (isSearchable ? SEARCH_INPUT_CURSOR_POSITION : FIRST_OPTION_CURSOR_POSITION),
    [isSearchable]
  )

  const filteredItems = useMemo(
    () => items.filter(item => item.id !== activeItemId && _.includes(item.displayName.toLowerCase(), searchString.toLowerCase())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, searchString, activeItem]
  )

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
    if (onToggle) {
      onToggle(!isOpen)
    }
  }, [isOpen, onToggle])

  const onSearchInputFocus = useCallback(() => {
    setCursor(SEARCH_INPUT_CURSOR_POSITION)
  }, [])

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.keyCode) {
        case 38:
          // Key Up
          if (cursor === firstFocusableElPosition) {
            setCursor(filteredItems.length)
          } else {
            setCursor(cursor - 1)
          }
          break
        case 40:
          // Key down
          if (cursor === filteredItems.length) {
            setCursor(firstFocusableElPosition)
          } else {
            setCursor(cursor + 1)
          }
          break
        case 13:
          // Key Enter
          if (cursor > 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onSelect(filteredItems[cursor - 1]!)
          }
          break
        default:
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cursor, filteredItems, onSelect]
  )

  useEffect(() => {
    close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem])

  useEffect(() => {
    if (isOpen) {
      setCursor(firstFocusableElPosition)
    } else {
      setCursor(OUTSIDE_CURSOR_POSITION)
      setSearchString('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    const searchInput = searchInputRef.current
    if (searchInput && cursor === SEARCH_INPUT_CURSOR_POSITION) {
      searchInput.focus()
    }
  }, [cursor])

  return (
    <OutsideClickHandler onOutsideClick={close}>
      {activeItem && (
        <Picker tabIndex={0} onKeyDown={handleKeyDown}>
          <ToggleLink data-test="sr-menu-toggle-link" onClick={toggle}>
            <SelectedItem data-test="sr-menu-selected-item">{activeItem.displayName}</SelectedItem>
            <DownCaretIcon>{VmsIcons.Chevron}</DownCaretIcon>
          </ToggleLink>
          {isOpen && (
            <DropdownContent>
              <PinArrow />
              <OptionsWrapper>
                {isSearchable && (
                  <SearchInput
                    ref={searchInputRef}
                    data-test="sr-menu-search-input"
                    type="text"
                    placeholder="Search.."
                    value={searchString}
                    onFocus={onSearchInputFocus}
                    onChange={onChange}
                  />
                )}
                <ul>
                  {filteredItems.map((item, index) => (
                    <Option key={item.id} item={item} active={index === cursor - 1} onSelect={onSelect} />
                  ))}
                </ul>
              </OptionsWrapper>
            </DropdownContent>
          )}
        </Picker>
      )}
    </OutsideClickHandler>
  )
}

const Picker = styled.div`
  float: right;
  margin: 5px 10px;
  outline: none;
`

const DropdownContent = styled.div`
  line-height: 41px;
  margin-top: 10px;
  position: absolute;
  right: 5px;
  top: 28px;
  width: 180px;
  z-index: 999;
`

const SelectedItem = styled.div`
  float: left;
`

const CaretIcon = styled(StyledIcons.S)`
  float: right;
  margin-top: 6px;
  color: #bdbdbd;
`

const DownCaretIcon = styled(CaretIcon)`
  transform: rotate(90deg);
`

const OptionsWrapper = styled.div`
  border-radius: 3px;
  background: #444;
  padding: 6px;

  ul {
    margin: 0;
    padding: 0;
  }
`

const SearchInput = styled.input`
  && {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #999;
    border-radius: 3px;
  }
`

const PinArrow = styled.div`
  height: 0;
  width: 0;
  border-bottom: 8px solid #444;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  right: 10px;
  top: -6px;
  margin: 0 0 0 5px;
`

const ToggleLink = styled.div`
  float: left;
  line-height: 31px;
  color: #777;
  cursor: pointer;
`
