import _ from 'lodash'
import moment from 'moment-timezone'
import { useMemo, useEffect } from 'react'
import { dateParts, getPythonWeekday, formatWithoutYear } from 'mgr/lib/utils/MomentUtils'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { useDebounceStateEffect } from '@sevenrooms/core/ui-kit/hooks'
import { Table, TableBody, TableRow, TableCell, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { DayTimeLimitMatrix } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'

export interface PacingDayTimeLimitMatrixProps {
  dayTimeLimitMatrix: DayTimeLimitMatrix
  dateRangeFrom: moment.Moment | undefined
  dateRangeTo: moment.Moment | undefined | null
  timeRangeFrom: moment.Moment | undefined
  timeRangeTo: moment.Moment | undefined
  timeIntervalMins: number
  updateDayTimeLimitMatrix: (value: DayTimeLimitMatrix) => void
}

export function PacingDayTimeLimitMatrix({
  dayTimeLimitMatrix,
  dateRangeFrom,
  dateRangeTo,
  timeRangeFrom,
  timeRangeTo,
  timeIntervalMins,
  updateDayTimeLimitMatrix,
}: PacingDayTimeLimitMatrixProps) {
  const timeSlots = useMemo(() => {
    if (!timeRangeFrom || !timeRangeTo || !timeRangeFrom.isValid() || !timeRangeTo.isValid()) {
      return []
    }
    const results = []
    let iterTime = timeRangeFrom.clone()
    while (iterTime.isBefore(timeRangeTo)) {
      results.push(iterTime)
      iterTime = iterTime.clone().add({ minutes: timeIntervalMins || 15 })
    }
    return results
  }, [timeRangeFrom, timeRangeTo, timeIntervalMins])
  const weekdays = useMemo(() => enabledWeekdays(dateRangeFrom, dateRangeTo), [dateRangeFrom, dateRangeTo])
  useEffect(() => {
    let needsUpdate = false
    const dayTimeLimitMatrixClone = {} as DayTimeLimitMatrix
    for (const [dayOfWeek] of weekdays) {
      dayTimeLimitMatrixClone[dayOfWeek] = {}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const clonedDayLimits = dayTimeLimitMatrixClone[dayOfWeek]!
      const existingDayLimits = dayTimeLimitMatrix?.[dayOfWeek]
      for (const timeSlot of timeSlots) {
        const tsKey = canonicalTimeSlot(timeSlot)
        if (existingDayLimits?.[tsKey] !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          clonedDayLimits[tsKey] = existingDayLimits[tsKey]!
        } else {
          clonedDayLimits[tsKey] = null
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (!dayTimeLimitMatrix || !hasSameKeys(clonedDayLimits, existingDayLimits!)) {
        needsUpdate = true
      }
    }
    if (!hasSameKeys(dayTimeLimitMatrixClone, dayTimeLimitMatrix)) {
      needsUpdate = true
    }
    if (needsUpdate) {
      updateDayTimeLimitMatrix(dayTimeLimitMatrixClone)
    }
  }, [dayTimeLimitMatrix, weekdays, timeSlots, updateDayTimeLimitMatrix])

  return (
    <Table>
      <thead>
        <tr>
          <TableCell key="day_col" isHeader isInFirstRow attach="left" minWidth={120}>
            Day / Time
          </TableCell>
          {weekdays.map(([dayOfWeek, weekdayName]) => (
            <TableCell key={dayOfWeek} isHeader isInFirstRow minWidth={120} borderLeft>
              {weekdayName}
            </TableCell>
          ))}
        </tr>
      </thead>

      <TableBody>
        {timeSlots.map((timeSlot, row, rowArr) => (
          <TableRow key={timeSlot.format()}>
            <TableCell key="day_col" isInLastRow={row === rowArr.length - 1} attach="left">
              <VStack alignItems="start">
                <span key="from-time">{timeSlot.format('LT')}</span>
                <Text key="to-time" color="secondaryFont">
                  - {formatSlotEndTime(timeSlot, timeIntervalMins, timeRangeTo)}
                </Text>
              </VStack>
            </TableCell>
            {weekdays.map(([dayOfWeek]) => (
              <TableCell key={dayOfWeek} isInLastRow={row === rowArr.length - 1} removePadding borderLeft>
                <MatrixCellInput
                  limit={getLimit(dayTimeLimitMatrix, dayOfWeek, timeSlot)}
                  onChange={(slotLimit: string) =>
                    updateDayTimeLimitMatrix(cloneAndSetLimit(dayTimeLimitMatrix, dayOfWeek, timeSlot, slotLimit))
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

interface MatrixCellInputProps {
  limit: number | null | undefined
  onChange: (slotLimit: string) => void
}

function MatrixCellInput({ limit, onChange }: MatrixCellInputProps) {
  const savedLimit = _.isNil(limit) ? '' : `${limit}`
  const [debouncedLimit, setDebouncedLimit] = useDebounceStateEffect<string>(savedLimit, onChange, 200)
  return (
    <Input
      value={debouncedLimit}
      onChange={event => setDebouncedLimit(event.target.value)}
      placeholder="unlimited"
      border="none"
      type="number"
      min="0"
    />
  )
}

const enabledWeekdays = (
  dateRangeFrom: moment.Moment | undefined,
  dateRangeTo: moment.Moment | undefined | null
): [number, string | JSX.Element][] => {
  if (!dateRangeFrom || !dateRangeTo) {
    return allWeekdays()
  }
  const numDays = dateRangeTo.diff(dateRangeFrom, 'days')
  if (numDays < 0) {
    return []
  }
  if (numDays >= 7) {
    return allWeekdays()
  }
  const iterMoment = moment(dateParts(dateRangeFrom))
  const safeDateRangeTo = moment(dateParts(dateRangeTo))
  const results = []
  while (!iterMoment.isAfter(safeDateRangeTo)) {
    results.push([
      getPythonWeekday(iterMoment),
      <VStack key={iterMoment.format()} alignItems="start">
        <span key="weekday">{iterMoment.format('dddd')}</span>
        <Text key="date" color="secondaryFont">
          {formatWithoutYear(iterMoment, 'L')}
        </Text>
      </VStack>,
    ] as [number, JSX.Element])
    iterMoment.add({ days: 1 })
  }
  return results
}

const allWeekdays = (): [number, string][] => {
  const weekdays = moment.weekdays()
  weekdays.push(weekdays.shift() as string)
  return weekdays.map((weekdayName, weekday) => [weekday, weekdayName])
}

const getLimit = (dayTimeLimitMatrix: DayTimeLimitMatrix, dayOfWeek: number, timeSlot: moment.Moment) =>
  dayTimeLimitMatrix?.[dayOfWeek]?.[canonicalTimeSlot(timeSlot)]

const canonicalTimeSlot = (timeSlot: moment.Moment) => timeSlot.hour() * 60 + timeSlot.minute()

const cloneAndSetLimit = (dayTimeLimitMatrix: DayTimeLimitMatrix, dayOfWeek: number, timeSlot: moment.Moment, limit: string) => ({
  ...dayTimeLimitMatrix,
  [dayOfWeek]: {
    ...dayTimeLimitMatrix?.[dayOfWeek],
    [canonicalTimeSlot(timeSlot)]: parseLimit(limit),
  },
})

const parseLimit = (limit: string) => (limit ? Math.max(0, parseInt(limit, 10)) : null)

const hasSameKeys = (a: Record<number, unknown>, b: Record<number, unknown>) => _.isEqual(_.keys(a).sort(), _.keys(b).sort())

const formatSlotEndTime = (timeSlot: moment.Moment, timeIntervalMins: number, timeRangeTo: moment.Moment | undefined) => {
  let endTime = timeSlot.clone().add({ minutes: timeIntervalMins - 1 })
  if (timeRangeTo && endTime.isAfter(timeRangeTo)) {
    endTime = timeRangeTo.clone().subtract({ minutes: 1 })
  }
  return endTime.format('LT')
}
