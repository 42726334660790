import _ from 'lodash'
import { batchActions } from 'redux-batched-actions'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import {
  fetchPodConfigurations,
  tryAddPodCategory,
  tryEditPodCategory,
  tryDeletePodCategory,
  tryAddPodToVenue,
  tryRemovePodFromVenue,
} from 'mgr/lib/services/PodServices'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'

export const dispatchConfigData = (dispatch, actionType, response) => {
  dispatch({
    type: actionType,
    podsConfigData: response.data.pods_config_data,
    podAccessors: response.data.pod_accessors,
    podCategoriesByAccessor: response.data.pod_categories_by_accessor,
    podCategorySearchGroupId: response.data.pod_category_search_group_id,
    totalVenues: response.data.total_venues,
  })
}

export const tryLoadPodConfigs = venueGroup => (dispatch, getState) => {
  const { pods } = getState()
  if (Object.keys(pods.podCategoriesByAccessor).length !== 0) {
    return
  }
  return dispatch(loadPodConfigs(venueGroup.id))
}

const tryLoadPodConfigsIfNoOptimisticPending = () => (dispatch, getState) => {
  const { appState, pods } = getState()
  if (pods.optimisticPending === 0) {
    dispatch(loadPodConfigs(appState.venueGroup.id, true))
  }
}

const loadPodConfigs =
  (venueGroupId, suppressErrorMessage = false) =>
  dispatch => {
    dispatch({ type: ActionTypes.LOAD_VENUE_POD_CONFIGS_START })
    return fetchPodConfigurations({ venue_group_id: venueGroupId })
      .then(response => dispatchConfigData(dispatch, ActionTypes.LOAD_VENUE_POD_CONFIGS_SUCCESS, response))
      .catch(e => {
        console.error('an error occurred fetching pod configurations', e.toString())
        dispatch({
          type: ActionTypes.LOAD_VENUE_POD_CONFIGS_FAIL,
        })
        if (!suppressErrorMessage) {
          dispatch(GlobalActions.showErrorMessage('Fetching pod configurations failed, please try again or contact support'))
        }
        return e
      })
  }

export const openAddPodSlideout = (isNewPodCategory, podCategoryName, podCategoryHexColor, pods) => ({
  type: ActionTypes.OPEN_POD_SLIDEOUT,
  isNewPodCategory,
  selectedPodCategoryName: podCategoryName,
  selectedPodCategoryHexColor: podCategoryHexColor,
  selectedPods: pods,
})

export const openEditPodSlideout = (dispatch, isNewPodCategory, podCategoryId, podCategoryName, podCategoryHexColor, pods) => {
  dispatch({
    type: ActionTypes.OPEN_POD_SLIDEOUT,
    isNewPodCategory,
    selectedPodCategoryId: podCategoryId,
    selectedPodCategoryName: podCategoryName,
    selectedPodCategoryHexColor: podCategoryHexColor,
    selectedPods: pods,
  })
}

export const onEditPodCategoryClick = accessor_name => (dispatch, getState) => {
  const { pods } = getState()
  const podCategory = pods.podCategoriesByAccessor[accessor_name]
  const podCategoryPods = JSON.parse(JSON.stringify(podCategory.pods))
  openEditPodSlideout(dispatch, false, podCategory.id, podCategory.name, podCategory.hex_color, podCategoryPods)
}

export const changePodCategoryName = podCategoryName => ({
  type: ActionTypes.CHANGE_POD_CATEGORY_NAME,
  podCategoryName,
})

export const onColorSwatchClick = podHexColor => ({
  type: ActionTypes.CHANGE_POD_HEX_COLOR,
  hexColor: podHexColor,
})

export const appendNewPod = (callbackFunc, text) => ({
  type: ActionTypes.APPEND_NEW_POD,
  callbackFunc,
  text,
})

export const changePodName = (podName, idx, callbackFunc) => ({
  type: ActionTypes.CHANGE_POD_NAME,
  podName,
  idx,
  callbackFunc,
})

export const deletePod = (idx, callbackFunc) => ({
  type: ActionTypes.DELETE_POD,
  idx,
  callbackFunc,
})

export const addPodToVenue = (venueId, venueGroupId, podCategoryId, podId) => (dispatch, getState) => {
  const pod = getOptimisticAddedPod(venueId, venueGroupId, podCategoryId, podId, getState())
  dispatch(
    batchActions(
      [
        { type: ActionTypes.ADD_POD_TO_VENUE_START },
        pod && { type: ActionTypes.ADD_PENDING_OPTIMISTIC_OPERATION },
        pod && { type: ActionTypes.ADD_POD_TO_VENUE_OPTIMISTIC_UPDATE, venueId, pod },
      ].filter(Boolean)
    )
  )

  const params = {
    venue_id: venueId,
    venue_group_id: venueGroupId,
    pod_category_id: podCategoryId,
    pod_id: podId,
  }

  return tryAddPodToVenue(params)
    .then(() => {
      dispatch(
        batchActions(
          [{ type: ActionTypes.ADD_POD_TO_VENUE_SUCCESS }, pod && { type: ActionTypes.REMOVE_PENDING_OPTIMISTIC_OPERATION }].filter(Boolean)
        )
      )
    })
    .catch(e => {
      console.error('an error occurred adding pod to venue', e.toString())
      dispatch(
        batchActions(
          [
            { type: ActionTypes.ADD_POD_TO_VENUE_FAIL },
            pod && { type: ActionTypes.REMOVE_PENDING_OPTIMISTIC_OPERATION },
            pod && { type: ActionTypes.ADD_POD_TO_VENUE_OPTIMISTIC_ROLLBACK, venueId, pod },
            GlobalActions.showErrorMessage('Failed to add pod to venue, please try again or contact support'),
          ].filter(Boolean)
        )
      )
      return e
    })
    .finally(() => dispatch(tryLoadPodConfigsIfNoOptimisticPending()))
}

const getOptimisticAddedPod = (venueId, venueGroupId, podCategoryId, podId, state) => {
  const category = _.find(_.values(state.pods.podCategoriesByAccessor), category => category.id === podCategoryId)
  const pod = category && _.find(category.pods, pod => pod.id === podId)
  return (
    pod && {
      id: podId,
      name: pod.name,
      venue_group_id: venueGroupId,
      pod_category_id: podCategoryId,
      accessor_name: category.accessor_name,
      hex_color: category.hex_color,
    }
  )
}

export const removePodFromVenue = (venueId, venueGroupId, podCategoryId, podId) => (dispatch, getState) => {
  const pod = getOptimisticRemovedPod(venueId, podCategoryId, podId, getState())
  dispatch(
    batchActions(
      [
        { type: ActionTypes.ADD_POD_TO_VENUE_START },
        pod && { type: ActionTypes.ADD_PENDING_OPTIMISTIC_OPERATION },
        pod && { type: ActionTypes.REMOVE_POD_FROM_VENUE_OPTIMISTIC_UPDATE, venueId, pod },
      ].filter(Boolean)
    )
  )

  const params = {
    venue_id: venueId,
    venue_group_id: venueGroupId,
    pod_category_id: podCategoryId,
    pod_id: podId,
  }

  return tryRemovePodFromVenue(params)
    .then(() => {
      dispatch(
        batchActions(
          [{ type: ActionTypes.REMOVE_POD_FROM_VENUE_SUCCESS }, pod && { type: ActionTypes.REMOVE_PENDING_OPTIMISTIC_OPERATION }].filter(
            Boolean
          )
        )
      )
    })
    .catch(e => {
      console.error('an error occurred removing pod from venue', e.toString())
      dispatch(
        batchActions(
          [
            { type: ActionTypes.REMOVE_POD_FROM_VENUE_FAIL },
            pod && { type: ActionTypes.REMOVE_PENDING_OPTIMISTIC_OPERATION },
            pod && { type: ActionTypes.REMOVE_POD_FROM_VENUE_OPTIMISTIC_ROLLBACK, venueId, pod },
            GlobalActions.showErrorMessage('Failed to remove pod from venue, please try again or contact support'),
          ].filter(Boolean)
        )
      )
      return e
    })
    .finally(() => dispatch(tryLoadPodConfigsIfNoOptimisticPending()))
}

const getOptimisticRemovedPod = (venueId, podCategoryId, podId, state) => {
  const category = _.find(_.values(state.pods.podCategoriesByAccessor), it => it.id === podCategoryId)
  const venue = _.find(state.pods.podsConfigData, it => it.venue_id === venueId)
  const pod = category && venue && _.find(venue[category.accessor_name], it => it.id === podId)
  return pod && { ...pod, accessor_name: category.accessor_name }
}

const getPodCategoryFormParams = (appState, pods) => {
  const venueGroupId = appState.venueGroup.id
  const params = {
    venue_group_id: venueGroupId,
    pod_category_id: pods.selectedPodCategoryId,
    pod_category_name: pods.selectedPodCategoryName,
    hex_color: pods.selectedPodCategoryHexColor,
    is_availability_search_group: pods.selectedPodCategoryIsSearchGroup,
    pods: JSON.stringify(
      _.map(
        _.filter(pods.selectedPods, p => !p.is_deleted),
        pod => ({ name: pod.name, id: pod.id })
      )
    ),
  }
  return params
}

export const addPodCategory = () => (dispatch, getState) => {
  const { appState, pods } = getState()
  dispatch({
    type: ActionTypes.ADD_POD_CATEGORY_START,
  })
  const params = getPodCategoryFormParams(appState, pods)

  return tryAddPodCategory(params)
    .then(response => {
      dispatchConfigData(dispatch, ActionTypes.ADD_POD_CATEGORY_SUCCESS, response)
      dispatch(GlobalActions.showSuccessMessage(`You have successfully created Pod Category: ${params.pod_category_name}`))
    })
    .catch(e => {
      console.error('an error occurred adding new pod category', e.toString())
      dispatch({
        type: ActionTypes.ADD_POD_CATEGORY_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Failed to add new pod category, please try again or contact support'))
      return e
    })
}

export const editPodCategory = () => (dispatch, getState) => {
  const { appState, pods } = getState()
  dispatch({
    type: ActionTypes.EDIT_POD_CATEGORY_START,
  })
  const params = getPodCategoryFormParams(appState, pods)

  return tryEditPodCategory(params)
    .then(response => {
      dispatchConfigData(dispatch, ActionTypes.EDIT_POD_CATEGORY_SUCCESS, response)
      dispatch(GlobalActions.showSuccessMessage(`You have successfully edited Pod Category: ${params.pod_category_name}`))
    })
    .catch(e => {
      console.error('an error occurred editing pod category', e.toString())
      dispatch({
        type: ActionTypes.EDIT_POD_CATEGORY_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Failed to edit pod category, please try again or contact support'))
      return e
    })
}

export const toggleDeleteWarning = () => ({
  type: ActionTypes.TOGGLE_DELETE_WARNING,
})

export const toggleReplaceSearchGroupWarning = () => ({
  type: ActionTypes.TOGGLE_REPLACE_SEARCH_GROUP_WARNING,
})

export const deletePodCategory = () => (dispatch, getState) => {
  const { appState, pods } = getState()
  dispatch({
    type: ActionTypes.DELETE_POD_CATEGORY_START,
  })
  const venueGroupId = appState.venueGroup.id
  const params = {
    venue_group_id: venueGroupId,
    pod_category_id: pods.selectedPodCategoryId,
  }

  return tryDeletePodCategory(params)
    .then(response => {
      dispatchConfigData(dispatch, ActionTypes.DELETE_POD_CATEGORY_SUCCESS, response)
      dispatch(GlobalActions.showSuccessMessage(`You have successfully deleted Pod Category: ${pods.selectedPodCategoryName}`))
    })
    .catch(e => {
      console.error('an error occurred deleting pod category', e.toString())
      dispatch({
        type: ActionTypes.EDIT_POD_CATEGORY_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Failed to delete pod category, please try again or contact support'))
      return e
    })
}

export const focusPodInput = pod_idx => ({
  type: ActionTypes.FOCUS_POD_INPUT,
  pod_idx,
})

export const blurPodInput = pod_idx => ({
  type: ActionTypes.BLUR_POD_INPUT,
  pod_idx,
})

export const updatePodSlideoutErrors = formErrors => ({
  type: ActionTypes.UPDATE_POD_SLIDEOUT_ERRORS,
  formErrors,
})

export const toggleIsSearchGroup = podCategoryId => ({
  type: ActionTypes.TOGGLE_IS_SEARCH_GROUP,
  podCategoryId,
})
