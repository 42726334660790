import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import history from 'mgr/pages/shared/utils/History'
import {
  resetVenueLanguageToInitial,
  tryGetVenueLanguages,
  venueLanguageChanged,
} from 'mgr/pages/single-venue/settings/actions/LanguageActions'
import {
  addCustomPolicy,
  tryGetPolicyLanguageStrings,
  trySavePolicyStrings,
  updatePolicyString,
} from 'mgr/pages/single-venue/settings/actions/LanguageStringActions'
import { selectLanguageStringSettingsLanguages } from 'mgr/pages/single-venue/settings/selectors/language'
import LanguageContentLayout from 'svr/component-lib/Manager/Layout/LanguageContent/index'
import EditableLanguageTable from 'svr/component-lib/Manager/MultiLanguage/LanguageTable/EditableLanguageTable'

class PolicyLanguageStrings extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isReload: false }
  }

  componentDidMount() {
    const { venue, languages, isLanguageListLoading, tryGetVenueLanguages, resetVenueLanguageToInitial } = this.props

    if (!isLanguageListLoading) {
      if (languages.length === 0) {
        tryGetVenueLanguages(venue.id)
      } else {
        this.setState({ isReload: true })
        resetVenueLanguageToInitial()
      }
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { venue, defaultLanguage, selectedLanguage, tryGetPolicyLanguageStrings, interfaceType, isLanguageStringsLoading } = this.props
    if (!isLanguageStringsLoading) {
      const reloadStringsForInitialLanguageReset = this.state.isReload && this.state.isReload !== prevState.isReload
      const reloadForLanguageChange = selectedLanguage && defaultLanguage && prevProps.selectedLanguage !== selectedLanguage
      if (reloadForLanguageChange || reloadStringsForInitialLanguageReset) {
        tryGetPolicyLanguageStrings(venue.id, interfaceType, defaultLanguage, selectedLanguage)
      }
    }
  }

  render() {
    const {
      venue,
      venues,
      defaultLanguage,
      selectedLanguage,
      venueLanguageChanged,
      isLanguageListLoading,
      mediaUrl,
      isLanguageStringsLoading,
      interfaceType,
      title,
      routes,
      enabledLanguages,
      onChangeVenue,
      infoText,
      addCustomPolicy,
      policies,
      updatePolicyString,
      trySavePolicyStrings,
    } = this.props
    const translatedLanguages = []
    if (!_.isEmpty(selectedLanguage) && !selectedLanguage.is_default) {
      translatedLanguages.push(selectedLanguage)
    }
    const isLoading = isLanguageListLoading || isLanguageStringsLoading
    const selectedLanguageValue = _.isEmpty(selectedLanguage) ? '' : selectedLanguage.value

    return (
      <LanguageContentLayout
        title={title}
        venue={venue}
        venues={venues}
        routes={routes}
        history={history}
        onChangeVenue={onChangeVenue}
        languages={enabledLanguages}
        isLoading={isLoading}
        selectedLanguage={selectedLanguageValue}
        onChangeLanguage={venueLanguageChanged}
        mediaUrl={mediaUrl}
        saveFunction={() => trySavePolicyStrings(interfaceType)}
        infoText={infoText}
      >
        {!_.isEmpty(policies) && (
          <EditableLanguageTable
            defaultLanguage={defaultLanguage}
            translatedLanguages={translatedLanguages}
            enabledLanguages={enabledLanguages}
            selectedLanguage={selectedLanguageValue}
            onChangeLanguage={venueLanguageChanged}
            handleCellChange={updatePolicyString}
            addNewRecordFunction={addCustomPolicy}
            data={policies}
            descriptionHeaderText="Policy Name"
            descriptionPlaceholderText="Enter policy name"
            languageColumnPlaceholderText="Enter your policy"
            addButtonText="+ Create New Policy"
          />
        )}
      </LanguageContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    venue,
    venues,
    languages: state.languages.venueLanguages,
    enabledLanguages: selectLanguageStringSettingsLanguages(state),
    defaultLanguage: state.languages.defaultLanguage,
    selectedLanguage: state.languages.selectedLanguage,
    isLanguageListLoading: state.languages.isLoading,
    mediaUrl: state.appState.mediaUrl,
    isLanguageStringsLoading: state.languageStrings.isLoading,
    policies: state.languageStrings.policies,
  }
}

const mapDispatchToProps = {
  tryGetVenueLanguages,
  venueLanguageChanged,
  updatePolicyString,
  resetVenueLanguageToInitial,
  onChangeVenue,
  addCustomPolicy,
  tryGetPolicyLanguageStrings,
  trySavePolicyStrings,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PolicyLanguageStrings))
