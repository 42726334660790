import _ from 'lodash'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { StyledVmsIconS } from 'svr/common/VmsIcons'

const OuterBody = styled.div`
  width: 100%;
  min-width: 154px;
  min-height: ${props => props.height}px;
  text-align: ${props => props.textAlign};
  ${props => props.theme.clearFix};
`

const Icon = styled(StyledVmsIconS)`
  pointer-events: none;
  display: inline-block;
  color: ${props => props.iconColor};
`

const DropdownRelative = styled.div`
  position: relative;
  top: 2px;
  right: ${props => (props.textAlign === 'right' ? '0' : 'auto')};
`

const DropdownContainer = styled.div`
  position: ${props => (props.outerDropdownPos ? props.outerDropdownPos : 'absolute')};
  right: ${props => (props.textAlign === 'right' ? props.rightAlignPx : 'auto')};
`

const DropdownAbsolute = styled.div`
  position: absolute;
  right: ${props => (props.textAlign === 'right' ? '0' : 'auto')};
  width: ${props => props.optionsWidth};
`

const DropUpAbsolute = styled(DropdownAbsolute)`
  bottom: 0;
`

const OptionBase = styled.div`
  overflow: hidden;
  font-size: 14px;
  align-items: baseline;
  padding: 10px;
  box-sizing: border-box !important;
  display: flex;
`

const OptionLightTheme = styled(OptionBase)`
  color: ${props => props.theme.navigationDark};
  background-color: ${props => (props.isSelectedChoice ? props.theme.primaryRgba20 : props.theme.background)};
  :hover,
  :focus {
    background-color: ${props => props.theme.primaryRgba10};
  }
`

const OptionDarkTheme = styled(OptionBase)`
  color: ${props =>
    props.isSelectedChoice ? props.theme.white : props.customStyle ? props.customStyle.fontsColorPrimary : props.theme.navigationDark};
  background-color: ${props =>
    props.isSelectedChoice
      ? props.customStyle
        ? props.customStyle.colorPrimary
        : '#031927'
      : props.customStyle
      ? props.customStyle.isResWidgetDark
        ? '#8a8b8b'
        : props.customStyle.colorCellBackground
      : props.theme.background};
  border-bottom: ${props => (props.isLast ? '0px' : '1px')} solid
    ${props =>
      props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
        ? props.customStyle.colorLines
        : props.theme.navigationDark};
  :hover,
  :focus {
    color: #031927;
    background-color: ${props => props.theme.lightGrey};
  }
`

const OptionDetailsContainer = styled.div`
  display: inline-block;
`

const OptionTextContainer = styled.div`
  display: flex;
  align-items: center;
`

const OptionText = styled.div`
  display: inline-block;
  flex-shrink: 50;
  padding-right: ${props => props.theme.gutter.triple};
`

const OptionIcon = styled(StyledVmsIconS)`
  pointer-events: none;
  margin: 0 10px 0 5px;
  display: inline-block;
  color: ${props => props.theme.black};
`

const OptionsContainer = styled.div`
  position: relative;
  border: 1px solid ${props => (props.customStyle ? props.customStyle.colorLines : props.borderColor)};
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  left: 0;
  width: 100%;
  max-height: 332px;
  overflow-y: auto;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
  z-index: 501;
  border-radius: 0 0 4px 4px;
`

const DropDown = styled.div`
  width: 250px;
  top: 5px;
  left: -1px;
  position: relative;
  z-index: 501;
`

const IconContainer = styled.div`
  display: inline-block;
  padding: 10px;
  background-color: ${props => (props.isOpen ? '#347baf' : props.theme.white)};
  color: ${props => (props.isOpen ? props.theme.white : props.iconColor)};
  border-radius: 25px;
  :hover {
    background-color: #347baf !important;
    color: #e8f5ff !important;
  }
`

export class IconDropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleToggleDropdownClick = this.handleToggleDropdownClick.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleKeyPressOption = this.handleKeyPressOption.bind(this)
  }

  handleItemClick(e, choice) {
    e.stopPropagation()
    if (choice.hasOwnProperty('onClickHandler')) {
      choice.onClickHandler()
    }
    this.closeDropdown()
  }

  handleKeyPress(event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleKeyPressOption(event, choice) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.handleItemClick(event, choice)
    }
  }

  closeDropdown() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleClickOutside(e) {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleToggleDropdownClick() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const {
      testId,
      name,
      dropDown,
      isLightTheme,
      isDropUp,
      style,
      optionsContainerStyle,
      height,
      wrapText,
      id,
      icon,
      ariaDescriptor,
      customStyle,
      textAlign,
      rightAlignPx,
      iconColor,
      outerDropdownPos,
      optionsWidth,
    } = this.props
    const { isOpen } = this.state
    const hoverBorderColor = isLightTheme ? Theme.darkGrey : Theme.black
    const borderColor = isOpen ? hoverBorderColor : isLightTheme ? Theme.lightGrey : '#031927'

    const OptionTheme = isLightTheme ? OptionLightTheme : OptionDarkTheme
    const dataTestId = testId || (name ? `sr-icon-dropdown-${name.toLowerCase().replace(/ /g, '_')}` : null)

    const renderSingleOption = (choice, isLast, isSelectedChoice, optionId) => {
      let optionStyle = null
      if (!wrapText) {
        choice.style = _.extend(choice.style, { height: 'auto' })
        optionStyle = { whiteSpace: 'normal' }
      }

      return (
        <OptionTheme
          data-test={`${dataTestId}-${choice.name.toLowerCase().replace(/ /g, '_')}`}
          onKeyDown={e => this.handleKeyPressOption(e, choice)}
          tabIndex="0"
          aria-label={choice.name}
          style={choice.style}
          key={choice.name}
          id={optionId}
          {...{ isLast, isSelectedChoice, customStyle }}
          onClick={e => this.handleItemClick(e, choice)}
        >
          <OptionDetailsContainer>
            <OptionTextContainer>
              <OptionIcon style={optionStyle}>{choice.icon}</OptionIcon>
              <OptionText style={optionStyle}>{choice.name}</OptionText>
            </OptionTextContainer>
          </OptionDetailsContainer>
        </OptionTheme>
      )
    }

    const renderOptions = choices => choices.map((choice, idx) => renderSingleOption(choice))

    const renderedOptions = renderOptions(this.props.choices)
    const optionsContainer = (
      <OptionsContainer
        id="optionsContainer"
        data-test="sr-icon-dropdown-options"
        key="optionsContainer"
        style={optionsContainerStyle}
        ariaExpanded={isOpen}
        role="list"
        {...{ borderColor, customStyle }}
      >
        {renderedOptions}
      </OptionsContainer>
    )

    const DropContainer = isDropUp ? DropUpAbsolute : DropdownAbsolute

    return (
      <OuterBody data-test={dataTestId} textAlign={textAlign} className={id} {...{ style, height }}>
        <IconContainer
          data-test={testId || (name ? `sr-icon-dropdown-icon-${name.toLowerCase().replace(/ /g, '_')}` : null)}
          isOpen={isOpen}
          iconColor={iconColor}
          onClick={this.handleToggleDropdownClick}
          aria-label={ariaDescriptor}
          onKeyDown={this.handleKeyPress}
          role="button"
          tabIndex="0"
        >
          <Icon>{icon}</Icon>
        </IconContainer>
        <DropdownContainer outerDropdownPos={outerDropdownPos} textAlign={textAlign} rightAlignPx={rightAlignPx}>
          <DropdownRelative>
            <DropContainer textAlign={textAlign} optionsWidth={optionsWidth}>
              {isOpen && !dropDown && optionsContainer}
              {isOpen && dropDown && <DropDown>{dropDown}</DropDown>}
            </DropContainer>
          </DropdownRelative>
        </DropdownContainer>
      </OuterBody>
    )
  }
}

IconDropdownMenu.defaultProps = {
  icon: '',
  id: '',
  name: '',
  ariaDescriptor: '',
  textAlign: 'left',
  rightAlignPx: '0px',
  outerDropdownPos: 'absolute',
}

IconDropdownMenu.propTypes = {
  icon: React.PropTypes.string,
  id: React.PropTypes.string,
  name: React.PropTypes.string,
  ariaDescriptor: React.PropTypes.string,
  textAlign: React.PropTypes.string,
  rightAlignPx: React.PropTypes.string,
  iconColor: React.PropTypes.string,
  outerDropdownPos: React.PropTypes.string,
}

IconDropdownMenu = onClickOutside(IconDropdownMenu)
export default IconDropdownMenu
