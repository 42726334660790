import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'
import Icon from 'svr/component-lib/Generic/Icon'

const Container = styled.div`
  display: flex;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  outline: 0;
  position: relative;
  min-height: 30px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? '#999' : props.theme.navigationDark)};
`

const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  :focus + div {
    border-color: rgba(95, 193, 250, 1);
  }
`

const IconWrapper = styled.div`
  margin-top: 1px;
`

const IconContainer = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 2px;
  color: #fff;
  font-weight: 700;
  border-color: ${props => {
    if (props.disabled) {
      return '#CCCCCC'
    }
    if (!props.isValid) {
      return '#f00'
    }
    if (props.value) {
      return '#347baf'
    }
    return '#888C92'
  }};
  background-color: ${props => {
    if (props.disabled) {
      return '#CCCCCC'
    }
    if (props.value) {
      return '#347baf'
    }
    return ''
  }};
`

const getUsedIcon = ({ icon, value }) => {
  if (icon) {
    return icon
  }

  return value ? Icon.ICONS.Check : Icon.ICONS.None
}

/**
 * @typedef {{
 *   disabled?: boolean;
 *   value: boolean;
 *   isValid?: boolean;
 *   icon?: any;
 *   onChange: function;
 * }} CheckboxButtonProps
 *
 * @type {React.FC<CheckboxButtonProps>}
 */
const CheckboxButton = ({ disabled, value, isValid, icon, onChange, children, ...props }) => {
  const dataProps = passDataAttrs(props)
  const usedIcon = getUsedIcon({ icon, value })
  const handlerChange = useCallback(() => {
    onChange(!value)
  }, [onChange, value])

  return (
    <Label {...dataProps} disabled={disabled}>
      <Container>
        <Input type="checkbox" value={value} disabled={disabled} onChange={handlerChange} />
        <IconContainer value={value} disabled={disabled} isValid={isValid}>
          <IconWrapper>
            <Icon size={12} icon={usedIcon} />
          </IconWrapper>
        </IconContainer>
        <div>{children}</div>
      </Container>
    </Label>
  )
}

CheckboxButton.propTypes = {
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  icon: PropTypes.oneOf([...Object.values(Icon.ICONS), PropTypes.any]),
  onChange: PropTypes.func.isRequired,
}

CheckboxButton.defaultProps = {
  disabled: false,
  icon: '',
  isValid: true,
}

export default CheckboxButton
