import type { RevenueDashboard, RevenueSource } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { COLS } from '../../constants/utils'
import { usePerformanceTableContext } from '../../hooks'
import { reportingMessages } from '../../locales'
import { ColumnSwitch } from '../ColumnSwitch'
import { RevenuePerformanceRow } from '../RevenuePerformanceRow'
import type { DateFilterEnum } from '../../constants'

interface RevenuePerformanceProps {
  data: RevenueDashboard | undefined
  filter: DateFilterEnum | undefined
}

export function RevenuePerformance({ data, filter }: RevenuePerformanceProps) {
  const { formatMessage } = useLocales()
  const { sortDescending, sortCategory } = usePerformanceTableContext()

  const sorter = (a: RevenueSource, b: RevenueSource) => {
    if (!a || !b) {
      return 0
    }
    const catA = Number(a[sortCategory as keyof RevenueSource])
    const catB = Number(b[sortCategory as keyof RevenueSource])

    return sortDescending ? catB - catA : catA - catB
  }

  const rows = data ? [...data.revenueSources].sort(sorter) : []

  return (
    <Box pl="xl" pr="xl" pt="xl" pb="xl" backgroundColor="secondaryBackground">
      <Box width="1116px">
        <HStack alignItems="end">
          <Box width={COLS[0]}>
            <Header mb="xs" type="h2">
              Performance
            </Header>
            <SecondaryText>{formatMessage(reportingMessages.clickOnAfeature)}</SecondaryText>
          </Box>
          <Box width={COLS[1]}>
            <ColumnSwitch title={formatMessage(reportingMessages.columnHeaderCovers)} category="covers" />
          </Box>
          <Box width={COLS[2]}>
            <ColumnSwitch title={formatMessage(reportingMessages.columnHeaderBenchmark)} category="benchmark" />
          </Box>
          <Box width={COLS[3]}>
            <ColumnSwitch title={formatMessage(reportingMessages.columnHeaderRevenue)} category="revenue" />
          </Box>
        </HStack>
        <Box mt="s">
          <VStack spacing="s">
            {rows.map(row => (
              <RevenuePerformanceRow key={row.type} filter={filter} source={row} />
            ))}
          </VStack>
        </Box>
      </Box>
    </Box>
  )
}
