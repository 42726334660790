import _ from 'lodash'
import { useCallback, useState } from 'react'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, ErrorMessage, Label } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import type { MenuCategory, OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface CategoryModalProps {
  category?: MenuCategory
  menu: OrderingMenu
  onCategorySave: (category: MenuCategory) => void
  onCategoryModalClose: () => void
}

const isNameUnique = (name: string, categories: MenuCategory[]) =>
  !_.some(categories, category => category.name.toLowerCase() === name.toLowerCase())

export function CategoryModal({ category, menu, onCategorySave, onCategoryModalClose }: CategoryModalProps) {
  const [name, setName] = useState(category?.name || '')
  const [nameUnique, setNameUnique] = useState(true)
  const isNameInvalid = !isNameUnique(name, menu.hierarchy.categories) || _.isEmpty(name.trim())

  const saveAndClose = useCallback(() => {
    onCategorySave({
      ...category,
      name,
      items: category ? category.items : [],
    })
    onCategoryModalClose()
  }, [onCategorySave, category, name, onCategoryModalClose])

  const validateAndUpdateName = useCallback(
    (name: string) => {
      setNameUnique(isNameUnique(name, menu.hierarchy.categories))
      setName(name)
    },
    [menu.hierarchy.categories]
  )

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onCategoryModalClose()}>
        <ModalTitle title={category ? 'Edit Category Name' : 'New Category'} />
      </ModalHeader>
      <ModalBody>
        <Label primary="Category Name">
          <Input name="categoryName" value={name} onChange={event => validateAndUpdateName(event.target.value)} placeholder="Enter Name" />
          {!nameUnique && <ErrorMessage>A category named {name} has already been created.</ErrorMessage>}
        </Label>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onCategoryModalClose} data-test="sr-menu-builder-cancel">
            Cancel
          </Button>
          <Button variant="primary" disabled={isNameInvalid} onClick={saveAndClose} data-test="sr-menu-builder-save">
            {category ? 'Save Changes' : 'Create'}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
