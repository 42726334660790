import { useLocales } from '@sevenrooms/core/locales'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { formatParagraph, type DetailProps, CREATE_UPGRADE_INSTRUCTIONS_URL } from '../../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { AddUpgradesSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'
import { FeatureEnablementProofSection } from '../../../Proofs'

export function AddUpgradesDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { insight } = data

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={formatMessage(insightsMessages.addUpgradesSlideoutDescription, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: the type ParseMessage needs to be updated
        link: (chunks: string[]) => (
          <Link to={CREATE_UPGRADE_INSTRUCTIONS_URL} target="_blank" data-test="insights-add-upgrades-detail-upgrades-instructions-link">
            {chunks}
          </Link>
        ),
        p: (chunks: string[]) => formatParagraph(chunks),
      })}
      proofLabel={formatMessage(insightsMessages.addUpgradesProofLabel)}
      suggestedActionsComponent={<AddUpgradesSuggestedActions />}
      proofComponent={
        <FeatureEnablementProofSection
          title={formatMessage(insightsMessages.addUpgradesProofTitle)}
          lastCheckedDate={insight.createdDatetime}
        />
      }
    />
  )
}
