import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useDurationOptions } from '@sevenrooms/mgr-core/hooks/useDurationOptions'
import { Autocomplete } from '@sevenrooms/react-components/components/Autocomplete'
import { TextField } from '@sevenrooms/react-components/components/TextField'
import { useAvailabilityUrlParams } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'
import { filterAccessRuleDataByDate, hasDurationOverride, type AccessRuleInputProps } from './AccessRuleHelpers'
import { StandardInput } from './StandardInput'

export function DurationInput({ accessRules }: Partial<AccessRuleInputProps>) {
  const { formatMessage } = useLocales()

  const [{ date, durationOverride }, setParams] = useAvailabilityUrlParams()
  const options = useDurationOptions()
  const iso8601 = date.toIso()

  const value = useMemo(() => getSelected(options, durationOverride), [durationOverride, options])
  const canSelectDuration = useMemo(() => {
    const availableAccessRules = filterAccessRuleDataByDate(accessRules ?? [], iso8601)
    return hasDurationOverride(availableAccessRules)
  }, [accessRules, iso8601])

  return (
    <StandardInput
      htmlFor="aqv-duration"
      label={formatMessage(availabilityDebuggerMessages.durationLabel)}
      tooltip={formatMessage(availabilityDebuggerMessages.durationTooltip)}
    >
      <Autocomplete
        fullWidth
        disabled={!canSelectDuration}
        id="aqv-duration"
        renderInput={params => <TextField {...params} />}
        options={options}
        value={value}
        onChange={(_event, selected, _reason) => setParams({ durationOverride: selected?.id })}
      />
    </StandardInput>
  )
}

function getSelected(options: ReturnType<typeof useDurationOptions>, selected?: number) {
  return options.filter(o => o.id === selected)[0] ?? null
}
