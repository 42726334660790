import styled from 'styled-components'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

interface ExcludedDateRangeEditCellProps {
  index: number
  onEdit: (index: number) => void
  onDelete: (index: number) => void
}

export function ExcludedDateRangeEditCell({ index, onEdit, onDelete }: ExcludedDateRangeEditCellProps) {
  return (
    <Container>
      <Menu data-test={`excluded-date-range-edit-menu-${index}`}>
        <MenuItem
          data-test={`excluded-date-range-edit-link-${index}`}
          onClick={() => {
            onEdit(index)
          }}
        >
          <Icon name="VMSWeb-edit" />
          Edit
        </MenuItem>
        <MenuItem
          data-test={`excluded-date-range-delete-link-${index}`}
          onClick={() => {
            onDelete(index)
          }}
        >
          <Icon name="VMSWeb-trash" />
          Delete
        </MenuItem>
      </Menu>
    </Container>
  )
}

const Container = styled.div`
  width: 26px;
`
