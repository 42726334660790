import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'
import Icon from 'svr/component-lib/Generic/Icon'

const Container = styled.div`
  display: flex;
  position: relative;
`

const SelectWrapper = styled.select`
  background: #ffffff;
  border: 1px solid #dbdcde;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 44px;
  width: 100%;
  padding: 0 10px;
  appearance: none;
`

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 14px;
  pointer-events: none;
`

const Select = ({ disabled, value, onChange, options, ...props }) => {
  const dataProps = passDataAttrs(props)

  return (
    <Container>
      <SelectWrapper
        {...dataProps}
        disabled={disabled}
        value={value}
        onChange={e => {
          onChange(e.target.value)
        }}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectWrapper>
      <IconWrapper>
        <Icon icon={Icon.ICONS.ChevronDown} />
      </IconWrapper>
    </Container>
  )
}

Select.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

Select.defaultProps = {
  disabled: false,
}

export default Select
