import _ from 'lodash'
import { Desc, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import { useLocales } from '@sevenrooms/core/locales'
import { type CheckboxChoiceValue, CheckboxGroup } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { ImportLocales } from '../Import.locales'

interface TagColumnSelectionProps {
  customFields: string[]
  handleCustomFieldsSelected: (value: CheckboxChoiceValue[]) => void
  handleTagFieldsSelected: () => void
  handleClickBack: () => void
}

export default function TagColumnSelection(props: TagColumnSelectionProps) {
  const { customFields, handleCustomFieldsSelected, handleTagFieldsSelected, handleClickBack } = props
  const { formatMessage } = useLocales()

  const customFieldOptions = _.map(customFields, field => ({ label: field, value: field }))

  return (
    <>
      <Desc>{formatMessage(ImportLocales.step3)}</Desc>
      <SubDesc>{formatMessage(ImportLocales.chooseTagColumn, { bold: (chunks: string[]) => <b>{chunks}</b> })}</SubDesc>
      <CheckboxGroup choices={customFieldOptions} onChange={handleCustomFieldsSelected} />
      <Box marginTop="20px">
        <Button variant="contained" onClick={handleClickBack} style={{ marginRight: '20px' }} data-test="custom-tag-columns-back">
          {formatMessage(ImportLocales.backStep)}
        </Button>
        <Button variant="contained" onClick={handleTagFieldsSelected} data-test="custom-tag-columns-submit">
          {formatMessage(ImportLocales.mapTags)}
        </Button>
      </Box>
    </>
  )
}
