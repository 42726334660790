import _ from 'lodash'
import { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { EditingLayoutFooter } from 'mgr/pages/single-venue/settings/components/shared/layout/EditingLayoutFooter'
import { MiddlewareSource } from 'mgr/pages/single-venue/settings/constants'
import HeaderCropper from 'mgr/pages/single-venue/settings/containers/ordering/HeaderCropper'
import HeaderImageUpload from 'mgr/pages/single-venue/settings/containers/ordering/HeaderImageUpload'
import {
  isMenuItem,
  isModifier,
  isModifierGroup,
  type ModifierGroups,
  type Modifiers,
  type ProductTags,
} from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import FormField from 'svr/component-lib/Generic/Form/FormField'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import EditingLayout from 'svr/component-lib/Manager/Layout/EditingLayout'
import SectionDropdown from 'svr/component-lib/Manager/Section'
import { Button, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, RelatedSettings, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import { ModifierGroupsField } from './ModifierGroupsField'
import { ModifiersField } from './ModifiersField'
import { ProductTagsField } from './ProductTagsField'
import { ProductTaxField } from './ProductTaxField'
import type { ModifiersGroupToModifiers, ProductInventoryItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

const SectionCustomStyles = css`
  padding-top: 12px;
`
const ImageHeader = styled.div`
  font-size: 14px;
  line-height: 18px;
`

const TagsFormField = styled(FormField)`
  height: 49px;
`

const ProductTaxFormField = styled(FormField)`
  padding-top: 4px;
  padding-bottom: ${props => props.theme.gutter.double};
`

const VerifyAgeTitle = styled.div`
  font-weight: 500;
  padding-bottom: ${props => props.theme.gutter.double};
`

const isFieldNotEmpty = (value?: string) => !_.isEmpty((value || '').trim())
const defaultProductImageAspect = 1.5
const productImageWidth = 490
const modifierGroupsTitle = 'Choose your modifier groups for this menu item. You can reorder them once you have selected them.'
const modifierInfo =
  'Example: If this modifier is “Filet Mignon” and it is already in the modifier group “First Course”, but you want to have\n' +
  'a nested modifier group “Steak Temperature” under “Filet Mignon”, you will need to add “Steak Temperature” here as a\n' +
  'nested modifier group.'
const modifierTipTitle = 'Tip '
const modifierTipDescription =
  'To add this modifier to a modifier group, you will need to first create this modifier and then go ' +
  'to the modifier group and add this modifier to that group there.'

export interface ProductFormProps {
  isSaving: boolean
  venue: Venue
  product: ProductInventoryItem
  productTags: ProductTags
  modifiers: Modifiers
  modifierGroups: ModifierGroups
  isCropImageModalOpen: boolean
  onSave: (value: ProductInventoryItem, createNext: boolean) => void
  onCancel: () => void
  updateName: (value: string) => void
  updateDescription: (value: string) => void
  updatePrice: (value: number) => void
  updateSelectedProductTags: (value: string[]) => void
  updateSelectedModifiers: (value: string[]) => void
  removeSelectedModifier: (value: string) => void
  updateSelectedGroupModifiers: (groupId: string, modifiers?: string[]) => void
  updateSelectedGroups: (groups: ModifiersGroupToModifiers[]) => void
  updateTaxRate: (value: number) => void
  updateTaxGroupId: (value: string) => void
  updateMinAmount: (value: number) => void
  updateMaxAmount: (value: number) => void
  updateMaxSameModifiersAmount: (value: number) => void
  updateAllowOrderSameModifiers: (value: boolean) => void
  updateVerifyAge: (value: boolean) => void
}

export function ProductForm({
  onSave,
  onCancel,
  isSaving,
  venue,
  updateName,
  updateDescription,
  updatePrice,
  updateSelectedProductTags,
  updateSelectedModifiers,
  removeSelectedModifier,
  updateSelectedGroupModifiers,
  updateSelectedGroups,
  updateTaxRate,
  updateTaxGroupId,
  updateMinAmount,
  updateMaxAmount,
  updateAllowOrderSameModifiers,
  updateMaxSameModifiersAmount,
  updateVerifyAge,
  product,
  productTags,
  modifiers,
  modifierGroups,
  isCropImageModalOpen,
}: ProductFormProps) {
  const [isNameValid, setNameValid] = useState(true)
  const isCreate = !product.id
  const isFormValid = isFieldNotEmpty(product.name)
  const isNoPosMiddlewareSource = venue.orderingMiddlewareSource === MiddlewareSource.NO_POS
  const [modifierGroupsDisplayed, setModifierGroupsDisplayed] = useState<boolean>(
    !isModifier(product) || !!product?.modifierGroupsToModifiers?.length
  )
  const onNameFieldChanged = useCallback(
    (value: string) => {
      setNameValid(isFieldNotEmpty(value))
      updateName(value)
    },
    [updateName]
  )

  const saveButtonDisabled = isSaving || !isFormValid

  const onSaveForm = useCallback(
    (createNext: boolean) => {
      if (saveButtonDisabled) {
        return
      }
      onSave(
        {
          ...product,
        },
        createNext
      )
    },
    [product, saveButtonDisabled, onSave]
  )

  return (
    <>
      <EditingLayout data-test="product-item-container">
        {isModifier(product) && (
          <Box pb="lm">
            <RelatedSettings primary={<>{modifierTipTitle}&#10024;</>} secondary={modifierTipDescription} />
          </Box>
        )}
        <SectionDropdown headerText="GENERAL">
          <FormField>
            <ActiveLabelTextInput
              field="name"
              isValid={isNameValid}
              placeholderLabel="Name*"
              value={product.name}
              onChange={onNameFieldChanged}
              dataTest="product-name"
            />
          </FormField>
          {!isModifierGroup(product) && (
            <>
              <FormField>
                <ActiveLabelTextInput
                  field="description"
                  isValid
                  placeholderLabel="Description"
                  value={product.description}
                  onChange={updateDescription}
                  dataTest="product-description"
                />
              </FormField>
              <FormField>
                <ActiveLabelTextInput
                  field="price"
                  isValid
                  placeholderLabel="Price"
                  value={product.price}
                  onChange={updatePrice}
                  dataTest="product-price"
                  inputType={TextInput.inputTypes.CURRENCY}
                  prefix={venue.currencySymbol}
                />
              </FormField>
            </>
          )}
          {!isNoPosMiddlewareSource && product.displayId && (
            <FormField>
              <ActiveLabelTextInput
                field="identifier"
                isValid
                disabled
                placeholderLabel="PLU / Identifier"
                value={product.displayId}
                onChange={updatePrice}
                dataTest="product-identifier"
              />
            </FormField>
          )}
          {product.typeDisplayName && (
            <FormField>
              <ActiveLabelTextInput
                field="productType"
                isValid
                disabled
                placeholderLabel="Product Type"
                value={product.typeDisplayName}
                onChange={updatePrice}
                dataTest="product-type-display-name"
              />
            </FormField>
          )}
          {!isModifierGroup(product) && (
            <ProductTaxFormField>
              <ProductTaxField
                dataTest="product-tax"
                taxRate={product.taxRate}
                taxGroupId={product.taxGroupId}
                onTaxRateChange={updateTaxRate}
                onTaxGroupIdChange={updateTaxGroupId}
              />
            </ProductTaxFormField>
          )}
          {!isModifierGroup(product) && (
            <FormField>
              <RadioList
                onClick={updateVerifyAge}
                listLabel={<VerifyAgeTitle>Requires age verification at checkout</VerifyAgeTitle>}
                selectedValue={!!product.verifyAge}
                options={[
                  { value: false, label: 'No' },
                  { value: true, label: 'Yes' },
                ]}
              />
            </FormField>
          )}
        </SectionDropdown>
        {isMenuItem(product) && (
          <>
            <SectionDropdown headerText="PRODUCT TAGS" customContentContainerStyles={SectionCustomStyles}>
              <TagsFormField>
                <ProductTagsField
                  venueId={venue.id}
                  selectedTags={product.productTags}
                  productTags={productTags}
                  onUpdateSelected={updateSelectedProductTags}
                />
              </TagsFormField>
            </SectionDropdown>
            <SectionDropdown headerText="REFERENCE IMAGE UPLOAD" customContentContainerStyles={SectionCustomStyles}>
              <FormField>
                <ImageHeader>Click to upload an image</ImageHeader>
                <HeaderImageUpload
                  imageType="product"
                  url={product.uploadedImageData?.productImgUrl}
                  cropData={product.uploadedImageData?.productImgCropData}
                  width={productImageWidth}
                  height={productImageWidth / (product.uploadedImageData?.productImgCropData?.aspect || defaultProductImageAspect)}
                />
                {isCropImageModalOpen && product.uploadedImageData && (
                  <HeaderCropper
                    imageType="product"
                    url={product.uploadedImageData.productImgUrl}
                    cropData={product.uploadedImageData.productImgCropData}
                    defaultAspect={defaultProductImageAspect}
                  />
                )}
              </FormField>
            </SectionDropdown>
          </>
        )}
        {!isModifierGroup(product) && (
          <SectionDropdown
            headerText={`${isModifier(product) ? 'NESTED ' : ''}MODIFIER GROUPS`}
            customContentContainerStyles={SectionCustomStyles}
          >
            {isModifier(product) && (
              <Checkbox
                onChange={() => {
                  setModifierGroupsDisplayed(!modifierGroupsDisplayed)
                  updateSelectedGroups([])
                }}
                info={<>{modifierInfo}</>}
                checked={modifierGroupsDisplayed}
                data-test="product-show_modifier_groups"
              >
                I want to add nested modifier groups that modify this modifier
              </Checkbox>
            )}
            {modifierGroupsDisplayed && (
              <ModifierGroupsField
                title={`${isModifierGroup(product) ? modifierGroupsTitle : ''}`}
                modifierGroups={modifierGroups}
                modifiers={modifiers}
                selectedModifierGroups={product.modifierGroupsToModifiers}
                updateSelectedGroupModifiers={updateSelectedGroupModifiers}
                updateSelectedGroups={updateSelectedGroups}
              />
            )}
          </SectionDropdown>
        )}
        {isModifierGroup(product) && (
          <>
            <SectionDropdown headerText="OTHER SETTINGS" customContentContainerStyles={SectionCustomStyles}>
              <FormField>
                <ActiveLabelTextInput
                  field="minAmount"
                  isValid
                  placeholderLabel="Minimum number of selections in this group"
                  value={product.minAmount}
                  onChange={updateMinAmount}
                  dataTest="product-min_amount"
                  inputType={TextInput.inputTypes.POSITIVE_INT}
                />
              </FormField>
              <FormField>
                <ActiveLabelTextInput
                  field="maxAmount"
                  allowEmpty
                  isValid
                  placeholderLabel="Maximum number of selections in this group"
                  value={product.maxAmount}
                  onChange={updateMaxAmount}
                  placeholder="Unlimited"
                  dataTest="product-max_amount"
                  inputType={TextInput.inputTypes.POSITIVE_INT}
                />
              </FormField>
              <Box>
                <Checkbox
                  onChange={() => {
                    updateAllowOrderSameModifiers(!product.allowOrderSameModifiers)
                  }}
                  info={
                    <VStack>
                      <Text color="lightFont">
                        Example: for modifier group “Sides” where guest can select maximum 3 sides, you want to allow guest to select 3
                        french fries, instead of making them select 3 different sides.
                      </Text>
                      <Box mt="m">
                        <Text color="lightFont">
                          This is available only when the maximum number of selections in this modifier group is 2 or above.
                        </Text>
                      </Box>
                    </VStack>
                  }
                  disabled={product.minAmount < 2 && !!product.maxAmount && product.maxAmount < 2}
                  checked={product.allowOrderSameModifiers}
                  data-test="product-allow_order_same_modifiers"
                >
                  Allow guest to order more than one of a single modifier
                </Checkbox>
                {product.allowOrderSameModifiers && (
                  <Box mt="m" ml="m">
                    <FormField>
                      <ActiveLabelTextInput
                        allowEmpty
                        field="maxAmountForEachModifier"
                        isValid
                        placeholderLabel="Maximum number of selections for each modifier"
                        value={product.maxSameModifiersAmount}
                        onChange={updateMaxSameModifiersAmount}
                        placeholder="Unlimited"
                        dataTest="product-max_amount_each_modifier"
                        inputType={TextInput.inputTypes.POSITIVE_INT}
                      />
                    </FormField>
                  </Box>
                )}
              </Box>
            </SectionDropdown>
            <SectionDropdown headerText="SELECT YOUR MODIFIERS" customContentContainerStyles={SectionCustomStyles}>
              <FormField>
                <ModifiersField
                  modifiers={modifiers}
                  selectedModifiers={product.modifierItemIds}
                  updateSelectedModifiers={updateSelectedModifiers}
                  removeSelectedModifier={removeSelectedModifier}
                />
              </FormField>
            </SectionDropdown>
          </>
        )}
      </EditingLayout>
      <EditingLayoutFooter data-test="create-products-buttons-bar">
        <Button variant="primary" disabled={saveButtonDisabled} onClick={() => onSaveForm(false)} data-test="save-button">
          Save
        </Button>
        {isCreate && (
          <Button variant="secondary" disabled={saveButtonDisabled} onClick={() => onSaveForm(true)} data-test="save-and-create-button">
            Save and Create Another
          </Button>
        )}
        <Button variant="tertiary" onClick={onCancel} data-test="cancel-button">
          Cancel
        </Button>
      </EditingLayoutFooter>
    </>
  )
}
