import Radium from 'radium'
import React, { PureComponent } from 'react'
import onClickOutside from 'react-onclickoutside'
import StyleUtils from 'mgr/layout/StyleUtils'
import { CommonStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import Button from 'svr/common/Button'
import { merge } from 'svr/common/SRUtils'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

class AddReservationButton extends PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleDropDownClick = this.handleDropDownClick.bind(this)
    this.handleAddRequestClick = this.handleAddRequestClick.bind(this)
    this.handleAddBlockClick = this.handleAddBlockClick.bind(this)
    this.state = { isOpen: this.props.isOpen }
  }

  handleClick() {
    this.props.onClick()
  }

  handleDropDownClick(e) {
    this.setState({ isOpen: !this.state.isOpen })
    e.stopPropagation()
  }

  handleAddRequestClick(e, value) {
    this.setState({ isOpen: false })
    this.props.onClickAddRequest()
  }

  handleAddBlockClick(e, value) {
    this.setState({ isOpen: false })
    this.props.onClickAddBlock()
  }

  handleClickOutside(e) {
    this.setState({ isOpen: false })
  }

  render() {
    const styles = {
      body: merge(
        {
          backgroundColor: CommonStyles.ButtonPrimaryColor,
          height: 41,
          lineHeight: '41px',
          textAlign: 'center',
          display: 'inline-block',
          color: 'white',
          fontSize: '14px',
          border: '1px solid #2C76AA',
        },
        StyleUtils.rounded('4px')
      ),
      prelabel: {
        fontSize: '16px',
        marginRight: 10,
        marginLeft: 12,
      },
      hoverStyle: {
        textDecoration: 'none',
        backgroundColor: '#347baf',
      },
      dropDownStyle: {
        borderBottom: '1px solid #2C76AA',
        display: 'inline-block',
        lineHeight: '41px',
        height: 41,
        textAlign: 'center',
        width: 41,
        marginLeft: 12,
        transform: 'rotate(90deg)',
        fontSize: '16px',
      },
      dropdownContainer: {
        position: 'relative',
      },
      dropdownMenu: merge(
        {
          backgroundColor: 'white',
          position: 'absolute',
          width: 196,
          zIndex: '-1',
          top: -2,
        },
        StyleUtils.shadow('rgba(0,0,0,0.68)')
      ),
      dropdownItem: {
        width: 'auto',
        lineHeight: '40px',
        height: 40,
        textAlign: 'right',
        paddingRight: 11,
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#eee',
        },
      },
    }
    return (
      <div>
        <Button
          testId="sr-button-add_reservation"
          onClick={this.handleClick}
          onClickDropDown={this.handleDropDownClick}
          label="Add Reservation"
          style={styles.body}
          hover_style={styles.hoverStyle}
          prelabel={VmsIcons.BookAdd}
          prelabel_style={merge(VmsIconSizes.small, styles.prelabel)}
          includeDropDown
          dropdownlabel={VmsIcons.Chevron}
          dropdown_style={merge(VmsIconSizes.small, styles.dropDownStyle)}
        />
        <div style={styles.dropdownContainer}>
          {this.state.isOpen ? (
            <div data-test="sr-dropdown-add_reservation" style={styles.dropdownMenu}>
              <div data-test="sr-button-add_request" key="addRequest" style={styles.dropdownItem} onClick={this.handleAddRequestClick}>
                Add Request
              </div>
              {this.props.permissions.canAddBlocks ? (
                <div data-test="sr-button-add_block" key="addBlock" style={styles.dropdownItem} onClick={this.handleAddBlockClick}>
                  Add Block
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

export default onClickOutside(Radium(AddReservationButton))
