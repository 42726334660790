import type { Product, ProductApi } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

export const adaptProductItemToApi = (product: Product): ProductApi => ({
  id: product.id,
  name: product.name || '',
  description: product.description,
  display_id: product.displayId,
  type: product.type,
  type_display_name: product.typeDisplayName,
  menu_names: product.menuNames,
})

export const adaptProductItemFromApi = (product: ProductApi): Product => ({
  id: product.id,
  name: product.name,
  description: product.description,
  displayId: product.display_id,
  type: product.type,
  typeDisplayName: product.type_display_name,
  menuNames: product.menu_names,
})
