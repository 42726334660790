import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import { connect } from 'react-redux'
import { cropImage, closeCrop } from 'mgr/pages/single-venue/settings/actions/services'
import * as styles from 'mgr/pages/single-venue/settings/assets/styles'
import { Button, Title } from 'mgr/pages/single-venue/settings/components/shared'

class HeaderCropper extends Component {
  constructor(props) {
    super(props)

    this.cropImage = this.props.cropImage.bind(this, this.props.imageType)
    this.closeCrop = this.props.closeCrop.bind(this, this.props.imageType)
  }

  onImageLoadCrop = percentCoords => {
    this.cropImage(percentCoords)
  }

  render() {
    const noteStyle = {
      margin: '5px 10px 10px',
      ...styles.eg,
    }

    const message =
      'For your header image, we recommend uploading a rectangular ' +
      'image that is no smaller than 1400 pixels wide and 600 pixels tall. ' +
      'Note your image must be below 4mb.'
    const defaultCrop = {
      x: 25,
      y: 25,
      width: 50,
      aspect: this.props.defaultAspect || 7 / 3,
    }
    const cropObj = this.props.cropData || defaultCrop

    return (
      <div id="crop-interface">
        <div style={styles.shadow} />
        <div style={styles.cropWrap}>
          <div id="content-header" style={styles.headerStyle}>
            <Title style={styles.headerTextStyle}>{`${this.props.imageType[0].toUpperCase() + this.props.imageType.slice(1)} Image`}</Title>
          </div>
          <div data-test="sr-cropper-close" onClick={this.closeCrop} style={styles.closer}>
            ×
          </div>
          <ReactCrop
            data-test="sr-cropper-crop"
            src={this.props.url}
            crop={cropObj}
            onComplete={this.cropImage}
            onImageLoaded={this.onImageLoadCrop}
            keepSelection
          />
          <div>
            <p style={noteStyle}>{message}</p>
          </div>
          <div style={{ clear: 'both', paddingTop: 10 }}>
            <Button data-test="sr-cropper-save-btn" onClick={this.closeCrop} style={{ width: 320, margin: '5px 10px' }}>
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  cropImage: (imageType, percentCoords) => {
    const { height, width } = percentCoords
    // Shield against zero dimension crops
    if (height === 0 || width === 0) {
      return
    }
    dispatch(cropImage(imageType, percentCoords))
  },
  closeCrop: imageType => {
    dispatch(closeCrop(imageType))
  },
})

export default connect(null, mapDispatchToProps)(HeaderCropper)
