import React from 'react'
import { css } from 'styled-components'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'

export const customBorderlessTableContainerStyles = css`
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} 0;
  .ReactTable {
    border-radius: 5px;
    border-width: 0px;

    .rt-table {
      overflow: visible;
    }

    .rt-tr {
      font-size: ${props => props.theme.fontSize.body};
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      &.-even {
        background-color: ${props => props.theme.color.white};
      }
    }

    .rt-thead {
      .rt-th {
        padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.triple};
        font-size: ${props => props.theme.fontSize.body};
        font-weight: 500;
        line-height: 17px;
        text-transform: none;
        :focus {
          outline: none;
        }
      }

      &.-header {
        background-color: ${props => props.theme.color.white};
        box-shadow: None;
        .rt-th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
    }

    .rt-tbody {
      .rt-tr:not(.-padRow) {
        :hover {
          cursor: default;
          background: ${props => props.theme.color.white};
          .highlighted {
            background: ${props => props.theme.color.white};
          }
        }
      }

      .rt-td {
        padding-left: ${props => props.theme.gutter.triple};
        padding-top: ${props => props.theme.gutter.half};
        padding-bottom: ${props => props.theme.gutter.half};
      }
    }
  }
`

class BorderlessTable extends React.PureComponent {
  render() {
    const { data, columns, isLoading } = this.props
    return (
      <Table
        data={data}
        columns={columns}
        loading={isLoading}
        pageSize={data.length}
        showPagination={false}
        customTableContainerStyles={customBorderlessTableContainerStyles}
        sortable={false}
        resizable={false}
      />
    )
  }
}

export default BorderlessTable
