import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useState, useMemo, useEffect } from 'react'
import { adjustForStartOfDay, formatTimeOnly } from 'mgr/lib/utils/MomentUtils'
import { getGroupedHoursByWeekDay } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/AvailableHours/getGroupedHoursByWeekDay'
import { PacingDayTimeLimitMatrix } from 'mgr/pages/single-venue/settings/components/ordering/OrderPacing/PacingDayTimeLimitMatrix'
import { PacingTimeRange } from 'mgr/pages/single-venue/settings/components/ordering/OrderPacing/PacingTimeRange'
import { EditingLayoutFooter } from 'mgr/pages/single-venue/settings/components/shared/layout/EditingLayoutFooter'
import { WEEK_INDEX_TO_TITLE_MIN } from 'svr/constants'
import { mergeMenuAvailableHours } from 'svr/lib/Ordering/mergeMenuAvailableHours'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, CheckboxGroupSection, DateRangePicker, Label, type CheckboxChoice } from '@sevenrooms/core/ui-kit/form'
import { useDebounceStateEffect } from '@sevenrooms/core/ui-kit/hooks'
import { BaseSection, Box } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import type { TimeRange, OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { ORDER_METHOD_TYPE } from 'mgr/pages/single-venue/settings/types/ordering/Order.types'
import type { FULFILLMENT_METHOD_TYPE } from 'mgr/pages/single-venue/settings/types/ordering/OrderingSite.types'
import type { PacingRule, OrderingSiteOption, DayTimeLimitMatrix } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'

export interface PacingRuleFormProps {
  onSave: (value: PacingRule | Omit<PacingRule, 'id'>) => void
  onCancel: () => void
  isSaving: boolean
  venue: Venue
  pacingRule: PacingRule
  orderingSites: OrderingSiteOption[]
  menus: OrderingMenu[]
  defaultMenuHours: TimeRange
  updateName: (value: string) => void
  updateDateRangeFrom: (value: Date) => void
  updateDateRangeTo: (value: Date | null) => void
  updateOrderMethods: (value: ORDER_METHOD_TYPE[]) => void
  updateOrderingSiteIds: (value: string[]) => void
  updateTimeRangeFrom: (value: moment.Moment) => void
  updateTimeRangeTo: (value: moment.Moment) => void
  updateTimeIntervalMins: (value: number) => void
  updateDayTimeLimitMatrix: (value: DayTimeLimitMatrix) => void
}

export function PacingRuleForm({
  onSave,
  onCancel,
  isSaving,
  venue,
  pacingRule,
  orderingSites,
  menus,
  defaultMenuHours,
  updateName,
  updateDateRangeFrom,
  updateDateRangeTo,
  updateOrderMethods,
  updateOrderingSiteIds,
  updateTimeRangeFrom,
  updateTimeRangeTo,
  updateTimeIntervalMins,
  updateDayTimeLimitMatrix,
}: PacingRuleFormProps) {
  const isNameValid = isFieldNotEmpty(pacingRule.name)
  const hasOrderMethods = !!pacingRule.orderMethods?.length

  const timeRangeFrom = useMemo(
    () => pacingRule.timeRangeFrom && adustedMomentTime(pacingRule.timeRangeFrom, venue.startOfDayHour),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pacingRule.timeRangeFrom]
  )
  const timeRangeTo = useMemo(
    () => pacingRule.timeRangeTo && adustedMomentTime(pacingRule.timeRangeTo, venue.startOfDayHour, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pacingRule.timeRangeTo]
  )
  const orderingSitesFiltered = useMemo(
    () => orderingSites.filter(orderingSite => checkSiteMatchesOrderMethod(orderingSite.fulfillmentMethod, pacingRule.orderMethods)),
    [orderingSites, pacingRule.orderMethods]
  )
  const menusById = useMemo(() => _.keyBy(menus, 'id'), [menus])
  const orderingSiteChoices = useMemo(() => {
    const forMethodsText = hasOrderMethods ? ` for ${pacingRule.orderMethods.join(' or ').split('_').join(' ').toLowerCase()}` : ''
    return [
      {
        label: `All ordering sites${forMethodsText} (${orderingSitesFiltered.length})`,
        value: ALL_ORDERING_SITES_ID,
        choices: orderingSitesFiltered.map(orderingSite => {
          const groupedHoursDisplay = getSiteGroupedHoursDisplay(orderingSite, venue, menusById)
          return {
            label: `${orderingSite.name}${groupedHoursDisplay}`,
            value: orderingSite.id,
          }
        }),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderingSitesFiltered, pacingRule.orderMethods, menusById])
  const allSelectableOrderingSiteIds = useMemo(() => orderingSitesFiltered.map(orderingSite => orderingSite.id), [orderingSitesFiltered])
  const timeRangeMinMax = useMemo(
    () => ({
      start: adustedMomentTime(moment({ hour: venue.startOfDayHour }), venue.startOfDayHour),
      end: adustedMomentTime(moment({ hour: venue.startOfDayHour }), venue.startOfDayHour, true),
    }),
    [venue.startOfDayHour]
  )
  useEffect(() => {
    if (!pacingRule.dateRangeFrom) {
      updateDateRangeFrom(new Date())
    }
    if (!timeRangeFrom && defaultMenuHours.start) {
      updateTimeRangeFrom(timeToMoment(defaultMenuHours.start, venue.startOfDayHour))
    }
    if (!timeRangeTo && defaultMenuHours.end) {
      updateTimeRangeTo(timeToMoment(defaultMenuHours.end, venue.startOfDayHour))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pacingRule, timeRangeMinMax])

  // Empty pacingRule.orderingSiteIds means all are selected
  // Needed so group checkbox can default to "All selected", but user can still deselect all of them
  const [hasMadeOrderingSiteSelection, setMadeOrderingSiteSelection] = useState(false)
  const [selectedOrderingSiteIds, setSelectedOrderingSiteIds] = useState(
    pacingRule.orderingSiteIds?.length ? pacingRule.orderingSiteIds : allSelectableOrderingSiteIds
  )

  const onOrderingSiteIdsFieldChanged = useCallback(
    (choice: CheckboxChoice<string>, checked: boolean) => {
      let updatedSiteIds: string[]
      if (choice.value === ALL_ORDERING_SITES_ID) {
        if (checked) {
          updatedSiteIds = allSelectableOrderingSiteIds
        } else {
          updatedSiteIds = []
        }
      } else if (!hasMadeOrderingSiteSelection && !pacingRule.orderingSiteIds?.length) {
        // Unchecking one when default view shows "All" selected
        updatedSiteIds = allSelectableOrderingSiteIds.filter(v => v !== choice.value)
      } else {
        updatedSiteIds = checked
          ? [...pacingRule.orderingSiteIds, choice.value as string]
          : pacingRule.orderingSiteIds.filter(v => v !== choice.value)
      }
      updateOrderingSiteIds(updatedSiteIds.length === allSelectableOrderingSiteIds.length ? [] : updatedSiteIds)
      setSelectedOrderingSiteIds(updatedSiteIds)
      setMadeOrderingSiteSelection(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderingSitesFiltered, pacingRule.orderingSiteIds]
  )

  const hasOrderingSiteIds = !!selectedOrderingSiteIds.length
  const isFormValid = isNameValid && !!pacingRule.dateRangeFrom && hasOrderMethods && hasOrderingSiteIds
  const saveButtonDisabled = isSaving || !isFormValid

  const onSaveForm = useCallback(() => {
    if (saveButtonDisabled) {
      return
    }
    onSave({
      ...pacingRule,
    })
  }, [pacingRule, saveButtonDisabled, onSave])

  return (
    <>
      <Box data-test="pacing-rule-container" mb="xxl" p="lm">
        <BaseSection title="General">
          <Box pt="m" pl="lm">
            <Label primary="Name*">
              <NameInput name={pacingRule.name} isNameValid={isNameValid} updateName={updateName} />
            </Label>
          </Box>
          <Box pt="m" pl="lm">
            <Label primary="Date Range*">
              <DateRangePicker
                startDate={pacingRule.dateRangeFrom?.toDate()}
                endDate={pacingRule.dateRangeTo?.toDate() || 'infinite'}
                id="pacing-rule-date-range"
                infinite
                onStartDateChange={(startDate: Date | null) => startDate && updateDateRangeFrom(startDate)}
                onEndDateChange={(endDate: Date | 'infinite' | null) => updateDateRangeTo(endDate === 'infinite' ? null : endDate)}
              />
            </Label>
          </Box>
          <Box pt="m" pl="lm">
            <Label primary="What fulfillment method(s) should this rule apply to?*">
              <CheckboxGroupSection
                name="order_methods"
                choices={[
                  { label: 'Delivery', value: 'DELIVERY' },
                  { label: 'Pickup', value: 'PICKUP' },
                  { label: 'Room Service', value: 'ROOM_SERVICE' },
                ]}
                selected={pacingRule.orderMethods}
                onChange={(choice, checked) => {
                  const orderMethods = checked
                    ? [...pacingRule.orderMethods, choice.value]
                    : pacingRule.orderMethods.filter(v => v !== choice.value)
                  updateOrderMethods(orderMethods)
                }}
              />
            </Label>
          </Box>
          <Box pt="m" pl="lm">
            <Label primary="What ordering sites should this rule apply to?*">
              <CheckboxGroupSection<string>
                name="ordering_sites"
                choices={orderingSiteChoices}
                selected={selectedOrderingSiteIds}
                onChange={onOrderingSiteIdsFieldChanged}
              />
            </Label>
          </Box>
        </BaseSection>
        <BaseSection title="Max number of orders" description={MAX_ORDERS_DESCRIPTION}>
          <Box pt="m" pl="lm">
            <PacingTimeRange
              timeRangeFrom={timeRangeFrom || timeRangeMinMax.start}
              timeRangeTo={timeRangeTo || timeRangeMinMax.end}
              timeIntervalMins={pacingRule.timeIntervalMins}
              menuStart={timeRangeMinMax.start}
              menuEnd={timeRangeMinMax.end}
              updateTimeRangeFrom={updateTimeRangeFrom}
              updateTimeRangeTo={updateTimeRangeTo}
              updateTimeIntervalMins={updateTimeIntervalMins}
            />
          </Box>
          <Box pt="m" pl="lm">
            <PacingDayTimeLimitMatrix
              dayTimeLimitMatrix={pacingRule.dayTimeLimitMatrix}
              dateRangeFrom={pacingRule.dateRangeFrom}
              dateRangeTo={pacingRule.dateRangeTo}
              timeRangeFrom={timeRangeFrom || timeRangeMinMax.start}
              timeRangeTo={timeRangeTo || timeRangeMinMax.end}
              timeIntervalMins={pacingRule.timeIntervalMins}
              updateDayTimeLimitMatrix={updateDayTimeLimitMatrix}
            />
          </Box>
        </BaseSection>
      </Box>
      <EditingLayoutFooter data-test="edit-pacing-rule-buttons-bar">
        <Button variant="primary" disabled={saveButtonDisabled} onClick={onSaveForm} data-test="save-button">
          Save
        </Button>
        <Button variant="tertiary" onClick={onCancel} data-test="cancel-button">
          Cancel
        </Button>
      </EditingLayoutFooter>
    </>
  )
}

const isFieldNotEmpty = (value?: string) => !_.isEmpty((value || '').trim())

const MAX_ORDERS_DESCRIPTION = (
  <>
    Add an order limit in any cell where you want to cap the total number of orders for that time interval. If no limit is added for a given
    interval, no cap will be enforced.
    <br />
    <br />
    Note that Menu Hours still control when menus can be ordered, and these order limits will be enforced assuming the menu(s) are orderable
    at those times. Start time and End time below are just used to narrow down the table below to the time frame where you want to set order
    limits.
  </>
)

const ALL_ORDERING_SITES_ID = 'ALL_ORDERING_SITES_ID'

const checkSiteMatchesOrderMethod = (siteFulfillmentMethod: FULFILLMENT_METHOD_TYPE, selectedOrderMethods?: ORDER_METHOD_TYPE[]) => {
  if (!selectedOrderMethods) {
    return false
  }
  if (siteFulfillmentMethod === 'PICKUP_OR_DELIVERY') {
    return selectedOrderMethods.includes('PICKUP') || selectedOrderMethods.includes('DELIVERY')
  }
  return selectedOrderMethods.includes(siteFulfillmentMethod)
}

const timeToMoment = (time: string, startOfDayHour: number, isEndTime = false) =>
  adustedMomentTime(moment(time, 'hh:mm'), startOfDayHour, isEndTime)

const adustedMomentTime = (time: moment.Moment, startOfDayHour: number, isEndTime = false) =>
  adjustForStartOfDay(formatTimeOnly(time), startOfDayHour, isEndTime)

const getSiteGroupedHoursDisplay = (orderingSite: OrderingSiteOption, venue: Venue, menusById: Record<string, OrderingMenu>) => {
  const siteMenus = orderingSite.menuIds.length
    ? (orderingSite.menuIds.map(menuId => menusById[menuId]).filter(menu => !!menu) as OrderingMenu[])
    : Object.values(menusById)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const menuAvailableHours = mergeMenuAvailableHours(siteMenus, venue.locale, venue.startOfDayHour)[0]!.availableHours
  const groupedHoursByWeekDay = getGroupedHoursByWeekDay(menuAvailableHours.map(h => h.hours))
  const groupedHoursDisplay = groupedHoursByWeekDay
    .filter(({ hoursListTitle }) => !!hoursListTitle)
    .map(({ endDayOfWeekIndex, startDayOfWeekIndex, hoursListTitle }) => {
      const daysOfWeek =
        endDayOfWeekIndex !== null
          ? `${WEEK_INDEX_TO_TITLE_MIN[startDayOfWeekIndex]} - ${WEEK_INDEX_TO_TITLE_MIN[endDayOfWeekIndex]}`
          : WEEK_INDEX_TO_TITLE_MIN[startDayOfWeekIndex]
      return `${daysOfWeek} ${hoursListTitle}`
    })
    .join(', ')
  return groupedHoursDisplay ? ` (${groupedHoursDisplay})` : ''
}

function NameInput({ name, isNameValid, updateName }: { name: string; isNameValid: boolean; updateName: (value: string) => void }) {
  const [isFirstLoad, setIsInitialLoad] = useState(true)
  const [debouncedName, setDebouncedName] = useDebounceStateEffect(
    name,
    value => {
      setIsInitialLoad(false)
      updateName(value)
    },
    200
  )
  return (
    <Input
      value={debouncedName}
      onChange={event => setDebouncedName(event.target.value)}
      placeholder="Enter name"
      required
      invalid={!isFirstLoad && !isNameValid}
    />
  )
}
