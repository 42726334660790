import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import * as DeliveryIntegrationActions from 'mgr/pages/single-venue/settings/actions/ordering/DeliveryIntegrationActions'
import * as DoorDashFields from 'mgr/pages/single-venue/settings/components/ordering/DoorDashFields'
import {
  BookingChannelContainer,
  LargeChannelImg,
  FormContainer,
  FieldRow,
  FieldCol,
  HorizontalLine,
  Header,
  HeaderTitle,
  HeaderButtons,
  DeactivateButton,
  EditButton,
  SaveButton,
  CancelButton,
  ErrorDialogWrapper,
  ErrorIcon,
  ErrorBody,
  ErrorDismiss,
} from 'mgr/pages/single-venue/settings/components/shared'
import VmsIcons from 'svr/common/VmsIcons'

class DoorDashDeliveryIntegrationSettings extends PureComponent {
  constructor(props) {
    super(props)
    this.validateFieldMap = {}
    this.onSaveClickHandler = this.onSaveClickHandler.bind(this)
  }

  onSaveClickHandler() {
    const { actions, isLoading } = this.props
    if (isLoading) {
      return
    }
    const formErrors = _.reduce(
      this.validateFieldMap,
      (invalidFields, input, field) => {
        const invalidText = !_.isNil(input) && ((input.props && input.props.disabled) || input.isValid())
        return _.assign(invalidFields, invalidText !== true && invalidText !== false && { [field]: invalidText })
      },
      {}
    )

    actions.formValidated(formErrors)

    if (_.isEmpty(formErrors)) {
      actions.saveDoorDashIntegrationSettings()
    }
  }

  render() {
    const { doordashIntegrationSettings, isDoorDashActiveIntegration, actions } = this.props

    if (_.isNil(doordashIntegrationSettings)) {
      return null
    }
    const { selectEditDoorDashIntegration, deactivateDoorDashIntegration } = actions

    return (
      <BookingChannelContainer>
        <Header>
          <LargeChannelImg path="integrations/doordash-logo.jpeg" />
          <HeaderTitle data-test="sr-doordash-header">DoorDash Drive</HeaderTitle>
          <HeaderButtons>
            {!isDoorDashActiveIntegration && [
              <EditButton onClick={() => selectEditDoorDashIntegration()} key="EditButton" data-test="sr-doordash-edit-or-configure-button">
                {doordashIntegrationSettings.enabled ? 'Edit' : 'Configure'}
              </EditButton>,
              doordashIntegrationSettings.enabled && (
                <DeactivateButton
                  onClick={() => deactivateDoorDashIntegration()}
                  key="DeactivateButton"
                  data-test="sr-doordash-deactivate-button"
                >
                  Deactivate
                </DeactivateButton>
              ),
            ]}
          </HeaderButtons>
        </Header>
        {isDoorDashActiveIntegration && this.renderEditDoorDashForm()}
      </BookingChannelContainer>
    )
  }

  renderEditDoorDashForm() {
    const { doordashIntegrationSettings, actions, formErrors, isLoading } = this.props
    const { changeDoorDashField, cancelDeliveryIntegrationEdit } = actions
    const { validateFieldMap } = this
    const fieldArgs = {
      doordashIntegrationSettings,
      changeDoorDashField,
      formErrors,
      validateFieldMap,
    }
    return (
      <>
        <HorizontalLine key="HorizontalLine" />
        <FormContainer key="FormContainer">
          <FieldRow>
            <FieldCol xs={12} md={6} data-test="sr-doordash-configure-business-name-input">
              {DoorDashFields.businessNameField(fieldArgs)}
            </FieldCol>
          </FieldRow>
          <FieldRow>
            <FieldCol xs={12} md={12} data-test="sr-doordash-configure-pickup-instr-input">
              {DoorDashFields.pickupInstructionsField(fieldArgs)}
            </FieldCol>
          </FieldRow>
          {this.renderErrorDialog()}
          <FieldRow>
            <FieldCol xs={12} md={12}>
              <SaveButton
                onClick={this.onSaveClickHandler}
                disabled={isLoading}
                key="SaveButton"
                data-test="sr-doordash-configure-save-button"
              >
                {isLoading ? <PulsatingDots /> : 'Enable Integration'}
              </SaveButton>
              <CancelButton
                onClick={() => cancelDeliveryIntegrationEdit()}
                disabled={isLoading}
                key="CancelButton"
                data-test="sr-doordash-configure-cancel-button"
              >
                Cancel
              </CancelButton>
            </FieldCol>
          </FieldRow>
        </FormContainer>
      </>
    )
  }

  renderErrorDialog() {
    const { actions, formErrors, didDismissErrorDisplay } = this.props
    const formErrorMessages = _.compact(_.values(formErrors)).sort()
    const isVisible = !didDismissErrorDisplay && !_.isEmpty(formErrorMessages)
    return (
      isVisible && (
        <ErrorDialogWrapper>
          <ErrorIcon>{VmsIcons.WarningLine}</ErrorIcon>
          <ErrorBody>
            {formErrorMessages.map(e => (
              <li key={e}>
                {e}
                <br />
              </li>
            ))}
          </ErrorBody>
          <ErrorDismiss onClick={actions.dismissErrorDisplay}>{VmsIcons.Close}</ErrorDismiss>
        </ErrorDialogWrapper>
      )
    )
  }
}

const mapStateToProps = state => ({
  isDoorDashActiveIntegration: state.deliveryIntegrations.activeIntegrationKey === DeliveryIntegrationActions.INTEGRATION_KEY_DOORDASH,
  doordashIntegrationSettings: state.deliveryIntegrations.integrationSettings[DeliveryIntegrationActions.INTEGRATION_KEY_DOORDASH],
  formErrors: state.deliveryIntegrations.formErrors,
  isLoading: state.deliveryIntegrations.isLoading,
  didDismissErrorDisplay: state.deliveryIntegrations.didDismissErrorDisplay,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      selectEditDoorDashIntegration: _.partial(
        DeliveryIntegrationActions.selectDeliveryIntegrationEdit,
        DeliveryIntegrationActions.INTEGRATION_KEY_DOORDASH
      ),
      deactivateDoorDashIntegration: _.partial(
        DeliveryIntegrationActions.tryDeactivateDeliveryIntegration,
        DeliveryIntegrationActions.INTEGRATION_KEY_DOORDASH
      ),
      cancelDeliveryIntegrationEdit: DeliveryIntegrationActions.cancelDeliveryIntegrationEdit,
      changeDoorDashField: _.partial(
        DeliveryIntegrationActions.changeDeliveryIntegrationField,
        DeliveryIntegrationActions.INTEGRATION_KEY_DOORDASH
      ),
      formValidated: DeliveryIntegrationActions.deliveryIntegrationFormValidated,
      dismissErrorDisplay: DeliveryIntegrationActions.deliveryIntegrationDismissErrorDisplay,
      saveDoorDashIntegrationSettings: _.partial(
        DeliveryIntegrationActions.trySaveDeliveryIntegrationSettings,
        DeliveryIntegrationActions.INTEGRATION_KEY_DOORDASH
      ),
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(DoorDashDeliveryIntegrationSettings)
