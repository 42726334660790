import { push } from 'connected-react-router'
import * as ActionTypes from 'mgr/pages/single-venue/reporting/actions/ActionTypes'
import { tryLoadOutgoingEmails } from 'mgr/pages/single-venue/reporting/actions/OutgoingEmails'
import queryString from 'query-string'

const filterChange = update => (dispatch, getState) => {
  const state = getState()
  const { filters } = state.outgoingEmails

  dispatch(
    push({
      search: queryString.stringify({
        ...filters,
        startDate: filters.startDate.format('YYYY-MM-DD'),
        endDate: filters.endDate.format('YYYY-MM-DD'),
      }),
    })
  )
}

export const onDateFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState

  dispatch({
    type: ActionTypes.OUTGOING_EMAILS_DATE_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadOutgoingEmails(venue))
}

export const onOutgoingEmailsFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState

  dispatch({
    type: ActionTypes.OUTGOING_EMAILS_EMAIL_TYPE_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadOutgoingEmails(venue))
}

export const onSearchFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.OUTGOING_EMAILS_SEARCH_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadOutgoingEmails(venue))
}

const clearPagination = () => (dispatch, getState) => {
  const { pageSize } = getState().outgoingEmails.filters
  dispatch({
    type: ActionTypes.OUTGOING_EMAILS_PAGINATION_CHANGE,
    page: 0,
    pageSize,
  })
}

export const onOutgoingEmailsPageChange = (page, pageSize) => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.OUTGOING_EMAILS_PAGINATION_CHANGE,
    page,
    pageSize,
  })

  dispatch(filterChange())
  dispatch(tryLoadOutgoingEmails(venue))
}

export const onCustomDateRangeChange = (dateType, dateVal) => dispatch => {
  dispatch({
    type: ActionTypes.OUTGOING_EMAILS_CUSTOM_RANGE_CHANGE,
    dateType,
    dateVal,
  })

  dispatch(filterChange())
}
