import type { Insight } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { HStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text, Header } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../locales'
import { StatusBadge } from '../Status'

interface DetailHeaderProps {
  insight: Insight
  title: string
  description: string
  headerText?: string
}

export function DetailHeader({ insight, title, description, headerText }: DetailHeaderProps) {
  const { formatMessage } = useLocales()

  return (
    <>
      <HStack pt="l" spacing="m">
        <Box width="60%">
          <SecondaryText type="p">{title}</SecondaryText>
          <Header type="h1">{headerText ?? insight.insightText}</Header>
        </Box>
        <Box>
          <SecondaryText type="p">{formatMessage(insightsMessages.statusTitle)}</SecondaryText>
          <StatusBadge variant={insight.status} />
        </Box>
      </HStack>
      <Box mt="lm" mr="lm" mb="lm">
        <Text>{description}</Text>
      </Box>
    </>
  )
}
