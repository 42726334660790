import { useMemo } from 'react'
import type { UpgradeCoverageShiftTableRow, UpgradeCoverageAccessRuleTableRow, InsightProofData } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Spreadsheet, VStack, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'

export interface UpgradeCoverageProofSectionProps {
  insightProofData: InsightProofData[]
}

export function UpgradeCoverageProofSection({ insightProofData }: UpgradeCoverageProofSectionProps) {
  const { formatMessage } = useLocales()
  const columnWidth = 75
  const shiftProofData = (insightProofData?.[0]?.tableRows as UpgradeCoverageShiftTableRow[]) ?? []
  const accessRuleProofData = (insightProofData?.[1]?.tableRows as UpgradeCoverageAccessRuleTableRow[]) ?? []
  const displayShiftProofData = !!shiftProofData.length
  const displayAccessRuleProofData = !!accessRuleProofData.length

  const shiftColumns = useMemo<DataTableColumn<UpgradeCoverageShiftTableRow>[]>(() => {
    if (!displayShiftProofData) {
      return []
    }
    return [
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.upgradeCoverageTableHeaderShiftName)}
          </Text>
        ),
        render: 'shiftName',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.upgradeCoverageTableHeaderNumAccessRules)}
          </Text>
        ),
        render: 'numAccessRules',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.upgradeCoverageTableHeaderAccessRules)}
          </Text>
        ),
        render: 'accessRules',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.upgradeCoverageTableHeaderSummary)}
          </Text>
        ),
        render: 'summary',
        width: columnWidth,
        maxWidth: columnWidth,
      },
    ]
  }, [formatMessage, displayShiftProofData])

  const accessRuleColumns = useMemo<DataTableColumn<UpgradeCoverageAccessRuleTableRow>[]>(() => {
    if (!displayAccessRuleProofData) {
      return []
    }
    return [
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.upgradeCoverageTableHeaderAccessRuleName)}
          </Text>
        ),
        render: 'accessRuleName',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow={3} textStyle="body1Bold">
            {formatMessage(insightsMessages.upgradeCoverageTableHeaderFollowingShift)}
          </Text>
        ),
        key: 'id',
        render: () => <Text>{formatMessage(commonMessages.no)}</Text>,
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.upgradeCoverageTableHeaderSummary)}
          </Text>
        ),
        render: 'summary',
        width: columnWidth,
        maxWidth: columnWidth,
      },
    ]
  }, [formatMessage, displayAccessRuleProofData])

  return (
    <VStack spacing="s">
      {displayShiftProofData && (
        <VStack pb={displayAccessRuleProofData ? 'lm' : 'auto'} data-test="upgrade-coverage-shift-proof">
          <Text type="p">{formatMessage(insightsMessages.upgradeCoverageShiftProofLabel)}</Text>
          <Spreadsheet data={shiftProofData} columns={shiftColumns} noPagination />
        </VStack>
      )}

      {displayAccessRuleProofData && (
        <VStack data-test="upgrade-coverage-access-rule-proof">
          <Text type="p">{formatMessage(insightsMessages.upgradeCoverageAccessRuleProofLabel)}</Text>
          <Spreadsheet data={accessRuleProofData} columns={accessRuleColumns} noPagination />
        </VStack>
      )}
    </VStack>
  )
}
