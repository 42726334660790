import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import { loadCheckoutSettings } from 'mgr/pages/single-venue/settings/actions/ordering/CheckoutActions'
import {
  tryGetOrderingSites,
  tryDeleteOrderingSite,
  trySetThirdPartyDefault,
} from 'mgr/pages/single-venue/settings/actions/ordering/OrderingSitesActions'
import OrderingSitesTable from 'mgr/pages/single-venue/settings/components/ordering/OrderingSites/OrderingSitesTable'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { SaveButton } from 'svr/component-lib/Manager/Layout/LanguageContent'
import MessageBox from 'svr/component-lib/Manager/MessageBox'

const OrderingSitesContainer = styled.div`
  padding: ${props => props.theme.gutter.double};
`

const OrderingSites = ({
  venue,
  venues,
  isLoading,
  orderingSites,
  onChangeVenue,
  loadCheckoutSettings,
  tryGetOrderingSites,
  tryDeleteOrderingSite,
  trySetThirdPartyDefault,
  thirdPartyDefaultOrderingSiteId,
  history,
}) => {
  useEffect(() => {
    if (!isLoading) {
      loadCheckoutSettings()
      tryGetOrderingSites(venue.id)
    }
  }, [])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [siteToDelete, setSiteToDelete] = useState()

  const clickDeleteSite = siteId => {
    setShowDeleteModal(true)
    setSiteToDelete(siteId)
  }

  const deleteSite = () => {
    setShowDeleteModal(false)
    tryDeleteOrderingSite(venue.id, siteToDelete)
  }

  const clickSetThirdPartyDefault = siteId => {
    trySetThirdPartyDefault(venue.id, siteId)
  }

  return (
    <ContentLayout
      title="Ordering Site Management"
      venue={venue}
      venues={venues}
      onChangeVenue={onChangeVenue}
      Actions={
        <SaveButton
          onClick={() => {
            history.push(`/manager2/${venue.urlKey}/settings/ordering/site`)
          }}
          id="add-site-button"
          data-test="add-site-button"
        >
          Add New Ordering Site
        </SaveButton>
      }
    >
      <OrderingSitesContainer id="ordering-sites-container">
        {showDeleteModal && (
          <MessageBox
            handleActionClick={deleteSite}
            handleCloseClick={setShowDeleteModal.bind(this, false)}
            dialogType={MessageBox.DialogType.INFO}
            header="Delete Site?"
            actionButtonText="Delete"
            explanation="Are you sure you want to delete this site?"
          />
        )}
        <OrderingSitesTable
          orderingSites={orderingSites}
          venueUrlKey={venue.urlKey}
          isLoading={isLoading}
          onDeleteClick={clickDeleteSite}
          onSetThirdPartyDefault={clickSetThirdPartyDefault}
          thirdPartyDefaultOrderingSiteId={thirdPartyDefaultOrderingSiteId}
        />
      </OrderingSitesContainer>
    </ContentLayout>
  )
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    isLoading: state.orderingSites.isLoading,
    orderingSites: state.orderingSites.orderingSites,
    thirdPartyDefaultOrderingSiteId: state.orderingCheckout.checkoutSettings.third_party_default_ordering_site_id,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
  loadCheckoutSettings,
  tryGetOrderingSites,
  tryDeleteOrderingSite,
  trySetThirdPartyDefault,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderingSites)
