import _ from 'lodash'
import { showErrorMessage, showSuccessMessage } from 'mgr/lib/actions/GlobalActions'
import { getLanguageStrings, saveVenueLanguageStrings, getPolicyStrings, savePolicyStrings } from 'mgr/lib/services/LanguageServices'
import {
  GET_LANGUAGE_STRINGS_START,
  GET_LANGUAGE_STRINGS_SUCCESS,
  GET_LANGUAGE_STRINGS_FAIL,
  UPDATE_LANGUAGE_STRING,
  SAVE_LANGUAGE_STRINGS_START,
  SAVE_LANGUAGE_STRINGS_SUCCESS,
  SAVE_LANGUAGE_STRINGS_FAIL,
  ADD_CUSTOM_POLICY,
  GET_POLICY_STRINGS_START,
  GET_POLICY_STRINGS_SUCCESS,
  GET_POLICY_STRINGS_FAIL,
  UPDATE_POLICY_STRING,
  SAVE_POLICY_STRINGS_START,
  SAVE_POLICY_STRINGS_SUCCESS,
  SAVE_POLICY_STRINGS_FAIL,
} from 'mgr/pages/single-venue/settings/actions/ActionTypes'

export const tryGetLanguageStrings = (venueId, interfaceName, defaultLanguage, selectedLanguage) => dispatch => {
  dispatch({
    type: GET_LANGUAGE_STRINGS_START,
  })

  return getLanguageStrings(venueId, interfaceName, defaultLanguage, selectedLanguage)
    .then(response => {
      dispatch({
        type: GET_LANGUAGE_STRINGS_SUCCESS,
        data: response.data,
      })
    })
    .catch(e => {
      dispatch(showErrorMessage('Unable to load this page, please try again later or contact customer support'))

      dispatch({
        type: GET_LANGUAGE_STRINGS_FAIL,
      })

      return e
    })
}

export const convertUpdatesObjectToList = updatesObj => Object.entries(updatesObj).map(([id, value]) => ({ id, value }))

export const trySaveLanguageStrings = interfaceName => (dispatch, getState) => {
  const state = getState()
  const { languageStringUpdates } = state.languageStrings
  const successfulSaveMessage = 'Successfully saved changes'
  if (_.isEmpty(languageStringUpdates.existing) && _.isEmpty(languageStringUpdates.new)) {
    dispatch(showSuccessMessage(successfulSaveMessage))
  } else {
    const { defaultLanguage, selectedLanguage } = state.languages
    const venueId = state.appState.venue.id
    const languageStringUpdateData = {
      language_codes: selectedLanguage.value ? [defaultLanguage.value, selectedLanguage.value] : [defaultLanguage.value],
      new_string_updates: convertUpdatesObjectToList(languageStringUpdates.new),
      existing_string_updates: convertUpdatesObjectToList(languageStringUpdates.existing),
    }

    dispatch({
      type: SAVE_LANGUAGE_STRINGS_START,
    })

    return saveVenueLanguageStrings(venueId, interfaceName, languageStringUpdateData)
      .then(response => {
        dispatch(showSuccessMessage(successfulSaveMessage))
        dispatch({
          type: SAVE_LANGUAGE_STRINGS_SUCCESS,
          data: response.data,
        })
      })
      .catch(e => {
        dispatch(showErrorMessage('Unable to save changes, please try again later or contact customer support'))

        dispatch({
          type: SAVE_LANGUAGE_STRINGS_FAIL,
        })

        return e
      })
  }
}

export const updateLanguageString = (languageStringId, masterStringId, value) => ({
  type: UPDATE_LANGUAGE_STRING,
  languageStringId,
  masterStringId,
  value,
})

export const updatePolicyString = (id, updateObj) => ({
  type: UPDATE_POLICY_STRING,
  id,
  newPolicyObj: updateObj,
})

export const addCustomPolicy = translatedLanguages => (dispatch, getState) => {
  const state = getState()
  const { defaultLanguage } = state.languages
  dispatch({
    type: ADD_CUSTOM_POLICY,
    defaultLanguage: defaultLanguage.value,
    translatedLanguages,
  })
}

export const tryGetPolicyLanguageStrings = (venueId, policyCategory) => (dispatch, getState) => {
  dispatch({
    type: GET_POLICY_STRINGS_START,
  })
  return getPolicyStrings(venueId, policyCategory)
    .then(response => {
      const { policies } = response.data
      const state = getState()
      const policyStrings = parsePolicyData(policies, state)
      dispatch({
        type: GET_POLICY_STRINGS_SUCCESS,
        data: policyStrings,
      })
    })
    .catch(e => {
      dispatch(showErrorMessage('Unable to load this page, please try again later or contact customer support'))
      dispatch({
        type: GET_POLICY_STRINGS_FAIL,
      })
      return e
    })
}

export const trySavePolicyStrings = interfaceName => (dispatch, getState) => {
  const state = getState()
  const { policies, policyUpdatesIds } = state.languageStrings
  if (_.isEmpty(policyUpdatesIds)) {
    const emptyFieldsMessage = 'Nothing to save'
    dispatch(showErrorMessage(emptyFieldsMessage))
  } else {
    const { defaultLanguage, selectedLanguage } = state.languages
    const venueId = state.appState.venue.id
    const allPolicyStringsToUpdate = policies.filter(policy => policyUpdatesIds.has(policy.id))
    const invalidPolicy = allPolicyStringsToUpdate.find(
      policy =>
        _.isEmpty(policy.name) ||
        (!_.isEmpty(defaultLanguage) && _.isEmpty(policy[defaultLanguage.value])) ||
        (!_.isEmpty(selectedLanguage) && _.isEmpty(policy[selectedLanguage.value]))
    )
    if (invalidPolicy) {
      dispatch(showErrorMessage('Unable to save changes because there are empty fields'))
      dispatch({
        type: SAVE_POLICY_STRINGS_FAIL,
      })
      return
    }
    const validPolicyStringsToUpdate = allPolicyStringsToUpdate.map(policyToUpdate => {
      const defaultLanguageObj = _.isEmpty(defaultLanguage) ? {} : { [defaultLanguage.value]: policyToUpdate[defaultLanguage.value] }
      const selectedLanguageObj = _.isEmpty(selectedLanguage) ? {} : { [selectedLanguage.value]: policyToUpdate[selectedLanguage.value] }
      return {
        id: policyToUpdate.isCustom ? '' : policyToUpdate.id,
        name: policyToUpdate.name,
        localized_text: { ...defaultLanguageObj, ...selectedLanguageObj },
      }
    })
    dispatch({
      type: SAVE_POLICY_STRINGS_START,
    })
    savePolicyStrings(venueId, interfaceName, validPolicyStringsToUpdate)
      .then(response => {
        const successfulSaveMessage = 'Successfully saved changes'
        dispatch(showSuccessMessage(successfulSaveMessage))
        const policies = response.data
        const state = getState()
        const policyStrings = parsePolicyData(policies, state)
        dispatch({
          type: SAVE_POLICY_STRINGS_SUCCESS,
          data: policyStrings,
        })
      })
      .catch(e => {
        dispatch(showErrorMessage('Unable to save changes, please try again later or contact customer support'))
        dispatch({
          type: SAVE_POLICY_STRINGS_FAIL,
        })
        return e
      })
  }
}

const parsePolicyData = (policies, state) => {
  const { selectedLanguage } = state.languages
  const { defaultLanguage } = state.languages
  return _.sortBy(
    policies.map((policy, index) => {
      const selectedLanguageValue = _.isEmpty(selectedLanguage) ? '' : selectedLanguage.value
      const enabledLanguage = !_.isEmpty(selectedLanguageValue) ? selectedLanguageValue : defaultLanguage.value
      return {
        name: policy.name,
        [defaultLanguage.value]: policy.localized_text[defaultLanguage.value] || '',
        [enabledLanguage]: policy.localized_text[enabledLanguage] || '',
        id: policy.id,
        createdAt: policy.created,
      }
    }),
    ['createdAt']
  )
}
