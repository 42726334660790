import { Desc, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { ImportLocales } from '../Import.locales'
import { ImportType } from '../types'

interface InitialSettingsProps {
  importType: ImportType
  dateFormat: string
  handleChooseDateFormat: (value: string) => void
  futureTableAndSeatingAreaOverride: string
  handleChooseTableOverride: (value: string) => void
  handleNext: () => void
  disableNext: boolean
}

export default function InitialSettings(props: InitialSettingsProps) {
  const {
    importType,
    dateFormat,
    handleChooseDateFormat,
    futureTableAndSeatingAreaOverride,
    handleChooseTableOverride,
    handleNext,
    disableNext,
  } = props
  const { formatMessage } = useLocales()

  const reservationDateFormatOptions = [
    { value: 'yyyy.mm.dd', label: formatMessage(ImportLocales.reservationDateFormat1) },
    { value: 'yyyy/mm/dd', label: formatMessage(ImportLocales.reservationDateFormat2) },
    { value: 'yyyy-mm-dd', label: formatMessage(ImportLocales.reservationDateFormat3) },
    { value: 'mm.dd.yyyy', label: formatMessage(ImportLocales.reservationDateFormat4) },
    { value: 'mm/dd/yyyy', label: formatMessage(ImportLocales.reservationDateFormat5) },
    { value: 'mm-dd-yyyy', label: formatMessage(ImportLocales.reservationDateFormat6) },
    { value: 'dd.mm.yyyy', label: formatMessage(ImportLocales.reservationDateFormat7) },
    { value: 'dd/mm/yyyy', label: formatMessage(ImportLocales.reservationDateFormat8) },
    { value: 'dd-mm-yyyy', label: formatMessage(ImportLocales.reservationDateFormat9) },
  ]
  const clientDateFormatOptions = [
    { value: 'dd.mm', label: formatMessage(ImportLocales.clientDateFormat1) },
    { value: 'dd/mm', label: formatMessage(ImportLocales.clientDateFormat2) },
    { value: 'dd-mm', label: formatMessage(ImportLocales.clientDateFormat3) },
    { value: 'mm.dd', label: formatMessage(ImportLocales.clientDateFormat4) },
    { value: 'mm/dd', label: formatMessage(ImportLocales.clientDateFormat5) },
    { value: 'mm-dd', label: formatMessage(ImportLocales.clientDateFormat6) },
  ]

  const dateFormatOptions = importType === ImportType.RESERVATION ? reservationDateFormatOptions : clientDateFormatOptions

  return (
    <>
      <Desc>{formatMessage(ImportLocales.step1)}</Desc>
      <SubDesc>{formatMessage(ImportLocales.chooseDateFormat)}</SubDesc>
      <RadioList field="dateFormat" selectedValue={dateFormat} options={dateFormatOptions} onClick={handleChooseDateFormat} />
      {importType === ImportType.RESERVATION && (
        <>
          <br />
          <SubDesc>{formatMessage(ImportLocales.chooseTableSeatingAreaOverride)}</SubDesc>
          <RadioList
            field="futureTableAndSeatingAreaOverride"
            selectedValue={futureTableAndSeatingAreaOverride}
            options={[
              { value: 'table', label: formatMessage(ImportLocales.tableOverride) },
              { value: 'seating_area', label: formatMessage(ImportLocales.seatingAreaOverride) },
              { value: 'no_override', label: formatMessage(ImportLocales.noOverride) },
            ]}
            onClick={(value: string) => handleChooseTableOverride(value)}
          />
        </>
      )}

      <Box marginTop="20px">
        <Button variant="contained" onClick={handleNext} disabled={disableNext} data-test="date-format-submit">
          {formatMessage(ImportLocales.nextStep)}
        </Button>
      </Box>
    </>
  )
}
