import {
  useGetAccessRulesListQuery,
  useGetAudienceHierarchyQuery,
  useGetClientTagGroupsQuery,
  useGetSeatingAreasTablesQueryNew,
} from '@sevenrooms/core/api'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { useAvailabilityUrlParams } from '../../hooks'
import { AccessRuleInput } from './AccessRuleInput'
import { AudienceInput } from './AudienceInput'
import { ClientTagsInput } from './ClientTagsInput'
import { DateInput } from './DateInput'
import { DurationInput } from './DurationInput'
import { SeatingAreaInput } from './SeatingAreaInput'

export function AvailabilityCalendarInput() {
  const { venueId, venueKey } = useVenueContext()
  const [{ date }] = useAvailabilityUrlParams()
  const { venueSettings } = useVenueSettingsContext()
  const isExclusiveArAccessEnabled = venueSettings?.is_exclusive_ar_access_enabled

  const audienceHierarchy = useGetAudienceHierarchyQuery({ venueId })
  const accessRules = useGetAccessRulesListQuery({ venueId, startDate: date.toIso() })
  const seatingAreasTables = useGetSeatingAreasTablesQueryNew({ venueId })
  const clientTagGroups = useGetClientTagGroupsQuery({ venueKey })

  return (
    <Stack direction="column" spacing={0}>
      <Stack direction="row" alignItems="end" spacing={2} sx={{ width: '100%' }}>
        <DateInput />
        <AudienceInput audiences={audienceHierarchy.data} />
        <AccessRuleInput accessRules={accessRules.data} />
        <SeatingAreaInput seatingAreas={seatingAreasTables.data?.newSeatingAreaCodesToTables} />
        {isExclusiveArAccessEnabled && <ClientTagsInput clientTags={clientTagGroups.data} />}
        <DurationInput accessRules={accessRules.data} />
      </Stack>
    </Stack>
  )
}
