import React from 'react'
import styled from 'styled-components'
import { Spinner } from './Spinner'

export function Blocker(props) {
  return (
    <BlockerContainer {...props}>
      {props.children}
      {props.isActive && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
    </BlockerContainer>
  )
}

const BlockerContainer = styled.div`
  position: relative;
  ${props => props.css};
`

const Overlay = styled.div`
  background-color: rgba(247, 247, 247, 0.65);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`
