import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Icon from 'svr/component-lib/Generic/Icon'

const SlideoutHeader = styled.div`
  height: 42px;
  width: 100%;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.navigationDark};
  padding: 0;
  position: relative;
`

const SlideoutTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 42px;
`

const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.primaryRgba10};
  }
`

const SlideoutBody = styled.div`
  position: absolute;
  top: 42px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`

const SlideoutContainer = styled.div`
  height: 100%;
  border-collapse: separate;
  width: 80%;
  max-width: ${props => props.maxWidth};
  background-color: ${props => props.theme.margin};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 400;
  top: 0;
  font-family: ${props => props.theme.fontFamily} !important;
  font-feature-settings: normal;
  font-kerning: auto;
  font-size-adjust: none;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  font-variant-caps: normal;
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  right: ${props => (props.isOpen ? '0' : '-100%')};
  transition: all 300ms ease-in-out;
`

const SlideoutBlurView = styled.div`
  position: fixed;
  z-index: 399;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  background-color: ${props => props.theme.navigationDarkRgba60};
  transition: all 300ms ease-in-out;
`

const Content = styled.div`
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  width: 100%;
  bottom: 90px;
  background: #fff;
`
const Controls = styled.div`
  box-shadow: 0 0 6px 0 #00000030;
  position: absolute;
  bottom: 0;
  height: 90px;
  left: 0;
  right: 0;
  padding-right: 36px;
  background: #fff;
  display: flex;
  align-items: center;
  > * {
    margin-left: auto;
  }
`

const Slideout = ({ title, isBlurBgVisible, isOpen, onClose, children, maxWidth }) => (
  <div>
    {isBlurBgVisible && <SlideoutBlurView isOpen={isOpen} />}
    <SlideoutContainer isOpen={isOpen} maxWidth={maxWidth}>
      <SlideoutHeader>
        <SlideoutTitle data-test="sr-slideout-title">{title}</SlideoutTitle>
        <CloseButton data-test="sr-slideout-close" id="sr-slideout-close" onClick={onClose}>
          <Icon icon={Icon.ICONS.Close} />
        </CloseButton>
      </SlideoutHeader>
      <SlideoutBody>{children}</SlideoutBody>
    </SlideoutContainer>
  </div>
)

Slideout.Content = Content
Slideout.Controls = Controls

Slideout.defaultProps = {
  title: '',
  maxWidth: '796px',
  isBlurBgVisible: false,
}

Slideout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isBlurBgVisible: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
}

export default Slideout
