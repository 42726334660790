import { defineMessages } from '@sevenrooms/core/locales'

export const ImportLocales = defineMessages({
  reservationTitle: {
    id: 'import.reservationTitle',
    defaultMessage: 'Reservation import',
  },
  clientTitle: {
    id: 'import.clientTitle',
    defaultMessage: 'Client import',
  },
  step1: {
    id: 'import.step1',
    defaultMessage: 'Step 1',
  },
  step2: {
    id: 'import.step2',
    defaultMessage: 'Step 2',
  },
  step3: {
    id: 'import.step3',
    defaultMessage: 'Step 3',
  },
  step4: {
    id: 'import.step4',
    defaultMessage: 'Step 4',
  },
  nextStep: {
    id: 'import.nextStep',
    defaultMessage: 'Next',
  },
  backStep: {
    id: 'import.backStep',
    defaultMessage: 'Back',
  },
  chosenDateFormat: {
    id: 'reviews.chosenDateFormat',
    defaultMessage: "Date format is ''{dateFormat}'",
  },
  uploadFile: {
    id: 'import.uploadFile',
    defaultMessage: "Please upload a file. Don't forget to map field from file with ''{importType} tags' field",
  },
  importing: {
    id: 'import.importing',
    defaultMessage: 'Importing',
  },
  importEmail: {
    id: 'import.importEmail',
    defaultMessage: 'Importing your data may take some time. You will receive an email when the import is finished.',
  },
  importId: {
    id: 'import.importId',
    defaultMessage: 'Import ID: {importId}',
  },
  saveImportId: {
    id: 'import.saveImportId',
    defaultMessage: 'Please save this Import ID in case you need to undo the import.',
  },
  importFile: {
    id: 'import.importFile',
    defaultMessage: 'Import file',
  },
  mapTags: {
    id: 'import.mapTags',
    defaultMessage: 'Map tags',
  },
  submit: {
    id: 'import.submit',
    defaultMessage: 'Submit',
  },
  close: {
    id: 'import.close',
    defaultMessage: 'Close',
  },
  copyImportId: {
    id: 'import.copyImportId',
    defaultMessage: 'Copy Import ID',
  },
  chooseDateFormat: {
    id: 'import.chooseDateFormat',
    defaultMessage: 'Please choose a date format',
  },
  chooseTableSeatingAreaOverride: {
    id: 'import.chooseTableSeatingAreaOverride',
    defaultMessage:
      'For future reservations, please specify how to prioritize Table and Seating Area if the provided table is not in the provided area.',
  },
  chooseTagColumn: {
    id: 'import.chooseTagColumn',
    defaultMessage: `Select which custom columns from your file contain <bold>TAGS</bold> that you want to map to 7R tags.`,
  },
  mapTagsFromFile: {
    id: 'import.mapTagsFromFile',
    defaultMessage: 'Map tags from file with 7R tags. You can create a new tag even with new category',
  },
  fileUploading: {
    id: 'import.fileUploading',
    defaultMessage: 'File is uploading, please wait..',
  },
  submitWhenMapped: {
    id: 'import.submitWhenMapped',
    defaultMessage: 'Click submit if you have mapped tags',
  },
  tableOverride: {
    id: 'import.tableOverride',
    defaultMessage: "Update the Seating Area to match the Table's actual location.",
  },
  seatingAreaOverride: {
    id: 'import.seatingAreaOverride',
    defaultMessage: 'Remove the Table value and allow the system to auto-assign to a table within the provided Seating Area.',
  },
  noOverride: {
    id: 'import.noOverride',
    defaultMessage: 'Allow the Table and Seating Area to be mismatched.',
  },
  reservationDateFormat1: {
    id: 'import.reservationDateFormat1',
    defaultMessage: 'yyyy.mm.dd',
  },
  reservationDateFormat2: {
    id: 'import.reservationDateFormat2',
    defaultMessage: 'yyyy/mm/dd',
  },
  reservationDateFormat3: {
    id: 'import.reservationDateFormat3',
    defaultMessage: 'yyyy-mm-dd',
  },
  reservationDateFormat4: {
    id: 'import.reservationDateFormat4',
    defaultMessage: 'mm.dd.yyyy',
  },
  reservationDateFormat5: {
    id: 'import.reservationDateFormat5',
    defaultMessage: 'mm/dd/yyyy',
  },
  reservationDateFormat6: {
    id: 'import.reservationDateFormat6',
    defaultMessage: 'mm-dd-yyyy',
  },
  reservationDateFormat7: {
    id: 'import.reservationDateFormat7',
    defaultMessage: 'dd.mm.yyyy',
  },
  reservationDateFormat8: {
    id: 'import.reservationDateFormat8',
    defaultMessage: 'dd/mm/yyyy',
  },
  reservationDateFormat9: {
    id: 'import.reservationDateFormat9',
    defaultMessage: 'dd-mm-yyyy',
  },
  clientDateFormat1: {
    id: 'import.clientDateFormat1',
    defaultMessage: 'dd.mm',
  },
  clientDateFormat2: {
    id: 'import.clientDateFormat2',
    defaultMessage: 'dd/mm',
  },
  clientDateFormat3: {
    id: 'import.clientDateFormat3',
    defaultMessage: 'dd-mm',
  },
  clientDateFormat4: {
    id: 'import.clientDateFormat4',
    defaultMessage: 'mm.dd',
  },
  clientDateFormat5: {
    id: 'import.clientDateFormat5',
    defaultMessage: 'mm/dd',
  },
  clientDateFormat6: {
    id: 'import.clientDateFormat6',
    defaultMessage: 'mm-dd',
  },
} as const)
