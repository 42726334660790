import _ from 'lodash'
import moment from 'moment-timezone'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import SegmentedControl from 'mgr/lib/components/SegmentedControl'
import { TextInputLabel } from 'mgr/lib/forms/TextInput'
import * as BookingChannelActions from 'mgr/pages/single-venue/settings/actions/BookingChannelActions'
import * as ResyFields from 'mgr/pages/single-venue/settings/components/ResyFields'
import ResyImages from 'mgr/pages/single-venue/settings/components/ResyImages'
import {
  BookingChannelContainer,
  LargeChannelImg,
  FormContainer,
  FieldRow,
  FieldCol,
  HorizontalLine,
  Header,
  HeaderTitle,
  HeaderButtons,
  HeaderTimestamp,
  DeactivateButton,
  EditButton,
  SaveFooter,
  SaveDraftButton,
  SaveButton,
  CancelButton,
  ErrorDialogWrapper,
  ErrorIcon,
  ErrorBody,
  ErrorDismiss,
} from 'mgr/pages/single-venue/settings/components/shared'
import VmsIcons from 'svr/common/VmsIcons'
import ResyImageCropper, { CropperScreen } from './ResyImageCropper'

class ResyBookingChannelSettings extends PureComponent {
  constructor(props) {
    super(props)
    this.validateFieldMap = {}
    this.onSaveDraftClickHandler = this.onSaveDraftClickHandler.bind(this)
    this.onSaveAndPublishClickHandler = this.onSaveAndPublishClickHandler.bind(this)
  }

  onSaveDraftClickHandler() {
    const { actions, isLoading } = this.props
    if (isLoading) {
      return
    }

    actions.saveDraftResyChannelSettings()
  }

  onSaveAndPublishClickHandler() {
    const { actions, validateAllFields, isLoading } = this.props
    if (isLoading) {
      return
    }
    const formErrors = _.reduce(
      this.validateFieldMap,
      (invalidFields, input, field) => {
        const invalidText = !_.isNil(input) && ((input.props && input.props.disabled) || input.isValid())
        return _.assign(invalidFields, invalidText !== true && invalidText !== false && { [field]: invalidText })
      },
      {}
    )

    actions.formValidated(formErrors)

    if (_.isEmpty(formErrors)) {
      actions.saveResyChannelSettings()
    }
  }

  render() {
    const { resyChannelSettings, isResyActiveChannel, actions } = this.props

    if (_.isNil(resyChannelSettings)) {
      return null
    }
    const { selectEditResyChannel, deactivateResyChannel } = actions
    const updatedMoment = moment(`${resyChannelSettings.updated}Z`)
    const createdMoment = moment(`${resyChannelSettings.created}Z`)
    const lastUpdatedDisplay = updatedMoment.diff(createdMoment).seconds() < 5 ? 'Never' : updatedMoment.format('lll')

    return (
      <BookingChannelContainer>
        <Header>
          <LargeChannelImg path="integrations/Resy_Logo.png" />
          <HeaderTitle>Resy</HeaderTitle>
          <HeaderButtons>
            {!isResyActiveChannel && [
              <EditButton onClick={() => selectEditResyChannel()} key="EditButton">
                {resyChannelSettings.is_active ? 'Edit' : 'Configure'}
              </EditButton>,
              resyChannelSettings.is_active && (
                <DeactivateButton onClick={() => deactivateResyChannel()} key="DeactivateButton">
                  Deactivate
                </DeactivateButton>
              ),
            ]}
          </HeaderButtons>
        </Header>
        <HeaderTimestamp>
          {resyChannelSettings.is_active ? `Last published ${lastUpdatedDisplay}` : `Last updated ${lastUpdatedDisplay}`}
        </HeaderTimestamp>
        {isResyActiveChannel && this.renderEditResyForm()}
      </BookingChannelContainer>
    )
  }

  renderEditResyForm() {
    const { resyChannelSettings, resyChannelFormOptions, actions, formErrors, isLoading, photoIdx } = this.props
    const { changeResyField, cancelBookingChannelEdit, editPhoto, deletePhoto, uploadPhoto } = actions
    const { validateFieldMap } = this
    const fieldArgs = {
      resyChannelSettings,
      resyChannelFormOptions,
      changeResyField,
      formErrors,
      validateFieldMap,
    }
    return [
      <HorizontalLine key="HorizontalLine" />,
      <FormContainer key="FormContainer">
        <FieldRow>
          <FieldCol xs={12} md={6}>
            {ResyFields.nameField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={6}>
            {ResyFields.groupNameField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={6}>
            {ResyFields.address1Field(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={6}>
            {ResyFields.address2Field(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={6}>
            {ResyFields.crossStreet1Field(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={6}>
            {ResyFields.crossStreet2Field(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={4}>
            {ResyFields.neighborhoodField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.localityField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.regionField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={4}>
            {ResyFields.locationCodeField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.postalCodeField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.countryField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={4}>
            {ResyFields.geoLatitudeField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.geoLongitudeField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.localeField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={6}>
            {ResyFields.phoneNumberField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={6}>
            {ResyFields.urlField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={12}>
            {ResyFields.contentAboutField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={12}>
            {ResyFields.contentNeedToKnowField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={4}>
            {ResyFields.cuisineDetailField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.cuisineTypeField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.styleField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={12}>
            {ResyFields.menuItemsField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={12}>
            <TextInputLabel>DAYS OF OPERATION</TextInputLabel>
            <div>
              {_.range(7).map(index =>
                ResyFields.daysOfOperationCheckbox({
                  ...fieldArgs,
                  index,
                })
              )}
            </div>
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={4}>
            {ResyFields.bookingWindowDaysField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.partyMinField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.partyMaxField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={4}>
            {ResyFields.defaultTimeSlotDescriptionField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.priceRangeField(fieldArgs)}
          </FieldCol>
          <FieldCol xs={12} md={4}>
            {ResyFields.ticketAvgPerPersonField(fieldArgs)}
          </FieldCol>
        </FieldRow>
        <FieldRow>
          <FieldCol xs={12} md={12}>
            <ResyImages images={resyChannelSettings.images} editPhoto={editPhoto} deletePhoto={deletePhoto} uploadPhoto={uploadPhoto} />
          </FieldCol>
        </FieldRow>
        {this.renderErrorDialog()}
        <FieldRow>
          <FieldCol xs={12} md={12}>
            <SaveButton onClick={this.onSaveAndPublishClickHandler} disabled={isLoading} key="SaveButton">
              {isLoading ? <PulsatingDots /> : 'Save and Publish'}
            </SaveButton>
            {!resyChannelSettings.is_active && (
              <SaveDraftButton onClick={this.onSaveDraftClickHandler} disabled={isLoading} key="SaveDraftButton">
                {isLoading ? <PulsatingDots /> : 'Save Draft'}
              </SaveDraftButton>
            )}
            <CancelButton onClick={() => cancelBookingChannelEdit()} disabled={isLoading} key="CancelButton">
              Cancel
            </CancelButton>
          </FieldCol>
        </FieldRow>
      </FormContainer>,
      photoIdx >= 0 && <ResyImageCropper key="ResyImageCropper" />,
      photoIdx >= 0 && <CropperScreen key="CropperScreen" />,
    ]
  }

  renderErrorDialog() {
    const { actions, formErrors, didDismissErrorDisplay } = this.props
    const formErrorMessages = _.compact(_.values(formErrors)).sort()
    const isVisible = !didDismissErrorDisplay && !_.isEmpty(formErrorMessages)
    return (
      isVisible && (
        <ErrorDialogWrapper>
          <ErrorIcon>{VmsIcons.WarningLine}</ErrorIcon>
          <ErrorBody>
            {formErrorMessages.map(e => (
              <li key={e}>
                {e}
                <br />
              </li>
            ))}
          </ErrorBody>
          <ErrorDismiss onClick={actions.dismissErrorDisplay}>{VmsIcons.Close}</ErrorDismiss>
        </ErrorDialogWrapper>
      )
    )
  }
}

const mapStateToProps = state => ({
  isResyActiveChannel: state.bookingChannels.activeChannelKey === BookingChannelActions.CHANNEL_KEY_RESY,
  resyChannelSettings: state.bookingChannels.channelSettings[BookingChannelActions.CHANNEL_KEY_RESY],
  resyChannelFormOptions: state.bookingChannels.channelFormOptions[BookingChannelActions.CHANNEL_KEY_RESY],
  formErrors: state.bookingChannels.formErrors,
  isLoading: state.bookingChannels.isLoading,
  photoIdx: state.bookingChannels.photoIdx,
  didDismissErrorDisplay: state.bookingChannels.didDismissErrorDisplay,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      selectEditResyChannel: _.partial(BookingChannelActions.selectBookingChannelEdit, BookingChannelActions.CHANNEL_KEY_RESY),
      deactivateResyChannel: _.partial(BookingChannelActions.tryDeactivateBookingChannel, BookingChannelActions.CHANNEL_KEY_RESY),
      cancelBookingChannelEdit: BookingChannelActions.cancelBookingChannelEdit,
      changeResyField: _.partial(BookingChannelActions.changeBookingChannelField, BookingChannelActions.CHANNEL_KEY_RESY),
      formValidated: BookingChannelActions.bookingChannelFormValidated,
      dismissErrorDisplay: BookingChannelActions.bookingChannelDismissErrorDisplay,
      saveDraftResyChannelSettings: _.partial(
        BookingChannelActions.trySaveDraftBookingChannelSettings,
        BookingChannelActions.CHANNEL_KEY_RESY
      ),
      saveResyChannelSettings: _.partial(BookingChannelActions.trySaveBookingChannelSettings, BookingChannelActions.CHANNEL_KEY_RESY),
      editPhoto: BookingChannelActions.editPhoto,
      deletePhoto: BookingChannelActions.deletePhoto,
      uploadPhoto: BookingChannelActions.uploadPhoto,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResyBookingChannelSettings)
