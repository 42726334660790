import * as ReactDOM from 'react-dom'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AppRoot } from '@sevenrooms/mgr-core'
import { ActivityLogModal, type ActivityLogModalInterface } from './components/ActivityLogModal'

export * from './components/VenueGroupActivityLog'
export * from './components/shared/ActivityLog.locales'

const renderActivityLogModal = ({ entityObjectId, isActive, title, setIsActive, onClose }: ActivityLogModalInterface) => {
  ReactDOM.render(
    <AppRoot>
      <ActivityLogModal entityObjectId={entityObjectId} title={title} isActive={isActive} setIsActive={setIsActive} onClose={onClose} />
    </AppRoot>,
    document.getElementById('activity-log-modal') as HTMLElement
  )
}

const unmountActivityLogModal = () => {
  ReactDOM.unmountComponentAtNode(document.getElementById('activity-log-modal') as HTMLElement)
}

svrExport('MgrActivityLog', 'renderActivityLogModal', renderActivityLogModal)
svrExport('MgrActivityLog', 'unmountActivityLogModal', unmountActivityLogModal)
