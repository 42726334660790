import { useGetOrdersQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useDestination } from '@sevenrooms/core/navigation'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { Box, Loader } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useNavigationCalendarContext } from '@sevenrooms/mgr-core/hooks/useNavigationCalendarContext'
import { routes } from '@sevenrooms/routes'
import { ordersMessages } from '../locales/ordersMessages'
import { OrdersContent } from './OrdersContent'

export function OrdersManagement() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { date, setDate } = useNavigationCalendarContext()
  const { query } = useDestination(routes.manager2.orders)
  const queryDate = query.date ? DateOnly.from(query.date).toJsDate() : undefined
  const formattedDate = date ? DateOnly.fromDate(date).toIso() : null

  if (queryDate && queryDate.getTime() !== date?.getTime()) {
    setDate(queryDate)
  }
  const {
    data: orders = [],
    isFetching,
    refetch: reFetchOrders,
  } = useGetOrdersQuery({
    venueId: venue.id,
    start: formattedDate,
    end: formattedDate,
  })

  return (
    <>
      <SettingsPageMeta title={formatMessage(ordersMessages.ordersTitle)} />
      <SettingsPageContent title={formatMessage(ordersMessages.ordersTitle)}>
        <Box pt="s" pb="lm" maxWidth="100%">
          {isFetching ? <Loader /> : <OrdersContent orders={orders} venue={venue} onSlideOutOrderStatusChange={reFetchOrders} />}
        </Box>
      </SettingsPageContent>
    </>
  )
}
