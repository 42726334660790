import type { UserCountTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../../locales'
import { formatParagraph, type DetailProps, HELP_CENTER_USERS_URL } from '../../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { LowUsersSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'

export function LowUsersDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { insightProofData } = data

  const tableRows = (insightProofData?.[0]?.tableRows as UserCountTableRow[]) ?? []

  const description = formatMessage(insightsMessages.lowUsersSlideoutDescription, {
    p: (chunks: string[]) => formatParagraph(chunks),
    link: (chunks: string[]) => (
      <Link to={HELP_CENTER_USERS_URL} target="_blank">
        {chunks}
      </Link>
    ),
  })

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={description}
      proofLabel={formatMessage(insightsMessages.lowUsersProofLabel)}
      suggestedActionsComponent={<LowUsersSuggestedActions venueUrlKey={venue.urlKey} />}
      proofComponent={
        <Text>
          {formatMessage(insightsMessages.lowUsersUserCount)}: {tableRows[0]?.userCount}
        </Text>
      }
    />
  )
}
