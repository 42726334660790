import type { UpgradeCoverageSuggestedActionsType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link, List } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../locales'
import { formatBold } from '../../../utils'

export interface UpgradeCoverageSuggestedActionsProps {
  insightSuggestedActionData: UpgradeCoverageSuggestedActionsType
}

export function UpgradeCoverageSuggestedActions({ insightSuggestedActionData }: UpgradeCoverageSuggestedActionsProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-upgrade-coverage-suggested-actions">
      <List variant="ordered" spacing="s">
        {!!insightSuggestedActionData.shiftNames.length && (
          <Text type="li" data-test="insights-upgrade-coverage-suggested-actions-navigate-shifts">
            <Box mb="s">
              {formatMessage(insightsMessages.suggestedActionUpgradeCoverageNavToShiftsStep, {
                link: (
                  <Link
                    to={nav.href(routes.manager.manage.shifts, {
                      params: { venueKey: venue.urlKey },
                    })}
                    target="_blank"
                    data-test="insights-upgrade-coverage-suggested-actions-shifts-link"
                  >
                    {formatMessage(insightsMessages.suggestedActionNavigatetoShiftLinkText, {
                      icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
                    })}
                  </Link>
                ),
              })}
            </Box>
            <List variant="ordered" spacing="s" type="a">
              <Text type="li" data-test="insights-upgrade-coverage-suggested-actions-add-shift-upgrades">
                <Box mb="s">
                  {formatMessage(insightsMessages.suggestedActionUpgradeCoverageAddShiftUpgradesStep, {
                    strong: (chunks: string[]) => formatBold(chunks),
                  })}
                </Box>
                <List variant="ordered" type="i">
                  {insightSuggestedActionData.shiftNames.map((shiftName: string) => (
                    <Text type="li" key={`shift-name-${shiftName}`} data-test={`insights-upgrade-coverage-shift-${shiftName}`}>
                      {formatMessage(insightsMessages.suggestedActionUpgradeCoverageShiftNameStep, {
                        shiftName,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore: the type ParseMessage needs to be updated
                        strong: (chunks: string[]) => formatBold(chunks),
                      })}
                    </Text>
                  ))}
                </List>
              </Text>
            </List>
          </Text>
        )}

        {!!insightSuggestedActionData.accessRuleNames.length && (
          <Text type="li" data-test="insights-upgrade-coverage-suggested-actions-navigate-access-rules">
            <Box mb="s">
              {formatMessage(insightsMessages.suggestedActionUpgradeCoverageNavToAccessRulesStep, {
                link: (
                  <Link
                    to={nav.href(routes.manager.manage.accessRules, {
                      params: { venueKey: venue.urlKey },
                    })}
                    target="_blank"
                    data-test="insights-upgrade-coverage-suggested-actions-access-rules-link"
                  >
                    {formatMessage(insightsMessages.suggestedActionNavigatetoAccessRulesText, {
                      icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
                    })}
                  </Link>
                ),
              })}
            </Box>
            <List variant="ordered" spacing="s" type="a">
              <Text type="li" data-test="insights-upgrade-coverage-suggested-actions-add-access-rule-upgrades">
                <Box mb="s">
                  {formatMessage(insightsMessages.suggestedActionUpgradeCoverageAddAccessRuleUpgradesStep, {
                    strong: (chunks: string[]) => formatBold(chunks),
                  })}
                </Box>
                <List variant="ordered" type="i">
                  {insightSuggestedActionData.accessRuleNames.map((accessRuleName: string) => (
                    <Text
                      type="li"
                      key={`access-rule-name-${accessRuleName}`}
                      data-test={`insights-upgrade-coverage-access-rule-${accessRuleName}`}
                    >
                      {formatMessage(insightsMessages.suggestedActionUpgradeCoverageAccessRuleNameStep, {
                        accessRuleName,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore: the type ParseMessage needs to be updated
                        strong: (chunks: string[]) => formatBold(chunks),
                      })}
                    </Text>
                  ))}
                </List>
              </Text>
            </List>
          </Text>
        )}
      </List>
    </VStack>
  )
}
