import _ from 'lodash'
import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import StyleUtils from 'mgr/layout/StyleUtils'
import { getIconForStatus, getIconColor } from 'mgr/lib/utils/Statuses'
import { getDateForActual } from 'mgr/pages/shared/utils/Actuals'
import {
  statusDropDownOpenedAction,
  statusDropDownClosedAction,
  tryPostSaveResTableAssignmentAction,
} from 'mgr/pages/single-venue/dayview/actions/Actions'
import { enterTableAssignMode } from 'mgr/pages/single-venue/dayview/actions/FloorplanActions'
import { CommonStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { isTouchDevice } from 'svr/common/BrowserUtils'
import { merge } from 'svr/common/SRUtils'
import { zeroIndexMonth, isPastTime } from 'svr/common/TimeUtil'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

const BodyDiv = styled.div`
  position: relative;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.white};
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.greyMuted};
  :hover {
    background-color: ${props => props.theme.color.greyMuted};
  }
`

const ActionBarDiv = styled.div`
  display: flex;
  align-items: center;
`

const VipDiv = styled.div`
  position: absolute;
  box-sizing: border-box;
  border-right: 2px solid white;
  height: 20px;
  width: 20px;
  background-color: ${CommonStyles.ClientVipColor};
  top: -10px;
  left: -10px;
  transform: rotate(45deg);
  z-index: 2;
`

const FloorplanResCellStyles = {
  statusIcon: merge(
    {
      textAlign: 'center',
      top: 2,
      fontSize: '30px',
      position: 'relative',
      padding: 2,
      marginRight: 5,
      marginLeft: 3,
      ':hover': {
        background: '#ccc',
      },
    },
    StyleUtils.rounded('15px')
  ),
  guests: {
    fontWeight: 600,
    textAlign: 'right',
  },
  name: {
    marginRight: 15,
    flexGrow: '1',
  },
  icon: {
    marginRight: 3,
  },
  resTag: {
    fontSize: '16px',
  },
  resTagsIcon: {
    letterSpacing: '-7px',
    marginRight: 10,
    position: 'relative',
    top: 2,
  },
  lock: merge(
    {
      padding: 3,
      fontSize: '17px',
      opacity: 0.6,
      ':hover': {
        opacity: 0.85,
      },
    },
    StyleUtils.rounded('3px')
  ),
  table: merge(
    {
      marginLeft: 4,
      marginRight: 4,
      minWidth: '40px',
      height: '40px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      padding: 2,
      background: '#eee',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    StyleUtils.rounded('11px')
  ),
}

class FloorplanResCell extends PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleDropDownClick = this.handleDropDownClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleLockClick = this.handleLockClick.bind(this)
    this.handleTableClick = this.handleTableClick.bind(this)

    this.state = {
      hover: false,
    }
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.containerRef = null
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      hover: false,
    })
  }

  onMouseOver() {
    this.setState({ hover: true })
  }

  onMouseOut() {
    this.setState({ hover: false })
  }

  handleClick() {
    this.props.floorplanActions.showActual(this.props.actual)
  }

  handleDropDownClick(e) {
    if (this.isPastTime(this.props.actual)) {
      e.stopPropagation()
      return
    }

    if (!this.props.actual.is_in_service) {
      if (!this.props.statusDropDownState.isOpen) {
        const elRect = this.containerRef.getBoundingClientRect()
        this.props.actions.statusDropDownOpened(this.props.actual, elRect)
      }
    }
    e.stopPropagation()
  }

  handleClickOutside() {
    this.setState({ hover: false })
  }

  canAssignTables(venue, actual) {
    return venue.features.can_edit_floorplan && !this.isPastTime(actual) && !actual.is_in_service && venue.isNightlifeClass
  }

  canLockOrUnlockAutoAssignments(venue, actual) {
    return (
      venue.features.can_edit_floorplan &&
      venue.isAutoAssignEnabled &&
      !this.isPastTime(actual) &&
      !actual.is_in_service &&
      venue.isDiningClass
    )
  }

  handleTableClick(e) {
    const { venue, actual, actions } = this.props
    if (!this.canAssignTables(venue, actual)) {
      // Propagate and open res card
      return
    }

    actions.enterTableAssignMode(actual)
    e.stopPropagation()
  }

  handleLockClick(e) {
    const { venue, actual, isUnassigned, actions } = this.props
    e.stopPropagation()
    if (!this.canLockOrUnlockAutoAssignments(venue, actual)) {
      return
    }

    if (this.isLockedActual(actual, venue)) {
      if (venue.isAutoAssignEnabled) {
        actions.autoAssignTables(venue.id, actual.id)
      } else {
        actions.unassignTables(venue.id, actual.id)
      }
    } else {
      actions.assignTable(venue.id, actual.id, _.map(actual.computedTableList, 'id'))
    }
  }

  isLockedActual(actual, venue) {
    const hasNoTables = _.isEmpty(actual.all_table_ids_list)
    const isHardUnassigned = hasNoTables && venue.isAutoAssignEnabled && actual.disable_auto_assign
    const isHardAssigned = !hasNoTables
    return isHardAssigned || isHardUnassigned
  }

  isPastTime(actual) {
    const endOrder = actual.arrival_time_sort_order + actual.duration / 15
    return isPastTime(endOrder, this.props.venue.timezone, this.props.venue.startOfDayHour, this.props.date)
  }

  renderOccasions(date, occasions) {
    const isBirthdayToday =
      'birthday' in occasions && zeroIndexMonth(occasions.birthday.month) === date.month() && occasions.birthday.day === date.date()
    const isAnniversaryToday =
      'anniversary' in occasions &&
      zeroIndexMonth(occasions.anniversary.month) === date.month() &&
      occasions.anniversary.day === date.date()
    occasions = []
    if (isBirthdayToday) {
      occasions.push(
        <div key="birthday" style={[VmsIconSizes.xxsmall, FloorplanResCellStyles.icon]}>
          {VmsIcons.Birthday}
        </div>
      )
    }
    if (isAnniversaryToday) {
      occasions.push(
        <div key="anniversary" style={[VmsIconSizes.xxsmall, FloorplanResCellStyles.icon]}>
          {VmsIcons.Anniversary}
        </div>
      )
    }
    return occasions
  }

  renderTags(tagGroups, actualId) {
    const tagComponents = []
    let count = 0
    for (const tagGroup of tagGroups) {
      for (let idx = 0; idx < tagGroup.tags.length; idx++) {
        const tag = tagGroup.tags[idx]
        tagComponents.push(
          <span
            key={tag.tag_group_id + tag.tag_name}
            data-for={`tip-${actualId}`}
            data-tip={tag.tag_name_display}
            style={[VmsIconSizes.xxsmall, FloorplanResCellStyles.resTag, { color: tag.tag_color }]}
          >
            {count === 0 ? VmsIcons.Tag : VmsIcons.TagMulti}
          </span>
        )
        count++
      }
    }
    return tagComponents
  }

  getIconColor(actual) {
    return getIconColor(this.props.statusesByDb, actual.status)
  }

  renderStatus(actual) {
    return getIconForStatus(actual.status, actual.is_left)
  }

  getTablesDisplay(actual, venue) {
    if (_.isEmpty(actual.computedTableList)) {
      return this.canAssignTables(venue, actual) ? 'assign' : ''
    }
    const maxNumToShow = 2
    const tableCodes = _.take(actual.computedTableList.map(t => t.item_code).sort(), maxNumToShow)
    if (_.size(actual.computedTableList) > maxNumToShow) {
      tableCodes[maxNumToShow - 1] = `${tableCodes[maxNumToShow - 1]}+`
    }
    const result = tableCodes.map(tc => (
      <span key={tc}>
        {tc}
        <br />
      </span>
    ))
    return result
  }

  render() {
    return this.renderActual()
  }

  renderNameToolTip(actual) {
    const size = 45
    const style = `width: ${size}px; height: ${size}px; border-radius: ${size / 2}px;margin: 3px 5px 0 3px; position: relative`
    const photo =
      actual.client_photo && actual.client_photo.medium
        ? `
    <div style="float:left; margin-right:8px">
      <img src="/.h/download/${actual.client_photo.medium}" style="${style}"/>
    </div>
    `
        : ''
    const extraMargin = photo ? 'margin-top:7px' : ''
    const html = `
      <div style="text-align:left; ">
        ${photo}
        <div style="float:left;${extraMargin}">
          <div style="font-size:12px; font-weight:600">${actual.client_display_name}</div>
          <div style="font-size:12px; max-width: 300px;white-space:pre-line ">${actual.notes}</div>
        </div>
        <div style="clear:both;"></div>
      </div>
    `
    return html
  }

  renderActual() {
    const { venue, actual, isUnassigned } = this.props
    const computedStyles = {
      problemRes: {
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: 4,
        zIndex: 31,
      },
    }
    if (actual.problem) {
      computedStyles.problemRes.backgroundColor = actual.problem.is_major
        ? CommonStyles.ProblemReservationMajor
        : CommonStyles.ProblemReservationMinor
    }

    const isLocked = this.isLockedActual(actual, venue)
    const isPast = this.isPastTime(actual)

    let lockStyle = FloorplanResCellStyles.lock
    let statusStyle = FloorplanResCellStyles.statusIcon
    let tableStyle = FloorplanResCellStyles.table
    let hoverStyle = {}

    if (this.state.hover) {
      hoverStyle = FloorplanResCellStyles.hover
      lockStyle = merge(lockStyle, { opacity: 0.55 })
    } else {
      lockStyle = merge(lockStyle, { ':hover': {} })
    }

    if (!this.state.hover || isPast) {
      statusStyle = merge(statusStyle, { ':hover': {} })
    }

    if (!this.canLockOrUnlockAutoAssignments(venue, actual)) {
      lockStyle = merge(lockStyle, { display: 'none' })
    } else if (!this.state.hover) {
      if (!isLocked) {
        lockStyle = merge(lockStyle, { opacity: 0 })
      }
    } else if (isUnassigned && !isLocked) {
      lockStyle = merge(lockStyle, { opacity: 0 })
    }

    if (lockStyle.display === 'none') {
      tableStyle = merge(tableStyle, { marginRight: 8 })
    }

    if (this.canAssignTables(venue, actual)) {
      tableStyle = merge(tableStyle, { ':hover': { background: '#ccc' } })
    }
    return (
      <div
        ref={container => {
          this.containerRef = container
        }}
      >
        {!isTouchDevice() && <ReactTooltip id={`tip-${actual.id}`} effect="solid" />}
        <BodyDiv onClick={this.handleClick} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
          {!_.isEmpty(actual.problem) && (
            <div key="_problem" style={computedStyles.problemRes} data-for={`tip-${actual.id}`} data-tip={actual.problem.problem_name} />
          )}
          {actual.is_client_vip && <VipDiv key="_vip" />}
          <div
            key="_status"
            data-for={`tip-${actual.id}`}
            data-tip={actual.status_formatted}
            style={[VmsIconSizes.xxsmall, statusStyle, this.getIconColor(actual)]}
            onClick={this.handleDropDownClick}
          >
            {this.renderStatus(actual)}
          </div>
          <div
            key="_clientName"
            style={FloorplanResCellStyles.name}
            data-for={`tip-${actual.id}`}
            data-tip={this.renderNameToolTip(actual)}
            data-html
            data-multiline
          >
            {!_.isEmpty(actual.arrival_time_display) && <div>{actual.arrival_time_display}</div>}
            <div>{actual.client_display_name}</div>
            {venue.isNightlifeClass && (
              <div>
                {actual.total_guests && `${actual.total_guests}ppl`}

                {actual.total_guests && actual.min_price_formatted && '-'}
                {actual.min_price_formatted}
              </div>
            )}
          </div>
          {actual.notes && (
            <div
              key="_note"
              style={[VmsIconSizes.xxsmall, FloorplanResCellStyles.icon]}
              data-for={`tip-${actual.id}`}
              data-tip={this.renderNameToolTip(actual)}
              data-html
              data-multiline
            >
              {VmsIcons.NoteLine}
            </div>
          )}
          <div key="_resTags" style={[FloorplanResCellStyles.icon, FloorplanResCellStyles.resTagsIcon]}>
            {this.renderTags(actual.reservation_tags, actual.id)}
          </div>
          {this.renderOccasions(getDateForActual(actual), actual.client_occasions_month_day)}
          <div key="_maxGuests" style={FloorplanResCellStyles.guests}>
            {actual.max_guests}
          </div>

          <ActionBarDiv>
            <div
              key="_table"
              data-for={`tip-${actual.id}`}
              data-tip={
                actual.table_codes_display
                  ? `Table ${actual.table_codes_display}`
                  : isPast
                  ? ''
                  : !venue.isAutoAssignEnabled || isLocked
                  ? 'Unassigned'
                  : 'Auto Assigned'
              }
              style={tableStyle}
              onClick={this.handleTableClick}
            >
              <span>{this.getTablesDisplay(actual, venue)}</span>
            </div>
            <div
              key="_locked"
              data-for={`tip-${actual.id}`}
              data-tip={
                isPast || actual.is_in_service
                  ? `Assigned to ${actual.table_codes_display}`
                  : !isLocked
                  ? 'Click to assign table'
                  : venue.isAutoAssignEnabled
                  ? 'Click to enable auto assign'
                  : 'Click to unassign table'
              }
              style={[VmsIconSizes.xxsmall, FloorplanResCellStyles.icon, lockStyle]}
              onClick={this.handleLockClick}
            >
              {isLocked ? VmsIcons.Lock : VmsIcons.Unlock}
            </div>
          </ActionBarDiv>
        </BodyDiv>
      </div>
    )
  }
}

FloorplanResCell = Radium(FloorplanResCell)

const mapStateToProps = (state, ownProps) => ({
  venue: state.appState.venue,
  date: state.dayviewState.date,
  statusDropDownState: state.statusDropDownState,
  statusesByDb: state.appState.venueSettings.statusesByDb,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    statusDropDownOpened: (actual, boundingRect) => {
      dispatch(statusDropDownOpenedAction(actual, boundingRect))
    },
    statusDropDownClosed: () => {
      dispatch(statusDropDownClosedAction())
    },
    assignTable: (venueId, actualId, tableIds) => {
      dispatch(tryPostSaveResTableAssignmentAction({ venueId, actualId, tableIds }))
    },
    autoAssignTables: (venueId, actualId) => {
      dispatch(
        tryPostSaveResTableAssignmentAction({
          venueId,
          actualId,
          isAutoAssign: true,
        })
      )
    },
    unassignTables: (venueId, actualId) => {
      dispatch(tryPostSaveResTableAssignmentAction({ venueId, actualId }))
    },
    enterTableAssignMode: actual => {
      dispatch(enterTableAssignMode(actual))
    },
  },
})

FloorplanResCell = connect(mapStateToProps, mapDispatchToProps)(FloorplanResCell)

export default FloorplanResCell
