import { srGet, srPost } from '@sevenrooms/core/api'

export const fetchLandingPageSettingsData = venue =>
  Promise.all([srGet(`/api-yoa/venue/landing_page_settings`, { venue: venue.id }), srGet(`/api-yoa/venue_profile`, { venue: venue.id })])
    .then(([landingPageSettings, venueProfile]) => ({ landingPageSettings, venueProfile }))
    .catch(e => {
      console.error('An unexpected error occurred fetching the autotags list', e)

      throw e
    })

export const saveLandingPageSettingsData = (venue, data) =>
  srPost(
    `/api-yoa/venue/landing_page_settings`,
    { venue: venue.id },
    { convertToGetParams: true },
    { 'Content-Type': 'application/json' },
    data
  ).catch(e => {
    console.error('An unexpected error occurred fetching the autotags list', e)

    throw e
  })
