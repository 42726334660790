/* eslint react/prop-types: 0 */
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router'
import styled from 'styled-components'
import VenueGroupNavigation from 'mgr/layout/VenueGroupNav'
import history from 'mgr/pages/shared/utils/History'
import PodsLayout from 'mgr/pages/venue-group/containers/pods/Pods'
import ReportingLayout from 'mgr/pages/venue-group/containers/reporting/Reporting'
import TagsLayout from 'mgr/pages/venue-group/containers/tags/Tags'
import UsersLayout from 'mgr/pages/venue-group/containers/users/Users'

class VenueGroupAppNavigation extends VenueGroupNavigation {
  constructor(props) {
    super(props)
  }
}

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
`

const VenueGroupPortalBodyContainer = styled.div`
  display: block;
  width: 100%;
`

const VenueGroupPortalOuterContainer = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: ${props => props.theme.color.white};
`

class App extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { venueGroup, user, activeSection, hasVenueUsers, match, features } = this.props
    const {
      canAdminPods,
      canAdminGlobalTags,
      canAdminPodTags,
      canAdminUsers,
      canAdminRoles,
      hasAccessGroupReporting,
      canAdminVenueGroupUser,
    } = features
    const venueGroupId = venueGroup.id
    let firstLink
    if (canAdminPods) {
      firstLink = `/group/${venueGroupId}/pods`
    } else if (canAdminGlobalTags || canAdminPodTags) {
      firstLink = `/group/${venueGroupId}/tags`
    } else if (canAdminUsers || canAdminRoles) {
      firstLink = `/group/${venueGroupId}/users`
    } else {
      firstLink = `/group/${venueGroupId}/reporting`
    }
    return (
      <ContentContainer>
        <VenueGroupAppNavigation
          user={user}
          venueGroup={venueGroup}
          venueGroupId={venueGroupId}
          activeSection={activeSection}
          hasVenueUsers={hasVenueUsers}
          isRouterMode
          features={features}
        />
        <VenueGroupPortalBodyContainer>
          <VenueGroupPortalOuterContainer>
            <Switch>
              {canAdminPods && <Route path={`${match.path}/:group_id?/pods`} component={PodsLayout} />}
              {(canAdminGlobalTags || canAdminPodTags) && <Route path={`${match.path}/:group_id?/tags`} component={TagsLayout} />}
              {(canAdminUsers || canAdminVenueGroupUser || canAdminRoles) && (
                <Route path={`${match.path}/:group_id?/users`} component={UsersLayout} />
              )}
              {hasAccessGroupReporting && <Route path={`${match.path}/:group_id?/reporting`} component={ReportingLayout} />}
              <Route path="*">
                <Redirect to={firstLink} />
              </Route>
            </Switch>
          </VenueGroupPortalOuterContainer>
        </VenueGroupPortalBodyContainer>
      </ContentContainer>
    )
  }
}

const mapStateToProps = state => ({
  location: history && history.location,
  user: state.appState.user,
  venueGroup: state.appState.venueGroup,
  hasVenueUsers: state.appState.userDomain.hasVenueUsers,
  activeSection: state.navState.activeSection,
  features: {
    canAdminUsers: state.appState.userDomain.f_user_admin,
    canAdminRoles: state.appState.userDomain.f_role_admin,
    canAdminPods: state.appState.userDomain.f_administrate_pods,
    canAdminGlobalTags:
      state.appState.userDomain.f_administrate_global_reservation_tags || state.appState.userDomain.f_administrate_global_client_tags,
    canAdminGlobalReservationTags: state.appState.userDomain.f_administrate_global_reservation_tags,
    canAdminGlobalClientTags: state.appState.userDomain.f_administrate_global_client_tags,
    canAdminPodTags:
      state.appState.userDomain.f_administrate_pod_reservation_tags || state.appState.userDomain.f_administrate_pod_client_tags,
    canAdminPodReservationTags: state.appState.userDomain.f_administrate_pod_reservation_tags,
    canAdminPodClientTags: state.appState.userDomain.f_administrate_pod_client_tags,
    canAdminVenueGroupUser: state.appState.userDomain.f_administrate_venue_group_user,
    canAdminVenueGroupUserPermission: state.appState.userDomain.f_administrate_venue_group_user_permission,
    hasAccessGroupReporting: state.appState.userDomain.has_access_group_reporting,
  },
})

App = connect(mapStateToProps)(App)

export default withRouter(App)
