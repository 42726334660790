import moment from 'moment-timezone'
import { HeaderTimestamp } from 'mgr/pages/single-venue/settings/components/shared'

interface TimeProps {
  created: moment.Moment
  updated: moment.Moment
}

const getTime = ({ created, updated }: TimeProps) => {
  if (!updated || !created) {
    return '...'
  }

  const updatedMoment = moment(`${updated}Z`)
  const createdMoment = moment(`${created}Z`)
  const lastUpdatedDisplay = updatedMoment.diff(createdMoment, 'seconds') < 5 ? 'Never' : updatedMoment.format('lll')
  return lastUpdatedDisplay
}

function LastUpdated({ updated, created }: TimeProps) {
  const lastUpdatedDisplay = getTime({ updated, created })

  return <HeaderTimestamp>{`Last updated ${lastUpdatedDisplay}`}</HeaderTimestamp>
}

export default LastUpdated
