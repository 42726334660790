import React from 'react'
import styled from 'styled-components'
import { StyledVmsIconXS } from 'svr/common/VmsIcons'

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.containerStyles}
`

const SearchInput = styled.input`
  padding-left: ${props => props.theme.padding.small};
  border: none;
  outline: none;
  width: 100%;
  z-index: 501;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.theme.darkGrey};
    font-size: 14px;
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.darkGrey};
    font-size: 14px;
  }
  ${props => props.inputStyles}
`

const Icon = styled(StyledVmsIconXS)`
  pointer-events: none;
  display: inline-block;
  color: ${props => props.iconColor};
`

export class IconTextInput extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  changeSearchInputHandler(e) {
    this.props.onChange(e.target.value, this.props.name)
  }

  onKeyPressHandler(e) {
    const enterKeyPressed = e.key === 'Enter' || e.charCode === 13
    if (enterKeyPressed && this.props.handleSubmission) {
      this.props.handleSubmission()
    }
  }

  render() {
    const searchInputId = this.props.name ? `sr-search-input-${this.props.name.toLowerCase().replace(/ /g, '_')}` : null
    return (
      <SearchInputWrapper containerStyles={this.props.containerStyles}>
        <Icon iconColor={this.props.iconColor} iconStyles={this.props.iconStyles}>
          {this.props.icon}
        </Icon>
        <SearchInput
          id={searchInputId}
          data-test={searchInputId}
          value={this.props.value}
          placeholder={this.props.placeholderLabel}
          onChange={e => this.changeSearchInputHandler(e)}
          onKeyPress={e => this.onKeyPressHandler(e)}
          inputStyles={this.props.inputStyles}
        />
      </SearchInputWrapper>
    )
  }
}

export default IconTextInput
