import constate from 'constate'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSaveInsightMutation } from '@sevenrooms/core/api'
import { type InsightSaveParameters, InsightStatus, type Insight } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { incrementNewInsightCount, decrementNewInsightCount } from '../components/insightsNewInsightCountSlice'
import { insightsMessages } from '../locales'

export interface OnSaveProps {
  updatedStatus: string
  changeRequested?: boolean
  automatedChangeRequested?: boolean
  resolved?: boolean
}

export function useInsightSuggestedActions({ insight }: { insight: Insight }) {
  const { venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()
  const [displayDeclineReasonSection, setDisplayDeclineReasonSection] = useState(false)
  const [displayChangeRequestSection, setDisplayChangeRequestSection] = useState(false)
  const [declinedReason, setDeclinedReason] = useState<string>()
  const [changeRequestInstructions, setChangeRequestInstructions] = useState<string>()
  const [saveInsight, { isLoading: isSaving }] = useSaveInsightMutation()

  const onSave = useCallback(
    ({ updatedStatus, changeRequested = false, automatedChangeRequested = false, resolved = false }: OnSaveProps) => {
      const changeRequestedDatetime = changeRequested ? new Date().toISOString() : undefined
      const automatedChangeRequestedDatetime = automatedChangeRequested ? new Date().toISOString() : undefined
      const resolvedDatetime = resolved ? new Date().toISOString() : undefined
      const insightParameters: InsightSaveParameters = {
        status: updatedStatus,
        declinedReason,
        changeRequestInstructions,
        changeRequestedDatetime,
        automatedChangeRequestedDatetime,
        resolvedDatetime,
      }
      const insightId = insight.id
      saveInsight({ venueId, insightId, insightParameters })
        .unwrap()
        .then(() => {
          if (insight.status !== InsightStatus.NEW && updatedStatus === InsightStatus.NEW) {
            dispatch(incrementNewInsightCount())
          } else if (insight.status === InsightStatus.NEW && updatedStatus !== InsightStatus.NEW) {
            dispatch(decrementNewInsightCount())
          }
        })
        .catch(() => {
          notify({ content: formatMessage(insightsMessages.insightSaveErrorMessage), type: 'error' })
        })
    },
    [saveInsight, insight, venueId, formatMessage, declinedReason, dispatch, changeRequestInstructions]
  )

  const onSubmitDecline = useCallback(() => {
    onSave({ updatedStatus: InsightStatus.DECLINED })
    setDeclinedReason(undefined)
    setDisplayDeclineReasonSection(false)
  }, [onSave, setDeclinedReason, setDisplayDeclineReasonSection])

  const onChangeRequest = useCallback(() => {
    onSave({ updatedStatus: InsightStatus.TRACKING, changeRequested: true })
    setDeclinedReason(undefined)
    setDisplayChangeRequestSection(false)
  }, [onSave, setDeclinedReason, setDisplayChangeRequestSection])

  const onResolved = useCallback(() => {
    onSave({ updatedStatus: InsightStatus.TRACKING, changeRequested: true, resolved: true })
    setChangeRequestInstructions(undefined)
  }, [onSave, setChangeRequestInstructions])

  return {
    insight,
    displayDeclineReasonSection,
    displayChangeRequestSection,
    declinedReason,
    changeRequestInstructions,
    isSaving,
    setDisplayDeclineReasonSection,
    setDisplayChangeRequestSection,
    setDeclinedReason,
    setChangeRequestInstructions,
    onSave,
    onSubmitDecline,
    onChangeRequest,
    onResolved,
  }
}

export const [InsightSuggestedActionsContext, useInsightSuggestedActionsContext] = constate(useInsightSuggestedActions)
