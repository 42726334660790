import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import { formatDateOnly } from 'mgr/lib/utils/MomentUtils'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, DateRangePicker, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import type { ExcludedDateRange } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface ExcludedDateRangeModalProps {
  excludedDateRange?: ExcludedDateRange
  onCancel: () => void
  onSave: (excludedDateRange: ExcludedDateRange) => void
  onSaveAndAddNew: (excludedDateRange: ExcludedDateRange) => void
}

export function ExcludedDateRangeModal({ excludedDateRange, onCancel, onSave, onSaveAndAddNew }: ExcludedDateRangeModalProps) {
  const [dateRangeFrom, setDateRangeFrom] = useState(excludedDateRange?.from)
  const [dateRangeTo, setDateRangeTo] = useState(excludedDateRange?.to)
  const isFormValid = useMemo(() => dateRangeFrom && dateRangeTo, [dateRangeFrom, dateRangeTo])
  const [notes, setNotes] = useState(excludedDateRange?.notes || '')

  const saveAndClose = useCallback(() => {
    if (!dateRangeFrom || !dateRangeTo) {
      return
    }
    onSave({
      from: dateRangeFrom,
      to: dateRangeTo,
      notes,
    })
  }, [onSave, dateRangeFrom, dateRangeTo, notes])

  const saveAndAddNew = useCallback(() => {
    if (!dateRangeFrom || !dateRangeTo) {
      return
    }
    onSaveAndAddNew({
      from: dateRangeFrom,
      to: dateRangeTo,
      notes,
    })
    setDateRangeTo(undefined)
    setDateRangeFrom(undefined)
    setNotes('')
  }, [onSaveAndAddNew, dateRangeFrom, dateRangeTo, notes])

  const onSelectDateRangeFrom = useCallback(
    (value: Date | null) => {
      if (!value) {
        setDateRangeFrom(undefined)
        return
      }
      const dateRangeFromValue = formatDateOnly(moment(value))
      setDateRangeFrom(dateRangeFromValue)
      if (!dateRangeTo || dateRangeTo.isBefore(dateRangeFromValue)) {
        setDateRangeTo(dateRangeFromValue)
      }
    },
    [setDateRangeFrom, setDateRangeTo, dateRangeTo]
  )

  const onSelectDateRangeTo = useCallback(
    (value: Date | null) => {
      if (!value) {
        setDateRangeTo(undefined)
        return
      }
      const dateRangeToValue = formatDateOnly(moment(value))
      setDateRangeTo(dateRangeToValue)
      if (!dateRangeFrom || dateRangeToValue.isBefore(dateRangeFrom)) {
        setDateRangeFrom(dateRangeToValue)
      }
    },
    [setDateRangeFrom, setDateRangeTo, dateRangeFrom]
  )

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onCancel()}>
        <ModalTitle
          title={excludedDateRange ? 'Edit Excluded Date(s)' : 'Add Excluded Dates'}
          subTitle="This menu will not be available on the selected dates."
        />
      </ModalHeader>
      <ModalBody>
        <Box>
          <Label primary="Date Range*">
            <DateRangePicker
              startDate={dateRangeFrom?.toDate()}
              endDate={dateRangeTo?.toDate()}
              id="excludedDateRange-Range"
              onStartDateChange={(startDate: Date | null) => onSelectDateRangeFrom(startDate)}
              onEndDateChange={(endDate: Date | 'infinite' | null) => endDate !== 'infinite' && onSelectDateRangeTo(endDate)}
              zIndexLayer="modal"
            />
          </Label>
        </Box>
        <Box pt="m">
          <Label primary="Notes" secondary="This is optional.">
            <Input name="excludedDateRangeNotes" value={notes} onChange={event => setNotes(event.target.value)} />
          </Label>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onCancel} data-test="sr-excluded-date-ranges-modal-cancel">
            Cancel
          </Button>
          {!excludedDateRange && (
            <Button
              variant="secondary"
              disabled={!isFormValid}
              onClick={saveAndAddNew}
              data-test="sr-excluded-date-ranges-modal-save-and-new"
            >
              Add and Create Another
            </Button>
          )}
          <Button variant="primary" disabled={!isFormValid} onClick={saveAndClose} data-test="sr-excluded-date-ranges-modal-save">
            {excludedDateRange ? 'Save Changes' : 'Add Dates'}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
