import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import VmsIcons, { BorderedVmsIcon, customIcon } from 'svr/common/VmsIcons'

const iconSize = 22

const GoToNowContainer = styled.div`
  position: relative;
  margin-right: 9px;
`

const GoToNowIcon = styled(BorderedVmsIcon)`
  opacity: ${props => props.isDisabled && 0.3};
  pointer-events: ${props => props.isDisabled && 'none'};
`

const GoToIcon = customIcon(`${iconSize}px`)

export const findCurrentShift = (shifts, venue, currTime = moment().tz(venue.timezone).format('HH:mm:ss')) => {
  const startOfDay = moment({ hour: venue.startOfDayHour }).format('HH:mm:ss')

  // no shift found
  if (_.isEmpty(shifts)) {
    return null
  }

  // only one shift exists
  if (shifts.length === 1) {
    return shifts[0]
  }
  // before the start of the first shift
  if (isBetween(currTime, startOfDay, shifts[0].start_time)) {
    return shifts[0]
  }

  for (let i = 0; i < shifts.length; i++) {
    const start = shifts[i].start_time
    const end = shifts[i].end_time
    // corresponding shift exists
    if (isBetween(currTime, start, end)) {
      return shifts[i]
    }
    // current time is in between two shifts
    if (i < shifts.length - 1 && isBetween(currTime, end, shifts[i + 1].start_time)) {
      return shifts[i + 1]
    }
  }

  // current time is after the last shift
  if (isBetween(currTime, shifts[shifts.length - 1].end_time, startOfDay)) {
    return shifts[shifts.length - 1]
  }
}

export const isBetween = (curr, start, end) => {
  if (start < end) {
    return start <= curr && curr < end
  }
  // contains midnight
  return !(end <= curr && curr < start)
}

const GoToNowButton = ({ onClickHandler, isDisabled }) => (
  <GoToNowContainer data-test="sr-button-go_to_now">
    <GoToNowIcon onClick={onClickHandler} isDisabled={isDisabled}>
      <GoToIcon>{VmsIcons.GoToNow}</GoToIcon>
    </GoToNowIcon>
  </GoToNowContainer>
)

export default GoToNowButton
