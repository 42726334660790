import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import * as DeliveryIntegrationActions from 'mgr/pages/single-venue/settings/actions/ordering/DeliveryIntegrationActions'
import * as OrkestroFields from 'mgr/pages/single-venue/settings/components/ordering/OrkestroFields'
import {
  BookingChannelContainer,
  LargeChannelImg,
  FormContainer,
  FieldRow,
  FieldCol,
  HorizontalLine,
  Header,
  HeaderTitle,
  HeaderButtons,
  DeactivateButton,
  EditButton,
  SaveButton,
  CancelButton,
  ErrorDialogWrapper,
  ErrorIcon,
  ErrorBody,
  ErrorDismiss,
} from 'mgr/pages/single-venue/settings/components/shared'
import VmsIcons from 'svr/common/VmsIcons'

class OrkestroDeliveryIntegrationSettings extends PureComponent {
  constructor(props) {
    super(props)
    this.validateFieldMap = {}
    this.onSaveClickHandler = this.onSaveClickHandler.bind(this)
  }

  onSaveClickHandler() {
    const { actions, isLoading } = this.props
    if (isLoading) {
      return
    }
    const formErrors = _.reduce(
      this.validateFieldMap,
      (invalidFields, input, field) => {
        const invalidText = !_.isNil(input) && ((input.props && input.props.disabled) || input.isValid())
        return _.assign(invalidFields, invalidText !== true && invalidText !== false && { [field]: invalidText })
      },
      {}
    )

    actions.formValidated(formErrors)

    if (_.isEmpty(formErrors)) {
      actions.saveOrkestroIntegrationSettings()
    }
  }

  render() {
    const { orkestroIntegrationSettings, isOrkestroActiveIntegration, actions } = this.props

    if (_.isNil(orkestroIntegrationSettings)) {
      return null
    }
    const { selectEditOrkestroIntegration, deactivateOrkestroIntegration } = actions

    return (
      <BookingChannelContainer>
        <Header>
          <LargeChannelImg path="integrations/orkestro-logo.png" />
          <HeaderTitle data-test="sr-orkestro-header">Orkestro</HeaderTitle>
          <HeaderButtons>
            {!isOrkestroActiveIntegration && [
              <EditButton onClick={() => selectEditOrkestroIntegration()} key="EditButton" data-test="sr-orkestro-edit-or-configure-button">
                {orkestroIntegrationSettings.enabled ? 'Edit' : 'Configure'}
              </EditButton>,
              orkestroIntegrationSettings.enabled && (
                <DeactivateButton
                  onClick={() => deactivateOrkestroIntegration()}
                  key="DeactivateButton"
                  data-test="sr-orkestro-deactivate-button"
                >
                  Deactivate
                </DeactivateButton>
              ),
            ]}
          </HeaderButtons>
        </Header>
        {isOrkestroActiveIntegration && this.renderEditOrkestroForm()}
      </BookingChannelContainer>
    )
  }

  renderEditOrkestroForm() {
    const { orkestroIntegrationSettings, actions, formErrors, isLoading } = this.props
    const { changeOrkestroField, cancelDeliveryIntegrationEdit } = actions
    const { validateFieldMap } = this
    const fieldArgs = {
      orkestroIntegrationSettings,
      changeOrkestroField,
      formErrors,
      validateFieldMap,
    }
    return (
      <>
        <HorizontalLine key="HorizontalLine" />
        <FormContainer key="FormContainer">
          <FieldRow>
            <FieldCol xs={12} md={6} data-test="sr-orkestro-configure-apikey-input">
              {OrkestroFields.apiKeyField(fieldArgs)}
            </FieldCol>
          </FieldRow>
          <FieldRow>
            <FieldCol xs={12} md={12} data-test="sr-orkestro-configure-pickup-instr-input">
              {OrkestroFields.pickupInstructionsField(fieldArgs)}
            </FieldCol>
          </FieldRow>
          {this.renderErrorDialog()}
          <FieldRow>
            <FieldCol xs={12} md={12}>
              <SaveButton
                onClick={this.onSaveClickHandler}
                disabled={isLoading}
                key="SaveButton"
                data-test="sr-orkestro-configure-save-button"
              >
                {isLoading ? <PulsatingDots /> : 'Enable Integration'}
              </SaveButton>
              <CancelButton
                onClick={() => cancelDeliveryIntegrationEdit()}
                disabled={isLoading}
                key="CancelButton"
                data-test="sr-orkestro-configure-cancel-button"
              >
                Cancel
              </CancelButton>
            </FieldCol>
          </FieldRow>
        </FormContainer>
      </>
    )
  }

  renderErrorDialog() {
    const { actions, formErrors, didDismissErrorDisplay } = this.props
    const formErrorMessages = _.compact(_.values(formErrors)).sort()
    const isVisible = !didDismissErrorDisplay && !_.isEmpty(formErrorMessages)
    return (
      isVisible && (
        <ErrorDialogWrapper>
          <ErrorIcon>{VmsIcons.WarningLine}</ErrorIcon>
          <ErrorBody>
            {formErrorMessages.map(e => (
              <li key={e}>
                {e}
                <br />
              </li>
            ))}
          </ErrorBody>
          <ErrorDismiss onClick={actions.dismissErrorDisplay}>{VmsIcons.Close}</ErrorDismiss>
        </ErrorDialogWrapper>
      )
    )
  }
}

const mapStateToProps = state => ({
  isOrkestroActiveIntegration: state.deliveryIntegrations.activeIntegrationKey === DeliveryIntegrationActions.INTEGRATION_KEY_ORKESTRO,
  orkestroIntegrationSettings: state.deliveryIntegrations.integrationSettings[DeliveryIntegrationActions.INTEGRATION_KEY_ORKESTRO],
  formErrors: state.deliveryIntegrations.formErrors,
  isLoading: state.deliveryIntegrations.isLoading,
  didDismissErrorDisplay: state.deliveryIntegrations.didDismissErrorDisplay,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      selectEditOrkestroIntegration: _.partial(
        DeliveryIntegrationActions.selectDeliveryIntegrationEdit,
        DeliveryIntegrationActions.INTEGRATION_KEY_ORKESTRO
      ),
      deactivateOrkestroIntegration: _.partial(
        DeliveryIntegrationActions.tryDeactivateDeliveryIntegration,
        DeliveryIntegrationActions.INTEGRATION_KEY_ORKESTRO
      ),
      cancelDeliveryIntegrationEdit: DeliveryIntegrationActions.cancelDeliveryIntegrationEdit,
      changeOrkestroField: _.partial(
        DeliveryIntegrationActions.changeDeliveryIntegrationField,
        DeliveryIntegrationActions.INTEGRATION_KEY_ORKESTRO
      ),
      formValidated: DeliveryIntegrationActions.deliveryIntegrationFormValidated,
      dismissErrorDisplay: DeliveryIntegrationActions.deliveryIntegrationDismissErrorDisplay,
      saveOrkestroIntegrationSettings: _.partial(
        DeliveryIntegrationActions.trySaveDeliveryIntegrationSettings,
        DeliveryIntegrationActions.INTEGRATION_KEY_ORKESTRO
      ),
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrkestroDeliveryIntegrationSettings)
