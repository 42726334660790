import _ from 'lodash'
import Radium from 'radium'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  addNewReservationAction,
  addNewBlockAction,
  showBlockAction,
  showAccessRuleAction,
  addDropDownClosedAction,
  statusDropDownClosedAction,
  tryGetAccessRuleAction,
} from 'mgr/pages/single-venue/dayview/actions/Actions'
import { CommonStyles, GridStyles, DayViewStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import DisableScreen from 'mgr/pages/single-venue/dayview/components/DisableScreen'
import GridConflictDialog from 'mgr/pages/single-venue/dayview/components/grid/GridConflictDialog'
import GridDropDown from 'mgr/pages/single-venue/dayview/components/grid/GridDropdown'
import GridMoveMode from 'mgr/pages/single-venue/dayview/components/grid/GridMoveMode'
import GridStatusDropDown from 'mgr/pages/single-venue/dayview/components/grid/GridStatusDropdown'

class GridActionsContainer extends PureComponent {
  handleClickOutside = () => {
    if (this.props.addDropDownState.isOpen) {
      this.props.actions.addDropDownClosed()
    }
    if (this.props.statusDropDownState.isOpen) {
      this.props.actions.statusDropDownClosed()
    }
  }

  handleClickAddReservation = () => {
    this.props.actions.addNewReservation(this.props.date, this.props.addDropDownState.rowEntity, this.props.addDropDownState.order)
    this.props.actions.addDropDownClosed()
  }

  handleClickAddBlock = () => {
    this.props.actions.addNewBlock(this.props.date, this.props.addDropDownState.rowEntity, this.props.addDropDownState.order)
    this.props.actions.addDropDownClosed()
  }

  handleClickEditBlock = () => {
    this.props.actions.showBlock(this.props.addDropDownState.blockOrAccessRule)
    this.props.actions.addDropDownClosed()
  }

  handleEditAccessRuleClick = () => {
    const { id } = this.props.addDropDownState.blockOrAccessRule
    const { date, venueId } = this.props
    this.props.actions.tryGetAccessRule(id, venueId, date.format('YYYY-MM-DD'))
    this.props.actions.addDropDownClosed()
  }

  render() {
    const {
      highlightBarShowing,
      addDropDownState,
      statusDropDownState,
      conflictDialogState,
      isLoading,
      isActualMoveMode,
      useDisableScreen,
      venueId,
      permissions,
    } = this.props
    const styles = {
      hoverBox: {
        position: 'absolute',
        width: GridStyles.TimeHeaderWidth - 6,
        height: GridStyles.ReservationRowHeight - 6,
        top: 3,
        left: 3,
        borderRadius: '5px',
        ':hover': {
          backgroundColor: addDropDownState.isBookable ? CommonStyles.ButtonPrimaryColor : '#ccc',
        },
      },
      dropdown: {
        position: 'absolute',
        top: 3,
        left: 3,
      },
    }
    const dropdownTop = _.get(this.props, 'addDropDownState.boundingRect.top', 0)
    const statusTop = _.get(this.props, 'statusDropDownState.boundingRect.top', 0)
    const dropdownTopPosition = highlightBarShowing
      ? dropdownTop - GridStyles.TimeHeaderWidth + addDropDownState.scrollY + DayViewStyles.NotesHighlightBarHeight + 7 // margins, borders adjust
      : dropdownTop + addDropDownState.scrollY + 3
    const statusTopPosition = highlightBarShowing
      ? statusTop - GridStyles.ReservationRowHeight + statusDropDownState.scrollY + DayViewStyles.NotesHighlightBarHeight + 8
      : statusTop + GridStyles.ReservationRowHeight + statusDropDownState.scrollY

    const addDropDownPositioning = addDropDownState.isOpen
      ? {
          left: addDropDownState.boundingRect.left - 77, // TODO: make it not magic
          top: dropdownTopPosition,
        }
      : {}
    const addDropdownStyles = addDropDownState.isOpen ? styles.hoverBox : styles.dropdown

    const statusDropDownPositioning = statusDropDownState.isOpen
      ? {
          left: statusDropDownState.boundingRect.left - 77, // TODO: make it not magic
          top: statusTopPosition,
        }
      : {}

    return (
      <div>
        {conflictDialogState.isOpen && (
          <GridConflictDialog
            explanation={conflictDialogState.explanation}
            actionButtonText={conflictDialogState.actionButtonText}
            conflictActual={conflictDialogState.conflictActual}
            closeAction={conflictDialogState.closeAction}
            nextAction={conflictDialogState.nextAction}
          />
        )}

        {isLoading && useDisableScreen && <DisableScreen />}

        {isActualMoveMode && <GridMoveMode highlightBarShowing={highlightBarShowing} />}

        <div style={[addDropdownStyles, addDropDownPositioning]}>
          {addDropDownState.isOpen && (
            <GridDropDown
              isBookable={addDropDownState.isBookable}
              onAddReservationClick={this.handleClickAddReservation}
              onAddBlockClick={this.handleClickAddBlock}
              onEditBlockClick={this.handleClickEditBlock}
              onEditAccessRuleClick={this.handleEditAccessRuleClick}
              onClickOutside={this.handleClickOutside}
              dropDownType={addDropDownState.dropDownType}
              permissions={permissions}
            />
          )}
        </div>

        <div style={[styles.dropdown, statusDropDownPositioning]}>
          {statusDropDownState.isOpen && (
            <GridStatusDropDown venueId={venueId} actualId={statusDropDownState.actual.id} onClickOutside={this.handleClickOutside} />
          )}
        </div>
      </div>
    )
  }
}

const mapGridActionsStateToProps = state => ({
  date: state.dayviewState.date,
  venueId: state.appState.venue.id,
  addDropDownState: state.addDropDownState,
  statusDropDownState: state.statusDropDownState,
  isLoading: state.loadingState.isLoading,
  useDisableScreen: state.loadingState.useDisableScreen,
  conflictDialogState: state.conflictDialogState,
  permissions: state.appState.venue.permissions,
  isActualMoveMode: state.gridState.isActualMoveMode,
})

const mapGridActionsDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addDropDownClosed: addDropDownClosedAction,
      statusDropDownClosed: statusDropDownClosedAction,
      addNewReservation: addNewReservationAction,
      addNewBlock: addNewBlockAction,
      showBlock: showBlockAction,
      showAccessRule: showAccessRuleAction,
      tryGetAccessRule: tryGetAccessRuleAction,
    },
    dispatch
  ),
})

GridActionsContainer = Radium(GridActionsContainer)
GridActionsContainer = connect(mapGridActionsStateToProps, mapGridActionsDispatchToProps)(GridActionsContainer)

export default GridActionsContainer
