import React from 'react'
import Input from 'svr/component-lib/Generic/TextInputs/Input'

const CurrencyInput = ({ value, onChange, isValid, currencySymbol, testId, width = 150 }) => {
  const setValue = Math.floor(Math.round(value) * 10) / 1000
  return (
    <Input
      data-test={testId}
      width={width}
      value={setValue}
      isValid={isValid}
      onChange={newValue => {
        const currencyValue = newValue * 100
        onChange(currencyValue)
      }}
      type="number"
      min={0}
      step={0.01}
      prefix={currencySymbol}
    />
  )
}

export default CurrencyInput
