import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import { isValidURL } from 'mgr/lib/utils/Url'
import { toggleLendingPageSlideout, addButton, editButton } from 'mgr/pages/single-venue/marketing/actions/LandingPageSettings'
import { CustomAutotagSearchDropdownSingle } from 'mgr/pages/single-venue/marketing/components/auto-tags/custom-auto-tags/CustomAutotagSearchDropdownSingle'
import { DisplayArea } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import { ActionButton } from 'mgr/pages/single-venue/marketing/components/shared/ActionButton'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'

const displayOptions = {
  primary: 'primary',
  secondary: 'secondary',
}

export const buttonTypes = {
  widget: 'widget',
  customLink: 'customLink',
  contactUs: 'contactUs',
  menu: 'menu',
}

export const buttonTypesNames = {
  [buttonTypes.contactUs]: 'Contact Us',
  [buttonTypes.customLink]: 'Custom Link',
  [buttonTypes.menu]: 'Menu',
  [buttonTypes.widget]: 'Widget',
}

export const widgetActions = {
  reservations: 'reservations',
  waitlist: 'waitlist',
  events: 'events',
}

export const widgetActionsNames = {
  [widgetActions.reservations]: 'Reservation Widget',
  [widgetActions.waitlist]: 'Waitlist Widget',
  [widgetActions.events]: 'Events Widget',
}

export const contactUsActions = {
  call: 'call',
  email: 'email',
}

export const contactUsActionsNames = {
  [contactUsActions.call]: 'Call',
  [contactUsActions.email]: 'Email',
}

const actions = {
  [buttonTypes.widget]: [
    {
      key: widgetActions.reservations,
      displayText: widgetActionsNames[widgetActions.reservations],
    },
    {
      key: widgetActions.waitlist,
      displayText: widgetActionsNames[widgetActions.waitlist],
    },
    {
      key: widgetActions.events,
      displayText: widgetActionsNames[widgetActions.events],
    },
  ],
  [buttonTypes.contactUs]: [
    {
      key: contactUsActions.call,
      displayText: contactUsActionsNames[contactUsActions.call],
    },
    {
      key: contactUsActions.email,
      displayText: contactUsActionsNames[contactUsActions.email],
    },
  ],
}

const initialData = {
  id: undefined,
  name: '',
  buttonType: undefined,
  action: undefined,
  customInput: '',
  display: displayOptions.primary,
  invalid: {},
}

class LandingPageButtonSlideout extends React.Component {
  state = initialData

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialState.id !== this.state.id) {
      this.setState(nextProps.initialState)
    }
  }

  validate() {
    const invalid = {
      name: !this.state.name,
      buttonType: !this.state.buttonType,
    }

    if (this.state.buttonType === buttonTypes.customLink) {
      invalid.customInput = !isValidURL(this.state.customInput)
    } else if (this.state.buttonType !== buttonTypes.menu) {
      invalid.action = !this.state.action
    }

    this.setState({ invalid })

    const allChecksResults = Object.values(invalid)
    const res = allChecksResults.every(v => v === false)
    return res
  }

  submit = () => {
    const isValid = this.validate()

    if (!isValid) {
      return
    }

    const newButton = {
      id: this.state.id,
      button_text: this.state.name,
      type: this.state.buttonType,
      primary: this.state.display === displayOptions.primary,
      action: this.state.buttonType === buttonTypes.menu ? undefined : this.state.action,
      custom_link: this.state.buttonType === buttonTypes.menu ? undefined : this.state.customInput,
    }

    if (this.props.isEditMode) {
      this.props.editButton(newButton)
    } else {
      this.props.addButton(newButton)
    }

    this.setState(initialData)
  }

  render() {
    return (
      <SlideoutComponent
        bodyCSS={css`
          display: flex;
          flex-flow: column;
        `}
        isSlideoutOpen={this.props.isSlideoutOpen}
        onCloseHandler={() => {
          this.setState(initialData)
          this.props.toggleLendingPageSlideout()
        }}
        title={<SlideoutTitle>{this.props.isEditMode ? 'Edit button' : 'Add new button'}</SlideoutTitle>}
        maxWidth="500px"
      >
        <LandingPageDisplayArea>
          <InnerContent>
            <ActiveLabelTextInput
              value={this.state.name}
              onChange={name => this.setState({ name })}
              placeholderLabel="Button text"
              inputId="sr-lp-btn-text"
              isValid={!this.state.invalid.name}
            />
          </InnerContent>

          <InnerContent>
            <InnerContentLabel>Links to</InnerContentLabel>
            <LandingPageSlideoutDropdown
              placeholder="Select Button Type"
              selectedValue={this.state.buttonType}
              isValid={!this.state.invalid.buttonType}
              data-test-id="sr-lp-btn-type"
              onChange={buttonType => this.setState({ buttonType, action: undefined })}
              options={[
                {
                  key: buttonTypes.widget,
                  displayText: buttonTypesNames[buttonTypes.widget],
                },
                {
                  key: buttonTypes.customLink,
                  displayText: buttonTypesNames[buttonTypes.customLink],
                },
                {
                  key: buttonTypes.contactUs,
                  displayText: buttonTypesNames[buttonTypes.contactUs],
                },
                {
                  key: buttonTypes.menu,
                  displayText: buttonTypesNames[buttonTypes.menu],
                },
              ]}
            />
          </InnerContent>

          <InnerContent>
            <InnerContentLabel>Action</InnerContentLabel>
            {this.state.buttonType === buttonTypes.customLink || this.state.buttonType === buttonTypes.menu ? (
              <ActiveLabelTextInput
                value={this.state.buttonType === buttonTypes.menu ? this.props.venueProfile.menu_link : this.state.customInput}
                inputId="sr-lp-custom-input"
                isValid={!this.state.invalid.customInput}
                labelForInvalid={this.state.buttonType === buttonTypes.customLink && 'Invalid Link'}
                disabled={this.state.buttonType === buttonTypes.menu}
                onChange={customInput => this.setState({ customInput })}
                placeholderLabel={
                  this.state.buttonType === buttonTypes.menu
                    ? this.props.venueProfile.menu_link
                      ? ''
                      : 'No Menu URL configured'
                    : 'Custom link URL'
                }
              />
            ) : (
              <LandingPageSlideoutDropdown
                key={this.state.buttonType}
                placeholder="Select Button Action"
                id="sr-lp-btn-action"
                isValid={!this.state.invalid.action}
                selectedValue={this.state.action}
                onChange={action => this.setState({ action })}
                options={actions[this.state.buttonType] || []}
              />
            )}
          </InnerContent>

          <InnerContent>
            <RadioList
              onClick={display => this.setState({ display })}
              listLabel={<InnerContentLabel>Display option</InnerContentLabel>}
              selectedValue={this.state.display}
              options={[
                { value: displayOptions.primary, label: 'Primary Button' },
                { value: displayOptions.secondary, label: 'Secondary Button' },
              ]}
            />
          </InnerContent>
        </LandingPageDisplayArea>

        <ActionArea>
          <CreateButton id="sr-create-button" onClick={this.submit}>
            {this.props.isEditMode ? 'Update' : 'Create'}
          </CreateButton>
        </ActionArea>
      </SlideoutComponent>
    )
  }
}

const mapStateToProps = state => {
  const initialState = { ...initialData }
  const allButtons = state.landingPageSettings.data.buttons.primary.concat(state.landingPageSettings.data.buttons.secondary)

  const toEdit = allButtons.find(b => b.id === state.landingPageSettings.editButtonId)

  if (toEdit) {
    initialState.id = toEdit.id
    initialState.name = toEdit.button_text
    initialState.buttonType = toEdit.type
    initialState.action = toEdit.action
    initialState.customInput = toEdit.custom_link
    initialState.display = toEdit.primary ? displayOptions.primary : displayOptions.secondary
  } else {
    initialState.id = `${Math.random()}_${allButtons.length}`
  }

  return {
    isSlideoutOpen: state.landingPageSettings.isSlideoutOpen,
    isEditMode: Boolean(toEdit),
    venueProfile: state.landingPageSettings.venueProfile,
    initialState,
  }
}

const mapDispatchToProps = {
  toggleLendingPageSlideout,
  addButton,
  editButton,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageButtonSlideout)

function LandingPageSlideoutDropdown(props) {
  return (
    <CustomAutotagSearchDropdownSingle
      {...props}
      dropdownIcon={<DropdownIcon>{VmsIcons.ChevronDown}</DropdownIcon>}
      dropdownListCss={css`
        overflow: hidden;
      `}
      valueBoxCss={css`
        height: 100%;
        border: none;
      `}
      caretCss={css`
        top: 15px;
      `}
      dropdownSelectTextCss={css`
        color: #888c92;
      `}
      customWrapper={css`
        height: 60px;
        width: 100%;
        border-style: solid;
        border-color: ${props.isValid ? '#dbdcde' : 'red'};
        border-radius: 4px;
        border-width: 1px;
      `}
    />
  )
}

const LandingPageDisplayArea = styled(DisplayArea)`
  overflow: auto;
`

const DropdownIcon = styled(StyledIcons.S)`
  transform: rotate(-90deg);
  font-size: 24px;
`

const SlideoutTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  height: 42px;
  line-height: 42px;
  margin-left: 20px;
`

const InnerContent = styled.div`
  padding: 18px;
`

const InnerContentLabel = styled.span`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #888c92;
`

export const ActionArea = styled.div`
  min-height: 90px;
  display: flex;
  background-color: white;
  justify-content: flex-end;
  align-items: center;
  padding-right: 36px;
`

const CreateButton = styled(ActionButton)`
  background: ${props => props.theme.primary};
`
