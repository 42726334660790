import { useState, useEffect, useMemo, type Dispatch, type SetStateAction } from 'react'
import type { Summary } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { Label, InfoButton } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { DateFilterEnum } from '../../constants'
import { reportingMessages } from '../../locales/reportingMessages'
import { formatBold, formatParagraph, formatCurrency } from '../../utils'

interface RevenueHeaderProps {
  filter: DateFilterEnum | undefined
  setFilter: Dispatch<SetStateAction<DateFilterEnum | undefined>>
  summary: Summary | undefined
}

const LEARN_LINK = 'https://help.sevenrooms.com/hc/en-us/articles/28103130130459-Revenue-Reporting-Beta#h_01J4S41T252DWWT91991ZJ769K'

export function RevenueHeader({ filter, setFilter, summary }: RevenueHeaderProps) {
  const { formatMessage } = useLocales()
  const labelText = (
    <Text textDisplay="block" textAlign="right" color="secondaryFont">
      {formatMessage(reportingMessages.dateFilterLabel)}
    </Text>
  )
  const [titleText, setTitleText] = useState<string | null>(formatMessage(reportingMessages.filterLastMonth))

  const { venue } = useVenueContext()

  const earnings = summary ? summary.revenueEarned : 0
  const earnedPercentage = summary ? summary.revenueEarned / (summary.revenueEarned + summary.benchmarkMissed) : 0
  const percentageText = `${Math.floor(earnedPercentage * 100)}%`
  const [dollars, cents] = formatCurrency(venue, earnings).split('.')

  const dateFilterOptions = useMemo(
    () => [
      {
        id: DateFilterEnum.THIS_MONTH,
        label: formatMessage(reportingMessages.filterThisMonth),
        titleText: formatMessage(reportingMessages.titleTextThisMonth),
      },
      {
        id: DateFilterEnum.LAST_MONTH,
        label: formatMessage(reportingMessages.filterLastMonth),
        titleText: formatMessage(reportingMessages.titleTextLastMonth),
      },
      {
        id: DateFilterEnum.LAST_3_MONTHS,
        label: formatMessage(reportingMessages.filter3Month),
        titleText: formatMessage(reportingMessages.titleText3Month),
      },
      {
        id: DateFilterEnum.LAST_6_MONTHS,
        label: formatMessage(reportingMessages.filter6Month),
        titleText: formatMessage(reportingMessages.titleText6Month),
      },
      {
        id: DateFilterEnum.YEAR_TO_DATE,
        label: formatMessage(reportingMessages.filterYearToDate),
        titleText: formatMessage(reportingMessages.titleTextYearToDate),
      },
      {
        id: DateFilterEnum.LAST_YEAR,
        label: formatMessage(reportingMessages.filterLastYear),
        titleText: formatMessage(reportingMessages.titleTextLastYear),
      },
      {
        id: DateFilterEnum.ALL,
        label: formatMessage(reportingMessages.filterAll),
        titleText: formatMessage(reportingMessages.titleTextAll),
      },
    ],
    [formatMessage]
  )

  const { replace } = useNavigation()

  useEffect(() => {
    const text = dateFilterOptions.filter(obj => obj.id === filter)[0]?.titleText
    if (text) {
      setTitleText(text)
    }
  }, [setTitleText, dateFilterOptions, filter])

  const updateFilter = (value: DateFilterEnum) => {
    setFilter(value)
    replace(routes.manager2.reporting.revenue, { params: { venueKey: venue.urlKey }, query: { filter: value } })
  }

  return (
    <Box
      data-test="revenue-dashboard-header"
      borderWidth="l"
      borderTopColor="secondaryBackground"
      backgroundColor="primaryBackground"
      pt="xxl"
      pl="xl"
      pr="xl"
    >
      <HStack>
        <Box mr="auto">
          <HStack>
            <Box mt="xs">
              <Text fontSize="3xl" fontWeight="bold">
                {formatMessage(reportingMessages.youveEarned)}
              </Text>
            </Box>
            <Box mt="xs" ml="s">
              <Text fontSize="3xl" fontWeight="bold" color="success">
                {dollars}
              </Text>
            </Box>
            {cents && (
              <Box>
                <Text color="success" fontWeight="bold">
                  .{cents}
                </Text>
              </Box>
            )}
          </HStack>
          <Box mt="m" mb="s">
            <Text fontSize="xxl" color="secondaryFont">
              {formatMessage(reportingMessages.fromSevenRooms)} {titleText}.
            </Text>
          </Box>
        </Box>
        <Box>
          <Label primary={labelText}>
            <Select
              id="date-filter"
              data-test="date-filter-selector"
              options={dateFilterOptions}
              value={filter}
              searchable={false}
              onChange={updateFilter}
            />
          </Label>
        </Box>
      </HStack>
      <Box mt="m" mb="lm" backgroundColor="attentionNew" borderRadius="s" overflow="hidden">
        <Box data-test="revenue-percentage-bar" height="15px" width={percentageText} backgroundColor="success" />
      </Box>
      <HStack pb="m">
        <Box mt="xxs" mr="xs" borderRadius="s" backgroundColor="success" width="12px" height="12px" />
        <Box mr="m">
          <Text color="secondaryFont">{formatMessage(reportingMessages.revenueEarned)}</Text>
        </Box>
        <Box mt="xxs" mr="xs" borderRadius="s" backgroundColor="attentionNew" width="12px" height="12px" />
        <Box mr="auto">
          <Text color="secondaryFont">{formatMessage(reportingMessages.missedOpportunity)}</Text>
        </Box>
        <Box>
          <Text color="secondaryFont">{formatMessage(reportingMessages.howWeCalculateTip)}</Text>
          <Text width="24px" textDisplay="inline-block" textAlign="right">
            <InfoButton
              data-test="revenue-how-we-calculate-tip"
              tooltip={{
                content: (
                  <>
                    {formatMessage(reportingMessages.calculationTip, {
                      p: (chunks: string[]) => formatParagraph(chunks),
                      strong: (chunks: string[]) => formatBold(chunks),
                      link: (chunks: string[]) => (
                        <Link to={LEARN_LINK} isExternal target="_blank" data-test="learn-more-calculation-link">
                          {chunks}
                        </Link>
                      ),
                    })}
                  </>
                ),
                displayAction: 'click',
              }}
            />
          </Text>
        </Box>
      </HStack>
    </Box>
  )
}
