import React from 'react'
import { connect } from 'react-redux'
import { updateNavState } from 'mgr/lib/actions/NavActions'
import { VenueGroupNavSections, VenueGroupSubNavSections } from 'mgr/pages/shared/utils/Constants'
import { tryLoadPodConfigs } from 'mgr/pages/venue-group/actions/Pods'
import {
  openAddTagSlideout,
  openEditTagSlideout,
  tryFetchReservationTagCategories,
  tryEnableTagCategory,
  tryDisableTagCategory,
  tryDeleteTagCategory,
  toggleDeleteWarning,
  toggleDisableWarning,
  tryFetchFollowers,
} from 'mgr/pages/venue-group/actions/Tags'
import { addTagCategoryButton } from 'mgr/pages/venue-group/containers/tags/AddTagCategoryButton'
import { TagCategoryContainer, TagCategoryWrapper, TagsContent } from 'mgr/pages/venue-group/containers/tags/Tags.styles'
import { WarningMessage } from 'mgr/pages/venue-group/containers/tags/WarningMessage'
import { VmsIcons } from 'svr/common/VmsIcons'
import TagCategory from 'svr/component-lib/Manager/Containers/TagCategory'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { groupTagIsEditable } from './utils'

class ReservationTagsLayout extends React.PureComponent {
  constructor(props) {
    super(props)
    this.openTagSlideoutHandler = this.openTagSlideoutHandler.bind(this)
  }

  componentDidMount() {
    const { venueGroup, updateNavState, tryFetchReservationTagCategories, tryFetchFollowers, tryLoadPodConfigs } = this.props
    updateNavState(VenueGroupNavSections.TAGS, VenueGroupSubNavSections.RESERVATION_TAGS)
    tryFetchReservationTagCategories(venueGroup)
    tryFetchFollowers(venueGroup.id)
    tryLoadPodConfigs(venueGroup)
  }

  openTagSlideoutHandler() {
    const tagCategoryClassification = this.props.userDomain.f_administrate_global_reservation_tags ? 'GLOBAL' : 'POD'
    this.props.openAddTagSlideout(true, tagCategoryClassification, '', '#e21d3e')
  }

  render() {
    const { venueGroup, userDomain, tagCategories, showDeleteWarning, showDisableWarning } = this.props

    return (
      <ContentLayout
        title="Group Reservation Tags"
        venueGroup={venueGroup}
        userDomain={userDomain}
        Actions={
          (userDomain.f_administrate_global_reservation_tags || userDomain.f_administrate_pod_reservation_tags) &&
          addTagCategoryButton(this.openTagSlideoutHandler)
        }
      >
        <TagsContent>
          <TagCategoryContainer>
            {tagCategories.map(tagCategory => (
              <TagCategoryWrapper key={`category-wrapper-${tagCategory.id}`}>
                <TagCategory
                  key={tagCategory.id}
                  hexColor={tagCategory.color_hex}
                  isDefaultCategory={tagCategory.is_default}
                  isDisabled={!!tagCategory.disabled}
                  isRestricted={!!tagCategory.is_restricted}
                  isVip={!!tagCategory.vip}
                  isGlobal={tagCategory.is_global}
                  superuserVisibleOnly={tagCategory.privacy === 'PRIVATE'}
                  showReservation={tagCategory.show_reservation}
                  showChit={!!tagCategory.show_chit}
                  className="pod-tag-category"
                  categoryId={tagCategory.id}
                  categoryName={tagCategory.name}
                  categoryNameDisplay={tagCategory.name_display}
                  categoryIcon={tagCategory.is_pod_tag ? VmsIcons.SiteMapLine : VmsIcons.SafariLine}
                  isPodTag={tagCategory.is_pod_tag}
                  pods={tagCategory.pods}
                  userCanAdministrateTagCategory={groupTagIsEditable(
                    tagCategory.is_pod_tag,
                    tagCategory.is_global,
                    userDomain.f_administrate_pod_reservation_tags,
                    userDomain.f_administrate_global_reservation_tags
                  )}
                  restrictedIcon={VmsIcons.Lock}
                  onEditClick={this.props.openEditTagSlideout}
                  onEnableClick={this.props.tryEnableTagCategory}
                  onDisableClick={this.props.toggleDisableWarning}
                  onDeleteClick={this.props.toggleDeleteWarning}
                  tagNames={tagCategory.tags}
                  tagNameDisplays={tagCategory.tag_name_displays}
                />
              </TagCategoryWrapper>
            ))}
          </TagCategoryContainer>
        </TagsContent>
        {showDeleteWarning && <WarningMessage isDelete proceed={this.props.tryDeleteTagCategory} cancel={this.props.toggleDeleteWarning} />}
        {showDisableWarning && (
          <WarningMessage isDelete={false} proceed={this.props.tryDisableTagCategory} cancel={this.props.toggleDisableWarning} />
        )}
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => ({
  venueGroup: state.appState.venueGroup,
  userDomain: state.appState.userDomain,
  tagCategories: state.tags.tagCategories,
  showDeleteWarning: state.tags.showDeleteWarning,
  showDisableWarning: state.tags.showDisableWarning,
})

const mapDispatchToProps = {
  updateNavState,
  openAddTagSlideout,
  openEditTagSlideout,
  tryFetchReservationTagCategories,
  tryEnableTagCategory,
  tryDisableTagCategory,
  tryDeleteTagCategory,
  toggleDeleteWarning,
  toggleDisableWarning,
  tryFetchFollowers,
  tryLoadPodConfigs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationTagsLayout)
