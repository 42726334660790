import React from 'react'
import { DayViewInnerContainer } from 'mgr/pages/single-venue/dayview'
import DayviewToolbar from 'mgr/pages/single-venue/dayview/containers/DayviewToolbar'
import Floorplan from 'mgr/pages/single-venue/dayview/containers/Floorplan'

class FloorplanLayout extends React.PureComponent {
  render() {
    return (
      <div>
        <DayviewToolbar history={this.props.history} key="dayViewToolBar" />
        <DayViewInnerContainer extraMargin={false}>
          <Floorplan />
        </DayViewInnerContainer>
      </div>
    )
  }
}

export default FloorplanLayout
