import { srGet, srPut } from '@sevenrooms/core/api'

export const fetchBookingChannelSettings = (params, errHandler) =>
  srGet(`/api-yoa/booking_channels`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(response => {
    if (response.error && typeof errHandler === 'function') {
      errHandler(response)
    } else if (response.status !== 200) {
      throw new Error(response)
    }

    return response
  })

export const saveBookingChannelSettings = ({ venueId, channelKey, bookingChannelSettings }) => {
  const path = `/api-yoa/booking_channels/${channelKey}`
  return srPut(path, {
    booking_channel_settings: JSON.stringify(bookingChannelSettings),
    venue_id: venueId,
  }).then(response => {
    if (response.error) {
      throw response.errorMsg || 'Failed to save'
    }
    return response.data || {}
  })
}
