import _ from 'lodash'
import { type FormEvent, useMemo, useState } from 'react'
import { PRODUCT_TYPES_DISPLAY_NAMES } from 'mgr/pages/single-venue/settings/constants'
import { Select, SelectFilter, SelectTarget } from '@sevenrooms/core/ui-kit/core'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { useDebounceEffect } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, Tab, TabPanel, TabList, Tabs, VStack } from '@sevenrooms/core/ui-kit/layout'
import type { EIGHTY_SIX_PRODUCT_TYPES } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

const PRODUCT_CHOICES = [
  {
    label: PRODUCT_TYPES_DISPLAY_NAMES.ALL,
    id: 'ALL',
  },
  {
    label: PRODUCT_TYPES_DISPLAY_NAMES.MENU_ITEM,
    id: 'MENU_ITEM',
  },
  {
    label: PRODUCT_TYPES_DISPLAY_NAMES.MODIFIER,
    id: 'MODIFIER',
  },
] as const

export interface TabType {
  id: string
  text: string
}

export interface ProductControlsProps {
  tabs: TabType[]
  selectedTabId: string
  onSelectedTabChange: (id: string) => void
  onProductFilterSearchChange: (value: string) => void
  productTypeFilter?: EIGHTY_SIX_PRODUCT_TYPES
  onProductTypeFilterChange?: (value: EIGHTY_SIX_PRODUCT_TYPES) => void
}

function ProductControls({
  tabs,
  selectedTabId,
  onSelectedTabChange,
  onProductFilterSearchChange,
  productTypeFilter,
  onProductTypeFilterChange,
}: ProductControlsProps) {
  const [value, setValue] = useState('')
  const [productType, setProductType] = useState(productTypeFilter ?? PRODUCT_CHOICES[0].id)
  const selectedTabIndex = useMemo(() => _.findIndex(tabs, { id: selectedTabId }), [selectedTabId, tabs])
  useDebounceEffect(() => onProductFilterSearchChange(value), 200, [value])

  return (
    <VStack>
      <Tabs onSelect={(index: number) => onSelectedTabChange(tabs[index]?.id as string)} selectedIndex={selectedTabIndex}>
        <TabList>
          {tabs.map(tab => (
            <Tab key={tab.id} data-test={tab.id}>
              {tab.text}
            </Tab>
          ))}
        </TabList>
        {tabs.map(tab => (
          <TabPanel key={tab.id} />
        ))}
      </Tabs>
      <HStack justifyContent="space-between" alignItems="flex-end" pt="lm">
        <Box>
          {!_.isNil(productTypeFilter) && (
            <Label primary="Product Type">
              <Select
                data-test="sr-products-select-product-type"
                options={PRODUCT_CHOICES}
                value={productType}
                searchable={false}
                onChange={value => {
                  setProductType(value)
                  onProductTypeFilterChange?.(value)
                }}
              />
            </Label>
          )}
        </Box>
        <SelectTarget data-test="sr-products-search-bar" width="448px">
          <SelectFilter
            placeholder="Search by product name or identifier/PLU"
            selectedValue={value || null}
            searchable
            onChange={(event: FormEvent<HTMLInputElement>) => {
              setValue(event.currentTarget.value)
            }}
          />
        </SelectTarget>
      </HStack>
    </VStack>
  )
}

export default ProductControls
