import React, { useState } from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { type DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { AccordionDetails } from '@sevenrooms/react-components/components/AccordionDetails'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesMatchCategoryMessages } from '../../../locales'
import { groupReasonsByAccessRule } from './accessRuleCategoryUtil'
import { AccordionContent } from './AccordionContent'
import { getAccessRuleUrl } from './urlRedirectUtil'

interface AccessRulesMatchCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  defaultExpanded?: boolean
  covers: number
  date: DateOnly
  time: TimeOnly
  venue: Venue
  audiences: Record<string, string>
}

interface AccessRulesMatchDetailsProps {
  accessRuleId: string
  reason: string
  reasons: AvailabilityDebuggerReason[]
  covers: number
  date: DateOnly
  time: TimeOnly
  venue: Venue
  audiences: Record<string, string>
}

function AccessRulesMatchDetails({ accessRuleId, reason, reasons, covers, date, time, venue, audiences }: AccessRulesMatchDetailsProps) {
  const { formatMessage } = useLocales()
  // We are returning an array of reasons, but we are only interested in the first one because all reasons are the same
  // except for the tableItemCode returned At this point, we don't care about the tables
  const mainReason = reasons[0]
  const accessRuleName = mainReason?.data?.accessRuleName

  const formattedHeader = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionHeader, {
    ruleName: accessRuleName,
    a: (chunks: string[]) =>
      accessRuleId ? (
        <Link data-test="access-rule-chunks" href={getAccessRuleUrl(venue, date, accessRuleId)} target="_blank">
          {chunks}
        </Link>
      ) : (
        <>{chunks}</>
      ),
  })
  let formattedARDetails: string

  switch (reason) {
    case 'NO_INVENTORY_RESERVATIONS_REMAINING':
      formattedARDetails = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNoInventoryReservations, {
        time: time.formatSTime(),
      })
      break
    case 'NO_INVENTORY_COVERS_REMAINING':
      formattedARDetails = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNoInventoryCovers, {
        count: covers,
        time: time.formatSTime(),
        inventoryRemaining: 0,
      })
      break
    case 'CUTOFF_PASSED':
      formattedARDetails = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionCutoffPassed, {
        cutoffNum: mainReason?.data?.bookingCutoffNum,
        cutoffType: mainReason?.data?.bookingCutoffType,
        cutoffHour: mainReason?.data?.bookingCutoffHour
          ? TimeOnly.from(mainReason?.data?.bookingCutoffHour).formatSTime()
          : 'RESERVATION_TIME',
      })
      break
    case 'HELD_BY_ACCESS_RULE':
      formattedARDetails = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionHeldByAccessRule, {
        ruleName: mainReason?.data?.accessRuleName,
        table: mainReason?.data?.tableItemCode,
      })
      break
    case 'NOT_REACHED_BOOKING_START_TIME':
      formattedARDetails = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNotReachedBookingStartTime, {
        startNum: mainReason?.data?.tierStartNum,
        startType: mainReason?.data?.tierStartType,
        startHour: mainReason?.data?.tierStartHour ? TimeOnly.from(mainReason?.data?.tierStartHour).formatSTime() : 'RESERVATION_TIME',
        audience: mainReason?.data?.audience ? audiences?.[mainReason?.data?.audience] : '',
      })
      break
    case 'NO_PACING_COVERS_REMAINING':
      formattedARDetails = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNoPacingCoversRemaining, {
        count: covers,
        covers,
        time: time.formatSTime(),
      })
      break
    case 'TAGS_MISMATCH':
      formattedARDetails = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionTagsMismatch, {
        audience: mainReason?.data?.audience ? audiences?.[mainReason?.data?.audience] : '',
      })
      break
    default:
      return <></>
  }

  return (
    <Box sx={{ '&:not(:last-child)': { pb: 2 } }}>
      <Typography fontWeight="medium">{formattedHeader}</Typography>
      <Typography key={`${accessRuleId}-${reason}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
        {formattedARDetails}
      </Typography>
    </Box>
  )
}

export function AccessRulesMatchCategory({
  reasons,
  defaultExpanded = true,
  venue,
  covers,
  date,
  time,
  audiences,
}: AccessRulesMatchCategoryProps) {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  const { formatMessage } = useLocales()

  const groupedReasonsByAr = groupReasonsByAccessRule(reasons)
  const headerMessage = formatMessage(accessRulesMatchCategoryMessages.accordianHeader, {
    count: Object.keys(groupedReasonsByAr).length,
    numArs: Object.keys(groupedReasonsByAr).length,
  })

  return (
    <AccordionContent
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      headerMessage={headerMessage}
      dataTestId="access-rules-match-category-content"
      actionButtonEnabled={false}
    >
      <AccordionDetails id="access-rules-match-panel-content">
        {groupedReasonsByAr.map(({ accessRuleId, reasonCode, reasons }) => (
          <AccessRulesMatchDetails
            key={`${accessRuleId}-${reasonCode}`}
            accessRuleId={accessRuleId}
            reason={reasonCode}
            reasons={reasons}
            date={date}
            time={time}
            venue={venue}
            covers={covers}
            audiences={audiences}
          />
        ))}
      </AccordionDetails>
    </AccordionContent>
  )
}
