import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'

// todo fix z index layers
const NotesHighlightContainer = styled.div`
  display: flex;
  position: fixed;
  width: calc(100% - ${ManagerStyles.NavigationWidth}px);
  box-sizing: border-box;
  background-color: ${props => props.theme.color.white};
  padding: ${props => props.theme.gutter.double};
  border-top: 2px solid ${props => props.theme.color.greyMuted};
  border-bottom: 2px solid ${props => props.theme.color.greyMuted};
  z-index: 54;
`

const Text = styled.div`
  margin-left: ${props => props.theme.gutter.standard};
  font-size: ${props => props.theme.fontSize.body};
  font-family: ${props => props.theme.fontFamily};
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

// min-width 0: https://css-tricks.com/flexbox-truncated-text/
const Highlight = styled.div`
  display: flex;
  min-width: 0;
`

const HighlightNote = styled(Highlight)`
  ${props => props.hasSiblings && 'flex: 1;'};
`

const HighlightEvent = styled(Highlight)`
  ${props => props.hasSiblings && 'width: 25%;'};
`

const NotesHighlightBar = ({ note, events }) => {
  const hasEvents = !!events.length
  const hasNoteAndEvent = note && hasEvents
  const highlightedEvent = hasEvents && events[0]

  return (
    <NotesHighlightContainer>
      {hasEvents && (
        <HighlightEvent hasSiblings={hasNoteAndEvent}>
          <StyledVmsIconS>{VmsIcons.Event}</StyledVmsIconS>
          <Text>{`${highlightedEvent.title} - ${highlightedEvent.description}`}</Text>
        </HighlightEvent>
      )}

      {!!note && (
        <HighlightNote hasSiblings={hasNoteAndEvent}>
          <StyledVmsIconS>{VmsIcons.Note}</StyledVmsIconS>
          <Text data-test="sr-day-view-note">{note}</Text>
        </HighlightNote>
      )}
    </NotesHighlightContainer>
  )
}

NotesHighlightBar.propTypes = {
  note: React.PropTypes.string,
  events: React.PropTypes.array,
}

export default NotesHighlightBar
