import { useLocales } from '@sevenrooms/core/locales'
import { Icon, type IconType } from '@sevenrooms/core/ui-kit/icons'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { variantTitle, variantColor, variantShortTitle, variantIcon } from '../../utils'

export interface StatusBadgeProps {
  variant?: string
  displayCompact?: boolean
}

export function StatusBadge({ variant = 'new', displayCompact }: StatusBadgeProps) {
  const { formatMessage } = useLocales()
  const message = displayCompact ? variantShortTitle[variant] : variantTitle[variant]
  const text = message ? formatMessage(message) : ''
  const color = variantColor[variant]
  const icon = variantIcon[variant]
  const name = `VMSWeb-insight-${icon}` as IconType

  return (
    <Box pt="xxs" pr="s" pb="xxs" pl={displayCompact ? 's' : 'xxs'} display="inline" borderRadius="s" backgroundColor={color}>
      {displayCompact !== true && <Icon name={name} size="lg" color="primaryBackground" />}
      <Text color="primaryBackground">{text}</Text>
    </Box>
  )
}
