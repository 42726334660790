import React from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'

const DropperWrapper = styled.div`
  background: #f5f5f5 url(${window.globalInit.mediaUrl}images/baseline_cloud_upload_black_18dp.png) 50% 50% no-repeat;
  border: 1px dashed #ccc;
  width: 90%;
  height: 60%;
  margin: auto;
  position: relative;
`

const DropperText = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  text-align: center;
  pointer-events: none;
`

const ResChannelFileDropzone = ({ onDrop, isUploadingFile, exampleFileName }) => (
  <DropperWrapper>
    <DropperText>
      {isUploadingFile ? (
        'Uploading...'
      ) : (
        <span>
          Upload a reservation CSV file by dragging it here.
          <br />
          Your file should be named like "{exampleFileName}"
        </span>
      )}
    </DropperText>
    {isUploadingFile || (
      <Dropzone
        style={{
          cursor: 'pointer',
          height: '100%',
          width: '100%',
        }}
        onDrop={onDrop}
        multiple={false}
      />
    )}
  </DropperWrapper>
)

export default ResChannelFileDropzone
