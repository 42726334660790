import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import FloorplanLayoutDropDown from 'mgr/lib/components/FloorplanLayoutDropDown'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import * as FloorplanActions from 'mgr/pages/single-venue/dayview/actions/FloorplanActions'
import { DayViewStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import FloorplanRenderer from 'mgr/pages/single-venue/dayview/components/floorplan/FloorplanRenderer'
import FloorplanResCell from 'mgr/pages/single-venue/dayview/components/floorplan/FloorplanResCell'
import FloorplanRoomSelectBar from 'mgr/pages/single-venue/dayview/components/floorplan/FloorplanRoomSelectBar'
import GridActionsContainer from 'mgr/pages/single-venue/dayview/containers/GridActionsContainer'
import { selectNoteAndEvents } from 'mgr/pages/single-venue/dayview/selectors/DayViewSelectors'
import { selectFloorplanRoomPositions, selectSortedActuals } from 'mgr/pages/single-venue/dayview/selectors/FloorplanSelectors'

const FloorplanContainer = styled.div`
  background-color: ${props => props.theme.color.white};
`

const ReservationList = styled.div`
  width: 300px;
  min-height: calc(100vh - 62px);
`

const FloorplanViewPanel = styled.div`
  position: fixed;
  top: ${props => 62 + (props.extraMargin ? DayViewStyles.NotesHighlightBarHeight : 0)}px;
  left: 380px;
  right: 0;
  bottom: 0;
  background: #222;
`

const FloorplanViewPanelHeader = styled.div`
  font-size: 15px;
  padding: 0 5px;
  background-color: ${props => props.theme.color.grey};
  z-index: 5;
`

const FloorplanSelectedTablesDiv = styled.div`
  padding: 5px 0;
  color: #fff;
  font-weight: 800;
`

const ClearFixDiv = styled.div`
  ${props => props.theme.clearFix};
`

const FloorplanCanvasContainer = styled.div``

const ResListSectionHeader = styled.div`
  height: 42px;
  line-height: 42px;
  background-color: ${props => props.theme.color.secondary};
  padding: 0 8px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SaveSelectedTablesButton = styled.button`
  ${props => props.theme.primaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
  margin: 5px;
  width: 290px;
`

const CloseButton = styled.div`
  color: ${props => props.theme.color.primary};
  cursor: pointer;
  font-size: 25px;
  width: 30px;
  text-align: center;
`

class Floorplan extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      venue,
      date,
      actuals,
      dayFloorPlan,
      actions,
      noteAndEvents,
      selectedFloorplanRoom,
      floorplanRoomPositions,
      assignModeActual,
      selectedTablePositions,
      selectedDisplayTablePosition,
      isInTableAssignMode,
    } = this.props
    const { tableItems, floorplanRooms, floorplanLayoutConfigs, activeFloorplanLayoutConfig } = dayFloorPlan
    const { hasNoteOrEvent } = noteAndEvents
    const { tablePositions, shapePositions, floorplanMaxXY } = floorplanRoomPositions
    const backgroundImageUrl =
      selectedFloorplanRoom && !activeFloorplanLayoutConfig.uses_grid ? `/.h/download/${selectedFloorplanRoom.site_image_dict.raw}` : null
    const initialScaleFactor = (selectedFloorplanRoom && selectedFloorplanRoom.site_scale_factor) || 0.4
    const onFloorplanRoomChangeHandler = selectedFloorplanRoom => {
      actions.changeSelectedFloorplanRoom(selectedFloorplanRoom)
      this.floorplanRenderer.resetPosition()
    }
    const selectedLayoutId = activeFloorplanLayoutConfig.id
    const isInDisplayTableMode = !_.isNil(selectedDisplayTablePosition)
    const getActualsOnSelectedTable = () =>
      _.filter(
        actuals,
        actual =>
          !_.isEmpty(actual.computedTableList) &&
          _.includes(
            actual.computedTableList.map(t => t.id),
            selectedDisplayTablePosition.id
          )
      )
    const renderReservationCell = actual => (
      <FloorplanResCell key={actual.id} venueId={venue.id} actual={actual} rowEntity={0} floorplanActions={actions} isUnassigned={false} />
    )
    return (
      <FloorplanContainer>
        <ReservationList>
          {isInTableAssignMode && (
            <ResListSectionHeader>
              <span>Assign Tables</span>
              <CloseButton onClick={actions.closeTableCard}>&times;</CloseButton>
            </ResListSectionHeader>
          )}
          {isInDisplayTableMode && (
            <ResListSectionHeader>
              <span>Table {selectedDisplayTablePosition.item_code}</span>
              <CloseButton onClick={actions.closeTableCard}>&times;</CloseButton>
            </ResListSectionHeader>
          )}
          {isInTableAssignMode
            ? renderReservationCell(assignModeActual)
            : isInDisplayTableMode
            ? _.map(getActualsOnSelectedTable(), renderReservationCell)
            : _.map(actuals, renderReservationCell)}

          {isInTableAssignMode && <SaveSelectedTablesButton onClick={actions.saveSelectedTableAssignments}>Save</SaveSelectedTablesButton>}
        </ReservationList>
        <FloorplanViewPanel extraMargin={hasNoteOrEvent}>
          <FloorplanViewPanelHeader>
            {_.size(floorplanRooms) > 1 && (
              <FloorplanRoomSelectBar onChangeHandler={onFloorplanRoomChangeHandler} {...{ floorplanRooms, selectedFloorplanRoom }} />
            )}
            {venue.isNightlifeClass &&
              venue.features.can_activate_floorplan_layout &&
              !isInTableAssignMode &&
              _.size(floorplanLayoutConfigs) > 1 && (
                <FloorplanLayoutDropDown
                  label={`LAYOUT - ${date.format('MMM D').toUpperCase()}`}
                  style={{ float: 'right', margin: '5px 0' }}
                  onChangeFloorplanLayout={actions.changeSelectedFloorplanLayout}
                  {...{ floorplanLayoutConfigs, selectedLayoutId }}
                />
              )}
            <ClearFixDiv />
            {isInTableAssignMode && (
              <FloorplanSelectedTablesDiv>
                Selected Tables: {selectedTablePositions.map(tp => tp.item_code).join(', ')}
              </FloorplanSelectedTablesDiv>
            )}
          </FloorplanViewPanelHeader>
          <FloorplanCanvasContainer>
            <FloorplanRenderer
              ref={r => (this.floorplanRenderer = r)}
              onTablePositionClick={actions.clickTablePosition}
              selectedTablePositions={
                isInTableAssignMode ? selectedTablePositions : isInDisplayTableMode ? [selectedDisplayTablePosition] : []
              }
              {...{
                initialScaleFactor,
                tablePositions,
                shapePositions,
                floorplanMaxXY,
                backgroundImageUrl,
              }}
            />
          </FloorplanCanvasContainer>
        </FloorplanViewPanel>
        <GridActionsContainer highlightBarShowing={hasNoteOrEvent} />
      </FloorplanContainer>
    )
  }
}

const mapStateToProps = state => ({
  venue: state.appState.venue,
  actuals: selectSortedActuals(state),
  date: state.dayviewState.date,
  dayFloorPlan: state.dayviewState.dayFloorPlan,
  selectedFloorplanRoom: state.floorplanState.selectedFloorplanRoom,
  floorplanRoomPositions: selectFloorplanRoomPositions(state),
  assignModeActual: state.floorplanState.assignModeActual,
  selectedTablePositions: state.floorplanState.selectedTablePositions,
  selectedDisplayTablePosition: state.floorplanState.selectedDisplayTablePosition,
  isInTableAssignMode: state.floorplanState.isInTableAssignMode,
  noteAndEvents: selectNoteAndEvents(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showActual: Actions.showActualAction,
      showBlock: Actions.showBlockAction,
      showAccessRule: Actions.showAccessRuleAction,
      showInfoMessage: GlobalActions.showInfoMessage,
      showSuccessMessage: GlobalActions.showSuccessMessage,
      showWarnMessage: GlobalActions.showWarnMessage,
      showErrorMessage: GlobalActions.showErrorMessage,
      clickTablePosition: FloorplanActions.clickTablePosition,
      closeTableCard: FloorplanActions.closeTableCard,
      changeSelectedFloorplanRoom: FloorplanActions.changeSelectedFloorplanRoom,
      changeSelectedFloorplanLayout: FloorplanActions.changeSelectedFloorplanLayout,
      saveSelectedTableAssignments: FloorplanActions.saveSelectedTableAssignments,
    },
    dispatch
  ),
})

Floorplan = connect(mapStateToProps, mapDispatchToProps)(Floorplan)

export default Floorplan
