import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'
import { DisplayArea, SlideoutContent } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import { openOutgoingEmail } from 'mgr/pages/single-venue/reporting/actions/OutgoingEmails'
import {
  PrimaryEmailInfo,
  SecondaryEmailInfo,
  EmailSubject,
  EmailTimestamp,
  EmailContent,
  EmailDivider,
} from 'mgr/pages/single-venue/reporting/components/IndividualOutgoingEmail'
import OutgoingEmailSlideout from 'mgr/pages/single-venue/reporting/containers/OutgoingEmailSlideout'

class ShowOutgoingEmail extends React.PureComponent {
  keydown_listener = event => {
    if (event.keyCode === 27) {
      this.props.openOutgoingEmail(null)
    }
  }

  componentDidMount() {
    window.document.body.addEventListener('keydown', this.keydown_listener)
  }

  componentWillUnmount() {
    window.document.body.removeEventListener('keydown', this.keydown_listener)
  }

  render() {
    const { email } = this.props

    const date = moment(email.local_date_time, 'MM-DD-YYYY HH:mm:ss').format('MMM Do YYYY, h:mm a')
    const sent_or_queued = email.message_id ? 'Sent' : 'Queued'

    return (
      <OutgoingEmailSlideout>
        <SlideoutContent>
          <DisplayArea>
            <PrimaryEmailInfo>
              <EmailSubject data-test="sr-outgoing-email-subject">{email.subject}</EmailSubject>
              <EmailTimestamp>
                {sent_or_queued} on {date}
              </EmailTimestamp>
            </PrimaryEmailInfo>
            <SecondaryEmailInfo>
              From: {email.from_name} | To: {email.recipients_formatted}
            </SecondaryEmailInfo>
            <EmailDivider />
            <EmailContent data-test="sr-outgoing-email-body" dangerouslySetInnerHTML={{ __html: email.body }} />
          </DisplayArea>
        </SlideoutContent>
      </OutgoingEmailSlideout>
    )
  }
}

const mapStateToProps = state => ({
  email: state.outgoingEmails.data.selected || {},
  venueName: state.appState.venue.name,
})

const mapDispatchToProps = {
  openOutgoingEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowOutgoingEmail)
