import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { DragDropContext, Droppable, type DroppableProvided, type DropResult } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { CategoryMenuItem } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/CategoryMenuItem'
import { CategoryPositionDropdown } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/CategoryPositionDropdown'
import { useToggle } from 'svr/common/hooks/useToggle'
import { IconButton, Button, SimplePopover } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import type { MenuCategory } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

type Columns = Record<ColumnName, MenuItem[]>
export enum ColumnName {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface CategoryProps {
  venue: Venue
  category: MenuCategory
  menuItems: MenuItem[]
  position: number
  categoriesCount: number
  onCategoryOrderChanged: (categoryName: string, position: number) => void
  onCategoryEdit: (category: MenuCategory) => void
  onCategoryDelete: (categoryName: string) => void
  onCategoryMenuItemDelete: (category: MenuCategory, menuItem: MenuItem) => void
  onCategoryMenuItemsUpdate: (category: MenuCategory, menuItems: MenuItem[]) => void
  onAddMenuItemModalOpen: (category: MenuCategory) => void
  isVisible: boolean
}

export function Category({
  venue,
  category,
  menuItems,
  position,
  categoriesCount,
  onCategoryOrderChanged,
  onCategoryDelete,
  onCategoryEdit,
  onCategoryMenuItemDelete,
  onCategoryMenuItemsUpdate,
  onAddMenuItemModalOpen,
  isVisible,
}: CategoryProps) {
  const [isExpanded, toggleExpanded] = useToggle(true)

  // there is limitation in library that we used for drag and drop (not working properly with flex wrap items)
  // https://github.com/atlassian/react-beautiful-dnd/issues/316
  // That's why building columns manually
  const columns: Columns = useMemo(() => {
    const columns: Columns = {
      [ColumnName.LEFT]: [],
      [ColumnName.RIGHT]: [],
    }
    const categoryItems = category.items.map(item => _.find(menuItems, { id: item.id })).filter(item => item) as MenuItem[]
    categoryItems.forEach((item, index) => columns[index % 2 === 0 ? ColumnName.LEFT : ColumnName.RIGHT].push(item))
    return columns
  }, [category.items, menuItems])

  const onMenuItemMove = useCallback(
    (dropResult: DropResult) => {
      const { source, destination } = dropResult
      if (!destination) {
        return
      }
      const sourceColumnName = source.droppableId as ColumnName
      const destinationColumnName = destination.droppableId as ColumnName
      const updatedColumns: Columns = {
        [ColumnName.LEFT]: [...columns[ColumnName.LEFT]],
        [ColumnName.RIGHT]: [...columns[ColumnName.RIGHT]],
      }
      const draggedMenuItem = _.find(updatedColumns[sourceColumnName], (_item, index: number) => index === source.index) as MenuItem
      updatedColumns[sourceColumnName].splice(source.index, 1)
      updatedColumns[destinationColumnName].splice(destination.index, 0, draggedMenuItem)
      const items = _.range(0, Math.max(updatedColumns[ColumnName.LEFT].length, updatedColumns[ColumnName.RIGHT].length)).reduce(
        (accumulator: MenuItem[], index) => {
          Object.keys(columns).forEach(position => {
            const menuItem = updatedColumns[position as ColumnName][index]
            if (menuItem) {
              accumulator.push(menuItem)
            }
          })
          return accumulator
        },
        []
      )
      onCategoryMenuItemsUpdate(category, items)
    },
    [category, columns, onCategoryMenuItemsUpdate]
  )

  return (
    <CategoryWrapper>
      <CategoryHeader p="lm">
        <HStack alignItems="center" justifyContent="flex-start" spacing="m">
          <CategoryPositionDropdown
            category={category}
            position={position}
            categoriesCount={categoriesCount}
            onCategoryOrderChanged={onCategoryOrderChanged}
          />
          <CategoryNameWrapper alignItems="center">
            <Header type="h3">
              {category.name} ({category.items.length})
            </Header>
            <EditCategoryButton borderType="none-round" size="s" icon="VMSWeb-edit" onClick={() => onCategoryEdit(category)} />
          </CategoryNameWrapper>
        </HStack>
        <HStack alignItems="center" justifyContent="flex-end" spacing="m">
          <Button data-test="add-menu-item-button" variant="tertiary" icon="VMSWeb-add" onClick={() => onAddMenuItemModalOpen(category)}>
            Add Menu Item
          </Button>
          <SimplePopover data-test={`menu-builder-category-menu-${category.name}`}>
            <SimplePopover.PopoverItem
              key="menu-build-category-edit"
              text="Edit Name"
              iconName="VMSWeb-edit"
              onClick={() => onCategoryEdit(category)}
              data-test={`menu-builder-category-menu-edit-${category.name}`}
            />
            <SimplePopover.PopoverItem
              key="menu-build-category-delete"
              text="Delete"
              iconName="VMSWeb-trash"
              onClick={() => onCategoryDelete(category.name)}
              data-test={`menu-builder-category-menu-delete-${category.name}`}
            />
          </SimplePopover>
          <IconButton
            borderType="none-round"
            size="s"
            icon={isExpanded ? 'VMSWeb-chevron-up' : 'VMSWeb-chevron-down'}
            onClick={toggleExpanded}
          />
        </HStack>
      </CategoryHeader>
      {isExpanded && (
        <CategoryContent pl="lm" pr="lm">
          {category.items.length ? (
            <DragDropContext onDragEnd={onMenuItemMove}>
              <HStack spacing="lm">
                {Object.keys(columns).map(position => (
                  <Droppable key={position} droppableId={position}>
                    {(provided: DroppableProvided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <VStack flexWrap="wrap" justifyContent="space-between">
                          {columns[position as ColumnName].map((menuItem, index) => (
                            <CategoryMenuItem
                              key={menuItem.id}
                              index={index}
                              venue={venue}
                              menuItem={menuItem}
                              categoryName={category.name}
                              isVisible={isVisible}
                              onDelete={(menuItem: MenuItem) => onCategoryMenuItemDelete(category, menuItem)}
                            />
                          ))}
                        </VStack>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </HStack>
            </DragDropContext>
          ) : (
            <Box pb="lm">
              <SecondaryText>There are currently no menu items in here</SecondaryText>
            </Box>
          )}
        </CategoryContent>
      )}
    </CategoryWrapper>
  )
}

const EditCategoryButton = styled(IconButton)``
const CategoryNameWrapper = styled(HStack)``

const CategoryWrapper = styled(Box)`
  background-color: #f7f7f7;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
`

const CategoryHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #f7f7f7;

  ${CategoryNameWrapper} {
    ${EditCategoryButton} {
      visibility: hidden;
    }
    &:hover {
      ${EditCategoryButton} {
        visibility: visible;
      }
    }
  }
`

const CategoryContent = styled(Box)``
