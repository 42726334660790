import { VmsIcons } from 'svr/common/VmsIcons'
import ActionButton from 'svr/component-lib/Manager/Buttons/ActionButton'

export const addTagCategoryButton = (onClickHandler: () => void) => (
  <ActionButton
    onClickHandler={onClickHandler}
    className="add-tag-category"
    actionText="Add Tag Category"
    actionIcon={VmsIcons.Plus}
    backgroundColor="#347baf"
  />
)
