import _ from 'lodash'
import moment from 'moment/moment'
import { useMemo } from 'react'
import { TimeRange } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/AvailableTimes/TimeRange'
import { TimeRangeReadOnly } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/AvailableTimes/TimeRangeReadOnly'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import type { OrderingMenu, TimeRange as TimeRangeType } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface AvailableTimesProps {
  menu: OrderingMenu
  venue: Venue
  onAddMenuAvailability: (dayOfWeekIndex: number) => void
  onRemoveMenuAvailability: (dayOfWeekIndex: number, dateRangeIndex: number) => void
  onCopyMenuAvailabilityToAll: (dayOfWeekIndex: number, dateRangeIndex: number) => void
  onMenuAvailabilityUpdate: (dayOfWeekIndex: number, dateRangeIndex: number, start?: string, end?: string) => void
  weekdays: [number, string][]
}

const generateTimeSlots = (startOfDayHour?: number): string[] => {
  const startTime = moment().startOf('day')
  if (startOfDayHour) {
    startTime.add(startOfDayHour, 'hour')
  }
  const durationInMinutes = 15
  const timeSlots = []
  const time = startTime.clone()

  do {
    timeSlots.push(time.format('HH:mm:ss'))
    time.add(durationInMinutes, 'minutes')
  } while (time.format('HH:mm') !== startTime.format('HH:mm'))

  return timeSlots
}

export function AvailableTimes({
  menu,
  venue,
  onAddMenuAvailability,
  onRemoveMenuAvailability,
  onCopyMenuAvailabilityToAll,
  onMenuAvailabilityUpdate,
  weekdays,
}: AvailableTimesProps) {
  const timeSlots = useMemo(() => generateTimeSlots(venue.startOfDayHour), [venue.startOfDayHour])

  return (
    <div>
      {weekdays.map(([dayOfWeekIndex, dayOfWeekDisplay]) => {
        const timeRanges = menu.availableHours[dayOfWeekIndex] || []
        return (
          <div key={dayOfWeekDisplay}>
            <Text fontWeight="bold" fontSize="m" data-test={`menu-availability-${dayOfWeekIndex}-day`}>
              {dayOfWeekDisplay}
            </Text>
            {venue.isSevenRoomsOrderingMiddleware ? (
              <>
                {timeRanges.map((timeRange: TimeRangeType, index: number) => {
                  const isCopied = _.every(Object.values(menu.availableHours), ranges =>
                    _.some(ranges, range => range.start === timeRange.start && range.end === timeRange.end)
                  )
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={`${timeRange.start}-${timeRange.end}-${index}`} pt="s">
                      <TimeRange
                        start={timeRange.start}
                        end={timeRange.end}
                        timeSlots={timeSlots}
                        isCopied={isCopied}
                        onMenuAvailabilityUpdate={(start?: string, end?: string) =>
                          onMenuAvailabilityUpdate(dayOfWeekIndex, index, start, end)
                        }
                        onRemoveMenuAvailability={() => onRemoveMenuAvailability(dayOfWeekIndex, index)}
                        onCopyMenuAvailabilityToAll={() => onCopyMenuAvailabilityToAll(dayOfWeekIndex, index)}
                      />
                    </Box>
                  )
                })}
                <Box pt="m" pb="lm">
                  <Button
                    data-test="menu-management-add-menu-availability"
                    variant="tertiary"
                    icon="VMSWeb-add"
                    noPadding
                    onClick={() => onAddMenuAvailability(dayOfWeekIndex)}
                  >
                    Add menu availability
                  </Button>
                </Box>
              </>
            ) : (
              <Box mt="s" mb="m" data-test={`menu-availability-${dayOfWeekIndex}-hours`}>
                <TimeRangeReadOnly hoursList={timeRanges} />
              </Box>
            )}
          </div>
        )
      })}
    </div>
  )
}
