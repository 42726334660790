import type { Order } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { EmptyState } from '@sevenrooms/core/ui-kit/layout'
import NoOrders from '../images/SevenRooms_Ilustration_Ordering.svg'
import { ordersMessages } from '../locales/ordersMessages'

interface EmptyOrdersStateProps {
  orders: Order[]
  filteredOrders: Order[]
}

export function EmptyOrdersState({ orders, filteredOrders }: EmptyOrdersStateProps) {
  const { formatMessage } = useLocales()

  if (!orders.length) {
    return (
      <EmptyState
        title={formatMessage(ordersMessages.noOrdersPrimary)}
        description={formatMessage(ordersMessages.noOrdersSecondary)}
        image={NoOrders}
        alt={formatMessage(ordersMessages.emptyTableTitle)}
      />
    )
  }

  if (!filteredOrders.length) {
    return <EmptyState description={formatMessage(ordersMessages.ordersEmptySearchResult)} />
  }

  return null
}
