import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Box, Spreadsheet, type DataTableColumn, type SortingRule } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ExcludedDateRangeEditCell } from './ExcludedDateRangeEditCell'
import type { ExcludedDateRange } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

interface ExcludedDateRangesTableProps {
  excludedDateRanges: ExcludedDateRange[]
  onEdit: (index: number) => void
  onDelete: (index: number) => void
}

interface ExcludedDateRangeWithId extends ExcludedDateRange {
  id: number
}

export function ExcludedDateRangesTable({ excludedDateRanges, onEdit, onDelete }: ExcludedDateRangesTableProps) {
  const columns = useMemo<DataTableColumn<ExcludedDateRangeWithId>[]>(
    () => [
      {
        header: 'Date Range',
        key: 'dateRange',
        render: (value: ExcludedDateRange, index: number) => (
          <BasicCell value={formatDateRange(value)} data-test={`excluded-date-range-${index}-date-range`} />
        ),
        textDisplay: 'flex',
        sortable: true,
      },
      {
        header: 'Notes',
        key: 'notes',
        render: (value: ExcludedDateRange, index: number) => (
          <BasicCell value={value.notes || '-'} data-test={`excluded-date-range-${index}-notes`} />
        ),
        textDisplay: 'flex',
        sortable: true,
      },
      {
        header: '',
        key: 'action',
        render: (_value: ExcludedDateRange, index: number) => (
          <ExcludedDateRangeEditCell onEdit={onEdit} onDelete={onDelete} index={index} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const [sorted, setSorted] = useState<SortingRule<string>[]>([
    {
      id: 'dateRange',
      desc: false,
    },
  ])

  const excludedDateRangesWithIds = useMemo(
    () => excludedDateRanges.map((excludedDateRange, idx) => ({ ...excludedDateRange, id: idx })),
    [excludedDateRanges]
  )
  return (
    <Box mt="lm" data-test="sr-excluded-date-range-table">
      <Spreadsheet
        data={excludedDateRangesWithIds}
        columns={columns}
        sorted={sorted}
        onSortedChange={(data: SortingRule<string>[]) => setSorted(data)}
      />
    </Box>
  )
}

export interface BasicCellProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
}

function BasicCell({ value, ...props }: BasicCellProps) {
  return (
    <Cell {...props}>
      <Text>{value}</Text>
    </Cell>
  )
}

const Cell = styled.div`
  box-sizing: border-box;
  height: 36px;
  ${props => props.theme.twoLineEllipsis};
`

const formatDateRange = (excludedDateRange: ExcludedDateRange) => {
  const startDateFormatted = excludedDateRange.from.format('L')
  const endDateFormatted = excludedDateRange.to.format('L')
  return startDateFormatted === endDateFormatted ? startDateFormatted : `${startDateFormatted} - ${endDateFormatted}`
}
