import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { ReportingDashboard } from '@sevenrooms/mgr-reporting/views/ReportingDashboard'

class LookerReportingLayout extends PureComponent {
  render() {
    const { venue, venues, history } = this.props

    return (
      <ContentLayout
        hideTitleBar
        venue={venue}
        venues={venues}
        onChangeVenue={venueNew => {
          history.push(`/manager/${venueNew.urlKey}/reports/view_reports_redirection`)
          window.location.reload()
        }}
        overrideChangeVenue
      >
        <ReportingDashboard />
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
  }
}

export default withRouter(connect(mapStateToProps, null)(LookerReportingLayout))
