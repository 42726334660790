import { useMemo } from 'react'
import type { ReducePaidChannelTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Spreadsheet, Box, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'

export function ReducePaidChannelProofTable({ tableRows }: { tableRows: ReducePaidChannelTableRow[] }) {
  const { formatMessage } = useLocales()
  const columnWidth = 120
  const columns = useMemo<DataTableColumn<ReducePaidChannelTableRow>[]>(
    () => [
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.reducePaidChannelTableHeaderDate)}
          </Text>
        ),
        render: 'date',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.reducePaidChannelTableHeaderTotalRes)}
          </Text>
        ),
        render: 'totalReservationsCompleted',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.reducePaidChannelTableHeaderPaidRes)}
          </Text>
        ),
        render: 'paidReservationsCompleted',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.reducePaidChannelTableHeaderNoAvaila)}
          </Text>
        ),
        render: 'nearbySearchesWithNoAvailability',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.reducePaidChannelTableHeaderPotential25)}
          </Text>
        ),
        key: 'conservativeReplacementMultiplier',
        render: tableRow => (tableRow.conservativeReplacementMultiplier ? `${tableRow.conservativeReplacementMultiplier.toFixed()}%` : ''),
        width: 180,
        maxWidth: 180,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.reducePaidChannelTableHeaderPotentialCapped)}
          </Text>
        ),
        render: 'conservativeReplacedReservations',
        width: 160,
        maxWidth: 160,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.reducePaidChannelTableHeaderInfo)}
          </Text>
        ),
        render: 'insightTableDetail',
        width: 500,
        maxWidth: 500,
      },
    ],
    [formatMessage]
  )
  return (
    <Box data-test="reduce-paid-channel-proof-grid">
      <Spreadsheet data={tableRows} columns={columns} width={1800} noPagination />
    </Box>
  )
}
