import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

const RequiredLabel = styled.div`
  color: #d01a20;
  position: absolute;
  right: 20px;
  top: 21px;
  font-size: 15px;
  height: 18px;
  line-height: 18px;
`

export class ActiveLabelTextInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isFocused: false }
  }

  onInputFocus = () => {
    this.setState({ isFocused: true })
  }

  onInputBlur = () => {
    this.setState({ isFocused: false })
  }

  render() {
    const textInputProps = { ...this.props }
    textInputProps.onFocus = this.onInputFocus
    textInputProps.onBlur = this.onInputBlur
    textInputProps.disableAutoComplete = true
    const { placeholderLabel } = this.props
    delete textInputProps.placeholderLabel
    const shouldShowLabel = !_.isNil(this.props.placeholder) || !_.isNil(this.props.value) || this.state.isFocused
    if (shouldShowLabel) {
      textInputProps.label = placeholderLabel
    } else {
      textInputProps.placeholder = placeholderLabel
    }
    const displayPrefix = this.props.prefix && !_.isNil(this.props.value)
    return (
      <InputWrapper>
        <TextInput
          {...textInputProps}
          customInput={css`
            height: ${shouldShowLabel ? '36px' : '100%'};
            outline: none;
            width: 100% !important;
            border: none !important;
            padding: 0 ${displayPrefix ? '10px 0 0' : '10px'} !important;
            font-size: 16px !important;
            font-weight: 300 !important;
            color: black !important;
          `}
          customPrefix={css`
            font-size: 16px !important;
            font-weight: 300 !important;
            padding-left: 10px;
          `}
          customInputLabel={css`
            display: block;
            width: auto;
            margin: 8px 0 0px 10px;
            color: #878787;
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
          `}
          customInputWrapper={css`
            overflow: hidden;
            height: 60px;
            width: 100%;
            border-style: solid;
            border-color: ${!this.props.isValid ? '#E95757' : this.state.isFocused ? '#3E92D1' : '#DBDCDE'};
            border-radius: 5px;
            border-width: 1px;
            background-color: ${this.props.disabled ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 1)'};
          `}
        />
        {!this.props.isValid && <RequiredLabel>{this.props.labelForInvalid || 'Required'}</RequiredLabel>}
      </InputWrapper>
    )
  }
}

export default ActiveLabelTextInput
