/* eslint react/prop-types: 0 */
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { updateNavState } from 'mgr/lib/actions/NavActions'
import { VenueGroupNavSections, VenueGroupSubNavSections } from 'mgr/pages/shared/utils/Constants'
import {
  tryLoadVmsRoleTemplate,
  onPresetRoleTemplateChange,
  onRoleNameChange,
  resetVmsRoleTemplateState,
  tryCreateVmsRoleTemplate,
  trySaveVmsRoleTemplate,
  invalidateRole,
  setAllowedPermissions,
  updateEditablePermission,
} from 'mgr/pages/venue-group/actions/UserRoles'
import { VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import ActionButton from 'svr/component-lib/Manager/Buttons/ActionButton'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import DetailsDropdownMenu from 'svr/component-lib/Manager/Menus/DetailsDropdownMenu'
import SectionDropdown from 'svr/component-lib/Manager/Section'
import { CheckboxGroup } from '@sevenrooms/core/ui-kit/form'
import {
  ADDITIONAL_PERMISSIONS,
  ADMINISTRATION_PERMISSIONS,
  ADVANCED_BOOKING_PERMISSIONS,
  BASIC_BOOKING_PERMISSIONS,
  STANDARD_EMAIL_PERMISSIONS,
  DATA_PERMISSIONS,
  DATA_PERMISSIONS_FOR_NON_USER_MANAGERS,
  ADMINISTRATION_PERMISSIONS_AR_BULK_COPY_ENABLED,
  ADVANCED_BOOKING_PERMISSIONS_FOR_PROMOTERS,
  ADVANCED_BOOKING_PERMISSIONS_FOR_IT_USER_ADMINISTRATOR,
} from 'mgr/pages/venue-group/constants'

const SectionContainer = styled.div`
  width: 715px;
  padding: ${props => props.theme.padding.large} 0 0 ${props => props.theme.padding.large};
`

const RoleInformationContainer = styled.div``

const RoleInformationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
`

const RoleInfoLabel = styled.div`
  color: ${props => props.theme.color.black};
`

const RoleInfoHref = styled.a`
  color: #6a6a6a;
  cursor: pointer;
`

const RolesDefinitionLabel = styled.div`
  display: inline-block;
`

const Icon = styled(StyledVmsIconXS)`
  display: inline-block;
  padding: 0 4px;
`

const RoleContainer = styled.div`
  padding-top: ${props => props.theme.padding.medium};
`

const PermissionsContainer = styled.div``
const GroupPermissionsWrapper = styled.div``
const CheckboxGroupWrapper = styled.div`
  padding-top: ${props => props.theme.padding.medium};
`

class CreateEditRoleLayout extends React.PureComponent {
  componentDidMount = () => {
    const { updateEditablePermission, venueGroup } = this.props
    this.props.updateNavState(VenueGroupNavSections.USERS, VenueGroupSubNavSections.ROLES)
    const pathName = this.props.match.path
    const pathParts = pathName.split('/')
    const isEdit = pathParts[pathParts.length - 1] === 'edit'
    if (isEdit) {
      const historyParts = this.props.history.location.pathname.split('/')
      const vmsRoleTemplateId = historyParts[historyParts.length - 1]
      this.props.tryLoadVmsRoleTemplate(vmsRoleTemplateId)
    } else {
      this.props.resetVmsRoleTemplateState()
    }
    updateEditablePermission(venueGroup.shouldLockEmailCampaignsWithGlobalTags)
  }

  successHandlerCallback = () => {
    const pathName = this.props.match.path
    const urlPath = pathName.substr(0, pathName.lastIndexOf('/'))
    this.props.history.push(urlPath)
  }

  handleCreateSaveRole = () => {
    const pathName = this.props.match.path
    const pathParts = pathName.split('/')
    const isEdit = pathParts[pathParts.length - 1] === 'edit'
    if (this.props.roleName.trim() === '') {
      this.props.invalidateRole()
      return
    }
    if (isEdit) {
      this.props.trySaveVmsRoleTemplate().then(this.successHandlerCallback)
    } else {
      this.props.tryCreateVmsRoleTemplate().then(this.successHandlerCallback)
    }
  }

  addEditRoleButton = isEdit => {
    const createOrSave = isEdit ? 'Save' : 'Create'
    return (
      <ActionButton
        fontSize="15px"
        horizontalPadding="46px"
        onClickHandler={this.handleCreateSaveRole}
        dataTest={`${createOrSave}-role-button`}
        className={`${createOrSave}-role-button`}
        actionText={`${createOrSave}`}
        backgroundColor="#347baf"
      />
    )
  }

  handleRoleTemplateChange = val => {
    this.props.onPresetRoleTemplateChange(val)
  }

  handleRoleNameChange = val => {
    this.props.onRoleNameChange(val)
  }

  roleInformationSection = () => (
    <RoleInformationContainer>
      <RoleInformationHeader>
        <RoleInfoLabel>Choose a template to customize the role from</RoleInfoLabel>
        <RoleInfoHref href="https://help.sevenrooms.com/hc/en-us/articles/5308806365339-Managing-Group-Users-and-Roles">
          <RolesDefinitionLabel>What is this?</RolesDefinitionLabel>
          <Icon>{VmsIcons.HelpLine}</Icon>
        </RoleInfoHref>
      </RoleInformationHeader>
      <RoleContainer>
        <DetailsDropdownMenu
          name="role-template"
          choices={this.props.presetRoleTemplates}
          onChangeHandler={this.handleRoleTemplateChange}
          isLightTheme
          value={this.props.rolePresetTemplateId}
          height={60}
          placeholder="Select Role Template"
        />
      </RoleContainer>
      {this.props.rolePresetTemplateId && (
        <RoleContainer>
          <ActiveLabelTextInput
            field="customRoleName"
            dataTest="customRoleName"
            onChange={this.handleRoleNameChange}
            value={this.props.roleName}
            placeholderLabel="Custom Role Name"
            isValid={this.props.roleValid}
          />
        </RoleContainer>
      )}
    </RoleInformationContainer>
  )

  generateOptionGroup = (groupName, permissions, hiddenPermissions) => {
    const selectedPresetRoleTemplate = _.find(this.props.presetRoleTemplates, {
      value: this.props.rolePresetTemplateId,
    })
    return {
      label: groupName,
      name: groupName,
      value: groupName,
      choices: permissions
        .filter(permission => !hiddenPermissions.includes(permission.name))
        .map(permission => ({
          ...permission,
          subPermissions: permission.subPermissions?.filter(subPermission => !hiddenPermissions.includes(subPermission.name)),
        }))
        .map(permission => ({
          label: permission.label,
          name: permission.name,
          value: permission.name,
          selected: this.props.permissions[permission.name],
          choices:
            permission.subPermissions && this.generateOptionGroup(permission.label, permission.subPermissions, hiddenPermissions).choices,
          disabled:
            (permission.name === 'f_make_reservation_booker_follower' && this.props.permissions.f_make_reservation_follower) ||
            (permission.name === 'f_require_approval' && !this.props.permissions.f_access_operations_tab) ||
            !selectedPresetRoleTemplate.editable_permissions.includes(permission.name),
          autoCheckBySelecting: permission.autoCheckBySelecting,
          autoUncheckByDeselecting: permission.autoUncheckByDeselecting,
          info: permission.info,
        })),
    }
  }

  isGroupSelected = options => options.every(o => o.selected)

  getAdministrationPermissions = () => {
    const administrationPermissions = ADMINISTRATION_PERMISSIONS
    if (this.props.venueGroup.accessRuleBulkCopyEnabled) {
      return ADMINISTRATION_PERMISSIONS_AR_BULK_COPY_ENABLED
    }
    return administrationPermissions
  }

  getAdvancedBookingPermission = () => {
    if (this.props.rolePresetTemplateId === 'IT_USER_ADMINISTRATOR') {
      return ADVANCED_BOOKING_PERMISSIONS_FOR_IT_USER_ADMINISTRATOR
    }
    if (this.props.rolePresetTemplateId === 'PROMOTER') {
      return ADVANCED_BOOKING_PERMISSIONS_FOR_PROMOTERS
    }
    return ADVANCED_BOOKING_PERMISSIONS
  }

  getDataPermissions = () => {
    if (this.props.selectedRolePrivilegeLevel !== 'USERMANAGER') {
      return DATA_PERMISSIONS_FOR_NON_USER_MANAGERS
    }
    return DATA_PERMISSIONS
  }

  handleCheckboxChecked = selectedOptions => this.props.setAllowedPermissions(selectedOptions)

  vmsAccessPermissionsSection = () => {
    const selectedPermission = []
    const hiddenPermissions = []
    if (this.props.venueGroup.disableGlobalHistory) {
      hiddenPermissions.push('f_view_clients_across_venue_group')
    }
    Object.keys(this.props.permissions).forEach(permission => {
      if (permission === 'f_emails_with_global_tags' && this.props.venueGroup.shouldLockEmailCampaignsWithGlobalTags) {
        return
      }

      if (this.props.permissions[permission]) {
        selectedPermission.push(permission)
      }
    })
    const basicBookingGroup = this.generateOptionGroup('Basic Booking', BASIC_BOOKING_PERMISSIONS, hiddenPermissions)
    const advancedBookingGroup = this.generateOptionGroup('Advanced Booking', this.getAdvancedBookingPermission(), hiddenPermissions)
    const administrationGroup = this.generateOptionGroup('Administration', this.getAdministrationPermissions(), hiddenPermissions)
    const dataGroup = this.generateOptionGroup('Data', this.getDataPermissions(), hiddenPermissions)
    const additionalGroup = this.generateOptionGroup('Additional', ADDITIONAL_PERMISSIONS, hiddenPermissions)
    return (
      <GroupPermissionsWrapper>
        <CheckboxGroupWrapper>
          <CheckboxGroup
            onChange={this.handleCheckboxChecked}
            name={basicBookingGroup.label}
            data-test="basic-booking-permissions"
            choices={[basicBookingGroup]}
            selected={selectedPermission}
            canNestingCheckboxBeSelected
          />
        </CheckboxGroupWrapper>
        <CheckboxGroupWrapper>
          <CheckboxGroup
            onChange={this.handleCheckboxChecked}
            name={advancedBookingGroup.label}
            data-test="advanced-booking-permissions"
            choices={[advancedBookingGroup]}
            selected={selectedPermission}
            canNestingCheckboxBeSelected
          />
        </CheckboxGroupWrapper>
        <CheckboxGroupWrapper>
          <CheckboxGroup
            onChange={this.handleCheckboxChecked}
            name={administrationGroup.label}
            data-test="administration-permissions"
            choices={[administrationGroup]}
            selected={selectedPermission}
            canNestingCheckboxBeSelected
          />
        </CheckboxGroupWrapper>
        <CheckboxGroupWrapper>
          <CheckboxGroup
            onChange={this.handleCheckboxChecked}
            name={dataGroup.label}
            data-test="data-permissions"
            choices={[dataGroup]}
            selected={selectedPermission}
            canNestingCheckboxBeSelected
          />
        </CheckboxGroupWrapper>
        <CheckboxGroupWrapper>
          <CheckboxGroup
            onChange={this.handleCheckboxChecked}
            name={additionalGroup.label}
            data-test="additional-permissions"
            choices={[additionalGroup]}
            selected={selectedPermission}
            canNestingCheckboxBeSelected
          />
        </CheckboxGroupWrapper>
      </GroupPermissionsWrapper>
    )
  }

  emailSubscriptionsSection = () => {
    const selectedPermission = []
    Object.keys(this.props.permissions).forEach(permission => {
      if (this.props.permissions[permission]) {
        selectedPermission.push(permission)
      }
    })
    const standardEmailGroup = this.generateOptionGroup('Standard Emails', STANDARD_EMAIL_PERMISSIONS, [])
    return (
      <GroupPermissionsWrapper>
        <CheckboxGroupWrapper>
          <CheckboxGroup
            onChange={this.handleCheckboxChecked}
            name={standardEmailGroup.label}
            data-test="standard-emails-permissions"
            choices={[standardEmailGroup]}
            selected={selectedPermission}
            canNestingCheckboxBeSelected
          />
        </CheckboxGroupWrapper>
      </GroupPermissionsWrapper>
    )
  }

  render = () => {
    const { match, venueGroup, userDomain, roleName } = this.props
    const pathName = match.path
    const pathParts = pathName.split('/')
    const urlPath = pathName.substr(0, pathName.lastIndexOf('/'))
    const isEdit = pathParts[pathParts.length - 1] === 'edit'
    const createOrEdit = isEdit ? 'Edit' : 'Create'
    const createOrEditPath = createOrEdit.toLowerCase()
    const createOrEditTitle = isEdit ? `Edit Role: ${roleName}` : 'Create Role'

    return (
      <ContentLayout
        title={createOrEditTitle}
        routes={[
          {
            name: 'Roles',
            route: `${urlPath}`,
          },
          {
            name: `${createOrEdit}`,
            route: `${urlPath}/${createOrEditPath}`,
          },
        ]}
        venueGroup={venueGroup}
        userDomain={userDomain}
        Actions={this.addEditRoleButton(isEdit)}
      >
        <SectionContainer>
          <SectionDropdown headerId="role-information" headerText="Information" showCaret={false} isOpenDefault>
            {this.roleInformationSection()}
          </SectionDropdown>
        </SectionContainer>
        {this.props.rolePresetTemplateId && (
          <PermissionsContainer>
            <SectionContainer>
              <SectionDropdown
                headerId="vms-access-permissions"
                headerText="Venue Management System Access Permissions"
                showCaret={false}
                isOpenDefault
              >
                {this.vmsAccessPermissionsSection()}
              </SectionDropdown>
            </SectionContainer>
            <SectionContainer>
              <SectionDropdown headerId="email-subscriptions" headerText="Email Subscriptions" showCaret={false} isOpenDefault>
                {this.emailSubscriptionsSection()}
              </SectionDropdown>
            </SectionContainer>
          </PermissionsContainer>
        )}
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => ({
  venueGroup: state.appState.venueGroup,
  userDomain: state.appState.userDomain,
  roleValid: state.roles.selectedRoleValid,
  roleName: state.roles.selectedRoleName,
  rolePresetTemplateId: state.roles.selectedPresetRoleTemplateId,
  permissions: state.roles.permissions,
  presetRoleTemplates: state.roles.presetRoleTemplates,
  selectedRolePrivilegeLevel: state.roles.selectedRolePrivilegeLevel,
})

const mapDispatchToProps = {
  updateNavState,
  tryLoadVmsRoleTemplate,
  onPresetRoleTemplateChange,
  onRoleNameChange,
  tryCreateVmsRoleTemplate,
  trySaveVmsRoleTemplate,
  resetVmsRoleTemplateState,
  invalidateRole,
  setAllowedPermissions,
  updateEditablePermission,
}

CreateEditRoleLayout.propTypes = {
  updateNavState: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  tryLoadVmsRoleTemplate: PropTypes.func,
  resetVmsRoleTemplateState: PropTypes.func,
  roleName: PropTypes.string,
  invalidateRole: PropTypes.func,
  trySaveVmsRoleTemplate: PropTypes.func,
  tryCreateVmsRoleTemplate: PropTypes.func,
  onPresetRoleTemplateChange: PropTypes.func,
  onRoleNameChange: PropTypes.func,
  presetRoleTemplates: PropTypes.array,
  rolePresetTemplateId: PropTypes.string,
  roleValid: PropTypes.bool,
  permissions: PropTypes.object,
  venueGroup: PropTypes.object,
  selectedRolePrivilegeLevel: PropTypes.string,
  setAllowedPermissions: PropTypes.func,
  userDomain: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditRoleLayout)
