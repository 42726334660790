import { useCallback } from 'react'
import { useSaveInsightMutation } from '@sevenrooms/core/api'
import type { InsightSaveParameters } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { Box, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../locales'

export interface AssignedUsersProps {
  insightId: string
  insightAssignedUserId: string | null
  userOptions: { id: string; label: string }[]
}

export function AssignedUsers({ insightId, insightAssignedUserId, userOptions }: AssignedUsersProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [saveInsight] = useSaveInsightMutation()

  const updateUser = useCallback(
    (value: string | null) => {
      const insightParameters: InsightSaveParameters = {
        assignedUserId: value ?? '',
      }
      saveInsight({ venueId, insightId, insightParameters })
        .then(() => {
          notify({ content: formatMessage(insightsMessages.insightSaveSuccessMessage), type: 'success', autoClose: 1000 })
        })
        .catch(() => {
          notify({ content: formatMessage(insightsMessages.insightSaveErrorMessage), type: 'error' })
        })
    },
    [formatMessage, saveInsight, venueId, insightId]
  )

  return (
    <VStack spacing="s" data-test="insight-assigned-user-section" width="40%">
      <Text fontWeight="bold">{formatMessage(insightsMessages.assignedUserLabel)}</Text>
      <SecondaryText>{formatMessage(insightsMessages.assignedUserDescription)}</SecondaryText>
      <Box>
        <Select
          withEmpty
          data-test="insight-assigned-user-field"
          options={userOptions}
          value={insightAssignedUserId ?? undefined}
          onChange={updateUser}
        />
      </Box>
    </VStack>
  )
}
