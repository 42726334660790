import { Chart } from 'react-google-charts'
import type { RevenueHistorical } from '@sevenrooms/core/domain'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { DateFilterEnum } from '../../constants'

interface RowChartProps {
  chartData: RevenueHistorical[] | []
  filter: DateFilterEnum | undefined
}

export function RowChart({ chartData, filter }: RowChartProps) {
  let groupWidth = 75
  switch (filter) {
    case DateFilterEnum.LAST_MONTH:
    case DateFilterEnum.THIS_MONTH:
      groupWidth = 98
      break
    case DateFilterEnum.LAST_3_MONTHS:
      groupWidth = 95
      break
    case DateFilterEnum.LAST_6_MONTHS:
    case DateFilterEnum.LAST_YEAR:
    case DateFilterEnum.ALL:
      groupWidth = 90
      break
    default:
      break
  }

  const options = {
    seriesType: 'bars',
    isStacked: 'relative',
    chartArea: {
      height: '100%',
      width: '100%',
      bottom: 16,
    },
    vAxis: {
      textPosition: 'none',
      baselineColor: 'none',
      gridlines: {
        color: 'none',
      },
    },
    bar: {
      groupWidth: `${groupWidth}%`,
    },
    legend: { position: 'none' },
    series: {
      0: { color: theme.colors.brandColor, enableInteractivity: false },
      1: { color: theme.colors.dividerLines, enableInteractivity: false },
    },
  }

  const dataSet: Array<{ label: string | undefined; value: number | undefined }> = []
  const currentDate = new Date()
  const dateFormatter = new Intl.DateTimeFormat(TimeLocale.getLocale(), { month: 'short' })

  if (filter !== DateFilterEnum.ALL) {
    chartData.forEach(item => {
      dataSet.push({
        label: dateFormatter.format(new Date(`${item.date}-`)),
        value: item.value,
      })
    })
  } else {
    chartData.forEach(item => {
      dataSet.push({
        label: item.date,
        value: item.value,
      })
    })
  }

  const max = dataSet.reduce((a, b) => ((a?.value ?? 0) > (b?.value ?? 0) ? a : b)).value || 0

  const offCurrentStyle = 'opacity: 0.2;'

  const dataMap = dataSet.map((obj, iter) => {
    let style = offCurrentStyle
    if (filter === DateFilterEnum.YEAR_TO_DATE) {
      if (currentDate.getMonth() === iter) {
        style = ''
      }
    } else if (iter === dataSet.length - 1) {
      style = ''
    }
    const val = obj.value || 0
    return [obj.label, val, style, max - val]
  })

  const data = [['Month', 'Revenue', { role: 'style' }, 'bg'], ...dataMap]

  return <Chart chartType="ComboChart" options={options} data={data} width="520px" height="90px" />
}
