import { FlatfileButton } from '@flatfile/react'
import { useSelector } from 'react-redux'
import { Desc, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import { ENVIRONMENTS } from 'svr/manager/lib/utils/Constants'
import { useLocales } from '@sevenrooms/core/locales'
import type { RootState } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { ImportLocales } from '../Import.locales'
import { selectFlatFileCustomer } from '../reservationAndClientImportSlice'
import type { FlatFileFieldMapping, FlatFileResults, ImportType } from '../types'

interface FlatFileImportProps {
  importType: ImportType
  dateFormat: string
  title: string
  fields: FlatFileFieldMapping[]
  handleFlatFileDone: (flatFileResults: FlatFileResults) => void
  handleClickBack: () => void
}

export default function FlatFileImport(props: FlatFileImportProps) {
  const { importType, dateFormat, title, fields, handleFlatFileDone, handleClickBack } = props

  const { formatMessage } = useLocales()
  const { userDomain } = useAppContext()
  const flatFileCustomer = useSelector(selectFlatFileCustomer)
  const flatFileLicenseKey = useSelector((state: RootState) => state.reservationAndClientImport.licenseKey)

  if (userDomain?.env === undefined || !flatFileLicenseKey) {
    return null
  }

  const devMode = [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.QA].includes(userDomain.env)

  const flatFileSettings = {
    type: title,
    fields,
    allowCustom: true,
    devMode,
  }

  return (
    <>
      <Desc>{formatMessage(ImportLocales.step2)}</Desc>
      <SubDesc>{formatMessage(ImportLocales.chosenDateFormat, { dateFormat })}</SubDesc>
      <SubDesc>{formatMessage(ImportLocales.uploadFile, { importType: importType.toString() })}</SubDesc>
      <FlatfileButton
        settings={flatFileSettings}
        licenseKey={flatFileLicenseKey}
        customer={flatFileCustomer}
        onData={async (flatFileResults: FlatFileResults) => {
          handleFlatFileDone(flatFileResults)
        }}
        render={(_importer, launch) => (
          <Box marginTop="20px">
            <Button variant="contained" onClick={handleClickBack} style={{ marginRight: '20px' }} data-test="flatfile-import-back">
              {formatMessage(ImportLocales.backStep)}
            </Button>
            <Button variant="contained" onClick={launch} data-test="flatfile-import-start-upload">
              {formatMessage(ImportLocales.importFile)}
            </Button>
          </Box>
        )}
      />
    </>
  )
}
