import Radium from 'radium'
import _ from 'lodash'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import StyleUtils from 'mgr/layout/StyleUtils'
import { getAvailabilityByTableId, getProblemDescription } from 'mgr/pages/shared/utils/Availability'
import { isPartOfTableCombo } from 'mgr/pages/shared/utils/Tables'
import {
  showConflictDialogAction,
  closeConflictDialogAction,
  completeMoveActualAction,
  cancelMoveActualAction,
} from 'mgr/pages/single-venue/dayview/actions/Actions'
import { CommonStyles, GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'
import GridMoveDropDown from './GridMoveDropDown'

const GridMoveCellStyles = {
  body: merge(
    {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 52,
      height: GridStyles.ReservationRowHeight,
      lineHeight: `${GridStyles.ReservationRowHeight}px`,
      top: 0,
      left: -20,
      color: 'white',
      backgroundColor: 'transparent',
      fontSize: '13px',
      cursor: 'pointer',
      overflow: 'hidden',
    },
    StyleUtils.rounded('10px')
  ),
  sameActual: merge(
    {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 53,
      height: GridStyles.ReservationRowHeight,
      lineHeight: `${GridStyles.ReservationRowHeight}px`,
      top: 0,
      left: -20,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      overflow: 'hidden',
    },
    StyleUtils.rounded('10px')
  ),
  left: {
    display: 'inline-block',
    width: 20,
    height: '100%',
    backgroundColor: 'transparent',
    zIndex: 52,
  },
  center: {
    display: 'inline-block',
    height: '100%',
    zIndex: 53,
    background: 'transparent',
    opacity: 0,
    top: -13, // TODO: figure out why this is needed
    position: 'relative',
  },
  right: {
    display: 'inline-block',
    width: 20,
    height: '100%',
    backgroundColor: 'transparent',
    zIndex: 52,
  },
  problem: {
    backgroundColor: CommonStyles.ProblemReservationMinor,
  },
  recommended: {
    backgroundColor: CommonStyles.ButtonPrimaryColor,
  },
  isMovingCell: {
    backgroundColor: 'white',
    width: 60,
  },
  tableName: {
    display: 'inline-block',
    fontWeight: '600',
    marginLeft: 2,
    marginRight: 10,
  },
  partySizeRange: {
    display: 'inline-block',
    marginRight: 20,
  },
  description: {
    display: 'inline-block',
  },
  moreOptions: merge(
    {
      color: 'white',
      border: '1px dashed white',
      padding: 7,
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 52,
      fontWeight: 600,
      ':hover': {
        backgroundColor: 'white',
        color: '#aaa',
      },
    },
    StyleUtils.rounded('5px')
  ),
  close: {
    position: 'relative',
    fontSize: '23px',
    top: 5,
    left: 13,
    color: '#ccc',
    ':hover': {
      color: '#777',
    },
  },
}

class GridMoveCell extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { hover: false, isMoreOptionsOpen: false }
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.onMoreOptionsMouseOver = this.onMoreOptionsMouseOver.bind(this)
    this.onMoreOptionsMouseOut = this.onMoreOptionsMouseOut.bind(this)
    this.containerRef = null
    this.handleMoveClick = this.handleMoveClick.bind(this)
    this.handleMoreOptionsClick = this.handleMoreOptionsClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleClickClose = this.handleClickClose.bind(this)
  }

  onMouseOver() {
    this.setState({ hover: true })
  }

  onMouseOut() {
    this.setState({ hover: false })
  }

  onMoreOptionsMouseOver() {
    this.setState({ hover: true })
  }

  onMoreOptionsMouseOut() {
    this.setState({ hover: false })
  }

  handleMoreOptionsClick() {
    this.setState({ isMoreOptionsOpen: true })
  }

  handleClickOutside() {
    this.setState({ isMoreOptionsOpen: false })
  }

  handleMoveClick() {
    const moveState = this.getMoveState(false)
    const conflictActual = null
    if (moveState.isProblem) {
      this.props.actions.showConflictDialogAction(
        moveState.description,
        'Assign table',
        conflictActual,
        completeMoveActualAction(this.props.venue.id, this.props.actual.id, this.props.table.id),
        closeConflictDialogAction(this.props.actual.id)
      )
    } else {
      this.props.actions.assignTable(this.props.venue.id, this.props.actual, this.props.table.id)
    }
  }

  handleClickClose() {
    this.props.actions.cancelMoveActual()
  }

  getExtensionWidth(isMovingCell) {
    return isMovingCell ? GridMoveCellStyles.isMovingCell.width * 2 : GridMoveCellStyles.left.width * 2
  }

  getHoverWidth(duration, isMovingCell = false) {
    return this.getDurationWidth(duration) + this.getExtensionWidth(isMovingCell)
  }

  getDurationWidth(duration) {
    const durationOrders = duration / 15
    const durationWidth = durationOrders * GridStyles.TimeHeaderWidth + durationOrders - 4 + Math.floor(durationOrders / 6)
    return durationWidth + 4
  }

  getIsRecommended(avail) {
    if (!avail) {
      return false
    }
    return !(
      avail.willCauseProblems ||
      avail.isLocked ||
      avail.tooSmall ||
      avail.tooLarge ||
      (avail.primaryBlock && avail.primaryBlock.isBlocked) ||
      (avail.primaryRule && avail.primaryRule.isHold)
    )
  }

  getMoveState(shortFormat = true) {
    let isProblem = true
    let isRecommended = false
    const tableName = this.props.isUnassigned ? '' : this.props.table.item_code
    const partySizeRange = this.props.isUnassigned ? '' : this.props.table.party_size_range_display
    const description = this.props.isUnassigned ? '' : getProblemDescription(this.props.availability, shortFormat)
    if (this.getIsRecommended(this.props.availability)) {
      isProblem = false
      isRecommended = true
    }
    return {
      tableName,
      partySizeRange,
      description,
      isProblem,
      isRecommended,
    }
  }

  isMovingCell(actual) {
    const idx = _.findIndex(actual.all_table_ids_list, tableId => tableId === this.props.table.id)
    return idx !== -1
  }

  shouldShowMoreOptions() {
    const isPartOfCombo = this.props.isPartOfTableCombo
    const hasComboOptions = this.props.availability.recommendedCombos?.length || this.props.availability.notRecommendedCombos?.length
    return isPartOfCombo && hasComboOptions
  }

  render() {
    const { actual } = this.props
    const duration = actual.duration + (actual.buffer_mins || 0) || 0
    const hoverWidth = this.getHoverWidth(duration)
    const durationWidth = this.getDurationWidth(duration)
    const isMovingCell = this.isMovingCell(actual)
    const moveState = this.getMoveState()

    const computedStyles = {
      body: merge(GridMoveCellStyles.body, { width: hoverWidth }),
      sameActual: merge(GridMoveCellStyles.sameActual, { width: hoverWidth }),
      center: merge(GridMoveCellStyles.center, { width: durationWidth }),
      left: GridMoveCellStyles.left,
      right: GridMoveCellStyles.right,
      moreOptions: merge(GridMoveCellStyles.moreOptions, {
        left: durationWidth + 30,
      }),
    }

    if (moveState.isProblem) {
      if (this.state.hover) {
        computedStyles.body = merge(computedStyles.body, GridMoveCellStyles.problem)
      }
      computedStyles.left = merge(computedStyles.left, GridMoveCellStyles.problem)
      computedStyles.right = merge(computedStyles.right, GridMoveCellStyles.problem)
    } else if (moveState.isRecommended) {
      if (this.state.hover) {
        computedStyles.body = merge(computedStyles.body, GridMoveCellStyles.recommended)
      }
      computedStyles.left = merge(computedStyles.left, GridMoveCellStyles.recommended)
      computedStyles.right = merge(computedStyles.right, GridMoveCellStyles.recommended)
    } else {
      computedStyles.body = merge(computedStyles.body, GridMoveCellStyles.recommended)
    }

    if (this.state.hover) {
      if (!isMovingCell) {
        computedStyles.center = merge(computedStyles.center, { opacity: 1 })
        computedStyles.body = merge(computedStyles.body, StyleUtils.scale('1.005'), StyleUtils.shadow('rgba(50,50,50,0.4)', '2px', '6px'))
      }
    }

    // no op if you're hovering over yourself or if you're unassigned
    if (this.props.isUnassigned || isMovingCell) {
      return (
        <div
          ref={container => {
            this.containerRef = container
          }}
        >
          <div style={computedStyles.sameActual} />
        </div>
      )
    }

    return (
      <div
        ref={container => {
          this.containerRef = container
        }}
      >
        <div
          data-test="sr-grid_click_to_move_row"
          style={computedStyles.body}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          onClick={this.handleMoveClick}
        >
          <div style={computedStyles.left} />
          <div style={computedStyles.center}>
            <div style={GridMoveCellStyles.tableName}>{moveState.tableName}</div>
            <div style={GridMoveCellStyles.partySizeRange}>{moveState.partySizeRange}</div>
            <div data-test="sr-label-description" style={GridMoveCellStyles.description}>
              {moveState.description}
            </div>
          </div>
          <div style={computedStyles.right} />
        </div>
        {this.shouldShowMoreOptions() ? (
          <div onMouseOver={this.onMoreOptionsMouseOver} onMouseOut={this.onMoreOptionsMouseOut}>
            <div data-test="sr-label-more_options" style={computedStyles.moreOptions} onClick={this.handleMoreOptionsClick}>
              More Options
            </div>
            {this.state.isMoreOptionsOpen ? (
              <GridMoveDropDown
                leftPos={computedStyles.moreOptions.left}
                parentRef={this.containerRef}
                onClickOutside={this.handleClickOutside}
                availability={this.props.availability}
                actual={this.props.actual}
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

GridMoveCell = Radium(GridMoveCell)

const mapStateToProps = (state, ownProps) => ({
  venue: state.appState.venue,
  venueSettings: state.appState.venueSettings,
  availability: getAvailabilityByTableId(state.gridState.gridAvailabilityMap, ownProps.isUnassigned ? null : ownProps.table.id),
  isPartOfTableCombo: isPartOfTableCombo(state.dayviewState.dayFloorPlan.tableCombos, ownProps.isUnassigned ? null : ownProps.table.id),
})

const mapDispatchToProps = dispatch => ({
  actions: {
    assignTable: (venueId, actual, tableId) => {
      dispatch(completeMoveActualAction(venueId, actual.id, tableId ? [tableId] : null))
    },
    showConflictDialogAction: (explanation, actionButtonText, conflictActual, nextAction, closeAction) => {
      dispatch(showConflictDialogAction(explanation, actionButtonText, conflictActual, nextAction, closeAction))
    },
    cancelMoveActual: () => {
      dispatch(cancelMoveActualAction())
    },
  },
})

GridMoveCell = connect(mapStateToProps, mapDispatchToProps)(GridMoveCell)

export default GridMoveCell
