/* eslint-disable consistent-return, import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter, type RouteComponentProps } from 'react-router'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import ChangePageModal from 'mgr/lib/components/ChangePageModal'
import { TransitionBlockerWithOldRouterSupport } from 'mgr/lib/components/TransitionBlockerWithOldRouterSupport'
import type { Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'

interface DirtyStateUnloaderProps extends RouteComponentProps {
  isDirty: boolean
  modalQuestionContext: string
  changeVenueIntent: boolean
  newVenue: Venue
  onChangeVenue: typeof GlobalActions.onChangeVenue
}

function DirtyStateUnloader({
  isDirty,
  modalQuestionContext,
  children,
  changeVenueIntent,
  newVenue,
  onChangeVenue,
}: React.PropsWithChildren<DirtyStateUnloaderProps>) {
  const onConfirmTransition = useCallback(() => {
    if (changeVenueIntent) {
      onChangeVenue(newVenue)
      window.location.reload()
    }
  }, [changeVenueIntent, newVenue, onChangeVenue])

  return (
    <>
      <TransitionBlockerWithOldRouterSupport
        modal={<ChangePageModal settingsTitle={modalQuestionContext} />}
        isBlocked={isDirty}
        blockBeforeUnload={!changeVenueIntent}
        onConfirmTransition={onConfirmTransition}
      />
      {children}
    </>
  )
}

const mapStateToProps = (state: State) => ({
  changeVenueIntent: state.appState.changeVenueIntent,
  newVenue: state.appState.changeVenueIntentObj,
})

const mapDispatchToProps = {
  onChangeVenue: GlobalActions.onChangeVenue,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DirtyStateUnloader))
