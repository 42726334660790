import { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { onChangeVenueExitEditFlow } from 'mgr/lib/actions/DirtyStateUnloader'
import DirtyStateUnloader from 'mgr/lib/components/DirtyStateUnloader'
import * as ProductInventoryActions from 'mgr/pages/single-venue/settings/actions/ordering/ProductInventoryActions'
import { ProductForm } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/ProductForm'
import { PRODUCT_TYPES_DISPLAY_NAMES } from 'mgr/pages/single-venue/settings/constants'
import {
  selectOrderingProductInventoryModifiers,
  selectOrderingProductInventoryModifierGroups,
} from 'mgr/pages/single-venue/settings/selectors/productInventory'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { Route } from 'mgr/pages/single-venue/settings/settings.types'
import type { ModifierGroups, Modifiers, PRODUCT_TYPES, ProductTags } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type {
  ProductInventoryRouteParams,
  ProductInventoryItem,
  ModifiersGroupToModifiers,
} from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'
import type { RouteComponentProps } from 'react-router'

export interface ProductItemProps extends RouteComponentProps<ProductInventoryRouteParams> {
  routes: Route[]
  isSaving: boolean
  venue: Venue
  mediaUrl: string
  venues: UserDomainVenue[]
  onChangeVenue: (venue: UserDomainVenue) => void
  product: ProductInventoryItem
  productTags: ProductTags
  modifiers: Modifiers
  modifierGroups: ModifierGroups
  isCropImageModalOpen: boolean
  clearProductData: (productType: PRODUCT_TYPES) => void
  updateName: (value: string) => void
  updateDescription: (value: string) => void
  updatePrice: (value: number) => void
  updateSelectedProductTags: (value: string[]) => void
  updateSelectedModifiers: (value: string[]) => void
  removeSelectedModifier: (value: string) => void
  updateSelectedGroupModifiers: (groupId: string, modifiers?: string[]) => void
  updateSelectedGroups: (groups: ModifiersGroupToModifiers[]) => void
  updateTaxRate: (value: number) => void
  updateTaxGroupId: (value: string) => void
  updateMinAmount: (value: number) => void
  updateMaxAmount: (value: number) => void
  updateMaxSameModifiersAmount: (value: number) => void
  updateAllowOrderSameModifiers: (value: boolean) => void
  updateVerifyAge: (value: boolean) => void
  loadProductItemById: (venueId: string, productId: string, productType: PRODUCT_TYPES) => Promise<void>
  loadProductInventoryResources: (venueId: string, productType: PRODUCT_TYPES) => Promise<void>
  saveProductItemById: (venueId: string, productId: string, productData: ProductInventoryItem) => Promise<void>
  createProductItem: (venueId: string, productData: ProductInventoryItem) => Promise<void>
  isDirty: boolean
}

function ProductItem({
  routes,
  match,
  history,
  venue,
  mediaUrl,
  venues,
  product,
  productTags,
  modifiers,
  modifierGroups,
  onChangeVenue,
  isCropImageModalOpen,
  clearProductData,
  updateSelectedGroupModifiers,
  updateSelectedGroups,
  updateTaxRate,
  updateTaxGroupId,
  updateMinAmount,
  updateMaxAmount,
  updateMaxSameModifiersAmount,
  updateName,
  updateDescription,
  updatePrice,
  updateSelectedProductTags,
  updateSelectedModifiers,
  removeSelectedModifier,
  updateAllowOrderSameModifiers,
  updateVerifyAge,
  loadProductItemById,
  loadProductInventoryResources,
  saveProductItemById,
  createProductItem,
  isDirty,
}: ProductItemProps) {
  const venueId = venue.id
  const { id: productId, type } = match.params
  const productType = type.toUpperCase() as PRODUCT_TYPES

  const [isLoading, updateLoading] = useState(false)
  const [isSaving, updateSaving] = useState(false)

  const openProductsListPage = useCallback(() => {
    const productInventoryListRoute = routes[0]
    if (productInventoryListRoute) {
      const url = productInventoryListRoute.route
      history.push(url)
    }
  }, [history, routes])

  const loadData = useCallback(async () => {
    if (isLoading) {
      return
    }
    try {
      updateLoading(true)
      if (productId) {
        await loadProductItemById(venue.id, productId, productType)
      } else {
        clearProductData(productType)
      }
      await loadProductInventoryResources(venue.id, productType)
    } catch (e) {
      openProductsListPage()
    } finally {
      updateLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadProductInventoryResources, venue.id, productId, productType, loadProductItemById, openProductsListPage, updateLoading])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onSaveMenu = useCallback(
    async (changedProductItem: ProductInventoryItem, createNext: boolean) => {
      if (isSaving) {
        return
      }
      try {
        updateSaving(true)
        if (productId) {
          await saveProductItemById(venueId, productId, changedProductItem)
        } else {
          await createProductItem(venueId, changedProductItem)
        }
        if (createNext) {
          clearProductData(productType)
        } else {
          openProductsListPage()
        }
      } finally {
        updateSaving(false)
      }
    },
    [clearProductData, createProductItem, isSaving, openProductsListPage, productId, productType, saveProductItemById, venueId]
  )

  const productItemTitle = productId
    ? `Edit ${product.typeDisplayName}: ${product.name}`
    : `Create ${PRODUCT_TYPES_DISPLAY_NAMES[productType]}`

  const contentTitle = isLoading ? 'Loading...' : productItemTitle

  return (
    <DirtyStateUnloader isDirty={isDirty} modalQuestionContext={`this ${PRODUCT_TYPES_DISPLAY_NAMES[productType]}`}>
      <ContentLayout
        routes={routes}
        title={contentTitle}
        venue={venue}
        venues={venues}
        showSpinner={isLoading || isSaving}
        spinnerImgUrl={mediaUrl}
        onChangeVenue={onChangeVenue}
        overrideChangeVenue
      >
        <ProductForm
          {...{
            isSaving,
            product,
            productType,
            productTags,
            modifiers,
            modifierGroups,
            isCropImageModalOpen,
            updateSelectedGroupModifiers,
            updateSelectedGroups,
            updateTaxRate,
            updateTaxGroupId,
            updateName,
            updateDescription,
            updatePrice,
            updateSelectedProductTags,
            updateSelectedModifiers,
            removeSelectedModifier,
            updateMinAmount,
            updateMaxAmount,
            updateMaxSameModifiersAmount,
            updateAllowOrderSameModifiers,
            updateVerifyAge,
            venue,
          }}
          onSave={onSaveMenu}
          onCancel={openProductsListPage}
        />
      </ContentLayout>
    </DirtyStateUnloader>
  )
}

const mapStateToProps = (state: State) => {
  const { mediaUrl, venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    mediaUrl,
    venues,
    product: state.orderingProductInventory.product,
    productTags: state.orderingProductInventory.productTags,
    modifiers: selectOrderingProductInventoryModifiers(state),
    modifierGroups: selectOrderingProductInventoryModifierGroups(state),
    isCropImageModalOpen: state.orderingProductInventory.isCropImageModalOpen,
    isDirty: state.orderingProductInventory.isDirty,
  }
}

const mapDispatchToProps = {
  onChangeVenue: onChangeVenueExitEditFlow,
  loadProductItemById: ProductInventoryActions.loadProductItemById,
  loadProductInventoryResources: ProductInventoryActions.loadProductInventoryResources,
  saveProductItemById: ProductInventoryActions.saveProductItemById,
  createProductItem: ProductInventoryActions.createProductItem,
  clearProductData: ProductInventoryActions.clearProductInventoryData,
  updateName: ProductInventoryActions.updateName,
  updateDescription: ProductInventoryActions.updateDescription,
  updatePrice: ProductInventoryActions.updatePrice,
  updateSelectedProductTags: ProductInventoryActions.updateSelectedProductTags,
  updateSelectedModifiers: ProductInventoryActions.updateSelectedModifiers,
  removeSelectedModifier: ProductInventoryActions.removeSelectedModifier,
  updateSelectedGroups: ProductInventoryActions.updateSelectedGroups,
  updateSelectedGroupModifiers: ProductInventoryActions.updateSelectedGroupModifiers,
  updateTaxRate: ProductInventoryActions.updateTaxRate,
  updateTaxGroupId: ProductInventoryActions.updateTaxGroupId,
  updateMinAmount: ProductInventoryActions.updateMinAmount,
  updateMaxAmount: ProductInventoryActions.updateMaxAmount,
  updateMaxSameModifiersAmount: ProductInventoryActions.updateMaxSameModifiersAmount,
  updateAllowOrderSameModifiers: ProductInventoryActions.updateAllowOrderSameModifiers,
  updateVerifyAge: ProductInventoryActions.updateVerifyAge,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem)
