import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { useGetActivityLogQuery, venueGroupApi } from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { DateRangePicker, FormSelect, Label, DatePickerUtils } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  Flex,
  HStack,
  LeftBorder,
  Loader,
  Pagination,
  PaginationSpace,
  PaginationWrapper,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useStoreSelector } from '@sevenrooms/mgr-core'
import { activityLogMessages } from '../shared/ActivityLog.locales'
import { useVenueGroupActivityLogForm } from './VenueGroupActivityLog.zod'

const paginationOptions = [
  { id: '50', label: '50' },
  { id: '100', label: '100' },
  { id: '250', label: '250' },
]

const allOption = {
  id: 'ALL',
  label: 'All',
}

export function VenueGroupActivityLog() {
  const { venueGroup, userDomain } = useStoreSelector(state => state.appState)
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()
  const { field, watch, setValue } = useForm(useVenueGroupActivityLogForm())
  const watchAllFields = watch()
  const [prevCursorMap, setPrevCursorMap] = useState<{ [key: number]: string | null }>({ 1: null })
  const [currentPage, setCurrentPage] = useState(1)

  const formValues = useMemo(
    () => ({
      fromDate: watchAllFields.dateRange?.startDate?.toISOString(),
      toDate: watchAllFields.dateRange?.endDate?.toISOString(),
      user: watchAllFields.user === 'ALL' ? null : watchAllFields.user,
      category: watchAllFields.category === 'ALL' ? null : watchAllFields.category,
      limit: watchAllFields.limit || 100,
      cursor: watchAllFields.cursor,
    }),
    [watchAllFields]
  )

  const onChange = useCallback(() => {
    setPrevCursorMap({ 1: null })
    setCurrentPage(1)
  }, [])

  const { data, isLoading } = useGetActivityLogQuery({ venueGroupId: venueGroup.id, ...formValues })

  const categoryOptions = useMemo(
    () => [allOption, ...(data?.categories || [])].sort((a, b) => (a.label < b.label ? -1 : 1)),
    [data?.categories]
  )
  const userOptions = useMemo(() => [allOption, ...(data?.users || [])].sort((a, b) => (a.label < b.label ? -1 : 1)), [data?.users])

  return (
    <ContentLayout title="Group Activity Log" venueGroup={venueGroup} userDomain={userDomain}>
      <Box p="s" data-test="venue-group-activity-log-main-box">
        <HStack spacing="m">
          <Label primary="">
            <DateRangePicker
              startDateProps={{
                isOutsideRange: DatePickerUtils.isAfterCurrentFn(),
              }}
              endDateProps={{
                isOutsideRange: DatePickerUtils.isAfterCurrentFn(),
              }}
              field={field.prop('dateRange')}
              onStartDateChange={onChange}
              onEndDateChange={onChange}
            />
          </Label>
          <Label primary="">
            <FormSelect
              field={field.prop('user')}
              placeholder={formatMessage(activityLogMessages.selectUser)}
              options={userOptions}
              searchable
              onChange={onChange}
            />
          </Label>
          <Label primary="">
            <FormSelect
              field={field.prop('category')}
              placeholder={formatMessage(activityLogMessages.selectCategory)}
              options={categoryOptions}
              searchable
              onChange={onChange}
            />
          </Label>
        </HStack>
      </Box>
      {isLoading && (
        <Flex minHeight="274px" justifyContent="center" alignItems="center" data-test="loading-box">
          <Loader>
            <Text textStyle="body1">{formatMessage(commonMessages.loading)}</Text>
          </Loader>
        </Flex>
      )}
      <Box p="s">
        {!isLoading && (
          <Box mr="xxl">
            {data && (
              <Box>
                <VStack
                  spacing="s"
                  p="s"
                  justifyContent={data.activities?.length === 0 ? 'center' : 'flex-start'}
                  borderTopColor="borders"
                  borderLeftColor="borders"
                  borderRightColor="borders"
                  borderTopLeftRadius="s"
                  borderTopRightRadius="s"
                  height="calc(100vh - 30em)"
                  overflow="scroll"
                >
                  {data.activities?.length === 0 && (
                    <Text textStyle="descriptions" textAlign="center">
                      {formatMessage(activityLogMessages.notFound)}
                    </Text>
                  )}
                  {data?.activities.map(activity => (
                    <Text textStyle="body2" key={activity.id}>
                      [{activity.timestamp}] {activity.user.firstName} {activity.user.lastName} {activity.action}
                    </Text>
                  ))}
                </VStack>
                <PaginationWrapper>
                  <LeftBorder />
                  <PaginationSpace>
                    <Pagination
                      rowsPerPage={data.pagination.limit || 100}
                      canNextPage={
                        data.pagination.nextCursor !== data.pagination.prevCursor && data.activities.length === data.pagination.limit
                      }
                      hideLastPage
                      canPreviousPage={prevCursorMap[currentPage - 1] !== undefined}
                      currentPage={currentPage}
                      totalItemsCount={data.pagination.total}
                      onPerPageChange={limit => {
                        onChange()
                        setValue('limit', limit)
                        setValue('cursor', undefined)
                      }}
                      options={paginationOptions}
                      goToPage={page => {
                        switch (page) {
                          case 'prev':
                            setValue('cursor', prevCursorMap[currentPage - 1] as string)
                            setCurrentPage(currentPage - 1)
                            break
                          case 'first':
                            setValue('cursor', prevCursorMap[1] as string)
                            setCurrentPage(1)
                            break
                          case 'next':
                            setValue('cursor', data.pagination.nextCursor)
                            prevCursorMap[currentPage + 1] = data.pagination.nextCursor
                            setPrevCursorMap(prevCursorMap)
                            setCurrentPage(currentPage + 1)
                            break
                          default:
                        }
                        dispatch(venueGroupApi.util.resetApiState())
                      }}
                    />
                  </PaginationSpace>
                </PaginationWrapper>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ContentLayout>
  )
}
