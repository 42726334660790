import React from 'react'
import styled, { css } from 'styled-components'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import { textCell, dateCell } from 'svr/component-lib/Generic/Tables/Cells'

const OutgoingEmailsListContainer = styled.div`
  flex: 1;
`
const customTableContainerStyles = css`
  padding: 0;
  background-color: ${props => props.theme.color.white};
`
const OutgoingEmailsList = ({ emails, isLoading, onOutgoingEmailsPageChange, page, pageSize, pages, rowClick, locale }) => (
  <OutgoingEmailsListContainer>
    <Table
      data={emails}
      loading={isLoading}
      enableTooltips
      rowClick={rowClick}
      customTableContainerStyles={customTableContainerStyles}
      manual
      onPageChange={onOutgoingEmailsPageChange}
      page={page}
      pageSize={pageSize}
      pages={pages}
      loadingText="Searching for emails..."
      noDataText="No emails found"
      columns={[
        {
          Header: 'FROM',
          accessor: 'from_name',
          Cell: textCell,
          sortable: false,
        },
        {
          Header: 'TO',
          accessor: 'recipients_formatted',
          Cell: textCell,
          sortable: false,
        },
        {
          Header: 'MESSAGE',
          accessor: 'subject',
          Cell: textCell,
          sortable: false,
          minWidth: 175,
        },
        {
          Header: 'DATE/TIME',
          accessor: 'local_date_time',
          Cell: dateCell.bind(null, locale, true, 'MM-DD-YYYY HH:mm:ss'),
          sortable: false,
        },
      ]}
    />
  </OutgoingEmailsListContainer>
)

export default OutgoingEmailsList
