import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import shallowCompare from 'react-addons-shallow-compare'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { getSortAndIntervalOrdersForShift } from 'mgr/lib/utils/ShiftUtils'
import {
  getSortOrderStatsForShift,
  getStatsForSeatingAreaAndShift,
  getStatsForShift,
  getUnassignedGuestSizes,
} from 'mgr/pages/shared/utils/Actuals'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import { GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import GridCollapsibleSection from 'mgr/pages/single-venue/dayview/components/grid/GridCollapsibleSection'
import { GridSection, GridUnassignedSection } from 'mgr/pages/single-venue/dayview/components/grid/GridSection'
import GridTimeBar from 'mgr/pages/single-venue/dayview/components/grid/GridTimeBar'
import { selectNoteAndEvents } from 'mgr/pages/single-venue/dayview/selectors/DayViewSelectors'
import GridActionsContainer from './GridActionsContainer'

const GridContainer = styled.div`
  background-color: ${props => props.theme.color.greyMuted};
  width: 100%;
  min-height: 1000px;
`

class Grid extends Component {
  constructor(props) {
    super(props)
    this.isScrollSyncing = {}
    this.handleScrollChange = this.handleScrollChange.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.shouldUpdate && shallowCompare(this, nextProps, nextState) // be specific about when this component rerenders
  }

  handleScrollChange(scrollPos, sectionId) {
    if (!this.isScrollSyncing[sectionId]) {
      $('.scrollSync').each((idx, el) => {
        const syncingSectionId = $(el).attr('data-section-id')
        this.isScrollSyncing[syncingSectionId] = true
        $(el).scrollLeft(scrollPos)
      })
    }
    this.isScrollSyncing[sectionId] = false
  }

  filterSeatingAreasForShift(seatingAreasToTables, shift) {
    if (_.isEmpty(seatingAreasToTables)) {
      return []
    }

    let filteredSeatingAreas = seatingAreasToTables.filter(seatingArea => seatingArea.id !== undefined)

    if (shift && !_.isEmpty(shift.seating_areas)) {
      filteredSeatingAreas = filteredSeatingAreas
        .filter(seatingArea => !_.isEmpty(seatingArea.tables))
        .sort((a, b) => {
          const isSeatingAreaAIncludedInShift = _.includes(shift.seating_areas, a.id)
          const isSeatingAreaBIncludingInShift = _.includes(shift.seating_areas, b.id)

          if (isSeatingAreaAIncludedInShift && !isSeatingAreaBIncludingInShift) {
            return -1
          }
          if (!isSeatingAreaAIncludedInShift && isSeatingAreaBIncludingInShift) {
            return 1
          }
          return 0
        })
    }

    return filteredSeatingAreas
  }

  getSeatingAreaStats(seatingAreaToTable, shift) {
    if (shift && !_.isEmpty(this.props.gridStatsByShift)) {
      return getStatsForSeatingAreaAndShift(seatingAreaToTable.id || '_UNASSIGNED', shift.persistent_id, this.props.gridStatsByShift)
    }
    return { covers: 0, count: 0 }
  }

  getTotalStats(shift) {
    if (shift && !_.isEmpty(this.props.gridStatsByShift)) {
      return getStatsForShift(shift.persistent_id, this.props.gridStatsByShift)
    }
    return { covers: 0, count: 0 }
  }

  renderSeatingAreas(seatingAreasToTables, sortOrders, intervalOrder, shift) {
    // Assigned sections
    const filteredSeatingAreas = this.filterSeatingAreasForShift(seatingAreasToTables, shift)

    let seatingAreasList = filteredSeatingAreas.map((seatingAreaToTable, idx) => {
      const seating_areas = _.get(shift, 'seating_areas')
      const { id, tables } = seatingAreaToTable
      const seatingAreaName = seatingAreaToTable.name.toUpperCase()
      const isNotOnShift = shift && !_.isEmpty(seating_areas) && !_.includes(seating_areas, id)
      const title = isNotOnShift ? `${seatingAreaName} (manual assignments only)` : seatingAreaName
      const popoverText = isNotOnShift
        ? `<div style="text-align: center;"><div>This seating area is not active for this shift, but the tables below are in the floorplan layout.</div>
            <div style="margin-top: -10px;">Reservations that are manually assigned here will not count towards the shift's pacing limitations.</div></div>`
        : null
      const seatingAreaStats = this.getSeatingAreaStats(seatingAreaToTable, shift)
      const backgroundColor = isNotOnShift && '#888c92'

      return (
        <GridCollapsibleSection
          key={id}
          title={title}
          numCovers={seatingAreaStats.covers}
          numReservations={seatingAreaStats.count}
          isCollapsed={false}
          popoverText={popoverText}
          backgroundColor={backgroundColor}
          gridSection={
            <GridSection
              key={`${id}_section`}
              sectionId={`${id}_section`}
              tables={tables}
              sortOrders={sortOrders}
              intervalOrder={intervalOrder}
              onScrollHandler={this.handleScrollChange}
              gridActions={this.props.actions}
            />
          }
        />
      )
    })

    // if there are no seating areas, then show a single section
    if (!seatingAreasList.length && shift) {
      const totalStats = this.getTotalStats(shift)
      const tables = this.props.tableItems
      seatingAreasList = [
        <GridCollapsibleSection
          key="_noSeatingAreasSection"
          title="TABLES"
          numCovers={totalStats.covers}
          numReservations={totalStats.count}
          isCollapsed={false}
          gridSection={
            <GridSection
              key="_noSeatingAreasSection_section"
              sectionId="_noSeatingAreasSection_section"
              tables={tables}
              sortOrders={sortOrders}
              intervalOrder={intervalOrder}
              onScrollHandler={this.handleScrollChange}
              gridActions={this.props.actions}
            />
          }
        />,
      ]
    }

    // Unassigned section
    const unassignedStats = this.getSeatingAreaStats('_UNASSIGNED', shift)
    const guestSizes = getUnassignedGuestSizes(this.props.gridActualsMapByShift, this.props.shiftPersistentId)

    // don't show unassigned if it doesn't exist
    if (!guestSizes.length) {
      return seatingAreasList
    }

    // this is ugly. unfortunately trying to store scrollPos in state completely kills performance
    // we need to set the initial scroll position in case there aren't any unassigned res and then
    // one is added
    const currentScroll = $('.scrollSync[data-section-id]').first().scrollLeft()

    seatingAreasList.unshift(
      <GridCollapsibleSection
        key="_unassigned"
        title="UNASSIGNED"
        numCovers={unassignedStats.covers}
        numReservations={unassignedStats.count}
        isCollapsed={false}
        gridSection={
          <GridUnassignedSection
            key="_unassigned_section"
            sectionId="_unassigned_section"
            initialScroll={currentScroll}
            guestSizes={guestSizes}
            sortOrders={sortOrders}
            intervalOrder={intervalOrder}
            onScrollHandler={this.handleScrollChange}
            gridActions={this.props.actions}
          />
        }
      />
    )

    return seatingAreasList
  }

  render() {
    const styles = {
      seatingAreas: {
        paddingTop: GridStyles.TimeHeaderHeight,
        marginBottom: 100,
      },
    }

    const { shiftPersistentId, shiftsByPersistentId, date, gridStatsByShift, venue, noteAndEvents } = this.props
    const shift = shiftPersistentId ? shiftsByPersistentId[shiftPersistentId] : null
    const { sortOrders, intervalOrder } = getSortAndIntervalOrdersForShift(date, shift)
    const sortOrderStats = getSortOrderStatsForShift(shiftPersistentId, gridStatsByShift)

    return (
      <GridContainer>
        <GridTimeBar
          key="timeBar"
          onScrollHandler={this.handleScrollChange}
          sortOrders={sortOrders}
          intervalOrder={intervalOrder}
          sortOrderStats={sortOrderStats}
          actions={this.props.actions}
          highlightBarShowing={noteAndEvents.hasNoteOrEvent}
        />
        <div style={styles.seatingAreas}>
          {this.renderSeatingAreas(this.props.seatingAreaCodesToTables, sortOrders, intervalOrder, shift)}
        </div>
        <GridActionsContainer highlightBarShowing={noteAndEvents.hasNoteOrEvent} />
      </GridContainer>
    )
  }
}

/* <GridTimeIndicator /> */

const mapStateToProps = state => ({
  venue: state.appState.venue,
  shouldUpdate: state.gridState.shouldUpdate,
  gridStatsByShift: state.gridState.gridStatsByShift,
  gridActualsMapByShift: state.gridState.gridActualsMapByShift,
  date: state.dayviewState.date,
  shiftPersistentId: state.dayviewState.shiftPersistentId,
  shiftsByPersistentId: state.dayviewState.shiftsByPersistentId,
  seatingAreaCodesToTables: state.dayviewState.dayFloorPlan.seatingAreaCodesToTables,
  tableItems: state.dayviewState.dayFloorPlan.tableItems,
  isActualMoveMode: state.gridState.isActualMoveMode,
  noteAndEvents: selectNoteAndEvents(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changeTableSort: Actions.changeGridTableSort,
      showActual: Actions.showActualAction,
      showBlock: Actions.showBlockAction,
      showAccessRule: Actions.showAccessRuleAction,
      showInfoMessage: GlobalActions.showInfoMessage,
      showSuccessMessage: GlobalActions.showSuccessMessage,
      showWarnMessage: GlobalActions.showWarnMessage,
      showErrorMessage: GlobalActions.showErrorMessage,
    },
    dispatch
  ),
})

Grid = Radium(Grid)
Grid = connect(mapStateToProps, mapDispatchToProps)(Grid)

export default Grid
