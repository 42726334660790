import { useMemo } from 'react'
import { type InsightType, type Insight, InsightStatus, type InsightVenueUser } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Link } from '@sevenrooms/core/navigation'
import { BaseSection, Box, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { useUserContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../locales'
import { getTypeGroupInfo } from '../../utils'
import { InsightRow } from '../InsightRow'
import { StatusHeader } from '../Status'

export interface InsightTypeGroupSectionProps {
  insightTypeGroup: string
  insightTypes: InsightType[]
  insightVenueUsers: InsightVenueUser[]
}

const statusOrder = [InsightStatus.NEW, InsightStatus.TRACKING, InsightStatus.DECLINED]

export function InsightTypeGroupSection({ insightTypeGroup, insightTypes, insightVenueUsers }: InsightTypeGroupSectionProps) {
  const { formatMessage } = useLocales()
  const user = useUserContext()

  const { formattedTitle, formattedDescription, learnUrl } = useMemo(() => {
    const { title, description, learnUrl } = getTypeGroupInfo(insightTypeGroup)
    return {
      formattedTitle: title ? formatMessage(title) : '',
      formattedDescription: description ? formatMessage(description) : '',
      learnUrl,
    }
  }, [formatMessage, insightTypeGroup])

  const userNameById = Object.fromEntries(insightVenueUsers.map(x => [x.id, x.name]))
  const currentVenueUserId = insightVenueUsers.find(x => x.userId === user?.id)?.id

  const displaySet = useMemo(() => {
    const insights = insightTypes.reduce((insightAcc: Insight[], insightType) => {
      insightAcc.push(...(insightType.insights ?? []))
      return insightAcc
    }, [])

    return insights.length
      ? statusOrder.map(status => {
          const insightSet = insights.filter(insight => insight.status === status)
          return [
            <StatusHeader key={status} variant={status} count={insightSet.length} />,
            insightSet.map(insight => (
              <InsightRow
                key={insight.id}
                insights={insights}
                insight={insight}
                insightAssignedUserName={insight.assignedUserId ? userNameById[insight.assignedUserId] : undefined}
                isAssignedToCurrentUser={insight.assignedUserId === currentVenueUserId}
              />
            )),
          ]
        })
      : [
          <StatusHeader key="emptyStatus" variant="empty" count={0} />,
          <DividerLine key="emptyDivider" margin="none" />,
          <Box key="emptyMessage" mt="sm">
            <SecondaryText>{formatMessage(insightsMessages.insightsEmptyMessage)}</SecondaryText>
          </Box>,
        ]
  }, [insightTypes, formatMessage, userNameById, currentVenueUserId])

  // Indicative that we have not
  // prepared any content even if
  // the type exists
  if (!formattedTitle) {
    return null
  }

  return (
    <BaseSection title={formattedTitle} maxWidth="100%">
      <Box mb="lm">
        <Box ml="lm" mr="lm">
          <SecondaryText fontSize="m">{formattedDescription}</SecondaryText>
        </Box>
        <Box mt="sm" mb="sm" ml="lm">
          <Text fontSize="m">
            <Link isExternal to={learnUrl || ''}>
              Learn more
            </Link>
          </Text>
        </Box>
        <Box mt="sm" mb="sm" ml="lm" mr="lm">
          {displaySet}
        </Box>
      </Box>
    </BaseSection>
  )
}
