import Radium from 'radium'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import ManagerStyles from 'mgr/layout/Styles'
import { cancelMoveActualAction } from 'mgr/pages/single-venue/dayview/actions/Actions'
import { GridStyles, DayViewStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

const GridMoveModeStyles = {
  body: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 50,
    pointerEvents: 'none',
  },
  topBar: {
    position: 'fixed',
    zIndex: 60,
    height: GridStyles.MoveModeTopBarHeight,
    left: 0,
    right: 0,
    backgroundColor: '#E6E8EB',
    top: 0,
    marginTop: ManagerStyles.DayviewToolbarHeight,
    pointerEvents: 'auto',
  },
  leftMask: {
    backgroundColor: 'black',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    opacity: '.43',
    zIndex: 1,
    pointerEvents: 'auto',
  },
  rightMask: {
    backgroundColor: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    opacity: '.43',
    zIndex: 1,
    pointerEvents: 'auto',
  },
  swimLane: {
    pointerEvents: 'none',
    background: 'transparent',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 500,
  },
  close: {
    position: 'absolute',
    pointerEvents: 'auto',
    zIndex: 2,
    right: 30,
    color: '#eee',
    fontSize: '30px',
    cursor: 'pointer',
    ':hover': {
      color: 'white',
    },
  },
}

class GridMoveMode extends PureComponent {
  handleClickClose = () => {
    this.props.actions.cancelMoveActual()
  }

  getDurationWidth(duration) {
    const durationOrders = duration / 15
    const durationWidth = durationOrders * GridStyles.TimeHeaderWidth + durationOrders - 4 + Math.floor(durationOrders / 6)
    return durationWidth + 4
  }

  render() {
    const { actual, boundingRect, highlightBarShowing, venueSettings } = this.props
    const leftAdjust = {
      width: boundingRect.left,
    }
    const swimAdjust = {
      left: boundingRect.left,
      width: this.getDurationWidth(actual.duration + (actual.buffer_mins || 0) || 0),
    }
    const rightAdjust = {
      left: swimAdjust.left + swimAdjust.width,
    }

    const topPosition = 165 + (highlightBarShowing ? DayViewStyles.NotesHighlightBarHeight : 0)

    return (
      <div>
        <div style={GridMoveModeStyles.body}>
          <div style={[GridMoveModeStyles.leftMask, leftAdjust]} />
          <div style={[GridMoveModeStyles.swimLane, swimAdjust]} />
          <div style={[GridMoveModeStyles.rightMask, rightAdjust]} />
          <div
            data-test="sr-button-close"
            style={[VmsIconSizes.xsmall, GridMoveModeStyles.close, { top: topPosition }]}
            onClick={this.handleClickClose}
          >
            {VmsIcons.Close}
          </div>
        </div>
      </div>
    )
  }
}

GridMoveMode = Radium(GridMoveMode)

const mapStateToProps = (state, ownProps) => ({
  venueSettings: state.appState.venueSettings,
  boundingRect: state.gridState.boundingRect,
  actual: state.gridState.movingActual,
})
const mapDispatchToProps = dispatch => ({
  actions: {
    cancelMoveActual: () => {
      dispatch(cancelMoveActualAction())
    },
  },
})

GridMoveMode = connect(mapStateToProps, mapDispatchToProps)(GridMoveMode)

export default GridMoveMode
