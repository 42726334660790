import { useLocales } from '@sevenrooms/core/locales'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import { settingsMessages } from '../settings.locales'
import { LanguageSettings } from './LanguageSettings'

interface PagesLanguageSettingsRoutes {
  FEEDBACK_PAGE_LANGUAGE: string
  RECEIPT_PAGE_LANGUAGE: string
  PAYLINK_PAGE_LANGUAGE: string
  RES_WIDGET_CANCEL_PAGE_LANGUAGE: string
  RES_WIDGET_CONFIRM_PAGE_LANGUAGE: string
  EMAIL_PREFERENCE_CENTER_LANGUAGE: string
  MARKETING_SUBSCRIPTION: string
}

interface PagesLanguageSettingsProps {
  venue: Venue
  venues: UserDomainVenue[]
  routes: PagesLanguageSettingsRoutes
  onChangeVenue: (venue: UserDomainVenue) => void
}

export function PagesLanguageSettings({ onChangeVenue, routes, venue, venues }: PagesLanguageSettingsProps) {
  const { formatMessage } = useLocales()
  return (
    <LanguageSettings title={formatMessage(settingsMessages.pages)} venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
      <LanguageSettings.Block label={formatMessage(settingsMessages.pages)}>
        {venue.isDiningClass && (
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.pagesFeedback)}
            id="row-block-feedback-language"
            editLink={routes.FEEDBACK_PAGE_LANGUAGE}
          />
        )}
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.pagesReceipt)}
          id="row-block-receipt-language"
          editLink={routes.RECEIPT_PAGE_LANGUAGE}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.pagesPaylink)}
          id="row-block-paylink-language"
          editLink={routes.PAYLINK_PAGE_LANGUAGE}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.pagesCancellation)}
          id="row-block-res-cancel-language"
          editLink={routes.RES_WIDGET_CANCEL_PAGE_LANGUAGE}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.pagesConfirmation)}
          id="row-block-res-confirm-language"
          editLink={routes.RES_WIDGET_CONFIRM_PAGE_LANGUAGE}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.pagesEmailPreferenceCenter)}
          id="row-block-email-preference-center-language"
          editLink={routes.EMAIL_PREFERENCE_CENTER_LANGUAGE}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.pagesMarketingSubscription)}
          id="row-block-marketing-subscription-language"
          editLink={routes.MARKETING_SUBSCRIPTION}
        />
      </LanguageSettings.Block>
    </LanguageSettings>
  )
}
