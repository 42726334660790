import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack, DividerLine, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText, List, ListItem } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../locales'
import { formatBold, variantColor } from '../../utils'
import { StatusBadge } from '../Status'

export function InsightsSidebar() {
  const { formatMessage } = useLocales()
  return (
    <VStack
      p="l m lm m"
      mt="lm"
      mb="sm"
      m="lm 4xl sm none"
      spacing="sm"
      width="30%"
      backgroundColor="primaryBackground"
      borderColor="dividerLines"
      borderRadius="s"
      data-test="insights-sidebar"
    >
      <HStack alignItems="center" pl="xxs">
        <Icon name="VMSWeb-insight-new" size="3x" color="status" />
        <Text textStyle="h2">{formatMessage(insightsMessages.sidebarHeader)}</Text>
      </HStack>
      <SecondaryText>{formatMessage(insightsMessages.sidebarDescription)}</SecondaryText>
      <SecondaryText>
        <List>
          <ListItem>{formatMessage(insightsMessages.sidebarSuggestionsBullet1)}</ListItem>
          <ListItem>{formatMessage(insightsMessages.sidebarSuggestionsBullet2)}</ListItem>
          <ListItem>{formatMessage(insightsMessages.sidebarSuggestionsBullet3)}</ListItem>
        </List>
      </SecondaryText>
      <DividerLine mt="sm" mb="sm" />
      <Text textStyle="h3" color="secondaryFont" letterSpacing="m">
        {formatMessage(insightsMessages.sidebarInsightStatuses)}
      </Text>
      <HStack pt="xxs" pb="s" spacing="s">
        <StatusBadge variant="new" displayCompact />
        <StatusBadge variant="tracking" displayCompact />
        <StatusBadge variant="declined" displayCompact />
      </HStack>
      <SecondaryText>
        {formatMessage(insightsMessages.sidebarNewDescription, {
          strong: (chunks: string[]) => formatBold(chunks, variantColor.new),
        })}
      </SecondaryText>
      <SecondaryText>
        {formatMessage(insightsMessages.sidebarTrackingDescription, {
          strong: (chunks: string[]) => formatBold(chunks, variantColor.tracking),
        })}
      </SecondaryText>
      <SecondaryText>
        {formatMessage(insightsMessages.sidebarDeclinedDescription, {
          strong: (chunks: string[]) => formatBold(chunks, variantColor.declined),
        })}
      </SecondaryText>
      <DividerLine mt="sm" mb="sm" />
      <Text textStyle="h3" color="secondaryFont" letterSpacing="m">
        {formatMessage(insightsMessages.sidebarFeedbackRequestHeader)}
      </Text>
      <SecondaryText>{formatMessage(insightsMessages.sidebarFeedbackRequestText)}</SecondaryText>
      <Box>
        <Button
          variant="primary"
          size="s"
          fullWidth
          data-test="insight-sidebar-request-feedback-button"
          id="insight-sidebar-request-feedback-button"
        >
          {formatMessage(insightsMessages.sidebarFeedbackRequestButtonLabel)}
        </Button>
      </Box>
    </VStack>
  )
}
