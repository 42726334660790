import { defineMessages } from '@sevenrooms/core/locales'

export const ordersMessages = defineMessages({
  ordersTitle: {
    id: 'orders.title',
    defaultMessage: 'Orders',
  },
  ordersQuickLinksTitle: {
    id: 'orders.links.quick.title',
    defaultMessage: 'Quick Links',
  },
  ordersQuickLinkOrderingSite: {
    id: 'orders.links.quick.orderingSite.title',
    defaultMessage: 'Ordering Sites',
  },
  ordersQuickLinkMenuManagement: {
    id: 'orders.links.quick.menuManagement.title',
    defaultMessage: 'Menu Management',
  },
  ordersQuickLinkRoomNumbers: {
    id: 'orders.links.quick.roomNumbers.title',
    defaultMessage: 'Room Numbers',
  },
  ordersQuickLinkEightySixProducts: {
    id: 'orders.links.quick.eightySixProducts.title',
    defaultMessage: '86 Products',
  },
  ordersQuickLinkCheckoutSettings: {
    id: 'orders.links.quick.checkoutSettings.title',
    defaultMessage: 'Checkout Settings',
  },
  ordersQuickLinkGuestNotifications: {
    id: 'orders.links.quick.guestNotifications.title',
    defaultMessage: 'Guest Notifications',
  },
  ordersQuickLinkOrderPacing: {
    id: 'orders.links.quick.orderPacing.title',
    defaultMessage: 'Order Pacing',
  },
  ordersQuickLinkServiceFees: {
    id: 'orders.links.quick.serviceFees.title',
    defaultMessage: 'Service Fees',
  },
  ordersQuickLinkOrdersReporting: {
    id: 'orders.links.quick.ordersReporting.title',
    defaultMessage: 'Orders Reporting',
  },
  ordersQuickLinkPromoCodeManagement: {
    id: 'orders.links.quick.promoCodeManagement.title',
    defaultMessage: 'Promo Code Management',
  },
  noOrdersPrimary: {
    id: 'orders.absent.primary',
    defaultMessage: 'You do not have any orders',
  },
  noOrdersSecondary: {
    id: 'orders.absent.secondary',
    defaultMessage: 'All orders for this venue will show up here',
  },
  emptyTableTitle: {
    id: 'orders.emptyTableTitle',
    defaultMessage: 'No orders have been made yet',
  },
  ordersEmptySearchResult: {
    id: 'orders.search.result.empty',
    defaultMessage: 'No orders for selected filters',
  },
  ordersTableColumnNameName: {
    id: 'orders.table.column.header.name',
    defaultMessage: 'Name',
  },
  ordersTableColumnNameStatus: {
    id: 'orders.table.column.header.status',
    defaultMessage: 'Status',
  },
  ordersTableColumnNameReadyTime: {
    id: 'orders.table.column.header.readyTime',
    defaultMessage: 'Ready Time',
  },
  ordersTableColumnNameOrderNumber: {
    id: 'orders.table.column.header.orderNumber',
    defaultMessage: 'Order Number',
  },
  ordersTableColumnNameFulfillment: {
    id: 'orders.table.column.header.fulfillment',
    defaultMessage: 'Fulfillment',
  },
  ordersTableColumnNameTotal: {
    id: 'orders.table.column.header.total',
    defaultMessage: 'Order Total',
  },
  ordersTableColumnNameNotes: {
    id: 'orders.table.column.header.notes',
    defaultMessage: 'Notes',
  },
  ordersTableFilterDate: {
    id: 'orders.table.column.filter.date',
    defaultMessage: 'Date',
  },
  ordersTableFilterStatus: {
    id: 'orders.table.column.filter.status',
    defaultMessage: 'Status Filter',
  },
  ordersTableFilterStatusPlaceholder: {
    id: 'orders.table.column.filter.status.placeholder',
    defaultMessage: 'Select Status(s)',
  },
  ordersTableFilterStatusQueued: {
    id: 'orders.table.column.filter.status.queued',
    defaultMessage: 'Queued',
  },
  ordersTableFilterStatusOpen: {
    id: 'orders.table.column.filter.status.open',
    defaultMessage: 'Open',
  },
  ordersTableFilterStatusFailedInjection: {
    id: 'orders.table.column.filter.status.failedInjection',
    defaultMessage: 'Failed injection',
  },
  ordersTableFilterStatusWorking: {
    id: 'orders.table.column.filter.status.working',
    defaultMessage: 'Working on Order',
  },
  ordersTableFilterStatusReadyForPickup: {
    id: 'orders.table.column.filter.status.readyForPickup',
    defaultMessage: 'Ready for Pickup',
  },
  ordersTableFilterStatusReadyForDriver: {
    id: 'orders.table.column.filter.status.readyForDriver',
    defaultMessage: 'Ready for Driver',
  },
  ordersTableFilterStatusWithDriver: {
    id: 'orders.table.column.filter.status.withDriver',
    defaultMessage: 'With Driver',
  },
  ordersTableFilterStatusClosed: {
    id: 'orders.table.column.filter.status.closed',
    defaultMessage: 'Closed',
  },
  ordersTableFilterStatusVoid: {
    id: 'orders.table.column.filter.status.void',
    defaultMessage: 'Void',
  },
  ordersTableFilterFulfillment: {
    id: 'orders.table.column.filter.fulfillment',
    defaultMessage: 'Fulfillment Filter',
  },
  ordersTableFilterFulfillmentPickup: {
    id: 'orders.table.column.filter.fulfillment.pickup',
    defaultMessage: 'Pickup',
  },
  ordersTableFilterFulfillmentDelivery: {
    id: 'orders.table.column.filter.fulfillment.delivery',
    defaultMessage: 'Delivery',
  },
  ordersTableFilterFulfillmentOnPremise: {
    id: 'orders.table.column.filter.fulfillment.onPremise',
    defaultMessage: 'On-Premise',
  },
  ordersTableFilterFulfillmentRoomService: {
    id: 'orders.table.column.filter.fulfillment.roomService',
    defaultMessage: 'Room Service',
  },
  ordersTableFilterFulfillmentPlaceholder: {
    id: 'orders.table.column.filter.fulfillment.placeholder',
    defaultMessage: 'Select Fulfillment(s)',
  },
  ordersTableFilterBySearchStringPlaceholder: {
    id: 'orders.table.column.filter.searchString.placeholder',
    defaultMessage: 'Search for name, phone/order number...',
  },
  ordersFooterTotalAll: {
    id: 'orders.footer.total.all',
    defaultMessage: 'Total Orders',
  },
  ordersFooterTotalVoided: {
    id: 'orders.footer.total.voided',
    defaultMessage: 'Voided Orders',
  },
  ordersFooterTotalRevenue: {
    id: 'orders.footer.total.revenue',
    defaultMessage: 'Total Revenue',
  },
  ordersFooterTotalInfo: {
    id: 'orders.footer.total.info',
    defaultMessage: 'Total Orders does <em>not</em> include Voided Orders',
  },
  ordersFooterSeparator: {
    id: 'orders.footer.separator',
    defaultMessage: '{left} of {right}',
  },
  ordersNotificationChangeStatusFailure: {
    id: 'orders.notification.changeStatus.failure',
    defaultMessage: 'There was an error changing status',
  },
  ordersNotificationChangeStatusSuccess: {
    id: 'orders.notification.changeStatus.success',
    defaultMessage: 'Status is changed to <status></status>',
  },
  ordersNotificationRefundSuccess: {
    id: 'orders.notification.refund.success',
    defaultMessage: 'Order is now refunded',
  },
  ordersNotificationRefundFailure: {
    id: 'orders.notification.refund.failure',
    defaultMessage: 'There was an error when attempting to refund the transaction. Please try again.',
  },
  ordersNotificationSuccess: {
    id: 'orders.notification.success',
    defaultMessage: 'Notification Sent',
  },
  ordersNotificationFailure: {
    id: 'orders.notification.failure',
    defaultMessage:
      'There was an error when attempting to send the refund notification. Please try to re-send refund notification from the order slideout.',
  },
  ordersRefundOrderModalTitle: {
    id: 'orders.modal.refund.title',
    defaultMessage: "Refund Order for '{name}'?",
  },
  ordersRefundOrderModalBody: {
    id: 'orders.modal.refund.body',
    defaultMessage: 'Would you like to fully refund this order?',
  },
} as const)
