import { css } from 'styled-components'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import { customBorderlessTableContainerStyles } from 'svr/component-lib/Generic/Tables/BorderlessTable'
import { SettingsTablePagination } from './SettingsTablePagination'
import type { Column, SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

const TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
const DEFAULT_PAGE_SIZE = TABLE_PAGE_SIZE_OPTIONS[1]

const customStyles = css`
  ${customBorderlessTableContainerStyles}
  .ReactTable {
    border-radius: 4px;
    border-width: 1px;
    margin-top: ${props => props.theme.gutter.double};
    .rt-thead {
      .rt-th {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        padding: 19px ${props => props.theme.gutter.double};
      }
    }
    .rt-tbody {
      .rt-td {
        font-size: 14px;
        line-height: 18px;
        padding: 12px ${props => props.theme.gutter.double};
      }
    }
  }
`
interface SettingsTableProps<T> {
  data: T[]
  columns: Column[]
  isLoading?: boolean
  sorted?: SortColumn[]
  onSortedChange?: (data: SortColumn[]) => void
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
function SettingsTable<T extends unknown>({ data, columns, sorted, onSortedChange, isLoading = false }: SettingsTableProps<T>) {
  return (
    <Table
      data={data}
      columns={columns}
      loading={isLoading}
      customTableContainerStyles={customStyles}
      sortable
      resizable={false}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      showPageSizeOptions
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
      minRows={0}
      onSortedChange={onSortedChange}
      sorted={sorted}
      customPaginationComponent={SettingsTablePagination}
    />
  )
}

SettingsTable.defaultProps = {
  isLoading: false,
  sorted: undefined,
  onSortedChange: undefined,
}

export default SettingsTable
