import Konva from 'konva'
import _ from 'lodash'
import React from 'react'
import { Stage, Layer, Circle, Rect, Text, Image } from 'react-konva'

const FLOORPLAN_SHAPE_NAMES = [
  // "Label" images
  'label_arrow.png',
  'label_exit.png',
  'label_headphones.png',
  // "Furniture" images
  'bar_round_corner.png',
  'bar_round.png',
  'bar_square_corner.png',
  'bar_square.png',
  'booth_half.png',
  'booth_quarter.png',
  'chair_roundback.png',
  'chair_squareback.png',
  'line_quarter.png',
  'section_corner_round.png',
  'section_corner_square.png',
  'section_end_cap.png',
  'section_end_round.png',
  'section_end_square.png',
  'section_middle.png',
  'shape_piano.png',
  'sofa_roundback_long.png',
  'sofa_roundback_short.png',
  'sofa_roundback_standard.png',
  'sofa_squareback_banquet.png',
  'sofa_squareback_long.png',
  'sofa_squareback_sectional.png',
  'sofa_squareback_short.png',
  'sofa_squareback_standard.png',
  'stair_l.png',
  'stair_spiral.png',
  'stair_straight_short.png',
  'stair_straight.png',
  'stair_u.png',
]

const selectedStrokeColor = '#73e6ff'
const selectedStrokeOffset = 8
const strokeWidth = 8

class FloorplanRenderer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scaleFactor: this.props.initialScaleFactor,
      backgroundImage: null,
      floorplanShapeImages: {},
    }
    this.handleWheelScroll = this.handleWheelScroll.bind(this)
    this.handleBackgroundImageLoad = this.handleBackgroundImageLoad.bind(this)
    this.handleFloorplanShapeImageLoad = this.handleFloorplanShapeImageLoad.bind(this)
    this.requiresBackgroundImageLoad = true
    this.preloadedFloorplanShapeImages = {}
  }

  componentDidMount() {
    window.addEventListener('wheel', this.handleWheelScroll)
    this.loadFloorplanShapeImages()
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleWheelScroll)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.backgroundImageUrl !== nextProps.backgroundImageUrl) {
      this.requiresBackgroundImageLoad = true
    }
  }

  componentDidUpdate() {
    if (this.requiresBackgroundImageLoad) {
      this.loadBackgroundImage()
      this.requiresBackgroundImageLoad = false
    }
  }

  loadBackgroundImage() {
    const { backgroundImageUrl } = this.props
    if (!_.isEmpty(backgroundImageUrl)) {
      const backgroundImage = new window.Image()
      backgroundImage.src = backgroundImageUrl
      backgroundImage.onload = e => this.handleBackgroundImageLoad(e, backgroundImageUrl)
    }
  }

  handleBackgroundImageLoad(e, backgroundImageUrl) {
    if (this.props.backgroundImageUrl !== backgroundImageUrl) {
      return
    }
    const backgroundImage = e.target
    this.setState({ backgroundImage })
  }

  loadFloorplanShapeImages() {
    // TODO: Convert to a single sprite
    for (const shapeName of FLOORPLAN_SHAPE_NAMES) {
      const shapeImage = new window.Image()
      shapeImage.src = `${window.globalInit.mediaUrl}images/floorplan-shapes/${shapeName}`
      shapeImage.onload = e => this.handleFloorplanShapeImageLoad(e, shapeName)
    }
  }

  handleFloorplanShapeImageLoad(e, shapeName) {
    const shapeImage = e.target
    this.preloadedFloorplanShapeImages[shapeName] = shapeImage
    if (_.size(this.preloadedFloorplanShapeImages) === FLOORPLAN_SHAPE_NAMES.length) {
      this.setState({
        floorplanShapeImages: this.preloadedFloorplanShapeImages,
      })
    }
  }

  handleWheelScroll(e) {
    const stage = this.getStage()
    if (_.isNil(stage)) {
      return
    }
    const pointerPosition = stage.getPointerPosition()
    if (_.isNil(pointerPosition)) {
      // Mouse wheel outside of canvas
      return
    }
    e.preventDefault()
    const oldScale = stage.scaleX()
    const scaleBy = 1.1
    const isMacLike = Boolean(window.navigator.platform.match(/(mac|iphone|ipod|ipad)/i))

    const mousePointTo = {
      x: pointerPosition.x / oldScale - stage.x() / oldScale,
      y: pointerPosition.y / oldScale - stage.y() / oldScale,
    }

    const zoomIn = isMacLike ? e.deltaY < 0 : e.deltaY > 0
    const scaleFactor = zoomIn ? oldScale * scaleBy : oldScale / scaleBy
    stage.scale({ x: scaleFactor, y: scaleFactor })

    const position = {
      x: -(mousePointTo.x - stage.getPointerPosition().x / scaleFactor) * scaleFactor,
      y: -(mousePointTo.y - stage.getPointerPosition().y / scaleFactor) * scaleFactor,
    }
    stage.position(position)
    this.setState({ scaleFactor })
  }

  getStage() {
    if (_.isNil(this.stageRef)) {
      return null
    }
    return this.stageRef.getStage()
  }

  resetPosition() {
    const stage = this.getStage()
    if (_.isNil(stage)) {
      return
    }
    stage.position({ x: 0, y: 0 })
  }

  render() {
    const { tablePositions, shapePositions, onTablePositionClick, floorplanMaxXY, selectedTablePositions } = this.props
    const { scaleFactor, backgroundImage, floorplanShapeImages } = this.state
    const selectedTablePositionIds = new Set(selectedTablePositions.map(tp => tp.id))
    const maxXScaled = scaleFactor * floorplanMaxXY.x
    const maxYScaled = scaleFactor * floorplanMaxXY.y
    const canvasBackgroundImage = backgroundImage && <Image image={backgroundImage} scale={{ x: 2.5, y: 2.5 }} />
    const canvasShapes = []
    const canvasTexts = []
    const canvasTables = []
    const canvasTableNumbers = []
    const canvasNames = []
    const selectedStrokes = []
    for (const tablePosition of tablePositions) {
      const {
        floorplan_x_coord,
        floorplan_y_coord,
        floorplan_size,
        floorplan_shape,
        floorplan_color,
        floorplan_rotation,
        name_display,
        strokeColor,
      } = tablePosition
      const tableId = tablePosition.id
      const isSelected = selectedTablePositionIds.has(tableId)
      const commonTableArgs = {
        key: tableId,
        onClick: () => onTablePositionClick(tablePosition),
        onMouseEnter: () => (this.getStage().container().style.cursor = 'pointer'),
        onMouseLeave: () => (this.getStage().container().style.cursor = 'default'),
        draggable: false,
      }
      const commonTableShapeArgs = {
        fill: floorplan_color,
        stroke: strokeColor || null,
        strokeWidth: strokeColor ? strokeWidth : 0,
        rotation: floorplan_rotation,
      }
      const isCircle = floorplan_shape === 'CIRCLE'
      const isSquare = floorplan_shape === 'SQUARE'
      const isRectangle = floorplan_shape === 'RECTANGLE'
      const tableWidth = floorplan_size * (isRectangle ? 2 : 1)
      const rotationFactorOnNameY = isCircle
        ? 1
        : isRectangle && floorplan_rotation % 180 > 1
        ? 1.5
        : isSquare && floorplan_rotation % 90 > 1
        ? 1.25
        : 1
      canvasTables.push(
        isCircle ? (
          <Circle
            x={floorplan_x_coord + floorplan_size / 2}
            y={floorplan_y_coord + floorplan_size / 2}
            radius={tableWidth / 2}
            {...commonTableArgs}
            {...commonTableShapeArgs}
          />
        ) : (
          <Rect
            x={floorplan_x_coord + tableWidth / 2}
            y={floorplan_y_coord + floorplan_size / 2}
            width={tableWidth}
            height={floorplan_size}
            offsetX={tableWidth / 2.0}
            offsetY={floorplan_size / 2.0}
            cornerRadius={floorplan_size / 8}
            {...commonTableArgs}
            {...commonTableShapeArgs}
          />
        )
      )
      if (isSelected) {
        const selectionWidth = tableWidth + 2 * selectedStrokeOffset
        const selectionHeight = floorplan_size + 2 * selectedStrokeOffset
        const commonSelectedStrokeArgs = {
          key: tableId,
          stroke: selectedStrokeColor,
          strokeWidth,
          draggable: false,
          rotation: floorplan_rotation,
        }
        selectedStrokes.push(
          isCircle ? (
            <Circle
              x={floorplan_x_coord + floorplan_size / 2}
              y={floorplan_y_coord + floorplan_size / 2}
              radius={selectionWidth / 2}
              listening={false}
              {...commonSelectedStrokeArgs}
            />
          ) : (
            <Rect
              x={floorplan_x_coord + tableWidth / 2}
              y={floorplan_y_coord + floorplan_size / 2}
              width={selectionWidth}
              height={selectionHeight}
              offsetX={selectionWidth / 2.0}
              offsetY={selectionHeight / 2.0}
              cornerRadius={selectionHeight / 6}
              listening={false}
              {...commonSelectedStrokeArgs}
            />
          )
        )
      }
      canvasTableNumbers.push(
        <Text
          text={tablePosition.item_code}
          x={floorplan_x_coord}
          y={floorplan_y_coord + floorplan_size / 2 - 16}
          align="center"
          fill="#000"
          width={tableWidth}
          fontSize={38}
          fontFamily="Roboto"
          {...commonTableArgs}
        />
      )
      if (!_.isEmpty(name_display)) {
        canvasNames.push(
          <Text
            text={name_display}
            x={floorplan_x_coord - floorplan_size / 2}
            y={floorplan_y_coord + floorplan_size * rotationFactorOnNameY + 12}
            align="center"
            fill="#FFF"
            width={tableWidth + floorplan_size}
            fontSize={25}
            fontFamily="Roboto"
            fontStyle="bold"
            {...commonTableArgs}
          />
        )
      }
    }
    for (const shapePosition of shapePositions) {
      const { configKey, x_coord, y_coord, sizeWidth, sizeHeight, rotation, flipHorizontal, flipVertical } = shapePosition
      const offsetX = sizeWidth / 2.0
      const offsetY = sizeHeight / 2.0
      const commonShapeArgs = {
        key: configKey,
        x: x_coord + offsetX,
        y: y_coord + offsetY,
        width: sizeWidth,
        height: sizeHeight,
        offsetX,
        offsetY,
        rotation,
        scaleX: flipHorizontal ? -1 : 1,
        scaleY: flipVertical ? -1 : 1,
        draggable: false,
        listening: false,
      }
      if (!_.isEmpty(shapePosition.floorText)) {
        canvasTexts.push(
          <Text
            text={shapePosition.floorText}
            fontSize={shapePosition.floorTextSize}
            fill={shapePosition.colorHex || '#FFF'}
            align="center"
            fontFamily="Roboto"
            fontStyle="bold"
            {...commonShapeArgs}
            y={commonShapeArgs.y + sizeHeight / 4} // Vertical centering hack
          />
        )
      } else if (_.isEmpty(shapePosition.furnitureImageID)) {
        canvasShapes.push(<Rect fill={shapePosition.colorHex || '#FFF'} cornerRadius={shapePosition.cornerRadius} {...commonShapeArgs} />)
      } else {
        const { furnitureImageID } = shapePosition
        // "Label" images don't include the .png suffix
        const shapeImageKey = furnitureImageID.includes('.') ? furnitureImageID : `${furnitureImageID}.png`
        const shapeImage = floorplanShapeImages[shapeImageKey]
        if (!shapeImage) {
          continue
        }
        canvasShapes.push(<Image image={shapeImage} {...commonShapeArgs} />)
      }
    }
    return (
      <Stage
        width={floorplanMaxXY.x}
        height={floorplanMaxXY.y}
        scale={{ x: scaleFactor, y: scaleFactor }}
        ref={stage => {
          this.stageRef = stage
        }}
        draggable
        dragBoundFunc={pos => ({
          x: pos.x > maxXScaled ? maxXScaled : pos.x < -maxXScaled ? -maxXScaled : pos.x,
          y: pos.y > maxYScaled ? maxYScaled : pos.y < -maxYScaled ? -maxYScaled : pos.y,
        })}
      >
        <Layer>
          {canvasBackgroundImage}
          {canvasShapes}
          {canvasTexts}
          {canvasTables}
          {canvasTableNumbers}
          {canvasNames}
          {selectedStrokes}
        </Layer>
      </Stage>
    )
  }
}

FloorplanRenderer.propTypes = {
  initialScaleFactor: React.PropTypes.number.isRequired,
  tablePositions: React.PropTypes.array.isRequired,
  shapePositions: React.PropTypes.array.isRequired,
  floorplanMaxXY: React.PropTypes.object.isRequired,
  selectedTablePositions: React.PropTypes.array.isRequired,
  onTablePositionClick: React.PropTypes.func.isRequired,
  backgroundImageUrl: React.PropTypes.string,
}

FloorplanRenderer.defaultProps = {
  selectedTablePositions: [],
  onTablePositionClick: () => {},
}

export default FloorplanRenderer
