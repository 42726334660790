import React from 'react'
import styled, { css } from 'styled-components'
import { StyledVmsIconS, VmsIcons } from 'svr/common/VmsIcons'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import editableCell from 'svr/component-lib/Generic/Tables/Cells/editable'
import VenueSupportedLanguageDropDown from 'svr/component-lib/Manager/MultiLanguage/VenueSupportedLanguageDropDown'

const Delete = styled(StyledVmsIconS)`
  right: ${props => props.theme.gutter.half};
  font-size: 18px;
  z-index: 1;
  color: ${props => props.theme.charcoal};
  display: block;
`

const customTableContainerStyles = css`
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} 0;
  .ReactTable {
    border-radius: 5px;
    border-width: 2px;
    overflow: hidden;

    &.TableDropdown {
      overflow: visible;
    }

    .rt-tr-group {
      overflow: hidden;
    }

    .rt-tr {
      font-size: ${props => props.theme.fontSize.body};
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      &.-even {
        background-color: ${props => props.theme.color.white};
      }
    }

    .rt-thead {
      .rt-th {
        padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.triple};
        font-size: ${props => props.theme.fontSize.body};
        font-weight: 500;
        line-height: 17px;
        text-transform: none;
        :focus {
          outline: none;
        }

        &.no-padding {
          padding: 0;
        }
      }

      &.-header {
        background-color: ${props => props.theme.color.white};
        box-shadow: None;
        .rt-th {
          border-right: 1px solid rgba(0, 0, 0, 0.07);
          border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
    }

    .rt-tbody {
      overflow: auto;
      min-height: 0px;
      .rt-tr:not(.-padRow) {
        :hover {
          cursor: default;
          background: ${props => props.theme.color.white};
          .highlighted {
            background: ${props => props.theme.color.white};
          }
        }
      }

      .rt-td {
        max-height: 300px;
        border-right: 1px solid rgba(0, 0, 0, 0.07);
        padding: 0;
      }
    }

    .description-head {
      background-color: ${props => props.theme.color.lightGrey};
      padding-left: ${props => props.theme.gutter.triple};
    }

    .description-body {
      background-color: ${props => props.theme.color.lightGrey};
      font-size: ${props => props.theme.fontSize.caption};
      color: ${props => props.theme.color.secondary};
    }
    .form-element.textarea {
      width: 100%;
    }

    .custom-cell-input-wrapper {
      border: none;
      border-right: 1px solid rgba(0, 0, 0, 0.07);
      padding-left: 24px;
      padding-top: 4px;
      padding-bottom: 4px;
      cursor: pointer;
    }

    .language-dropdown-header {
      overflow: visible;
      padding: 0 !important;
      border-top: 0;
      border-bottom: 0 !important;
      position: static !important;
      & > div {
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }
  }
`

const cellStyles = {
  position: 'relative',
}

class EditableLanguageTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
    }
    this.onDelete = this.props.onDelete

    const getDeleteComponent = (id, isNew) => (
      <Delete data-test-id="editable-language-table-delete" onClick={() => this.onDelete(id, isNew)}>
        {VmsIcons.Delete}
      </Delete>
    )

    const {
      defaultLanguage,
      translatedLanguages,
      elementId = '1',
      enabledLanguages,
      selectedLanguage,
      onChangeLanguage,
      descriptionHeaderText,
      descriptionPlaceholderText,
      languageColumnPlaceholderText,
      useInputTextAreaCells = true,
    } = this.props
    this.showLanguageDropdown = enabledLanguages.length > 1
    const staticColumns = [
      {
        Header: descriptionHeaderText,
        accessor: 'name',
        className: 'description-body',
        sortable: false,
        headerClassName: 'description-head',
        Cell: cellInfo =>
          editableCell(
            this.state.data,
            this.handleInputChange,
            elementId,
            0,
            '',
            cellInfo,
            descriptionPlaceholderText,
            useInputTextAreaCells
          ),
        getProps: () => ({
          style: { ...cellStyles },
        }),
        Footer: 'display',
      },
      {
        Header: defaultLanguage.name,
        accessor: defaultLanguage.value,
        sortable: false,
        Cell: cellInfo => {
          const deleteComponent =
            this.onDelete && !translatedLanguages?.length ? getDeleteComponent(cellInfo.original.id, cellInfo.original.isNew) : null
          return editableCell(
            this.state.data,
            this.handleInputChange,
            elementId,
            1,
            '',
            cellInfo,
            languageColumnPlaceholderText,
            useInputTextAreaCells,
            deleteComponent
          )
        },
        getProps: () => ({
          style: { ...cellStyles },
        }),
      },
    ]
    const translatedLanguageColumns = translatedLanguages.map(language => ({
      Header: () => {
        if (this.showLanguageDropdown) {
          return (
            <VenueSupportedLanguageDropDown
              id="supported-languages-picker"
              languages={enabledLanguages}
              selectedLanguage={selectedLanguage}
              onChange={onChangeLanguage}
              style={{
                width: '100%',
                marginRight: '0',
                height: '100%',
              }}
              borderAreaStyle={{
                height: '100%',
                boxShadow: 'none',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                paddingLeft: '24px',
                paddingRight: '24px',
              }}
              selectedItemDisplayStyle={{
                marginTop: '0',
                marginLeft: '0',
                fontSize: '15px',
                fontWeight: '500',
                lineHeight: '17px',
                textTransform: 'none',
              }}
              optionBaseStyle={{
                paddingLeft: '24px',
                paddingRight: '24px',
              }}
              dropdownRelativeStyle={{
                position: 'absolute',
                width: '451px',
              }}
              isLightTheme
            />
          )
        }
        return language.name
      },
      accessor: language.value,
      sortable: false,
      headerClassName: this.showLanguageDropdown ? 'language-dropdown-header' : '',
      Cell: cellInfo => {
        const deleteComponent = this.onDelete ? getDeleteComponent(cellInfo.original.id, cellInfo.original.isNew) : null
        return editableCell(
          this.state.data,
          this.handleInputChange,
          elementId,
          2,
          '',
          cellInfo,
          languageColumnPlaceholderText,
          useInputTextAreaCells,
          deleteComponent
        )
      },
      getProps: () => ({
        style: { ...cellStyles },
      }),
    }))

    this.columns = staticColumns.concat(translatedLanguageColumns)
  }

  componentDidUpdate() {
    this.onDelete = this.props.onDelete
  }

  handleInputChange = (cellInfo, event) => {
    const newValue = event.target.value

    const data = [...this.props.data]
    const cellId = data[cellInfo.index].id
    const cell = cellInfo.column.id
    this.setState({ data })
    const updateObj = { cell, value: newValue }

    this.props.handleCellChange(cellId, updateObj)
  }

  renderCustomTfootComponent = () => {
    const { addNewRecordFunction, translatedLanguages, addButtonText } = this.props
    return (
      <>
        <button
          onClick={() => addNewRecordFunction(translatedLanguages)}
          type="button"
          style={{
            height: '60px',
            maxHeight: '60px',
            minHeight: '59px',
            padding: '8px 16px',
            display: 'flex',
            alignItems: 'center',
            color: '#347baf',
            fontSize: '14px',
            lineHeight: '18px',
            appearance: 'none',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          {addButtonText}
        </button>
      </>
    )
  }

  render() {
    const { data, elementId } = this.props
    return (
      <div id={`language-table${elementId}`}>
        <Table
          data={data}
          columns={this.columns}
          pageSize={data.length}
          showPagination={false}
          customTableContainerStyles={customTableContainerStyles}
          sortable={false}
          resizable={false}
          customTfootComponent={this.renderCustomTfootComponent}
          className={this.showLanguageDropdown ? 'TableDropdown' : ''}
        />
      </div>
    )
  }
}

EditableLanguageTable.defaultProps = {
  handleCellChange: () => {},
}

export default EditableLanguageTable
