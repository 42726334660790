import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { DragDropPanel } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/Section/DragDropPanel'
import SearchGroupDropdownMenu from 'svr/component-lib/Manager/Menus/SearchGroupDropdownMenu'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Item } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/Section/Section'
import type { Modifiers } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'

export interface ModifiersFieldProps {
  modifiers: Modifiers
  selectedModifiers: string[]
  updateSelectedModifiers: (value: string[]) => void
  removeSelectedModifier: (value: string) => void
}

export function ModifiersField({ modifiers, selectedModifiers, updateSelectedModifiers, removeSelectedModifier }: ModifiersFieldProps) {
  const panelItems = useMemo(
    () =>
      selectedModifiers
        .map(modifier => ({
          id: modifier,
          title: _.get(modifiers[modifier], 'name')
            ? `${_.get(modifiers[modifier], 'name')} ${_.get(modifiers[modifier], 'priceFormatted', '')}`
            : undefined,
        }))
        .filter((item): item is Item => item.title !== undefined),
    [modifiers, selectedModifiers]
  )

  const groups = useMemo(
    () => [
      {
        choices: Object.entries(modifiers)
          .sort(([_id1, { name: name1 }], [_id2, { name: name2 }]) => name1.localeCompare(name2))
          .map(([value, modifier]) => ({
            name: `${_.get(modifier, 'name')} ${_.get(modifier, 'priceFormatted', '')}`,
            value,
            disabled: false,
          })),
      },
    ],
    [modifiers]
  )
  const onChangeHandler = useCallback(
    (value: string) => {
      const isSelected = _.includes(selectedModifiers, value)
      if (isSelected) {
        updateSelectedModifiers(selectedModifiers.filter(selectedModifier => selectedModifier !== value))
      } else {
        updateSelectedModifiers([...selectedModifiers, value])
      }
    },
    [selectedModifiers, updateSelectedModifiers]
  )

  return (
    <>
      <Box mb="sm">
        <Text>
          Please search and select the modifier(s) that belong to this modifier group in the dropdown. You can reorder selected modifiers
          below.
        </Text>
      </Box>
      <SearchGroupDropdownMenu
        name="modifiers-field"
        isLightTheme
        placeholder="Search for modifiers"
        groups={groups}
        values={selectedModifiers}
        onChangeHandler={onChangeHandler}
        height={60}
        isDropUp
        style={{
          width: '100%',
        }}
        optionsContainerStyle={{
          top: '-66px',
        }}
      />
      {!!panelItems.length && (
        <Box mt="m">
          <DragDropPanel
            items={panelItems}
            onItemsUpdate={items => {
              updateSelectedModifiers(items.map(item => item.id))
            }}
            onRemove={removeSelectedModifier}
            panelId="Modifiers"
          />
        </Box>
      )}
    </>
  )
}
