import { useMemo } from 'react'
import { Modal, ModalBody, ModalHeader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import {
  ADDITIONAL_PERMISSIONS,
  ADVANCED_BOOKING_PERMISSIONS,
  BASIC_BOOKING_PERMISSIONS,
  STANDARD_EMAIL_PERMISSIONS,
  type Permission,
} from '../constants'

export interface AccessDefinitionModalProps {
  roleId: string
  vmsRoleTemplates: VMSRole[]
  cancel: () => void
}

export function AccessDefinitionModal({ roleId, vmsRoleTemplates, cancel }: AccessDefinitionModalProps) {
  const vmsRoleTemplate = useMemo(() => vmsRoleTemplates.find(role => role.id === roleId), [vmsRoleTemplates, roleId])
  const [roleHasBasicBookingPermissions, enabledBasicBookingPermissions] = useMemo(
    () => filterPermissions(vmsRoleTemplate, BASIC_BOOKING_PERMISSIONS),
    [vmsRoleTemplate]
  )
  const [roleHasAdvancedBookingPermissions, enabledAdvancedBookingPermissions] = useMemo(
    () => filterPermissions(vmsRoleTemplate, ADVANCED_BOOKING_PERMISSIONS),
    [vmsRoleTemplate]
  )
  const [roleHasAdditionalPermissions, enabledAdditionalPermissions] = useMemo(
    () => filterPermissions(vmsRoleTemplate, ADDITIONAL_PERMISSIONS),
    [vmsRoleTemplate]
  )
  const [roleHasStandardEmailPermissions, enabledStandardEmailPermissions] = useMemo(
    () => filterPermissions(vmsRoleTemplate, STANDARD_EMAIL_PERMISSIONS),
    [vmsRoleTemplate]
  )

  if (!vmsRoleTemplate) {
    return null
  }

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader backgroundColor="secondaryBackground" onClose={cancel}>
        <VStack spacing="s">
          <Text textTransform="uppercase" color="secondaryFont" fontSize="s" fontWeight="bold">
            Role
          </Text>
          <Text fontSize="xl">{vmsRoleTemplate.name}</Text>
        </VStack>
      </ModalHeader>
      <ModalBody width="636px" maxHeight="352px">
        <VStack mt="m">
          <Text textTransform="uppercase" color="secondaryFont" fontSize="s" fontWeight="bold">
            Venue Management System Access Permissions
          </Text>
        </VStack>
        <VStack mt="m" mb="m" spacing="m">
          <PermissionDescription
            enabled={roleHasBasicBookingPermissions}
            headerText="Basic Booking"
            permissionList={enabledBasicBookingPermissions}
          />
          <PermissionDescription
            enabled={roleHasAdvancedBookingPermissions}
            headerText="Advanced Booking"
            permissionList={enabledAdvancedBookingPermissions}
          />
          <PermissionDescription
            enabled={roleHasAdditionalPermissions}
            headerText="Additional"
            permissionList={enabledAdditionalPermissions}
          />
          <PermissionDescription
            enabled={roleHasStandardEmailPermissions}
            headerText="Standard Email"
            permissionList={enabledStandardEmailPermissions}
          />
        </VStack>
      </ModalBody>
    </Modal>
  )
}

interface PermissionDescriptionProps {
  enabled: boolean
  headerText: string
  permissionList?: Permission[]
}

function PermissionDescription({ enabled, headerText, permissionList }: PermissionDescriptionProps) {
  if (!enabled || !permissionList) {
    return null
  }
  return (
    <VStack spacing="s">
      <Text fontWeight="bold">{headerText}</Text>
      <List>
        {permissionList.map(permission => (
          <ListItem key={permission.name}>
            <Text>{permission.label}</Text>
          </ListItem>
        ))}
      </List>
    </VStack>
  )
}

// Basic Type Definition Until 'Good' types are created
interface VMSRole {
  name: string
  [key: string]: string | boolean | null
}

const filterPermissions = (vmsRoleTemplate: VMSRole | undefined, permissions: Permission[]): [boolean, Permission[] | undefined] => {
  if (!vmsRoleTemplate) {
    return [false, undefined]
  }
  const enabledPermissions = permissions.filter(permission => vmsRoleTemplate[permission.name] === true)
  const roleHasPermissionGroup = enabledPermissions.length > 0
  return [roleHasPermissionGroup, enabledPermissions]
}
