import { useState, useCallback } from 'react'

export const useLoading = (handler, deps) => {
  const [isLoading, updateLoading] = useState(false)
  const loadingHandler = useCallback(async (...args) => {
    try {
      updateLoading(true)
      await handler(...args)
    } finally {
      updateLoading(false)
    }
  }, deps)

  return [isLoading, loadingHandler]
}
