import type { NoTableCombinationsTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { insightsMessages } from '../../../locales'
import { formatParagraph, type DetailProps } from '../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { NoTableCombinationSuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { NoTableCombinationProofTable } from '../../Proofs'

export function NoTableCombinationDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { insightProofData } = data
  const tableRows = (insightProofData?.[0]?.tableRows as NoTableCombinationsTableRow[]) ?? []

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.floorplanOptimizationTitle)}
      description={formatMessage(insightsMessages.suggestedActionsNoTableCombinationsSlideoutDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
      })}
      proofLabel={formatMessage(insightsMessages.suggestedActionsNoTableCombinationsProofLabel)}
      suggestedActionsComponent={<NoTableCombinationSuggestedActions />}
      proofComponent={<NoTableCombinationProofTable tableRows={tableRows} />}
    />
  )
}
