import { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as OrderingMenuManagement from 'mgr/pages/single-venue/settings/actions/ordering/OrderingMenuManagement'
import MenuListTable from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuListTable'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { SaveButton } from 'svr/component-lib/Manager/Layout/LanguageContent'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { RouteComponentProps } from 'react-router'

const MenuManagementContainer = styled.div`
  padding: ${props => props.theme.gutter.double};
`

export interface MenusListProps extends RouteComponentProps {
  venue: Venue
  venues: UserDomainVenue[]
  onChangeVenue: (venue: UserDomainVenue) => void
  isLoading: boolean
  orderingMenusList: OrderingMenu[]
  loadMenusListByVenueId: (venueId: string) => Promise<unknown>
  removeMenu: (venueId: string, menuId: string) => Promise<unknown>
}

function MenusList({
  venue,
  venues,
  isLoading,
  onChangeVenue,
  orderingMenusList,
  loadMenusListByVenueId,
  removeMenu,
  history,
}: MenusListProps) {
  useEffect(() => {
    if (!isLoading) {
      loadMenusListByVenueId(venue.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMenusListByVenueId])

  const dataTableMemo = useMemo(() => orderingMenusList, [orderingMenusList])

  return (
    <ContentLayout
      title="Menu Management"
      venue={venue}
      venues={venues}
      onChangeVenue={onChangeVenue}
      Actions={
        venue.isSevenRoomsOrderingMiddleware && (
          <SaveButton
            onClick={() => {
              history.push(`/manager2/${venue.urlKey}/settings/ordering/menu`)
            }}
            id="create-new-menu"
            data-test="create-new-menu"
          >
            Create New Menu
          </SaveButton>
        )
      }
    >
      <MenuManagementContainer data-test="ordering-menus-list-container">
        <MenuListTable venue={venue} orderingMenusList={dataTableMemo} isLoading={isLoading} removeMenu={removeMenu} />
      </MenuManagementContainer>
    </ContentLayout>
  )
}

const mapStateToProps = (state: State) => {
  const { userDomain, venue } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    isLoading: state.orderingMenusList.isLoading,
    orderingMenusList: state.orderingMenusList.menusList,
  }
}

const mapDispatchToProps = {
  onChangeVenue: GlobalActions.onChangeVenue,
  loadMenusListByVenueId: OrderingMenuManagement.loadMenusListByVenueId,
  removeMenu: OrderingMenuManagement.removeMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(MenusList)
