import type { AvailabilityBookedCoversResponse } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { availabilityDebuggerMessages } from '../../locales'

export function AvailabilityCovers({ shiftCovers }: { shiftCovers: AvailabilityBookedCoversResponse }) {
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '56px',
        width: '100%',
        p: '12px 24px 12px 24px',
        ml: '-32px',
        borderTop: '1px solid #EEE',
        backgroundColor: 'common.white',
      }}
    >
      <TotalCoversContent totalCovers={shiftCovers.totalBookedCovers} />
      {shiftCovers.shiftCovers.map(s => (
        <ShiftCoversContent key={s.name} covers={s.covers} shiftName={s.name} />
      ))}
    </Box>
  )
}

function TotalCoversContent({ totalCovers }: { totalCovers: number }) {
  const { formatMessage } = useLocales()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #EEE', pr: '24px' }}>
      <Typography variant="h5">{totalCovers}</Typography>
      <Typography variant="body2" color="textSecondary">
        {formatMessage(availabilityDebuggerMessages.totalBookedCovers)}
      </Typography>
    </Box>
  )
}

function ShiftCoversContent({ covers, shiftName }: { covers: number; shiftName: string }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pl: '64px' }}>
      <Typography variant="h5">{covers}</Typography>
      <Typography variant="body2" color="textSecondary">
        {shiftName}
      </Typography>
    </Box>
  )
}
