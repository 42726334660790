import { useState } from 'react'
import { MenuItem } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/MenuItem'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { HStack, Box } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import type { MenuItem as OrderingMenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface MenuItemRowProps {
  venue: Venue
  menuItem: OrderingMenuItem
  isSaved: boolean
  isSelected: boolean
  isVisible: boolean
  onAdd: (menuItem: OrderingMenuItem) => void
  onRemove: (menuItem: OrderingMenuItem) => void
}

export function MenuItemRow({ venue, menuItem, isSaved, isSelected, isVisible, onAdd, onRemove }: MenuItemRowProps) {
  const [isCheckBtnHover, setCheckBtnHover] = useState(false)

  return (
    <HStack
      width="100%"
      boxSizing="border-box"
      spacing="m"
      justifyContent="space-between"
      alignItems="center"
      pt="sm"
      pb="sm"
      pl="m"
      pr="s"
      display={isVisible ? 'flex' : 'none'}
    >
      <Box width="100%">
        <MenuItem venue={venue} item={menuItem} itemInfoWidth="236px" />
      </Box>
      <Box>
        {isSelected ? (
          <IconButton
            size="s"
            borderType="round"
            {...(isCheckBtnHover
              ? { icon: 'VMSWeb-close', iconColor: 'primaryFont', iconBorderColor: 'borders', iconBackgroundColor: 'secondaryBackground' }
              : { icon: 'VMSWeb-check', iconColor: 'success', iconBorderColor: 'success', iconBackgroundColor: 'successBackground' })}
            onMouseOver={() => setCheckBtnHover(true)}
            onFocus={() => setCheckBtnHover(true)}
            onMouseOut={() => setCheckBtnHover(false)}
            onBlur={() => setCheckBtnHover(false)}
            onClick={() => onRemove(menuItem)}
          />
        ) : (
          <IconButton
            size="s"
            borderType="round"
            icon={isSaved ? 'VMSWeb-check' : 'VMSWeb-add'}
            disabled={isSaved}
            onClick={() => !isSaved && onAdd(menuItem)}
          />
        )}
      </Box>
    </HStack>
  )
}
