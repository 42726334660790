import styled from 'styled-components'

export const PrimaryEmailInfo = styled.div`
  background-color: ${props => props.theme.color.lightGrey};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme.gutter.double};
  padding: ${props => props.theme.gutter.double};
`
export const EmailSubject = styled.div`
  font-size: ${props => props.theme.fontSize.subheader};
  font-weight: bold;
`
export const EmailTimestamp = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSize.body};
  padding: 0 ${props => props.theme.gutter.double} ${props => props.theme.gutter.standard};
  font-weight: bold;
  min-width: 120px;
`
export const SecondaryEmailInfo = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSize.body};
  padding: 0 ${props => props.theme.gutter.double} ${props => props.theme.gutter.standard} 32px;
`
export const EmailDivider = styled.div`
  border-bottom: 2px solid ${props => props.theme.color.lightGrey};
  padding: ${props => props.theme.gutter.standard};
`
export const EmailContent = styled.div`
  background-color: ${props => props.theme.color.lightGrey};
  margin: ${props => props.theme.gutter.double};
  padding: ${props => props.theme.gutter.double};
`
