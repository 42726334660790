import { useCallback, useMemo } from 'react'
import { SimpleDropdownMenu, type SimpleDropdownMenuProps } from 'svr/component-lib/Manager/Menus/SimpleDropdownMenu'
import type { OptionItem } from 'svr/component-lib/Manager/Menus/SimpleDropdownMenu/Option'

export interface Venue {
  id: string
  // eslint-disable-next-line camelcase
  internal_display_name: string
  name: string
  // eslint-disable-next-line camelcase
  manager_base_url: string
}

export interface UserDomainVenuesDropdownMenuProps {
  venues: Venue[]
  activeVenueId: string
  entityUrlSuffix: string
  onToggle: SimpleDropdownMenuProps<string>['onToggle']
}
export function UserDomainVenuesDropdownMenu({ venues, activeVenueId, entityUrlSuffix, onToggle }: UserDomainVenuesDropdownMenuProps) {
  const items = useMemo(
    () =>
      venues.map(venue => ({
        id: venue.id,
        displayName: venue.internal_display_name || venue.name,
        data: { ...venue },
      })),
    [venues]
  )

  const goToVenue = useCallback((venue: OptionItem<string>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path = (venue as any).data.manager_base_url + entityUrlSuffix
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).location = window.location.origin + path
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="user-domain-venues-picker">
      <SimpleDropdownMenu items={items} activeItemId={activeVenueId} isSearchable onToggle={onToggle} onSelect={goToVenue} />
    </div>
  )
}
