/* eslint-disable camelcase */
import { srDelete, srGet, srPostJson, srPut, throwFetchError } from '@sevenrooms/core/api'
import { adaptOrderingMenuFromApi, adaptOrderingMenuToApi } from './adapters/adaptOrderingMenu'
import type { OrderingMenu, OrderingMenuApi } from 'svr/manager/pages/src/single-venue/settings/types/ordering/MenuManagement.types'

export const getMenusListByVenueId = (venueId: string) =>
  srGet<{ menus: OrderingMenuApi[] }>(`/api-yoa/ordering/menus/${venueId}`, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      menus: response.data.menus.map(menu => adaptOrderingMenuFromApi(menu)),
    }))

export const getMenuById = async (venueId: string, menuId: string) =>
  srGet<{ menu: OrderingMenuApi }>(`/api-yoa/ordering/menus/${venueId}/${menuId}`, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(throwFetchError)
    .then(response => ({
      ...response.data,
      menu: adaptOrderingMenuFromApi(response.data.menu),
    }))

export const putMenuById = async (venueId: string, menuId: string, menuData: OrderingMenu) =>
  srPut(`/api-yoa/ordering/menus/${venueId}/${menuId}`, adaptOrderingMenuToApi(menuData), {
    json: true,
  }).then(throwFetchError)

export const createMenu = async (venueId: string, menuData: OrderingMenu) =>
  srPostJson(`/api-yoa/ordering/menus/${venueId}`, adaptOrderingMenuToApi(menuData)).then(throwFetchError)

export const deleteMenu = async (venueId: string, menuId: string) =>
  srDelete(`/api-yoa/ordering/menus/${venueId}/${menuId}`, {}, { skipInterfaceError: true }).then(throwFetchError)
