import { OrderMethodEnum, type Order } from '@sevenrooms/core/domain'

export namespace OrdersFormatService {
  export function formatFulfillment(order: Order) {
    const method = OrderMethodEnum[order.method] as string
    if (order.method === 'ROOM_SERVICE') {
      return `${order.roomNumber}`
    }
    const tableNumber = order.posTableName || order.posTableId
    if (order.method === 'ON_PREMISE' && tableNumber) {
      return `${tableNumber}`
    }
    return method
  }
}
