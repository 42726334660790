import * as React from 'react'
import styled from 'styled-components'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'

const FooterContainer = styled(Box)`
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  background: ${props => props.theme.white};
  box-shadow: 0 0 8px rgb(0 0 0 / 25%);
`

const ButtonsContainer = styled(HStack)`
  padding-left: 12px;
`

interface EditingLayoutFooterProps extends React.HTMLAttributes<HTMLDivElement> {}

export function EditingLayoutFooter({ children, ...props }: React.PropsWithChildren<EditingLayoutFooterProps>) {
  return (
    <FooterContainer pt="m" pb="m" pl="lm" {...props}>
      <ButtonsContainer spacing="s" alignItems="center">
        {children}
      </ButtonsContainer>
    </FooterContainer>
  )
}
