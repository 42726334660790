/* eslint-disable camelcase */
import { processFail, showErrorMessage, showSuccessMessage } from 'mgr/lib/actions/GlobalActions'
import {
  getProductInventoryItemById,
  saveProductInventoryItem,
  getProductInventoryByVenueId,
  getProductInventoryResources,
  createProductInventoryItem,
  deleteProductInventoryItem,
} from 'mgr/lib/services/ProductInventoryServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { PRODUCT_TYPES_DISPLAY_NAMES } from 'mgr/pages/single-venue/settings/constants'
import type {
  ModifierGroups,
  Modifiers,
  PRODUCT_TYPES,
  PRODUCT_TYPES_ALL,
  ProductTags,
} from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { ProductInventoryItem, ModifiersGroupToModifiers } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'
import type { Dispatch } from 'redux'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export interface GetOrderingProductInventoryStartAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_START
}
export interface GetOrderingProductInventoryFailAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_FAIL
}
export interface GetOrderingProductInventorySuccessAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_SUCCESS
  products: ProductInventoryItem[]
}
export interface GetOrderingProductInventoryItemSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS
  product: ProductInventoryItem
}
export interface GetOrderingProductInventoryItemResourcesSuccessAction {
  type: typeof ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_RESOURCES_SUCCESS
  modifiers: Modifiers
  modifierGroups: ModifierGroups
  productTags: ProductTags
}
export interface RemoveProductStartAction {
  type: typeof ActionTypes.REMOVE_PRODUCT_INVENTORY_START
}
export interface RemoveProductSuccessAction {
  type: typeof ActionTypes.REMOVE_PRODUCT_INVENTORY_SUCCESS
}
export interface RemoveProductFailAction {
  type: typeof ActionTypes.REMOVE_PRODUCT_INVENTORY_FAIL
}
export interface UploadPhotoPreviewAction {
  type: typeof ActionTypes.UPLOAD_PHOTO_PREVIEW
  imageType: string
  preview: string
}
export interface UploadPhotoSuccessAction {
  type: typeof ActionTypes.UPLOAD_PHOTO_SUCCESS
  imageType: string
  upload: { url_key: string; post_key: string }
}
export interface CropOpenAction {
  type: typeof ActionTypes.CROP_OPEN
  imageType: string
}
export interface CropPhotoAction {
  type: typeof ActionTypes.CROP_PHOTO
  imageType: string
  coords: string
}
export interface CropCloseAction {
  type: typeof ActionTypes.CROP_CLOSE
  imageType: string
}
export interface CropDeleteAction {
  type: typeof ActionTypes.CROP_DELETE
  imageType: string
}
export interface SaveProductInventoryItemAction {
  type: typeof ActionTypes.SAVE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS
}
export interface CreateProductInventoryItemAction {
  type: typeof ActionTypes.CREATE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS
}

export type PhotoAction =
  | UploadPhotoPreviewAction
  | UploadPhotoSuccessAction
  | CropOpenAction
  | CropPhotoAction
  | CropCloseAction
  | CropDeleteAction

export type ProductInventoryAction =
  | GetOrderingProductInventoryStartAction
  | GetOrderingProductInventoryFailAction
  | GetOrderingProductInventorySuccessAction
  | GetOrderingProductInventoryItemSuccessAction
  | GetOrderingProductInventoryItemResourcesSuccessAction
  | SaveProductInventoryItemAction
  | CreateProductInventoryItemAction
  | ReturnType<typeof clearProductInventoryData>
  | ReturnType<typeof selectProductInventoryStateTab>
  | ReturnType<typeof setProductInventoryFilterSearchValue>
  | ReturnType<typeof setProductInventorySortedColumns>
  | ReturnType<typeof updateName>
  | ReturnType<typeof updateDescription>
  | ReturnType<typeof updatePrice>
  | ReturnType<typeof updateSelectedProductTags>
  | ReturnType<typeof updateSelectedModifiers>
  | ReturnType<typeof removeSelectedModifier>
  | ReturnType<typeof updateSelectedGroups>
  | ReturnType<typeof updateSelectedGroupModifiers>
  | ReturnType<typeof updateTaxRate>
  | ReturnType<typeof updateMinAmount>
  | ReturnType<typeof updateMaxAmount>
  | ReturnType<typeof updateMaxSameModifiersAmount>
  | ReturnType<typeof updateAllowOrderSameModifiers>
  | ReturnType<typeof updateTaxGroupId>
  | ReturnType<typeof updateVerifyAge>
  | ReturnType<typeof openCreateProductModal>
  | ReturnType<typeof closeCreateProductModal>
  | PhotoAction

export const setProductInventoryFilterSearchValue = (value: string) =>
  ({ type: ActionTypes.SET_PRODUCT_INVENTORY_FILTER_SEARCH_VALUE, value } as const)

export const setProductInventorySortedColumns = (data: SortColumn[]) =>
  ({ type: ActionTypes.SET_PRODUCT_INVENTORY_SORTED_COLUMNS, data } as const)

export const selectProductInventoryStateTab = (productStateId: PRODUCT_TYPES_ALL) =>
  ({ type: ActionTypes.SELECT_PRODUCT_INVENTORY_STATE_TAB, productStateId } as const)

export const clearProductInventoryData = (productType: PRODUCT_TYPES) =>
  ({ type: ActionTypes.CLEAR_PRODUCT_INVENTORY_DATA, productType } as const)

export const updateName = (value: string) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_NAME, value } as const)

export const updateDescription = (value: string) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_DESCRIPTION, value } as const)

export const updatePrice = (value: number) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_PRICE, value } as const)

export const updateTaxRate = (value: number) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_TAX_RATE, value } as const)

export const updateTaxGroupId = (value: string) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_TAX_GROUP_ID, value } as const)

export const updateSelectedProductTags = (value: string[]) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_PRODUCT_TAGS, value } as const)

export const updateMinAmount = (value: number) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_MIN_AMOUNT, value } as const)

export const updateMaxAmount = (value: number) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_MAX_AMOUNT, value } as const)

export const updateMaxSameModifiersAmount = (value: number) =>
  ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_MAX_SAME_MODIFIERS_AMOUNT, value } as const)

export const updateAllowOrderSameModifiers = (value: boolean) =>
  ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_ALLOW_ORDER_SAME_MODIFIERS, value } as const)

export const updateVerifyAge = (value: boolean) => ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_VERIFY_AGE, value } as const)

export const openCreateProductModal = () => ({ type: ActionTypes.OPEN_CREATE_PRODUCT_MODAL } as const)

export const closeCreateProductModal = () => ({ type: ActionTypes.CLOSE_CREATE_PRODUCT_MODAL } as const)

export const updateSelectedModifiers = (value: string[]) =>
  ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS, value } as const)

export const removeSelectedModifier = (value: string) => ({ type: ActionTypes.REMOVE_PRODUCT_INVENTORY_SELECTED_MODIFIER, value } as const)

export const updateSelectedGroupModifiers = (groupId: string, modifiers?: string[]) =>
  ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUP, groupId, modifiers } as const)

export const updateSelectedGroups = (groups: ModifiersGroupToModifiers[]) =>
  ({ type: ActionTypes.UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUPS, groups } as const)

type loadProductInventoryByVenueIdType = GetOrderingProductInventoryStartAction | GetOrderingProductInventorySuccessAction
export const loadProductInventoryByVenueId = (venueId: string) => async (dispatch: Dispatch<loadProductInventoryByVenueIdType>) => {
  dispatch({ type: ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_START })
  try {
    const response = await getProductInventoryByVenueId(venueId)
    dispatch({
      type: ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_SUCCESS,
      products: response.products,
    })
  } catch (e) {
    dispatch(
      processFail(
        'Unable to fetch product inventory, please try again later or contact customer support.',
        ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_FAIL
      )
    )
  }
}

export const loadProductItemById =
  (venueId: string, productId: string, productType: PRODUCT_TYPES) =>
  async (dispatch: Dispatch<GetOrderingProductInventoryItemSuccessAction | ReturnType<typeof showErrorMessage>>) => {
    let response

    try {
      response = await getProductInventoryItemById(venueId, productId, productType)
    } catch (e) {
      dispatch(showErrorMessage('Unable to fetch product item, please try again later or contact customer support.'))
      throw e
    }

    dispatch({
      type: ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS,
      product: response.product,
    })
  }

export const loadProductInventoryResources =
  (venueId: string, productType: PRODUCT_TYPES) =>
  async (dispatch: Dispatch<GetOrderingProductInventoryItemResourcesSuccessAction | ReturnType<typeof showErrorMessage>>) => {
    let response

    try {
      response = await getProductInventoryResources(venueId, productType)
    } catch (e) {
      dispatch(showErrorMessage('Unable to fetch product item resources, please try again later or contact customer support.'))
      throw e
    }

    dispatch({
      type: ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_RESOURCES_SUCCESS,
      productTags: response.productTags,
      modifiers: response.modifiers,
      modifierGroups: response.modifierGroups,
    })
  }

export const saveProductItemById =
  (venueId: string, productId: string, productData: ProductInventoryItem) =>
  async (
    dispatch: Dispatch<ReturnType<typeof showErrorMessage> | ReturnType<typeof showSuccessMessage> | SaveProductInventoryItemAction>
  ) => {
    try {
      await saveProductInventoryItem(venueId, productId, productData)
      dispatch({ type: ActionTypes.SAVE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS })
    } catch (e) {
      dispatch(showErrorMessage('Something went wrong. Please try saving again.'))
      throw e
    }
    dispatch(showSuccessMessage(`${PRODUCT_TYPES_DISPLAY_NAMES[productData.type]} ”${productData.name}” has been successfully edited.`))
  }

export const createProductItem =
  (venueId: string, productData: ProductInventoryItem) =>
  async (
    dispatch: Dispatch<ReturnType<typeof showErrorMessage> | ReturnType<typeof showSuccessMessage> | CreateProductInventoryItemAction>
  ) => {
    try {
      await createProductInventoryItem(venueId, productData)
      dispatch({ type: ActionTypes.CREATE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS })
    } catch (e) {
      dispatch(showErrorMessage('Something went wrong. Please try saving again.'))
      throw e
    }
    dispatch(showSuccessMessage(`${PRODUCT_TYPES_DISPLAY_NAMES[productData.type]} ”${productData.name}” has been successfully created.`))
  }

export const removeProductItem =
  (venueId: string, productId: string, productType: PRODUCT_TYPES) =>
  async (
    dispatch: Dispatch<
      | RemoveProductStartAction
      | RemoveProductSuccessAction
      | RemoveProductFailAction
      | loadProductInventoryByVenueIdType
      | ReturnType<typeof showErrorMessage>
      | ReturnType<typeof showSuccessMessage>
    >
  ) => {
    dispatch({
      type: ActionTypes.REMOVE_PRODUCT_INVENTORY_START,
    })

    try {
      await deleteProductInventoryItem(venueId, productId, productType)
    } catch (e) {
      dispatch(
        processFail(
          'Unable to delete product, please try again later or contact customer support.',
          ActionTypes.REMOVE_PRODUCT_INVENTORY_FAIL
        )
      )
      throw e
    }

    dispatch({
      type: ActionTypes.REMOVE_PRODUCT_INVENTORY_SUCCESS,
    })
    dispatch(showSuccessMessage('Product Deleted'))
    await loadProductInventoryByVenueId(venueId)(dispatch)
  }
