import _ from 'lodash'
import React, { createRef } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import { getContrastingColor } from 'svr/common/Color'
import { VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'

const PodCellComponentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
  }
`
const PodCellContainer = styled.div`
  padding: ${props => props.theme.gutter.double};
  width: 100%;
  height: 100%;
`

const PodElementContainer = styled.div`
  display: inline-flex;
  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
  }
`
const PodContainer = styled.span`
  color: ${props => getContrastingColor(props.backgroundColor)};
  border-radius: 25px;
  padding: 5px 10px;
  background-color: ${props => props.backgroundColor};
`
const PodName = styled.span`
  max-width: 160px;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 18px;
`

const Icon = styled(StyledVmsIconXS)`
  padding-left: 5px;
`

const PodPickerContainer = styled.div`
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 350px;
  padding: 8px;
  z-index: 999;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
`

const HoverMessage = styled.span`
  color: #b1b4b9;
  display: ${props => (props.isVisible ? 'block' : 'none')};
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  padding-bottom: 10px;
  width: 100%;
  display: ${props => (props.isVisible ? 'block' : 'none')};
`

function PodPicker(props) {
  const selectedPodIds = getPodsDictionary(props.selectedPods)
  return (
    <OutsideClickHandler onOutsideClick={props.offPodPickerClick}>
      <PodPickerContainer onClick={props.onPodPickerClick} className="sr-podpicker-container">
        {_.map(props.accessorPods, pod => (
          <PodElementContainer key={pod.id}>
            <Pod
              pod={pod}
              key={pod.id}
              id={`sr-filtered-pod-${pod.id}`}
              className="sr-filtered-pods"
              hexColor={props.hexColor}
              venueId={props.venueId}
              action={selectedPodIds[pod.id] ? 'REMOVE' : 'ADD'}
              vmsIcon={selectedPodIds[pod.id] ? VmsIcons.Checked : VmsIcons.OvalLine}
              onAddPod={props.onAddPod}
              onRemovePod={props.onRemovePod}
              onPodClick={props.onPodClick}
              offPodClick={props.offPodClick}
            />
          </PodElementContainer>
        ))}
      </PodPickerContainer>
    </OutsideClickHandler>
  )
}

const getPodsDictionary = (pods = []) => pods.reduce((map, pod) => ({ ...map, [pod.id]: pod }), {})

function Pod(props) {
  function podClickHandler() {
    const fn = props.action === 'REMOVE' ? props.onRemovePod : props.onAddPod
    fn.call(null, props.venueId, props.pod.venue_group_id, props.pod.pod_category_id, props.pod.id)
  }

  function cellClickHandler() {
    podClickHandler()
    props.onPodClick()
  }

  return (
    <OutsideClickHandler onOutsideClick={props.offPodClick}>
      <PodContainer className={props.className} backgroundColor={props.hexColor} onClick={cellClickHandler}>
        <PodName id={props.id}>{props.pod.name}</PodName>
        <Icon>{props.vmsIcon}</Icon>
      </PodContainer>
    </OutsideClickHandler>
  )
}

class PodPickerCell extends React.Component {
  constructor(props) {
    super(props)
    this.searchField = createRef()
    this.state = {
      showPodPicker: false,
      podPickerActive: false,
      podActive: false,
      cellHover: false,
      searchFilter: '',
    }
  }

  cellClickHandler = () => {
    this.setState({ showPodPicker: true }, () => this.searchField.current.focus())
  }

  outsideClickHandler = () => {
    if (this.state.showPodPicker) {
      this.setState({ showPodPicker: false, searchFilter: '' })
    }
  }

  onHoverHandler = () => this.setState({ cellHover: true })

  offHoverHandler = () => this.setState({ cellHover: false })

  onPodPickerClick = () => this.setState({ podPickerActive: true })

  offPodPickerClick = () => this.setState({ podPickerActive: false })

  onPodClick = () => this.setState({ podActive: true })

  offPodClick = () => this.setState({ podActive: false })

  changeSearchInputHandler = ({ target: { value } }) => this.setState({ searchFilter: value })

  render() {
    const searchFilter = this.state.searchFilter.trim().toLowerCase()
    const displayPods = filterPods(this.props.podCategory.pods, searchFilter)
    const showHoverMessage = this.state.cellHover && !this.props.pods && !this.state.showPodPicker && !this.state.podPickerActive
    return (
      <PodCellComponentContainer onMouseOver={this.onHoverHandler} onMouseOut={this.offHoverHandler}>
        <OutsideClickHandler onOutsideClick={this.outsideClickHandler}>
          <PodCellContainer onClick={this.cellClickHandler} className="sr-podpicker-cell">
            <HoverMessage isVisible={showHoverMessage} onMouseOver={this.onHoverHandler}>
              Click and Search Pods
            </HoverMessage>
            <SearchInput
              className="sr-pods-search-filter"
              isVisible={this.state.showPodPicker}
              placeholder="Search pods..."
              onChange={this.changeSearchInputHandler}
              value={this.state.searchFilter}
              ref={this.searchField}
            />
            {_.map(this.props.pods, pod => (
              <PodElementContainer key={pod.id}>
                <Pod
                  pod={pod}
                  key={pod.id}
                  id={`sr-selected-pod-${pod.id}`}
                  className="sr-selected-pods"
                  hexColor={pod.hex_color}
                  venueId={this.props.venue.venue_id}
                  vmsIcon={VmsIcons.ClearLine}
                  action="REMOVE"
                  onAddPod={this.props.onAddPod}
                  onRemovePod={this.props.onRemovePod}
                  onPodClick={_.noop}
                  offPodClick={_.noop}
                />
              </PodElementContainer>
            ))}
          </PodCellContainer>
          {(this.state.showPodPicker || this.state.podPickerActive || this.state.podActive) && (
            <PodPicker
              venueId={this.props.venue.venue_id}
              selectedPods={this.props.pods}
              hexColor={this.props.podCategory.hex_color}
              accessorPods={displayPods}
              onAddPod={this.props.onAddPod}
              onRemovePod={this.props.onRemovePod}
              onPodPickerClick={this.onPodPickerClick}
              offPodPickerClick={this.offPodPickerClick}
              onPodClick={this.onPodClick}
              offPodClick={this.offPodClick}
            />
          )}
        </OutsideClickHandler>
      </PodCellComponentContainer>
    )
  }
}

const filterPods = (pods, searchFilter) => (searchFilter === '' ? pods : _.filter(pods, it => it.name.toLowerCase().includes(searchFilter)))

export default PodPickerCell
