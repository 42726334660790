import type { GoogleResAvailabilitySuggestedActions, GoogleReserveAvailabilityTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { insightsMessages } from '../../../locales'
import { formatParagraph, formatList, formatListItem, type DetailProps } from '../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { GoogleReserveAvailabilitySuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { GoogleReserveAvailabilityProofSection } from '../../Proofs'

export function GoogleReserveAvailabilityDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { suggestedActionsData, insightProofData } = data
  const proofDataRecord = (insightProofData?.[0]?.tableRows?.[0] as GoogleReserveAvailabilityTableRow) ?? {}

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.availabilityOptimizationTitle)}
      description={formatMessage(insightsMessages.googleReserveAvailabilityDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
        ul: (chunks: string[]) => formatList(chunks),
        li: (chunks: string[]) => formatListItem(chunks),
      })}
      suggestedActionsComponent={
        <GoogleReserveAvailabilitySuggestedActions
          insightSuggestedActionData={suggestedActionsData as GoogleResAvailabilitySuggestedActions}
          insightProofDataRecord={proofDataRecord}
        />
      }
      proofComponent={<GoogleReserveAvailabilityProofSection insightProofDataRecord={proofDataRecord} />}
    />
  )
}
