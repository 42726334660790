import { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as Product86Actions from 'mgr/pages/single-venue/settings/actions/ordering/EightySixProductActions'
import EightySixProductForm from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/EightySixProducts/EightySixProductForm'
import EightySixProductTable from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/EightySixProducts/EightySixProductTable'
import ProductControls from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductControls'
import { SettingsModal, Screen } from 'mgr/pages/single-venue/settings/components/shared'
import { selectOrderingProductsList } from 'mgr/pages/single-venue/settings/selectors/eightySixProduct'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { EightySixProduct as EightySixProductType } from 'mgr/pages/single-venue/settings/types/ordering/EightySixProduct.types'
import type { EIGHTY_SIX_PRODUCT_TYPES, PRODUCT_STATES } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { Moment } from 'moment'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export interface EightySixProductProps {
  venue: Venue
  venues: UserDomainVenue[]
  sorted: SortColumn[]
  isLoading: boolean
  onChangeVenue: (venue: UserDomainVenue) => void
  orderingProductsList: EightySixProductType[]
  selectedItem: EightySixProductType | null
  loadSnoozedProductListByVenueId: (venueId: string) => void
  selectProductItem: (product: EightySixProductType | null) => void
  selectSnoozeOption: (snoozeOptionId: string) => void
  selectedSnoozeOptionId: string
  closeSnoozeModal: () => void
  submitProductSnooze: (product: EightySixProductType, snoozeOptionId: string) => void
  selectProductStateTab: (productStateId: PRODUCT_STATES) => void
  productStateId: PRODUCT_STATES
  setProductFilterSearchValue: (value: string) => void
  setProductTypeFilter: (value: EIGHTY_SIX_PRODUCT_TYPES) => void
  productTypeFilter: EIGHTY_SIX_PRODUCT_TYPES
  selectedSpecificSnoozeDate: Moment
  setSpecificSnoozeDate: (date: Moment) => void
  setProductSortedColumns: (data: SortColumn[]) => void
}

function EightySixProduct({
  venue,
  venues,
  sorted,
  isLoading,
  onChangeVenue,
  orderingProductsList,
  selectedItem,
  loadSnoozedProductListByVenueId,
  selectProductItem,
  selectSnoozeOption,
  selectedSnoozeOptionId,
  closeSnoozeModal,
  submitProductSnooze,
  selectProductStateTab,
  productStateId,
  setProductFilterSearchValue,
  setProductTypeFilter,
  productTypeFilter,
  selectedSpecificSnoozeDate,
  setSpecificSnoozeDate,
  setProductSortedColumns,
}: EightySixProductProps) {
  useEffect(() => {
    if (!isLoading) {
      loadSnoozedProductListByVenueId(venue.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataTableMemo = useMemo(() => orderingProductsList, [orderingProductsList])

  return (
    <ContentLayout title="86 Products" venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
      <Box data-test="eightysix-product-list-container" pt="m" pl="lm" pr="lm" pb="lm">
        {selectedItem && (
          <>
            <Screen />
            <SettingsModal
              className="settings"
              toggler={closeSnoozeModal}
              action={() => submitProductSnooze(selectedItem, selectedSnoozeOptionId)}
              actionText="Save"
              title={`86 ${selectedItem.name} for:`}
              content={
                <EightySixProductForm
                  selectedSnoozeOptionId={selectedSnoozeOptionId}
                  onChangeSnoozeOption={selectSnoozeOption}
                  productStateId={productStateId}
                  onSpecificSnoozeDateChange={setSpecificSnoozeDate}
                  selectedSpecificSnoozeDate={selectedSpecificSnoozeDate}
                />
              }
              visibility={!!selectedItem}
            />
          </>
        )}

        <ProductControls
          tabs={[
            {
              id: 'available',
              text: 'Available Products',
            },
            {
              id: 'snoozed',
              text: `86'd Products`,
            },
          ]}
          selectedTabId={productStateId}
          onSelectedTabChange={(id: string) => selectProductStateTab(id as PRODUCT_STATES)}
          onProductFilterSearchChange={setProductFilterSearchValue}
          productTypeFilter={productTypeFilter}
          onProductTypeFilterChange={setProductTypeFilter}
        />

        <EightySixProductTable
          venue={venue}
          onEightSixItemClick={selectProductItem}
          isLoading={isLoading}
          productList={dataTableMemo}
          productStateId={productStateId}
          sorted={sorted}
          onSortedChange={setProductSortedColumns}
        />
      </Box>
    </ContentLayout>
  )
}

const mapStateToProps = (state: State) => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    isLoading: state.orderingProducts.isLoading,
    orderingProductsList: selectOrderingProductsList(state),
    selectedSnoozeOptionId: state.orderingProducts.selectedSnoozeOptionId,
    selectedItem: state.orderingProducts.selectedItem,
    productStateId: state.orderingProducts.productStateId,
    productTypeFilter: state.orderingProducts.productTypeFilter,
    selectedSpecificSnoozeDate: state.orderingProducts.selectedSpecificSnoozeDate,
    sorted: state.orderingProducts.sorted,
  }
}

const mapDispatchToProps = {
  onChangeVenue: GlobalActions.onChangeVenue,
  loadSnoozedProductListByVenueId: Product86Actions.loadSnoozedProductListByVenueId,
  selectSnoozeOption: Product86Actions.selectSnoozeOption,
  closeSnoozeModal: Product86Actions.closeSnoozeModal,
  selectProductItem: Product86Actions.selectProductSnoozeItem,
  submitProductSnooze: Product86Actions.submitProductSnooze,
  selectProductStateTab: Product86Actions.selectProductSnoozeStateTab,
  setProductFilterSearchValue: Product86Actions.setProductSnoozeFilterSearchValue,
  setProductTypeFilter: Product86Actions.setProductSnoozeTypeFilter,
  setSpecificSnoozeDate: Product86Actions.setSpecificSnoozeDate,
  setProductSortedColumns: Product86Actions.setProductSnoozeSortedColumns,
}

export default connect(mapStateToProps, mapDispatchToProps)(EightySixProduct)
