import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _ from 'underscore'
import { Input } from 'svr/common/FormElement'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'

const CropInterface = styled.div`
  background: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.five};
  left: 50%;
  margin-left: -310px;
  position: fixed;
  top: 30px;
  padding: 20px;
  width: 600px;
  z-index: 600;
`

const Header = styled.div`
  display: flex;
`

const Note = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSize.small};
  font-style: oblique;
  margin-bottom: 20px;
`

const Save = styled.div`
  ${props => props.theme.primaryButton};
  background: ${props => props.theme.color.teal};
  margin: 10px 0 0 auto;
  padding: 13px;
  width: 120px;
  :hover {
    opacity: 0.8;
  }
`

const PhotoTitle = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
`

const ModalCloser = styled(StyledVmsIconS)`
  color: ${props => props.theme.color.black};
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
`

export const CropperScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #020202;
  z-index: 550;
  opacity: 0.5;
`

class PhotoCropper extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    const {
      photoId,
      aspect,
      allPhotos,

      cropImage,
      closeCrop,
      saveCrop,
      customNote,
    } = this.props

    const note = { margin: '5px 10px 10px' }

    const data = allPhotos.filter(item => item.id == photoId)[0]

    const src = `/.h/download/${data.raw_url_key}`
    const crop = data.crop_info || {
      x: 25,
      y: 25,
      width: 50,
      aspect,
    }

    if (!src) {
      return null
    }
    return (
      <CropInterface>
        <div>
          <Header>
            <PhotoTitle>{data.label}</PhotoTitle>
            <ModalCloser onClick={closeCrop}>{VmsIcons.Close}</ModalCloser>
          </Header>
          <ReactCrop
            keepSelection
            src={src}
            crop={crop}
            onComplete={coords => cropImage(photoId, coords)}
            onImageLoaded={coords => cropImage(photoId, coords)}
          />
          <div>
            <Note>{customNote || ''}</Note>
          </div>
          <Save onClick={saveCrop}>Save</Save>
        </div>
      </CropInterface>
    )
  }
}

export default PhotoCropper
