import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router'
import styled from 'styled-components'
import { updateNavState } from 'mgr/lib/actions/NavActions'
import LookerReportingEmbed from 'mgr/pages/shared/components/reporting/LookerReportingEmbed'
import { VenueGroupNavSections } from 'mgr/pages/shared/utils/Constants'
import { getReportingLookerSideNav } from 'mgr/pages/shared/utils/Reporting'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { VenueGroupActivityLog } from '@sevenrooms/mgr-activity-log'
import { SettingsPage, SettingsPageMeta } from '@sevenrooms/mgr-core'
import ReportingHomepageLayout from './ReportingHomepageLayout'

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
`

class ReportingLayout extends React.Component {
  constructor(props) {
    super(props)

    const { venueGroup } = this.props

    const subNavItems = [
      {
        id: 'administrative',
        title: 'Administrative',
        allowed: true,
        collapsible: false,
        items: [
          {
            id: 'activity-log',
            title: 'Activity Log',
            to: `/group/${venueGroup.id}/reporting/activity_log`,
            items: [],
            reload: true,
          },
        ],
      },
    ]
    let lookerItems
    if (venueGroup.isLookerEnabled === 'True') {
      // why is this a string though?
      lookerItems = getReportingLookerSideNav(venueGroup.lookerFolders, `/group/${venueGroup.id}/reporting`)
    }

    const menuItems = lookerItems ? lookerItems.concat(subNavItems) : subNavItems

    const firstLink = `/group/${venueGroup.id}/reporting/home`
    menuItems.unshift({
      id: 'reporting-main',
      title: 'Reporting',
      allowed: true,
      collapsible: false,
      items: [
        {
          id: 'reporting-home-link',
          title: 'Home',
          to: firstLink,
          items: [],
          reload: true,
        },
      ],
    })

    this.state = {
      items: menuItems,
      firstLink,
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.location.pathname !== nextProps.location.pathname || this.props.userDomain.venueGroups !== nextProps.userDomain.venueGroups
    )
  }

  componentDidMount() {
    const { updateNavState } = this.props
    updateNavState(VenueGroupNavSections.REPORTING, null)
  }

  render() {
    const { match, location, venueGroup, userDomain, history } = this.props
    const reportingUrl = venueGroup.isLookerEnabled && location && _.drop(location.pathname.split('/'), 4).join('/')

    return (
      <SettingsPage hideSearch showSideNavigation sideNavigationItems={this.state.items}>
        <SettingsPageMeta title="Group Reporting" />
        <ContentContainer>
          <Switch>
            <Route path={`/group/${venueGroup.id}/reporting/home`} component={ReportingHomepageLayout} />
            <Route
              path={`${match.path}/embed`}
              render={() => (
                <ContentLayout
                  venueGroup={venueGroup}
                  userDomain={userDomain}
                  onChangeVenueGroup={newVenueGroup => {
                    history.push(`/group/${newVenueGroup.id}`)
                    window.location.reload()
                  }}
                  overrideChangeVenue
                >
                  {venueGroup.isLookerEnabled && <LookerReportingEmbed isGroup url={reportingUrl} />}
                </ContentLayout>
              )}
            />
            {userDomain.has_access_group_reporting && (
              <Route path={`/group/${venueGroup.id}/reporting/activity_log`} component={VenueGroupActivityLog} />
            )}
            <Route path="*">
              <Redirect to={this.state.firstLink} />
            </Route>
          </Switch>
        </ContentContainer>
      </SettingsPage>
    )
  }
}

const mapStateToProps = state => ({
  venueGroup: state.appState.venueGroup,
  userDomain: state.appState.userDomain,
})

const mapDispatchToProps = {
  updateNavState,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingLayout))
