import React from 'react'
import styled, { css } from 'styled-components'
import { dateToFormat } from 'svr/common/DateTime'
import EditSiteExampleContainer, { EditSiteExampleWrapper } from './EditSiteExampleContainer'

const TOTAL_SLOTS = 3
const HOURS_OFFSET = 12
const DAY_OFFSET = 1
const HOURS_IN_DAY = 24
const SLOT_TIME_FORMAT = 'h:mm A'
const MIN_MS = 1000 * 60

const SelectContainer = styled.div`
  font-family: Roboto;
  background: #fcfcfd;
  position: relative;
  margin-bottom: 34px;
`

const EmptyBlock = styled.div`
  width: 40px;
`

const SelectWrapper = styled.div`
  border: 1px solid #cfd0d7;
  border-radius: 4px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  color: #8a8a8a;
  padding-right: 44px;
`

const HintLeft = styled.div`
  position: absolute;
  font-size: 12px;
  color: #565c63;
  left: -111px;
  top: 28px;
`

const HintBottom = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  font-size: 12px;
  color: #565c63;
  top: 120px;
  left: 90px;
`

const StickSingleLeft = styled.div`
  border-top: 2px solid #347baf;
  position: absolute;
  width: 25px;
  top: 34px;
  left: -15px;
`

const SticksLeft = styled.div`
  border: 2px solid #347baf;
  border-right: none;
  position: absolute;
  height: 28px;
  top: 20px;
  left: 8px;
  width: 6px;
`

const StickSingleBottom = styled.div`
  border-left: 2px solid #347baf;
  position: absolute;
  height: 10px;
  top: 106px;
  left: 88px;
`

const SticksBottom = styled.div`
  border: 2px solid #347baf;
  border-top: none;
  position: absolute;
  height: 14px;
  top: 92px;
  left: 52px;
  width: 73px;
`

const Arrow = () => (
  <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 5L5 0L0 5L10 5Z" fill="#8A8A8A" />
  </svg>
)

const ArrowWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 12px;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 24px;
  position: relative;
  background: #efeff0;
  border: 1px solid #b4b5b6;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: -4px;
  ${props =>
    props.withScroll &&
    css`
      max-height: 104px;
      overflow-y: auto;
    `}
`

const Option = styled.div`
  color: #1e252f;
  min-width: 140px;
  & + & {
    padding-top: 12px;
  }
`

const getNextDays = days => {
  const nextDay = new Date().setDate(new Date().getDate() + days)
  return new Date(nextDay)
}

const generateSlots = (timeBuffer, timeInterval, totalSlots) => {
  const slots = Array(totalSlots).fill(null)

  const date = new Date()
  date.setHours(12, 0, 0, 0)
  const timestamp = date.getTime()

  return slots.map((slot, index) => {
    const startDate = timestamp + index * (timeInterval * MIN_MS)
    const endDate = startDate + timeBuffer * MIN_MS
    return { startDate, endDate, key: index }
  })
}

const getDaysValue = ({ maxOrderAheadTime, orderAheadValue }) => {
  switch (orderAheadValue) {
    case 'HOURS':
      return Math.floor((maxOrderAheadTime + HOURS_OFFSET - 1) / HOURS_IN_DAY) + 1
    case 'DAYS':
    default:
      return maxOrderAheadTime + DAY_OFFSET
  }
}

const generateOrderAheadValues = ({ maxOrderAheadTime, orderAheadValue }) => {
  const days = getDaysValue({
    maxOrderAheadTime,
    orderAheadValue,
  })
  const daysTotal = ['Today']
  if (days >= 2) {
    daysTotal.push('Tomorrow')
  }
  if (days >= 3) {
    // eslint-disable-next-line no-plusplus
    for (let i = 2; i < days; i++) {
      const nextDay = dateToFormat(getNextDays(i), 'ddd, MMM D, YYYY')
      daysTotal.push(nextDay)
    }
  }

  return daysTotal
}

const EditSiteAllowToScheduleExample = ({
  timeSlotBuffer = 0,
  timeSlotInterval = 0,
  isAllowOrderAhead = false,
  maxOrderAheadTime = 0,
  orderAheadValue = 0,
}) => (
  <EditSiteExampleContainer>
    <EditSiteExampleWrapper>
      <EmptyBlock />
    </EditSiteExampleWrapper>
    <EditSiteExampleWrapper>
      <SelectContainer>
        <SelectWrapper>
          Choose a time
          <ArrowWrapper>
            <Arrow />
          </ArrowWrapper>
        </SelectWrapper>
        <OptionsContainer>
          <HintLeft>Time Slot interval</HintLeft>
          <HintBottom>Time Slot Buffer</HintBottom>
          <StickSingleLeft />
          <SticksLeft />
          <StickSingleBottom />
          <SticksBottom />
          {generateSlots(timeSlotBuffer, timeSlotInterval, TOTAL_SLOTS).map(({ startDate, endDate, key }) => (
            <Option key={key}>
              {dateToFormat(new Date(startDate), SLOT_TIME_FORMAT)} - {dateToFormat(new Date(endDate), SLOT_TIME_FORMAT)}
            </Option>
          ))}
        </OptionsContainer>
      </SelectContainer>
    </EditSiteExampleWrapper>
    {isAllowOrderAhead && (
      <EditSiteExampleWrapper>
        <SelectContainer>
          <SelectWrapper>
            Choose a date
            <ArrowWrapper>
              <Arrow />
            </ArrowWrapper>
          </SelectWrapper>
          <OptionsContainer withScroll>
            {generateOrderAheadValues({
              maxOrderAheadTime,
              orderAheadValue,
            }).map(value => (
              <Option key={value}>{value}</Option>
            ))}
          </OptionsContainer>
        </SelectContainer>
      </EditSiteExampleWrapper>
    )}
  </EditSiteExampleContainer>
)

export default EditSiteAllowToScheduleExample
