import constate from 'constate'
import { useState } from 'react'
import type { RevenueTypeKey } from '@sevenrooms/core/domain'

function usePerformanceTable() {
  const [sortCategory, setSortCategory] = useState<string>('revenue')
  const [sortDescending, setSortDescending] = useState<boolean>(true)
  const [expanded, setExpanded] = useState<RevenueTypeKey | null>(null)

  return {
    sortCategory,
    setSortCategory,
    sortDescending,
    setSortDescending,
    expanded,
    setExpanded,
  }
}

export const [PerformanceTableContext, usePerformanceTableContext] = constate(usePerformanceTable)
