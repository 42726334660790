import type { UpgradeCoverageSuggestedActionsType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'
import { formatParagraph, type DetailProps, CREATE_UPGRADE_INSTRUCTIONS_URL } from '../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { UpgradeCoverageSuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { UpgradeCoverageProofSection } from '../../Proofs'

export function UpgradeCoverageDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { suggestedActionsData, insightProofData } = data

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.availabilityOptimizationTitle)}
      description={formatMessage(insightsMessages.upgradeCoverageAvailabilityDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
        link: (chunks: string[]) => (
          <Link to={CREATE_UPGRADE_INSTRUCTIONS_URL} target="_blank">
            {chunks}
          </Link>
        ),
      })}
      suggestedActionsComponent={
        <UpgradeCoverageSuggestedActions insightSuggestedActionData={suggestedActionsData as UpgradeCoverageSuggestedActionsType} />
      }
      proofComponent={<UpgradeCoverageProofSection insightProofData={insightProofData} />}
    />
  )
}
