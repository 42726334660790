import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line import/no-cycle
import LanguageTable from 'svr/component-lib/Manager/MultiLanguage/LanguageTable'
import _ from 'lodash'

const SectionContainer = styled.div`
  margin: ${props => props.theme.gutter.triple} ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple} 0;
  display: flex;
  flex-direction: column;
`

const CategoryLabel = styled.span`
  margin: 0 0 ${props => props.theme.gutter.standard};
`

class LanguageCategories extends React.PureComponent {
  render() {
    const { data, defaultLanguage, translatedLanguages, handleCellChange, selectedLanguage, enabledLanguages, onChangeLanguage } =
      this.props
    return (
      <div>
        {data.map((section, index) => {
          const elementId = index + 1
          const enabledLanguage = !_.isEmpty(selectedLanguage) ? selectedLanguage : defaultLanguage.value
          const enabledLanguageStrings = section.strings.filter(languageString => !_.isEmpty(languageString[enabledLanguage]))
          return (
            !_.isEmpty(enabledLanguageStrings) && (
              <SectionContainer key={index} id={`language-section${elementId}`}>
                {section.category && <CategoryLabel id={`category-label${elementId}`}>{section.category}</CategoryLabel>}
                <LanguageTable
                  key={index}
                  elementId={elementId}
                  data={enabledLanguageStrings}
                  defaultLanguage={defaultLanguage}
                  translatedLanguages={translatedLanguages}
                  category={section.category}
                  handleCellChange={handleCellChange}
                  enabledLanguages={enabledLanguages}
                  onChangeLanguage={onChangeLanguage}
                  selectedLanguage={selectedLanguage}
                />
              </SectionContainer>
            )
          )
        })}
      </div>
    )
  }
}

export default LanguageCategories
