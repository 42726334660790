import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

const ActiveBarContainer = styled.div`
  background: #eaf5fe;
  height: 16px;
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gutter.medium};
  font-size: ${props => props.theme.fontSize.body};
`

const StatusIcon = styled.div`
  background-color: ${props => (props.isActive === true ? props.theme.active : props.theme.inactive)};
  margin: 0 ${props => props.theme.gutter.standard};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SwitchToggleLink = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

const StatusBlock = ({ isActive }) => (
  <StatusContainer>
    Order Site Status:
    <StatusIcon isActive={isActive} data-test="edit-site-active-bar-indicator" />
    {isActive ? 'Active' : 'Not Active'}
  </StatusContainer>
)

const Loader = ({ isLoading, children }) => (isLoading ? 'Loading...' : children)

const OrderSiteStatus = ({ isActive, isLoading, updateIsActive }) => {
  const isLoadingData = isLoading || _.isNil(isActive)

  return (
    <ActiveBarContainer data-test="edit-site-active-bar">
      <Loader isLoading={isLoadingData}>
        <StatusBlock isActive={isActive} />
        <SwitchToggleLink onClick={updateIsActive} data-test="edit-site-active-bar-toggle-link">
          {`Switch ${isActive === true ? 'off' : 'on'}`}
        </SwitchToggleLink>
      </Loader>
    </ActiveBarContainer>
  )
}

export default OrderSiteStatus
