import type { AvailabilityCalendarTimeslotResponse } from '@sevenrooms/core/domain'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Grid } from '@sevenrooms/react-components/components/Grid'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { AvailabilityCalendarBlock } from './AvailabilityCalendarBlock'
import { GRID_ITEM_MIN_WIDTH } from './constants'

interface AvailabilityCalendarGridRowProps {
  partySize: number
  timeslots: AvailabilityCalendarTimeslotResponse[]
  date: DateOnly
}
export function AvailabilityCalendarHourItem({
  timeslots,
  partySize,
  date,
}: {
  timeslots: AvailabilityCalendarTimeslotResponse[]
  partySize: number
  date: DateOnly
}) {
  const bookableTimeslots = timeslots.filter(x => x.isBookableInterval)

  return (
    <Grid item sx={{ minWidth: GRID_ITEM_MIN_WIDTH }} data-test="availability-calendar-hour-item">
      <Stack direction="row">
        {bookableTimeslots.map(timeslot => (
          <AvailabilityCalendarBlock key={`block-${timeslot.sortOrder}`} timeslot={timeslot} partySize={partySize} date={date} />
        ))}
      </Stack>
    </Grid>
  )
}

export function AvailabilityCalendarGridRow({ timeslots, partySize, date }: AvailabilityCalendarGridRowProps) {
  const theme = useTheme()

  const timeslotHourItems: AvailabilityCalendarTimeslotResponse[][] = []
  for (const [index, timeslot] of timeslots.entries()) {
    const prevTimeslot = timeslots.at(index - 1)
    if (prevTimeslot?.time.getInfo().hours === timeslot.time.getInfo().hours) {
      timeslotHourItems.at(-1)?.push(timeslot)
    } else {
      timeslotHourItems.push([timeslot])
    }
  }

  return (
    <Grid container item spacing={1} wrap="nowrap" sx={{ mb: theme.spacing(1) }}>
      {timeslotHourItems.map(hourTimeslots => (
        <AvailabilityCalendarHourItem key={hourTimeslots[0]?.time.toIso()} timeslots={hourTimeslots} partySize={partySize} date={date} />
      ))}
    </Grid>
  )
}
