import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { CallToAction, HigherModal, Error, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import { convert, transition, ProviderState } from '../actions/EmailServiceProviderActions'

const Button = styled.div`
  display: flex;
  height: 50px;
  background: #4285f4;
  border: 1px solid #4285f4;
  width: 260px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const Logo = styled.div`
  background: white;
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Text = styled.div`
  flex: 1;
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
`

const LocationLink = styled.span`
  color: ${props => props.theme.primary};
  cursor: pointer;
`

const LocationInfo = styled.div`
  color: #999;
  font-size: 12px;
  margin-top: 20px;
`

const Location = ({ text, container }) => (
  <LocationLink onClick={() => transition(container, ProviderState.POST_AUTHENTICATED)}>{text}</LocationLink>
)

const GoogleButton = ({ src, onClick, text }) => (
  <Button onClick={onClick}>
    <Logo>
      <img width="18" height="18" src={src} />
    </Logo>
    <Text>{text}</Text>
  </Button>
)

const AuthedHeader = ({ container }) => (
  <span>
    <CallToAction>Linked to the google account {container.state.connected_account}</CallToAction>
    <GoogleButton
      className="google-auth-button"
      onClick={() => transition(container, ProviderState.DISCONNECTING)}
      src={`${container.props.media_url}images/google-g-logo.png`}
      text="Disconnect"
    />
  </span>
)

export class GoogleSection extends PureComponent {
  constructor(props) {
    super(props)
    this.url_base = 'google'
    this.connected_account = null
    this.state_var = 'google_connection'
    this.service_type = 'reviewchannels'
    this.state = { [this.state_var]: ProviderState.WORKING, error: undefined }
    this.googleCallback = this.googleCallback.bind(this)
  }

  componentDidMount() {
    this.checkConnection()
  }

  render() {
    switch (this.state.google_connection) {
      case ProviderState.DISCONNECTED:
        return (
          <span>
            <CallToAction>Link an existing Google account</CallToAction>
            <GoogleButton
              className="google-auth-button"
              onClick={() => this.loginUserGP()}
              src={`${this.props.media_url}images/google-g-logo.png`}
              text="Sign in with Google"
            />
          </span>
        )

      case ProviderState.AUTHENTICATED:
        return (
          <span>
            <AuthedHeader container={this} />
            <LocationInfo>
              <Location text="Add your place ID" container={this} />
            </LocationInfo>
          </span>
        )

      case ProviderState.POST_AUTHENTICATED:
        return (
          <span>
            <HigherModal
              toggler={() => {
                transition(this, this.state.place_id ? ProviderState.CONNECTED : ProviderState.AUTHENTICATED)
              }}
              action={() => this.sendLocation()}
              actionText="Confirm"
              title="PLACE ID"
              content={this.locationForm()}
              visibility
            />
          </span>
        )

      case ProviderState.CONNECTED:
        return (
          <span>
            <AuthedHeader container={this} />
            <LocationInfo>
              Your place ID is {this.state.place_id} <Location text="Change" container={this} />
            </LocationInfo>
          </span>
        )

      case ProviderState.POST_CONNECTING:
        return null

      case ProviderState.CONNECTING:
        return null

      case ProviderState.DISCONNECTING:
        return (
          <span>
            <HigherModal
              toggler={() => {
                transition(this, this.state.place_id ? ProviderState.CONNECTED : ProviderState.AUTHENTICATED)
              }}
              action={() => {
                this.setState({
                  connected_account: null,
                  place_id: null,
                })
                this.disconnect()
              }}
              actionText="Confirm"
              title="Are you sure?"
              content="Disconnecting Google from SevenRooms will remove the ability to reply to Google reviews through SevenRooms."
              visibility
            />
          </span>
        )

      case ProviderState.CANT_CONNECT:
        return null

      case ProviderState.WORKING:
        return (
          <span>
            <CallToAction className="emma-working-button">Working....</CallToAction>
          </span>
        )

      case ProviderState.ERROR:
        return <CallToAction>Error communicating with 7r</CallToAction>

      default:
        return null
    }
  }

  disconnect() {
    if (this.state[this.state_var] === ProviderState.DISCONNECTING) {
      transition(this, ProviderState.WORKING)
      fetch(`/manager/${this.props.venue_id}/reviewchannels/${this.url_base}/disconnect`, {
        method: 'POST',
      })
        .then(convert)
        .then(r => transition(this, ProviderState.DISCONNECTED))
        .catch(r => transition(this, ProviderState.ERROR))
    }
  }

  googleCallback(response) {
    const data = new FormData()
    data.append('code', response.code)
    fetch(`/manager/${this.props.venue_id}/reviewchannels/${this.url_base}/process_auth`, {
      method: 'POST',
      body: data,
    })
      .then(convert)
      .then(r => {
        this.setState({ connected_account: r.google_review_email })
        transition(this, ProviderState.POST_AUTHENTICATED)
      })
      .catch(r => this.setState({ error: 'Error communicating with 7r' }))
  }

  loginUserGP() {
    transition(this, ProviderState.WORKING)
    googleAuthClient.callback = this.googleCallback
    googleAuthClient.requestCode()
  }

  sendLocation() {
    transition(this, ProviderState.WORKING)
    const data = new FormData()
    data.append('place_id', this.state.place_id)

    fetch(`/manager/${this.props.venue_id}/reviewchannels/${this.url_base}/connect`, {
      method: 'POST',
      body: data,
    })
      .then(convert)
      .then(r => {
        if (r.success) {
          transition(this, ProviderState.CONNECTED)
        } else {
          this.setState({ place_id: null })
          transition(this, ProviderState.POST_AUTHENTICATED, 'Unable to find location by place ID, please try again.')
        }
      })
      .catch(r => this.setState({ error: 'Error communicating with 7r' }))
  }

  locationForm() {
    return (
      <span>
        <CallToAction>Set Google place ID</CallToAction>
        <Error>{this.state.error}</Error>
        <TextInput placeholder="Place ID" name="place_id" value={this.state.place_id} onChange={place_id => this.setState({ place_id })} />
        <SubDesc>
          <a href="https://developers.google.com/maps/documentation/places/web-service/place-id" target="_blank" rel="noreferrer">
            Where can I find my place ID?
          </a>
        </SubDesc>
      </span>
    )
  }

  checkConnection() {
    if (!this.props.is_sizzle_enabled) {
      transition(this, ProviderState.CANT_CONNECT)
    } else if (this.state[this.state_var] !== ProviderState.ERROR) {
      fetch(`/manager/${this.props.venue_id}/${this.service_type}/${this.url_base}/is_connected`)
        .then(convert)
        .then(r => {
          let connection_state = ProviderState.DISCONNECTED

          if (r.google_review_email) {
            connection_state = ProviderState.AUTHENTICATED
            this.setState({ connected_account: r.google_review_email })
          }
          if (r.google_review_place_id) {
            connection_state = ProviderState.CONNECTED
            this.setState({ place_id: r.google_review_place_id })
          }
          transition(this, connection_state)
        })
        .catch(() => {
          transition(this, ProviderState.ERROR)
        })
    }
  }
}
