import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useOverflowed } from '@sevenrooms/core/ui-kit/hooks'
import { Tooltip } from '@sevenrooms/core/ui-kit/layout'

export const MenusContainer = styled.div`
  ${props => props.theme.twoLineEllipsis};
`

interface MenusColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  menuNames?: string[]
}

export function MenusColumn({ menuNames, ...props }: MenusColumnProps) {
  const menusString = useMemo(() => (menuNames || []).join(', '), [menuNames])
  const [isOverflowed, menuRef] = useOverflowed(menusString)

  return (
    <Tooltip content={<>{menusString}</>} show={isOverflowed} displayAction="hover">
      <MenusContainer ref={menuRef} {...props}>
        {menusString}
      </MenusContainer>
    </Tooltip>
  )
}
