import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../../locales'
import {
  CREATE_UPGRADE_INSTRUCTIONS_URL,
  CONFIG_ACCESS_RULE_UPGRADES_INSTRUCTIONS_URL,
  CONFIG_BUNDLED_UPGRADES_INSTRUCTIONS_URL,
  formatBold,
} from '../../../../utils'

export function AddUpgradesSuggestedActions() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack mr="s">
      <VStack p="s s s xxs" spacing="s" data-test="insights-add-upgrades-suggested-actions">
        <Text data-test="insights-add-upgrades-suggested-actions-navigate">
          1.{' '}
          {formatMessage(insightsMessages.suggestedActionsAddUpgradesNavigateStep, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            link: (chunks: string[]) => (
              <Link
                to={nav.href(routes.manager.upgrades, {
                  params: { venueKey: venue.urlKey },
                })}
                target="_blank"
                data-test="insights-add-upgrades-suggested-actions-upgrades-link"
              >
                {chunks}
              </Link>
            ),
          })}
        </Text>
        <Text data-test="insights-add-upgrades-suggested-actions-instructions">
          2.{' '}
          {formatMessage(insightsMessages.suggestedActionsFollowInstructionsStep, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            link: (chunks: string[]) => (
              <Link
                to={CREATE_UPGRADE_INSTRUCTIONS_URL}
                target="_blank"
                data-test="insights-add-upgrades-suggested-actions-upgrade-instructions-link"
              >
                {chunks}
              </Link>
            ),
          })}
        </Text>
        <VStack spacing="s" pl="m">
          <Text data-test="insights-add-upgrades-suggested-actions-add-category">
            a. {formatMessage(insightsMessages.suggestedActionsAddUpgradesAddUpgradeCategoryStep)}
          </Text>
          <Text data-test="insights-add-upgrades-suggested-actions-add-options">
            b. {formatMessage(insightsMessages.suggestedActionsAddUpgradesAddUpgradeOptionStep)}
          </Text>
        </VStack>
        <Text data-test="insights-add-upgrades-suggested-actions-add-availability">
          3. {formatMessage(insightsMessages.suggestedActionsAddUpgradesAvailabilityStep)}
        </Text>
        <VStack spacing="s" pl="m">
          <Text data-test="insights-add-upgrades-suggested-actions-add-auto">
            a. {formatMessage(insightsMessages.suggestedActionsAddUpgradesAutoStep)}
          </Text>
          <VStack spacing="s" pl="m">
            <Text data-test="insights-add-upgrades-suggested-actions-open-shifts">
              i.{' '}
              <Link to={nav.href(routes.manager.manage.shifts, { params: { venueKey: venue.urlKey } })} target="_blank">
                {formatMessage(insightsMessages.suggestedActionNavigatetoShiftLinkText, {
                  icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
                })}
              </Link>
            </Text>
            <Text data-test="insights-add-upgrades-suggested-actions-edit-shift">
              ii.{' '}
              {formatMessage(insightsMessages.suggestedActionsAddUpgradesAutoEditShiftStep, {
                strong: (chunks: string[]) => formatBold(chunks),
              })}
            </Text>
            <Text data-test="insights-add-upgrades-suggested-actions-add-to-shift">
              iii.{' '}
              {formatMessage(insightsMessages.suggestedActionsAddUpgradesToShiftStep, {
                strong: (chunks: string[]) => formatBold(chunks),
              })}
            </Text>
          </VStack>
          <Text data-test="insights-add-upgrades-suggested-actions-access-rule">
            b.{' '}
            {formatMessage(insightsMessages.suggestedActionsAddUpgradesToAccessRuleStep, {
              icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore: the type ParseMessage needs to be updated
              link: (chunks: string[]) => (
                <Link
                  to={CONFIG_ACCESS_RULE_UPGRADES_INSTRUCTIONS_URL}
                  target="_blank"
                  data-test="insights-add-upgrades-suggested-actions-access-rule-upgrade-instructions-link"
                >
                  {chunks}
                </Link>
              ),
            })}
          </Text>
          <VStack spacing="s" pl="m">
            <Text data-test="insights-add-upgrades-suggested-actions-bundled-upgrade">
              i.{' '}
              {formatMessage(insightsMessages.suggestedActionsAddUpgradesBundledUpgradesStep, {
                icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: the type ParseMessage needs to be updated
                link: (chunks: string[]) => (
                  <Link
                    to={CONFIG_BUNDLED_UPGRADES_INSTRUCTIONS_URL}
                    target="_blank"
                    data-test="insights-add-upgrades-suggested-actions-bundled-upgrade-instructions-link"
                  >
                    {chunks}
                  </Link>
                ),
              })}
            </Text>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  )
}
