import { Icon, type IconType } from '@sevenrooms/core/ui-kit/icons'
import { variantColor, variantIcon } from '../../utils/insightStatusUtils'

export interface StatusIconProps {
  variant: string
  size?: 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x'
}

export function StatusIcon({ variant, size = 'lg' }: StatusIconProps) {
  const color = variantColor[variant]
  const icon = variantIcon[variant]
  const name = `VMSWeb-insight-${icon}-circle` as IconType

  return <Icon name={name} size={size} color={color} />
}
