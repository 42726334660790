import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { getOrderingBrandSettings, saveOrderingBrandSettings } from 'mgr/lib/services/BrandSettingsServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { defaultBrandColors } from '@sevenrooms/core/ui-kit/theme'

export const loadBrandSettings = () => async (dispatch, getState) => {
  const state = getState()

  const { venue } = state.appState

  let response
  try {
    response = await getOrderingBrandSettings({ venueId: venue.id })
  } catch (e) {
    dispatch(GlobalActions.showErrorMessage('Unable to fetch Brand Settings, please try again later or contact customer support.'))
    return
  }

  const { ordering_brand_settings: orderingBrandSettings } = response.data
  dispatch({
    type: ActionTypes.GET_ORDERING_BRAND_SETTINGS,
    payload: {
      headerImageUrl: orderingBrandSettings.header_img_url || '',
      headerImageBlobKey: orderingBrandSettings.header_img_blob_key || '',
      headerImagePhotoDict: orderingBrandSettings.header_img_photo_dict || '',
      headerImageCropData: _.isEmpty(orderingBrandSettings.header_img_crop_data) ? null : orderingBrandSettings.header_img_crop_data,

      colorPrimary: orderingBrandSettings.color_primary || defaultBrandColors.brandColor,
      fontsColorLinks: orderingBrandSettings.fonts_color_links || defaultBrandColors.brandLink,
    },
  })
}

export const saveBrandSettings = () => async (dispatch, getState) => {
  const state = getState()
  const { orderingBrandSettings } = state

  const { id: venueId } = state.appState.venue
  const brandSettings = {
    header_img_blob_key: orderingBrandSettings.headerImageBlobKey || '',
    header_img_url: orderingBrandSettings.headerImageUrl || '',
    header_img_crop_data: JSON.stringify(orderingBrandSettings.headerImageCropData) || {},
    color_primary: orderingBrandSettings.colorPrimary,
    fonts_color_links: orderingBrandSettings.fontsColorLinks,
  }
  try {
    await saveOrderingBrandSettings({
      venueId,
      brandSettings,
    })
    dispatch(GlobalActions.showSuccessMessage('Saved successfully and published'))
    dispatch({ type: ActionTypes.SAVE_ORDERING_BRAND_SETTINGS_SUCCESS })
  } catch (e) {
    dispatch(GlobalActions.showErrorMessage('Unable to save Brand Settings, please try again later or contact customer support.'))
  }
}

export const updateBrandSettingsField = (field, value) => ({
  type: ActionTypes.UPDATE_BRAND_SETTINGS_FIELD,
  field,
  value,
})
