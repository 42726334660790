const validateTips = (fields, enforceDoordashRules) => {
  const defaultTipNumber = fields.default_tip
  const tipField = `tip${defaultTipNumber}`
  if (enforceDoordashRules && fields[tipField] < 15) {
    return {
      [tipField]: 'For the DoorDash Drive integration, your default tip percentage has to be at least 15%',
    }
  }
  return {}
}

export const validateFields = (fields, enforceDoordashRules) => ({
  ...validateTips(fields, enforceDoordashRules),
})
