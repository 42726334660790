export const CommonStyles = {
  ClientVipColor: '#F7ED5A',
  ButtonPrimaryColor: '#3391D7',
  ProblemReservationMinor: '#ff943d',
  ProblemReservationMajor: '#f3356b',
}

export const DayViewStyles = {
  NotesHighlightBarHeight: 56,
}

export const GridStyles = {
  TimeHeaderHeight: 70,
  TimeHeaderWidth: 60,
  ReservationRowHeight: 34,
  ReservationRowVerticalMargin: 4,
  TableSortWidth: 125,
  SectionsTopMargin: 10,
  SectionsLeftMargin: 15,
  MajorAxesBorderWidth: 2,
  MinorAxesBorderWidth: 1,
  MoveModeTopBarHeight: 85,
}
GridStyles.TableCellWidth = GridStyles.TableSortWidth - GridStyles.SectionsLeftMargin + 10 // TODO wtf why 10
