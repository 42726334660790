import { useCallback, useMemo, useState } from 'react'
import { useChangeOrderStatusMutation } from '@sevenrooms/core/api'
import type { OrderStatus, Order } from '@sevenrooms/core/domain'
import { OrderMethodStatuses } from '@sevenrooms/core/domain/constants'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { OrdersSelect } from '@sevenrooms/core/ui-kit/core'
import { Flex, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import { useNavigationCalendarContext } from '@sevenrooms/mgr-core/hooks/useNavigationCalendarContext'
import { useOrdersManagementResourcesContext } from '../hooks/useOrdersManagementResourcesContext'
import { ordersMessages } from '../locales/ordersMessages'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

interface OrderStatusCellProps {
  venue: Venue
  order: Order
  onStatusChange: (order: Order, status: OrderStatus) => void
  onOpen: () => void
}

export function OrderStatusCell({ venue, order, onStatusChange, onOpen }: OrderStatusCellProps) {
  const { formatMessage } = useLocales()
  const { date } = useNavigationCalendarContext()
  const formattedDate = date ? DateOnly.fromDate(date).toIso() : null
  const { statusChoices, statusDisplayNames } = useOrdersManagementResourcesContext()
  const filteredStatusChoices: OrderStatus[] = useMemo(
    () =>
      statusChoices
        .filter(status => OrderMethodStatuses[order.method].includes(status.id as OrderStatus) || order.status === status.id)
        .map(status => status.id as OrderStatus),
    [order, statusChoices]
  )
  const [disabled, setDisabled] = useState(false)

  const [changeOrderStatus] = useChangeOrderStatusMutation()

  const changeStatus = useCallback(
    async (status: OrderStatus) => {
      setDisabled(true)
      const { error } = (await changeOrderStatus({ venueId: venue.id, orderId: order.id, status, date: formattedDate })) as {
        error: FetchBaseQueryError
      }
      if (error) {
        notify({ content: formatMessage(ordersMessages.ordersNotificationChangeStatusFailure), type: 'error' })
      } else {
        notify({
          content: (
            <Text color="primaryBackground">
              {formatMessage(ordersMessages.ordersNotificationChangeStatusSuccess, {
                status: () => (
                  <Text color="primaryBackground" fontWeight="bold">
                    {statusDisplayNames[status]}
                  </Text>
                ),
              })}
            </Text>
          ),
          type: 'success',
        })
      }
      setDisabled(false)
    },
    [changeOrderStatus, formatMessage, formattedDate, order.id, statusDisplayNames, venue.id]
  )

  const onChangeCallback = useCallback(
    (status: OrderStatus) => {
      changeStatus(status).then()
      onStatusChange(order, status)
    },
    [changeStatus, onStatusChange, order]
  )

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      onClick={event => {
        event.stopPropagation()
      }}
    >
      <OrdersSelect
        choices={filteredStatusChoices}
        value={order.status}
        onChange={onChangeCallback}
        data-test={`order-${order.id}-status-select`}
        onOpen={onOpen}
        disabled={disabled}
        scrollLock
      />
    </Flex>
  )
}
