import { Draggable, type DraggableProvided, type DraggableStateSnapshot } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { MenuItem } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/MenuItem'
import { SimplePopover } from '@sevenrooms/core/ui-kit/form'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import type { MenuItem as OrderingMenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface CategoryMenuItemProps {
  venue: Venue
  menuItem: OrderingMenuItem
  categoryName: string
  index: number
  isVisible: boolean
  onDelete: (menuItem: OrderingMenuItem) => void
}

export function CategoryMenuItem({ venue, menuItem, index, isVisible, onDelete }: CategoryMenuItemProps) {
  return (
    <>
      <Draggable draggableId={menuItem.id} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Content pt="s" pb="s" pl="sm" mb="lm" isDragging={snapshot.isDragging}>
              <IconWrapper mr="sm" {...provided.dragHandleProps}>
                <Icon name={Icons['VMSWeb-drag']} />
              </IconWrapper>
              <MenuItem venue={venue} item={menuItem} isVisible={isVisible} />
              <Box pl="xs" pr="xs">
                <SimplePopover data-test={`menu-builder-category-item-menu-${menuItem.id}`}>
                  <SimplePopover.PopoverItem
                    key="menu-builder-delete-item"
                    onClick={() => onDelete(menuItem)}
                    iconName="VMSWeb-remove-circle-outline"
                    text="Remove from category"
                    data-test={`menu-builder-category-item-menu-delete-${menuItem.id}`}
                  />
                </SimplePopover>
              </Box>
            </Content>
          </div>
        )}
      </Draggable>
    </>
  )
}

const Content = styled(Box)<{ isDragging: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.mediumGrey};
  background-color: ${props => props.theme.white};
  box-sizing: border-box;
  border-radius: 4px;
  width: 448px;
  height: 72px;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
`

const IconWrapper = styled(Box)`
  font-size: 14px;
`
