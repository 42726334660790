import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../../locales'
import { formatBold } from '../../../../utils'

export function LowUsersSuggestedActions({ venueUrlKey }: { venueUrlKey: string }) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-low-users-suggested-actions">
      <Text data-test="insights-low-users-suggested-actions-navigate-to-users">
        1.{' '}
        {formatMessage(insightsMessages.suggestedActionNavigatetoUserAccounts, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link to={nav.href(routes.manager.userAccounts, { params: { venueKey: venueUrlKey } })} target="_blank">
              {chunks}
            </Link>
          ),
        })}
      </Text>
      <Text data-test="insights-low-users-suggested-actions-add-new">
        2.{' '}
        {formatMessage(insightsMessages.suggestedActionAddNew, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <Text data-test="insights-low-users-suggested-actions-setup-user">
        3. {formatMessage(insightsMessages.suggestedActionInputUserInfo)}
      </Text>
      <Text data-test="insights-low-users-suggested-actions-save">4. {formatMessage(insightsMessages.suggestedActionSaveRepeat)}</Text>
    </VStack>
  )
}
