import _ from 'lodash'
import React, { Component } from 'react'
import styled from 'styled-components'

const ColorSwatchContainer = styled.div``
const ColorSwatchInputContainer = styled.div``
const ColorSwatchLabelContainer = styled.label``
const ColorSwatchLabel = styled.p``
const ColorSwatchInputInnerContainer = styled.p``
const ColorSwatchField = styled.input`
  background-color: ${props => props.bgColor} !important;
  width: 94px !important;
  height: 52px !important;
  color: transparent !important;
  cursor: pointer !important;
`
const HiddenInput = styled.input``

const ColorSwatchPickerContainer = styled.div`
  position: absolute;
  right: 18px;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;
`

const ColorSwatchTable = styled.table`
  border-spacing: 3px 3px;
  margin-bottom: 0;
  background-color: #eee;
  border-collapse: separate;
`

const ColorSwatchTableBody = styled.tbody``
const ColorSwatchRow = styled.tr``
const ColorSwatchCell = styled.td`
  background-color: ${props => props.bgColor};
  width: 10px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 10px;
`

const DEFAULT_COLOR_CHOICES = [
  '#df39b2',
  '#f35ac3',
  '#f374d0',
  '#f49edd',
  '#f0c8e5',
  '#e21d3e',
  '#ee415b',
  '#fa637a',
  '#fd8396',
  '#ff9aa1',
  '#f57929',
  '#fd9e4e',
  '#febc53',
  '#ffe058',
  '#fdf66e',
  '#43d240',
  '#7ce36a',
  '#95e772',
  '#b5ec83',
  '#d4f195',
  '#04c172',
  '#04da76',
  '#4be984',
  '#8ef3b0',
  '#affbbf',
  '#15c1b7',
  '#00c9ba',
  '#2cdcd0',
  '#59e9e0',
  '#8ff4f0',
  '#06a4ff',
  '#1dc4ff',
  '#61d4fb',
  '#90ddff',
  '#b3e8ff',
  '#1579ff',
  '#4390f7',
  '#67a6fd',
  '#7fb6ff',
  '#99c5ff',
  '#5f6eff',
  '#7d8fff',
  '#88a5f5',
  '#a4b9ff',
  '#bdc4fb',
  '#5c8aa1',
  '#7697a8',
  '#97aeb9',
  '#cad6da',
  '#e2e7e9',
]

class ColorSwatchPicker extends Component {
  constructor(props) {
    super(props)
    this.groupedColors = _.chunk(this.props.colors, 10)
  }

  render() {
    return (
      <ColorSwatchPickerContainer>
        <ColorSwatchTable>
          <ColorSwatchTableBody>
            {this.groupedColors.map(function (colorGroup, i) {
              return (
                <ColorSwatchRow key={i}>
                  {colorGroup.map(function (c, j) {
                    return (
                      <ColorSwatchCell
                        key={j}
                        id={`${this.props.id}-${c.substring(1)}`}
                        onClick={_.partial(this.props.onSelectHandler, c)}
                        bgColor={c}
                      />
                    )
                  }, this)}
                </ColorSwatchRow>
              )
            }, this)}
          </ColorSwatchTableBody>
        </ColorSwatchTable>
      </ColorSwatchPickerContainer>
    )
  }
}

class ColorSwatch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      color: this.props.color || '#000000',
      colors: this.props.colors || DEFAULT_COLOR_CHOICES,
      id: this.props.id,
      name: this.props.name,
      label: this.props.label || '',
      show_swatch: false,
    }
    this.onClickHandler = this.onClickHandler.bind(this)
    this.onSelectHandler = this.onSelectHandler.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.color !== this.props.color) {
      this.setState({ color: this.props.color })
    }
  }

  onClickHandler(e) {
    if (this.node.contains(e.target)) {
      this.setState({ show_swatch: true })
    } else {
      this.setState({ show_swatch: false })
    }
  }

  componentWillMount() {
    document.addEventListener('click', this.onClickHandler, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickHandler, false)
  }

  onSelectHandler(color, e) {
    this.setState({ show_swatch: false, color })
    this.props.onColorSwatchClick(color)
    e.preventDefault()
  }

  render() {
    return (
      <ColorSwatchContainer id="sr-colorswatch" className="colorswatch">
        <ColorSwatchInputContainer>
          <ColorSwatchLabelContainer>
            {this.props.label && <ColorSwatchLabel className="label">{this.props.label}</ColorSwatchLabel>}
            <ColorSwatchInputInnerContainer className="input">
              <ColorSwatchField
                id={this.state.id}
                type="text"
                bgColor={this.state.color}
                value=""
                className="colorswatch-text"
                onClick={this.onClickHandler}
                ref={node => (this.node = node)}
              />
              <HiddenInput type="hidden" name={this.state.name} value={this.state.color || ''} />
            </ColorSwatchInputInnerContainer>
          </ColorSwatchLabelContainer>
        </ColorSwatchInputContainer>
        {this.state.show_swatch && (
          <ColorSwatchPicker id={this.state.id} colors={this.state.colors} onSelectHandler={this.onSelectHandler} />
        )}
      </ColorSwatchContainer>
    )
  }
}

export default ColorSwatch
