import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'
import { SubNavStyles } from 'mgr/pages/shared/SubNav'
import Toolbar from 'mgr/pages/single-venue/dayview/containers/DayviewToolbar'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import { tryUploadResyReservations } from 'mgr/pages/single-venue/reporting/actions/ResyImport'
import ResChannelFileDropzone from 'mgr/pages/single-venue/reporting/components/ResChannelFileDropzone'
import { ReportingContainer, ReportingWrapper } from 'mgr/pages/single-venue/reporting/components/ReportingWrap'

const ImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${props => props.theme.color.white};
  padding: 0 10px;
`

class ResyImport extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
  }

  onDrop(acceptedFiles) {
    this.props.tryUploadResyReservations(acceptedFiles[0])
  }

  render() {
    const { isUploadingFile, history, onChangeVenue } = this.props

    return (
      <ReportingWrapper>
        <ReportingContainer>
          <Toolbar
            showShiftDropdown={false}
            showDateDropdown={false}
            showControls={false}
            showActionButton={false}
            overrideChangeVenue={onChangeVenue}
            history={history}
            key="dayViewToolBar"
            width={`calc(100% - ${SubNavStyles.width}px - ${ManagerStyles.NavigationWidth}px)`}
          />
          <ImportContainer>
            <h1>Resy Channel Connect Reservation Upload</h1>
            <ResChannelFileDropzone
              onDrop={this.onDrop}
              isUploadingFile={isUploadingFile}
              exampleFileName="venue_reservation_report_Saturday_January_27,_2024.csv"
            />
          </ImportContainer>
        </ReportingContainer>
      </ReportingWrapper>
    )
  }
}

const mapStateToProps = state => ({
  venue: state.appState.venue,
  isUploadingFile: state.guestcenterimport.isUploadingFile,
})

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      onChangeVenue,
      tryUploadResyReservations,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResyImport))
