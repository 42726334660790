import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { map, find } from 'underscore'
import GenericTagsDropDown from 'mgr/lib/components/GenericTagsDropDown'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import { SlideoutContent } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import DropdownMenu from 'svr/component-lib/Manager/Menus/DropdownMenu'
import Button from 'svr/lib/Buttons/Button'
import ColorSwatch from 'svr/lib/ColorPicker/ColorSwatch'
import { addNewCategory, addNewTag, mapTag } from '../components/imports/reservationAndClientImportSlice'

const SlideoutSection = styled.div``
const DisplayArea = styled.div`
  background: #ffffff;
  box-shadow: ${props => props.theme.shadow.six};
  height: 100%;
  padding-bottom: 10px;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0 20px;
`

const InputField = styled.div`
  border: 1px solid ${props => (props.isInvalid ? '#E95757' : '#e6e8eb')};
  border-radius: 5px;
  display: inline-block;
  width: 329px;
  height: 100%;
`
const SlideoutBody = styled.div`
  height: calc(100% - 109px);
  overflow-y: scroll;
`
const SlideoutButtonContainer = styled.div``
const SlideoutButtonTitle = styled.div`
  font-size: 13px;
`
const SlideoutButton = styled.div`
  display: inline-block;
  text-align: center;
  background-color: ${props => (props.isDeleteBtn ? props.theme.color.errorAccent : '#347BAF')};
  color: ${props => props.theme.color.white};
  padding: 12px 28px;
  border-radius: 5px;
  margin-right: 20px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => props.disabled && 0.5};
  pointer-events: ${props => props.disabled && 'none'};
`
const SlideoutFooter = styled.div`
  position: fixed;
  text-align: right;
  bottom: 0;
  width: 100%;
  max-width: 498px;
  padding: 25px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.color.white};
`

const SlideoutLabel = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  color: ${props => props.theme.color.grey};
  font-weight: bold;
  padding: 20px 0 0 20px;
`
const SlideoutErrolLabel = styled(SlideoutLabel)`
  color: red;
  text-transform: none;
  padding: 10px 0px 10px 20px;
`

const SlideoutSubLabel = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.color.grey};
  padding: 20px 0 0 20px;
`

const SlideoutSubSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 0 20px;
`

const ColorPickerContainer = styled.div`
  display: inline-block;
  width: 100px;
`

class TagMappingSlideout extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      chooseFromExistingTag: true,
      chooseFromExistsingCategory: true,
      chosenTags: [],
      chosenCategories: [],
      newTagName: null,
      newCategoryName: null,
      newCategoryColor: '#43d240',
      scope: 0,
      errorText: '',
    }
  }

  handleSelectRadioTag = value => {
    this.setState({
      chooseFromExistingTag: value,
      errorText: '',
    })
  }

  chooseFromExistsingCategory = value => {
    this.setState({
      chooseFromExistsingCategory: value,
      errorText: '',
    })
  }

  handleChangeTagMapping = tags => {
    this.setState({ chosenTags: tags })
  }

  handleChangeTagName = name => {
    this.setState({
      newTagName: name,
    })
  }

  handleSelectCategoryName = category => {
    this.setState({
      chosenCategories: category,
      newCategoryName: null,
    })
  }

  handleChangeCategoryColor = color => {
    this.setState({
      newCategoryColor: color,
    })
  }

  handleChooseScope = scope => {
    this.setState({
      scope,
    })
  }

  handleCreateCategoryName = categoryName => {
    this.setState({
      newCategoryName: categoryName,
      chosenCategories: [],
    })
  }

  handleClose = () => {
    this.setState({
      chooseFromExistingTag: true,
      chooseFromExistsingCategory: true,
      chosenTags: [],
      chosenCategories: [],
      newTagName: null,
      newCategoryName: null,
      newCategoryColor: '#43d240',
      scope: 0,
    })
    this.props.onCloseHandler()
  }

  handleOnClickMapping = () => {
    let tag
    let tagGroup = null
    if (this.state.chooseFromExistingTag) {
      // eslint-disable-next-line prefer-destructuring
      tag = this.state.chosenTags[0]
    } else {
      if (this.state.chooseFromExistsingCategory) {
        tagGroup = find(this.props.tagGroups, elem => elem.id === this.state.chosenCategories[0].value)
      } else {
        tagGroup = {
          id: this.state.newCategoryName,
          name: this.state.newCategoryName,
          name_display: this.state.newCategoryName,
          color_hex: this.state.newCategoryColor,
          isNew: true,
          tags: [],
          tag_name_displays: {},
          scope: this.state.scope,
        }
        this.props.addNewCategory(tagGroup)
      }

      tag = {
        isNew: true,
        tagName: this.state.newTagName,
        tagNameDisplay: this.state.newTagName,
        tagGroup,
      }
      this.props.addNewTag(tag)
    }

    this.props.mapTag({ mappedTag: this.props.tagToMapping, tagToMap: tag })
    this.handleClose()
  }

  renderSelectTag() {
    if (!this.state.chooseFromExistingTag) {
      return null
    }
    let selectedTags = this.state.chosenTags
    if (!selectedTags.length) {
      selectedTags = this.props.mappedTags[this.props.tagToMapping] ? [this.props.mappedTags[this.props.tagToMapping]] : null
    }
    return (
      <SlideoutSection>
        <SlideoutSubLabel>Select tag for mapping</SlideoutSubLabel>
        <SlideoutSubSection>
          <GenericTagsDropDown
            tagGroups={this.props.tagGroups}
            useOutsideLabel={false}
            selectedTags={selectedTags}
            onChangeHandler={this.handleChangeTagMapping}
            height="44px"
            width="100%"
            float="none"
            name="tag to mapping"
            isMultiSelect={false}
          />
        </SlideoutSubSection>
      </SlideoutSection>
    )
  }

  renderCreatingTag() {
    if (this.state.chooseFromExistingTag) {
      return null
    }
    const choices = map(this.props.tagGroups, elem => ({ name: elem.name, value: elem.id }))
    return (
      <SlideoutSection>
        <SlideoutSubLabel>Select tag for mapping</SlideoutSubLabel>
        <SlideoutSubSection>
          <ActiveLabelTextInput
            field="tag-name"
            value={this.state.newTagName}
            isValid={this.state.newTagName}
            placeholderLabel="Tag name"
            onChange={this.handleChangeTagName}
          />
        </SlideoutSubSection>
        <SlideoutSubLabel>Choose or create a category:</SlideoutSubLabel>
        <SlideoutSubSection>
          <RadioList
            field="createCatalogOrChooseRadio"
            selectedValue={this.state.chooseFromExistsingCategory}
            options={[
              { value: true, label: 'Choose from exists' },
              { value: false, label: 'Create a new one' },
            ]}
            onClick={this.chooseFromExistsingCategory}
          />
        </SlideoutSubSection>

        {this.state.chooseFromExistsingCategory ? (
          <SlideoutSubSection>
            <DropdownMenu
              key="add-tag-from-default"
              choices={choices}
              selectedValues={this.state.chosenCategories}
              placeholder="Select Category"
              isLightTheme
              searchingEnabled
              style={{ width: '100%' }}
              isFullSizeMenu
              onChangeHandler={choices => {
                this.handleSelectCategoryName(choices)
              }}
            />
          </SlideoutSubSection>
        ) : null}
        {!this.state.chooseFromExistsingCategory ? (
          <>
            <FlexRow>
              <InputField>
                <ActiveLabelTextInput
                  field="tag-category-name"
                  value={this.state.newCategoryName}
                  placeholderLabel="Category Name"
                  isValid={this.state.newCategoryName}
                  onChange={this.handleCreateCategoryName}
                />
              </InputField>
              <ColorPickerContainer>
                <ColorSwatch
                  id="sr-colorswatch-input"
                  name="color"
                  color={this.state.newCategoryColor}
                  onColorSwatchClick={this.handleChangeCategoryColor}
                />
              </ColorPickerContainer>
            </FlexRow>
            <SlideoutSubLabel>Classification:</SlideoutSubLabel>
            <SlideoutSubSection>
              <RadioList
                field="selectScope"
                selectedValue={this.state.scope}
                options={[
                  { value: 0, label: 'Local (used by this locations)' },
                  { value: 1, label: 'Global (used by all locations)' },
                ]}
                onClick={this.handleChooseScope}
              />
            </SlideoutSubSection>
          </>
        ) : null}
      </SlideoutSection>
    )
  }

  isApplyButtonDisabled = () => {
    const { state } = this
    if (state.chooseFromExistingTag && state.chosenTags.length) {
      return false
    }
    // when all needed fields are filled we check for uniq
    if (
      !state.chooseFromExistingTag &&
      ((state.chooseFromExistsingCategory && state.newTagName && state.chosenCategories.length) ||
        (!state.chooseFromExistsingCategory && state.newTagName && state.newCategoryName))
    ) {
      let errorText = ''
      if (this.state.chooseFromExistsingCategory && this.state.chosenCategories.length) {
        const tagGroup = find(this.props.tagGroups, elem => elem.id === this.state.chosenCategories[0].value)
        const tagName = this.state.newTagName
        if (tagGroup.tags.indexOf(tagName) !== -1) {
          errorText = `'${tagGroup.name}' already has tag '${tagName}'`
        }
      } else if (find(this.props.tagGroups, elem => elem.name === this.state.newCategoryName)) {
        errorText = `Category with name '${this.state.newCategoryName}' already exists`
      }
      this.setState({ errorText })
      return errorText !== ''
    }
    return true
  }

  render() {
    return (
      <SlideoutComponent title="Mapping tags" maxWidth="498px" isSlideoutOpen={this.props.isSlideoutOpen} onCloseHandler={this.handleClose}>
        <SlideoutContent>
          <DisplayArea>
            <SlideoutBody id="sr-tags-slideout-main">
              <SlideoutSection>
                <SlideoutLabel>Map tag to tag '{this.props.tagToMapping}' </SlideoutLabel>
                <SlideoutSubLabel>Do you want to use exists tag or create a new one?</SlideoutSubLabel>
                <SlideoutSubSection>
                  <RadioList
                    field="createTarOrChooseRadio"
                    selectedValue={this.state.chooseFromExistingTag}
                    options={[
                      { value: true, label: 'Choose from exists' },
                      { value: false, label: 'Create a new one' },
                    ]}
                    onClick={this.handleSelectRadioTag}
                  />
                </SlideoutSubSection>
              </SlideoutSection>
              {this.renderSelectTag()}
              {this.renderCreatingTag()}
            </SlideoutBody>
            <SlideoutFooter>
              {this.state.errorText && <SlideoutErrolLabel>{this.state.errorText}</SlideoutErrolLabel>}
              <SlideoutButton onClick={this.handleOnClickMapping} disabled={this.isApplyButtonDisabled()}>
                <Button
                  buttonId="sr-submit-mapping"
                  onClick={this.handleOnClickMapping}
                  disabled={this.isApplyButtonDisabled()}
                  innerElement={
                    <SlideoutButtonContainer>
                      <SlideoutButtonTitle>Apply</SlideoutButtonTitle>
                    </SlideoutButtonContainer>
                  }
                />
              </SlideoutButton>
            </SlideoutFooter>
          </DisplayArea>
        </SlideoutContent>
      </SlideoutComponent>
    )
  }
}

TagMappingSlideout.propTypes = {
  tagGroups: React.PropTypes.arrayOf(React.PropTypes.object),
  isSlideoutOpen: React.PropTypes.bool.isRequired,
  tagToMapping: React.PropTypes.string,

  addNewCategory: React.PropTypes.func.isRequired,
  addNewTag: React.PropTypes.func.isRequired,
  onCloseHandler: React.PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  mappedTags: state.reservationAndClientImport.mappedTags,
})

const mapDispatchToProps = {
  addNewCategory,
  addNewTag,
  mapTag,
}

export default connect(mapStateToProps, mapDispatchToProps)(TagMappingSlideout)
