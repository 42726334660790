import constate from 'constate'
import { useState } from 'react'
import type { InsightDetail, Insight, InsightVenueUser } from '@sevenrooms/core/domain'
import { useDestination } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/routes'

function useInsightsDashboard() {
  const { query } = useDestination(routes.manager2.insights)
  const urlId = query.iid ? query.iid : null

  const [insightId, setInsightId] = useState<string | null>(null)
  const [insight, setInsight] = useState<InsightDetail | null>(null)
  const [browseStatus, setBrowseStatus] = useState<string | null>(null)
  const [queryId, setQueryId] = useState<string | null>(urlId)
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false)
  const [insightList, setInsightList] = useState<Insight[]>([])
  const [insightVenueUsers, setInsightVenueUsers] = useState<InsightVenueUser[]>()
  return {
    setInsightId,
    insightId,
    setInsight,
    insight,
    setBrowseStatus,
    browseStatus,
    queryId,
    setQueryId,
    setSlideoutOpen,
    slideoutOpen,
    setInsightList,
    insightList,
    setInsightVenueUsers,
    insightVenueUsers,
  }
}

export const [InsightsDashboardContext, useInsightsDashboardContext] = constate(useInsightsDashboard)
