import React, { useMemo } from 'react'
import type { TablesWithoutSeatingAreaTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Spreadsheet, Box, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'

export function TablesWithoutSeatingAreaProofTable({ tableRows }: { tableRows: TablesWithoutSeatingAreaTableRow[] }) {
  const { formatMessage } = useLocales()
  const columnWidth = 75
  const columns = useMemo<DataTableColumn<TablesWithoutSeatingAreaTableRow>[]>(
    () => [
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.noSeatingAreaTableHeaderTableNum)}
          </Text>
        ),
        render: 'tableName',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.noSeatingAreaTableHeaderImpactedFloorplans)}
          </Text>
        ),
        key: 'impactedFloorplans',
        render: (row: TablesWithoutSeatingAreaTableRow) => <Text overflowWrap="break-word">{row.impactedFloorplans}</Text>,
        width: columnWidth,
        maxWidth: columnWidth,
      },
    ],
    [formatMessage]
  )
  return (
    <Box data-test="no-seating-area-durations-proof-grid">
      <Spreadsheet data={tableRows} columns={columns} noPagination />
    </Box>
  )
}
