/* eslint-disable camelcase */
import moment from 'moment-timezone'
import type {
  OrderingMenuApi,
  OrderingMenu,
  MenuCategoryItemApi,
  MenuCategoryItem,
  TimeRange,
} from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

const DATE_FORMAT = 'YYYY-MM-DD'

export const adaptOrderingMenuFromApi = (menu: OrderingMenuApi): OrderingMenu => {
  const categories = (menu?.hierarchy?.categories || []).map(category => ({
    ...category,
    items: category.items.map(item => adaptCategoryMenuItemFromApi(item)),
  }))
  const excludedDateRanges = menu.excluded_date_ranges?.map(excludedDateRange => ({
    ...excludedDateRange,
    from: moment(excludedDateRange.from, DATE_FORMAT),
    to: moment(excludedDateRange.to, DATE_FORMAT),
  }))
  return {
    availableHours: menu.available_hours,
    dateRangeFrom: moment(menu.date_range_from, DATE_FORMAT),
    dateRangeTo: menu.date_range_to ? moment(menu.date_range_to, DATE_FORMAT) : null,
    excludedDateRanges,
    id: menu.id,
    hierarchy: {
      ...menu.hierarchy,
      categories,
    },
    isActive: menu.is_active,
    name: menu.name,
    prepTimeMins: menu.prep_time_mins,
    sortOrder: menu.sort_order,
    updated: menu.updated,
    sourceIntegrationNameDisplay: menu.source_integration_name_display,
  }
}

export const adaptOrderingMenuToApi = (menu: OrderingMenu): OrderingMenuApi => {
  const categories = (menu.hierarchy.categories || []).map(category => ({
    ...category,
    items: category.items.map(menuItem => adaptCategoryMenuItemToApi(menuItem)),
  }))
  const availableHours = Object.entries(menu.availableHours)
    .map<[string, TimeRange[]]>(([dayOfWeekIndex, timeRanges]) => [dayOfWeekIndex, timeRanges.filter(range => range.start && range.end)])
    .reduce<Record<number, TimeRange[]>>((accumulator, [index, timeRanges]) => {
      const dayOfWeekIndex = parseInt(index)
      accumulator[dayOfWeekIndex] = timeRanges
      return accumulator
    }, {})
  const excluded_date_ranges = menu.excludedDateRanges.map(excludedDateRange => ({
    ...excludedDateRange,
    from: excludedDateRange.from.format(DATE_FORMAT),
    to: excludedDateRange.to.format(DATE_FORMAT),
  }))
  return {
    available_hours: availableHours,
    excluded_date_ranges,
    id: menu.id,
    hierarchy: {
      ...menu.hierarchy,
      categories,
    },
    name: menu.name || '',
    prep_time_mins: menu.prepTimeMins,
    sort_order: menu.sortOrder,
    updated: menu.updated || '',
    date_range_from: menu.dateRangeFrom.format(DATE_FORMAT),
    date_range_to: menu.dateRangeTo?.format(DATE_FORMAT) || null,
    source_integration_name_display: menu.sourceIntegrationNameDisplay || '',
  }
}

export const adaptCategoryMenuItemFromApi = (menuItem: MenuCategoryItemApi): MenuCategoryItem => ({
  id: menuItem.id,
  modifierGroups: menuItem.modifier_groups,
})

export const adaptCategoryMenuItemToApi = (menuItem: MenuCategoryItem): MenuCategoryItemApi => ({
  id: menuItem.id,
  modifier_groups: menuItem.modifierGroups,
})
