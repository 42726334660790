import { useLocales } from '@sevenrooms/core/locales'
import { insightsMessages } from '../../../../locales'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { PaymentEnabledSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'
import { FeatureEnablementProofSection } from '../../../Proofs'
import type { DetailProps } from '../../../../utils'

export function PaymentEnabledDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { insight } = data

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={formatMessage(insightsMessages.paymentEnabledSlideoutDescription)}
      proofLabel={formatMessage(insightsMessages.paymentEnabledProofLabel)}
      suggestedActionsComponent={<PaymentEnabledSuggestedActions />}
      proofComponent={
        <FeatureEnablementProofSection
          title={formatMessage(insightsMessages.paymentEnabledProofTitle)}
          lastCheckedDate={insight.createdDatetime}
        />
      }
    />
  )
}
