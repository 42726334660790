import { useMemo } from 'react'
import type { PacingCoverageTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Spreadsheet, Box, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'

export function PacingCoverageProofTable({ tableRows }: { tableRows: PacingCoverageTableRow[] }) {
  const { formatMessage } = useLocales()
  const columnWidth = 200
  const columns = useMemo<DataTableColumn<PacingCoverageTableRow>[]>(
    () => [
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderShiftName)}
          </Text>
        ),
        render: 'shiftName',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderDate)}
          </Text>
        ),
        render: 'date',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderDayOfWeek)}
          </Text>
        ),
        render: 'dayOfWeek',
        width: columnWidth,
        maxWidth: columnWidth,
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderMaxCover)}
          </Text>
        ),
        render: 'maxCoverCapacity',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow={3} textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderTotalPacing)}
          </Text>
        ),
        render: 'totalShiftPacing',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderPacingCoverage)}
          </Text>
        ),
        key: 'pacingCoveragePct',
        render: tableRow => `${tableRow.pacingCoveragePct.toFixed(1)}%`,
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderCompletedCovers)}
          </Text>
        ),
        render: 'completedCovers',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow="twoLinesEllipsis" textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderPacingFill)}
          </Text>
        ),
        key: 'pacingFillPct',
        render: tableRow => `${tableRow.pacingFillPct.toFixed(1)}%`,
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
      {
        header: () => (
          <Text textOverflow={3} textStyle="body1Bold">
            {formatMessage(insightsMessages.pacingCoverageTableHeaderSearchesNoAvailability)}
          </Text>
        ),
        render: 'searchesWithoutNearbyAvailability',
        width: columnWidth,
        maxWidth: columnWidth,
        cellAlign: 'end',
      },
    ],
    [formatMessage]
  )

  return (
    <Box data-test="pacing-coverage-proof-grid">
      <Spreadsheet width={1200} data={tableRows} columns={columns} noPagination />
    </Box>
  )
}
