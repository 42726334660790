import _ from 'lodash'
import { useState, useCallback } from 'react'

const useForm = initialFields => {
  const [fields, setFields] = useState(initialFields)

  const checkFieldsChanged = useCallback(checkFields => !_.isEqual(fields, checkFields), [fields])

  const resetFields = useCallback((newFields = {}) => {
    const mergedFields = {
      ...initialFields,
      ...newFields,
    }
    setFields(mergedFields)
  }, [])

  const handleSubmit = event => {
    if (event) {
      event.preventDefault()
    }
  }

  const handleChange = useCallback((value, fieldName) => {
    setFields(fields => ({
      ...fields,
      [fieldName]: value,
    }))
  }, [])

  const handleChangeFields = useCallback(newFields => {
    setFields(fields => ({
      ...fields,
      ...newFields,
    }))
  }, [])

  return {
    checkFieldsChanged,
    resetFields,
    handleChange,
    handleChangeFields,
    handleSubmit,
    fields,
  }
}

export default useForm
