import React from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { StyledVmsIconS, VmsIcons } from 'svr/common/VmsIcons'
import { bubbleClickOutsideConfig, OverflowMenuBase } from './index'

const Content = styled.div`
  display: flex;
`

const MoreIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > ${MoreIcon} {
    :hover {
      cursor: pointer;
      color: ${props => (props.isActive === true ? props.theme.background : '#347baf')};
      background-color: ${props => (props.isActive === true ? props.theme.primary : '#e8f5ff')};
      color: ;
    }
  }
`

const MoreIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSize.medium};
  color: ${props => (props.isActive === true ? props.theme.background : props.theme.darkGrey)};
  background-color: ${props => (props.isActive === true ? props.theme.primary : 'transparent')};
  width: 36px;
  height: 36px;
  padding-left: ${props => props.theme.gutter.standard};
  border-radius: 40px;
  text-align: center;
  transition: all 0.3s ease;
`

class StyledOverflowMenuBase extends OverflowMenuBase {
  render() {
    return (
      <MoreIconContainer isActive={this.state.open} onClick={this.onBubbleClick}>
        <MoreIcon data-test="menu-container" isActive={this.state.open}>
          <StyledVmsIconS>{VmsIcons.More}</StyledVmsIconS>
          {this.state.open && <Content>{this.props.children}</Content>}
        </MoreIcon>
      </MoreIconContainer>
    )
  }
}

StyledOverflowMenuBase.defaultProps = {
  onClickOutside: () => {},
  onBubbleClick: () => {},
}

export const StyledOverflowMenu = onClickOutside(StyledOverflowMenuBase, bubbleClickOutsideConfig)
