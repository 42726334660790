import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import DirtyStateUnloader from 'mgr/lib/components/DirtyStateUnloader'
import { dateParts, getPythonWeekday } from 'mgr/lib/utils/MomentUtils'
import { AvailableTimes } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/AvailableTimes/AvailableTimes'
import { ExcludedDateRanges } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ExcludedDateRanges/ExcludedDateRanges'
import { MenuBuilder } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/MenuBuilder'
import { EditingLayoutFooter } from 'mgr/pages/single-venue/settings/components/shared/layout/EditingLayoutFooter'
import { UTCdateToTimezoneFormat } from 'svr/common/DateTime'
import FormField from 'svr/component-lib/Generic/Form/FormField'
import GroupPanel from 'svr/component-lib/Generic/Panels/GroupPanel/GroupPanel'
import GroupPanelRow from 'svr/component-lib/Generic/Panels/GroupPanel/GroupPanelRow'
import GroupPanelRowCell from 'svr/component-lib/Generic/Panels/GroupPanel/GroupPanelRowCell'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import TinyWidthInput from 'svr/component-lib/Generic/TextInputs/TinyWidthInput'
import EditingLayout from 'svr/component-lib/Manager/Layout/EditingLayout'
import SectionDropdown from 'svr/component-lib/Manager/Section'
import { Button, DateRangePicker, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, Tab, TabList, TabPanel, Tabs } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import type {
  MenuCategory,
  MenuCategoryItem,
  OrderingMenu,
  ExcludedDateRange,
} from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { PRODUCT_TYPES_ALL } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

enum TABS {
  GENERAL_DETAILS = 'GENERAL_DETAILS',
  MENU_BUILDER = 'MENU_BUILDER',
  MENU_AVAILABILITY = 'MENU_AVAILABILITY',
  EXCLUDED_DATES = 'EXCLUDED_DATES',
}

const TABS_DISPLAY_NAMES: Record<string, string> = {
  [TABS.GENERAL_DETAILS]: 'General Details',
  [TABS.MENU_BUILDER]: 'Menu Builder',
  [TABS.MENU_AVAILABILITY]: 'Menu Availability',
  [TABS.EXCLUDED_DATES]: 'Excluded Dates',
}
const isFieldNotEmpty = (value?: string) => !_.isEmpty((value || '').trim())

export interface MenuFormProps {
  menu: OrderingMenu
  menuItems: MenuItem[]
  venue: Venue
  selectedMenuCategory?: MenuCategory
  onSave: (menu: OrderingMenu) => Promise<void>
  onCancel: () => void
  isSaving: boolean
  onNameChange: (value: string) => void
  onPrepTimeChange: (value: number) => void
  isDirty: boolean
  isCategoryModalOpen: boolean
  isConfirmDeleteModalOpen: boolean
  onCategoryModalOpen: (category?: MenuCategory) => void
  onCategoryModalClose: () => void
  onConfirmDeleteModalClose: () => void
  onCategoryOrderChanged: (categoryName: string, position: number) => void
  onConfirmDeleteModalOpen: (categoryName: string) => void
  onCategoryMenuItemDelete: (category: MenuCategory, menuItem: MenuItem) => void
  onCategoryMenuItemsUpdate: (category: MenuCategory, menuItems: MenuCategoryItem[]) => void
  onCategoryUpdate: (categoryName: string, category: MenuCategory) => void
  onCategoryDelete: (categoryName: string) => void
  onCategoryCreate: (category: MenuCategory) => void
  isAddMenuItemModalOpen: boolean
  onAddMenuItemModalOpen: (category: MenuCategory) => void
  onAddMenuItemModalClose: () => void
  onUpdateDateRangeFrom: (value: Date) => void
  onUpdateDateRangeTo: (value: Date | null) => void
  onAddMenuAvailability: (dayOfWeekIndex: number) => void
  onRemoveMenuAvailability: (dayOfWeekIndex: number, dateRangeIndex: number) => void
  onCopyMenuAvailabilityToAll: (dayOfWeekIndex: number, dateRangeIndex: number) => void
  onMenuAvailabilityUpdate: (dayOfWeekIndex: number, dateRangeIndex: number, start?: string, end?: string) => void
  onAddMenuExcludedDateRange: (excludedDateRange: ExcludedDateRange) => void
  onUpdateMenuExcludedDateRange: (index: number, excludedDateRange: ExcludedDateRange) => void
  onRemoveExcludedDateRange: (index: number) => void
}

export function OrderingMenuForm({
  menu,
  menuItems,
  venue,
  selectedMenuCategory,
  onSave,
  onCancel,
  isSaving,
  onNameChange,
  onPrepTimeChange,
  isDirty,
  isCategoryModalOpen,
  isConfirmDeleteModalOpen,
  onConfirmDeleteModalClose,
  onCategoryModalOpen,
  onCategoryModalClose,
  onCategoryOrderChanged,
  onCategoryCreate,
  onConfirmDeleteModalOpen,
  onCategoryUpdate,
  onCategoryDelete,
  onCategoryMenuItemDelete,
  onCategoryMenuItemsUpdate,
  isAddMenuItemModalOpen,
  onAddMenuItemModalOpen,
  onAddMenuItemModalClose,
  onAddMenuAvailability,
  onUpdateDateRangeFrom,
  onUpdateDateRangeTo,
  onRemoveMenuAvailability,
  onCopyMenuAvailabilityToAll,
  onMenuAvailabilityUpdate,
  onAddMenuExcludedDateRange,
  onUpdateMenuExcludedDateRange,
  onRemoveExcludedDateRange,
}: MenuFormProps) {
  const tabs = useMemo(
    () =>
      Object.keys(TABS)
        .filter((id: string) => venue.isSevenRoomsOrderingMiddleware || id !== TABS.MENU_BUILDER)
        .map((id: string) => ({ id, text: TABS_DISPLAY_NAMES[id] })),
    [venue.isSevenRoomsOrderingMiddleware]
  )
  const lastUpdatedTitle = useMemo(() => {
    const middleWare =
      !venue.isSevenRoomsOrderingMiddleware && menu.sourceIntegrationNameDisplay ? ` from ${menu.sourceIntegrationNameDisplay}` : ''
    const updatedAt = menu.updated ? UTCdateToTimezoneFormat(menu.updated, venue.timezone, 'MM/DD/YY h:mm:ss A') : ''
    return updatedAt ? `Last updated${middleWare} ${updatedAt}` : ''
  }, [menu.sourceIntegrationNameDisplay, menu.updated, venue.isSevenRoomsOrderingMiddleware, venue.timezone])

  const [activeTab, setActiveTab] = useState(TABS.GENERAL_DETAILS as string)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedTabIndex = useMemo(() => _.findIndex(tabs, { id: activeTab }), [activeTab])
  const [nameValid, setNameValid] = useState(true)
  const isFormValid = isFieldNotEmpty(menu?.name)
  const isSaveDisabled = !isFormValid || isSaving

  const onSaveForm = useCallback(() => {
    if (isSaveDisabled) {
      return
    }
    onSave({
      ...menu,
    } as OrderingMenu)
  }, [isSaveDisabled, menu, onSave])

  const onNameFieldChanged = useCallback(
    (value: string) => {
      setNameValid(isFieldNotEmpty(value))
      onNameChange(value)
    },
    [onNameChange, setNameValid]
  )
  const weekdays = useMemo(() => enabledWeekdays(menu.dateRangeFrom, menu.dateRangeTo), [menu.dateRangeFrom, menu.dateRangeTo])

  return (
    <DirtyStateUnloader isDirty={isDirty} modalQuestionContext="this menu">
      <MenuFormContainer>
        <Tabs onSelect={(index: number) => setActiveTab(tabs[index]?.id as PRODUCT_TYPES_ALL)} selectedIndex={selectedTabIndex}>
          <TabList>
            {tabs.map(tab => (
              <Tab data-test={tab.id} key={tab.id}>
                {tab.text}
              </Tab>
            ))}
          </TabList>
          <TabPanel data-test="menu-management-general-details-container">
            <TabContent>
              <SectionDropdown headerText="MENU SETTINGS" subHeaderText={lastUpdatedTitle}>
                <FormField>
                  <ActiveLabelTextInput
                    isValid={nameValid}
                    disabled={!venue.isSevenRoomsOrderingMiddleware}
                    placeholderLabel="Menu Name*"
                    value={menu?.name}
                    onChange={onNameFieldChanged}
                    dataTest="menu-name-input"
                  />
                </FormField>
                <FormField>
                  <PrepTimeGroupPanel>
                    <GroupPanelRow title="Order Prep Time">
                      <GroupPanelRowCell>
                        <TinyWidthInput
                          charLimit={10}
                          value={menu?.prepTimeMins}
                          onChange={onPrepTimeChange}
                          dataTest="prep-time-mins-input"
                          inputType={TextInput.inputTypes.POSITIVE_INT}
                        />
                      </GroupPanelRowCell>
                      <GroupPanelRowCell>min</GroupPanelRowCell>
                    </GroupPanelRow>
                  </PrepTimeGroupPanel>
                </FormField>
              </SectionDropdown>
            </TabContent>
          </TabPanel>
          {venue.isSevenRoomsOrderingMiddleware && (
            <TabPanel data-test="menu-management-menu-builder-container" forceRender>
              <MenuBuilderTabContent>
                <MenuBuilder
                  isVisible={activeTab === TABS.MENU_BUILDER}
                  {...{
                    menu,
                    menuItems,
                    venue,
                    selectedMenuCategory,
                    isCategoryModalOpen,
                    isConfirmDeleteModalOpen,
                    onConfirmDeleteModalClose,
                    onCategoryModalOpen,
                    onCategoryModalClose,
                    onCategoryOrderChanged,
                    onCategoryUpdate,
                    onCategoryCreate,
                    onConfirmDeleteModalOpen,
                    onCategoryDelete,
                    onCategoryMenuItemDelete,
                    onCategoryMenuItemsUpdate,
                    isAddMenuItemModalOpen,
                    onAddMenuItemModalOpen,
                    onAddMenuItemModalClose,
                  }}
                />
              </MenuBuilderTabContent>
            </TabPanel>
          )}
          <TabPanel data-test="menu-management-available-times-container">
            <TabContent>
              <SectionDropdown headerText="Order Fulfillment Dates and Times">
                {venue.isSevenRoomsOrderingMiddleware && (
                  <>
                    <Box mb="sm">
                      <Text>
                        Select the date range that your menu is available. If you need to exclude certain dates, you can do so in "Excluded
                        Dates" tab.
                      </Text>
                    </Box>
                    <Box pt="m" pb="m">
                      <Label primary="Date Range*">
                        <DateRangePicker
                          startDate={menu.dateRangeFrom?.toDate()}
                          endDate={menu.dateRangeTo?.toDate() || 'infinite'}
                          id="pacing-rule-date-range"
                          infinite
                          onStartDateChange={(startDate: Date | null) => startDate && onUpdateDateRangeFrom(startDate)}
                          onEndDateChange={(endDate: Date | 'infinite' | null) =>
                            onUpdateDateRangeTo(endDate === 'infinite' ? null : endDate)
                          }
                        />
                      </Label>
                    </Box>
                  </>
                )}
                <AvailableTimes
                  {...{
                    menu,
                    venue,
                    onAddMenuAvailability,
                    onRemoveMenuAvailability,
                    onCopyMenuAvailabilityToAll,
                    onMenuAvailabilityUpdate,
                    weekdays,
                  }}
                />
              </SectionDropdown>
            </TabContent>
          </TabPanel>
          <TabPanel data-test="menu-management-excluded-dates-container">
            <TabContent>
              <SectionDropdown headerText="EXCLUDED DATES & DATE RANGES">
                <Box mb="sm">
                  <Text>
                    If there are any specific dates or date ranges that this menu will not be available you can specify them here.
                  </Text>
                </Box>
                <ExcludedDateRanges
                  {...{
                    menu,
                    onAddMenuExcludedDateRange,
                    onUpdateMenuExcludedDateRange,
                    onRemoveExcludedDateRange,
                  }}
                />
              </SectionDropdown>
            </TabContent>
          </TabPanel>
        </Tabs>
        <EditingLayoutFooter data-test="create-menu-buttons-bar">
          <Button variant="primary" disabled={isSaveDisabled} onClick={onSaveForm} data-test="save-button">
            Save
          </Button>
          <Button variant="tertiary" onClick={onCancel} data-test="cancel-button">
            Cancel
          </Button>
        </EditingLayoutFooter>
      </MenuFormContainer>
    </DirtyStateUnloader>
  )
}

const TabContent = styled(EditingLayout)`
  padding: ${props => props.theme.gutter.triple} 0 0 0;
`

const MenuBuilderTabContent = styled(TabContent)`
  min-width: 968px;
`

const MenuFormContainer = styled.div`
  padding: ${props => props.theme.gutter.triple};
`

const PrepTimeGroupPanel = styled(GroupPanel)`
  padding: ${props => props.theme.padding.medium} ${props => props.theme.padding.large};
`

export const enabledWeekdays = (dateRangeFrom: moment.Moment, dateRangeTo: moment.Moment | null): [number, string][] => {
  if (!dateRangeFrom || !dateRangeTo) {
    return allWeekdays()
  }
  const numDays = dateRangeTo.diff(dateRangeFrom, 'days')
  if (numDays < 0) {
    return []
  }
  if (numDays >= 7) {
    return allWeekdays()
  }
  const iterMoment = moment(dateParts(dateRangeFrom))
  const safeDateRangeTo = moment(dateParts(dateRangeTo))
  const results = []
  while (!iterMoment.isAfter(safeDateRangeTo)) {
    results.push([getPythonWeekday(iterMoment), iterMoment.format('dddd (L)')] as [number, string])
    iterMoment.add({ days: 1 })
  }
  return results
}

const allWeekdays = (): [number, string][] => {
  const weekdays = moment.weekdays()
  weekdays.push(weekdays.shift() as string)
  return weekdays.map((weekdayName, weekday) => [weekday, weekdayName])
}
