import { useLocales } from '@sevenrooms/core/locales'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import { settingsMessages } from '../settings.locales'
import { LanguageSettings } from './LanguageSettings'

interface PolicyLanguageSettingsRoutes {
  BOOKING: string
  PAYMENT: string
  OTHER: string
}

interface PolicyLanguageSettingsProps {
  venue: Venue
  venues: UserDomainVenue[]
  routes: PolicyLanguageSettingsRoutes
  onChangeVenue: (venue: UserDomainVenue) => void
}

export function PolicyLanguageSettings({ venue, venues, routes, onChangeVenue }: PolicyLanguageSettingsProps) {
  const { formatMessage } = useLocales()
  return (
    <LanguageSettings title={formatMessage(settingsMessages.policies)} venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
      <LanguageSettings.Block label="">
        <LanguageSettings.Row label={formatMessage(settingsMessages.policiesBooking)} id="booking-policy-links" editLink={routes.BOOKING} />
        <LanguageSettings.Row label={formatMessage(settingsMessages.policiesPayment)} id="cancel-policy-links" editLink={routes.PAYMENT} />
        <LanguageSettings.Row label={formatMessage(settingsMessages.policiesOther)} id="other-policy-links" editLink={routes.OTHER} />
      </LanguageSettings.Block>
    </LanguageSettings>
  )
}
