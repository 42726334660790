import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../../locales'
import { formatBold } from '../../../../utils'

export function ResSummaryEmailEnabledSuggestedActions() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack>
      <VStack p="s s s xxs" spacing="s" data-test="insights-res-summary-email-enabled-suggested-actions">
        <Text data-test="insights-res-summary-email-enabled-suggested-actions-navigate">
          1.{' '}
          {formatMessage(insightsMessages.summaryEmailEnabledNavigateStep, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            link: (chunks: string[]) => (
              <Link
                to={nav.href(routes.manager2.settings.venue, {
                  params: { venueKey: venue.urlKey },
                  query: { activeTab: 'internalTeamEmails' },
                })}
                target="_blank"
                data-test="insights-res-summary-email-enabled-suggested-actions-venue-settings-link"
              >
                {chunks}
              </Link>
            ),
          })}
        </Text>
        <Text data-test="insights-res-summary-email-enabled-suggested-actions-toggle">
          2.{' '}
          {formatMessage(insightsMessages.resSummaryEmailEnabledToggleStep, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        <Text data-test="insights-res-summary-email-enabled-suggested-actions-select-shifts">
          3. {formatMessage(insightsMessages.resSummaryEmailEnabledSelectShiftsStep)}
        </Text>
        <Text data-test="insights-res-summary-email-enabled-suggested-actions-select-users">
          4.{' '}
          {formatMessage(insightsMessages.resSummaryEmailEnabledSelectUsersStep, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            link: (chunks: string[]) => (
              <Link
                to={nav.href(routes.manager.userAccounts, { params: { venueKey: venue.urlKey } })}
                target="_blank"
                data-test="insights-res-summary-email-enabled-suggested-actions-user-accounts-link"
              >
                {chunks}
              </Link>
            ),
          })}
        </Text>
      </VStack>
      <Box mb="s">
        <Text data-test="insights-res-summary-email-enabled-suggested-actions-note">
          {formatMessage(insightsMessages.summaryEmailEnabledSuggestedStepsNote, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
      </Box>
    </VStack>
  )
}
