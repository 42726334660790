/* eslint no-param-reassign: "off" */
import React from 'react'
import TextInput, { ValidatorTypes } from 'mgr/lib/forms/TextInput'

export const apiPublicKeyField = ({ yelloIntegrationSettings, changeYelloField, formErrors, validateFieldMap }) => (
  <TextInput
    label="API PUBLIC KEY*"
    charLimit={100}
    validator={ValidatorTypes.notEmpty}
    ref={e => {
      validateFieldMap.public_key = e
    }}
    isValid={!formErrors.public_key}
    value={yelloIntegrationSettings.settings.public_key}
    onChange={v => changeYelloField('public_key', v)}
  />
)

export const apiSecretKeyField = ({ yelloIntegrationSettings, changeYelloField, formErrors, validateFieldMap }) => (
  <TextInput
    label="API SECRET KEY*"
    charLimit={100}
    validator={ValidatorTypes.notEmpty}
    ref={e => {
      validateFieldMap.api_key = e
    }}
    isValid={!formErrors.api_key}
    value={yelloIntegrationSettings.settings.api_key}
    onChange={v => changeYelloField('api_key', v)}
  />
)

export const storeReferenceField = ({ yelloIntegrationSettings, changeYelloField, formErrors, validateFieldMap }) => (
  <TextInput
    label="STORE REFERENCE*"
    charLimit={100}
    validator={ValidatorTypes.notEmpty}
    ref={e => {
      validateFieldMap.store_reference = e
    }}
    isValid={!formErrors.store_reference}
    value={yelloIntegrationSettings.settings.store_reference}
    onChange={v => changeYelloField('store_reference', v)}
  />
)

export const pickupInstructionsField = ({ yelloIntegrationSettings, changeYelloField }) => (
  <TextInput
    label="PICKUP INSTRUCTIONS"
    charLimit={300}
    value={yelloIntegrationSettings.settings.pickup_instructions}
    onChange={v => changeYelloField('pickup_instructions', v)}
  />
)
