import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { MenusColumn } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenusColumn'
import SettingsTable from 'mgr/pages/single-venue/settings/components/shared/SettingsTable'
import { MiddlewareSource } from 'mgr/pages/single-venue/settings/constants'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import { FormatService } from '@sevenrooms/core/locales'
import type { Venue } from '@sevenrooms/mgr-core'
import { EditCell } from './EditCell'
import ProductInventoryItemCell from './ProductItemCell'
import type { PRODUCT_TYPES } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { ProductInventoryItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'
import type { Column, SortColumn, TableCellProps } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

const Cell = styled.div`
  height: 36px;
  ${props => props.theme.ellipsis};
`

interface ProductInventoryTableProps {
  productList: ProductInventoryItem[]
  venue: Venue
  isLoading?: boolean
  sorted?: SortColumn[]
  onSortedChange?: (data: SortColumn[]) => void
  onDeleteProduct: (venueId: string, menuId: string, productType: PRODUCT_TYPES) => void
}

export function ProductInventoryTable({
  productList,
  sorted,
  isLoading,
  venue,
  onSortedChange,
  onDeleteProduct,
}: ProductInventoryTableProps) {
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<ProductInventoryItem>()
  const isNoPosMiddlewareSource = venue.orderingMiddlewareSource === MiddlewareSource.NO_POS
  const onDelete = useCallback(
    (product: ProductInventoryItem) => {
      setSelectedProduct(product)
      setModalVisible(true)
    },
    [setModalVisible]
  )
  const columns = useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'name',
        Cell: (props: TableCellProps) => <ProductInventoryItemCell product={props.original as ProductInventoryItem} />,
        minWidth: 292,
        overflow: 'hidden',
      },
      ...(isNoPosMiddlewareSource
        ? []
        : [
            {
              Header: 'Identifier/PLU',
              accessor: 'displayId',
              Cell: (props: TableCellProps) => (
                <Cell data-test={`product-inventory-item-${props.index}-id/plu`}>{props.value as string}</Cell>
              ),
            },
          ]),
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value, index }: { value?: number; index: number }) => (
          <Cell data-test={`product-inventory-item-${index}-price`}>
            {value !== undefined && FormatService.formatCurrency(value, venue.currencyCode)}
          </Cell>
        ),
        className: 'align-right',
        headerClassName: 'align-right',
      },
      {
        Header: 'Product Type',
        accessor: 'typeDisplayName',
        width: 140,
        Cell: (props: TableCellProps) => (
          <Cell data-test={`product-inventory-item-${props.index}-product-type`}>{props.value as string}</Cell>
        ),
      },
      {
        Header: 'Menu(s)',
        accessor: 'menuNames',
        Cell: ({ value, index }: TableCellProps) => (
          <MenusColumn data-test={`product-inventory-item-${index}-menus`} menuNames={value as string[]} />
        ),
      },
      {
        Header: '',
        accessor: '',
        sortable: false,
        Cell: (props: TableCellProps) => <EditCell product={props.original as ProductInventoryItem} venue={venue} onDelete={onDelete} />,
        className: 'align-right',
        headerClassName: 'align-right',
        width: 75,
      },
    ],
    [isNoPosMiddlewareSource, onDelete, venue]
  ) as Column[]

  return (
    <div data-test="sr-ordering-product-inventory-table">
      <SettingsTable data={productList} columns={columns} sorted={sorted} onSortedChange={onSortedChange} isLoading={isLoading} />
      {isModalVisible && (
        <MessageBox
          handleActionClick={() => {
            if (selectedProduct) {
              onDeleteProduct(venue.id, selectedProduct.id, selectedProduct.type)
            }
            setModalVisible(false)
          }}
          handleCloseClick={() => {
            setModalVisible(false)
          }}
          dialogType={MessageBox.DialogType.WARNING}
          header="Warning"
          explain="Warning"
          details={[]}
          explanation="Are you sure that you want to delete this product?"
          actionButtonText="Delete"
        />
      )}
    </div>
  )
}

ProductInventoryTable.defaultProps = {
  isLoading: false,
  sorted: undefined,
  onSortedChange: undefined,
}
