import React from 'react'
import { css } from 'styled-components'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import editableCell from 'svr/component-lib/Generic/Tables/Cells/editable'
import VenueSupportedLanguageDropDown from 'svr/component-lib/Manager/MultiLanguage/VenueSupportedLanguageDropDown'

const customTableContainerStyles = css`
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} ${props => props.theme.gutter.standard} 0;
  .ReactTable {
    border-radius: 5px;
    border-width: 2px;

    &.TableDropdown {
      overflow: visible;
    }

    .rt-tr-group {
      overflow: hidden;
    }

    .rt-tr {
      font-size: ${props => props.theme.fontSize.body};
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      &.-even {
        background-color: ${props => props.theme.color.white};
      }
    }

    .rt-thead {
      .rt-th {
        padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.triple};
        font-size: ${props => props.theme.fontSize.body};
        font-weight: 500;
        line-height: 17px;
        text-transform: none;
        :focus {
          outline: none;
        }
      }

      &.-header {
        background-color: ${props => props.theme.color.white};
        box-shadow: None;
        .rt-th {
          border-right: 1px solid rgba(0, 0, 0, 0.07);
          border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
    }

    .rt-tbody {
      overflow: auto;
      .rt-tr:not(.-padRow) {
        :hover {
          cursor: default;
          background: ${props => props.theme.color.white};
          .highlighted {
            background: ${props => props.theme.color.white};
          }
        }
      }

      .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.07);
        padding: 0;
      }
    }

    .description-head {
      background-color: ${props => props.theme.color.lightGrey};
      padding-left: ${props => props.theme.gutter.triple};
    }

    .rt-td.description-body {
      background-color: ${props => props.theme.color.lightGrey};
      font-size: ${props => props.theme.fontSize.caption};
      color: ${props => props.theme.color.secondary};
      padding-left: ${props => props.theme.gutter.triple};
    }
    .form-element.text {
      height: 100%;
      width: 100%;
    }
    .language-dropdown-header {
      overflow: visible;
      padding: 0 !important;
      border-top: 0;
      border-bottom: 0 !important;
      position: static !important;
      & > div {
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }
  }
`

const cellStyles = {
  position: 'relative',
  minHeight: '58px',
  height: '100%',
}

class LanguageTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
    }

    const { defaultLanguage, translatedLanguages, elementId, enabledLanguages, selectedLanguage, onChangeLanguage } = this.props
    this.showLanguageDropdown = enabledLanguages.length > 1
    const staticColumns = [
      {
        Header: 'Description',
        accessor: 'desc',
        className: 'description-body',
        sortable: false,
        headerClassName: 'description-head',
        getProps: () => ({
          style: { ...cellStyles },
        }),
      },
      {
        Header: defaultLanguage.name,
        accessor: defaultLanguage.value,
        sortable: false,
        Cell: cellInfo => editableCell(this.state.data, this.handleInputChange, elementId, 0, 'languageStringValue', cellInfo, '', true),
        getProps: () => ({
          style: { ...cellStyles },
        }),
      },
    ]
    const translatedLanguageColumns = translatedLanguages.map(language => ({
      Header: () => {
        if (this.showLanguageDropdown) {
          return (
            <VenueSupportedLanguageDropDown
              id="supported-languages-picker"
              languages={enabledLanguages}
              selectedLanguage={selectedLanguage}
              onChange={onChangeLanguage}
              style={{
                width: '100%',
                marginRight: '0',
                height: '100%',
              }}
              borderAreaStyle={{
                height: '100%',
                boxShadow: 'none',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                paddingLeft: '24px',
                paddingRight: '24px',
              }}
              selectedItemDisplayStyle={{
                marginTop: '0',
                marginLeft: '0',
                fontSize: '15px',
                fontWeight: '500',
                lineHeight: '17px',
                textTransform: 'none',
              }}
              optionBaseStyle={{
                paddingLeft: '24px',
                paddingRight: '24px',
              }}
              dropdownRelativeStyle={{
                position: 'absolute',
                width: '451px',
              }}
              isLightTheme
            />
          )
        }
        return language.name
      },
      accessor: language.value,
      sortable: false,
      headerClassName: this.showLanguageDropdown ? 'language-dropdown-header' : '',
      Cell: cellInfo => editableCell(this.state.data, this.handleInputChange, elementId, 1, 'languageStringValue', cellInfo, '', true),
      getProps: () => ({
        style: { ...cellStyles },
      }),
    }))

    this.columns = staticColumns.concat(translatedLanguageColumns)
  }

  handleInputChange = (cellInfo, event) => {
    const newValue = event.target.value

    const data = [...this.state.data]
    const cellObj = data[cellInfo.index][cellInfo.column.id]
    if (!cellObj) {
      return
    }
    cellObj.languageStringValue = newValue
    this.setState({ data })

    this.props.handleCellChange(cellObj.languageStringId, cellObj.masterStringId, newValue)
  }

  render() {
    const { data, elementId } = this.props
    return (
      <div id={`language-table${elementId}`}>
        <Table
          data={data}
          columns={this.columns}
          pageSize={data.length}
          showPagination={false}
          customTableContainerStyles={customTableContainerStyles}
          sortable={false}
          resizable={false}
          className={this.showLanguageDropdown ? 'TableDropdown' : ''}
        />
      </div>
    )
  }
}

LanguageTable.defaultProps = {
  handleCellChange: () => {},
}

export default LanguageTable
