import type { GoogleReserveAvailabilityTableRow } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, Report, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'

export interface GoogleReserveAvailabilityProofSectionProps {
  insightProofDataRecord: GoogleReserveAvailabilityTableRow
}

export function GoogleReserveAvailabilityProofSection({ insightProofDataRecord }: GoogleReserveAvailabilityProofSectionProps) {
  const { formatMessage } = useLocales()
  const displayAudienceCompatibility = !!insightProofDataRecord.audienceSuggestedStep
  const displayPublicDescription = !!insightProofDataRecord.publicDescriptionSuggestedStep

  const getBooleanYesNoValue = (val: boolean | null) => {
    if (val !== null) {
      return val ? formatMessage(commonMessages.yes) : formatMessage(commonMessages.no)
    }
    return null
  }

  return (
    <Report>
      {displayAudienceCompatibility && (
        <ReportPart
          caption={formatMessage(insightsMessages.googleReserveAvailabilityAudienceProofLabel)}
          data-test="google-reserve-availability-audience-proof"
        >
          <ReportLine name={formatMessage(insightsMessages.googleReserveAvailabilityTableHeaderCurrentAudiences)}>
            <Text>{insightProofDataRecord.audience}</Text>
          </ReportLine>
          <ReportLine name={formatMessage(insightsMessages.googleReserveAvailabilityTableHeaderGoogleIncluded)}>
            <Text>{getBooleanYesNoValue(insightProofDataRecord.googleIncluded)}</Text>
          </ReportLine>
          <ReportLine name={formatMessage(insightsMessages.googleReserveAvailabilityTableHeaderSuggestedStep)}>
            <Text>{insightProofDataRecord.audienceSuggestedStep}</Text>
          </ReportLine>
        </ReportPart>
      )}

      {displayPublicDescription && (
        <ReportPart
          caption={formatMessage(insightsMessages.googleReserveAvailabilityPublicDescriptionProofLabel)}
          data-test="google-reserve-availability-public-description-proof"
        >
          <ReportLine name={formatMessage(insightsMessages.googleReserveAvailabilityTableHeaderPublicDescription)}>
            <Text>{insightProofDataRecord.publicDescription}</Text>
          </ReportLine>
          <ReportLine name={formatMessage(insightsMessages.googleReserveAvailabilityTableHeaderAllowDisplay)}>
            <Text>{getBooleanYesNoValue(insightProofDataRecord.allowDisplayWithoutPublicDescription)}</Text>
          </ReportLine>
          <ReportLine name={formatMessage(insightsMessages.googleReserveAvailabilityTableHeaderSeatingArea)}>
            <Text>{insightProofDataRecord.seatingArea}</Text>
          </ReportLine>
          <ReportLine name={formatMessage(insightsMessages.googleReserveAvailabilityTableHeaderSuggestedStep)}>
            <Text>{insightProofDataRecord.publicDescriptionSuggestedStep}</Text>
          </ReportLine>
        </ReportPart>
      )}
    </Report>
  )
}
