import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import OrderingSitesTableMenu from 'mgr/pages/single-venue/settings/components/ordering/OrderingSites/OrderingSitesTableMenu'
import { StyledVmsIconS, VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'
import BorderlessTable from 'svr/component-lib/Generic/Tables/BorderlessTable'
import { StyledOverflowMenu } from 'svr/component-lib/Generic/Tables/Cells/styledOverflowMenu'
import { FULFILLMENT_METHOD_TITLE } from 'svr/constants'

const IsActiveCellContainer = styled.div`
  display: flex;
  padding-left: ${props => props.theme.gutter.double};
  width: 100%;
`

const IsActiveIconContainer = styled.div`
  color: ${props => (props.isActive === true ? props.theme.active : props.theme.inactive)};
  padding-right: ${props => props.theme.gutter.standard};
`

const Tooltip = styled.span`
  margin-left: ${props => props.theme.gutter.standard};
  cursor: pointer;
`

const CheckIcon = styled(StyledVmsIconS)`
  color: ${props => props.theme.primary};
  margin: auto;
`

const InfoIcon = styled(StyledVmsIconXS)`
  color: ${props => props.theme.darkGrey};
  vertical-align: top;
`

const isActiveCell = props => (
  <IsActiveCellContainer>
    <IsActiveIconContainer isActive={props.value}>
      <StyledVmsIconS>{VmsIcons.Oval}</StyledVmsIconS>
    </IsActiveIconContainer>
    <div>{props.value === true ? 'Active' : 'Not Active'}</div>
  </IsActiveCellContainer>
)

const is3rdPartyDefaultHeaderCell = () => (
  <div>
    <ReactTooltip multiline id="3rd-party-default-site-info" effect="solid" place="top" offset={{ top: -7 }} />
    <span>3rd Party Default</span>
    <Tooltip
      data-tip="Ordering site that will be used on TripAdvisor <br /> and other 3rd party partner sites"
      data-for="3rd-party-default-site-info"
    >
      <InfoIcon>{VmsIcons.Info}</InfoIcon>
    </Tooltip>
  </div>
)

const menuCell = (orderingSites, venueUrlKey, onDeleteClick, onSetThirdPartyDefault, thirdPartyDefaultOrderingSiteId, props) => (
  <StyledOverflowMenu>
    <OrderingSitesTableMenu
      siteId={orderingSites[props.index].id}
      rowIndex={props.index}
      venueUrlKey={venueUrlKey}
      siteUrlKey={orderingSites[props.index].url_key}
      onDeleteClick={onDeleteClick}
      onSetThirdPartyDefault={onSetThirdPartyDefault}
      thirdPartyDefaultOrderingSiteId={thirdPartyDefaultOrderingSiteId}
    />
  </StyledOverflowMenu>
)

const OrderingSitesTable = ({
  orderingSites,
  venueUrlKey,
  isLoading,
  onDeleteClick,
  onSetThirdPartyDefault,
  thirdPartyDefaultOrderingSiteId,
}) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'URL Code',
      accessor: 'url_key',
    },
    {
      Header: 'Menus',
      accessor: 'menu_names',
      Cell: props => <div>{[props.value.join(', ')]}</div>,
    },
    {
      Header: 'Fulfillment Method',
      accessor: 'fulfillment_method',
      Cell: props => <div>{FULFILLMENT_METHOD_TITLE[props.value]}</div>,
    },
    {
      Header: 'Active Ordering Site',
      accessor: 'is_enabled',
      Cell: isActiveCell,
    },
    {
      Header: is3rdPartyDefaultHeaderCell,
      accessor: 'id',
      Cell: props => props.value === thirdPartyDefaultOrderingSiteId && <CheckIcon>{VmsIcons.Check}</CheckIcon>,
    },
    {
      Header: '',
      accessor: '',
      width: 70,
      Cell: menuCell.bind(null, orderingSites, venueUrlKey, onDeleteClick, onSetThirdPartyDefault, thirdPartyDefaultOrderingSiteId),
    },
  ]
  return (
    <BorderlessTable
      id="ordering-sites-table"
      data-test="ordering-sites-table"
      columns={columns}
      data={orderingSites}
      isLoading={isLoading}
    />
  )
}

export default OrderingSitesTable
