import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { useStoreSelector, useUserContext, useVenueGroupContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { type LookerSideNavigationCategoryItem, BASE_THUMBNAIL_URL } from '@sevenrooms/mgr-core/hooks/useLookerNavigationItems'
import type { VenueGroup } from '@sevenrooms/mgr-core/typings/venueGroup'
import ThumbnailRed from '../images/thumbnail_red.svg'
import { navigationItemMessages } from '../locales/navigationItemMessages'
import { reportingMessages } from '../locales/reportingMessages'
import type { NavigationDashboardCategoryItem } from '../components/NavigationDashboard'

export function useStaticNavigationItems(): NavigationDashboardCategoryItem[] {
  const { formatMessage } = useLocales()

  const { venueGroup } = useVenueGroupContext()

  const user = useUserContext()

  if (!user) {
    return []
  }

  const nonReactPath = `/group/${venueGroup.id}/reporting`
  const adminItems = []

  adminItems.push({
    id: 'activity-log',
    reload: true,
    to: `${nonReactPath}/activity_log`,
    title: formatMessage(navigationItemMessages.activityLogTitle),
    description: formatMessage(navigationItemMessages.activityLogDescription),
    items: [],
    thumbnail: ThumbnailRed,
  })

  return [
    {
      id: 'administrative',
      title: formatMessage(reportingMessages.reportingCategoryAdministrative),
      items: adminItems,
    },
  ]
}

const MAX_RECENTLY_VIEWED = 3

export function useReportViewHistory() {
  const lookerContextRecentlyViewed = useStoreSelector(state => state?.looker?.reportViewHistory)
  const appContextRecentlyViewed = (useAppContext().userDomain?.reportViewHistory as string[]) ?? []

  return lookerContextRecentlyViewed
    .concat(appContextRecentlyViewed.filter(x => !lookerContextRecentlyViewed.includes(x)))
    .slice(0, MAX_RECENTLY_VIEWED)
}

function parseLookerNavigationItems(venueGroup: VenueGroup): LookerSideNavigationCategoryItem[] {
  return Object.entries(venueGroup.lookerFolders).map(([key, reports]) => ({
    id: key,
    title: key,
    items:
      reports?.map(report => ({
        id: `looker-report-${report.type}-${report.id}`,
        title: report.title,
        to: `/group/${venueGroup.id}/reporting/embed/${report.type}/${report.id}`,
        reload: true,
        description: report.description,
        keywords: report.tags ?? [],
        popularSortOrder: report.popularSortOrder,
        items: [],
        thumbnail: report.thumbnailSrc ?? `${BASE_THUMBNAIL_URL}${report.type}/${report.id}`,
      })) ?? [],
  }))
}

export function useGroupNavigationDashboardItems(): NavigationDashboardCategoryItem[] {
  const { formatMessage } = useLocales()
  const { venueGroup } = useVenueGroupContext()

  const lookerItems = parseLookerNavigationItems(venueGroup)
  const staticItems = useStaticNavigationItems()
  const recentlyViewed = useReportViewHistory()

  const allItems = lookerItems.concat(staticItems)

  const recentlyViewedItems = []
  const popularItems = []
  const remainingItems = []
  for (const item of allItems.flatMap(x => x.items)) {
    const recentlyViewedSortOrder = recentlyViewed.findIndex(x => item.to.endsWith(x))
    if (recentlyViewedSortOrder >= 0) {
      recentlyViewedItems.push({
        ...item,
        recentlyViewedSortOrder,
        badge: formatMessage(reportingMessages.badgeRecentlyViewed, {
          icon: <Icon name="VMSWeb-eye" size="sm" />,
        }),
      })
    } else if (item.popularSortOrder) {
      popularItems.push({
        ...item,
        popularSortOrder: item.popularSortOrder,
        badge: formatMessage(reportingMessages.badgePopular, {
          icon: <Icon name="VMSWeb-trending-up" size="sm" />,
        }),
      })
    } else {
      remainingItems.push(item)
    }
  }

  const topItems = [
    ...recentlyViewedItems.sort((a, b) => a.recentlyViewedSortOrder - b.recentlyViewedSortOrder),
    ...popularItems.sort((a, b) => a.popularSortOrder - b.popularSortOrder),
  ]

  return [
    {
      id: 'highlighted-reports',
      title: formatMessage(reportingMessages.headerHighlightedReports),
      hideTitle: true,
      items: topItems,
    },
    {
      id: 'all-reports',
      title: formatMessage(reportingMessages.headerAllReports),
      items: remainingItems,
    },
  ]
}
