/* eslint-disable camelcase */
import { adaptProductItemFromApi, adaptProductItemToApi } from 'mgr/lib/services/ProductServices/adapters/adaptProduct'
import type { EightySixProduct, EightySixProductApi } from 'mgr/pages/single-venue/settings/types/ordering/EightySixProduct.types'

export const adaptProduct86FromApi = (product: EightySixProductApi): EightySixProduct => ({
  ...adaptProductItemFromApi(product),
  isIndefinite: product.is_indefinite,
  isSnoozed: product.is_snoozed,
  snoozeStartTime: product.snooze_start_time,
  snoozeEndTime: product.snooze_end_time,
})

export const adaptProduct86ToApi = (product: EightySixProduct): EightySixProductApi => ({
  ...adaptProductItemToApi(product),
  is_indefinite: product.isIndefinite,
  is_snoozed: product.isSnoozed,
  snooze_start_time: product.snoozeStartTime,
  snooze_end_time: product.snoozeEndTime,
})
