/* eslint-disable no-console */
import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { fetchFollowers } from 'mgr/lib/services/FollowerServices'
import { fetchClientTagCategories, fetchReservationTagCategories, addTagCategory, editTagCategory } from 'mgr/lib/services/TagServices'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'

export const tryFetchClientTagCategories = venueGroup => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.FETCH_CLIENT_TAG_CATEGORIES_START,
  })

  return fetchClientTagCategories({ venue_group_id: venueGroup.id })
    .then(response =>
      dispatch({
        type: ActionTypes.FETCH_CLIENT_TAG_CATEGORIES_SUCCESS,
        tagCategories: response.data,
      })
    )
    .catch(e => {
      console.error(`an error occurred fetching client tag categories: ${e.toString()}`)
      dispatch({
        type: ActionTypes.FETCH_CLIENT_TAG_CATEGORIES_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e.toString().replace('Error: Error:', 'Error:')} Fetching client tag categories failed, please try again or contact support`
        )
      )

      return e
    })
}

export const tryFetchReservationTagCategories = venueGroup => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.FETCH_RESERVATION_TAG_CATEGORIES_START,
  })

  return fetchReservationTagCategories({ venue_group_id: venueGroup.id })
    .then(response =>
      dispatch({
        type: ActionTypes.FETCH_RESERVATION_TAG_CATEGORIES_SUCCESS,
        tagCategories: response.data,
      })
    )
    .catch(e => {
      console.error(`an error occurred fetching reservation tag categories: ${e.toString()}`)
      dispatch({
        type: ActionTypes.FETCH_RESERVATION_TAG_CATEGORIES_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e
            .toString()
            .replace('Error: Error:', 'Error:')} Fetching reservation tag categories failed, please try again or contact support`
        )
      )

      return e
    })
}

export const tryEnableTagCategory = tagCategoryId => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.ENABLE_TAG_CATEGORY_START,
  })

  return editTagCategory({
    tag_category_id: tagCategoryId,
    venue_group_id: getState().appState.venueGroup.id,
    disabled: false,
  })
    .then(response =>
      dispatch({
        type: ActionTypes.ENABLE_TAG_CATEGORY_SUCCESS,
        tagCategoryId,
      })
    )
    .catch(e => {
      console.error(`an error occurred enabling tag category: ${e.toString()}`)
      dispatch({
        type: ActionTypes.ENABLE_TAG_CATEGORY_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e.toString().replace('Error: Error:', 'Error:')} Enabling tag category failed, please try again or contact support`
        )
      )

      return e
    })
}

export const tryDisableTagCategory = () => (dispatch, getState) => {
  const enableTagCategoryId = getState().tags.selectedTagCategoryId
  dispatch({
    type: ActionTypes.DISABLE_TAG_CATEGORY_START,
  })

  return editTagCategory({
    tag_category_id: enableTagCategoryId,
    venue_group_id: getState().appState.venueGroup.id,
    disabled: true,
  })
    .then(response =>
      dispatch({
        type: ActionTypes.DISABLE_TAG_CATEGORY_SUCCESS,
        tagCategoryId: enableTagCategoryId,
      })
    )
    .catch(e => {
      console.error(`an error occurred disabling tag category: ${e.toString()}`)
      dispatch({
        type: ActionTypes.DISABLE_TAG_CATEGORY_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e.toString().replace('Error: Error:', 'Error:')} Disabling tag category failed, please try again or contact support`
        )
      )

      return e
    })
}

export const tryDeleteTagCategory = () => (dispatch, getState) => {
  const deleteTagCategoryId = getState().tags.selectedTagCategoryId
  dispatch({
    type: ActionTypes.DELETE_TAG_CATEGORY_START,
  })

  return editTagCategory({
    tag_category_id: deleteTagCategoryId,
    venue_group_id: getState().appState.venueGroup.id,
    deleted: true,
  })
    .then(response =>
      dispatch({
        type: ActionTypes.DELETE_TAG_CATEGORY_SUCCESS,
        tagCategoryId: deleteTagCategoryId,
      })
    )
    .catch(e => {
      console.error(`an error occurred deleting tag category: ${e.toString()}`)
      dispatch({
        type: ActionTypes.DELETE_TAG_CATEGORY_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e.toString().replace('Error: Error:', 'Error:')} Deleting tag category failed, please try again or contact support`
        )
      )

      return e
    })
}

export const tryFetchFollowers = venueGroupId => (dispatch, getState) => {
  const { tags } = getState()
  if (tags.followers.length !== 0) {
    return
  }
  dispatch({
    type: ActionTypes.FETCH_FOLLOWERS_START,
  })

  // eslint-disable-next-line consistent-return
  return fetchFollowers(venueGroupId)
    .then(response =>
      dispatch({
        type: ActionTypes.FETCH_FOLLOWERS_SUCCESS,
        followers: response.data.followers,
      })
    )
    .catch(e => {
      console.error(`an error occurred fetching followers: ${e.toString()}`)
      dispatch({
        type: ActionTypes.FETCH_FOLLOWERS_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e.toString().replace('Error: Error:', 'Error:')} Fetching followers failed, please try again or contact support`
        )
      )

      return e
    })
}

const getTagCategoryFormParams = (appState, tags, navState) => {
  const venueGroupId = appState.venueGroup.id
  const tagMap = {}
  tags.selectedTagCategoryTags.forEach(t => {
    if (!t.isDeleted) {
      tagMap[t.name] = t.updatedName
    }
  })

  return {
    venue_group_id: venueGroupId,
    tag_category_id: tags.selectedTagCategoryId,
    domain: navState.subNavSection === 'CLIENT_TAGS' ? 'VenueGroupClient' : 'ReservationActual',
    name: tags.selectedTagCategoryName || tags.selectedTagCategoryNameDisplay,
    name_display: tags.selectedTagCategoryNameDisplay,
    hex_color: tags.selectedTagCategoryHexColor,
    privacy: tags.selectedTagCategorySuperuser ? 'PRIVATE' : 'PUBLIC',
    is_vip: tags.selectedTagCategoryVip,
    show_on_chit: tags.selectedTagCategoryShowChit,
    is_restricted: tags.selectedTagCategoryRestricted,
    show_reservation: tags.selectedTagCategoryResSummary,
    show_custom_tags_on_widget: tags.selectedTagCategoryShowCustom,
    is_pod_tag: tags.selectedTagCategoryClassification === 'POD',
    pod_ids: _.map(tags.selectedTagCategoryPods, t => t.value),
    tags: JSON.stringify(tagMap),
    followers: _.map(tags.selectedTagCategoryFollowers, f => f.value),
  }
}

export const tryAddTagCategory = callbackFunc => (dispatch, getState) => {
  const { appState, tags, navState } = getState()
  dispatch({
    type: ActionTypes.ADD_TAG_CATEGORY_START,
  })
  const params = getTagCategoryFormParams(appState, tags, navState)

  return addTagCategory(params)
    .then(response => {
      dispatch({
        type: ActionTypes.ADD_TAG_CATEGORY_SUCCESS,
        tagCategory: response.data.tag_group,
      })
      callbackFunc()
      dispatch(GlobalActions.showSuccessMessage(`You have successfully created Tag Category: ${params.name_display || params.name}`))
    })
    .catch(e => {
      console.error(`an error occurred adding new tag category: ${e.toString()}`)
      dispatch({
        type: ActionTypes.ADD_TAG_CATEGORY_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e.toString().replace('Error: Error:', 'Error:')} Failed to add new tag category, please try again or contact support`
        )
      )
      callbackFunc()
      return e
    })
}

export const tryEditTagCategory = callbackFunc => (dispatch, getState) => {
  const { appState, tags, navState } = getState()
  dispatch({
    type: ActionTypes.EDIT_TAG_CATEGORY_START,
  })
  const params = getTagCategoryFormParams(appState, tags, navState)

  return editTagCategory(params)
    .then(response => {
      dispatch({
        type: ActionTypes.EDIT_TAG_CATEGORY_SUCCESS,
        tagCategory: response.data.tag_group,
      })
      callbackFunc()
      dispatch(GlobalActions.showSuccessMessage(`You have successfully edited Tag Category: ${params.name_display || params.name}`))
    })
    .catch(e => {
      console.error(`an error occurred editing tag category: ${e.toString()}`)
      dispatch({
        type: ActionTypes.EDIT_TAG_CATEGORY_FAIL,
      })
      dispatch(
        GlobalActions.showErrorMessage(
          `${e.toString().replace('Error: Error:', 'Error:')} Failed to edit tag category, please try again or contact support`
        )
      )
      callbackFunc()
      return e
    })
}

export const toggleDeleteWarning = tagCategoryId => dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_DELETE_WARNING,
    tagCategoryId,
  })
}

export const toggleDisableWarning = tagCategoryId => dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_DISABLE_WARNING,
    tagCategoryId,
  })
}

export const openAddTagSlideout = (isNewTagCategory, tagCategoryClassification, tagCategoryName, tagCategoryHexColor) => dispatch => {
  dispatch({
    type: ActionTypes.OPEN_TAG_SLIDEOUT,
    isNewTagCategory,
    selectedTagCategoryClassification: tagCategoryClassification,
    selectedTagCategoryName: tagCategoryName,
    selectedTagCategoryHexColor: tagCategoryHexColor,
  })
}

export const openEditTagSlideout = tagCategoryId => (dispatch, getState) => {
  const { tags } = getState()
  const categoryIdx = _.findIndex(tags.tagCategories, { id: tagCategoryId })
  const tagCategory = tags.tagCategories[categoryIdx]
  const tagCategoryFollowers = _.map(tagCategory.followers, f => ({
    isSelected: true,
    name: f.full_name,
    value: f.id,
    hexColor: '#0A223A',
  }))
  dispatch({
    type: ActionTypes.OPEN_TAG_SLIDEOUT,
    isNewTagCategory: false,
    selectedTagCategoryId: tagCategoryId,
    selectedTagCategoryName: tagCategory.name,
    selectedTagCategoryNameDisplay: tagCategory.name_display,
    selectedTagCategoryIsDefault: tagCategory.is_default,
    selectedTagCategoryIsAutotag: tagCategory.is_autotag,
    selectedTagCategoryDefaultOptionalTags: tagCategory.default_optional_tags,
    selectedTagCategoryDisableNewTags: tagCategory.disable_new_tags,
    selectedTagCategoryHexColor: tagCategory.color_hex,
    selectedTagCategoryClassification: tagCategory.is_pod_tag ? 'POD' : 'GLOBAL',
    selectedTagCategoryVip: tagCategory.vip,
    selectedTagCategorySuperuser: tagCategory.privacy === 'PRIVATE' ? 1 : 0,
    selectedTagCategoryShowChit: tagCategory.show_chit,
    selectedTagCategoryResSummary: tagCategory.show_reservation,
    selectedTagCategoryShowCustom: tagCategory.show_custom_tags_on_widget,
    selectedTagCategoryFollowers: tagCategoryFollowers,
    selectedTagCategoryTags: tagCategory.tags,
    selectedTagCategoryTagNameDisplays: tagCategory.tag_name_displays,
    selectedTagCategoryPods: _.map(tagCategory.pods, pod => ({
      name: pod.name,
      value: pod.id,
      isSelected: true,
      classifier: pod.pod_category_id,
      hexColor: pod.hex_color,
    })),
  })
}

export const changeTagCategoryName = name => dispatch => {
  dispatch({
    type: ActionTypes.CHANGE_TAG_CATEGORY_NAME,
    name,
  })
}

export const toggleCheckbox = field => dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_CHECKBOX,
    field,
  })
}

export const setRadioButtonVal = (value, field) => dispatch => {
  dispatch({
    type: ActionTypes.SET_RADIO_BTN_VAL,
    field,
    value,
  })
}

export const updateTagCategoryPods = (selectedPods, podCategoryTagsByCategory) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_TAG_CATEGORY_PODS,
    selectedPods,
    podCategoryTagsByCategory,
  })
}

export const updateTagCategoryFollowers = (selectedFollowers, allFollowers) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_TAG_CATEGORY_FOLLOWERS,
    selectedFollowers,
    allFollowers,
  })
}

export const appendNewTag = (valid, text) => dispatch => {
  if (valid) {
    dispatch({
      type: ActionTypes.APPEND_NEW_TAG,
      text,
    })
  }
}

export const changeTagName = (val, idx, isActive, callbackFunc) => dispatch => {
  dispatch({
    type: ActionTypes.CHANGE_TAG_NAME,
    tagName: val,
    idx,
    isActive,
    callbackFunc,
  })
}

export const deleteTag = (idx, callbackFunc) => dispatch => {
  dispatch({
    type: ActionTypes.DELETE_TAG,
    idx,
    callbackFunc,
  })
}

export const updateTagSlideoutErrors = formErrors => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_TAG_SLIDEOUT_ERRORS,
    formErrors,
  })
}
