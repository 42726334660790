import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { updateNavState } from 'mgr/lib/actions/NavActions'
import { VenueGroupNavSections } from 'mgr/pages/shared/utils/Constants'
import {
  tryLoadPodConfigs,
  openAddPodSlideout,
  onEditPodCategoryClick,
  addPodToVenue,
  removePodFromVenue,
} from 'mgr/pages/venue-group/actions/Pods'
import PodPickerCell from 'mgr/pages/venue-group/components/PodPickerCell'
import ShowPod from 'mgr/pages/venue-group/containers/pods/ShowPod'
import { VmsIcons } from 'svr/common/VmsIcons'
import ActionableThCell from 'svr/component-lib/Generic/Tables/ActionableThCell'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import { textCell } from 'svr/component-lib/Generic/Tables/Cells'
import ActionButton from 'svr/component-lib/Manager/Buttons/ActionButton'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'

const PodsConfigContainer = styled.div`
  padding: 20px 0 0 20px;
`

const PodsTableContainer = styled.div`
  max-width: 92vw;
  height: 70vh;
  display: flex;
  align-items: center;
`

const NoPodsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

const EmptyCellDiv = styled.div`
  padding: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`

const NoPodsContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`

const NoPodsMessage = styled.div`
  color: #b9bcc1;
  font-style: italic;
`

const TotalVenueFooter = styled.div`
  margin: 0 0 0 8px;
  padding: 16px 0;
  max-width: 92vw;
  background-color: #f7f7f7;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const TotalVenueFooterText = styled.div`
  padding-left: 16px;
`

const customTableContainerStyles = css`
  padding: 0 0 0 8px;
  max-height: 100%;

  .ReactTable {
    max-width: 92vw;
  }

  .rt-td,
  .rt-th {
    border-right: 1px solid #e6e8eb !important;
    background-color: ${props => props.theme.color.white};
    outline: none;
  }

  .rt-tr {
    background-color: ${props => props.theme.color.white} !important;
  }

  .rt-thead {
    position: sticky;
    top: 0;
    z-index: 5 !important;
    box-shadow: none !important;
    border-bottom: 1px solid #e6e8eb;
  }

  .rt-pod-picker-cell {
    padding: 0 !important;
  }
`

export const podPickerCell = (cellInfo /* :Object */) => {
  const venue = _.find(cellInfo.column.podsConfigData, it => it.venue_id === cellInfo.original.venue_id)
  return (
    <PodPickerCell
      podAccessor={cellInfo.column.podAccessor}
      podCategory={cellInfo.column.podCategory}
      podsConfigData={cellInfo.column.podsConfigData}
      pods={venue[cellInfo.column.podAccessor]}
      venue={venue}
      onAddPod={cellInfo.column.onAddPod}
      onRemovePod={cellInfo.column.onRemovePod}
    />
  )
}

class PodsLayout extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    return propsHasChanged(props, state) ? mapPropsToState(props) : state
  }

  constructor(props) {
    super(props)
    this.state = mapPropsToState(this.props)
  }

  componentDidMount = () => {
    const { tryLoadPodConfigs, updateNavState, venueGroup } = this.props
    updateNavState(VenueGroupNavSections.PODS, null)
    tryLoadPodConfigs(venueGroup)
  }

  handleAddPodCategory = () => {
    this.props.openAddPodSlideout(true, '', '#e21d3e', [])
  }

  addPodCategoryButton = () => (
    <ActionButton
      onClickHandler={this.handleAddPodCategory}
      className="add-pod-category"
      actionText="Add New Pod Category"
      actionIcon={VmsIcons.Plus}
      backgroundColor="#347baf"
    />
  )

  render() {
    const { venueGroup, userDomain, podsConfigData, totalVenues } = this.props
    const { columns } = this.state
    const hasPodCategories = columns.length > 0
    const showNoPodsContainer = !hasPodCategories
    return (
      <ContentLayout title="Pods Management" venueGroup={venueGroup} userDomain={userDomain} Actions={this.addPodCategoryButton()}>
        <ShowPod />
        <PodsConfigContainer>
          <PodsTableContainer>
            <Table
              data={podsConfigData}
              customTableContainerStyles={customTableContainerStyles}
              useFullWidth={hasPodCategories}
              loading={false}
              manual
              enableTooltips
              showPagination={false}
              pageSize={20}
              loadingText="Loading pods configurations..."
              columns={columns}
              customThComponent={ActionableThCell}
              thClick={this.props.onEditPodCategoryClick}
            />
            {showNoPodsContainer && (
              <NoPodsContainer>
                <EmptyCellDiv>&nbsp;</EmptyCellDiv>
                <NoPodsContent>
                  <NoPodsMessage>No Pod Categories have been created yet</NoPodsMessage>
                </NoPodsContent>
              </NoPodsContainer>
            )}
          </PodsTableContainer>
          <TotalVenueFooter>
            <TotalVenueFooterText>Total {totalVenues} Venues</TotalVenueFooterText>
          </TotalVenueFooter>
        </PodsConfigContainer>
      </ContentLayout>
    )
  }
}

const propsHasChanged = (props, prevProps) =>
  props.podAccessors !== prevProps.podAccessors ||
  props.podsConfigData !== prevProps.podsConfigData ||
  props.podCategoriesByAccessor !== prevProps.podCategoriesByAccessor ||
  props.addPodToVenue !== prevProps.addPodToVenue ||
  props.removePodFromVenue !== prevProps.removePodFromVenue

const mapPropsToState = props => {
  const { podAccessors, podsConfigData, podCategoriesByAccessor, addPodToVenue, removePodFromVenue } = props
  const columns = _.map(podAccessors, podAccessor => ({
    Header: podAccessor.toUpperCase().replace(/_/g, ' '),
    Cell: podPickerCell,
    accessor: podAccessor,
    podAccessor,
    podsConfigData,
    podCategory: podCategoriesByAccessor[podAccessor],
    onAddPod: addPodToVenue,
    onRemovePod: removePodFromVenue,
    className: 'rt-pod-picker-cell',
    width: 200,
  }))
  if (columns.length > 0) {
    columns.push({
      Header: '',
      Cell: textCell,
      headerClassName: 'rt-disable',
    })
  }
  columns.unshift({
    Header: 'Venue',
    accessor: 'venue',
    Cell: textCell,
    className: 'rt-fixed rt-fixed-left',
    headerClassName: 'rt-fixed rt-fixed-left rt-fixed-top rt-disable',
    width: 200,
  })
  return { columns, podAccessors, podsConfigData, podCategoriesByAccessor, addPodToVenue, removePodFromVenue }
}

const mapStateToProps = state => ({
  venueGroup: state.appState.venueGroup,
  userDomain: state.appState.userDomain,
  podsConfigData: state.pods.podsConfigData,
  podAccessors: state.pods.podAccessors,
  totalVenues: state.pods.totalVenues,
  podCategoriesByAccessor: state.pods.podCategoriesByAccessor,
})

const mapDispatchToProps = {
  updateNavState,
  tryLoadPodConfigs,
  openAddPodSlideout,
  onEditPodCategoryClick,
  addPodToVenue,
  removePodFromVenue,
}

export default connect(mapStateToProps, mapDispatchToProps)(PodsLayout)
