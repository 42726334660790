import { useLocales } from '@sevenrooms/core/locales'
import { orderingNotificationSettingsMessages } from '../../locales/orderingNotificationSettingsMessages'
import { BaseNotificationToggleTable, type NotificationToggleTableProps } from './BaseNotificationToggleTable'
import { NotificationToggleRow } from './NotificationToggleRow'

const METHOD = 'PICKUP'

export function PickupNotificationToggleTable({ getNotificationSetting, toggleNotificationSetting }: NotificationToggleTableProps) {
  const { formatMessage } = useLocales()
  return (
    <BaseNotificationToggleTable
      getNotificationSetting={getNotificationSetting}
      toggleNotificationSetting={toggleNotificationSetting}
      method={METHOD}
    >
      <NotificationToggleRow
        notificationType={formatMessage(orderingNotificationSettingsMessages.readyForPickupTitle)}
        notificationTypeDescription={formatMessage(orderingNotificationSettingsMessages.readyForPickupDescription)}
        emailEnabled={getNotificationSetting(METHOD, 'READY_PICKUP', 'EMAIL')}
        smsEnabled={getNotificationSetting(METHOD, 'READY_PICKUP', 'SMS')}
        emailOnChange={() => toggleNotificationSetting(METHOD, 'READY_PICKUP', 'EMAIL')}
        smsOnChange={() => toggleNotificationSetting(METHOD, 'READY_PICKUP', 'SMS')}
        data-test="ordering-notification-pickup-ready-for-pickup-row"
      />
    </BaseNotificationToggleTable>
  )
}
