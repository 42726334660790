import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Text, SecondaryText, Tag } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../locales'

export function FeatureEnablementProofSection({ title, lastCheckedDate }: { title: string; lastCheckedDate?: string | null }) {
  const { formatMessage } = useLocales()
  return (
    <HStack
      data-test="feature-enablement-proof-section"
      p="lm"
      width="100%"
      backgroundColor="primaryBackground"
      justifyContent="space-between"
    >
      <VStack>
        <Text textStyle="h4" data-test="feature-enablement-proof-section-title">
          {title}
        </Text>
        {lastCheckedDate && (
          <SecondaryText data-test="feature-enablement-proof-section-date">
            {formatMessage(insightsMessages.featureEnablementProofDateLastChecked, {
              date: DateTime.from(lastCheckedDate).toDateOnly().toIso(),
            })}
          </SecondaryText>
        )}
      </VStack>
      <Tag backgroundColor={theme.colors.errorBackground}>
        <Text color="error">{formatMessage(commonMessages.inactive)}</Text>
      </Tag>
    </HStack>
  )
}
