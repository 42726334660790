import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { Select, type SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface PacingTimeRangeProps {
  timeRangeFrom: moment.Moment | undefined
  timeRangeTo: moment.Moment | undefined
  timeIntervalMins: number
  menuStart: moment.Moment | undefined
  menuEnd: moment.Moment | undefined
  updateTimeRangeFrom: (value: moment.Moment) => void
  updateTimeRangeTo: (value: moment.Moment) => void
  updateTimeIntervalMins: (value: number) => void
}

export function PacingTimeRange({
  timeRangeFrom,
  timeRangeTo,
  timeIntervalMins,
  menuStart,
  menuEnd,
  updateTimeRangeFrom,
  updateTimeRangeTo,
  updateTimeIntervalMins,
}: PacingTimeRangeProps) {
  const timeSlots = useMemo(() => {
    if (!menuStart || !menuEnd || !menuStart.isValid() || !menuEnd.isValid()) {
      return []
    }
    const results = []
    let iterTime = menuStart.clone()
    while (!iterTime.isAfter(menuEnd)) {
      results.push(iterTime)
      iterTime = iterTime.clone().add({ minutes: 15 })
    }
    return results
  }, [menuStart, menuEnd])
  const availableOptions = timeSlots.map(timeSlot => transformTimeOption(timeSlot))
  const selectedStartOption = useMemo(
    () => (timeRangeFrom ? findTimeOptionInArray(timeRangeFrom, availableOptions) : availableOptions[0]),
    [timeRangeFrom, availableOptions]
  )
  const selectedEndOption = useMemo(
    () => (timeRangeTo ? findTimeOptionInArray(timeRangeTo, availableOptions) : availableOptions[availableOptions.length - 1]),
    [timeRangeTo, availableOptions]
  )
  const availableEndOptions = useMemo(() => {
    if (selectedStartOption) {
      const selectedStartOptionIndex = _.findIndex(availableOptions, selectedStartOption)
      return availableOptions.filter((_option, index) => index > selectedStartOptionIndex)
    }
    return availableOptions
  }, [availableOptions, selectedStartOption])
  const onStartTimeSelect = useCallback(
    (value: string) => {
      if (value && value !== selectedStartOption?.id) {
        if (selectedEndOption && value > selectedEndOption.id) {
          updateTimeRangeTo(momentFromUnix(value))
        }
        updateTimeRangeFrom(momentFromUnix(value))
      }
    },
    [updateTimeRangeFrom, updateTimeRangeTo, selectedStartOption, selectedEndOption]
  )
  const onEndTimeSelect = useCallback(
    (value: string) => {
      if (value && value !== selectedEndOption?.id) {
        if (selectedStartOption && value < selectedStartOption.id) {
          updateTimeRangeFrom(momentFromUnix(value))
        }
        updateTimeRangeTo(momentFromUnix(value))
      }
    },
    [updateTimeRangeFrom, updateTimeRangeTo, selectedStartOption, selectedEndOption]
  )
  return (
    <VStack spacing="sm">
      <HStack spacing="xs">
        <Label primary="Start time">
          <Select
            placeholder="Choose time"
            data-test="pacing-rule-time-range-from"
            onChange={onStartTimeSelect}
            options={availableOptions}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value={selectedStartOption!.id}
            searchable={false}
          />
        </Label>
        <Box pl="m" pr="m" mt="l">
          <Text>to</Text>
        </Box>
        <Label primary="End time">
          <Select
            placeholder="Choose time"
            data-test="pacing-rule-time-range-to"
            onChange={onEndTimeSelect}
            options={availableEndOptions}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value={selectedEndOption!.id}
            searchable={false}
          />
        </Label>
        <Box pl="s" pr="s">
          <Label primary="Time interval">
            <Select
              data-test="pacing-rule-time-interval"
              onChange={value => value && updateTimeIntervalMins(parseInt(value, 10))}
              options={timeIntervalOptions}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={timeIntervalMins != null ? String(timeIntervalMins) : timeIntervalOptions[0]!.id}
              searchable={false}
            />
          </Label>
        </Box>
      </HStack>
    </VStack>
  )
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

const timeOptionId = (time: moment.Moment): string => `${time.unix()}`
const transformTimeOption = (time: moment.Moment): SelectOption => ({ id: timeOptionId(time), label: time.format('LT') })
const findTimeOptionInArray = (time: moment.Moment | undefined, options: SelectOption[]): SelectOption | undefined => {
  if (time) {
    const fromId = timeOptionId(time)
    return options.find(o => o.id === fromId)
  }
  return undefined
}
const transformIntervalOption = (value: number): SelectOption => ({
  id: `${value}`,
  label: formatTimeInterval(value),
})
const formatTimeInterval = (timeInterval: number) => (timeInterval / 60 >= 1 ? `${timeInterval / 60} hr` : `${timeInterval} min`)
const timeIntervalNumbers = _.concat([15, 30], _.range(60, 60 * 24 + 1, 60))
const timeIntervalOptions = timeIntervalNumbers.map(transformIntervalOption)
const momentFromUnix = (unix: string) => moment(1000 * parseInt(unix, 10))
