import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'
import Checkbox from 'mgr/lib/forms/Checkbox'
import { SubNavStyles } from 'mgr/pages/shared/SubNav'
import Toolbar from 'mgr/pages/single-venue/dayview/containers/DayviewToolbar'
import {
  onChangeVenue,
  tryLoadGuestCenterImportActivity,
  tryUploadGuestCenterReservations,
  changeHardAssignTables,
  changeIgnoreLastUpdated,
} from 'mgr/pages/single-venue/reporting/actions/GuestCenterImport'
import ResChannelFileDropzone from 'mgr/pages/single-venue/reporting/components/ResChannelFileDropzone'
import { ReportingWrapper, ReportingContainer } from 'mgr/pages/single-venue/reporting/components/ReportingWrap'

const ImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${props => props.theme.color.white};
  padding: 0 10px;
`

class GuestCenterImport extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
  }

  componentDidMount() {
    this.props.tryLoadGuestCenterImportActivity(this.props.venue)
  }

  onDrop(acceptedFiles) {
    this.props.tryUploadGuestCenterReservations(acceptedFiles[0])
  }

  render() {
    const {
      isUploadingFile,
      hardAssignTables,
      ignoreLastUpdated,
      history,
      onChangeVenue,
      changeHardAssignTables,
      changeIgnoreLastUpdated,
      isSuperhero,
    } = this.props

    return (
      <ReportingWrapper>
        <ReportingContainer>
          <Toolbar
            showShiftDropdown={false}
            showDateDropdown={false}
            showControls={false}
            showActionButton={false}
            overrideChangeVenue={onChangeVenue}
            history={history}
            key="dayViewToolBar"
            width={`calc(100% - ${SubNavStyles.width}px - ${ManagerStyles.NavigationWidth}px)`}
          />
          <ImportContainer>
            <h1>GuestCenter Channel Connect Reservation Upload</h1>
            {isSuperhero && (
              <p>
                <h4>Superhero upload options</h4>
                <Checkbox
                  name="guestcenter_import_hard_assign_tables"
                  label="Hard-assign tables from OpenTable"
                  value={hardAssignTables}
                  on={hardAssignTables}
                  onChange={changeHardAssignTables}
                />
                (warning: will hard assign both auto-assignments and hard assignments from OT)
                <Checkbox
                  name="guestcenter_import_ignore_last_updated"
                  label="Ignore last_updated and re-import existing reservations"
                  value={ignoreLastUpdated}
                  on={ignoreLastUpdated}
                  onChange={changeIgnoreLastUpdated}
                />
                (warning: will revert changes made in 7R to OT reservations if all Resolutions have not been made in GC)
              </p>
            )}
            <ResChannelFileDropzone
              onDrop={this.onDrop}
              isUploadingFile={isUploadingFile}
              exampleFileName="GuestCenter_reservations_77777_2027-07-07_1648.csv"
            />
          </ImportContainer>
        </ReportingContainer>
      </ReportingWrapper>
    )
  }
}

const mapStateToProps = state => ({
  isUploadingFile: state.guestcenterimport.isUploadingFile,
  hardAssignTables: state.guestcenterimport.hardAssignTables,
  ignoreLastUpdated: state.guestcenterimport.ignoreLastUpdated,
  isSuperhero: state.appState.user.is_superuser,
})

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      onChangeVenue,
      tryLoadGuestCenterImportActivity,
      tryUploadGuestCenterReservations,
      changeHardAssignTables,
      changeIgnoreLastUpdated,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestCenterImport))
