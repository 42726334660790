import { BaseNotificationToggleTable, type NotificationToggleTableProps } from './BaseNotificationToggleTable'

const METHOD = 'ROOM_SERVICE'

export function RoomServiceNotificationToggleTable({ getNotificationSetting, toggleNotificationSetting }: NotificationToggleTableProps) {
  return (
    <BaseNotificationToggleTable
      getNotificationSetting={getNotificationSetting}
      toggleNotificationSetting={toggleNotificationSetting}
      method={METHOD}
    />
  )
}
