/* eslint no-param-reassign: "off" */
import React from 'react'
import TextInput, { ValidatorTypes } from 'mgr/lib/forms/TextInput'

export const apiKeyField = ({ orkestroIntegrationSettings, changeOrkestroField, formErrors, validateFieldMap }) => (
  <TextInput
    label="API KEY*"
    charLimit={300}
    validator={ValidatorTypes.notEmpty}
    ref={e => {
      validateFieldMap.api_key = e
    }}
    isValid={!formErrors.api_key}
    value={orkestroIntegrationSettings.settings.api_key}
    onChange={v => changeOrkestroField('api_key', v)}
  />
)

export const pickupInstructionsField = ({ orkestroIntegrationSettings, changeOrkestroField }) => (
  <TextInput
    label="PICKUP INSTRUCTIONS"
    charLimit={300}
    value={orkestroIntegrationSettings.settings.pickup_instructions}
    onChange={v => changeOrkestroField('pickup_instructions', v)}
  />
)
