import React from 'react'
import styled from 'styled-components'

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  align-items: center;
  &:last-child {
    padding-bottom: 0;
  }
`

const TextLabel = styled.div``

const RightPlace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`

const GroupPanelRow = ({ title, children }) => (
  <RowContainer>
    {title && <TextLabel>{title}</TextLabel>}
    <RightPlace>{children}</RightPlace>
  </RowContainer>
)

export default GroupPanelRow
