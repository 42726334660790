import { defineMessages } from '@sevenrooms/core/locales'

export const TagDuplicateWarningModalLocales = defineMessages({
  title: {
    id: 'TagDuplicateWarningModalLocales.TagDuplicateWarningModalLocalesTitle',
    defaultMessage: 'Duplicate Tag in another Tag Category',
  },
  item: {
    id: 'TagDuplicateWarningModalLocales.TagDuplicateWarningModalLocalesText',
    defaultMessage: '"{tag}" in {count, plural, one {the Tag Category} other {the Tag Categories} } {categories}.',
  },
  description: {
    id: 'TagDuplicateWarningModalLocales.TagDuplicateWarningModalLocalesText',
    defaultMessage: 'Do you still want to create {count, plural, one {this Tag} other {these Tags} }?',
  },
} as const)
