import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import ModalCover from 'svr/component-lib/Generic/Modals/ModalCover'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, Spinner, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { RootState } from '@sevenrooms/mgr-core'
import { Button } from '@sevenrooms/react-components/components/Button'
import { ImportLocales } from './Import.locales'

const zIndexCss = css`
  z-index: 999;
  border-radius: 8px;
`

const Icon = styled(StyledVmsIconXS)`
  display: inline-block;
  color: #6a6a6a;
  ${props => props.customIconStyles};
`

const closeIconCss = css`
  font-size: 18px;
  color: #092238;
  cursor: pointer;
  padding-right: 2px;
`

const ModalBody = styled.div`
  width: 564px;
  border-radius: 5px;
  background-color: white;
  padding: 16px;
`
const ModalClose = styled.div`
  text-align: right;
`
const ModalContents = styled.div`
  padding: 0 2px 0 20px;
`

const ModalContent = styled.div`
  padding: 0px 0 20px 0;
  font-size: 16px;
`

const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 13px;
`

interface SubmitImportModalProps {
  handleClosePopUp: () => void
  submit: () => void
  handleCopyImportId: () => void
}

export default function SubmitImportModal(props: SubmitImportModalProps) {
  const { handleClosePopUp, submit, handleCopyImportId } = props
  const { formatMessage } = useLocales()
  const isSubmitting = useSelector((state: RootState) => state.reservationAndClientImport.isSubmitting)
  const importId = useSelector((state: RootState) => state.reservationAndClientImport.importId)

  return (
    <ModalCover customModalScreen={zIndexCss}>
      <ModalBody>
        <ModalClose>
          <Icon customIconStyles={closeIconCss} onClick={handleClosePopUp}>
            {VmsIcons.Close}
          </Icon>
        </ModalClose>
        <ModalContents>
          <ModalHeader>{formatMessage(ImportLocales.importing)}</ModalHeader>
          <ModalContent>
            <VStack spacing="m">
              <Box>
                <Text>{formatMessage(ImportLocales.importEmail)}</Text>
              </Box>
              {isSubmitting && (
                <Box textAlign="center" width="100%">
                  <Spinner />
                </Box>
              )}
              {!!importId && (
                <>
                  <Box textAlign="center" width="100%">
                    <Text fontSize="s" fontWeight="bold">
                      {formatMessage(ImportLocales.importId, { importId })}
                    </Text>
                  </Box>
                  <Box>
                    <Text>{formatMessage(ImportLocales.saveImportId)}</Text>
                  </Box>
                </>
              )}
            </VStack>
          </ModalContent>
          <HStack justifyContent="right" spacing="m">
            {!isSubmitting && !importId ? (
              <Button variant="contained" onClick={submit} className="sr-start-import-flatfile" data-test="submit-import-modal-submit">
                {formatMessage(ImportLocales.submit)}
              </Button>
            ) : (
              <>
                <Button variant="outlined" onClick={handleClosePopUp} data-test="submit-import-modal-close">
                  {formatMessage(ImportLocales.close)}
                </Button>
                <Button variant="contained" onClick={handleCopyImportId} disabled={!importId} data-test="submit-import-modal-copy-id">
                  {formatMessage(ImportLocales.copyImportId)}
                </Button>
              </>
            )}
          </HStack>
        </ModalContents>
      </ModalBody>
    </ModalCover>
  )
}
