import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const computeNoteAndEventsParams = state => ({
  venue: state.appState.venue,
  date: state.dayviewState.date,
  dailyEventsByVenueDate: state.dayviewState.dailyEventsByVenueDate,
})

const computeNoteAndEvents = ({ dailyEventsByVenueDate, venue, date }) => {
  const dailyEvents = _.get(dailyEventsByVenueDate, `[${venue.urlKey}][${date.format()}]`)
  const note = _.get(dailyEvents, 'note', false)
  const events = _.get(dailyEvents, 'events', [])
  const hasNoteOrEvent = !!note || !!events.length
  return { note, events, hasNoteOrEvent }
}

export const selectNoteAndEvents = createShallowEqualSelector(computeNoteAndEventsParams, computeNoteAndEvents)
