import { connect } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import type { Venue, UserDomain, UserDomainVenue } from '@sevenrooms/mgr-core'
import { PolicyLanguageSettings } from '@sevenrooms/mgr-settings/Settings/LanguageSettings'
import type { RouteComponentProps } from 'react-router'

export const POLICY_URL_KEYS = {
  BOOKING: 'booking_policies',
  PAYMENT: 'payment_policies',
  OTHER: 'other_policies',
}

interface PoliciesSettingsProps extends RouteComponentProps {
  readonly venue: Venue
  readonly venues: UserDomainVenue[]
  readonly onChangeVenue: (venue: UserDomainVenue) => void
}

function PoliciesSettingsComponent(props: PoliciesSettingsProps) {
  const { match, venue, venues, onChangeVenue } = props

  const routes = {
    BOOKING: `${match.path}/${POLICY_URL_KEYS.BOOKING}`,
    PAYMENT: `${match.path}/${POLICY_URL_KEYS.PAYMENT}`,
    OTHER: `${match.path}/${POLICY_URL_KEYS.OTHER}`,
  }

  return <PolicyLanguageSettings venue={venue} venues={venues} routes={routes} onChangeVenue={onChangeVenue} />
}

const mapStateToProps = (state: { appState: { venue: Venue; userDomain: UserDomain } }) => {
  const { venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
}

export const PoliciesSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(PoliciesSettingsComponent)
