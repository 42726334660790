import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import {
  fetchUserRoles,
  fetchUserRole,
  createUserRole,
  saveUserRole,
  deleteVmsRole,
  duplicateVmsRole,
} from 'mgr/lib/services/UserRoleServices'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'

export const tryLoadVmsRoleTemplates = venueGroup => (dispatch, getState) => {
  const { roles } = getState()
  if (roles.vmsRoleTemplates.length !== 0) {
    return
  }
  dispatch({
    type: ActionTypes.LOAD_VMS_ROLE_TEMPLATES_START,
  })

  fetchUserRoles({ venue_group_id: venueGroup.id })
    .then(response =>
      dispatch({
        type: ActionTypes.LOAD_VMS_ROLE_TEMPLATES_SUCCESS,
        vmsRoleTemplates: response.data.vms_role_templates,
      })
    )
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error('an error occurred fetching vms role templates', e.toString())
      dispatch({
        type: ActionTypes.LOAD_VMS_ROLE_TEMPLATES_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Fetching roles failed, please try again or contact support'))

      throw e
    })
}

export const tryLoadVmsRoleTemplate = vmsRoleTemplateId => (dispatch, getState) => {
  const { roles, appState } = getState()
  if (roles.selectedRoleId === vmsRoleTemplateId) {
    return
  }
  dispatch({
    type: ActionTypes.LOAD_VMS_ROLE_TEMPLATE_START,
  })

  fetchUserRole({
    vms_role_template_id: vmsRoleTemplateId,
    venue_group_id: appState.venueGroup.id,
  })
    .then(response =>
      dispatch({
        type: ActionTypes.LOAD_VMS_ROLE_TEMPLATE_SUCCESS,
        vmsRoleTemplate: response.data.vms_role_template,
      })
    )
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error('an error occurred fetching vms role template', e.toString())
      dispatch({
        type: ActionTypes.LOAD_VMS_ROLE_TEMPLATE_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Fetching role failed, please try again or contact support'))

      throw e
    })
}

export const onPresetRoleTemplateChange = presetRoleTemplateId => (dispatch, getState) => {
  const { roles } = getState()
  if (roles.selectedPresetRoleTemplateId === presetRoleTemplateId) {
    return
  }
  dispatch({
    type: ActionTypes.PRESET_ROLE_TEMPLATE_CHANGE,
    presetRoleTemplateId,
  })
}

export const onRoleNameChange = roleName => (dispatch, getState) => {
  const { roles } = getState()
  if (roles.selectedRoleName === roleName) {
    return
  }
  dispatch({
    type: ActionTypes.VMS_ROLE_TEMPLATE_NAME_CHANGE,
    roleName,
  })
}

export const toggleRolePermission = permissionName => ({
  type: ActionTypes.TOGGLE_VMS_ROLE_TEMPLATE_PERMISSION,
  permissionName,
})

export const setAllCheckboxOptions = (propNames, val) => ({
  type: ActionTypes.SET_GROUP_VMS_ROLE_TEMPLATE_PERMISSIONS,
  propNames,
  val,
})

export const setAllowedPermissions = propNames => ({
  type: ActionTypes.SET_ALLOWED_PERMISSIONS,
  propNames,
})

const getVmsRoleTemplateParams = (appState, rolesState) => ({
  venue_group_id: appState.venueGroup.id,
  vms_role_template_id: rolesState.selectedRoleId,
  name: rolesState.selectedRoleName,
  preset_role_template: rolesState.selectedPresetRoleTemplateId,
  privilege_level: rolesState.selectedRolePrivilegeLevel,
  ...rolesState.permissions,
})

export const tryCreateVmsRoleTemplate = () => (dispatch, getState) => {
  const { appState, roles } = getState()
  if (roles.isRoleUpdating) {
    return
  }
  dispatch({
    type: ActionTypes.TRY_CREATE_VMS_ROLE_TEMPLATE_START,
  })

  const params = getVmsRoleTemplateParams(appState, roles)
  // eslint-disable-next-line consistent-return
  return createUserRole(params)
    .then(response => {
      dispatch({
        type: ActionTypes.TRY_CREATE_VMS_ROLE_TEMPLATE_SUCCESS,
        vmsRoleTemplate: response.data.vms_role_template,
      })
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error('an error occurred fetching vms role template', e.toString())
      dispatch({
        type: ActionTypes.TRY_CREATE_VMS_ROLE_TEMPLATE_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Creating role failed, please try again or contact support'))

      throw e
    })
}

export const trySaveVmsRoleTemplate = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.TRY_SAVE_VMS_ROLE_TEMPLATE_START,
  })

  const { appState, roles } = getState()
  const params = getVmsRoleTemplateParams(appState, roles)
  return saveUserRole(params)
    .then(response => {
      dispatch({
        type: ActionTypes.TRY_SAVE_VMS_ROLE_TEMPLATE_SUCCESS,
        vmsRoleTemplate: response.data.vms_role_template,
      })
    })
    .catch(e => {
      dispatch({
        type: ActionTypes.TRY_SAVE_VMS_ROLE_TEMPLATE_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Saving role failed, please try again or contact support'))

      throw e
    })
}

export const resetVmsRoleTemplateState = () => dispatch => {
  dispatch({
    type: ActionTypes.RESET_VMS_ROLE_TEMPLATE_STATE,
  })
}

export const invalidateRole = () => dispatch => {
  dispatch({
    type: ActionTypes.INVALIDATE_ROLE,
  })
}

export const tryDeleteVmsRoleTemplate = roleId => (dispatch, getState) => {
  const { appState, roles } = getState()
  if (roles.isRoleUpdating) {
    return
  }
  dispatch({
    type: ActionTypes.DELETE_VMS_ROLE_TEMPLATE_START,
  })

  // eslint-disable-next-line consistent-return
  return deleteVmsRole({
    vms_role_template_id: roleId,
    venue_group_id: appState.venueGroup.id,
  })
    .then(() => {
      dispatch({
        type: ActionTypes.DELETE_VMS_ROLE_TEMPLATE_SUCCESS,
      })
    })
    .catch(e => {
      dispatch({
        type: ActionTypes.DELETE_VMS_ROLE_TEMPLATE_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Deleting vms role template failed, please try again or contact support'))

      throw e
    })
}

export const tryDuplicateVmsRoleTemplate = roleId => (dispatch, getState) => {
  const { appState, roles } = getState()
  if (roles.isRoleUpdating) {
    return
  }
  dispatch({
    type: ActionTypes.DUPLICATE_VMS_ROLE_TEMPLATE_START,
  })

  // eslint-disable-next-line consistent-return
  return duplicateVmsRole({
    vms_role_template_id: roleId,
    venue_group_id: appState.venueGroup.id,
  })
    .then(() => {
      dispatch({
        type: ActionTypes.DUPLICATE_VMS_ROLE_TEMPLATE_SUCCESS,
      })
    })
    .catch(e => {
      dispatch({
        type: ActionTypes.DUPLICATE_VMS_ROLE_TEMPLATE_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Duplicating vms role template failed, please try again or contact support'))

      throw e
    })
}

export const toggleDeleteWarning = (showWarning, selectedRoleId = null) => ({
  type: ActionTypes.TOGGLE_DELETE_WARNING,
  showWarning,
  selectedRoleId,
})

export const updateEditablePermission = shouldLockEmailCampaignsWithGlobalTags => ({
  type: ActionTypes.UPDATE_EDITABLE_PERMISSIONS,
  shouldLockEmailCampaignsWithGlobalTags,
})
