import { srDelete, srGet, srPostJson, throwFetchError } from '@sevenrooms/core/api'

export const fetchFormatUrl = (venueOrGroupId, url, isGroup, lookerFilters) =>
  srGet(
    `/api-yoa/report/looker/${isGroup ? 'group' : 'venue'}/dashboard`,
    {
      url,
      lookerFilters,
      is_group: isGroup,
      ...(isGroup && { venue_group: venueOrGroupId }),
      ...(!isGroup && { venue: venueOrGroupId }),
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.status !== 200) {
      throw response.error
    }
    return response
  })

export const postSaveActivityLog = (venueId, venueGroupId, eventType, reportTitle, reportPath, reportFormat) =>
  srPostJson(`/api-yoa/report/looker/activitylog/save?venue=${venueId}&venue_group=${venueGroupId}`, {
    event_type: eventType,
    report_title: reportTitle,
    report_path: reportPath,
    report_format: reportFormat,
  }).then(throwFetchError)

export const resetVenueUserApi = async (venueId, url) =>
  srDelete(
    `/api-yoa/report/looker/venue/dashboard`,
    {
      url,
      venue: venueId,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  )
