import type { Venue } from '@sevenrooms/core/domain/index'
import type { DateOnly } from '@sevenrooms/core/timepiece/index'

export function getBlocksUrl(venue: Venue, date: DateOnly, shiftCategory: string) {
  const dateInfo = date.getInfo()
  const formattedDateForLink = `${prependZero(dateInfo.month + 1)}-${prependZero(dateInfo.day)}-${dateInfo.year}`
  return `/manager2/${venue.urlKey}/${formattedDateForLink}/reservations/grid?shift=${shiftCategory}`
}

function prependZero(num: number) {
  return num < 10 ? `0${num}` : `${num}`
}

export function getReservationUrl(venue: Venue, date: DateOnly, persistentId: string, actualId: string) {
  const dateInfo = date.getInfo()
  const formattedDateForLink = `${dateInfo.month + 1}-${dateInfo.day}-${dateInfo.year}`
  // eslint-disable-next-line max-len
  return `/manager/${venue.urlKey}/reservations/day/${formattedDateForLink}?shift_id=${persistentId}&actual_id=${actualId}`
}

export function getAccessRuleUrl(venue: Venue, date: DateOnly, accessRuleId: string) {
  const dInfo = date.getInfo()
  const formattedDate = `${dInfo.month + 1}/${dInfo.day}/${dInfo.year}`
  return `/manager/${venue.urlKey}/manage/capacity/accessrules?selected_access_rule_id=${accessRuleId}&date=${formattedDate}`
}
