import { InsightStatus } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { Button, TextArea } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, VStack, DividerLine, Box } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useInsightSuggestedActionsContext } from '../../../hooks'
import { insightsMessages } from '../../../locales'
import { StatusIcon } from '../../Status'

export interface InsightDeclinedSectionProps {
  declinedReason?: string | null
  declinedBy?: string | null
  declinedDatetime?: string | null
}

export function InsightDeclinedSection({ declinedReason, declinedBy, declinedDatetime }: InsightDeclinedSectionProps) {
  const { formatMessage } = useLocales()
  const { onSave } = useInsightSuggestedActionsContext()
  const titleContent = (
    <HStack justifyContent="space-between" data-test="insight-declined-title">
      <HStack spacing="s">
        <StatusIcon variant="declined" />
        <Text textStyle="h2">{formatMessage(insightsMessages.statusDeclinedLabel)}</Text>
      </HStack>
    </HStack>
  )

  return (
    <BaseSection
      title={titleContent}
      data-test="insight-declined-section"
      actions={
        declinedBy &&
        declinedDatetime && (
          <SecondaryText fontStyle="italic" data-test="insight-declinedby-text">
            {formatMessage(insightsMessages.declinedByText, {
              user: declinedBy,
              date: DateTime.from(declinedDatetime).toDateOnly().toIso(),
            })}
          </SecondaryText>
        )
      }
    >
      <VStack pt="m">
        <DividerLine />
        <VStack p="m" spacing="s">
          <SecondaryText data-test="insight-declined-description">
            {formatMessage(insightsMessages.declinedText, {
              link: (
                <Button
                  variant="tertiary"
                  onClick={() => onSave({ updatedStatus: InsightStatus.NEW })}
                  noPadding
                  data-test="insight-declined-revert-link"
                >
                  {formatMessage(insightsMessages.revertStatusLinkText)}
                </Button>
              ),
            })}
          </SecondaryText>
          {declinedReason && (
            <Box data-test="declined-reason">
              <SecondaryText fontWeight="bold">{formatMessage(insightsMessages.declinedYourFeedback)}</SecondaryText>
              <TextArea
                data-test="insights-decline-reason-display-input"
                aria-label={formatMessage(insightsMessages.declinedYourFeedback)}
                value={declinedReason ?? ''}
                disabled
              />
            </Box>
          )}
        </VStack>
      </VStack>
    </BaseSection>
  )
}
