export const getLookerItems = (lookerFolders, baseUrl, exclude = []) =>
  Object.keys(lookerFolders)
    .filter(x => !exclude.includes(x))
    .reduce((accum, current) => {
      const items = lookerFolders[current].map(item => ({
        link: `${baseUrl}${item.url}`,
        name: item.title,
        className: 'looker-link',
        id: `${current.replace(' ', '-').toLowerCase()}-${item.title.replace(' ', '-').toLowerCase()}`,
      }))

      return [
        ...accum,
        {
          name: current,
          isTitle: true,
        },
        ...items,
      ]
    }, [])

const getLookerSideNav = (lookerFolders, baseUrl, exclude = []) =>
  Object.keys(lookerFolders)
    .filter(x => !exclude.includes(x))
    .map(category => {
      const items = lookerFolders[category].map(item => ({
        id: `report-link-${item.id}`,
        title: item.title,
        to: `${baseUrl}${item.url}`,
        items: [],
        reload: true,
      }))
      return {
        id: `${category.replace(' ', '-').toLowerCase()}`,
        allowed: true,
        collapsible: false,
        items,
        title: category,
      }
    })

const REPORTING_EXCLUDED_FOLDERS = ['Revenue Optimization Dashboards', 'Revenue Management Extended Dashboards']

export const getReportingLookerItems = (lookerFolders, baseUrl) => getLookerItems(lookerFolders, baseUrl, REPORTING_EXCLUDED_FOLDERS)

export const getReportingLookerSideNav = (lookerFolders, baseUrl) => getLookerSideNav(lookerFolders, baseUrl, REPORTING_EXCLUDED_FOLDERS)
