import * as React from 'react'
import styled from 'styled-components'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'

const EditableThContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const EditIcon = styled(StyledIcons.XS)`
  cursor: pointer;
  color: #93979d;
`

class ActionableThCell extends React.Component {
  constructor(props) {
    super(props)
    this.className = `rt-th ${this.props.className}`
    this.state = {
      isActiveHover: false,
      displayIcon: this.props.className.indexOf('rt-disable') === -1,
    }
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this)
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this)
  }

  onMouseEnterHandler() {
    this.setState({ isActiveHover: true })
  }

  onMouseLeaveHandler() {
    this.setState({ isActiveHover: false })
  }

  render() {
    return (
      <EditableThContainer
        className={this.className}
        style={this.props.style}
        role="columnheader"
        tabindex="-1"
        onClick={this.state.displayIcon ? this.props.onClick : null}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        {this.props.children[0]}
        {this.state.isActiveHover && this.state.displayIcon && (
          <EditIcon aria-hidden="true" className="sr-rt-th-edit">
            {VmsIcons.EditPencil}
          </EditIcon>
        )}
      </EditableThContainer>
    )
  }
}

export default ActionableThCell
