import { useLocales } from '@sevenrooms/core/locales'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import { settingsMessages } from '../settings.locales'
import { LanguageSettings } from './LanguageSettings'

interface EmailLanguageSettingsRoutes {
  RESERVATION_CONFIRMATION: string
  RESERVATION_UPDATE: string
  RESERVATION_CANCELLATION: string
  FEEDBACK_REQUEST_RES_EMAIL: string
  FEEDBACK_REQUEST_ORDER_EMAIL: string
  PAYLINK_REQUEST_EMAIL: string
  COMPLETED_TRANSACTION_EMAIL: string
  RESERVATION_REMINDER_EMAIL: string
  GUESTLIST_CONFIRMATION: string
  REQUEST_EMAIL: string
  EVENT_CONFIRMATION: string
  EMAILS_PRIORITY_ALERTS: string
  MARKETING_SUBSCRIPTION_CONFIRMATION: string
  CONTINUED_MARKETING_SUBSCRIPTION_CONFIRMATION_EMAIL: string
  SUBSCRIPTION_OPT_IN: string
}

interface EmailLanguageSettingsProps {
  venue: Venue
  venues: UserDomainVenue[]
  routes: EmailLanguageSettingsRoutes
  onChangeVenue: (venue: UserDomainVenue) => void
}

export function EmailLanguageSettings({ onChangeVenue, routes, venue, venues }: EmailLanguageSettingsProps) {
  const { formatMessage } = useLocales()
  return (
    <LanguageSettings title={formatMessage(settingsMessages.emails)} venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
      <LanguageSettings.Block label={formatMessage(settingsMessages.reservations)}>
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.reservationConfirmation)}
          id="row-block-reservation-confirmation"
          editLink={routes.RESERVATION_CONFIRMATION}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsReservationReminder)}
          id="row-block-reservation-reminder"
          editLink={routes.RESERVATION_REMINDER_EMAIL}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsReservationUpdate)}
          id="row-block-reservation-update"
          editLink={routes.RESERVATION_UPDATE}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsReservationCancellation)}
          id="row-block-reservation-cancellation"
          editLink={routes.RESERVATION_CANCELLATION}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsCompletedTransactionLanguage)}
          id="row-block-completed-transaction-email"
          editLink={routes.COMPLETED_TRANSACTION_EMAIL}
        />
        {/* temporary hiding */}
        {false && (
          <div>
            <LanguageSettings.Row label="Reservation Reminder" id="res-reminder-links" editLink="" />
            <LanguageSettings.Row label="Reservation Completed Charge" id="res-completed-links" editLink="" />
          </div>
        )}
        {/* remove when unhiding */}
      </LanguageSettings.Block>
      <LanguageSettings.Block label="Requests">
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsReservationRequests)}
          id="row-block-reservation-request"
          editLink={routes.REQUEST_EMAIL}
        />
      </LanguageSettings.Block>
      {venue.isDiningClass && (
        <LanguageSettings.Block label="Feedback">
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.emailsFeedbackRequestLanguage)}
            id="row-block-reservation-confirmation"
            editLink={routes.FEEDBACK_REQUEST_RES_EMAIL}
          />
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.emailsOrderFeedbackRequestLanguage)}
            id="row-block-reservation-confirmation"
            editLink={routes.FEEDBACK_REQUEST_ORDER_EMAIL}
          />
        </LanguageSettings.Block>
      )}
      <LanguageSettings.Block label="Paylink">
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsPaylinkRequestLanguage)}
          id="row-block-reservation-confirmation"
          editLink={routes.PAYLINK_REQUEST_EMAIL}
        />
      </LanguageSettings.Block>

      <LanguageSettings.Block label="Guestlist">
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsGuestlistConfirmation)}
          id="row-block-guestlist-confirmation"
          editLink={routes.GUESTLIST_CONFIRMATION}
        />
      </LanguageSettings.Block>
      {venue.eventWidgetEnabled && (
        <LanguageSettings.Block label="Events">
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.emailsEventConfirmation)}
            id="row-block-event-confirmation"
            editLink={routes.EVENT_CONFIRMATION}
          />
        </LanguageSettings.Block>
      )}
      {venue.isPriorityAlertsAdminEnabled && (
        <LanguageSettings.Block label="Priority Alerts">
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.emailsPriorityAlerts)}
            id="row-block-emails-priority-alerts"
            editLink={routes.EMAILS_PRIORITY_ALERTS}
          />
        </LanguageSettings.Block>
      )}
      <LanguageSettings.Block label="Marketing Subscription">
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsMarketingSubscriptionConfirmation)}
          id="row-block-marketing-subsciption-confirmation"
          editLink={routes.MARKETING_SUBSCRIPTION_CONFIRMATION}
        />
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailsContinuedMarketingSubscriptionConfirmationEmail)}
          id="row-block-continued-marketing-subsciption-confirmation-email"
          editLink={routes.CONTINUED_MARKETING_SUBSCRIPTION_CONFIRMATION_EMAIL}
        />
      </LanguageSettings.Block>
      <LanguageSettings.Block label={formatMessage(settingsMessages.emailsMultipleEmails)}>
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.emailSubscriptionOptIn)}
          id="row-block-marketing-subsciption-opt-in"
          editLink={routes.SUBSCRIPTION_OPT_IN}
        />
      </LanguageSettings.Block>

      {/* temporary hiding - remove the div tag when unhiding */}
      {false && (
        <div>
          <LanguageSettings.Block label="Request">
            <LanguageSettings.Row label="Request Submitted" id="req-submit-links" editLink="" />
            <LanguageSettings.Row label="Request Added" id="req-add-links" editLink="" />
            <LanguageSettings.Row label="Request Declined" id="req-decline-links" editLink="" />
            <LanguageSettings.Row label="Request Accepted" id="req-accept-links" editLink="" />
          </LanguageSettings.Block>

          <LanguageSettings.Block label="Feedback">
            <LanguageSettings.Row label="Feedback Request Email" id="feedback-request-email-links" editLink="" />
          </LanguageSettings.Block>
        </div>
      )}
      {/* remove when unhiding */}
    </LanguageSettings>
  )
}
