import React, { PureComponent } from 'react'
import { Check, CallToAction, NegativeButton, Button, HigherModal, Error, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import { convert, checkConnection, disconnect, transition, ProviderState } from '../actions/EmailServiceProviderActions'

export class EmmaSection extends PureComponent {
  constructor(props) {
    super(props)
    this.url_base = 'emma'
    this.connected_var = 'emma_connected'
    this.state_var = 'emma_connection'
    this.service_type = 'emailserviceproviders'
    this.state = { [this.state_var]: ProviderState.WORKING, error: undefined }
  }

  componentDidMount() {
    checkConnection(this)
  }

  render() {
    switch (this.state.emma_connection) {
      case ProviderState.DISCONNECTED:
        return (
          <span>
            <CallToAction>Link an existing Emma account</CallToAction>
            <Button className="emma-auth-button" onClick={() => transition(this, ProviderState.CONNECTING)}>
              Authenticate
            </Button>
          </span>
        )

      case ProviderState.POST_CONNECTING:
        return (
          <span>
            <CallToAction>Link an existing Emma account</CallToAction>
            <NegativeButton className="emma-auth-button">Authenticate</NegativeButton>
            <HigherModal
              title="Success"
              content="You have successfully authenticated your Emma account with SevenRooms. Next, head to the clients tab to send a list to Emma."
              visibility
              action={() => transition(this, ProviderState.CONNECTED)}
              actionText="Close"
            />
          </span>
        )

      case ProviderState.CONNECTING:
        return (
          <span>
            <CallToAction>Link an existing Emma account</CallToAction>
            <NegativeButton className="emma-auth-button">Authenticate</NegativeButton>
            <HigherModal
              toggler={() => transition(this, ProviderState.DISCONNECTED)}
              action={() => this.sendCredentials()}
              actionText="Confirm"
              title="EMMA INTEGRATION"
              content={this.emmaForm()}
              visibility
            />
          </span>
        )

      case ProviderState.DISCONNECTING:
        return (
          <span>
            <CallToAction>
              <Check src={`${this.props.media_url}images/check2x.png`} />
              Emma account successfully linked
            </CallToAction>
            <NegativeButton className="emma-disconnect-button">Disconnect Emma</NegativeButton>
            <HigherModal
              toggler={() => transition(this, ProviderState.CONNECTED)}
              action={() => disconnect(this)}
              actionText="Confirm"
              title="Are you sure?"
              content="Disconnecting Emma from SevenRooms will prevent users from sending clients to Emma."
              visibility
            />
          </span>
        )

      case ProviderState.CANT_CONNECT:
        return (
          <span>
            <Button
              className="emma-sizzle-only-button"
              onClick={() => {
                window.location.href =
                  'https://sevenrooms.com/en/7x-marketing-automation-guest-engagement/?&channel=EspProductPageEmmaButton&utm_source=espProductPage&utm_medium=display&utm_campaign=Emmabuttonnon7X+july2019'
              }}
            >
              Book a 7X demo
            </Button>
            <SubDesc>
              <a href="https://myemma.com/" target="_blank" rel="noreferrer">
                Learn more about Emma
              </a>
            </SubDesc>
          </span>
        )
      case ProviderState.CONNECTED:
        return (
          <span>
            <CallToAction>
              <Check src={`${this.props.media_url}images/check2x.png`} />
              Emma account successfully linked
            </CallToAction>
            <NegativeButton className="emma-disconnect-button" onClick={() => transition(this, ProviderState.DISCONNECTING)}>
              Disconnect Emma
            </NegativeButton>
          </span>
        )
      case ProviderState.WORKING:
        return <CallToAction className="emma-working-button">Working....</CallToAction>
      case ProviderState.ERROR:
        return <CallToAction>Error communicating with 7r</CallToAction>
      default:
        return null
    }
  }

  sendCredentials() {
    const data = new FormData()
    data.append('account_id', this.state.account_id)
    data.append('private_key', this.state.private_key)
    data.append('public_key', this.state.public_key)

    fetch(`/manager/${this.props.venue_id}/emailserviceproviders/${this.url_base}/connect`, {
      method: 'POST',
      body: data,
    })
      .then(convert)
      .then(r => {
        if (r.success) {
          transition(this, ProviderState.POST_CONNECTING)
        } else {
          this.setState({ error: 'Invalid credentials' })
        }
      })
      .catch(r => this.setState({ error: 'Error communicating with 7r' }))
  }

  emmaForm() {
    return (
      <span>
        <CallToAction>Authenticate Emma and SevenRooms integration</CallToAction>
        <Error>{this.state.error}</Error>
        <TextInput
          name="account_id"
          placeholder="Account ID"
          value={this.state.account_id}
          onChange={account_id => this.setState({ account_id })}
        />
        <TextInput
          name="private_key"
          placeholder="Private Key"
          value={this.state.private_key}
          onChange={private_key => this.setState({ private_key })}
        />
        <TextInput
          name="public_key"
          placeholder="Public Key"
          value={this.state.public_key}
          onChange={public_key => this.setState({ public_key })}
        />
        <SubDesc>
          <a href="https://sevenrooms.zendesk.com/hc/en-us/articles/360029972371" target="_blank" rel="noreferrer">
            What are these fields?
          </a>
        </SubDesc>
      </span>
    )
  }
}
