import React from 'react'
import styled, { css } from 'styled-components'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'

const LanguageContentContainer = styled.div``

/**
 * @typedef {HTMLAttributes<HTMLElement> & {
 *   disabled: boolean
 * }} SaveButtonProps
 *
 * @type {React.FC<SaveButtonProps>}
 */
export const SaveButton = styled.div`
  ${props => props.theme.primaryButton};
  background: ${props => props.theme.color.search};
  height: 46px;
  line-height: 46px;
  padding-left: ${props => props.theme.gutter.triple};
  padding-right: ${props => props.theme.gutter.triple};
  width: auto;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  transition: all 0.3s;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}
  :hover {
    opacity: 0.8;
  }
`

const LanguageContentLayoutContainer = styled.div`
  padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple} ${props => props.theme.gutter.medium}
    ${props => props.theme.gutter.double};
`

const LanguageBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.gutter.triple};
`

const InfoTextContainer = styled.span`
  font-size: 12px;
  color: #888c92;
  line-height: 22px;
  margin-bottom: 24px;
  margin-top: 24px;
  display: block;
`

class LanguageContentLayout extends React.PureComponent {
  saveButton() {
    return (
      <SaveButton onClick={this.props.saveFunction} id={`${this.props.title.split(' ').join('-').toLowerCase()}-save-button`}>
        Save and Publish
      </SaveButton>
    )
  }

  render() {
    const {
      title,
      venue,
      venues,
      onChangeVenue,
      routes,
      history,
      isLoading,
      mediaUrl,
      Actions = null,
      infoText,
      overrideRefreshOnVenueChange = false,
    } = this.props

    return (
      <ContentLayout
        title={title}
        venue={venue}
        venues={venues}
        routes={routes}
        history={history}
        onChangeVenue={onChangeVenue}
        Actions={Actions || this.saveButton()}
        showSpinner={isLoading}
        spinnerImgUrl={mediaUrl}
        overrideRefreshOnVenueChange={overrideRefreshOnVenueChange}
      >
        <LanguageContentLayoutContainer>
          <InfoTextContainer>{infoText}</InfoTextContainer>
          <LanguageContentContainer>{this.props.children}</LanguageContentContainer>
        </LanguageContentLayoutContainer>
      </ContentLayout>
    )
  }
}

LanguageContentLayout.propTypes = {
  title: React.PropTypes.string.isRequired,
  venue: React.PropTypes.object.isRequired,
  venues: React.PropTypes.array.isRequired,
  mediaUrl: React.PropTypes.string.isRequired,
  routes: React.PropTypes.array,
  history: React.PropTypes.object,
  isLoading: React.PropTypes.bool,
  Actions: React.PropTypes.element,
  infoText: React.PropTypes.string,
  onChangeVenue: React.PropTypes.func,
  overrideRefreshOnVenueChange: React.PropTypes.bool,
}

LanguageContentLayout.defaultProps = {
  title: '',
  venues: [],
  routes: [],
  isLoading: false,
  Actions: null,
  infoText: '',
}

export default LanguageContentLayout
