import type { ShiftSuggestedActions } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { insightsMessages } from '../../../../locales'
import { formatBold } from '../../../../utils'

export interface AddCancellationFeeSuggestedActionsProps {
  venueUrlKey: string
  insightSuggestedActionData: ShiftSuggestedActions
}

export function AddCancellationFeeSuggestedActions({ venueUrlKey, insightSuggestedActionData }: AddCancellationFeeSuggestedActionsProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { shiftName } = insightSuggestedActionData

  return (
    <VStack p="s s s xxs" spacing="s">
      <Text>
        1.{' '}
        <Link to={nav.href(routes.manager.manage.shifts, { params: { venueKey: venueUrlKey } })} target="_blank">
          {formatMessage(insightsMessages.suggestedActionNavigatetoShiftLinkText, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          })}
        </Link>
      </Text>
      <Text>
        2.{' '}
        {formatMessage(insightsMessages.suggestedActionEditShiftStep, {
          shiftName,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <VStack spacing="xxs">
        <Text>
          3.{' '}
          {formatMessage(insightsMessages.suggestedActionNavigatetoPaymentPolicyStep, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        <VStack pl="m">
          <Text>
            {formatMessage(insightsMessages.addCancellationFeeStep1, {
              strong: (chunks: string[]) => formatBold(chunks),
            })}
          </Text>
          <Text>
            {formatMessage(insightsMessages.addCancellationFeeStep2, {
              strong: (chunks: string[]) => formatBold(chunks),
            })}
          </Text>
          <Text>
            {formatMessage(insightsMessages.addCancellationFeeStep3, {
              strong: (chunks: string[]) => formatBold(chunks),
            })}
          </Text>
        </VStack>
      </VStack>
      <Text>
        4.{' '}
        {formatMessage(insightsMessages.suggestedActionSaveShiftAndRepeatStep, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
    </VStack>
  )
}
