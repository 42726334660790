import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const HRLine = styled.div`
  width: 100%;
  border-top: 1px solid #dbdcde;
  padding-bottom: 24px;
`

export const EditSiteExampleWrapper = styled.div`
  & + & {
    padding-left: 48px;
  }
  padding-bottom: 24px;
`
const EditSiteExampleContainer = ({ children }) => (
  <>
    <Container>{children}</Container>
    <HRLine />
  </>
)

export default EditSiteExampleContainer
