import _ from 'lodash'
import React from 'react'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import CheckboxToggle from 'mgr/lib/forms/CheckboxToggle'
import TextInput, { InputRestrictions, PhoneTextInput, ValidatorTypes } from 'mgr/lib/forms/TextInput'

const prependEmptyOption = choices => _.sortBy([{ name: '', value: '' }, ...choices], ['name', 'value'])

export const nameField = ({ resyChannelSettings, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="NAME*"
    charLimit={150}
    validator={ValidatorTypes.nameRequired}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.name = e)}
    isValid={!formErrors.name}
    value={resyChannelSettings.name}
    onChange={v => changeResyField('name', v)}
  />
)

export const groupNameField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="GROUP NAME*"
    charLimit={150}
    validator={ValidatorTypes.nameRequired}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.group_name = e)}
    isValid={!formErrors.group_name}
    value={resyChannelSettings.group_name}
    onChange={v => changeResyField('group_name', v)}
  />
)

export const address1Field = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="ADDRESS STREET*"
    charLimit={200}
    inputRestriction={InputRestrictions.name}
    validator={ValidatorTypes.nameRequired}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.address_1 = e)}
    isValid={!formErrors.address_1}
    value={resyChannelSettings.address_1}
    onChange={v => changeResyField('address_1', v)}
  />
)

export const address2Field = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="ADDRESS STREET 2"
    charLimit={200}
    inputRestriction={InputRestrictions.name}
    value={resyChannelSettings.address_2}
    onChange={v => changeResyField('address_2', v)}
  />
)

export const crossStreet1Field = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="CROSS STREET"
    placeholder="(optional)"
    charLimit={200}
    inputRestriction={InputRestrictions.name}
    value={resyChannelSettings.cross_street_1}
    onChange={v => changeResyField('cross_street_1', v)}
  />
)

export const crossStreet2Field = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="CROSS STREET 2"
    placeholder="(optional)"
    charLimit={200}
    inputRestriction={InputRestrictions.name}
    value={resyChannelSettings.cross_street_2}
    onChange={v => changeResyField('cross_street_2', v)}
  />
)

export const neighborhoodField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="NEIGHBORHOOD*"
    charLimit={200}
    inputRestriction={InputRestrictions.name}
    validator={ValidatorTypes.nameRequired}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.neighborhood = e)}
    isValid={!formErrors.neighborhood}
    value={resyChannelSettings.neighborhood}
    onChange={v => changeResyField('neighborhood', v)}
  />
)

export const localityField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="CITY/LOCALITY*"
    charLimit={200}
    inputRestriction={InputRestrictions.name}
    validator={ValidatorTypes.nameRequired}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.locality = e)}
    isValid={!formErrors.locality}
    value={resyChannelSettings.locality}
    onChange={v => changeResyField('locality', v)}
  />
)

export const regionField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="STATE/REGION*"
    charLimit={200}
    inputRestriction={InputRestrictions.name}
    validator={ValidatorTypes.nameRequired}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.region = e)}
    isValid={!formErrors.region}
    value={resyChannelSettings.region}
    onChange={v => changeResyField('region', v)}
  />
)

export const locationCodeField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <DropdownArrowsPicker
    name="LOCATION CODE*"
    choices={prependEmptyOption(
      _.map(resyChannelFormOptions.LOCATION_CODE_OPTIONS, (name, value) => ({
        name,
        value,
      }))
    )}
    isLightTheme
    useOutsideLabel
    value={resyChannelSettings.location_code || ''}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.location_code = e ? e.instanceRef : null)}
    isValid={!formErrors.location_code}
    onChangeHandler={v => changeResyField('location_code', v)}
    style={{
      minWidth: 80,
      width: '100%',
    }}
  />
)

export const postalCodeField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="POSTAL CODE*"
    charLimit={20}
    inputRestriction={InputRestrictions.name}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.postal_code = e)}
    isValid={!formErrors.postal_code}
    value={resyChannelSettings.postal_code}
    onChange={v => changeResyField('postal_code', v)}
  />
)

export const countryField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <DropdownArrowsPicker
    name="COUNTRY*"
    choices={prependEmptyOption(
      _.map(resyChannelFormOptions.COUNTRY_CODE_OPTIONS, (name, value) => ({
        name,
        value,
      }))
    )}
    isLightTheme
    useOutsideLabel
    value={resyChannelSettings.country_code || 'us'}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.country_code = e ? e.instanceRef : null)}
    isValid={!formErrors.country_code}
    onChangeHandler={v => changeResyField('country_code', v)}
    style={{
      minWidth: 80,
      width: '100%',
    }}
  />
)

export const geoLatitudeField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="GEO LATITUDE*"
    charLimit={200}
    inputRestriction={InputRestrictions.number}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.geo_latitude = e)}
    isValid={!formErrors.geo_latitude}
    value={`${resyChannelSettings.geo_latitude || ''}`}
    onChange={v => changeResyField('geo_latitude', v)}
  />
)

export const geoLongitudeField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="GEO LONGITUDE*"
    charLimit={200}
    inputRestriction={InputRestrictions.number}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.geo_longitude = e)}
    isValid={!formErrors.geo_longitude}
    value={`${resyChannelSettings.geo_longitude || ''}`}
    onChange={v => changeResyField('geo_longitude', v)}
  />
)

export const localeField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <DropdownArrowsPicker
    name="LOCALE*"
    choices={prependEmptyOption(resyChannelFormOptions.LOCALE_OPTIONS.map(o => ({ name: o, value: o })))}
    isLightTheme
    useOutsideLabel
    value={resyChannelSettings.locale || ''}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.locale = e ? e.instanceRef : null)}
    isValid={!formErrors.locale}
    onChangeHandler={v => changeResyField('locale', v)}
    style={{
      minWidth: 80,
      width: '100%',
    }}
  />
)

export const phoneNumberField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <PhoneTextInput
    label="PHONE NUMBER*"
    country={(resyChannelSettings.country_code || '').toUpperCase()}
    value={resyChannelSettings.phone_number}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.phone_number = e)}
    isValid={!formErrors.phone_number}
    displayInitialValueAsLocalNumber
    onChange={v => changeResyField('phone_number', v)}
    onCountryChange={v => changeResyField('country_code', v.toLowerCase())}
    inputClassName="resy_booking_channel_phone_number_field"
  />
)

export const urlField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="WEBSITE*"
    charLimit={200}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.url = e)}
    isValid={!formErrors.url}
    value={resyChannelSettings.url}
    onChange={v => changeResyField('url', v)}
  />
)

export const contentAboutField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="ABOUT CONTENT*"
    charLimit={5000}
    isMultiLine
    resizable
    minRows={9}
    maxRows={9}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.content_about = e)}
    isValid={!formErrors.content_about}
    value={resyChannelSettings.content_about}
    onChange={v => changeResyField('content_about', v)}
  />
)

export const contentNeedToKnowField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="NEED TO KNOW CONTENT"
    charLimit={5000}
    isMultiLine
    resizable
    minRows={6}
    maxRows={6}
    placeholder="(optional)"
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.content_need_to_know = e)}
    isValid={!formErrors.content_need_to_know}
    value={resyChannelSettings.content_need_to_know}
    onChange={v => changeResyField('content_need_to_know', v)}
  />
)

export const cuisineDetailField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <DropdownArrowsPicker
    name="CUISINE DETAIL*"
    choices={prependEmptyOption(
      resyChannelFormOptions.CUISINE_DETAIL_OPTIONS.map(o => ({
        name: o,
        value: o,
      }))
    )}
    isLightTheme
    useOutsideLabel
    value={resyChannelSettings.cuisine_detail || ''}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.cuisine_detail = e ? e.instanceRef : null)}
    isValid={!formErrors.cuisine_detail}
    onChangeHandler={v => changeResyField('cuisine_detail', v)}
    style={{
      minWidth: 80,
      width: '100%',
    }}
  />
)

export const cuisineTypeField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <DropdownArrowsPicker
    name="CUISINE TYPE*"
    choices={prependEmptyOption(
      resyChannelFormOptions.CUISINE_TYPE_OPTIONS.map(o => ({
        name: o,
        value: o,
      }))
    )}
    isLightTheme
    useOutsideLabel
    value={resyChannelSettings.cuisine_type || ''}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.cuisine_type = e ? e.instanceRef : null)}
    isValid={!formErrors.cuisine_type}
    onChangeHandler={v => changeResyField('cuisine_type', v)}
    style={{
      minWidth: 80,
      width: '100%',
    }}
  />
)

export const styleField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <DropdownArrowsPicker
    name="STYLE*"
    choices={prependEmptyOption(resyChannelFormOptions.STYLE_OPTIONS.map(o => ({ name: o, value: o })))}
    isLightTheme
    useOutsideLabel
    value={resyChannelSettings.style || ''}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.style = e ? e.instanceRef : null)}
    isValid={!formErrors.style}
    onChangeHandler={v => changeResyField('style', v)}
    style={{
      minWidth: 80,
      width: '100%',
    }}
  />
)

export const menuItemsField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="MENU ITEMS"
    placeholder="Veggie Burger, French Fries, Soda (optional)"
    charLimit={400}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.menu_items_comma = e)}
    isValid={!formErrors.menu_items_comma}
    value={resyChannelSettings.menu_items_comma}
    onChange={v => changeResyField('menu_items_comma', v)}
  />
)

export const daysOfOperationCheckbox = ({ index, resyChannelSettings, changeResyField }) => (
  <CheckboxToggle
    key={index}
    name="days_of_operation"
    label={['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'][index]}
    value={resyChannelSettings.days_of_operation[index]}
    on={resyChannelSettings.days_of_operation[index]}
    onChange={on =>
      changeResyField('days_of_operation', [
        ...resyChannelSettings.days_of_operation.slice(0, index),
        on,
        ...resyChannelSettings.days_of_operation.slice(index + 1),
      ])
    }
    style={{
      width: 100,
      float: 'left',
    }}
  />
)

export const bookingWindowDaysField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="BOOKING WINDOW (DAYS)*"
    charLimit={4}
    inputRestriction={InputRestrictions.integer}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.booking_window_days = e)}
    isValid={!formErrors.booking_window_days}
    value={`${resyChannelSettings.booking_window_days || 0}`}
    onChange={v => changeResyField('booking_window_days', parseInt(v, 10))}
  />
)

export const partyMinField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="MIN PARTY SIZE*"
    charLimit={4}
    inputRestriction={InputRestrictions.integer}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.party_min = e)}
    isValid={!formErrors.party_min}
    value={`${resyChannelSettings.party_min || ''}`}
    onChange={v => changeResyField('party_min', parseInt(v, 10))}
  />
)

export const partyMaxField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="MAX PARTY SIZE*"
    charLimit={4}
    inputRestriction={InputRestrictions.integer}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.party_max = e)}
    isValid={!formErrors.party_max}
    value={`${resyChannelSettings.party_max || ''}`}
    onChange={v => changeResyField('party_max', parseInt(v, 10))}
  />
)

export const defaultTimeSlotDescriptionField = ({ resyChannelSettings, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="DEFAULT SLOT DESCRIPTION*"
    charLimit={30}
    inputRestriction={InputRestrictions.name}
    validator={ValidatorTypes.nameRequired}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.default_time_slot_description = e)}
    isValid={!formErrors.default_time_slot_description}
    value={resyChannelSettings.default_time_slot_description}
    onChange={v => changeResyField('default_time_slot_description', v)}
  />
)

export const priceRangeField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <DropdownArrowsPicker
    name="PRICE RANGE*"
    choices={prependEmptyOption(
      _.map(resyChannelFormOptions.PRICE_RANGE_OPTIONS, (name, value) => ({
        name,
        value,
      }))
    )}
    isLightTheme
    useOutsideLabel
    value={resyChannelSettings.price_range || ''}
    validator={ValidatorTypes.notEmpty}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.price_range = e ? e.instanceRef : null)}
    isValid={!formErrors.price_range}
    onChangeHandler={v => changeResyField('price_range', v)}
    style={{
      minWidth: 80,
      width: '100%',
    }}
  />
)

export const ticketAvgPerPersonField = ({ resyChannelSettings, resyChannelFormOptions, changeResyField, formErrors, validateFieldMap }) => (
  <TextInput
    label="TICKET AVERAGE PRICE PER PERSON"
    placeholder="(optional)"
    charLimit={12}
    inputRestriction={InputRestrictions.number}
    /* eslint-disable-next-line no-param-reassign */
    ref={e => (validateFieldMap.ticket_avg_per_person = e)}
    isValid={!formErrors.ticket_avg_per_person}
    value={`${resyChannelSettings.ticket_avg_per_person || ''}`}
    onChange={v => changeResyField('ticket_avg_per_person', v)}
  />
)
