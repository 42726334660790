import { useLocales } from '@sevenrooms/core/locales'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { statusTitle, statusColor } from '../../utils'

export function StatusBadge({ statusType = 'opportunity' }: { statusType: string }) {
  const { formatMessage } = useLocales()
  const message = statusTitle[statusType]
  const text = message ? formatMessage(message) : ''
  const color = statusColor[statusType]

  return (
    <Flex
      position="relative"
      alignSelf="flex-start"
      pt="xs"
      pr="s"
      pb="xs"
      pl="s"
      display="inline"
      borderRadius="s"
      backgroundColor={color}
    >
      <Text color="primaryBackground">{text}</Text>
    </Flex>
  )
}
