import React, { useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, VStack, SectionHeader } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeBorderRadius } from '@sevenrooms/core/ui-kit/theme'
import { BasicLink, Text } from '@sevenrooms/core/ui-kit/typography'

export const NavigationDashboardCardTestIds = {
  header: 'navigation-card-header',
  link: 'navigation-card-link',
}

export interface NavigationDashboardCardProps {
  title: string
  to: string
  reload?: boolean
  thumbnail: string
  description?: string
  badge?: string
}

export function NavigationDashboardCard({ title, description, badge, to, reload, thumbnail }: NavigationDashboardCardProps) {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <NavigationDashboardCardLink reload={reload} to={to} dataTest={NavigationDashboardCardTestIds.link}>
      <Box
        width="100%"
        backgroundColor="primaryBackground"
        borderRadius="s"
        borderWidth="s"
        borderColor="dividerLines"
        pt="m"
        pb="m"
        boxShadow={hover ? 'primary' : undefined}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <HStack alignItems="center">
          <Box ml="m">
            <ImageWrapper src={thumbnail} alt="" width="75px" minWidth="75px" minHeight="46px" maxHeight="76px" />
          </Box>
          <Box ml="lm">
            <VStack>
              <Text color="secondaryFont" fontSize="xs" textTransform="uppercase">
                {badge}
              </Text>
              <Box>
                <SectionHeader title={title} description={description} type="h3" data-test={NavigationDashboardCardTestIds.header} />
              </Box>
            </VStack>
          </Box>
          <Box ml="auto" mr="m">
            <Icon name="VMSWeb-chevron-right" size="2x" color="darkFont" />
          </Box>
        </HStack>
      </Box>
    </NavigationDashboardCardLink>
  )
}

interface NavigationDashboardCardLink {
  to: string
  reload?: boolean
  dataTest?: string
}

function NavigationDashboardCardLink({ children, reload, to, dataTest }: React.PropsWithChildren<NavigationDashboardCardLink>) {
  if (reload) {
    return (
      <NonStyledLink data-test={dataTest} href={to}>
        {children}
      </NonStyledLink>
    )
  }

  return (
    <BasicLink data-test={dataTest} to={to}>
      {children}
    </BasicLink>
  )
}

const NonStyledLink = styled.a`
  color: inherit !important;
  text-decoration: none !important;
`

const ImageWrapper = styled.img<{
  width?: string
  minWidth?: string
  minHeight?: string
  maxHeight?: string
  borderRadius?: ThemeBorderRadius
}>`
  width: ${props => props.width ?? '100%'};
  min-width: ${props => props.minWidth ?? '100%'};
  min-height: ${props => props.minHeight ?? '100%'};
  max-height: ${props => props.maxHeight ?? '100%'};

  object-fit: cover;
  object-position: top;

  ${props =>
    props.borderRadius &&
    css`
      border-radius: ${props.theme.borderRadius[props.borderRadius]};
    `}
`
