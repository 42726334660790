import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import styled from 'styled-components'
import SubNav from 'mgr/pages/shared/SubNav'
import ClientTagsLayout from 'mgr/pages/venue-group/containers/tags/ClientTags'
import ReservationTagsLayout from 'mgr/pages/venue-group/containers/tags/ReservationTags'
import ShowTags from 'mgr/pages/venue-group/containers/tags/ShowTags'
import type { VenueGroup } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'

const TagsContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 100vh;
  overflow-y: scroll;
`

export interface TagsLayoutProps {
  venueGroup: VenueGroup
}

export interface subNavItemHeaderProps {
  name: string
  isTitle: boolean
}

export interface subNavItemProps {
  name: string
  id: string
  link: string
}

export function TagsLayout({ venueGroup: { id } }: TagsLayoutProps) {
  const subNavItems: [subNavItemHeaderProps, subNavItemProps, subNavItemProps] = [
    {
      name: 'Group By',
      isTitle: true,
    },
    {
      link: `/group/${id}/tags/client`,
      name: 'Client Tags',
      id: 'client-tags',
    },
    {
      link: `/group/${id}/tags/reservation`,
      name: 'Reservation Tags',
      id: 'reservation-tags',
    },
  ]

  return (
    <TagsContainer>
      <SubNav items={subNavItems} />
      <ContentContainer>
        <ShowTags />
        <Switch>
          <Route path={subNavItems[1].link} component={ClientTagsLayout} />
          <Route path={subNavItems[2].link} component={ReservationTagsLayout} />
          <Route path="*">
            <Redirect to={subNavItems[1].link} />
          </Route>
        </Switch>
      </ContentContainer>
    </TagsContainer>
  )
}

const mapStateToProps = (state: State) => ({
  venueGroup: state.appState.venueGroup,
})

export default connect(mapStateToProps)(TagsLayout)
