import _ from 'lodash'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'
import { selectFloorplanRoomPositions } from 'mgr/pages/single-venue/dayview/selectors/FloorplanSelectors'

import { srPost } from '@sevenrooms/core/api'

export const clickTablePosition = tablePosition => ({
  type: ActionTypes.FLOORPLAN_CLICK_TABLE_POSITION,
  tablePosition,
})

export const changeSelectedFloorplanRoom = selectedFloorplanRoom => ({
  type: ActionTypes.FLOORPLAN_CHANGE_FLOORPLAN_ROOM,
  selectedFloorplanRoom,
})

export const enterTableAssignMode = actual => (dispatch, getState) => {
  const state = getState()
  const floorplanRoomPositions = selectFloorplanRoomPositions(state)
  const { tablePositions } = floorplanRoomPositions
  const selectedTablePositions = _.filter(tablePositions, tp => _.includes(actual.table_ids_list, tp.id))
  return dispatch({
    type: ActionTypes.FLOORPLAN_ENTER_TABLE_ASSIGN_MODE,
    actual,
    selectedTablePositions,
  })
}

export const closeTableCard = () => ({
  type: ActionTypes.FLOORPLAN_CLOSE_TABLE_CARD,
})

export const saveSelectedTableAssignments = () => (dispatch, getState) => {
  const store = getState()
  const venueId = store.appState.venue.urlKey
  const actualId = store.floorplanState.assignModeActual.id
  const tableIds = store.floorplanState.selectedTablePositions.map(tp => tp.id)
  dispatch(Actions.tryPostSaveResTableAssignmentAction({ venueId, actualId, tableIds }))
}

export const changeSelectedFloorplanLayout = layoutId => (dispatch, getState) => {
  dispatch(Actions.startLoading())

  const state = getState()
  const { venue } = state.appState
  const { date } = state.dayviewState
  return Promise.resolve(dispatch(tryPostSelectedFloorplanLayout(venue.id, date, layoutId)))
    .then(() => {
      window.location.reload()
    })
    .catch(() => dispatch(Actions.endLoading()))
}

export const postSelectedFloorplanLayout = (venueId, date, layoutId, errHandler) =>
  srPost(`/api-yoa/floorplan_layouts/venue_date`, {
    venue_id: venueId,
    date: date.format('YYYY-MM-DD'),
    floorplan_layout_id: layoutId,
  }).then(response => {
    if (response.status != 200 && errHandler) {
      errHandler(response)
    }
    return response.data
  })

export const postSelectedFloorplanLayoutSuccess = staticData => ({
  type: ActionTypes.POST_SELECTED_FLOORPLAN_LAYOUT_SUCCESS,
  staticData,
})

export const postSelectedFloorplanLayoutFail = () => ({ type: ActionTypes.POST_SELECTED_FLOORPLAN_LAYOUT_FAIL })

export const tryPostSelectedFloorplanLayout = (venueId, date, layoutId) => (dispatch, getState) => {
  const errHandler = () => dispatch(postSelectedFloorplanLayoutFail())
  return postSelectedFloorplanLayout(venueId, date, layoutId, errHandler).then(() => dispatch(postSelectedFloorplanLayoutSuccess()))
}
