import styled from 'styled-components'
import { useOverflowed } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import type { MenuItem as OrderingMenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface MenuItemProps {
  venue: Venue
  item: OrderingMenuItem
  isVisible?: boolean
  itemInfoWidth?: string
}

export function MenuItem({ venue, item, itemInfoWidth, isVisible = true }: MenuItemProps) {
  const imageURL =
    item.imageUrls?.small || item.imageUrls?.externalUrls?.[0] || `${window.globalInit.mediaUrl}images/products/img_placeholder.jpg`
  const [isNameOverflowed, nameRef] = useOverflowed(item, isVisible)
  const [isDescriptionOverflowed, descriptionRef] = useOverflowed(item, isVisible)
  return (
    <MenuItemContent justifyContent="space-between">
      <HStack alignItems="center">
        <ImageContainer>
          <img src={imageURL} alt="" />
        </ImageContainer>
        <MenuItemInfo justifyContent="center" itemInfoWidth={itemInfoWidth} pl="m">
          <Tooltip content={<>{item.name}</>} show={isNameOverflowed} displayAction="hover">
            <Name type="div" ref={nameRef}>
              {item.name}
            </Name>
          </Tooltip>
          <Tooltip content={<>{item.description}</>} show={isDescriptionOverflowed} displayAction="hover">
            <Description type="div" ref={descriptionRef} color="secondaryFont">
              {item.description || '-'}
            </Description>
          </Tooltip>
        </MenuItemInfo>
      </HStack>
      <HStack alignItems="center">
        <PriceContainer pl="xs">
          <Text>
            {venue.currencySymbol}
            {item.price.toFixed(2)}
          </Text>
        </PriceContainer>
      </HStack>
    </MenuItemContent>
  )
}

const MenuItemContent = styled(HStack)`
  width: 100%;
`

const ImageContainer = styled(Box)`
  width: 38px;
  min-width: 38px;
  height: 38px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const MenuItemInfo = styled(VStack)<{ itemInfoWidth?: string }>`
  max-width: ${props => props.itemInfoWidth || '252px'};
`

const MenuItemInfoText = styled(Text)`
  ${props => props.theme.ellipsis};

  &&::after {
    content: '';
    display: block;
  }
`

const Name = styled(MenuItemInfoText)`
  ${props => props.theme.twoLineEllipsis};
`

const Description = styled(MenuItemInfoText)``

const PriceContainer = styled(Box)`
  width: 62px;
  max-width: 62px;
  text-align: right;
  ${props => props.theme.ellipsis};
`
