import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { onChangeVenueExitEditFlow } from 'mgr/lib/actions/DirtyStateUnloader'
import DirtyStateUnloader from 'mgr/lib/components/DirtyStateUnloader'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import OldTextInput from 'mgr/lib/forms/TextInput'
import * as OrderingSitesActions from 'mgr/pages/single-venue/settings/actions/ordering/OrderingSitesActions'
import EditSiteToggleableAmount from 'mgr/pages/single-venue/settings/components/ordering/EditSite/EditSiteToggleableAmount'
import OrderSiteStatus from 'mgr/pages/single-venue/settings/components/ordering/EditSite/OrderSiteStatus'
import { PosTablesField } from 'mgr/pages/single-venue/settings/components/ordering/EditSite/PosTablesField'
import EditSiteAllowToScheduleExample from 'mgr/pages/single-venue/settings/components/ordering/EditSiteAllowToScheduleExample'
import EditSiteNowOrdersExample from 'mgr/pages/single-venue/settings/components/ordering/EditSiteNowOrdersExample'
import { EditingLayoutFooter } from 'mgr/pages/single-venue/settings/components/shared/layout/EditingLayoutFooter'
import {
  selectAvailableMenuOptions,
  selectCustomOrderFieldOptions,
  selectFulfillmentMethodOptions,
  selectOrderAheadTimeDisplayUnitOptions,
  selectSelectedFulfillmentMethodData,
  selectPosTableOptions,
  selectSelectedPosTables,
} from 'mgr/pages/single-venue/settings/selectors/orderingSite'
import { StyledVmsIconS, VmsIcons } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import FormField from 'svr/component-lib/Generic/Form/FormField'
import GroupPanel from 'svr/component-lib/Generic/Panels/GroupPanel/GroupPanel'
import GroupPanelRow from 'svr/component-lib/Generic/Panels/GroupPanel/GroupPanelRow'
import GroupPanelRowCell from 'svr/component-lib/Generic/Panels/GroupPanel/GroupPanelRowCell'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import TinyWidthInput from 'svr/component-lib/Generic/TextInputs/TinyWidthInput'
import Tooltip from 'svr/component-lib/Generic/Tooltip'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import EditingLayout from 'svr/component-lib/Manager/Layout/EditingLayout'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import CategoryMultiSelect from 'svr/component-lib/Manager/MultiSelect/CategoryMultiSelect'
import SectionDropdown from 'svr/component-lib/Manager/Section'
import { FULFILLMENT_METHOD, FULFILLMENT_METHOD_TITLE } from 'svr/constants'
import { getMenuUrl } from 'widget/universal/utils/ordering/utilfunctions'
import { Button, Radio, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'

const Footnote = styled.div`
  font-size: 12px;
  color: #565c63;
  & + & {
    padding-top: 12px;
  }
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 42px;
  align-items: center;
`

const PickupInstructionIcon = styled.div`
  padding-left: 10px;
`

const TableSelectContainer = styled.div`
  margin-top: ${props => props.theme.gutter.medium};
  display: flex;
  flex-direction: row;
  width: 550px;
`

const StyledDropdownArrowsPicker = ({ style, ...args }) => (
  <DropdownArrowsPicker
    style={{
      width: '100%',
      minWidth: '100%',
      color: '#666666',
      ...style,
    }}
    borderAreaStyle={{
      border: '1px solid #ECECEC',
      height: '40px',
      minHeight: '40px',
    }}
    height={40}
    isLightTheme
    {...args}
  />
)

const _updateRequiredTextFields = (fieldName, setValidFunction, updateFieldFunction, inputValidationRequired, value) => {
  const noSpecialCharsRegEx = /^[A-Za-z0-9-_]*$/
  if (inputValidationRequired && !value.match(noSpecialCharsRegEx)) {
    return
  }
  updateFieldFunction(fieldName, value)
  setValidFunction(!_.isEmpty(value))
}

const _updateIsActive = (isActive, updateFieldFunction, setShowActiveModal, isNewSite) => {
  updateFieldFunction('is_enabled', isActive)
  setShowActiveModal(isActive === false && !isNewSite)
}

const _cancelButtonClick = (history, cancelPath) => {
  history.push(cancelPath)
}

const tooltipBannerMessage =
  'This is not required, but if you wish to communicate something to your guests when they visit your ordering site, ' +
  'this message will show to guests near the top of the ordering site.'

const posTableMultiselectOptions = [
  {
    value: true,
    label: 'This ordering site is for many/all of my tables and I want to require the Guest to enter their Table Number on checkout',
  },
  {
    value: false,
    label: 'This ordering site is for one table and I do NOT need the Guest to enter Table Number on checkout',
  },
]

const roomNumberFillMethodSelectOptions = [
  {
    value: false,
    label: 'Guest will enter their own room number',
  },
  {
    value: true,
    label: 'Guest will pick from the list of room number',
    info: (
      <>
        Room numbers are created in Settings {'>'} Ordering {'>'} Room Numbers
      </>
    ),
  },
]

const EditSite = ({
  match,
  routes,
  history,
  venue,
  venues,
  isLoading,
  isTableSyncLoading,
  isSaving,
  isDirty,
  name,
  urlKey,
  isActive,
  selectedFulfillmentMethod,
  pickupMin,
  pickupMax,
  onChangeVenue,
  deliveryMin,
  deliveryMax,
  roomServiceMin,
  roomServiceMax,
  displaySettings,
  bannerMessage,
  pickupInstructions,
  deliveryInstructions,
  roomServiceInstructions,
  timeSlotBuffer,
  timeSlotInterval,
  allowOrderAhead,
  customFieldsEnabled,
  customFieldsRequired,
  deliveryCustomFieldsEnabled,
  deliveryCustomFieldsRequired,
  roomServiceCustomFieldsEnabled,
  roomServiceCustomFieldsRequired,
  maxOrderAheadTime,
  maxOrderAheadTimeDisplayUnit,
  posTables,
  selectedPosTables,
  posTableOptions,
  updateSelectedPosTables,
  removeSelectedPosTable,
  currencySymbol,
  mediaUrl,
  availableMenuOptions,
  customOrderFieldOptions,
  fulfillmentMethodOptions,
  selectedFulfillmentMethodData,
  orderAheadTimeDisplayUnitOptions,
  venueSupportsPosTableSync,
  tryGetOrderingSite,
  updateField,
  toggleDisplayField,
  toggleField,
  updateMenus,
  trySaveOrderingSite,
  updateFulfillmentOption,
  tryGetOrderingSiteSyncedPosTables,
  isPosTableMultiSelect,
  isGuestWillPickUpRoomNumberFromList,
}) => {
  const dataLoaded = !_.isEmpty(name)
  const isNewSite = _.isNil(match.params.id)
  const orderingSiteId = !isNewSite ? match.params.id : 'new'
  const posTableSelectValid =
    !displaySettings.show_table_select || !posTableOptions?.length || !isPosTableMultiSelect || (isPosTableMultiSelect && posTables.length)
  const formIsValid = !_.isEmpty(name) && !_.isEmpty(urlKey) && posTableSelectValid
  const editCompletePath = routes[0].route
  const saveButtonDisabled = isLoading || !formIsValid
  const [showActiveModal, setShowActiveModal] = useState(false)
  const [nameValid, setNameValid] = useState(true)
  const [urlKeyValid, setUrlKeyValid] = useState(true)

  useEffect(() => {
    if (!isLoading && !isSaving) {
      tryGetOrderingSite(venue.id, orderingSiteId)
    }
  }, [match])

  const orderAheadValue =
    maxOrderAheadTimeDisplayUnit ||
    (orderAheadTimeDisplayUnitOptions && orderAheadTimeDisplayUnitOptions[0] ? orderAheadTimeDisplayUnitOptions[0].value : 0)

  return (
    <DirtyStateUnloader isDirty={isDirty} modalQuestionContext="this ordering site">
      <ContentLayout
        title={isNewSite ? 'Create New Ordering Site' : dataLoaded ? `Edit Ordering Site: ${name}` : 'Loading...'}
        venue={venue}
        venues={venues}
        history={history}
        routes={routes}
        showSpinner={isLoading}
        spinnerImgUrl={mediaUrl}
        onChangeVenue={onChangeVenue}
        overrideChangeVenue
      >
        <OrderSiteStatus
          isLoading={isLoading}
          isActive={isActive}
          updateIsActive={() => _updateIsActive(!isActive, updateField, setShowActiveModal, isNewSite)}
        />

        {showActiveModal && (
          <MessageBox
            handleActionClick={setShowActiveModal.bind(this, false)}
            handleCloseClick={() => _updateIsActive(!isActive, updateField, setShowActiveModal, isNewSite)}
            dialogType={MessageBox.DialogType.INFO}
            header="Deactivate Site?"
            actionButtonText="Switch Off"
            explanation={`If you switch this order site off, this site's URL (${window.location.origin}/${getMenuUrl(
              venue.urlKey,
              urlKey
            )}) will be rerouted to 404 error page.`}
            customBodyStyles={{ width: 500 }}
            data-test="confirm-deactivate-site-modal"
          />
        )}

        <EditingLayout>
          <SectionDropdown headerText="GENERAL" data-test="sr-section-general">
            <FormField>
              <ActiveLabelTextInput
                placeholderLabel="Name"
                isValid={nameValid}
                value={name}
                onChange={val => _updateRequiredTextFields('name', setNameValid, updateField, false, val)}
                dataTest="site-name-input"
              />
            </FormField>
            <FormField>
              <ActiveLabelTextInput
                placeholderLabel="URL Code"
                isValid={urlKeyValid}
                value={urlKey}
                onChange={val => _updateRequiredTextFields('url_key', setUrlKeyValid, updateField, true, val)}
                dataTest="url-key-input"
              />
            </FormField>
            <LabelContainer>
              <div>Banner Message</div>
              <PickupInstructionIcon>
                <Tooltip content={tooltipBannerMessage}>
                  <StyledVmsIconS>{VmsIcons.Info}</StyledVmsIconS>
                </Tooltip>
              </PickupInstructionIcon>
            </LabelContainer>
            <OldTextInput
              placeholder="Type your Banner Message here..."
              charLimit={5000}
              isMultiLine
              forceIndent
              minRows={10}
              maxRows={10}
              value={bannerMessage}
              onChange={val => updateField('banner_message', val)}
              testId="banner-message-multiline-input"
            />
          </SectionDropdown>

          <SectionDropdown headerText="MENUS">
            <CategoryMultiSelect options={availableMenuOptions} onSelect={updateMenus} width={600} dataTest="menu-multi-select-list" />
          </SectionDropdown>

          <SectionDropdown headerText="FULFILLMENT METHODS">
            {!_.isEmpty(fulfillmentMethodOptions) && (
              <VStack spacing="s" data-test="fulfillment-method-radio-list">
                {fulfillmentMethodOptions.map(fulfillmentMethodOption => (
                  <Box key={fulfillmentMethodOption.value}>
                    <Radio
                      data-test={fulfillmentMethodOption.value}
                      name={`${fulfillmentMethodOption.value}-fulfillmentMethods`}
                      value={fulfillmentMethodOption.value}
                      checked={selectedFulfillmentMethod === fulfillmentMethodOption.value}
                      onChange={() => updateFulfillmentOption(fulfillmentMethodOption.value)}
                    >
                      {fulfillmentMethodOption.label}
                    </Radio>
                    {selectedFulfillmentMethod === fulfillmentMethodOption.value &&
                      (fulfillmentMethodOption.value === FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE ||
                        fulfillmentMethodOption.value === FULFILLMENT_METHOD.ROOM_SERVICE) && (
                        <Box mt="m" ml="m">
                          <RadioGroup
                            choices={roomNumberFillMethodSelectOptions}
                            selected={isGuestWillPickUpRoomNumberFromList}
                            onChange={choice => updateField('is_guest_will_pick_up_room_number_from_list', choice.value)}
                            data-test="fulfillment-room-number-fill-method-radio-list"
                          />
                        </Box>
                      )}
                  </Box>
                ))}
              </VStack>
            )}
            {displaySettings.show_table_select && (
              <Box mt="m" ml="m">
                <RadioGroup
                  selected={isPosTableMultiSelect}
                  choices={posTableMultiselectOptions}
                  onChange={choice => updateField('is_pos_table_multiselect', choice.value)}
                  data-test="on-prem-table-mode"
                />
                <TableSelectContainer>
                  {!!posTableOptions?.length && isPosTableMultiSelect && (
                    <PosTablesField
                      posTables={posTables}
                      posTableOptions={posTableOptions}
                      selectedPosTables={selectedPosTables}
                      updateSelectedPosTables={updateSelectedPosTables}
                      removeSelectedPosTable={removeSelectedPosTable}
                    />
                  )}
                  {!!posTableOptions?.length && !isPosTableMultiSelect && (
                    <StyledDropdownArrowsPicker
                      choices={[
                        {
                          value: null,
                          name: '',
                        },
                        ...posTableOptions,
                      ]}
                      value={selectedPosTables.length ? selectedPosTables[0] : null}
                      onChangeHandler={val => updateSelectedPosTables(val ? [val] : [])}
                      style={{
                        width: '200px',
                        minWidth: '200px',
                      }}
                      name="POS Table"
                      isLightTheme
                      testId="pos-table-dropdown"
                      disabled={isTableSyncLoading}
                      displayOverride={isTableSyncLoading ? 'Syncing...' : null}
                    />
                  )}
                  {venueSupportsPosTableSync && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        tryGetOrderingSiteSyncedPosTables(venue.id)
                      }}
                      data-test="sync-table-button"
                      disabled={isTableSyncLoading}
                    >
                      Sync POS Tables
                    </Button>
                  )}
                </TableSelectContainer>
              </Box>
            )}
          </SectionDropdown>

          <SectionDropdown headerText="CHECKOUT SETTINGS">
            {displaySettings.show_pickup_section && (
              <GroupPanel data-test="pickup">
                <GroupPanel.Title>{selectedFulfillmentMethodData.onSiteLabel}</GroupPanel.Title>
                <div>
                  <EditSiteToggleableAmount
                    orderAmount={pickupMin}
                    showAmount={displaySettings.show_pickup_min}
                    label="Minimum Subtotal Amount"
                    onChangeAmount={val => updateField('subtotal_min', val)}
                    onClickToggle={() => toggleDisplayField('show_pickup_min')}
                    currencySymbol={currencySymbol}
                    dataTest="pickup-min"
                  />

                  <EditSiteToggleableAmount
                    orderAmount={pickupMax}
                    showAmount={displaySettings.show_pickup_max}
                    label="Maximum Subtotal Amount"
                    onChangeAmount={val => updateField('subtotal_max', val)}
                    onClickToggle={() => toggleDisplayField('show_pickup_max')}
                    currencySymbol={currencySymbol}
                    dataTest="pickup-max"
                  />
                </div>

                <LabelContainer>
                  <div>Post-Checkout Instructions</div>
                  <PickupInstructionIcon>
                    <Tooltip content={selectedFulfillmentMethodData.onSiteInstructionTooltip}>
                      <StyledVmsIconS>{VmsIcons.Info}</StyledVmsIconS>
                    </Tooltip>
                  </PickupInstructionIcon>
                </LabelContainer>
                <OldTextInput
                  placeholder="Type your instructions here..."
                  charLimit={5000}
                  isMultiLine
                  forceIndent
                  minRows={10}
                  maxRows={10}
                  value={pickupInstructions}
                  onChange={val => updateField('checkout_message', val)}
                  testId="pickup-instructions-multiline-input"
                />
                {customOrderFieldOptions.length > 0 && (
                  <>
                    <LabelContainer>Custom Fields</LabelContainer>
                    <FormField>
                      <StyledDropdownArrowsPicker
                        choices={customOrderFieldOptions}
                        values={customFieldsEnabled}
                        onChangeHandler={val => {
                          updateField('custom_fields_enabled', val)
                          updateField(
                            'custom_fields_required',
                            customFieldsRequired.filter(cf => _.includes(val, cf))
                          )
                        }}
                        name="Enabled Custom Fields"
                        isMultiSelect
                        testId="enable-custom-fields-dropdown"
                      />
                    </FormField>
                    <FormField>
                      <StyledDropdownArrowsPicker
                        choices={customOrderFieldOptions.filter(o => _.includes(customFieldsEnabled, o.value))}
                        values={customFieldsRequired}
                        onChangeHandler={val => updateField('custom_fields_required', val)}
                        name="Required Custom Fields"
                        isMultiSelect
                        testId="required-custom-fields-dropdown"
                      />
                    </FormField>
                  </>
                )}
              </GroupPanel>
            )}

            {displaySettings.show_delivery_section && (
              <GroupPanel data-test="delivery">
                <GroupPanel.Title>Delivery</GroupPanel.Title>
                <div>
                  <EditSiteToggleableAmount
                    orderAmount={deliveryMin}
                    showAmount={displaySettings.show_delivery_min}
                    label="Minimum Subtotal Amount"
                    onChangeAmount={val => updateField('delivery_subtotal_min', val)}
                    onClickToggle={() => toggleDisplayField('show_delivery_min')}
                    currencySymbol={currencySymbol}
                    dataTest="delivery-min"
                  />

                  <EditSiteToggleableAmount
                    orderAmount={deliveryMax}
                    showAmount={displaySettings.show_delivery_max}
                    label="Maximum Subtotal Amount"
                    onChangeAmount={val => updateField('delivery_subtotal_max', val)}
                    onClickToggle={() => toggleDisplayField('show_delivery_max')}
                    currencySymbol={currencySymbol}
                    dataTest="delivery-max"
                  />
                  <LabelContainer>
                    <div>Post-Checkout Instructions</div>
                  </LabelContainer>
                  <OldTextInput
                    placeholder="Type your instructions here..."
                    charLimit={5000}
                    isMultiLine
                    forceIndent
                    minRows={10}
                    maxRows={10}
                    value={deliveryInstructions}
                    onChange={val => updateField('delivery_checkout_message', val)}
                    testId="delivery-instructions-multiline-input"
                  />
                  {customOrderFieldOptions.length > 0 && (
                    <>
                      <LabelContainer>Custom Fields</LabelContainer>
                      <FormField>
                        <StyledDropdownArrowsPicker
                          choices={customOrderFieldOptions}
                          values={deliveryCustomFieldsEnabled}
                          onChangeHandler={val => {
                            updateField('delivery_custom_fields_enabled', val)
                            updateField(
                              'delivery_custom_fields_required',
                              deliveryCustomFieldsRequired.filter(cf => _.includes(val, cf))
                            )
                          }}
                          name="Enabled Custom Fields"
                          isMultiSelect
                          testId="enable-custom-fields-dropdown"
                        />
                      </FormField>
                      <FormField>
                        <StyledDropdownArrowsPicker
                          choices={customOrderFieldOptions.filter(o => _.includes(deliveryCustomFieldsEnabled, o.value))}
                          values={deliveryCustomFieldsRequired}
                          onChangeHandler={val => updateField('delivery_custom_fields_required', val)}
                          name="Required Custom Fields"
                          isMultiSelect
                          testId="required-custom-fields-dropdown"
                        />
                      </FormField>
                    </>
                  )}
                </div>
              </GroupPanel>
            )}

            {displaySettings.show_room_service_section && (
              <GroupPanel data-test="room-service">
                <GroupPanel.Title>
                  {selectedFulfillmentMethod === FULFILLMENT_METHOD.ROOM_SERVICE
                    ? FULFILLMENT_METHOD_TITLE[FULFILLMENT_METHOD.ROOM_SERVICE]
                    : 'Room Service'}
                </GroupPanel.Title>
                <div>
                  <EditSiteToggleableAmount
                    orderAmount={roomServiceMin}
                    showAmount={displaySettings.show_room_service_min}
                    label="Minimum Subtotal Amount"
                    onChangeAmount={val => updateField('room_service_subtotal_min', val)}
                    onClickToggle={() => toggleDisplayField('show_room_service_min')}
                    currencySymbol={currencySymbol}
                    dataTest="room-service-min"
                  />

                  <EditSiteToggleableAmount
                    orderAmount={roomServiceMax}
                    showAmount={displaySettings.show_room_service_max}
                    label="Maximum Subtotal Amount"
                    onChangeAmount={val => updateField('room_service_subtotal_max', val)}
                    onClickToggle={() => toggleDisplayField('show_room_service_max')}
                    currencySymbol={currencySymbol}
                    dataTest="room-service-max"
                  />
                  <LabelContainer>
                    <div>Post-Checkout Instructions</div>
                  </LabelContainer>
                  <OldTextInput
                    placeholder="Type your instructions here..."
                    charLimit={5000}
                    isMultiLine
                    forceIndent
                    minRows={10}
                    maxRows={10}
                    value={roomServiceInstructions}
                    onChange={val => updateField('room_service_checkout_message', val)}
                    testId="room-service-instructions-multiline-input"
                  />
                  {customOrderFieldOptions.length > 0 && (
                    <>
                      <LabelContainer>Custom Fields</LabelContainer>
                      <FormField>
                        <StyledDropdownArrowsPicker
                          choices={customOrderFieldOptions}
                          values={roomServiceCustomFieldsEnabled}
                          onChangeHandler={val => {
                            updateField('room_service_custom_fields_enabled', val)
                            updateField(
                              'room_service_custom_fields_required',
                              roomServiceCustomFieldsRequired.filter(cf => _.includes(val, cf))
                            )
                          }}
                          name="Enabled Custom Fields"
                          isMultiSelect
                          testId="enable-custom-fields-dropdown"
                        />
                      </FormField>
                      <FormField>
                        <StyledDropdownArrowsPicker
                          choices={customOrderFieldOptions.filter(o => _.includes(roomServiceCustomFieldsEnabled, o.value))}
                          values={roomServiceCustomFieldsRequired}
                          onChangeHandler={val => updateField('room_service_custom_fields_required', val)}
                          name="Required Custom Fields"
                          isMultiSelect
                          testId="required-custom-fields-dropdown"
                        />
                      </FormField>
                    </>
                  )}
                </div>
              </GroupPanel>
            )}
          </SectionDropdown>

          {displaySettings.show_timeslot_settings && (
            <SectionDropdown headerText="TIMESLOT SETTINGS">
              <GroupPanel data-test="now-orders">
                <GroupPanel.Title>For &quot;Now&quot; Orders</GroupPanel.Title>
                <EditSiteNowOrdersExample timeSlotBuffer={timeSlotBuffer} />
                <GroupPanelRow title="Time Slot Buffer">
                  <GroupPanelRowCell>
                    <TinyWidthInput
                      charLimit={10}
                      value={timeSlotBuffer}
                      onChange={val => updateField('time_slot_width_mins', val)}
                      dataTest="timeslot-buffer-input"
                      inputType={TextInput.inputTypes.POSITIVE_INT}
                    />
                  </GroupPanelRowCell>
                  <GroupPanelRowCell>min</GroupPanelRowCell>
                </GroupPanelRow>
                <div>
                  <Footnote>
                    *To change Order Prep time, please go to{' '}
                    <Link to={`/manager2/${venue.urlKey}/settings/ordering/menus`}>Menu Management</Link>.
                  </Footnote>
                  <Footnote>
                    *Delivery time shown here is an arbituary 10 min but the actual delivery time is calculated based on the address the
                    guests inputs.
                  </Footnote>
                </div>
              </GroupPanel>

              <GroupPanel data-test="future-orders">
                <GroupPanel.Header>
                  <VmsCheckbox
                    field=""
                    onClick={() => toggleField('allow_order_ahead')}
                    isSelected={allowOrderAhead}
                    label="Allow to schedule for future orders"
                    selectedIcon={VmsIcons.CheckBoxSelected}
                    unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                    tooltipIcon={VmsIcons.Info}
                    dataTest="allow-order-ahead-checkbox"
                  />
                </GroupPanel.Header>
                <EditSiteAllowToScheduleExample
                  timeSlotBuffer={timeSlotBuffer}
                  timeSlotInterval={timeSlotInterval}
                  isAllowOrderAhead={allowOrderAhead}
                  maxOrderAheadTime={maxOrderAheadTime}
                  orderAheadValue={orderAheadValue}
                />
                {allowOrderAhead && (
                  <GroupPanelRow title="Time Slot Interval">
                    <GroupPanelRowCell>
                      <TinyWidthInput
                        charLimit={10}
                        value={timeSlotInterval}
                        onChange={val => updateField('time_slot_spacing_mins', val)}
                        inputType={TextInput.inputTypes.POSITIVE_INT}
                        dataTest="timeslot-interval-input"
                      />
                    </GroupPanelRowCell>
                    <GroupPanelRowCell>min</GroupPanelRowCell>
                  </GroupPanelRow>
                )}
                {allowOrderAhead && (
                  <GroupPanelRow title="How far in advance do you want people to order?">
                    <GroupPanelRowCell>
                      <TinyWidthInput
                        charLimit={10}
                        value={maxOrderAheadTime}
                        onChange={val => updateField('max_order_ahead_time', val)}
                        inputType={TextInput.inputTypes.POSITIVE_INT}
                        max="90"
                        dataTest="max-order-ahead-time-input"
                      />
                    </GroupPanelRowCell>
                    <GroupPanelRowCell>
                      <StyledDropdownArrowsPicker
                        choices={orderAheadTimeDisplayUnitOptions}
                        value={orderAheadValue}
                        onChangeHandler={val => updateField('max_order_ahead_time_display_unit', val)}
                        style={{
                          width: '75px',
                          minWidth: '75px',
                        }}
                        noHeader
                        testId="max-order-ahead-time-display-unit-dropdown"
                      />
                    </GroupPanelRowCell>
                  </GroupPanelRow>
                )}
              </GroupPanel>
            </SectionDropdown>
          )}
        </EditingLayout>

        <EditingLayoutFooter data-test="edit-site-buttons-bar">
          <Button
            variant="primary"
            disabled={saveButtonDisabled}
            onClick={() => {
              if (saveButtonDisabled) {
                return
              }
              trySaveOrderingSite(orderingSiteId, history, editCompletePath)
            }}
            data-test="save-button"
          >
            Save
          </Button>
          <Button variant="tertiary" onClick={() => _cancelButtonClick(history, editCompletePath)} data-test="cancel-button">
            Cancel
          </Button>
        </EditingLayoutFooter>
      </ContentLayout>
    </DirtyStateUnloader>
  )
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  const orderingSite = state.orderingSite.editState
  return {
    venue,
    venues,
    currencySymbol: venue.currencySymbol,
    mediaUrl: state.appState.mediaUrl,
    isLoading: state.orderingSite.isLoading,
    isTableSyncLoading: state.orderingSite.isTableSyncLoading,
    isSaving: state.orderingSite.isSaving,
    isDirty: state.orderingSite.isDirty,
    name: orderingSite.name,
    urlKey: orderingSite.url_key,
    isActive: orderingSite.is_enabled,
    selectedFulfillmentMethod: orderingSite.fulfillment_method,
    pickupMin: orderingSite.subtotal_min,
    pickupMax: orderingSite.subtotal_max,
    deliveryMin: orderingSite.delivery_subtotal_min,
    deliveryMax: orderingSite.delivery_subtotal_max,
    roomServiceMin: orderingSite.room_service_subtotal_min,
    roomServiceMax: orderingSite.room_service_subtotal_max,
    displaySettings: orderingSite.displaySettings,
    pickupInstructions: orderingSite.checkout_message,
    deliveryInstructions: orderingSite.delivery_checkout_message,
    roomServiceInstructions: orderingSite.room_service_checkout_message,
    bannerMessage: orderingSite.banner_message,
    timeSlotBuffer: orderingSite.time_slot_width_mins,
    timeSlotInterval: orderingSite.time_slot_spacing_mins,
    allowOrderAhead: orderingSite.allow_order_ahead,
    maxOrderAheadTime: orderingSite.max_order_ahead_time,
    maxOrderAheadTimeDisplayUnit: orderingSite.max_order_ahead_time_display_unit,
    customFieldsEnabled: orderingSite.custom_fields_enabled,
    customFieldsRequired: orderingSite.custom_fields_required,
    deliveryCustomFieldsEnabled: orderingSite.delivery_custom_fields_enabled,
    deliveryCustomFieldsRequired: orderingSite.delivery_custom_fields_required,
    roomServiceCustomFieldsEnabled: orderingSite.room_service_custom_fields_enabled,
    roomServiceCustomFieldsRequired: orderingSite.room_service_custom_fields_required,
    posTables: orderingSite.pos_tables,
    selectedPosTables: selectSelectedPosTables(state),
    posTableOptions: selectPosTableOptions(state),
    isPosTableMultiSelect: orderingSite.is_pos_table_multiselect,
    isGuestWillPickUpRoomNumberFromList: orderingSite.is_guest_will_pick_up_room_number_from_list,
    availableMenuOptions: selectAvailableMenuOptions(state),
    customOrderFieldOptions: selectCustomOrderFieldOptions(state),
    fulfillmentMethodOptions: selectFulfillmentMethodOptions(state),
    selectedFulfillmentMethodData: selectSelectedFulfillmentMethodData(state),
    orderAheadTimeDisplayUnitOptions: selectOrderAheadTimeDisplayUnitOptions(state),
    venueSupportsPosTableSync: state.orderingSite.venueSupportsPosTableSync,
  }
}

const mapDispatchToProps = {
  onChangeVenue: onChangeVenueExitEditFlow,
  tryGetOrderingSite: OrderingSitesActions.tryGetOrderingSite,
  updateField: OrderingSitesActions.updateField,
  toggleDisplayField: OrderingSitesActions.toggleDisplayField,
  toggleField: OrderingSitesActions.toggleField,
  updateMenus: OrderingSitesActions.updateMenus,
  trySaveOrderingSite: OrderingSitesActions.trySaveOrderingSite,
  updateFulfillmentOption: OrderingSitesActions.updateFulfillmentOption,
  tryGetOrderingSiteSyncedPosTables: OrderingSitesActions.tryGetOrderingSiteSyncedPosTables,
  updateSelectedPosTables: OrderingSitesActions.updateSelectedPosTables,
  removeSelectedPosTable: OrderingSitesActions.removeSelectedPosTable,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSite)
