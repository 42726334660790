import { srGet, srPostJson, throwFetchError } from '@sevenrooms/core/api'

export const fetchDeliveryIntegrations = ({ venueId }) =>
  srGet(`/api-yoa/ordering/delivery_integrations/${venueId}`, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(throwFetchError)

const postDeliveryIntegrationAction = ({ action, venueId, deliveryIntegrationSettings }) => {
  const path = `/api-yoa/ordering/delivery_integrations/${venueId}`
  return srPostJson(path, {
    action,
    ...deliveryIntegrationSettings,
  }).then(throwFetchError)
}

export const postEnableDeliveryIntegration = args => postDeliveryIntegrationAction({ action: 'enable', ...args })
export const postDisableDeliveryIntegration = args => postDeliveryIntegrationAction({ action: 'disable', ...args })
