import { srGet, srPut, throwFetchError } from '@sevenrooms/core/api'
import type { CheckoutSettings } from 'mgr/pages/single-venue/settings/types/ordering/Checkout.types'

export const fetchOrderingCheckoutSettings = (venueId: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  srGet<any>(
    `/api-yoa/ordering/checkout_settings`,
    { venue_id: venueId },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).then(throwFetchError)

export const saveOrderingCheckoutSettings = async ({
  venueId,
  checkoutSettings,
  posFieldsDict = {},
}: {
  venueId: string
  checkoutSettings: CheckoutSettings
  posFieldsDict: Record<string, string>
}) => {
  const path = `/api-yoa/ordering/checkout_settings`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = await srPut<any>(path, {
    venue_id: venueId,
    checkout_settings: JSON.stringify(checkoutSettings),
    pos_fields: JSON.stringify(posFieldsDict),
  })
  return throwFetchError(result)
}
