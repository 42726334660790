import _ from 'lodash'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { validatorFuncs, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import Caret from 'svr/component-lib/Generic/Symbols/Caret'

const OuterBody = styled.div`
  width: 100%;
  min-width: 154px;
  min-height: ${props => props.height}px;
  text-align: ${props => props.textAlign};
  ${props => props.theme.clearFix};
`

const BorderedArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: ${props => props.height + 2}px;
  height: ${props => props.height + 2}px;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border: ${props => (props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark' ? 2 : 1)}px solid
    ${props =>
      props.isValid
        ? props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
          ? props.customStyle.colorLines
          : props.borderColor
        : props.theme.error};
  border-radius: 4px;
  background-color: ${props =>
    props.disabled
      ? props.theme.color.white
      : props.customStyle && props.customStyle.widgetTemplateTheme.toLowerCase() === 'dark'
      ? props.customStyle.colorCellBackground
      : props.theme.background};
  box-shadow: ${props => (props.isOpen ? '0 2px 4px 0 rgba(0,0,0,0.5)' : 'none')};

  :hover {
    border-color: ${props =>
      props.disabled ? (props.customStyle ? props.customStyle.colorLines : props.borderColor) : props.hoverBorderColor};
    transition: border-color 0.2s ease-out;
  }

  ${props => props.theme.clearFix};
`

// Fix for #3 here: https://github.com/philipwalton/flexbugs
// Since BorderedArea has min-height: 44px
const BorderedAreaFlexIEFix = styled.div`
  order: 0;
  width: 0;
  height: ${props => props.height + 2}px;
`

const SelectedItemDisplay = styled.div`
  order: 1;
  flex-grow: 1;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  width: 85%;
  color: ${props =>
    props.hasSelectedValues && !props.disabled
      ? props.customStyle
        ? props.customStyle.fontsColorPrimary
        : props.theme.navigationDark
      : props.theme.darkGrey};
  ${props => props.theme.ellipsis};
`

const GroupContainer = styled.div`
  padding: ${props => props.theme.padding.medium};
  border-bottom: ${props => (props.isLast ? '0px' : '1px')} solid #dedede;
`
const GroupLabelWrapper = styled.div``
const GroupSearchResults = styled.div`
  color: #6a6a6a;
  font-style: italic;
  padding-top: ${props => props.theme.padding.small};
`
const GroupLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  color: #6a6a6a;
  padding-left: ${props => (props.overridePadding ? props.overridePadding : props.theme.padding.small)};
`
const GroupDisplayWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`
const GroupDisplayText = styled.span`
  padding-left: ${props => props.theme.padding.small};
`
const CommaText = styled.span`
  padding-right: ${props => props.theme.padding.small};
`
const CheckboxWrapper = styled.div`
  padding-top: ${props => props.theme.padding.small};
`

const Icon = styled(StyledVmsIconXS)`
  pointer-events: none;
  display: inline-block;
  color: #6a6a6a;
`

const DropdownRelative = styled.div`
  position: relative;
  top: 2px;
  right: ${props => (props.textAlign === 'right' ? '0' : 'auto')};
`

const DropdownAbsolute = styled.div`
  position: absolute;
  width: 100%;
  right: ${props => (props.textAlign === 'right' ? '0' : 'auto')};
`

const DropUpAbsolute = styled(DropdownAbsolute)`
  bottom: 0;
`

const OptionsContainer = styled.div`
  position: relative;
  border: 1px solid ${props => (props.customStyle ? props.customStyle.colorLines : props.borderColor)};
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  left: 0;
  width: 100%;
  max-height: 332px;
  overflow-y: auto;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
  z-index: 501;
  border-radius: 0 0 4px 4px;
`

const DropDown = styled.div`
  width: 250px;
  top: 5px;
  left: -1px;
  position: relative;
  z-index: 501;
`

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SearchInput = styled.input`
  padding-left: ${props => props.theme.padding.small};
  border: none;
  outline: none;
  width: 100%;
  display: ${props => (props.isVisible ? 'block' : 'none')};
  z-index: 501;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.theme.darkGrey};
    font-size: 14px;
  }

  :-ms-input-placeholder {
    color: ${props => props.theme.darkGrey};
    font-size: 14px;
  }
`

const SelectedValuesDisplay = styled.div``
const requiredValidators = Object.freeze([ValidatorTypes.notEmpty])

const callValidator = (value, vtype) => validatorFuncs[vtype](value)

export class SearchGroupDropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      searchFilter: '',
    }
  }

  /**
   * Returns true if valid, otherwise invalid text
   */
  isValid = () => {
    const { validator, customValidator, values } = this.props
    const customIsValid = customValidator(values)

    if (customIsValid !== true) {
      return customIsValid
    }
    if (_.isEmpty(validator)) {
      return true
    }

    return callValidator(values || [], validator) || this.buildInvalidDisplayText()
  }

  handleKeyPress = event => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setState({ isOpen: false })
      return
    }

    if (event.key === 'Enter') {
      this.setState({ isOpen: !this.state.isOpen }, this.focusSearchInput)
    }
  }

  buildInvalidDisplayText = () => {
    const { name, placeholder, invalidDisplayText, validator, values } = this.props
    if (!_.isEmpty(invalidDisplayText)) {
      return invalidDisplayText
    }
    const isRequiredValidator = _.includes(requiredValidators, validator)
    const showRequiredMsg = (isRequiredValidator && !values) || _.isEmpty(values)
    return _.startCase(_.toLower(name || placeholder)) + (showRequiredMsg ? ' is required' : '  is not valid')
  }

  closeDropdown = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleToggleDropdownClick = () => {
    const { disabled } = this.props
    if (disabled || this.state.isOpen) {
      return
    }
    this.setState({ isOpen: !this.state.isOpen, searchFilter: '' }, this.focusSearchInput)
  }

  changeSearchInputHandler = e => {
    this.setState({
      searchFilter: e.target.value,
    })
  }

  filterChoices = choices => {
    if (this.state.searchFilter === '') {
      return choices
    }
    return _.filter(choices, choice => choice.name.toLowerCase().includes(this.state.searchFilter.trim().toLowerCase()))
  }

  focusSearchInput = () => {
    if (this.state.isOpen) {
      this.searchField.focus()
      this.searchField.setSelectionRange(this.searchField.value.length, this.searchField.value.length)
    }
  }

  render = () => {
    const {
      testId,
      showCaret,
      name,
      values,
      dropDown,
      isLightTheme,
      isValid,
      placeholder,
      isDropUp,
      style,
      borderAreaStyle,
      disabled,
      optionsContainerStyle,
      height,
      id,
      labelStyles,
      ariaDescriptor,
      customStyle,
    } = this.props
    const { isOpen } = this.state
    const hoverBorderColor = isLightTheme ? Theme.darkGrey : Theme.black
    const borderColor = isOpen ? hoverBorderColor : isLightTheme ? Theme.lightGrey : '#031927'

    const selectedItemNames = []
    const selectedItemNamesByIcon = new Map()
    const selectedItemChoices = []

    const renderSingleOption = (choice, idx, isSelectedChoice, uniqueKey, canSelectOptions) => (
      <CheckboxWrapper key={`${uniqueKey}-${choice.value}-group-${idx}`}>
        <VmsCheckbox
          key={`${choice.value}-${idx}`}
          field={choice.value}
          onClick={canSelectOptions ? this.props.onChangeHandler : () => {}}
          isSelected={isSelectedChoice}
          isDisabled={choice.disabled}
          label={choice.name}
          labelStyles={labelStyles}
          selectedIcon={VmsIcons.CheckBoxSelected}
          unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
        />
      </CheckboxWrapper>
    )

    const renderOptions = groups => {
      const strippedSearchFilter = this.state.searchFilter.trim()
      return groups.map((group, idx) => {
        const isLast = idx === groups.length - 1
        const filteredGroupChoices = this.filterChoices(group.choices)
        const groupChoices = filteredGroupChoices.map((choice, idx) => {
          const isSelectedChoice = values.includes(choice.value)
          if (isSelectedChoice) {
            selectedItemNames.push(choice.name)
            if (!selectedItemNamesByIcon.get(group.icon)) {
              selectedItemNamesByIcon.set(group.icon, [])
            }
            const selectedItems = selectedItemNamesByIcon.get(group.icon)
            selectedItems.push(choice.name)
            selectedItemNamesByIcon.set(group.icon, selectedItems)
            selectedItemChoices.push(renderSingleOption(choice, idx, true, 'selected', true))
          }
          return renderSingleOption(choice, idx, isSelectedChoice, 'choices', this.props.canSelectOptions)
        })
        return (
          <GroupContainer isLast={isLast} key={`search-group-container-${idx}`}>
            {(group.label || group.icon) && (
              <GroupLabelWrapper>
                <Icon>{group.icon}</Icon>
                <GroupLabel>{group.label}</GroupLabel>
              </GroupLabelWrapper>
            )}
            {strippedSearchFilter !== '' && _.isEmpty(filteredGroupChoices) ? (
              <GroupSearchResults>No Result</GroupSearchResults>
            ) : (
              groupChoices
            )}
          </GroupContainer>
        )
      })
    }
    const renderSelectedChoices = selectedItemChoices => {
      const hasSelectedChoices = selectedItemChoices.length !== 0
      return (
        hasSelectedChoices && (
          <GroupContainer key="selected-choices-container">
            <GroupLabelWrapper>
              <GroupLabel overridePadding="0px">Selected</GroupLabel>
            </GroupLabelWrapper>
            {selectedItemChoices}
          </GroupContainer>
        )
      )
    }

    const renderedOptions = renderOptions(this.props.groups)
    const renderedSelectedChoices = renderSelectedChoices(selectedItemChoices)
    const hasSelectedValues = !_.isEmpty(selectedItemNames)
    const optionsContainer = (
      <OptionsContainer
        id="optionsContainer"
        data-test="sr-picker_options"
        key="optionsContainer"
        style={optionsContainerStyle}
        ariaExpanded={isOpen}
        role="list"
        {...{ borderColor, customStyle }}
      >
        {renderedSelectedChoices}
        {renderedOptions}
      </OptionsContainer>
    )

    const DropContainer = isDropUp ? DropUpAbsolute : DropdownAbsolute
    const selectedItemsDisplay = []
    selectedItemNamesByIcon.forEach((values, groupIcon) => {
      const formattedValues = values.join(', ')
      selectedItemsDisplay.push(
        <GroupDisplayWrapper key={`${groupIcon}_${formattedValues}`}>
          <Icon>{groupIcon}</Icon>
          <GroupDisplayText>{formattedValues}</GroupDisplayText>
        </GroupDisplayWrapper>
      )
      selectedItemsDisplay.push(<CommaText key={`${groupIcon}_comma`}>,</CommaText>)
    })
    selectedItemsDisplay.pop()
    const defaultText = this.state.isOpen ? '' : placeholder

    return (
      <OuterBody
        data-test={testId || (name ? `sr-searchgroup-picker-${name.toLowerCase().replace(/ /g, '_')}` : null)}
        aria-label={ariaDescriptor}
        onKeyDown={this.handleKeyPress}
        role="button"
        tabIndex="0"
        id={id}
        {...{ style, height }}
      >
        <BorderedArea
          {...{
            height,
            borderColor,
            hoverBorderColor,
            isOpen,
            isValid,
            disabled,
            customStyle,
          }}
          style={borderAreaStyle}
          onClick={this.handleToggleDropdownClick}
        >
          <BorderedAreaFlexIEFix {...{ height }} />
          <SelectedItemDisplay
            {...{
              hasSelectedValues,
              disabled,
              customStyle,
            }}
          >
            <SearchInputWrapper>
              {this.state.isOpen && <Icon>{VmsIcons.Search}</Icon>}
              <SearchInput
                id={name ? `sr-search-input-${name.toLowerCase().replace(/ /g, '_')}` : null}
                value={this.state.searchFilter}
                isVisible={this.state.isOpen}
                placeholder={this.state.searchFilter}
                onChange={e => this.changeSearchInputHandler(e)}
                ref={searchField => {
                  this.searchField = searchField
                }}
              />
            </SearchInputWrapper>
            <SelectedValuesDisplay>{!this.state.isOpen && hasSelectedValues ? selectedItemsDisplay : defaultText}</SelectedValuesDisplay>
          </SelectedItemDisplay>
          {showCaret && !disabled && <Caret {...{ customStyle }} direction={this.state.isOpen ? 'up' : 'down'} />}
        </BorderedArea>
        <DropdownRelative>
          <DropContainer>
            {isOpen && !dropDown && optionsContainer}
            {isOpen && dropDown && <DropDown>{dropDown}</DropDown>}
          </DropContainer>
        </DropdownRelative>
      </OuterBody>
    )
  }
}

SearchGroupDropdownMenu.defaultProps = {
  groups: [],
  name: '',
  values: [],
  isValid: true,
  validator: null,
  placeholder: '',
  style: {},
  height: 42,
  optionsContainerStyle: {},
  borderAreaStyle: {},
  showCaret: true,
  dropDown: null,
  isLightTheme: false,
  disabled: false,
  wrapText: true,
  canSelectOptions: true,
  onChangeHandler: () => {},
  id: '',
  ariaDescriptor: '',
}

SearchGroupDropdownMenu.propTypes = {
  testId: React.PropTypes.string,
  isDropUp: React.PropTypes.bool,
  values: React.PropTypes.array,
  placeholder: React.PropTypes.string.isRequired,
  groups: React.PropTypes.array.isRequired,
  dropDown: React.PropTypes.object,
  isLightTheme: React.PropTypes.bool.isRequired,
  showCaret: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  canSelectOptions: React.PropTypes.bool,
  validator: React.PropTypes.oneOf(_.values(ValidatorTypes)),
  isValid: React.PropTypes.bool,
  wrapText: React.PropTypes.bool,

  onChangeHandler: React.PropTypes.func.isRequired,
  id: React.PropTypes.string,
  ariaDescriptor: React.PropTypes.string,
  customStyle: React.PropTypes.object,
}

SearchGroupDropdownMenu = onClickOutside(SearchGroupDropdownMenu)
export default SearchGroupDropdownMenu
