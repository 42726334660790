import _ from 'lodash'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { uploadPhoto, cropOpen, cropDelete } from 'mgr/pages/single-venue/settings/actions/services'
import * as styles from 'mgr/pages/single-venue/settings/assets/styles'

class HeaderImageUpload extends Component {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
    this.interfaceId = `sr-drop-interface-${this.props.imageType}`
    this.imgId = `sr-upload-img-${this.props.imageType}`

    this.uploadPhoto = this.props.uploadPhoto.bind(this, this.props.imageType)
    this.cropDelete = this.props.cropDelete.bind(this, this.props.imageType)
    this.cropOpen = this.props.cropOpen.bind(this, this.props.imageType)
  }

  onDrop(accepted) {
    this.uploadPhoto(accepted[0])
  }

  render() {
    const { cropData, url, style, width: interFaceWidth, height: interFaceHeight } = this.props

    const actionEdit = {
      ...styles.action,
      backgroundImage: `url(${window.globalInit.mediaUrl}images/eventmanager/edit.png)`,
    }

    const actionDelete = {
      ...styles.action,
      backgroundImage: `url(${window.globalInit.mediaUrl}images/eventmanager/delete.png)`,
    }

    const commonStyle = {
      ...styles.bigPhoto,
      width: `${interFaceWidth}px`,
      height: `${interFaceHeight}px`,
      ...style,
    }

    const largeImageStyle = {
      ...styles.hasPhoto,
      ...commonStyle,
    }
    const emptyPhotoStyle = {
      ...styles.emptyPhoto,
      ...commonStyle,
    }

    let crop = {}
    if (!_.isEmpty(cropData)) {
      const imgWidth = (100 / cropData.width) * interFaceWidth
      const imgHeight = (100 / cropData.height) * interFaceHeight
      const topOffset = imgHeight * (cropData.y / 100)
      const leftOffset = imgWidth * (cropData.x / 100)
      const width = `${imgWidth}px`
      const top = `-${topOffset}px`
      const left = `-${leftOffset}px`
      crop = { width, top, left }
    }

    const cropStyle = {
      ...styles.photoStyle,
      ...crop,
    }

    const content = url ? (
      <div id={this.interfaceId} style={largeImageStyle} data-test="sr-image-upload-drop-interface">
        <img id={this.imgId} src={url} style={cropStyle} />
        <div style={styles.photoActions}>
          <p data-test="sr-image-upload-edit" onClick={this.cropOpen} style={$.extend({}, actionEdit, styles.actionAdjust)} />
          <p data-test="sr-image-upload-delete" onClick={this.cropDelete} style={$.extend({}, actionDelete, styles.actionAdjust)} />
        </div>
      </div>
    ) : (
      <div id={this.interfaceId}>
        <Dropzone data-test="sr-image-upload-dropzone" style={emptyPhotoStyle} onDrop={this.onDrop} multiple={false} />
      </div>
    )

    return content
  }
}

const mapDispatchToProps = dispatch => ({
  uploadPhoto: (imageType, file) => {
    dispatch(uploadPhoto(imageType, file))
  },
  cropDelete: imageType => {
    dispatch(cropDelete(imageType))
  },
  cropOpen: imageType => {
    dispatch(cropOpen(imageType))
  },
})

export default connect(null, mapDispatchToProps)(HeaderImageUpload)
