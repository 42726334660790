import { useLocales } from '@sevenrooms/core/locales'
import type { UserDomainVenue, Venue, VenueSettings } from '@sevenrooms/mgr-core'
import { settingsMessages } from '../settings.locales'
import { LanguageSettings } from './LanguageSettings'

interface WidgetLanguageSettingsRoutes {
  RESERVATION_WIDGET_LANGUAGE: string
  ORDERING_SITE_LANGUAGE: string
  WAITLIST_WIDGET_LANGUAGE: string
  EVENT_WIDGET_LANGUAGE: string
  SUBSCRIPTION_WIDGET_LANGUAGE: string
}

interface WidgetLanguageSettingsProps {
  venue: Venue
  venues: UserDomainVenue[]
  routes: WidgetLanguageSettingsRoutes
  onChangeVenue: (venue: UserDomainVenue) => void
  venueSettings: VenueSettings
}

export function WidgetLanguageSettings({ venue, venues, onChangeVenue, venueSettings, routes }: WidgetLanguageSettingsProps) {
  const { formatMessage } = useLocales()
  return (
    <LanguageSettings title={formatMessage(settingsMessages.widgets)} venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
      <LanguageSettings.Block label={formatMessage(settingsMessages.widgets)}>
        <LanguageSettings.Row
          label={formatMessage(settingsMessages.reservationWidget)}
          id="row-block-res-widget-settings"
          blockText={formatMessage(settingsMessages.reservationWidgetSetup)}
          editLink={routes.RESERVATION_WIDGET_LANGUAGE}
          overrideIcons={!venueSettings.dining_widget_enabled}
        >
          <LanguageSettings.MenuItem
            key="widget-reservation-settings"
            onClick={() => (window.location.href = `/manager/${venue.urlKey}/settings/widgets/dining`)}
            text={formatMessage(settingsMessages.widgetsReservationSettings)}
            iconName="VMSWeb-settings"
          />
          <LanguageSettings.MenuItem
            key="open-reservation-widget"
            iconName="VMSWeb-open-in-new"
            onClick={() => (window.location.href = `/reservations/${venue.urlKey}`)}
            text={formatMessage(settingsMessages.widgetsReservationOpen)}
          />
        </LanguageSettings.Row>
        {venueSettings.is_sevenrooms_ordering_enabled && (
          <LanguageSettings.Row
            id="row-block-order-widget-settings"
            label={formatMessage(settingsMessages.orderingSites)}
            editLink={routes.ORDERING_SITE_LANGUAGE}
            addPlaceHolder
          />
        )}
        <LanguageSettings.Row
          id="row-block-waitlist-widget-settings"
          label={formatMessage(settingsMessages.waitlistWidget)}
          blockText={formatMessage(settingsMessages.waitlistWidgetSetup)}
          overrideIcons={!venueSettings.waitlist_widget_enabled}
          editLink={routes.WAITLIST_WIDGET_LANGUAGE}
        >
          <LanguageSettings.MenuItem
            key="waitlist-widget-settings"
            iconName="VMSWeb-settings"
            onClick={() => (window.location.href = `/manager/${venue.urlKey}/settings/widgets/waitlist`)}
            text={formatMessage(settingsMessages.widgetsWaitlistSettings)}
          />
          <LanguageSettings.MenuItem
            key="open-waitlist-widget"
            iconName="VMSWeb-open-in-new"
            onClick={() => (window.location.href = `/waitlist/${venue.urlKey}`)}
            text={formatMessage(settingsMessages.widgetsWaitlistOpen)}
          />
        </LanguageSettings.Row>
        <LanguageSettings.Row
          id="row-block-event-widget-settings"
          label={formatMessage(settingsMessages.eventWidget)}
          blockText={formatMessage(settingsMessages.eventWidgetSetup)}
          overrideIcons={!venueSettings.event_widget_enabled}
          editLink={routes.EVENT_WIDGET_LANGUAGE}
        >
          <LanguageSettings.MenuItem
            key="event-widget-settings"
            iconName="VMSWeb-settings"
            onClick={() => (window.location.href = `/manager/${venue.urlKey}/settings/widgets/events`)}
            text={formatMessage(settingsMessages.widgetsEventSettings)}
          />
          <LanguageSettings.MenuItem
            key="open-event-widget"
            iconName="VMSWeb-open-in-new"
            onClick={() => (window.location.href = `/events/${venue.urlKey}`)}
            text={formatMessage(settingsMessages.widgetsEventOpen)}
          />
        </LanguageSettings.Row>
        <LanguageSettings.Row
          id="row-block-subscription-widget-settings"
          label={formatMessage(settingsMessages.subscriptionWidget)}
          editLink={routes.SUBSCRIPTION_WIDGET_LANGUAGE}
          addPlaceHolder
        />
      </LanguageSettings.Block>
    </LanguageSettings>
  )
}
