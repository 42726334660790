import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Desc,
  SubDesc,
  AccessDenied,
  Contents,
  SubContainer,
  ProviderLogo,
  Provider,
  ProviderSection,
  SizedImg,
} from 'mgr/pages/single-venue/settings/components/shared'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { EmmaSection } from '../components/EmmaSection'
import { MailchimpSection } from '../components/MailchimpSection'

class EmailServiceProviders extends PureComponent {
  render() {
    const { venue, venues } = this.props

    return (
      <ContentLayout title="Email Service Providers" venue={venue} venues={venues} onChangeVenue={() => {}}>
        <Contents>
          {!this.props.is_sizzle_enabled ? (
            <AccessDenied>
              Email integrations are only available with 7X, our marketing automation and guest engagement package.
            </AccessDenied>
          ) : null}

          <SubContainer>
            <Desc>Sync clients directly with an Email Service Provider</Desc>
            <SubDesc className="emailserviceproviders-subdesc">
              Use this page to authenticate your Email Service Provider with SevenRooms
            </SubDesc>
            <Provider>
              <ProviderLogo>
                <SizedImg width="263" height="99" path="integrations/emma-logo.png" />
              </ProviderLogo>
              <ProviderSection>
                <EmmaSection
                  venue_id={this.props.venue_id}
                  media_url={this.props.media_url}
                  is_sizzle_enabled={this.props.is_sizzle_enabled}
                />
              </ProviderSection>
            </Provider>
            <Provider>
              <ProviderLogo>
                <SizedImg width="238" height="65" path="integrations/mailchimp-logo.png" />
              </ProviderLogo>
              <ProviderSection>
                <MailchimpSection
                  venue_id={this.props.venue_id}
                  media_url={this.props.media_url}
                  is_sizzle_enabled={this.props.is_sizzle_enabled}
                />
              </ProviderSection>
            </Provider>
          </SubContainer>
        </Contents>
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    venue_id: venue.id,
    is_sizzle_enabled: state.appState.venueSettings.is_sizzle_enabled,
    media_url: state.appState.mediaUrl,
  }
}

export default connect(mapStateToProps)(EmailServiceProviders)
