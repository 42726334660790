import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import {
  fetchDeliveryIntegrations,
  postEnableDeliveryIntegration,
  postDisableDeliveryIntegration,
} from 'mgr/lib/services/OrderingSettingsServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { batchActions } from 'svr/common/ReduxUtils'

export const INTEGRATION_KEY_DOORDASH = 'DOORDASH'
export const INTEGRATION_KEY_ORKESTRO = 'ORKESTRO'
export const INTEGRATION_KEY_YELLO = 'YELLO'

export const tryLoadDeliveryIntegrationSettings = () => (dispatch, getState) => {
  const state = getState()
  if (state.deliveryIntegrations.isInitialized) {
    return Promise.resolve()
  }
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.LOAD_DELIVERY_INTEGRATION_SETTINGS_START,
  })

  return fetchDeliveryIntegrations({ venueId: venue.id }).then(
    response =>
      dispatch({
        type: ActionTypes.LOAD_DELIVERY_INTEGRATION_SETTINGS_SUCCESS,
        integrationSettings: _.keyBy(response.data.delivery_integrations, 'delivery_integration'),
        supportedIntegrations: response.data.supported_integrations,
      }),
    error => {
      console.error('An error occurred fetching delivery integration configurations', error)
      dispatch({
        type: ActionTypes.LOAD_DELIVERY_INTEGRATION_SETTINGS_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Fetching delivery integration configurations failed, please try again or contact support'))
      return error
    }
  )
}

/** ************* */
/* Edit Actions */
/** ************* */

export const selectDeliveryIntegrationEdit = integrationKey => ({
  type: ActionTypes.SELECT_DELIVERY_INTEGRATION_EDIT,
  integrationKey,
})

export const tryDeactivateDeliveryIntegration = integrationKey => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.DEACTIVATE_DELIVERY_INTEGRATION_START,
  })

  const state = getState()
  const venueId = state.appState.venue.id
  const deliveryIntegrationSettings = {
    enabled: false,
    delivery_integration: integrationKey,
  }

  return postDisableDeliveryIntegration({
    venueId,
    integrationKey,
    deliveryIntegrationSettings,
  }).then(
    response => {
      const showMsgLevel = response.data.result === 'success' ? GlobalActions.showSuccessMessage : GlobalActions.showWarnMessage
      dispatch(
        batchActions([
          {
            type: ActionTypes.DEACTIVATE_DELIVERY_INTEGRATION_SUCCESS,
            integrationKey,
          },
          showMsgLevel(response.data.message),
        ])
      )
      window.metric.track(`Settings.DeliveryIntegrations.Disable.${integrationKey}.${response.data.result}`)
    },
    error => {
      dispatch(GlobalActions.showErrorMessage(`Unable to disable delivery integration, please try again later. ${error}`))
      dispatch({
        type: ActionTypes.DEACTIVATE_DELIVERY_INTEGRATION_FAIL,
      })
      return error
    }
  )
}

export const cancelDeliveryIntegrationEdit = () => ({
  type: ActionTypes.CANCEL_DELIVERY_INTEGRATION_EDIT,
})

export const changeDeliveryIntegrationField = (integrationKey, field, value) => ({
  type: ActionTypes.CHANGE_DELIVERY_INTEGRATION_FIELD,
  integrationKey,
  field,
  value,
})

export const deliveryIntegrationFormValidated = formErrors => ({
  type: ActionTypes.DELIVERY_INTEGRATION_FORM_VALIDATED,
  formErrors,
})

export const deliveryIntegrationDismissErrorDisplay = () => ({
  type: ActionTypes.DELIVERY_INTEGRATION_DISMISS_ERROR_DISPLAY,
})

export const DELIVERY_INTEGRATION_DEACTIVATED = 'DELIVERY_INTEGRATION_DEACTIVATED'

export const trySaveDeliveryIntegrationSettings = integrationKey => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SAVE_DELIVERY_INTEGRATION_SETTINGS_START,
  })

  const state = getState()
  const venueId = state.appState.venue.id
  const prevDeliveryIntegrationSettings = state.deliveryIntegrations.integrationSettings[integrationKey]
  const deliveryIntegrationSettings = {
    ...prevDeliveryIntegrationSettings,
    enabled: true,
  }

  return postEnableDeliveryIntegration({
    venueId,
    integrationKey,
    deliveryIntegrationSettings,
  }).then(
    response => {
      const showMsgLevel = response.data.result === 'success' ? GlobalActions.showSuccessMessage : GlobalActions.showWarnMessage
      dispatch(
        batchActions([
          {
            type: ActionTypes.SAVE_DELIVERY_INTEGRATION_SETTINGS_SUCCESS,
            enabled: response.data.result === 'success' ? true : prevDeliveryIntegrationSettings.enabled,
          },
          showMsgLevel(response.data.message),
        ])
      )
      window.metric.track(`Settings.DeliveryIntegrations.Enable.${integrationKey}.${response.data.result}`)
    },
    error => {
      dispatch(GlobalActions.showErrorMessage(`Unable to save delivery integration. ${error}`))
      dispatch({
        type: ActionTypes.SAVE_DELIVERY_INTEGRATION_SETTINGS_FAIL,
      })
      return error
    }
  )
}
