import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, VStack, Clickable } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { usePerformanceTableContext } from '../../hooks'

interface ColumnSwitchProps {
  title: string
  category: string
}

export function ColumnSwitch({ title, category }: ColumnSwitchProps) {
  const { sortDescending, setSortDescending, sortCategory, setSortCategory } = usePerformanceTableContext()
  const active = category === sortCategory

  const updateOrder = () => {
    setSortDescending(category !== sortCategory ? true : !sortDescending)
    setSortCategory(category)
  }

  const columnDataTest = `column-sort-${category}`

  return (
    <Clickable data-test={columnDataTest} onClick={updateOrder}>
      <HStack position="relative" left="sm" justifyContent="end">
        <Box mr="xs">
          <Text color={active ? 'primaryFont' : 'secondaryFont'} fontWeight="bold">
            {title}
          </Text>
        </Box>
        <Box>
          <VStack>
            <Box height="5px">
              <Icon name="VMSWeb-chevron-up" size="sm" color={active && !sortDescending ? 'primaryFont' : 'secondaryFont'} />
            </Box>
            <Box>
              <Icon name="VMSWeb-chevron-down" size="sm" color={active && sortDescending ? 'primaryFont' : 'secondaryFont'} />
            </Box>
          </VStack>
        </Box>
      </HStack>
    </Clickable>
  )
}
