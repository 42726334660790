import { useLocales } from '@sevenrooms/core/locales'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import { settingsMessages } from '../settings.locales'
import { LanguageSettings } from './LanguageSettings'

export interface Permissions {
  isOrderingSmsEnabled: boolean
  isReservationSmsEnabled: boolean
  isRemindersSmsEnabled: boolean
  isWaitlistSmsEnabled: boolean
  showOrderMenuSms: boolean
  priorityAlertsAdminEnabled: boolean
  vipChatEnabled: boolean
}

interface SMSLanguageSettingsRoutes {
  SMS_RES_LANGUAGE: string
  SMS_WAITLIST_LANGUAGE: string
  SMS_ORDER_MENU_LANGUAGE: string
  SMS_ORDERING: string
  SMS_CUSTOM: string
  SMS_PRIORITY_ALERTS: string
  SMS_PRIVATE_LINE: string
}

interface SMSLanguageSettingsProps {
  venue: Venue
  venues: UserDomainVenue[]
  routes: SMSLanguageSettingsRoutes
  permissions: Permissions
  onChangeVenue: (venue: UserDomainVenue) => void
}

export function SMSLanguageSettings({ venue, venues, routes, permissions, onChangeVenue }: SMSLanguageSettingsProps) {
  const { formatMessage } = useLocales()
  return (
    <LanguageSettings title={formatMessage(settingsMessages.SMS)} venue={venue} venues={venues} onChangeVenue={onChangeVenue}>
      <LanguageSettings.Block label={formatMessage(settingsMessages.SMS)}>
        {permissions.isReservationSmsEnabled && (
          <LanguageSettings.Row label={formatMessage(settingsMessages.SMSres)} id="res-sms" editLink={routes.SMS_RES_LANGUAGE} />
        )}

        {permissions.isWaitlistSmsEnabled && (
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.SMSwaitlist)}
            id="res-sms-waitlist"
            editLink={routes.SMS_WAITLIST_LANGUAGE}
          />
        )}

        {permissions.showOrderMenuSms && venue.isDiningClass && (
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.SMSorderMenu)}
            id="order-menu-sms"
            editLink={routes.SMS_ORDER_MENU_LANGUAGE}
          />
        )}

        {venue.isSevenRoomsOrderingEnabled && permissions.isOrderingSmsEnabled && venue.isDiningClass && (
          <LanguageSettings.Row label={formatMessage(settingsMessages.SMSordering)} id="ordering-sms" editLink={routes.SMS_ORDERING} />
        )}

        {(permissions.isReservationSmsEnabled || permissions.isWaitlistSmsEnabled) && (
          <LanguageSettings.Row label={formatMessage(settingsMessages.SMScustom)} id="res-sms-custom" editLink={routes.SMS_CUSTOM} />
        )}

        {permissions.priorityAlertsAdminEnabled && (
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.SMSpriorityAlerts)}
            id="priority-alerts-sms"
            editLink={routes.SMS_PRIORITY_ALERTS}
          />
        )}

        {permissions.vipChatEnabled && (
          <LanguageSettings.Row
            label={formatMessage(settingsMessages.SMSprivateLine)}
            id="private-line-sms"
            editLink={routes.SMS_PRIVATE_LINE}
          />
        )}
      </LanguageSettings.Block>
    </LanguageSettings>
  )
}
