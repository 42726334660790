/* eslint-disable camelcase */
import moment from 'moment-timezone'
import type {
  PacingRule,
  PacingRuleApi,
  OrderingSiteOption,
  OrderingSiteOptionApi,
} from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm:ss'

export const adaptPacingRuleToApiOnCreate = (pacingRule: Omit<PacingRule, 'id'>): Omit<PacingRuleApi, 'id'> => ({
  name: pacingRule.name,
  order_methods: pacingRule.orderMethods,
  ordering_site_ids: pacingRule.orderingSiteIds,
  date_range_from: pacingRule.dateRangeFrom?.format(DATE_FORMAT),
  date_range_to: pacingRule.dateRangeTo?.format(DATE_FORMAT) || null,
  time_range_from: pacingRule.timeRangeFrom?.format(TIME_FORMAT),
  time_range_to: pacingRule.timeRangeTo?.format(TIME_FORMAT),
  time_interval_mins: pacingRule.timeIntervalMins,
  day_time_limit_matrix: pacingRule.dayTimeLimitMatrix,
})

export const adaptPacingRuleToApiOnEdit = (pacingRule: PacingRule): PacingRuleApi => ({
  ...adaptPacingRuleToApiOnCreate(pacingRule),
  id: pacingRule.id,
})

export const adaptPacingRuleFromApi = (pacingRule: PacingRuleApi): PacingRule => ({
  id: pacingRule.id,
  name: pacingRule.name,
  orderMethods: pacingRule.order_methods,
  orderingSiteIds: pacingRule.ordering_site_ids,
  dateRangeFrom: pacingRule.date_range_from ? moment(pacingRule.date_range_from, DATE_FORMAT) : undefined,
  dateRangeTo: pacingRule.date_range_to ? moment(pacingRule.date_range_to, DATE_FORMAT) : undefined,
  timeRangeFrom: pacingRule.time_range_from ? moment(pacingRule.time_range_from, TIME_FORMAT) : undefined,
  timeRangeTo: pacingRule.time_range_to ? moment(pacingRule.time_range_to, TIME_FORMAT) : undefined,
  timeIntervalMins: pacingRule.time_interval_mins,
  dayTimeLimitMatrix: pacingRule.day_time_limit_matrix,
})

export const adaptOrderingSiteOptionFromApi = (orderingSite: OrderingSiteOptionApi): OrderingSiteOption => ({
  id: orderingSite.id,
  name: orderingSite.name,
  fulfillmentMethod: orderingSite.fulfillment_method,
  menuIds: orderingSite.menu_ids,
})
