import _ from 'lodash'
import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'
import { dropDownTypes } from 'mgr/pages/shared/utils/Constants'
import { CommonStyles, GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'

export class GridCell extends PureComponent {
  constructor(props) {
    super(props)
    this.handleDropDownClick = this.handleDropDownClick.bind(this)
    this.addDropDownContainerRef = null
  }

  handleDropDownClick() {
    const elRect = this.addDropDownContainerRef.getBoundingClientRect()
    const { blockTuples, accessTuples } = this.props
    let dropdownType = dropDownTypes.ADD_BLOCK
    let dropdownEntity = null
    if (!_.isEmpty(blockTuples)) {
      const blockTuple = blockTuples[0]
      dropdownType = dropDownTypes.EDIT_BLOCK
      dropdownEntity = blockTuple.block
    } else if (!_.isEmpty(accessTuples)) {
      const accessTuple = accessTuples[0]
      dropdownType = dropDownTypes.EDIT_ACCESS_RULE
      dropdownEntity = accessTuple.rule
    }
    this.props.onAddDropDownOpened(
      this.props.isBookable,
      this.props.table,
      this.props.order,
      this.props.isUnassigned,
      elRect,
      dropdownType,
      dropdownEntity
    )
  }

  render() {
    const styles = {
      hoverBox: {
        position: 'absolute',
        width: GridStyles.TimeHeaderWidth - 6,
        height: GridStyles.ReservationRowHeight - 6,
        top: 3,
        left: 3,
        borderRadius: '5px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: this.props.isBookable ? CommonStyles.ButtonPrimaryColor : '#ccc',
        },
      },
    }
    return (
      <div
        ref={addDropDownContainer => {
          this.addDropDownContainerRef = addDropDownContainer
        }}
      >
        <div key={`_hover${this.props.order}`} style={styles.hoverBox} onClick={this.handleDropDownClick} />
      </div>
    )
  }
}

GridCell = Radium(GridCell)
