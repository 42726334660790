import _ from 'lodash'
import pluralize from 'pluralize'
import { useCallback, useState, useMemo } from 'react'
import { Redirect } from 'react-router'
import BorderlessTable from 'svr/component-lib/Generic/Tables/BorderlessTable'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import { venueToday } from 'widget/universal/selectors/common/today'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import AvailableHours from './AvailableHours'
import type { OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type moment from 'moment-timezone'
import type { TableCellProps } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

interface ActionCellProps {
  value: string
  venue: Venue
  onDelete: (menuId: string) => void
}

function ActionCell({ value, venue, onDelete }: ActionCellProps) {
  const [isEdit, setIsEdit] = useState(false)

  return isEdit ? (
    <Redirect push to={`/manager2/${venue.urlKey}/settings/ordering/menu/${value}`} />
  ) : (
    <Menu data-test={`menu-management-menu-${value}`}>
      <MenuItem data-test={`menu-management-edit-item-${value}`} onClick={() => setIsEdit(true)}>
        <Icon name="VMSWeb-edit" />
        <Text>Edit</Text>
      </MenuItem>
      {venue.isSevenRoomsOrderingMiddleware && (
        <MenuItem
          data-test={`menu-management-delete-item-${value}`}
          onClick={() => {
            onDelete(value)
          }}
        >
          <Icon name="VMSWeb-trash" />
          <Text>Delete</Text>
        </MenuItem>
      )}
    </Menu>
  )
}

function PrepTimeCell({ value, index }: { value: string; index: number }) {
  return <div data-test={`menu-mgt-entry-${index}-prep-time`}>{value ? `${value} ${pluralize('minute', value)}` : '-'}</div>
}

const availableDatesCell = (props: TableCellProps, venueTodayMoment: moment.Moment) => (
  <div data-test={`menu-mgt-entry-${props.index}-available-dates`}>
    {formatAvailableDates(props.original as OrderingMenu, venueTodayMoment)}
  </div>
)

const statusCell = (props: TableCellProps, venueTodayMoment: moment.Moment) => {
  const isMenuActive = checkMenuActive(props.original as OrderingMenu, venueTodayMoment)
  return (
    <div data-test={`menu-mgt-entry-${props.index}-status`}>
      <StatusLabel variant={isMenuActive ? 'success' : 'inactive'}>{isMenuActive ? 'Active' : 'Inactive'}</StatusLabel>
    </div>
  )
}

const checkMenuActive = (menu: OrderingMenu, venueTodayMoment: moment.Moment) =>
  !menu.dateRangeTo || !menu.dateRangeTo.isBefore(venueTodayMoment)

const formatAvailableDates = (menu: OrderingMenu, venueTodayMoment: moment.Moment) => {
  if (menu.dateRangeTo || (menu.dateRangeFrom && menu.dateRangeFrom.isAfter(venueTodayMoment))) {
    const startFormatted = menu.dateRangeFrom.format('L')
    const endFormatted = menu.dateRangeTo ? menu.dateRangeTo.format('L') : 'Infinite'
    return [startFormatted, endFormatted].join(' - ')
  }
  return 'All dates'
}

interface MenuListTableProps {
  venue: Venue
  orderingMenusList: OrderingMenu[]
  isLoading: boolean
  removeMenu: (venueId: string, menuId: string) => void
}

function MenuListTable({ venue, orderingMenusList, removeMenu, isLoading }: MenuListTableProps) {
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedMenuId, setSelectedMenuId] = useState('')
  const venueTodayMoment = useMemo(() => venueToday(venue), [venue])
  const defaultSortOrderingMenusList = useMemo(
    () => _.sortBy(orderingMenusList, (menu: OrderingMenu) => !checkMenuActive(menu, venueTodayMoment)),
    [orderingMenusList, venueTodayMoment]
  )
  const onDelete = useCallback(
    (menuId: string) => {
      setSelectedMenuId(menuId)
      setModalVisible(true)
    },
    [setModalVisible]
  )
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: 250,
      Cell: ({ value, index }: { value: string; index: number }) => <div data-test={`menu-mgt-entry-${index}-name`}>{value}</div>,
    },
    {
      Header: 'Prep Time',
      accessor: 'prepTimeMins',
      maxWidth: 130,
      Cell: PrepTimeCell,
    },
    {
      Header: 'Available Dates',
      sortable: false,
      maxWidth: 220,
      accessor: 'id',
      Cell: (props: TableCellProps) => availableDatesCell(props, venueTodayMoment),
    },
    {
      Header: 'Menu Availability',
      sortable: false,
      accessor: 'availableHours',
      minWidth: 110,
      Cell: AvailableHours,
    },
    {
      Header: 'Status',
      sortable: false,
      accessor: 'dateRangeTo',
      maxWidth: 100,
      Cell: (props: TableCellProps) => statusCell(props, venueTodayMoment),
    },
    {
      sortable: false,
      headerClassName: 'align-right',
      width: 80,
      accessor: 'id',
      Cell: ({ value }: { value: string }) => <ActionCell value={value} venue={venue} onDelete={onDelete} />,
    },
  ]

  return (
    <div data-test="sr-ordering-menu-list-table">
      <BorderlessTable columns={columns} data={defaultSortOrderingMenusList} isLoading={isLoading} />
      {isModalVisible && (
        <MessageBox
          handleActionClick={() => {
            removeMenu(venue.id, selectedMenuId)
            setModalVisible(false)
          }}
          handleCloseClick={() => {
            setModalVisible(false)
          }}
          dialogType={MessageBox.DialogType.WARNING}
          header="Warning"
          explain="Warning"
          details={[]}
          explanation="Are you sure that you want to delete this menu?"
          actionButtonText="Delete"
        />
      )}
    </div>
  )
}

export default MenuListTable
