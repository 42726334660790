import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import Caret from 'svr/component-lib/Generic/Symbols/Caret'

const Container = styled.div`
  ${props => props.customContentContainerStyles}
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple};
  background-color: ${props => props.theme.color.lightGrey};
  border-radius: 8px;
`

const HeaderText = styled.div`
  font-size: ${props => props.headerFontSize};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const ContentContainer = styled.div`
  padding: ${props => props.theme.gutter.triple};
`

const SubheaderText = styled.div`
  font-size: 12px;
  color: #565c63;
  margin-left: auto;
  margin-right: 10px;
`

const TextsContainer = styled.div`
  display: flex;
  width: 100%;
`

const SectionDropdown = ({
  headerId,
  headerText,
  subHeaderText,
  headerFontSize,
  showCaret,
  children,
  isOpenDefault,
  customContentContainerStyles,
}) => {
  const [isOpen, updateOpen] = useState(isOpenDefault)

  return (
    <Container customContentContainerStyles={customContentContainerStyles}>
      <HeaderContainer onClick={() => showCaret && updateOpen(!isOpen)}>
        <TextsContainer>
          <HeaderText id={headerId} headerFontSize={headerFontSize}>
            {headerText}
          </HeaderText>
          <SubheaderText>{subHeaderText}</SubheaderText>
        </TextsContainer>
        {showCaret && <Caret direction={isOpen ? 'up' : 'down'} />}
      </HeaderContainer>
      {isOpen && <ContentContainer>{children}</ContentContainer>}
    </Container>
  )
}

SectionDropdown.defaultProps = {
  headerId: '',
  headerText: '',
  subHeaderText: '',
  showCaret: false,
  headerFontSize: '13px',
  isOpenDefault: true,
  customContentContainerStyles: {},
}

SectionDropdown.propTypes = {
  headerId: PropTypes.string,
  headerText: PropTypes.string,
  subHeaderText: PropTypes.string,
  headerFontSize: PropTypes.string,
  showCaret: PropTypes.bool,
  isOpenDefault: PropTypes.bool,
}

export default SectionDropdown
