import { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as ProductInventoryActions from 'mgr/pages/single-venue/settings/actions/ordering/ProductInventoryActions'
import ProductControls from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductControls'
import { CreateProductModal } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/CreateProductModal'
import { ProductInventoryTable } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/ProductInventory/ProductInventoryTable'
import { MiddlewareSource, PRODUCT_TYPES_DISPLAY_NAMES } from 'mgr/pages/single-venue/settings/constants'
import { selectOrderingProductInventoryList } from 'mgr/pages/single-venue/settings/selectors/productInventory'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'
import type { PRODUCT_TYPES, PRODUCT_TYPES_ALL } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { ProductInventoryItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'
import type { RouteComponentProps } from 'react-router'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

const Container = styled.div`
  padding: ${props => props.theme.gutter.triple};
`

export interface ProductInventoryProps extends RouteComponentProps {
  venue: Venue
  venues: UserDomainVenue[]
  sorted: SortColumn[]
  isLoading: boolean
  productStateId: PRODUCT_TYPES_ALL
  onChangeVenue: (venue: UserDomainVenue) => void
  orderingInventoryList: ProductInventoryItem[]
  loadProductInventoryByVenueId: (venueId: string) => void
  selectProductInventoryStateTab: (productStateId: PRODUCT_TYPES_ALL) => void
  setProductInventoryFilterSearchValue: (value: string) => void
  setProductInventorySortedColumns: (data: SortColumn[]) => void
  deleteProductInventory: (venueId: string, menuId: string, productType: PRODUCT_TYPES) => void
  isCreateProductModalOpen: boolean
  openCreateProductModal: () => void
  closeCreateProductModal: () => void
}

function ProductInventory({
  venue,
  venues,
  isLoading,
  orderingInventoryList,
  productStateId,
  onChangeVenue,
  loadProductInventoryByVenueId,
  sorted,
  history,
  setProductInventorySortedColumns,
  deleteProductInventory,
  selectProductInventoryStateTab,
  setProductInventoryFilterSearchValue,
  isCreateProductModalOpen,
  openCreateProductModal,
  closeCreateProductModal,
}: ProductInventoryProps) {
  const isNoPosIntegratedVenue = venue.orderingMiddlewareSource === MiddlewareSource.NO_POS
  useEffect(() => {
    if (!isLoading) {
      loadProductInventoryByVenueId(venue.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadProductInventoryByVenueId, venue.id])

  const data = useMemo(
    () => orderingInventoryList.filter(product => productStateId === 'ALL' || product.type === productStateId),
    [orderingInventoryList, productStateId]
  )

  const getTabLabel = useCallback(
    (productType: PRODUCT_TYPES_ALL) => {
      const count =
        productType === 'ALL'
          ? orderingInventoryList.length
          : orderingInventoryList && orderingInventoryList.filter(item => item.type === productType).length
      return `${PRODUCT_TYPES_DISPLAY_NAMES[productType]}${count ? ` (${count})` : ''}`
    },
    [orderingInventoryList]
  )

  return (
    <ContentLayout
      title="Product Inventory"
      venue={venue}
      venues={venues}
      onChangeVenue={onChangeVenue}
      Actions={
        isNoPosIntegratedVenue && (
          <Button
            variant="primary"
            icon="VMSWeb-add"
            onClick={openCreateProductModal}
            id="create-new-product"
            data-test="create-new-product"
          >
            New Product
          </Button>
        )
      }
    >
      <Container data-test="product-inventory-container">
        <ProductControls
          tabs={[
            {
              id: 'ALL',
              text: getTabLabel('ALL'),
            },
            {
              id: 'MENU_ITEM',
              text: getTabLabel('MENU_ITEM'),
            },
            {
              id: 'MODIFIER_GROUP',
              text: getTabLabel('MODIFIER_GROUP'),
            },
            {
              id: 'MODIFIER',
              text: getTabLabel('MODIFIER'),
            },
          ]}
          selectedTabId={productStateId}
          onSelectedTabChange={(value: string) => selectProductInventoryStateTab(value as PRODUCT_TYPES_ALL)}
          onProductFilterSearchChange={setProductInventoryFilterSearchValue}
        />
        <ProductInventoryTable
          isLoading={isLoading}
          productList={data}
          sorted={sorted}
          onSortedChange={setProductInventorySortedColumns}
          onDeleteProduct={deleteProductInventory}
          venue={venue}
        />
      </Container>
      <Window active={isCreateProductModalOpen}>
        <CreateProductModal
          onDiscard={closeCreateProductModal}
          onSave={(product: PRODUCT_TYPES) => {
            history.push(`/manager2/${venue.urlKey}/settings/ordering/inventory/product/${product.toLowerCase()}/`)
          }}
        />
      </Window>
    </ContentLayout>
  )
}

const mapStateToProps = (state: State) => {
  const { userDomain, venue } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    venue,
    venues,
    isLoading: state.orderingProductInventory.isLoading,
    isCreateProductModalOpen: state.orderingProductInventory.isCreateProductModalOpen,
    orderingInventoryList: selectOrderingProductInventoryList(state),
    sorted: state.orderingProductInventory.sorted,
    productStateId: state.orderingProductInventory.productStateId,
  }
}

const mapDispatchToProps = {
  onChangeVenue: GlobalActions.onChangeVenue,
  loadProductInventoryByVenueId: ProductInventoryActions.loadProductInventoryByVenueId,
  selectProductInventoryStateTab: ProductInventoryActions.selectProductInventoryStateTab,
  setProductInventorySortedColumns: ProductInventoryActions.setProductInventorySortedColumns,
  deleteProductInventory: ProductInventoryActions.removeProductItem,
  setProductInventoryFilterSearchValue: ProductInventoryActions.setProductInventoryFilterSearchValue,
  openCreateProductModal: ProductInventoryActions.openCreateProductModal,
  closeCreateProductModal: ProductInventoryActions.closeCreateProductModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInventory)
