import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack, HStack, Image } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText, Header } from '@sevenrooms/core/ui-kit/typography'
import gradient1 from '../../assets/gradient1.png'
import gradient2 from '../../assets/gradient2.png'
import upsell1 from '../../assets/upsell1.png'
import upsell2 from '../../assets/upsell2.png'
import { insightsMessages } from '../../locales'
import { variantColor } from '../../utils/insightStatusUtils'

export function InsightUpsell({ hiddenCount }: { hiddenCount: number }) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="none" pt="s">
      <Box pt="s" mr="m" ml="m" mb="none" borderRadius="s" boxShadow="primaryUp" />
      <Box pt="s" mr="s" ml="s" mb="none" borderRadius="s" backgroundColor="primaryBackground" boxShadow="primaryUp" />
      <Box
        p="none"
        borderRadius="s"
        backgroundColor="primaryBackground"
        backgroundImage={hiddenCount > 0 ? gradient1 : gradient2}
        boxShadow="secondaryUp"
        overflow="hidden"
      >
        <HStack justifyContent="space-between">
          <Box mr="lm" p="lm">
            <Header>
              {hiddenCount > 0 ? (
                <Box pt="xxs" pr="s" pb="xxs" pl="s" mr="s" display="inline" borderRadius="s" backgroundColor={variantColor.new}>
                  <Icon name="VMSWeb-lock" size="sm" color="primaryBackground" /> <Text color="primaryBackground">{hiddenCount}</Text>
                </Box>
              ) : (
                <Box display="inline" mr="s">
                  <Icon name="VMSWeb-lock" size="sm" color="sevenXTheme" />
                </Box>
              )}
              {formatMessage(hiddenCount > 0 ? insightsMessages.newInsightWaiting : insightsMessages.insightWaiting)}
            </Header>
            <Box mt="m">
              <SecondaryText>{formatMessage(insightsMessages.insightUpsell)}</SecondaryText>
            </Box>
          </Box>
          <Box textAlign="right">
            <Image width={105} height={90} src={hiddenCount > 0 ? upsell1 : upsell2} alt={formatMessage(insightsMessages.insightWaiting)} />
          </Box>
        </HStack>
      </Box>
    </VStack>
  )
}
