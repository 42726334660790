import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { fetchBookingChannelSettings, saveBookingChannelSettings } from 'mgr/lib/services/BookingChannelServices'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { batchActions } from 'svr/common/ReduxUtils'

import { srPost, srUpload } from '@sevenrooms/core/api'

export const CHANNEL_KEY_RESY = 'resy'

export const tryLoadBookingChannelSettings = () => (dispatch, getState) => {
  const state = getState()
  if (state.bookingChannels.isInitialized) {
    return
  }
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.LOAD_BOOKING_CHANNEL_SETTINGS_START,
  })

  return fetchBookingChannelSettings({ venue_id: venue.id })
    .then(response =>
      dispatch({
        type: ActionTypes.LOAD_BOOKING_CHANNEL_SETTINGS_SUCCESS,
        channelSettings: response.data.channel_settings,
        channelFormOptions: response.data.channel_form_options,
      })
    )
    .catch(e => {
      console.error('An error occurred fetching booking channel configurations', e)
      dispatch({
        type: ActionTypes.LOAD_BOOKING_CHANNEL_SETTINGS_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Fetching booking channel configurations failed, please try again or contact support'))
      return e
    })
}

/** ************* */
/* Edit Actions */
/** ************* */

export const selectBookingChannelEdit = channelKey => ({
  type: ActionTypes.SELECT_BOOKING_CHANNEL_EDIT,
  channelKey,
})

export const tryDeactivateBookingChannel = channelKey => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.DEACTIVATE_BOOKING_CHANNEL_START,
  })

  const state = getState()
  const venueId = state.appState.venue.id
  const bookingChannelSettings = {
    is_active: false,
  }

  return saveBookingChannelSettings({
    venueId,
    channelKey,
    bookingChannelSettings,
  })
    .then(response => {
      const showMsgLevel = response.result == 'success' ? GlobalActions.showSuccessMessage : GlobalActions.showWarnMessage
      dispatch(
        batchActions([
          {
            type: ActionTypes.DEACTIVATE_BOOKING_CHANNEL_SUCCESS,
            channelKey,
          },
          showMsgLevel(response.message),
        ])
      )
      window.metric.track(`Settings.BookingChannels.Deactivate.${channelKey}.${response.result}`)
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage(`Unable to deactivate booking channel, please try again later. ${e}`))
      dispatch({
        type: ActionTypes.DEACTIVATE_BOOKING_CHANNEL_FAIL,
      })
      return e
    })
}

export const cancelBookingChannelEdit = () => ({
  type: ActionTypes.CANCEL_BOOKING_CHANNEL_EDIT,
})

export const changeBookingChannelField = (channelKey, field, value) => ({
  type: ActionTypes.CHANGE_BOOKING_CHANNEL_FIELD,
  channelKey,
  field,
  value,
})

export const bookingChannelFormValidated = formErrors => ({
  type: ActionTypes.BOOKING_CHANNEL_FORM_VALIDATED,
  formErrors,
})

export const bookingChannelDismissErrorDisplay = () => ({
  type: ActionTypes.BOOKING_CHANNEL_DISMISS_ERROR_DISPLAY,
})

export const BOOKING_CHANNEL_DEACTIVATED = 'BOOKING_CHANNEL_DEACTIVATED'

export const trySaveDraftBookingChannelSettings = channelKey => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_START,
  })

  const state = getState()
  const venueId = state.appState.venue.id
  const bookingChannelSettings = state.bookingChannels.channelSettings[channelKey]

  return saveBookingChannelSettings({
    venueId,
    channelKey,
    bookingChannelSettings,
  })
    .then(response => {
      const showMsgLevel = response.result == 'success' ? GlobalActions.showSuccessMessage : GlobalActions.showWarnMessage
      dispatch(
        batchActions([
          {
            type: ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_SUCCESS,
            is_active: bookingChannelSettings.is_active,
          },
          showMsgLevel(response.message),
        ])
      )
      window.metric.track(`Settings.BookingChannels.SaveDraft.${channelKey}.${response.result}`)
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage(`Unable to save booking channel. ${e}`))
      dispatch({
        type: ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_FAIL,
      })
      return e
    })
}

export const trySaveBookingChannelSettings = channelKey => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_START,
  })

  const state = getState()
  const venueId = state.appState.venue.id
  const prevBookingChannelSettings = state.bookingChannels.channelSettings[channelKey]
  const bookingChannelSettings = {
    ...prevBookingChannelSettings,
    is_active: true,
  }

  return saveBookingChannelSettings({
    venueId,
    channelKey,
    bookingChannelSettings,
  })
    .then(response => {
      const showMsgLevel = response.result == 'success' ? GlobalActions.showSuccessMessage : GlobalActions.showWarnMessage
      dispatch(
        batchActions([
          {
            type: ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_SUCCESS,
            is_active: response.result == 'success' ? true : prevBookingChannelSettings.is_active,
          },
          showMsgLevel(response.message),
        ])
      )
      window.metric.track(`Settings.BookingChannels.Publish.${channelKey}.${response.result}`)
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage(`Unable to save booking channel. ${e}`))
      dispatch({
        type: ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_FAIL,
      })
      return e
    })
}

/** ************** */
/* Image Actions */
/** ************** */

export const loadCropEdit = photoIdx => ({ type: ActionTypes.BOOKING_CHANNEL_IMAGE_CROPPING, photoIdx })

const uploadPhotoSuccess = (uploadData, aspect) => ({
  type: ActionTypes.BOOKING_CHANNEL_IMAGE_UPLOAD_SUCCESS,
  uploadData,
  aspect,
})

export const uploadPhoto = (files, photoId, aspect) => (dispatch, getState) => {
  const imageFile = files[0]

  // if file size is greater than 8 MB, show them an error
  if (imageFile.size / 1048576 > 8) {
    dispatch(
      batchActions([
        { type: ActionTypes.BOOKING_CHANNEL_IMAGE_UPLOAD_ERROR },
        GlobalActions.showErrorMessage('Unable to save image, please try uploading an image sized less than 8MB.'),
      ])
    )

    return
  }

  dispatch({ type: ActionTypes.BOOKING_CHANNEL_IMAGE_UPLOAD_START })
  return srPost(`/upload-url`, { rurl: Math.random().toString() }, { convertToGetParams: true })
    .then(response => (response.error ? response : srUpload(response.upload_url, imageFile)))
    .then(uploadData => {
      const { bookingChannels } = getState()
      const photoIdx = bookingChannels.channelSettings[bookingChannels.activeChannelKey].images.length
      dispatch(batchActions([uploadPhotoSuccess(uploadData, aspect), loadCropEdit(photoIdx)]))
    })
}

export const cropImage = (photoIdx, coords) => ({
  type: ActionTypes.BOOKING_CHANNEL_IMAGE_CROP_PHOTO,
  photoIdx,
  coords,
})

export const closeCrop = () => ({ type: ActionTypes.BOOKING_CHANNEL_IMAGE_CROPPING, photoIdx: -1 })

export const saveCrop = () => ({ type: ActionTypes.BOOKING_CHANNEL_IMAGE_CROPPING, photoIdx: -1 })

export const editPhoto = (photoIdx, aspect) => ({
  type: ActionTypes.BOOKING_CHANNEL_IMAGE_EDIT_PHOTO,
  photoIdx,
  aspect,
})

export const deletePhoto = photoIdx => ({ type: ActionTypes.BOOKING_CHANNEL_IMAGE_DELETE_PHOTO, photoIdx })
