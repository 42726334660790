export enum DateFilterEnum {
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  ALL = 'ALL',
}
export type DateFilter = keyof typeof DateFilterEnum
