import { useMemo, useState } from 'react'
import { MenuItemSearchField } from 'mgr/pages/single-venue/settings/components/ordering/MenuManagement/MenuBuilder/AddMenuItem/MenuItemSearchField'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import type { MenuCategory, MenuCategoryItem } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface AddMenuItemModalProps {
  venue: Venue
  category: MenuCategory
  menuItems: MenuItem[]
  tooltipId: string
  onAddMenuItemModalClose: () => void
  onAddMenuItemModalSave: (category: MenuCategory, menuItems: MenuCategoryItem[]) => void
}

export function AddMenuItemModal({ category, venue, menuItems, onAddMenuItemModalClose, onAddMenuItemModalSave }: AddMenuItemModalProps) {
  const [selectedMenuItems, setSelectedMenuItems] = useState<MenuCategoryItem[]>([])
  const isSaveDisabled = useMemo(() => !selectedMenuItems.length, [selectedMenuItems])

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onAddMenuItemModalClose()}>
        <ModalTitle title="Add Menu Item" subTitle="Start searching or adding menu items into the category" />
      </ModalHeader>
      <ModalBody>
        <MenuItemSearchField {...{ category, venue, menuItems, selectedMenuItems, setSelectedMenuItems }} />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onAddMenuItemModalClose} data-test="sr-menu-builder-add-menu-item-modal-cancel">
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isSaveDisabled}
            onClick={() => onAddMenuItemModalSave(category, [...category.items, ...selectedMenuItems])}
            data-test="sr-menu-builder-add-menu-item-modal-save"
          >
            {selectedMenuItems.length > 1 ? `Add ${selectedMenuItems.length} Items` : 'Add Item'}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
