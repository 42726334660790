import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { useTaxGroups } from 'svr/common/hooks/useTaxGroups'
import Input from 'svr/component-lib/Generic/TextInputs/Input'

const CUSTOM_VALUE_ID = 'CUSTOM_VALUE_ID'
const CUSTOM_DROPDOWN_OPTION = {
  value: CUSTOM_VALUE_ID,
  name: 'Custom Tax',
}

const DropdownTitle = styled.div`
  font-weight: 500;
  padding-bottom: ${props => props.theme.gutter.double};
`

const InputTitle = styled.div`
  padding-top: 12px;
  padding-bottom: 4px;
`

const CustomFieldWrapper = styled.div`
  padding-left: ${props => props.theme.gutter.triple};
`

export interface ProductTaxFieldProps {
  taxRate?: number
  taxGroupId?: string
  isValid?: boolean
  dataTest: string
  onTaxRateChange: (value: number) => void
  onTaxGroupIdChange: (value: string) => void
}

interface TaxGroup {
  id: string
  name: string
}

interface Option {
  name: string
  value: string
}

export function ProductTaxField({ taxRate, taxGroupId, isValid, dataTest, onTaxRateChange, onTaxGroupIdChange }: ProductTaxFieldProps) {
  const { taxGroups }: { taxGroups: TaxGroup[] } = useTaxGroups()

  const options = useMemo(() => {
    let options: Option[] = []
    if (taxGroups) {
      options = taxGroups.map(taxGroup => ({
        name: taxGroup.name,
        value: taxGroup.id,
      }))
    }
    options.push(CUSTOM_DROPDOWN_OPTION)
    return options
  }, [taxGroups])

  const selectedOption = useMemo(() => {
    const selectedOption = options.filter(option => option.value === taxGroupId)[0]
    return (selectedOption && selectedOption.value) || CUSTOM_VALUE_ID
  }, [taxGroupId, options])

  const onSelectOption = useCallback(
    (value: string) => {
      if (value === CUSTOM_VALUE_ID) {
        onTaxRateChange(0)
      } else {
        onTaxGroupIdChange(value)
      }
    },
    [onTaxGroupIdChange, onTaxRateChange]
  )

  const onCustomTaxFieldChange = useCallback(
    (value: string) => {
      onTaxRateChange(Number(value))
    },
    [onTaxRateChange]
  )

  useEffect(() => {
    if (_.isNil(taxRate) && _.isNil(taxGroupId) && options[0]) {
      onTaxGroupIdChange(options[0].value)
    }
  }, [onTaxGroupIdChange, options, taxGroupId, taxRate])

  return (
    <>
      <DropdownTitle>How much tax should be applied on this checkout fee?</DropdownTitle>
      <DropdownArrowsPicker
        noHeader
        testId={`${dataTest}-dropdown`}
        placeholder="Tax Group"
        choices={options}
        value={selectedOption}
        onChangeHandler={onSelectOption}
        useOutsideLabel
        isLightTheme
        height={40}
        style={{
          width: '100%',
        }}
      />
      {selectedOption === CUSTOM_VALUE_ID && (
        <CustomFieldWrapper>
          <InputTitle>Custom tax amount</InputTitle>
          <Input
            data-test={`${dataTest}-custom-input`}
            isValid={isValid}
            value={taxRate}
            onChange={onCustomTaxFieldChange}
            type="number"
            min={0}
            max={100}
            suffix="%"
          />
        </CustomFieldWrapper>
      )}
    </>
  )
}
