import { useLocales } from '@sevenrooms/core/locales'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../../locales'
import { InsightDetailSlideoutSectionsContainer } from '../../../InsightDetailSlideoutSections'
import { LogoSuggestedActions } from '../../../InsightSuggestedActionsSectionContent'
import { FeatureEnablementProofSection } from '../../../Proofs'
import type { DetailProps } from '../../../../utils'

export function LogoDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { insight } = data

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.featureUsageTitle)}
      description={formatMessage(insightsMessages.featureUsageLogoText)}
      proofLabel={formatMessage(insightsMessages.featureUsageLogoProofLabel)}
      suggestedActionsComponent={<LogoSuggestedActions venueUrlKey={venue.urlKey} />}
      proofComponent={
        <FeatureEnablementProofSection
          title={formatMessage(insightsMessages.logoUploadProofTitle)}
          lastCheckedDate={insight.createdDatetime}
        />
      }
    />
  )
}
