import React from 'react'
import { css } from 'styled-components'
import TextInput from '../TextInput'

const InputWrapper = css`
  width: 80px;
  margin-right: 2px;
`

const InputText = css`
  box-sizing: border-box;
  height: 42px;
  border-radius: 4px;
  border-width: 1px;
  font-size: ${props => props.theme.fontSize.body};
`

const TinyWidthInput = ({ ...etc }) => <TextInput {...etc} customInputWrapper={InputWrapper} customInput={InputText} />

export default TinyWidthInput
