import React from 'react'
import styled from 'styled-components'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'

export const InnerModal = styled.div`
  background: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.five};
  display: flex;
  flex-direction: column;
  height: 90%;
  left: 10%;
  position: absolute;
  top: 5%;
  width: 80%;
  z-index: 10;
`

export const ModalTopBar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 0 0 30px;
  background: ${props => props.theme.color.lightGrey};
`

export const ModalTitle = styled.h1`
  font-size: ${props => props.theme.fontSize.subheader};
  text-align: left;
`

export const ModalParagraph = styled.div`
  font-size: ${props => props.theme.fontSize.body};
`

export const ModalCloser = styled(StyledVmsIconS)`
  color: ${props => props.theme.color.black};
  cursor: pointer;
  font-size: 16px;
  margin: auto ${props => props.theme.gutter.standard};
`

export const InnerModalContent = styled.div`
  flex-grow: 1;
  margin: 0 50px 20px;
  overflow: auto;
`

export const ModalActions = styled.div`
  display: flex;
  flex: 0 0 44px;
  justify-content: right;
  padding: ${props => props.theme.gutter.double};
`

export const ModalConfirm = styled.div`
  ${props => props.theme.primaryButton};
  background: ${props => props.theme.color.teal};
  line-height: 44px;
  width: 90px;
  :hover {
    opacity: 0.8;
  }
`

export const ModalCancel = styled.div`
  color: ${props => props.theme.color.teal};
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.body};
  line-height: 44px;
  margin-left: auto;
  text-align: center;
  width: 120px;
`

const CommonInnerModal = styled(InnerModal)`
  bottom: auto;
  height: auto;
`

export const CommonModal = ({ className, toggler, action, actionText, title, content, visibility }) =>
  visibility ? (
    <CommonInnerModal className={className}>
      {toggler !== undefined && (
        <ModalTopBar className="modal-top-bar">
          <ModalCloser onClick={toggler}>{VmsIcons.Close}</ModalCloser>
        </ModalTopBar>
      )}
      <InnerModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalParagraph>{content}</ModalParagraph>
      </InnerModalContent>
      <ModalActions className="modal-actions-container">
        {toggler !== undefined && (
          <ModalCancel className="modal-cancel" onClick={toggler}>
            Cancel
          </ModalCancel>
        )}
        {action !== undefined && (
          <ModalConfirm className="modal-confirm" onClick={action}>
            {actionText}
          </ModalConfirm>
        )}
      </ModalActions>
    </CommonInnerModal>
  ) : null
