import { useCallback, useState } from 'react'
import { useLazyGetAccessRulesListQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { DatePicker } from '@sevenrooms/react-components/components/DatePicker'
import { useAvailabilityUrlParams, useVenueTodayDate } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'
import {
  getSelectedAccessRuleOptions,
  transformAccessRuleDataToOptions,
  hasDurationOverride,
  filterAccessRuleDataByDate,
} from './AccessRuleHelpers'
import { StandardInput } from './StandardInput'

export function DateInput() {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const [getAccessRuleList] = useLazyGetAccessRulesListQuery()

  const venueTodayDate = useVenueTodayDate()
  const [{ date, accessPersistentIds }, setParams] = useAvailabilityUrlParams()
  const [initialDate] = useState(date.toJsDate())
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)

  const onChange = useCallback(
    async (d: Date | null) => {
      if (d == null) {
        return
      }

      const date = DateOnly.fromDate(d)
      const iso8601 = date.toIso()
      const updatedAcessRules = await getAccessRuleList({ venueId, startDate: iso8601 }).unwrap()
      const availableAccessRules = filterAccessRuleDataByDate(updatedAcessRules, iso8601)
      const newOptions = transformAccessRuleDataToOptions(availableAccessRules)
      const updatedAccessPersistentIds = getSelectedAccessRuleOptions(newOptions, accessPersistentIds).map(ar => ar.value)

      if (hasDurationOverride(availableAccessRules)) {
        setParams({ date, accessPersistentIds: updatedAccessPersistentIds })
      } else {
        setParams({ date, accessPersistentIds: updatedAccessPersistentIds, durationOverride: undefined })
      }
    },
    [venueId, getAccessRuleList, setParams, accessPersistentIds]
  )

  return (
    <StandardInput htmlFor="aqv-date" label={formatMessage(availabilityDebuggerMessages.dateLabel)}>
      <DatePicker
        open={datePickerOpen}
        onOpen={() => setDatePickerOpen(true)}
        onClose={() => setDatePickerOpen(false)}
        defaultValue={initialDate}
        minDate={venueTodayDate.toJsDate()}
        onChange={onChange}
        slotProps={{
          textField: {
            id: 'aqv-date',
            fullWidth: true,
            inputProps: {
              readOnly: true,
              onClick: () => setDatePickerOpen(!datePickerOpen),
            },
          },
        }}
      />
    </StandardInput>
  )
}
