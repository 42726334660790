import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { onChangeVenue } from 'mgr/lib/actions/DirtyStateUnloader'
import DirtyStateUnloader from 'mgr/lib/components/DirtyStateUnloader'
import * as BrandSettingsActions from 'mgr/pages/single-venue/settings/actions/ordering/BrandSettingsActions'
import * as styles from 'mgr/pages/single-venue/settings/assets/styles'
import HeaderCropper from 'mgr/pages/single-venue/settings/containers/ordering/HeaderCropper'
import HeaderImageUpload from 'mgr/pages/single-venue/settings/containers/ordering/HeaderImageUpload'
import ColorPicker from 'svr/common/ColorPicker'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { SaveButton } from 'svr/component-lib/Manager/Layout/LanguageContent'

const Container = styled.div`
  margin: 26px 31px;
`

const Header = styled.div`
  font-size: 16px;
  color: ${props => props.theme.black};
  margin-bottom: ${props => props.theme.gutter.triple};
  clear: both;
`

const SubHeader = styled.div`
  font-size: ${props => props.theme.fontSize.small};
  color: #999999;
  clear: both;
`

const SettingsSection = styled.div`
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: ${props => props.theme.padding.medium};
  margin: ${props => props.theme.gutter.double} 0;
  max-width: 885px;
`

class BrandSettings extends PureComponent {
  componentDidMount() {
    const { loadBrandSettings } = this.props.actions
    loadBrandSettings()
  }

  async handleSaveSettings() {
    const { saveBrandSettings } = this.props.actions
    await saveBrandSettings()
  }

  updateFieldByName(fieldName, value) {
    const { updateBrandSettingsField } = this.props.actions
    updateBrandSettingsField(fieldName, value)
  }

  render() {
    const { venue, venues, brandSettings } = this.props
    const { onChangeVenue } = this.props.actions
    return (
      <DirtyStateUnloader isDirty={brandSettings.isDirty} modalQuestionContext="this brand settings">
        <ContentLayout
          title="Brand Settings"
          venue={venue}
          venues={venues}
          onChangeVenue={onChangeVenue}
          overrideChangeVenue
          Actions={
            <SaveButton
              onClick={async () => {
                await this.handleSaveSettings()
              }}
              id="save-brand-settings-button"
              data-test="sr-save-button"
            >
              Save and Publish
            </SaveButton>
          }
        >
          <Container>
            <SettingsSection>
              <Header>Logo</Header>
              <SubHeader>
                Ordering sites will be using the same logo that you uploaded in{' '}
                <Link data-test="sr-link-general-settings" to={`/manager/${venue.urlKey}/settings/venue`}>
                  general settings
                </Link>
                .
              </SubHeader>
            </SettingsSection>

            <SettingsSection>
              <Header>Header image</Header>
              <SubHeader>Header image</SubHeader>

              <HeaderImageUpload
                imageType="header"
                url={brandSettings.headerImageUrl}
                cropData={brandSettings.headerImageCropData}
                width={490}
                height={210}
                style={{ marginBottom: '20px' }}
              />
              {brandSettings.isCropping === 'header' && (
                <HeaderCropper imageType="header" url={brandSettings.headerImageUrl} cropData={brandSettings.headerImageCropData} />
              )}
            </SettingsSection>
            <Header>Main colors</Header>
            <div style={styles.SettingRow}>
              <div style={styles.SettingColTitle}>
                <p className="group-label">Primary color</p>
              </div>
              <div style={styles.SettingCol}>
                <ColorPicker
                  color={{ hex: brandSettings.colorPrimary, opacity: 1.0 }}
                  onChangeColor={color => this.updateFieldByName('colorPrimary', color.hex)}
                  dataTest="sr-color-picker-btn-primary"
                />
              </div>
            </div>
            <div style={styles.SettingRow}>
              <div style={styles.SettingColTitle}>
                <p className="group-label">Links color</p>
              </div>
              <div style={styles.SettingCol}>
                <ColorPicker
                  color={{ hex: brandSettings.fontsColorLinks, opacity: 1.0 }}
                  onChangeColor={color => this.updateFieldByName('fontsColorLinks', color.hex)}
                  dataTest="sr-color-picker-btn-link"
                />
              </div>
            </div>
          </Container>
        </ContentLayout>
      </DirtyStateUnloader>
    )
  }
}

const mapStateToProps = state => {
  const { venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    brandSettings: state.orderingBrandSettings,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onChangeVenue,
      loadBrandSettings: BrandSettingsActions.loadBrandSettings,
      saveBrandSettings: BrandSettingsActions.saveBrandSettings,
      updateBrandSettingsField: BrandSettingsActions.updateBrandSettingsField,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandSettings)
