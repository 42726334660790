import _ from 'lodash'
import Radium from 'radium'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import ManagerStyles from 'mgr/layout/Styles'
import StyleUtils from 'mgr/layout/StyleUtils'
import { getIconForStatus } from 'mgr/lib/utils/Statuses'
import { getDateForActual } from 'mgr/pages/shared/utils/Actuals'
import {
  statusDropDownOpenedAction,
  statusDropDownClosedAction,
  tryPostSaveResTableAssignmentAction,
  tryPostSaveResDurationAction,
  showConflictDialogAction,
  closeConflictDialogAction,
  moveActualAction,
} from 'mgr/pages/single-venue/dayview/actions/Actions'
import { CommonStyles, GridStyles } from 'mgr/pages/single-venue/dayview/assets/Styles'
import { merge } from 'svr/common/SRUtils'
import { zeroIndexMonth, isPastTime, getDurationDisplay } from 'svr/common/TimeUtil'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'

const GridResCellStyles = {
  body: merge(
    {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 30,
      height: GridStyles.ReservationRowHeight - 4,
      lineHeight: `${GridStyles.ReservationRowHeight - 4}px`,
      top: 2,
      left: 2,
      color: ManagerStyles.AxesFontColor,
      backgroundColor: '#DEE1E4',
      fontSize: '13px',
      cursor: 'pointer',
      overflow: 'hidden',
    },
    StyleUtils.rounded('6px')
  ),
  hover: merge(
    {
      top: 1,
      left: 1,
      border: '1px solid #888',
      zIndex: '31',
    },
    StyleUtils.shadow('rgba(50,50,50,0.37)', '0px', '9px')
  ),
  buffer: {
    height: '100%',
    opacity: '0.25',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  vip: {
    position: 'absolute',
    boxSizing: 'border-box',
    borderRight: '2px solid white',
    height: 20,
    width: 20,
    backgroundColor: CommonStyles.ClientVipColor,
    top: -10,
    left: -10,
    transform: 'rotate(45deg)',
    zIndex: '2',
  },
  statusIcon: merge(
    {
      display: 'inline-block',
      textAlign: 'center',
      top: 2,
      fontSize: '19px',
      position: 'relative',
      padding: 2,
      marginRight: 5,
      marginLeft: 3,
      ':hover': {
        background: '#ccc',
      },
    },
    StyleUtils.rounded('6px')
  ),
  guests: {
    display: 'inline-block',
    fontWeight: 600,
    width: 30,
  },
  name: {
    display: 'inline-block',
    marginRight: 15,
  },
  icon: {
    display: 'inline-block',
    marginRight: 3,
  },
  resTag: {
    fontSize: '16px',
  },
  resTagsIcon: {
    letterSpacing: '-7px',
    marginRight: 10,
    position: 'relative',
    top: 2,
  },
  lock: merge(
    {
      float: 'right',
      padding: 3,
      position: 'relative',
      fontSize: '17px',
      opacity: 0.6,
      marginRight: 0,
      ':hover': {
        opacity: 0.85,
      },
    },
    StyleUtils.rounded('3px')
  ),
  drag: merge(
    {
      fontSize: '17px',
      float: 'right',
      display: 'inline-block',
      right: 4,
      top: 3,
      padding: 3,
      position: 'relative',
      marginLeft: 4,
      cursor: 'ew-resize',
      opacity: 0.0,
      ':hover': {
        opacity: 0.85,
      },
    },
    StyleUtils.rounded('3px'),
    StyleUtils.Unselectable
  ),
  move: merge(
    {
      fontSize: '17px',
      float: 'right',
      display: 'inline-block',
      right: 4,
      top: 3,
      padding: 3,
      position: 'relative',
      cursor: 'pointer',
      opacity: 0.3,
      ':hover': {
        opacity: 0.85,
      },
    },
    StyleUtils.rounded('3px')
  ),
  combos: {
    position: 'relative',
    float: 'right',
    top: 1,
    marginLeft: 4,
    marginRight: 4,
    maxWidth: 'calc(100% - 55px)',
    minWidth: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  combosIcon: {
    fontSize: '14px',
  },
  resizer: merge(
    {
      zIndex: '32',
    },
    StyleUtils.Unselectable
  ),
  arrivalTime: {
    display: 'inline-block',
    paddingLeft: 10,
  },
  duration: {
    display: 'inline-block',
    float: 'right',
    right: 4,
  },
  actionBar: {
    position: 'absolute',
    display: 'inline-block',
    right: 0,
    backgroundColor: 'transparent',
    height: '100%',
    maxWidth: '40%',
    zIndex: 10,
    paddingLeft: 10,
  },
  lockContainer: {
    display: 'inline-block',
    position: 'relative',
    float: 'right',
    top: 3,
    marginRight: 3,
    borderRadius: 6,
  },
}

class GridResCell extends PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleDropDownClick = this.handleDropDownClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.handleLockClick = this.handleLockClick.bind(this)
    this.handleMoveClick = this.handleMoveClick.bind(this)

    this.handleDurationMouseDown = this.handleDurationMouseDown.bind(this)
    this.onDurationMouseUp = this.onDurationMouseUp.bind(this)
    this.onDurationMouseMove = this.onDurationMouseMove.bind(this)

    this.state = {
      hover: false,
      isDurationDragging: false,
      resizeDuration: 0,
      isSavingDuration: false,
      isMoving: false,
    }
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.containerRef = null
    this.mouseMoveDebounced = _.debounce(this.onDurationMouseMove, 1)
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.mouseMoveDebounced, false)
    window.addEventListener('mouseup', this.onDurationMouseUp, false)
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.mouseMoveDebounced, false)
    window.removeEventListener('mouseup', this.onDurationMouseUp, false)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isDurationDragging: false,
      isSavingDuration: false,
      hover: false,
      isMoving: nextProps.isActualMoveMode,
    })
  }

  onDurationMouseUp(ev) {
    const { actual, venue } = this.props
    const duration = actual.duration + (actual.buffer_mins || 0)
    if (this.state.resizeDuration === duration) {
      this.setState({ isSavingDuration: false, isDurationDragging: false })
      ev.stopPropagation()
      return
    }
    if (this.state.isDurationDragging && !this.state.isSavingDuration) {
      let { resizeDuration } = this.state
      resizeDuration -= actual.buffer_mins || 0

      const conflictActual = this.findOverlappedActualForDuration(resizeDuration / 15)
      if (!conflictActual) {
        this.setState({ isSavingDuration: true })
        this.props.actions.saveDuration(venue.id, actual, resizeDuration)
      } else {
        this.setState({ isSavingDuration: true })
        this.props.actions.showConflictDialogAction(
          'Duration conflicts with a locked reservation',
          'Change duration',
          conflictActual,
          tryPostSaveResDurationAction(venue.id, actual.id, resizeDuration),
          closeConflictDialogAction(actual.id)
        )
      }
    }
    ev.stopPropagation()
  }

  onDurationMouseMove(ev) {
    const { actual } = this.props
    if (this.state.isDurationDragging && !this.state.isSavingDuration) {
      const boundingRect = ReactDOM.findDOMNode(this).getBoundingClientRect()
      const durationWidth = ev.clientX - boundingRect.left
      const resizeDuration = ((durationWidth + 4) * 15) / (GridStyles.TimeHeaderWidth + 1 + 1.0 / 6.0)
      const resizeOrders = Math.floor(resizeDuration / 15) + 1
      const resizeOrderMin = 2 + (actual.buffer_mins || 0) / 15
      if (resizeOrders >= resizeOrderMin) {
        this.setState({ resizeDuration: resizeOrders * 15 })
      }
    }
  }

  handleDurationMouseDown(e) {
    const { actual } = this.props
    e.stopPropagation()
    if (actual.is_waitlist_entry) {
      return
    }
    if (!this.state.isDurationDragging && !this.state.isSavingDuration) {
      this.setState({
        isDurationDragging: true,
        resizeDuration: actual.duration + (actual.buffer_mins || 0) || 0,
      })
    }
  }

  onMouseOver() {
    this.setState({ hover: true })
  }

  onMouseOut() {
    this.setState({ hover: false })
  }

  handleClick() {
    const { actual } = this.props
    if (actual.is_waitlist_entry) {
      return
    }
    this.props.gridActions.showActual(actual)
  }

  handleDropDownClick(e) {
    if (this.isPastTime(this.props.actual)) {
      e.stopPropagation()
      return
    }

    if (!this.props.actual.is_waitlist_entry && !this.props.actual.is_in_service) {
      if (!this.props.statusDropDownState.isOpen) {
        const elRect = this.containerRef.getBoundingClientRect()
        this.props.actions.statusDropDownOpened(this.props.actual, elRect)
      }
    }
    e.stopPropagation()
  }

  handleClickOutside() {
    this.setState({ hover: false })
  }

  handleLockClick(e) {
    const { venue, actual, isUnassigned, actions } = this.props
    if (this.isPastTime(actual) || actual.is_in_service) {
      e.stopPropagation()
      return
    }

    if (this.isLockedActual(actual, venue)) {
      if (venue.isAutoAssignEnabled) {
        actions.autoAssignTables(venue.id, actual.id)
      } else {
        actions.unassignTables(venue.id, actual.id)
      }
    } else {
      actions.assignTable(venue.id, actual.id, _.map(actual.computedTableList, 'id'))
    }
    e.stopPropagation()
  }

  handleMoveClick(e) {
    if (this.isPastTime(this.props.actual) || this.props.actual.is_in_service) {
      e.stopPropagation()
      return
    }
    this.setState({ isMoving: true, hover: true })
    const elRect = this.containerRef.getBoundingClientRect()
    this.props.actions.startMoveActual(this.props.venue.id, this.props.date, this.props.actual, elRect)
    e.stopPropagation()
  }

  getResizerWidth() {
    return this.getDurationWidth(this.state.resizeDuration)
  }

  getDurationWidth(duration) {
    const durationOrders = duration / 15
    const durationWidth = durationOrders * GridStyles.TimeHeaderWidth + durationOrders - 4 + Math.floor(durationOrders / 6)
    return durationWidth
  }

  findOverlappedActualForDuration(resizeOrders) {
    const existingEndOrder = this.props.actual.arrival_time_sort_order + this.props.actual.duration / 15
    const newEndOrder = this.props.actual.arrival_time_sort_order + resizeOrders
    const willOverlap = newEndOrder > existingEndOrder && _.has(this.props.usedOrders, newEndOrder)
    if (willOverlap) {
      return this.props.usedOrders[newEndOrder]
    }
    return null
  }

  isLockedActual(actual, venue) {
    const hasNoTables = _.isEmpty(actual.all_table_ids_list)
    const isHardUnassigned = hasNoTables && venue.isAutoAssignEnabled && actual.disable_auto_assign
    const isHardAssigned = !hasNoTables
    return isHardAssigned || isHardUnassigned
  }

  isPastTime(actual) {
    if (actual.is_waitlist_entry) {
      return true
    }
    const endOrder = actual.arrival_time_sort_order + actual.duration / 15
    return isPastTime(endOrder, this.props.venue.timezone, this.props.venue.startOfDayHour, this.props.date)
  }

  renderOccasions(date, occasions) {
    if (_.isEmpty(occasions)) {
      return null
    }
    const isBirthdayToday =
      'birthday' in occasions && zeroIndexMonth(occasions.birthday.month) === date.month() && occasions.birthday.day === date.date()
    const isAnniversaryToday =
      'anniversary' in occasions &&
      zeroIndexMonth(occasions.anniversary.month) === date.month() &&
      occasions.anniversary.day === date.date()
    const result = []
    if (isBirthdayToday) {
      result.push(
        <div key="birthday" style={[VmsIconSizes.xxsmall, GridResCellStyles.icon]}>
          {VmsIcons.Birthday}
        </div>
      )
    }
    if (isAnniversaryToday) {
      result.push(
        <div key="anniversary" style={[VmsIconSizes.xxsmall, GridResCellStyles.icon]}>
          {VmsIcons.Anniversary}
        </div>
      )
    }
    return result
  }

  renderTags(tagGroups, actualId) {
    const tagComponents = []
    let count = 0
    for (const tagGroup of tagGroups || []) {
      for (let idx = 0; idx < tagGroup.tags.length; idx++) {
        const tag = tagGroup.tags[idx]
        tagComponents.push(
          <span
            key={tag.tag_group_id + tag.tag_name}
            data-for={`tip-${actualId}`}
            data-tip={tag.tag_name_display}
            style={[VmsIconSizes.xxsmall, GridResCellStyles.resTag, { color: tag.tag_color }]}
          >
            {count === 0 ? VmsIcons.Tag : VmsIcons.TagMulti}
          </span>
        )
        count++
      }
    }
    return tagComponents
  }

  getIsConfirmedOrInservice(actual) {
    return (
      actual.is_waitlist_entry ||
      actual.is_in_service ||
      (this.props.statusesByDb[actual.status] && this.props.statusesByDb[actual.status].confirmed)
    )
  }

  getIconColor(actual) {
    const statusColor = actual.is_waitlist_entry
      ? '#fff'
      : (this.props.statusesByDb[actual.status] || this.props.statusesByDb.NOT_RECONCILED).color
    return { color: statusColor }
  }

  getBackgroundColor(actual) {
    if (this.getIsConfirmedOrInservice(actual)) {
      return { actualBg: '#ACB5BD', bufferBg: '#092239' }
    }
    return { actualBg: '#DEE1E4', bufferBg: '#576473' }
  }

  renderStatus(actual) {
    return actual.is_waitlist_entry ? VmsIcons.Waitlist : getIconForStatus(actual.status, actual.is_left)
  }

  isTableCombo(actual) {
    return actual.computedTableList.length > 1
  }

  getNextTableInCombo(actual) {
    const tableList = _.sortBy(actual.computedTableList, 'item_code')
    const numTables = tableList.length
    const idx = _.findIndex(tableList, {
      item_code: this.props.rowEntity.item_code,
    })
    const tables = []

    for (let x = 0; x < tableList.length; x++) {
      if (x == idx) {
        continue
      }
      tables.push(tableList[x].item_code)
    }
    /*
    tables.push((idx < numTables-1) ? tableList[idx+1].item_code : tableList[0].item_code)
    if (numTables > 2) {
      idx = (idx == numTables-1) ? 0 : idx+1
      tables.push((idx < numTables-1) ? tableList[idx+1].item_code : tableList[0].item_code)
    }
    */
    tables.sort()
    return tables.join(', ') + (numTables > 3 ? '+' : '')
  }

  render() {
    if (this.state.isDurationDragging) {
      return this.renderResizer()
    }
    return this.renderActual()
  }

  renderResizer() {
    const { actual } = this.props
    const computedStyles = {
      body: merge(GridResCellStyles.body, { width: this.getResizerWidth(actual.duration || 0) }, GridResCellStyles.resizer),
    }
    computedStyles.body.backgroundColor = ManagerStyles.PrimaryBackgroundColor
    computedStyles.body.color = 'white'
    return (
      <div style={[computedStyles.body]}>
        <div style={[GridResCellStyles.arrivalTime]}>{actual.arrival_time_display}</div>
        <div key="_resizer" style={[VmsIconSizes.xxsmall, GridResCellStyles.drag]}>
          {VmsIcons.DragLine}
        </div>
        <div style={GridResCellStyles.duration}>
          {getDurationDisplay(this.state.resizeDuration - (actual.buffer_mins || 0), true, false)}
          {actual.buffer_mins ? ` (+ ${actual.buffer_mins}m)` : ''}
        </div>
      </div>
    )
  }

  renderNameToolTip(actual) {
    const size = 45
    const photo = actual.client_photo && actual.client_photo.medium
    return ReactDOMServer.renderToStaticMarkup(
      <div style={{ textAlign: 'left' }}>
        {photo && (
          <div style={{ float: 'left', marginRight: '8px' }}>
            <img
              src={`/.h/download/${photo}`}
              style={{
                width: size,
                height: size,
                borderRadius: size / 2,
                margin: '3px 5px 0 3px',
                position: 'relative',
              }}
            />
          </div>
        )}
        <div style={{ float: 'left', marginTop: photo ? '7px' : null }}>
          <div style={{ fontSize: 12, fontWeight: 600 }}>{actual.client_display_name}</div>
          <div style={{ fontSize: 12, maxWidth: 300, whiteSpace: 'pre-line' }}>{actual.full_notes}</div>
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    )
  }

  renderActual() {
    const { venue, actual, isUnassigned } = this.props
    const bufferWidth = Math.max(this.getDurationWidth(actual.buffer_mins || 0), 0)
    const { actualBg, bufferBg } = this.getBackgroundColor(actual)
    const computedStyles = {
      body: merge(
        GridResCellStyles.body,
        { width: this.getDurationWidth(actual.duration + (actual.buffer_mins || 0) || 0) },
        actual.is_waitlist_entry && { cursor: 'default' }
      ),
      problemRes: {
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 4,
        zIndex: 31,
      },
      buffer: merge(GridResCellStyles.buffer, { width: bufferWidth }),
      lockContainer: merge(GridResCellStyles.lockContainer, { backgroundColor: actualBg }),
    }
    if (actual.problem) {
      computedStyles.problemRes.backgroundColor = actual.problem.is_major
        ? CommonStyles.ProblemReservationMajor
        : CommonStyles.ProblemReservationMinor
    }

    if (this.state.isMoving) {
      computedStyles.body = merge(computedStyles.body, { zIndex: 32 }, GridResCellStyles.hover)
    }

    const isLocked = this.isLockedActual(actual, venue)
    const isPast = this.isPastTime(actual)

    let moveStyle = GridResCellStyles.move
    let dragStyle = GridResCellStyles.drag
    let lockStyle = GridResCellStyles.lock
    let statusStyle = GridResCellStyles.statusIcon
    let combosStyle = GridResCellStyles.combos
    let { actionBar: actionBarStyle } = GridResCellStyles
    let hoverStyle = {}

    if (this.state.hover) {
      hoverStyle = GridResCellStyles.hover
      lockStyle = merge(lockStyle, { opacity: 0.55 })
      moveStyle = merge(moveStyle, { opacity: 0.55 })
      dragStyle = merge(dragStyle, { opacity: 0.55 })
      actionBarStyle = merge(actionBarStyle, { background: actualBg })
    } else {
      lockStyle = merge(lockStyle, { ':hover': {} })
      moveStyle = merge(moveStyle, { ':hover': {} })
      dragStyle = merge(dragStyle, { ':hover': {} })
      actionBarStyle = merge(actionBarStyle, { background: 'transparent' })
    }

    if (!this.state.hover || isPast) {
      statusStyle = merge(statusStyle, { ':hover': {} })
      moveStyle = merge(moveStyle, { display: 'none' })
      // dragStyle = merge(dragStyle, {display:'none'})
      if (!isLocked) {
        lockStyle = merge(lockStyle, { display: 'none' })
      } else {
        lockStyle = merge(lockStyle, { ':hover': {} })
      }
    } else if (isUnassigned && (!venue.isAutoAssignEnabled || !isLocked)) {
      lockStyle = merge(lockStyle, { display: 'none' })
    }

    if (actual.is_in_service) {
      lockStyle = merge(lockStyle, { ':hover': {} })
      moveStyle = merge(moveStyle, { display: 'none' })
    }
    if (venue.isNightlifeClass) {
      lockStyle = merge(lockStyle, { display: 'none' })
    }
    if (actual.is_waitlist_entry) {
      lockStyle = merge(lockStyle, { display: 'none' })
      moveStyle = merge(moveStyle, { display: 'none' })
      dragStyle = merge(dragStyle, { display: 'none' })
    }

    if (lockStyle.display === 'none') {
      combosStyle = merge(combosStyle, { marginRight: 8 })
    } else if (this.state.hover && dragStyle.display != 'none') {
      combosStyle = merge(combosStyle, { marginRight: 4 })
    }

    /* TODO: add message indicator <div style={[VmsIconSizes.xxsmall, GridResCellStyles.icon]}>{VmsIcons.Message}</div> */
    return (
      <div
        ref={container => {
          this.containerRef = container
        }}
      >
        <ReactTooltip id={`tip-${actual.id}`} effect="solid" />
        <div
          style={[computedStyles.body, { backgroundColor: actualBg }, hoverStyle]}
          onClick={this.handleClick}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          onMouseMove={this.handleDurationMouseMove}
        >
          {!_.isEmpty(actual.problem) ? (
            <div key="_problem" style={computedStyles.problemRes} data-for={`tip-${actual.id}`} data-tip={actual.problem.problem_name} />
          ) : (
            ''
          )}
          {actual.is_client_vip ? <div key="_vip" style={GridResCellStyles.vip} /> : ''}
          <div
            data-test="sr-icon-status"
            key="_status"
            data-for={`tip-${actual.id}`}
            data-tip={actual.is_waitlist_entry ? 'Waitlist (editable in app)' : actual.status_formatted}
            style={[VmsIconSizes.xxsmall, statusStyle, this.getIconColor(actual)]}
            onClick={this.handleDropDownClick}
          >
            {this.renderStatus(actual)}
          </div>
          <div data-test="sr-label-max_guests" key="_maxGuests" style={GridResCellStyles.guests}>
            {actual.max_guests}
          </div>
          <div
            data-test="sr-label-client_name"
            key="_clientName"
            style={GridResCellStyles.name}
            data-for={`tip-${actual.id}`}
            data-tip={this.renderNameToolTip(actual)}
            data-html
            data-multiline
          >
            {actual.client_display_name}
          </div>
          {actual.notes ? (
            <div
              key="_note"
              style={[VmsIconSizes.xxsmall, GridResCellStyles.icon]}
              data-for={`tip-${actual.id}`}
              data-tip={this.renderNameToolTip(actual)}
              data-html
              data-multiline
            >
              {VmsIcons.NoteLine}
            </div>
          ) : (
            ''
          )}
          <div key="_resTags" style={[GridResCellStyles.icon, GridResCellStyles.resTagsIcon]}>
            {this.renderTags(actual.reservation_tags, actual.id)}
          </div>
          {this.renderOccasions(getDateForActual(actual), actual.client_occasions_month_day)}

          <div style={actionBarStyle}>
            <div
              data-test="sr-icon-change_duration"
              key="_drag"
              data-for={`tip-${actual.id}`}
              data-tip="Drag to change duration"
              style={[VmsIconSizes.xxsmall, dragStyle]}
              onMouseDown={this.handleDurationMouseDown}
            >
              {VmsIcons.Drag}
            </div>

            <div style={computedStyles.lockContainer}>
              <div
                data-test="sr-icon-lock"
                key="_locked"
                data-for={`tip-${actual.id}`}
                data-tip={
                  isPast || actual.is_in_service
                    ? `Assigned to ${actual.table_codes_display}`
                    : !isLocked
                    ? 'Click to assign table'
                    : venue.isAutoAssignEnabled
                    ? 'Click to enable auto assign'
                    : 'Click to unassign table'
                }
                style={[VmsIconSizes.xxsmall, GridResCellStyles.icon, lockStyle]}
                onClick={this.handleLockClick}
              >
                {isLocked ? VmsIcons.Lock : VmsIcons.Unlock}
              </div>
            </div>

            {this.isTableCombo(actual) ? (
              <div key="_combos" data-for={`tip-${actual.id}`} data-tip={`Table combo ${actual.table_codes_display}`} style={combosStyle}>
                <span style={[VmsIconSizes.xxsmall, GridResCellStyles.icon, GridResCellStyles.combosIcon]}>{VmsIcons.Combo}</span>
                <span>{this.getNextTableInCombo(actual)}</span>
              </div>
            ) : (
              ''
            )}

            <div
              data-test="sr-icon-click_to_move"
              key="_move"
              data-for={`tip-${actual.id}`}
              data-tip="Click to move"
              style={[VmsIconSizes.xxsmall, moveStyle]}
              onClick={this.handleMoveClick}
            >
              {VmsIcons.Move}
            </div>
          </div>
          <div
            data-test="sr-buffer"
            key="_buffer"
            style={[
              computedStyles.buffer,
              {
                background: `repeating-linear-gradient(-45deg, ${bufferBg} 1px, white 1px, ${bufferBg} 5px)`,
              },
            ]}
          />
          {/* {placeResizeAtEnd && <div style={resizeActionBarStyle}>{resizeIcon}</div>} */}
        </div>
      </div>
    )
  }
}

GridResCell = Radium(GridResCell)

const mapStateToProps = (state, ownProps) => ({
  venue: state.appState.venue,
  venueSettings: state.appState.venueSettings,
  date: state.dayviewState.date,
  statusDropDownState: state.statusDropDownState,
  statusesByDb: state.appState.venueSettings.statusesByDb,
  shouldUpdateFromConflictDialogCount:
    ownProps.actual.id in state.conflictDialogState.refreshActualIdCount
      ? state.conflictDialogState.refreshActualIdCount[ownProps.actual.id]
      : 0,
  isActualMoveMode: state.gridState.movingActual ? state.gridState.movingActual.id === ownProps.actual.id : false,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    statusDropDownOpened: (actual, boundingRect) => {
      dispatch(statusDropDownOpenedAction(actual, boundingRect))
    },
    statusDropDownClosed: () => {
      dispatch(statusDropDownClosedAction())
    },
    assignTable: (venueId, actualId, tableIds) => {
      dispatch(tryPostSaveResTableAssignmentAction({ venueId, actualId, tableIds }))
    },
    autoAssignTables: (venueId, actualId) => {
      dispatch(
        tryPostSaveResTableAssignmentAction({
          venueId,
          actualId,
          isAutoAssign: true,
        })
      )
    },
    unassignTables: (venueId, actualId) => {
      dispatch(tryPostSaveResTableAssignmentAction({ venueId, actualId }))
    },
    startMoveActual: (venueId, date, actual, boundingRect) => {
      dispatch(moveActualAction(venueId, date, actual, boundingRect))
    },
    saveDuration: (venueId, actual, duration) => {
      dispatch(tryPostSaveResDurationAction(venueId, actual.id, duration))
    },
    showConflictDialogAction: (explanation, actionButtonText, conflictActual, nextAction, closeAction) => {
      dispatch(showConflictDialogAction(explanation, actionButtonText, conflictActual, nextAction, closeAction))
    },
  },
})

GridResCell = connect(mapStateToProps, mapDispatchToProps)(GridResCell)

export default GridResCell
