import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import * as ActionTypes from 'mgr/pages/single-venue/reporting/actions/ActionTypes'

import { srPost, srUpload } from '@sevenrooms/core/api'

const SR_API_VERSION = 'api/1_12'
const SR_EXPORT_RESERVATIONS_UPLOAD_URL = `/${SR_API_VERSION}/imports/guestcenter_reservations_upload`

export const onChangeVenue = venue => (dispatch, getState) => {
  dispatch({
    type: CHANGE_VENUE,
    venue,
  })
}

export const changeHardAssignTables = value => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.GC_IMPORT_CHANGE_HARD_ASSIGN_TABLES,
    value,
  })
}

export const changeIgnoreLastUpdated = value => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.GC_IMPORT_CHANGE_IGNORE_LAST_UPDATED,
    value,
  })
}

export const tryLoadGuestCenterImportActivity = venue => dispatch => {
  dispatch({ type: ActionTypes.GC_IMPORT_LOAD_ACTIVITY_START })

  //  return fetchImportActivity(venue).then(response => {
  //    if (response.data) {
  //      dispatch({
  //        type: ActionTypes.GC_IMPORT_LOAD_ACTIVITY_SUCCESS,
  //        autotags: response.data.results,
  //        rebuildState: response.data.rebuild_state,
  //      })
  //    }
  //  })
}

export const tryUploadGuestCenterReservations = file => (dispatch, getState) => {
  if (
    _.isNil(file) ||
    _.isNil(file.name) ||
    !_.startsWith(file.name.toLowerCase(), 'guestcenter_') ||
    !_.endsWith(file.name.toLowerCase(), '.csv')
  ) {
    dispatch(GlobalActions.showErrorMessage(`File does not match expected format: "${file.name}"`))
    return
  }
  const state = getState()

  const failureHandler = (error, errorMsg) => {
    dispatch(
      GlobalActions.showErrorMessage(
        `Failed to process upload. ${errorMsg || 'Please make sure your reservation export file matches this restaurant.'}`
      )
    )
    return { error, errorMsg }
  }
  dispatch({ type: ActionTypes.GC_IMPORT_UPLOAD_RESERVATIONS_START })
  return uploadFileRequest(file, state.guestcenterimport, failureHandler)
    .then(uploadData => {
      if (uploadData.error) {
        dispatch({ type: ActionTypes.GC_IMPORT_UPLOAD_RESERVATIONS_FAIL })
      } else {
        dispatch({ type: ActionTypes.GC_IMPORT_UPLOAD_RESERVATIONS_SUCCESS })
        dispatch(
          GlobalActions.showSuccessMessage(
            'Successfully uploaded GuestCenter Reservations. Depending on file size, please allow several minutes for processing.'
          )
        )
      }
    })
    .catch(error => {
      failureHandler()
      dispatch({ type: ActionTypes.GC_IMPORT_UPLOAD_RESERVATIONS_FAIL })
    })
}

export const uploadFileRequest = (file, guestcenterimportState, failureHandler) => {
  const { hardAssignTables, ignoreLastUpdated } = guestcenterimportState
  return srPost(`/upload-url`, { rurl: SR_EXPORT_RESERVATIONS_UPLOAD_URL }, { convertToGetParams: true }).then(response =>
    response.error
      ? response
      : srUpload(
          response.upload_url,
          file,
          {
            file_param: 'reservations',
            'follow-redirect': true,
            params: {
              hard_assign_tables: hardAssignTables,
              ignore_last_updated: ignoreLastUpdated,
            },
          },
          failureHandler
        )
  )
}
