import styled from 'styled-components'

export const Spinner = styled.div`
  background-image: url(${props => props.theme.mediaUrl}images/new-spinner.gif);
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: center;
  position: sticky;
  top: 0;
  margin-top: 10%;
`
