import React, { Component } from 'react'
import DropdownArrowsPicker from './DropdownArrowsPicker'

const FloorplanLayoutDropDown = ({
  selectedLayoutId,
  floorplanLayoutConfigs,
  label,
  onChangeFloorplanLayout,
  placeholder,
  width,
  height,
  style,
  showNavigation,
  disabled,
  isLightTheme,
}) => {
  const choices = floorplanLayoutConfigs.map(floorplanLayoutConfig => ({
    name: floorplanLayoutConfig.layout_id,
    value: floorplanLayoutConfig.id,
  }))
  const value = selectedLayoutId
  return (
    <DropdownArrowsPicker
      name={label}
      onChangeHandler={onChangeFloorplanLayout}
      style={{
        width,
        ...style,
      }}
      {...{
        choices,
        value,
        placeholder,
        showNavigation,
        disabled,
        isLightTheme,
        height,
      }}
    />
  )
}

FloorplanLayoutDropDown.defaultProps = {
  showNavigation: false,
  disabled: false,
  isLightTheme: false,
  label: 'CHANGE LAYOUT',
  width: 185,
  height: 32,
  style: {},
  onChangeFloorplanLayout: () => {},
}

FloorplanLayoutDropDown.propTypes = {
  selectedLayoutId: React.PropTypes.string,
  floorplanLayoutConfigs: React.PropTypes.array.isRequired,
  showNavigation: React.PropTypes.bool.isRequired,
  placeholder: React.PropTypes.string,
  disabled: React.PropTypes.bool.isRequired,
  isLightTheme: React.PropTypes.bool.isRequired,
  label: React.PropTypes.string.isRequired,
  width: React.PropTypes.number.isRequired,
  height: React.PropTypes.number.isRequired,
  style: React.PropTypes.object.isRequired,
  onChangeFloorplanLayout: React.PropTypes.func.isRequired,
}

export default FloorplanLayoutDropDown
