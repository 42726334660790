import { connect } from 'react-redux'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import { openAddTagSlideout } from 'mgr/pages/venue-group/actions/Tags'

const mapStateToProps = state => ({
  isSlideoutOpen: state.tags.selectedTagCategoryName !== null,
  maxWidth: '498px',
})

const mapDispatchToProps = dispatch => ({
  onCloseHandler: () => dispatch(openAddTagSlideout(false, '', null, '#e21d3e')),
})

const TagSlideout = connect(mapStateToProps, mapDispatchToProps)(SlideoutComponent)

export default TagSlideout
