import { useState } from 'react'
import { Redirect } from 'react-router'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { PacingRule } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'

interface PacingRuleEditCellProps {
  pacingRule: PacingRule
  venueUrlKey: string
  onDelete: (pacingRule: PacingRule) => void
}

export function PacingRuleEditCell({ pacingRule, venueUrlKey, onDelete }: PacingRuleEditCellProps) {
  const { id } = pacingRule
  const [isEdit, setIsEdit] = useState(false)
  return isEdit ? (
    <Redirect push to={`/manager2/${venueUrlKey}/settings/ordering/pacing-rules/${id}`} />
  ) : (
    <Box>
      <Menu data-test={`pacing-rule-inventory-edit-link-${id}`}>
        <MenuItem data-test={`pacing-rule-inventory-edit-link-link-${id}`} onClick={() => setIsEdit(true)}>
          <Icon name="VMSWeb-edit" />
          <Text>Edit</Text>
        </MenuItem>
        <MenuItem
          data-test={`pacing-rule-inventory-delete-link-${id}`}
          onClick={() => {
            onDelete(pacingRule)
          }}
        >
          <Icon name="VMSWeb-trash" />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  )
}
