import React from 'react'
import styled from 'styled-components'
import StandardButton from 'svr/component-lib/Generic/Buttons/StandardButton'

const Container = styled.div`
  > * {
    margin-left: 10px;
  }
`

const ButtonsControlsUpdate = ({ onSubmit, onDelete, isAbleToSubmit }) => (
  <Container>
    <StandardButton data-test="checkout-fee-delete-button" onClick={onDelete} skin={StandardButton.SKINS.DANGER}>
      Delete Service Fee
    </StandardButton>
    <StandardButton
      data-test="checkout-fee-update-button"
      disabled={!isAbleToSubmit}
      onClick={onSubmit}
      skin={StandardButton.SKINS.VMS_PRIMARY}
    >
      Update Changes
    </StandardButton>
  </Container>
)

const ButtonControlsCreate = ({ onSubmit, isAbleToSubmit }) => (
  <StandardButton
    data-test="checkout-fee-create-button"
    disabled={!isAbleToSubmit}
    onClick={onSubmit}
    skin={StandardButton.SKINS.VMS_PRIMARY}
    type="submit"
  >
    Create
  </StandardButton>
)

const ButtonControls = ({ isAbleToSubmit, onSubmit, onDelete, isNew }) => {
  if (isNew) {
    return <ButtonControlsCreate onSubmit={onSubmit} isAbleToSubmit={isAbleToSubmit} />
  }

  return <ButtonsControlsUpdate onSubmit={onSubmit} onDelete={onDelete} isAbleToSubmit={isAbleToSubmit} />
}

export default ButtonControls
