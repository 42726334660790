import { useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

export interface OptionItem<T extends string | number> {
  id: T
  displayName: string
}

export interface OptionProps<T extends string | number> {
  item: OptionItem<T>
  active: boolean
  onSelect: (item: OptionItem<T>) => void
}

export function Option<T extends string | number>({ item, active, onSelect }: OptionProps<T>) {
  const ref = useRef<HTMLLIElement>(null)

  const onClickHandler = useCallback(() => {
    onSelect(item)
  }, [onSelect, item])

  useEffect(() => {
    if (active) {
      ref.current?.focus()
    }
  }, [active])

  return (
    <StyledOption ref={ref} tabIndex={active ? 0 : -1} onClick={onClickHandler}>
      <OptionText active={active}>{item.displayName}</OptionText>
    </StyledOption>
  )
}

const StyledOption = styled.li`
  list-style: none;
  outline: none;
  margin: 5px !important;
  padding: 0;
`

const OptionText = styled.span<{ active: boolean }>`
  // There are global styles with id in selector declared in layout.styl,
  // to override them used important selector
  float: none !important;
  line-height: 1em !important;
  border-radius: 2px;
  color: white;
  display: block;
  font-size: 11px;
  padding: 3px;
  margin: 1px 0 !important;

  ${props =>
    props.active &&
    css`
      background: rgba(255, 255, 255, 0.3);
    `}
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
`
