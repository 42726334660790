import { connect } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import { selectNavigationPermissions } from 'mgr/lib/utils/PermissionsUtils'
import type { Venue, VenueSettings, UserDomain, UserDomainVenue } from '@sevenrooms/mgr-core'
import { SMSLanguageSettings, type Permissions } from '@sevenrooms/mgr-settings/Settings/LanguageSettings'
import type { match } from 'react-router'

interface SMSSettingsProps {
  readonly match: match
  readonly venue: Venue
  readonly venues: UserDomainVenue[]
  readonly onChangeVenue: (venue: UserDomainVenue) => void
  readonly permissions: Permissions
}

function SMSSettingsComponent(props: SMSSettingsProps) {
  const { venue, venues, onChangeVenue, match, permissions } = props
  const routes = {
    SMS_RES_LANGUAGE: `${match.path}/sms_res_language`,
    SMS_WAITLIST_LANGUAGE: `${match.path}/sms_waitlist_language`,
    SMS_ORDER_MENU_LANGUAGE: `${match.path}/sms_order_menu_language`,
    SMS_ORDERING: `${match.path}/sms_ordering`,
    SMS_CUSTOM: `${match.path}/custom_sms`,
    SMS_PRIORITY_ALERTS: `${match.path}/sms_priority_alerts`,
    SMS_PRIVATE_LINE: `${match.path}/sms_private_line`,
  }

  return <SMSLanguageSettings venue={venue} venues={venues} routes={routes} permissions={permissions} onChangeVenue={onChangeVenue} />
}

const mapStateToProps = (state: { appState: { venue: Venue; userDomain: UserDomain; venueSettings: VenueSettings } }) => {
  const { venue, userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  const permissions = selectNavigationPermissions(state) as Permissions
  return {
    venue,
    venues,
    permissions,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
}

export const SMSSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(SMSSettingsComponent)
