import { useState, useImperativeHandle, type Ref } from 'react'
import { UserDomainVenuesDropdownMenu, type UserDomainVenuesDropdownMenuProps, type Venue } from './UserDomainVenuesDropdownMenu'

interface HeaderProps {
  activeVenueId: string
  entityUrlSuffix: string
  onVenuesMenuToggle: UserDomainVenuesDropdownMenuProps['onToggle']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerRef: Ref<any>
}

export function Header({ activeVenueId, entityUrlSuffix, onVenuesMenuToggle, headerRef }: HeaderProps) {
  const [venues, setVenues] = useState<Venue[]>()

  useImperativeHandle(
    headerRef,
    () => ({
      onVenuesLoaded: (loadedVenues: Venue[]) => {
        setVenues(loadedVenues)
      },
    }),
    []
  )

  return venues && venues.length > 1 ? (
    <UserDomainVenuesDropdownMenu
      venues={venues}
      activeVenueId={activeVenueId}
      entityUrlSuffix={entityUrlSuffix}
      onToggle={onVenuesMenuToggle}
    />
  ) : null
}
