import styled from 'styled-components'
import { isMenuItem } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { ProductInventoryItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

const ProductContainer = styled.div`
  display: flex;
  width: 100%;
`

const ImageContainer = styled.div`
  width: 56px;
  min-width: 56px;
  height: 56px;
  margin-right: ${props => props.theme.gutter.double};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const ProductInfo = styled.div`
  width: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ProductName = styled.div`
  ${props => props.theme.ellipsis};
`
const ProductDescription = styled(ProductName)``

export interface ProductItemCellProps {
  product: ProductInventoryItem
}

function ProductItemCell({ product }: ProductItemCellProps) {
  let imageURL
  if (isMenuItem(product)) {
    imageURL = product.imageUrls?.small || product.imageUrls?.externalUrls?.[0]
  }
  if (!imageURL) {
    imageURL = `${window.globalInit.mediaUrl}images/products/img_placeholder.jpg`
  }

  return (
    <ProductContainer data-test={`sr-ordering-product-item-${product.id}`}>
      <ImageContainer>
        <img src={imageURL} alt="" />
      </ImageContainer>
      <ProductInfo>
        <ProductName>{product.name}</ProductName>
        <ProductDescription>{product.description}</ProductDescription>
      </ProductInfo>
    </ProductContainer>
  )
}

export default ProductItemCell
