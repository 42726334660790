import type { ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { reportingMessages } from '../locales'

export const statusTitle: { [key: string]: { id: string; defaultMessage: string } } = {
  OPPORTUNITY: reportingMessages.statusOpportunityLabel,
  PERFORMING: reportingMessages.statusPerformingLabel,
  ROOM_TO_GROW: reportingMessages.statusRoomToGrowLabel,
}

export const statusColor: { [key: string]: ThemeColor } = {
  OPPORTUNITY: 'attentionNew',
  PERFORMING: 'success',
  ROOM_TO_GROW: 'attentionSecondary',
}
