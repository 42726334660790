import Radium from 'radium'
import React, { Component } from 'react'

class DisableScreen extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const styles = {
      body: {
        height: '100%',
        width: '100%',
        backgroundColor: '#ccc',
        position: 'fixed',
        top: 0,
        left: 0,
        opacity: '.5',
        zIndex: this.props.zIndex || '30',
      },
      left: {
        width: 217,
        zIndex: this.props.zIndex || '46',
      },
      right: {
        left: 217,
      },
    }
    return (
      <div>
        <div style={[styles.body, styles.left]}>&nbsp;</div>
        <div style={[styles.body, styles.right]}>&nbsp;</div>
      </div>
    )
  }
}

DisableScreen = Radium(DisableScreen)

export default DisableScreen
