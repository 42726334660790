import { connect } from 'react-redux'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import { CustomSmsLanguageStrings, type CustomSmsLanguageStringsProps } from '@sevenrooms/mgr-settings/Settings/LanguageSettings'

function CustomSmsLanguageStringsComponent({ routes, onChangeVenue }: CustomSmsLanguageStringsProps) {
  return <CustomSmsLanguageStrings routes={routes} onChangeVenue={onChangeVenue} />
}

const mapDispatchToProps = {
  onChangeVenue,
}

export const CustomSmsLanguageStringsContainer = connect(null, mapDispatchToProps)(CustomSmsLanguageStringsComponent)
