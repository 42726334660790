export function groupTagIsEditable(
  isPodTag: boolean,
  isGlobalTag: boolean,
  userCanAdministratePodTags: boolean,
  userCanAdministrateGlobalTags: boolean
) {
  if (isPodTag) {
    return userCanAdministratePodTags
  }
  if (isGlobalTag) {
    return userCanAdministrateGlobalTags
  }
  return false
}
