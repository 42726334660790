import { FEE_AMOUNT } from 'mgr/pages/single-venue/settings/constants'

import { srDelete, srGet, srPostJson, srPut, throwFetchError } from '@sevenrooms/core/api'

const getFeeAmountValue = rawItem => {
  if (rawItem.is_pass_through_third_party) {
    return FEE_AMOUNT.PASS_THROUGH
  }
  if (rawItem.amount_fixed && rawItem.amount_fraction) {
    return FEE_AMOUNT.BOTH
  }
  if (rawItem.amount_fraction) {
    return FEE_AMOUNT.PERCENTAGE
  }
  if (rawItem.amount_fixed || rawItem.distance_tiers.length > 0) {
    return FEE_AMOUNT.AMOUNT
  }
  return ''
}

const feeAdapter = rawItem => ({
  id: rawItem.id,
  name: rawItem.name,
  amountFixed: rawItem.amount_fixed,
  amountFraction: rawItem.amount_fraction,
  distanceTiers: rawItem.distance_tiers,
  distanceUnit: rawItem.distance_unit,
  costPerExtraDistanceUnit: rawItem.cost_per_extra_distance_unit,
  fulfillmentMethods: rawItem.fulfillment_methods,
  isEnabled: rawItem.is_enabled,
  isDeleted: !!rawItem.deleted,
  isPassThroughThirdParty: rawItem.is_pass_through_third_party,
  orderingSiteIds: rawItem.ordering_site_ids,
  taxGroupId: rawItem.tax_group_id,
  updatedAt: rawItem.updated,
  updatedByUserId: rawItem.updated_by_user_id,
  venueId: rawItem.venue_id,
  feeAmount: getFeeAmountValue(rawItem),
})

const getPropsByFeeAmount = serviceCharge => {
  switch (serviceCharge.feeAmount) {
    case FEE_AMOUNT.PASS_THROUGH:
      return {
        is_pass_through_third_party: true,
      }
    case FEE_AMOUNT.PERCENTAGE:
      return {
        amount_fraction: serviceCharge.amountFraction,
      }
    case FEE_AMOUNT.AMOUNT:
      return {
        amount_fixed: serviceCharge.amountFixed,
        distance_tiers: serviceCharge.distanceTiers,
        cost_per_extra_distance_unit: serviceCharge.costPerExtraDistanceUnit,
      }
    case FEE_AMOUNT.BOTH:
      return {
        amount_fixed: serviceCharge.amountFixed,
        amount_fraction: serviceCharge.amountFraction,
      }
    default:
      return {}
  }
}

const serviceChargeToApi = serviceCharge => ({
  id: serviceCharge.id,
  name: serviceCharge.name,
  fulfillment_methods: serviceCharge.fulfillmentMethods,
  is_enabled: serviceCharge.isEnabled,
  deleted: serviceCharge.isDeleted || false,
  ordering_site_ids: serviceCharge.orderingSiteIds,
  tax_group_id: serviceCharge.taxGroupId,
  distance_unit: serviceCharge.distanceUnit,
  updated: serviceCharge.updatedAt,
  updated_by_user_id: serviceCharge.updatedByUserId,
  venue_id: serviceCharge.venueId,

  is_pass_through_third_party: false,
  amount_fixed: 0,
  amount_fraction: 0,
  distance_tiers: [],
  cost_per_extra_distance_unit: 0,
  ...getPropsByFeeAmount(serviceCharge),
})

export const getOrderingChargesList = ({ venueId }) =>
  srGet(`/api-yoa/ordering/service_charges/${venueId}`, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(throwFetchError)
    .then(response => ({
      data: {
        ...response.data,
        ordering_service_charges: response.data.ordering_service_charges.map(feeAdapter),
      },
    }))

export const putOrderingCharge = ({ venueId, serviceChargeId, serviceCharge }) =>
  srPut(`/api-yoa/ordering/service_charges/${venueId}/${serviceChargeId}`, serviceChargeToApi(serviceCharge), {
    json: true,
  }).then(throwFetchError)

export const postNewOrderingCharge = ({ venueId, serviceCharge }) =>
  srPostJson(`/api-yoa/ordering/service_charges/${venueId}`, serviceChargeToApi(serviceCharge), {
    json: true,
  }).then(throwFetchError)

export const deleteOrderingChargeById = ({ venueId, serviceChargeId }) =>
  srDelete(`/api-yoa/ordering/service_charges/${venueId}/${serviceChargeId}`).then(throwFetchError)
