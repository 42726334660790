import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import { fetchLandingPageSettingsData, saveLandingPageSettingsData } from 'mgr/lib/services/LandingPageService'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'

export const onChangeVenue = venue => dispatch => {
  dispatch({
    type: CHANGE_VENUE,
    venue,
  })
}

export const toggleLendingPageSlideout = () => ({
  type: ActionTypes.TOGGLE_LANDING_PAGE_SLIDEOUT,
})

export const toggleLendingPageHeaderTextSlideout = () => ({
  type: ActionTypes.TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT,
})

export const updateHeaderText = newText => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT,
    newText,
  })

  trySaveLandingPageSettingsData(dispatch, getState)
}

export const updateHeaderTextAndToggle = newText => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_AND_TOGGLE,
    newText,
  })

  trySaveLandingPageSettingsData(dispatch, getState)
}

export const toggleSetting = settingName => (dispatch, getState) => {
  if (settingName === 'show_header_text') {
    const state = getState()

    if (!state.landingPageSettings.data.header_text) {
      dispatch({
        type: ActionTypes.TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_FROM_RADIO_BUTTON,
      })
      return
    }
  }

  dispatch({
    type: ActionTypes.TOGGLE_SETTING,
    settingName,
  })

  trySaveLandingPageSettingsData(dispatch, getState)
}

export const addButton = button => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.LANDING_PAGE_ADD_BUTTON,
    button,
  })

  trySaveLandingPageSettingsData(dispatch, getState, 'You have successfully added a new button')
}

export const saveLandingPageDataSuccess = result => dispatch => {
  const nextState = populateData({ landingPageSettings: result })

  dispatch({
    type: ActionTypes.SAVE_LANDING_PAGE_DATA_SUCCESS,
    nextState: { data: nextState.landingPageSettingsData },
  })
}

export const saveLandingPageDataSuccessFail = () => dispatch => {
  dispatch({
    type: ActionTypes.SAVE_LANDING_PAGE_DATA_FAIL,
  })
}

export const reorderButtons = reorder => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.REORDER_LANDING_PAGE_BUTTONS,
    reorder,
  })

  trySaveLandingPageSettingsData(dispatch, getState)
}

export const tryLoadLandingPageSettingsData = venue => dispatch => {
  dispatch({ type: ActionTypes.LOAD_LANDING_PAGE_DATA_START })

  return fetchLandingPageSettingsData(venue)
    .then(response => {
      const nextState = populateData(response)

      dispatch({
        type: ActionTypes.LOAD_LANDING_PAGE_DATA_SUCCESS,
        venueProfile: response.venueProfile.data.venue,
        ...nextState,
      })
    })
    .catch(() => {
      dispatch(GlobalActions.showErrorMessage('An Error has occurred while loading data'))
    })
}

export const trySaveLandingPageSettingsData = (dispatch, getState, successNotification = undefined) => {
  dispatch({
    type: ActionTypes.SAVE_LANDING_PAGE_DATA_START,
  })

  const state = getState()

  const buttons = state.landingPageSettings.data.buttons.primary.concat(state.landingPageSettings.data.buttons.secondary)

  const toSave = {
    ...state.landingPageSettings.data,
    buttons,
  }

  return saveLandingPageSettingsData(state.appState.venue, toSave).then(
    result => {
      dispatch(saveLandingPageDataSuccess(result))

      if (successNotification) {
        dispatch(GlobalActions.showSuccessMessage(successNotification))
      }
    },
    response => {
      dispatch(saveLandingPageDataSuccessFail())
      dispatch(GlobalActions.showErrorMessage(String(response)))
      dispatch(GlobalActions.showErrorMessage('An Error has occurred while saving data'))
    }
  )
}

export const removeButton = id => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.REMOVE_LANDING_PAGE_BUTTON,
    id,
  })

  trySaveLandingPageSettingsData(dispatch, getState)
}

export const toggleButtonEditor = id => dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_LANDING_PAGE_SLIDEOUT_EDIT_BUTTON,
    id,
  })
}
export const editButton = button => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.EDIT_LANDING_PAGE_BUTTON,
    button,
  })

  trySaveLandingPageSettingsData(dispatch, getState, 'Button edits have been saved')
}

function populateData(data) {
  const buttons = {
    primary: [],
    secondary: [],
  }

  data.landingPageSettings.data.landing_page_settings.buttons.forEach((b, index) => {
    const button = { ...b }
    button.id = `${Math.random()}_${index}`

    if (button.primary) {
      buttons.primary.push(button)
    } else {
      buttons.secondary.push(button)
    }
  })

  return {
    landingPageSettingsData: {
      ...data.landingPageSettings.data.landing_page_settings,
      buttons,
    },
  }
}
