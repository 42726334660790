import type { ReducePaidChannelSuggestedActionsType, ReducePaidChannelTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { insightsMessages } from '../../../locales'
import { formatParagraph, type DetailProps } from '../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { ReducePaidChannelSuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { ReducePaidChannelProofTable } from '../../Proofs'

export function ReducePaidChannelDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { suggestedActionsData, insightProofData } = data
  const actionData = suggestedActionsData as ReducePaidChannelSuggestedActionsType
  const tableRows = (insightProofData?.[0]?.tableRows as ReducePaidChannelTableRow[]) ?? []

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.channelOptimizationTitle)}
      description={formatMessage(insightsMessages.suggestedActionsChannelOptimizationSlideoutDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
      })}
      suggestedActionsComponent={<ReducePaidChannelSuggestedActions shiftDetail={actionData.shiftDetail} />}
      proofLabel={formatMessage(insightsMessages.reducePaidChannelProofLabel, {
        shiftDetail: actionData.shiftDetail,
      })}
      proofComponent={<ReducePaidChannelProofTable tableRows={tableRows} />}
    />
  )
}
