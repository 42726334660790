import type { PacingCoverageSuggestedActionsType, PacingCoverageTableRow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { insightsMessages } from '../../../locales'
import { formatParagraph, type DetailProps } from '../../../utils'
import { InsightDetailSlideoutSectionsContainer } from '../../InsightDetailSlideoutSections'
import { PacingCoverageSuggestedActions } from '../../InsightSuggestedActionsSectionContent'
import { PacingCoverageProofTable } from '../../Proofs'

export function PacingCoverageDetail({ data }: DetailProps) {
  const { formatMessage } = useLocales()
  const { suggestedActionsData, insightProofData } = data
  const tableRows = (insightProofData?.[0]?.tableRows as PacingCoverageTableRow[]) ?? []

  return (
    <InsightDetailSlideoutSectionsContainer
      data={data}
      title={formatMessage(insightsMessages.availabilityOptimizationTitle)}
      description={formatMessage(insightsMessages.pacingCoverageDescription, {
        p: (chunks: string[]) => formatParagraph(chunks),
      })}
      suggestedActionsComponent={
        <PacingCoverageSuggestedActions insightSuggestedActionData={suggestedActionsData as PacingCoverageSuggestedActionsType} />
      }
      proofComponent={<PacingCoverageProofTable tableRows={tableRows} />}
    />
  )
}
