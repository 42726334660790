import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as NavActionTypes from 'mgr/lib/actions/NavActionTypes'

export const updateNavState = (activeSection, subNavSection) => ({
  type: NavActionTypes.UPDATE_NAV_STATE,
  activeSection,
  subNavSection,
})

export function useNavState(activeSection, subNavSection) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateNavState(activeSection, subNavSection))
    return () => dispatch(updateNavState(null, null))
  }, [dispatch, activeSection, subNavSection])
}
